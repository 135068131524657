/* eslint-disable */
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

const EventPartnerHeader = ({
  isEventModerator,
  parentEventTitle,
  changeStatusHandler,
  reloadEventMemberList,
}) => {
  const breadCrumbText = [
    { title: t("Event"), link: "/admin/events/list" },
    { title: t("Event Sponsor") },
  ];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ---- search section start --- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          {/* --- bread crumb ---- */}
          <BreadCrumb
            breadCrumbText={breadCrumbText}
            displayName={parentEventTitle}
          />
        </div>
        {/* ---- search section end --- */}

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          {/* ------ refresh icon start ------ */}
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center"
            onClick={reloadEventMemberList}
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          {/* ------ refresh icon end ------ */}

          {/* --- action section start --- */}
          {isEventModerator ? (
            <div className="dropdown flex-fill flex-grow-sm-0">
              <button
                className="btn btn-gray text-start w-100 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("Action")}
              </button>
              <ul className="dropdown-menu w-100 bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                {/* --- add label start --- */}
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2"
                    data-bs-toggle="modal"
                    data-bs-target="#addLabelModal"
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      label
                    </span>
                    <span className="d-block">{t("Add Label")}</span>
                  </Link>
                </li>
                {/* --- add label start --- */}

                {/* --- delete start --- */}
                <li>
                  <Link
                    to="#"
                    className="dropdown-item d-flex align-items-center gap-2"
                    onClick={changeStatusHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      delete
                    </span>
                    <span className="d-block">{t("Delete")}</span>
                  </Link>
                </li>
                {/* --- delete end --- */}
              </ul>
            </div>
          ) : null}
          {/* --- action section end --- */}

          {/* --- add new members start --- */}
          {isEventModerator ? (
            <Link
              to="#"
              className="btn btn-primary d-flex align-items-center gap-1"
              data-bs-toggle="modal"
              data-bs-target="#addEventCompany"
            >
              <span className="d-block material-symbols-outlined icon-md">
                add
              </span>
              <span className="d-block">{t("Add New")}</span>
            </Link>
          ) : null}
          {/* --- add new members end --- */}
        </div>
      </div>
    </div>
  );
};

export default EventPartnerHeader;
