/* eslint-disable */
import React, { useEffect, useState } from "react";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";
import { useTranslation } from "react-i18next";

const AddSectionModal = ({
  sectionOptions,
  projectId,
  onModalClose,
  selectedSectionId,
  setSelectedSectionId,
  selectedParentSectionValue,
  setSelectedParentSectionValue,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [parentSectionVal, setParentSectionVal] = useState(null);
  const [selectedParnetSectionId, setselectedParnetSectionId] = useState(null);
  const [title, settitle] = useState("");
  const [hours, sethours] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    hoursWarning: false,
    fromDateWarning: false,
    toDateWarning: false,
    dateCompareWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }
    if (hours === "") {
      setValidation((prevState) => ({
        ...prevState,
        hoursWarning: true,
      }));
      isValid = false;
    }

    if (fromDate === "") {
      setValidation((prevState) => ({ ...prevState, fromDateWarning: true }));
      isValid = false;
    }

    if (toDate === "") {
      setValidation((prevState) => ({ ...prevState, toDateWarning: true }));
      isValid = false;
    }

    if (fromDate !== "" && toDate !== "") {
      if (fromDate > toDate) {
        setValidation((prevState) => ({
          ...prevState,
          dateCompareWarning: true,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  //function for get project details
  const getProjectDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${projectId}?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status && response.data) {
        const data = response.data;

        setfromDate(data.startdate);
        settoDate(data.enddate);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //get section details
  const getSectionDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SECTION_DETAILS +
        `/${selectedSectionId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in section details", response);

      if (response.status) {
        settitle(response.data.title);
        sethours(response.data.hours);
        setfromDate(response.data.startdate);
        settoDate(response.data.enddate);
        parentSectionSelectionHandler(response.data.parentsectionvalue);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for select parent select
  const parentSectionSelectionHandler = (val) => {
    if (val) {
      setParentSectionVal(val);
      setselectedParnetSectionId(val.value);
    } else {
      setParentSectionVal(null);
      setselectedParnetSectionId(null);
    }
  };

  //save function
  const saveSectionHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        let sectionData = {
          parentsectionid: selectedParnetSectionId || null,
          title,
          startdate: fromDate,
          enddate: toDate,
          hours,
          project: projectId,
        };

        // console.log(sectionData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (selectedSectionId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_PROJECT_SECTION +
            `/${selectedSectionId}?token=${token}`;

          response = await putData(requestUrl, sectionData);
        } else {
          requestUrl =
            requestUrl + url.API_ADD_NEW_PROEJCT_SECTION + `?token=${token}`;
          response = await postData(requestUrl, sectionData);
        }

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          // close the modal of addLabel
          let addModal = document.querySelector("#addSectionModal");
          let modal = bootstrap.Modal.getInstance(addModal);
          modal.hide();
          resetHandler();
          onModalClose();
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please fill up required fileds");
    }

    setShowAlert(true);
  };

  //reset function
  const resetHandler = () => {
    setParentSectionVal(null);
    setselectedParnetSectionId(null);
    settitle("");
    sethours("");
    // setfromDate("");
    // settoDate("");
    setSelectedSectionId(null);
    setSelectedParentSectionValue(null);
    setValidation({
      nameWarning: false,
      hoursWarning: false,
      fromDateWarning: false,
      toDateWarning: false,
      dateCompareWarning: false,
    });
  };

  useEffect(() => {
    if (selectedSectionId) {
      getSectionDetails();
    }
  }, [selectedSectionId]);

  useEffect(() => {
    if (projectId) {
      getProjectDetails();
    }
  }, [projectId]);

  useEffect(() => {
    if (selectedParentSectionValue) {
      parentSectionSelectionHandler(selectedParentSectionValue);
    }
  }, [selectedParentSectionValue]);

  return (
    <div
      className="modal fade"
      id="addSectionModal"
      tabIndex="-1"
      aria-labelledby="addSectionModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addSectionModalLabel">
              {t("Section Details")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            {/* <!-- ========== Start Parent select Section ========== --> */}
            <div className="form-group mb-4">
              <label
                htmlFor="sectionName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Select Parent")}
              </label>
              <Select
                isClearable
                placeholder={t("Select Parent")}
                options={sectionOptions}
                value={parentSectionVal}
                onChange={(val) => {
                  parentSectionSelectionHandler(val);
                }}
              />
            </div>
            {/* <!-- ========== End Parent select Section ========== --> */}

            {/* <!-- ========== Start title of the Section ========== --> */}
            <div className="form-group mb-4">
              <label
                htmlFor="sectionName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Section Name")}
              </label>
              <input
                type="text"
                id="sectionName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter name")}
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                }}
              />

              {/* <!-- ========== Start title warning Section ========== --> */}
              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter title")}!</span>
                  </p>
                </div>
              )}
              {/* <!-- ========== End title warning Section ========== --> */}
            </div>
            {/* <!-- ========== End title of the Section ========== --> */}

            {/* <!-- ========== Start duration Section ========== --> */}
            <div className="form-group mb-4">
              <label
                htmlFor="targetHours"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Target Hours")}
              </label>
              <input
                type="text"
                id="targetHours"
                className="form-control fs-sm shadow-none"
                placeholder={t("Hrs required")}
                value={hours}
                onChange={(e) => {
                  sethours(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    hoursWarning: false,
                  }));
                }}
              />

              {/* <!-- ========== Start duration warning Section ========== --> */}
              {validation.hoursWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter hours")}!</span>
                  </p>
                </div>
              )}
              {/* <!-- ========== End duration warning Section ========== --> */}
            </div>
            {/* <!-- ========== End duration Section ========== --> */}

            {/* <!-- ========== Start of dates Section ========== --> */}
            <div className="form-group mb-4">
              <label
                htmlFor="deadline"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Deadline")}
              </label>
              <div className="field d-flex body-bg rounded-8 overflow-hidden">
                <input
                  type="date"
                  className="form-control fs-sm border-0 rounded-0 border-end shadow-none"
                  placeholder={t("Start Date")}
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      fromDateWarning: false,
                      toDateWarning: false,
                      dateCompareWarning: false,
                    }));
                  }}
                />
                <input
                  type="date"
                  className="form-control fs-sm border-0 rounded-0 shadow-none"
                  placeholder={t("End Date")}
                  value={toDate}
                  onChange={(e) => {
                    settoDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      fromDateWarning: false,
                      toDateWarning: false,
                      dateCompareWarning: false,
                    }));
                  }}
                />
              </div>

              {/* <!-- ========== Start to date warning Section ========== --> */}
              {validation.toDateWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter start date")}!</span>
                  </p>
                </div>
              )}
              {/* <!-- ========== End to date warning Section ========== --> */}

              {/* <!-- ========== Start from date warning Section ========== --> */}
              {validation.fromDateWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter end date")}!</span>
                  </p>
                </div>
              )}
              {/* <!-- ========== End from date warning Section ========== --> */}

              {/* <!-- ========== Start date compare warning Section ========== --> */}
              {validation.dateCompareWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>
                      {t("End date should be greater then start date")}!
                    </span>
                  </p>
                </div>
              )}
              {/* <!-- ========== End date compare warning Section ========== --> */}
            </div>
            {/* <!-- ========== End of dates Section ========== --> */}

            {/* <!-- ========== Start button Section ========== --> */}
            <div className="action">
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveSectionHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                {isSaving ? "" : t("Save Section")}
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
            {/* <!-- ========== End button Section ========== --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSectionModal;
