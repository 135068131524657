/* eslint-disable */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import images
import { assetImages } from 'constants';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import { eventDetailsHeaderLinks } from 'helper/EventHelper/EventHelper';

import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import AgendaHeader from '../Header/AgendaHeader';
import SaveAgendaModal from '../Modal/SaveAgendaModal';
import AgendaListFilterPopup from '../Popup/AgendaListFilterPopup';
import { useTranslation } from 'react-i18next';

const EventAgendaBody = () => {
  const { t, i18n } = useTranslation(); //for translation
  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  // -------- tabs header object --------------------
  const commonHeaderObject = eventDetailsHeaderLinks(params.id, t);

  // const { screenHeight } = useContext(GlobalProvider);
  const [isLoading, setIsLoading] = useState(false);
  const [agendaList, setAgendaList] = useState([]);

  const [parentEventTitle, setParentEventTitle] = useState('');

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedAgendaIds, setSelectedAgendaIds] = useState([]);

  const [agendaId, setAgendaId] = useState(null);

  const [isEventModerator, setisEventModerator] = useState(false);

  const [eventMemberStatus, setEventMemberStatus] = useState('');
  const [eventModStatus, setEventModStatus] = useState('');

  const [isEventDetailsLoading, setIsEventDetailsLoading] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_MEMBER_DETAILS_BY_QUERY +
        `?token=${token}&eventid=${params.id}`;

      const response = await getData(requestUrl);

      console.log('response in member details', response);

      if (response.status) {
        setEventMemberStatus(response.data.memberstatus);
        setEventModStatus(response.data.moderatorstatus);
        setinviteDate(response.data.requestdate);
        setJoinDate(response.data.joindate);
      } else {
        setAlertMessage(response.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get event details
  const getEventDetails = async () => {
    setIsEventDetailsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${params.id}?token=${token}`;

      console.log('url of event details------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response of event details------>', response);

      if (response.status) {
        //assign title
        response.data?.title && setParentEventTitle(response.data?.title);

        let isModerator = false;

        if (
          response.data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN'
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisEventModerator(isModerator);

        setIsEventDetailsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllEventAgenda = async () => {
    setRowSelection({});
    setSelectedAgendaIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_AGENDA +
        `?token=${token}&event=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);
      setIsLoading(false);
      if (response.status) {
        setAgendaList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async e => {
    e.preventDefault();

    for (let agendaId of selectedAgendaIds) {
      try {
        let eventData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_AGENDA +
          `/${agendaId}` +
          `?token=${token}`;

        await putData(requestUrl, eventData);
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllEventAgenda();
  };

  useEffect(() => {
    if (params.id) {
      getAllEventAgenda();
      getEventDetails();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="modal"
            data-bs-target="#addAgenda"
            onClick={() => {
              setAgendaId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'description',
        header: t('Name'),
        size: 250,
      },
      {
        accessorKey: 'date',
        header: t('Date'),
      },
      {
        accessorKey: 'starttime',
        header: t('Start Time'),
      },
      {
        accessorKey: 'endtime',
        header: t('End Time'),
      },
      {
        accessorKey: 'attandscount',
        header: t('Attendants'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Labels'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [agendaList, i18n.language]
  );

  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ];

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedAgendaIds(selectedIdsArray);
    } else {
      setSelectedAgendaIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_EVENT')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Agenda')}
          />

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {isEventDetailsLoading ? null : (
              <AgendaHeader
                isEventModerator={isEventModerator}
                parentEventTitle={parentEventTitle}
                reloadList={getAllEventAgenda}
                changeStatusHandler={changeStatusHandler}
              />
            )}

            {isLoading || isEventDetailsLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4 mt-2">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isEventModerator ||
              (eventMemberStatus === '1' && eventModStatus === '1') ? (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={agendaList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem
                  //     key="edit"
                  //     //onClick={() => console.info(row.id)}
                  //   >
                  //     <Link
                  //       className="dropdown-item"
                  //       to={`/admin/events/info/${row.id}`}
                  //     >
                  //       View Details
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            ) : eventMemberStatus === '' && eventModStatus === '' ? null : (
              <div className="table-wrapper">
                <div className="team_members mb-4">
                  <div className="challenges_empty text-center">
                    <div className="empty_pic mb-4">
                      {' '}
                      <img src={assetImages.emptyVector} alt="" />
                    </div>
                    <div className="empty_text">
                      <p className="fs-lg text-gray fw-semibold mb-4">
                        {t(
                          "Sorry....! You don't have privilege to see this content"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* ---- list section end---- */}
          </div>

          {/* === popup and modal section === */}
          {/* --- add label modal --- */}
          <AddLabelModal
            moduleName="eventagenda"
            afterTagModalClose={getAllEventAgenda}
            selectedIds={selectedAgendaIds}
          />

          {/* --- add new member modal --- */}
          <SaveAgendaModal
            isEventModerator={isEventModerator}
            agendaId={agendaId}
            onModalClose={getAllEventAgenda}
            setAgendaId={setAgendaId}
          />

          {/* <AgendaListFilterPopup /> */}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default EventAgendaBody;
