import Header from "components/Common/Header/Header";
import SaveTaskBody from "components/Common/SaveTask/SaveTaskBody";
import React, { useEffect } from "react";

const TaskDetails = () => {
  useEffect(() => {
    document.title = "View Task";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="mytask" />
      <SaveTaskBody />
    </main>
  );
};

export default TaskDetails;
