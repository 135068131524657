import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SkillPointAccordion = ({
  skillPointListBlock = [],
  updateSkillPointBlock,
  onDeleteSkillPointBlock,
  setSkillPointIndex,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      {/* ------ toggle accordion button start ------ */}
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseSix"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseSix"
        >
          {t("Skill Points")}
        </button>
      </h3>
      {/* ------ toggle accordion button end ------ */}
      {/* ----- skill point block list section start ----- */}
      <div
        id="panelsStayOpen-collapseSix"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          <div className="fields-group">
            <div className="fields d-flex flex-column gap-3">
              {/* ----- skill point list section start ------ */}
              {skillPointListBlock.map((skillBlock, index) => {
                return (
                  <div className="field" key={index}>
                    <div className="form-group">
                      <div className="row">
                        {/* ------ skill name section start ------ */}
                        <div className="col-lg-3 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label htmlFor="skill" className="text-nowrap me-3">
                              {t("Skill")}
                            </label>
                            <p className="form-control border-0 rounded-0 shadow-none">
                              {skillBlock?.skillname}
                            </p>
                          </div>
                        </div>
                        {/* ------ skill name section end ------ */}
                        {/* ------ skill point section start ------ */}
                        <div className="col-lg-4">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label htmlFor="point" className="text-nowrap me-3">
                              {t("Point")}
                            </label>
                            <p className="form-control border-0 rounded-0 shadow-none">
                              {skillBlock.points}
                            </p>
                          </div>
                        </div>
                        {/* ------ skill point section end ------ */}
                        {/* ------ experienceyear point section start ------ */}
                        <div className="col-lg-4">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label htmlFor="point" className="text-nowrap me-3">
                              {t("Total Experience")}
                            </label>
                            <p className="form-control border-0 rounded-0 shadow-none">
                              {skillBlock.experienceyear}
                            </p>
                          </div>
                        </div>
                        {/* ------ experienceyear point section end ------ */}
                        {/* ----- edit and delete icon section start ------ */}
                        <div className="col-lg-1 action d-flex align-items-center justify-content-end gap-3 mb-3">
                          {/* ----- edit icon start ------ */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#addSkillPointModal"
                            onClick={() => {
                              updateSkillPointBlock(skillBlock, index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              edit
                            </span>
                          </Link>
                          {/* ----- edit icon end ------ */}
                          {/* ----- delete icon start ------ */}
                          <button
                            className="d-flex align-items-center gap-1 fs-md fw-semibold text-black border-0 bg-white"
                            // data-bs-toggle="modal"
                            // data-bs-target="#deletewarning"
                            onClick={() => {
                              onDeleteSkillPointBlock(index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </button>
                          {/* ----- delete icon end ------ */}
                        </div>
                        {/* ----- edit and delete icon section end ------ */}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* ----- skill point list section end ------ */}
            </div>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#addSkillPointModal"
              onClick={() => {
                setSkillPointIndex(null);
              }}
              className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
            >
              <span className="d-block material-symbols-outlined">add</span>
              <span className="d-block">{t("Add Skill Points")}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillPointAccordion;
