/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

import PrivacyOptions from "data/Prod/PrivacyOptions.json";
import SubscribeOptions from "data/Prod/SubscribeOptions.json";
import smtpSecureOptions from "data/Prod/smtpSecureOptions.json";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";

import {
  getData,
  uploadSingleFile,
  uploadMultipleFile,
  postData,
  putData,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";
import SavePrivacyModal from "../Modal/SavePrivacyModal";
import { appUserHeaderLinks } from "helper/AppUserHelper/AppUserHelper";
import { useTranslation } from "react-i18next";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";

const AppUserPrivacyBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];
  const { t } = useTranslation(); //for translation

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App User"), link: "/admin/appuser/list" },
    { title: t("Privacy") },
  ];

  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = appUserHeaderLinks(params.id, t);

  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");

  const [shareAccountValue, setShareAccountValue] = useState(null);
  const [shareContactValue, setshareContactValue] = useState(null);
  const [shareMailValue, setshareMailValue] = useState(null);
  const [sharePhoneValue, setsharePhoneValue] = useState(null);
  const [subscribeMailValue, setsubscribeMailValue] = useState(null);
  const [subscribeMailStatus, setSubscribeMailStatus] = useState("0");
  const [subscribeAcceptOn, setSubscribeAcceptOn] = useState("");
  const [subscribeDeninedOn, setSubscribeDeninedOn] = useState("");

  //out of field requirements
  const [telephone, setTelephone] = useState("");
  const [position, setPosition] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [website, setWebsite] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");

  const [isSaving, setisSaving] = useState(false);

  //function for get user details
  const getUserDetails = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}&userip=${userPublicIp}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setName(data.name || "");
        setSurName(data.surname || "");

        setTelephone(data.phone ?? "");
        setPosition(data.position ?? "");
        setAddress(data.address || "");
        setWebsite(data.website || "");
        setRate(data.costperhour || "");
        setstateName(data.state || "");
        setcityName(data.city || "");
        setcountry(data.country || "");
        setzipcode(data.zipcode || "");

        for (let privacy of PrivacyOptions) {
          if (privacy.value == data.shareaccount) {
            setShareAccountValue(privacy);
          }

          if (privacy.value == data.sharecontacts) {
            setshareContactValue(privacy);
          }

          if (privacy.value == data.sharemail) {
            setshareMailValue(privacy);
          }

          if (privacy.value == data.sharephone) {
            setsharePhoneValue(privacy);
          }
        }

        for (let subscribe of SubscribeOptions) {
          if (subscribe.value == data.subscribecommercialmail) {
            setsubscribeMailValue(subscribe);
            setSubscribeMailStatus(subscribe.value);
          }
        }

        // mail configuration -----------------
        if (data.smtphost && data.smtphost.length > 0) {
          setSmtpHost(data.smtphost);
        }
        if (data.smtpuser && data.smtpuser.length > 0) {
          setSmtpUser(data.smtpuser);
        }
        if (data.smtppass && data.smtppass.length > 0) {
          setSmtpPassword(data.smtppass);
        }
        if (data.smtpport && data.smtpport.length > 0) {
          setSmtpPort(data.smtpport);
        }

        for (let smtpSecureOption of smtpSecureOptions) {
          // set secure smtp connect option YES/NO
          if (smtpSecureOption.value == data.smtpsecure) {
            setSmtpSecure(smtpSecureOption);
          }

          // set either to use this config or not, YES / NO
          if (smtpSecureOption.value == data.usesmtpmail) {
            setUseSmtpConfig(smtpSecureOption);
            setUseSmtpConfigValue(smtpSecureOption.value);
          }

          setSubscribeAcceptOn(response.data.subscribeaccepton ?? "");
          setSubscribeDeninedOn(response.data?.subscribedeniedon ?? "");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select subscribe
  const subscribeSelectionHandler = (val) => {
    if (val) {
      setsubscribeMailValue(val);
      setSubscribeMailStatus(val.value);
      if (val.value === "0") {
        setSubscribeDeninedOn(todayValue);
      } else if (val.value === "1") {
        setSubscribeAcceptOn(todayValue);
      } else {
      }
    }
  };

  //function for save/update user
  const saveUserHandler = async () => {
    setisSaving(true);

    const userPublicIp = await getPublicIP();

    try {
      let userData = {
        position,
        phone: telephone,
        address,
        city: cityName,
        state: stateName,
        country,
        zipcode,
        costperhour: rate,
        website,

        shareaccount: shareAccountValue ? shareContactValue.value : "",
        sharemail: shareMailValue ? shareMailValue.value : "",
        sharephone: sharePhoneValue ? sharePhoneValue.value : "",
        sharecontacts: shareContactValue ? shareContactValue.value : "",
        subscribecommercialmail: subscribeMailStatus,
        subscribeaccepton: subscribeAcceptOn,
        subscribedeniedon: subscribeDeninedOn,
      };

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl =
          requestUrl +
          url.API_UPDATE_APP_USER +
          `/${params.id}` +
          `?token=${token}&userip=${userPublicIp}`;
        response = await putData(requestUrl, userData);
      }

      console.log(response);
      setisSaving(false);

      if (response.status) {
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("savePrivacyModal")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getUserDetails();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ---- expert details common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Privacy")}
          />
        )}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---- bread crumb ------- */}
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            bottom={true}
            displayName={`${name} ${surName}`}
          />

          {/* -------- table section ---------- */}

          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <h3>{t("Privacy Settings")}</h3>
            <div className="form-group pb-3 pb-sm-4 mb-3 mb-sm-4 border-bottom border-gray-300">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Who can see what you are looking for")}
                  </p>
                </div>
                <div className="col-lg-6">
                  <Select
                    options={PrivacyOptions}
                    value={shareAccountValue}
                    onChange={(val) => setShareAccountValue(val)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group pb-3 pb-sm-4 mb-3 mb-sm-4 border-bottom border-gray-300">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Who can see your mail")}
                  </p>
                </div>
                <div className="col-lg-6">
                  <Select
                    options={PrivacyOptions}
                    value={shareMailValue}
                    onChange={(val) => setshareMailValue(val)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group pb-3 pb-sm-4 mb-3 mb-sm-4 border-bottom border-gray-300">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Who can see your phone")}
                  </p>
                </div>
                <div className="col-lg-6">
                  <Select
                    options={PrivacyOptions}
                    value={sharePhoneValue}
                    onChange={(val) => setsharePhoneValue(val)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group pb-3 pb-sm-4 mb-3 mb-sm-4 border-bottom border-gray-300">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Who can see your contacts")}
                  </p>
                </div>
                <div className="col-lg-6">
                  <Select
                    options={PrivacyOptions}
                    value={shareContactValue}
                    onChange={(val) => setshareContactValue(val)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-3 mb-sm-4 ">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Subscribe to commercial emails")}
                  </p>
                </div>
                <div className="col-lg-6">
                  <Select
                    options={SubscribeOptions}
                    value={subscribeMailValue}
                    onChange={(val) => subscribeSelectionHandler(val)}
                  />
                  <div className="d-flex justify-content-end gap-2 mt-2">
                    {subscribeAcceptOn === "" ? null : (
                      <p className="mb-0 ">
                        {t("Subscribe accept on")}{" "}
                        <span className="fw-semibold">{subscribeAcceptOn}</span>
                      </p>
                    )}
                    {subscribeDeninedOn === "" ? null : (
                      <p className="mb-0">
                        {t("Subscribe denied on")}{" "}
                        <span className="fw-semibold">
                          {subscribeDeninedOn}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="action">
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveUserHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                {t("Save Settings")}
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* ========= popups and modals area =========== */}
      {/* -------- add label modal ------------ */}
      <SavePrivacyModal afterModalClose={getUserDetails} />

      {/* --------- filter modal ----------- */}
    </div>
  );
};

export default AppUserPrivacyBody;
