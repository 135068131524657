/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import LessonsFAQData from "../../../../../../../data/Courses/LessonsFAQData.json";
import CommonTabs from "../../CommonTabs/CommonTabs";
import FAQHeader from "../FAQHeader/FAQHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import AddFaqPopup from "../Popup/AddFaqPopup";

const FAQBody = () => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "Question / Answer",
        header: t("Question / Answer"),
        size: 800,
        Cell: ({ row }) => (
          <td className="question-answer border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.qa.question}
                </p>
                <p className="text-gray">{row.original.qa.answer}</p>
              </div>
            </div>
          </td>
        ),
      },
      {
        accessorKey: "number",
        header: t("Question Number"),
        Cell: ({ row }) => (
          <td className="question-number border-bottom-0">
            <p>{row.original.number}</p>
          </td>
        ),
      },
      {
        accessorKey: "Action",
        header: t("Action"),
        Cell: () => (
          <td className="action border-bottom-0">
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#addFaq"
              aria-controls="addFaq"
            >
              <span className="d-block">{t("Details")}</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info("rowSelection", rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="course-details-wrapper bg-white pb-5">
        {/* -------------- common tab ----------- */}
        {params.id && <CommonTabs activeTab="faq" />}
        <div className="container-fluid px-lg-5">
          {/* ----------- filter area ---------- */}
          <FAQHeader />

          {/* -------- table area ------------ */}
          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={LessonsFAQData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={(row) => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* -------- add label modal -------- */}
      <AddLabelModal moduleName="" />

      {/* ---------- add faq popup ----------- */}
      <AddFaqPopup />
    </div>
  );
};

export default FAQBody;
