/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const AddUpdateLabelPopup = ({
  moduleName,
  afterTagModalClose,
  tagId,
  setTagId,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token"); // token

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [labelName, setLabelName] = useState("");
  const [labelSlug, setLabelSlug] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [errorResponseMessage, setErrorResponseMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    labelWarning: false,
    slugWarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;
    if (labelName === "") {
      setValidation((prevState) => ({ ...prevState, labelWarning: true }));
      isValid = false;
    }
    if (labelSlug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //get tag details
  const getTagDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TAG_DETAILS +
        `/${tagId}` +
        `?token=${token}`;
      const response = await getData(requestUrl);
      // console.log(response);
      if (response.status) {
        setLabelName(response.data.title);
        setLabelSlug(response.data.slug);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for create new tag
  const saveTagHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        let tagData = {
          title: labelName,
          slug: labelSlug.toUpperCase(),
          isglobal:
            moduleName && moduleName === "administration" ? true : false,
        };
        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (tagId) {
          requestUrl =
            requestUrl + url.API_UPDATE_TAG + `/${tagId}` + `?token=${token}`;
          response = await putData(requestUrl, tagData);
        } else {
          requestUrl = requestUrl + url.API_CREATE_NEW_TAG + `?token=${token}`;
          response = await postData(requestUrl, tagData);
        }

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#addUpdateLabelOffCanvas")
          ).hide();
          resetHandler();
          afterTagModalClose();
        } else {
          setMessageType("error");
          setErrorResponseMessage(response.message);
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up all required fields");
      setMessageType("error");
    }
    setShowAlert(true);
  };
  /* reset */
  const resetHandler = () => {
    setLabelName("");
    setLabelSlug("");
    setValidation({
      labelWarning: false,
      slugWarning: false,
    });
    setTagId(null);
    setErrorResponseMessage("");
  };

  useEffect(() => {
    if (tagId) {
      getTagDetails();
    }
  }, [tagId]);
  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="addUpdateLabelOffCanvas"
      aria-labelledby="offcanvasLabelDetailsLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Label Details")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Label Name")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={labelName}
                onChange={(e) => {
                  setLabelName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    labelWarning: false,
                  }));
                  setErrorResponseMessage("");
                }}
              />
              {/* level warning */}
              {validation.labelWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter label")}!</span>
                  </p>
                </div>
              )}
            </div>
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={labelSlug}
                onChange={(e) => {
                  setLabelSlug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                  setErrorResponseMessage("");
                }}
              />
              {/* slug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}

              {errorResponseMessage === "" ? null : (
                <div className="error-message my-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{errorResponseMessage}!</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveTagHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddUpdateLabelPopup;
