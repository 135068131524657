/* eslint-disable */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import images
import { assetImages } from 'constants';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import { eventDetailsHeaderLinks } from 'helper/EventHelper/EventHelper';

import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import EventPartnerHeader from '../Header/EventPartnerHeader';
import AddNewPartnerModal from '../Modal/AddNewPartnerModal';
import PartnerDetailsPopup from '../Popup/PartnerDetailsPopup';
import { useTranslation } from 'react-i18next';

const EventPartnerBody = () => {
  const { t, i18n } = useTranslation(); //for translation
  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  // -------- tabs header object --------------------
  const commonHeaderObject = eventDetailsHeaderLinks(params.id, t);

  // const { screenHeight } = useContext(GlobalProvider);
  const [parentEventTitle, setParentEventTitle] = useState('');

  const [roleList, setRoleList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);

  const [partnerId, setPartnerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isEventModerator, setisEventModerator] = useState(false);

  const [eventMemberStatus, setEventMemberStatus] = useState('');
  const [eventModStatus, setEventModStatus] = useState('');

  const [isEventDetailsLoading, setIsEventDetailsLoading] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_MEMBER_DETAILS_BY_QUERY +
        `?token=${token}&eventid=${params.id}`;

      const response = await getData(requestUrl);

      console.log('response in member details', response);

      if (response.status) {
        setEventMemberStatus(response.data.memberstatus);
        setEventModStatus(response.data.moderatorstatus);
        setinviteDate(response.data.requestdate);
        setJoinDate(response.data.joindate);
      } else {
        setAlertMessage(response.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get event details
  const getEventDetails = async () => {
    setIsEventDetailsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${params.id}?token=${token}`;

      console.log('url of event details------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response of event details------>', response);

      if (response.status) {
        //assign title
        response.data?.title && setParentEventTitle(response.data?.title);

        let isModerator = false;

        if (
          response.data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN'
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisEventModerator(isModerator);

        setIsEventDetailsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all roles
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLES +
        `?token=${token}&slug=MOD_EVENT`;

      const response = await getData(requestUrl);

      if (response.status) {
        setRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllEventPartners = async () => {
    setRowSelection({});
    setSelectedPartnerIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENT_PARTNERS +
        `?token=${token}&event=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);
      setIsLoading(false);
      if (response.status) {
        setPartnerList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async e => {
    e.preventDefault();

    let responseArr = [];

    for (let memberId of selectedPartnerIds) {
      try {
        let eventData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_EVENT_MEMBER +
          `/${memberId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, eventData);

        if (response.status) {
          responseArr.push(response.status);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllEventPartners();
  };

  useEffect(() => {
    if (params.id) {
      getEventDetails();
      getAllEventPartners();
    }
  }, [params.id]);

  useEffect(() => {
    getAllRoles();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),

        Cell: ({ row }) => {
          if (row.original.role === 'Moderator') {
            return (
              <button className="action_btn_mui">
                <span className="d-block material-symbols-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          } else {
            return (
              <button
                className="action_btn_mui"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasPartnerDetails"
                aria-controls="offcanvasPartnerDetails"
                onClick={() => {
                  setPartnerId(row.original._id);
                }}
              >
                <span className="d-block material-symbols-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          }
        },
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'name',
        header: t('Name'),
        size: 150,
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '35px', height: '35px' }}
              >
                <img
                  src={
                    row.original.image == ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.image
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">{row.original.name}</p>
                <Link to="#" className="d-block lh-1">
                  {row.original.email}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'role',
        header: t('Role'),
      },
      {
        accessorKey: 'moderatorstatus',
        header: t('Status'),
        size: 200,
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <ul className="d-flex flex-wrap gap-3">
              {row.original.moderatorstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold"></span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.moderatorstatus == '1'
                        ? 'text-success'
                        : row.original.moderatorstatus == '0'
                        ? 'text-primary'
                        : 'text-gray'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Moderator</span>
                </li>
              )}
              {row.original.memberstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold">--</span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.memberstatus == '1'
                        ? 'text-success'
                        : row.original.memberstatus == '0'
                        ? 'text-gray'
                        : 'text-warning'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Member</span>
                </li>
              )}
            </ul>
          </div>
        ),
      },
      {
        accessorKey: 'requestdate',
        header: t('Date'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Labels'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      // {
      //   accessorKey: "#",
      //   header: t("Action"),
      //   Cell: ({ row }) => (
      //     <div className="action border-bottom-0">
      //       {" "}
      //       {row.original.role == "Moderator" ? (
      //         <Link to="#">-</Link>
      //       ) : (
      //         <Link
      //           to="#offcanvasMemberDetails"
      //           className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
      //           type="button"
      //           data-bs-toggle="offcanvas"
      //           data-bs-target="#offcanvasPartnerDetails"
      //           aria-controls="offcanvasPartnerDetails"
      //           onClick={() => {
      //             setPartnerId(row.original._id);
      //           }}
      //         >
      //           <span className="d-block">Details</span>
      //           <span className="d-block material-symbols-outlined">
      //             chevron_right
      //           </span>
      //         </Link>
      //       )}
      //     </div>
      //   ),
      // },
    ],
    [partnerList, i18n.language]
  );

  const initialColumnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedPartnerIds(selectedIdsArray);
    } else {
      setSelectedPartnerIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_EVENT')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Partners')}
          />
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            <EventPartnerHeader
              isEventModerator={isEventModerator}
              parentEventTitle={parentEventTitle}
              reloadEventMemberList={getAllEventPartners}
              changeStatusHandler={changeStatusHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isEventDetailsLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isEventModerator ? (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={partnerList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }} // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem
                  //     key="edit"
                  //     //onClick={() => console.info(row.id)}
                  //   >
                  //     <Link
                  //       className="dropdown-item"
                  //       to={`/admin/events/info/${row.id}`}
                  //     >
                  //       View Details
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            ) : (
              <div className="table-wrapper">
                {eventMemberStatus === '1' && eventModStatus === '1' ? (
                  <div className="project-members-container mb-4">
                    <h4 className="fs-sm mb-2 fw-semibold">
                      {t('Event Partners')}
                    </h4>
                    <div className="row">
                      {partnerList.map((member, index) => {
                        return (
                          <div className="col-lg-3 col-sm-6 mb-2" key={index}>
                            <div className="member-box p-3 rounded-5 bg-white d-flex gap-2 align-items-center">
                              <div class="members-pic d-flex align-items-center justify-content-center overflow-hidden body-bg rounded-circle">
                                <img
                                  src={
                                    member.image === ''
                                      ? assetImages.defaultUser
                                      : url.SERVER_URL + member.image
                                  }
                                  alt=""
                                  className="h-100 w-100 object-fit-cover"
                                />
                              </div>
                              <div class="members-info">
                                <h3 class="fs-lg fw-semibold text-black mb-0">
                                  {member.name}
                                </h3>
                                <h5 class="fs-sm mb-0 text-black">
                                  {member.role}
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : eventMemberStatus === '' &&
                  eventMemberStatus === '' ? null : (
                  <div className="team_members mb-4">
                    <div className="challenges_empty text-center">
                      <div className="empty_pic mb-4">
                        {' '}
                        <img src={assetImages.emptyVector} alt="" />
                      </div>
                      <div className="empty_text">
                        <p className="fs-lg text-gray fw-semibold mb-4">
                          {t(
                            "Sorry....! You don't have privilege to see this content"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* ---- list section end---- */}
          </div>

          {/* === popup and modal section === */}
          {/* --- add label modal --- */}
          <AddLabelModal
            moduleName="eventmemeber"
            afterTagModalClose={getAllEventPartners}
            selectedIds={selectedPartnerIds}
          />

          {/* --- add new member modal --- */}
          <AddNewPartnerModal
            roleList={roleList}
            afterCloseModalHandler={getAllEventPartners}
          />

          {/* ---- member details popup ---- */}
          <PartnerDetailsPopup
            eventId={params.id}
            roleList={roleList}
            partnerId={partnerId}
            setPartnerId={setPartnerId}
            afterPopupClose={getAllEventPartners}
          />
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default EventPartnerBody;
