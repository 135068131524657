/* eslint-disable */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import images
import { assetImages } from 'constants';

//import components
import SaveEcosystemPopup from '../Popup/SaveEcosystemPopup';
import EcosystemFilterPopup from '../Popup/EcosystemFilterPopup';
import EcosystemHeader from '../Header/EcosystemHeader';
import { useTranslation } from 'react-i18next';

const EcosystemBody = () => {
  const token = localStorage.getItem('token');
  const savedEcosystemId = localStorage.getItem('ecosystem');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { t, i18n } = useTranslation(); //for translation
  const { screenHeight } = useContext(GlobalProvider);

  const [ecosystemList, setEcosystemList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [ecosystemId, setEcosystemId] = useState(null);

  //filter requiremnts
  const [filterModuleName, setFilterModuleName] = useState('');
  const [filterModuleSlug, setFilterModuleSlug] = useState('');
  const [filterDateRange, setFilterDateRange] = useState('');

  //material table column states
  const [rowSelection, setRowSelection] = useState({});
  const [selectedEcosystemIds, setSelectedEcosystemIds] = useState([]);

  //* Function for get all modules
  const getAllEcosystems = async () => {
    setRowSelection({});
    setSelectedEcosystemIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_ECOSYSTEM + `?token=${token}`;

      if (filterDateRange != '') {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }

      if (filterModuleName !== '') {
        requestUrl = requestUrl + `&filtername=${filterModuleName}`;
      }

      if (filterModuleSlug !== '') {
        requestUrl = requestUrl + `&filterslug=${filterModuleSlug}`;
      }

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setEcosystemList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* Function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedEcosystemIds) {
      try {
        let tagData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_ECOSYSTEM +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, tagData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllEcosystems();
  };

  //switch ecosystem
  const switchEcosystemHandler = async (ecosystemId, slug) => {
    try {
      let ecosystemData = {
        ecosystemid: ecosystemId,
        ecosystemslug: slug,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_SWITCH_ECOSYSTEM + `?token=${token}`;

      const response = await postData(requestUrl, ecosystemData);

      console.log(response);

      if (response.status) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('userInfo', JSON.stringify(response.data));
        localStorage.setItem('userID', JSON.stringify(response.data._id));
        localStorage.setItem('ecosystem', response.ecosystemid);
        localStorage.setItem('ecosystemslug', response.ecosystemslug);
        localStorage.setItem('ecosystemlogo', response.ecosystemlogo);
        localStorage.setItem('moduleaccess', response.data.moduleaccessdata);

        window.location.reload();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterModuleName('');
    setFilterModuleSlug('');
  };

  //* Material React Table Column and States
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        Cell: ({ renderedCellValue, row }) => (
          <div className="d-flex align-content-center gap-2">
            <span>{row.original.name}</span>
            {row.original._id === savedEcosystemId ? (
              <span className="icon-md material-symbols-outlined text-success">
                check_circle
              </span>
            ) : null}
          </div>
        ),
      },
      {
        accessorKey: '*',
        header: t('Logo'),
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            <img
              height={30}
              width={100}
              src={
                row.original.logopath == ''
                  ? assetImages.blankLogo
                  : url.SERVER_URL + row.original.logopath
              }
              loading="lazy"
              style={{ borderRadius: '5%' }}
            />
          </Box>
        ),
      },
      {
        accessorKey: 'slug',
        header: t('Slug'),
      },
      {
        accessorKey: 'createddate',
        header: t('Created Date'),
      },
      {
        accessorKey: 'feturedstring',
        header: t('Featured'),
      },
      {
        accessorKey: 'isdefaultstring',
        header: t('Is Default'),
      },
      {
        accessorKey: '#',
        header: t('Details'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            <Link
              to="#saveEcosystem"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              data-bs-toggle="offcanvas"
              role="button"
              aria-controls="saveEcosystem"
              onClick={() => {
                setEcosystemId(row.original._id);
              }}
            >
              <span className="d-block">{t('Details')}</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              onClick={() =>
                switchEcosystemHandler(row.original._id, row.original.slug)
              }
            >
              <span className="d-block">{t('Switch Ecosystem')}</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  //useEffect for get all ecosystem by filter
  useEffect(() => {
    if (filterModuleName != '' || filterModuleSlug != '') {
      getAllEcosystems();
    }
  }, [filterModuleName, filterModuleSlug]);

  //useEffect for get all ecosystem initially
  useEffect(() => {
    getAllEcosystems();
  }, []);

  //useEffect for row selection
  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedEcosystemIds(selectedIdsArray);
    } else {
      setSelectedEcosystemIds([]);
    }
  }, [rowSelection]);

  //* Check logged in user is admin or super admin
  if (userInfo.role.slug === 'ADMIN' || userInfo.role.slug === 'SUPER_ADMIN') {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <EcosystemHeader
              reloadList={getAllEcosystems}
              changeStatusHandler={changeStatusHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={ecosystemList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== popup and modal section ==== */}
        <SaveEcosystemPopup
          afterPopupCLose={getAllEcosystems}
          ecosystemId={ecosystemId}
          setEcosystemId={setEcosystemId}
        />

        <EcosystemFilterPopup
          setFilterModuleName={setFilterModuleName}
          setFilterModuleSlug={setFilterModuleSlug}
          setFilterDateRange={setFilterDateRange}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default EcosystemBody;
