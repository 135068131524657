import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import EcosystemBody from "components/AdministrationComponents/EcosystemComponents/EcosystemBody/EcosystemBody";

const Ecosystem = () => {
  useEffect(() => {
    document.title = "Administration | Ecosystem";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <EcosystemBody />
    </main>
  );
};

export default Ecosystem;
