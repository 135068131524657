/* eslint-disable */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import AddLabelModal from 'components/Common/Modal/AddLabelModal';

//import images
import { assetImages } from 'constants';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';

//import common header objects
import { crmCommonHeaderLinkLists } from 'helper/CrmHelper/CrmHelper';

import ConversationNewMailPopup from 'components/Common/Popup/ConversationNewMailPopup';
import AddToListModal from 'components/Common/Modal/AddToListModal';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import AddAlertModal from 'components/Common/Modal/AddAlertModal';
import AddFollowerModal from 'components/Common/Modal/AddFollowerModal';

import EcoLeadHeader from './Header/EcoLeadHeader';
import EcoLeadFilterPopup from './Popup/EcoLeadFilterPopup';
import PurchaseWarningModal from './Modal/PurchaseWarningModal';

const EcoLeadBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const { screenHeight } = useContext(GlobalProvider);

  const moduleAccess = localStorage.getItem('moduleaccess');
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const ecosystemSlug = localStorage.getItem('ecosystemslug');

  const [isLoading, setIsLoading] = useState(false);
  const [leadsDataList, setleadsDataList] = useState([]);
  const [filterStartDate, setfilterStartDate] = useState('');
  const [filterEndDate, setfilterEndDate] = useState('');
  const [filterOnwers, setfilterOnwers] = useState([]);
  const [filterCountry, setfilterCountry] = useState([]);
  const [filterPosition, setfilterPosition] = useState([]);
  const [filterEmployees, setfilterEmployees] = useState([]);
  const [filterBillings, setfilterBillings] = useState([]);
  const [filterSearchings, setfilterSearchings] = useState([]);
  const [filterCosts, setfilterCosts] = useState([]);
  const [filterComments, setfilterComments] = useState([]);
  const [filterSectors, setfilterSectors] = useState([]);
  const [filterTags, setfilterTags] = useState([]);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedLeadIds, setSelectedLeadIds] = useState([]);
  const [selectLeadMails, setSelectLeadMails] = useState('');

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  const [selectedLeadId, setSelectedLeadId] = useState(null);

  const [isPurchasing, setIsPurchasing] = useState(false);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  //function for get all lead records
  const getAllEcoLeads = async isFilter => {
    setRowSelection({});
    setSelectedLeadIds([]);
    setSelectLeadMails('');
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_ECO_LEADS + `?token=${token}`;

      if (filterStartDate !== '' && filterEndDate !== '') {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterCountry.length > 0) {
        requestUrl += `&filtercountry=${filterCountry}`;
      }

      if (filterPosition.length > 0) {
        requestUrl += `&filterposition=${filterPosition}`;
      }

      if (filterEmployees.length > 0) {
        requestUrl += `&filteremployees=${filterEmployees}`;
      }

      if (filterBillings.length > 0) {
        requestUrl += `&filterbillings=${filterBillings}`;
      }

      if (filterSearchings.length > 0) {
        requestUrl += `&filtersearchings=${filterSearchings}`;
      }

      if (filterCosts.length > 0) {
        requestUrl += `&filtercosts=${filterCosts}`;
      }

      if (filterComments.length > 0) {
        requestUrl += `&filtercomments=${filterComments}`;
      }

      if (filterSectors.length > 0) {
        requestUrl += `&filtersectors=${filterSectors}`;
      }

      if (filterTags.length > 0) {
        requestUrl += `&filtertags=${filterTags}`;
      }

      if (filterOnwers.length > 0) {
        requestUrl += `&filterowners=${filterOnwers}`;
      }

      requestUrl += `&isfilter=${isFilter}`;

      console.log('url of lead list---------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in lead list------->', response);

      if (response.status) {
        setleadsDataList(response.data);
        setMessageType('success');
      } else {
        setMessageType('error');
      }
      setAlertMessage(response.message);

      setIsLoading(false);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedLeadIds) {
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_LEAD +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl);

        if (response.status) {
          setMessageType('success');
        } else {
          setMessageType('error');
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
      }
      setShowAlert(true);
    }
    getAllEcoLeads(false);
  };

  //function for purchase lead
  const purchaseLeadHandler = async () => {
    if (selectedLeadId) {
      try {
        setIsPurchasing(true);

        let leadData = {
          leadid: selectedLeadId,
        };

        let requestUrl =
          url.API_BASE_URL + url.API_PURCHASE_LEAD + `?token=${token}`;

        const response = await postData(requestUrl, leadData);

        setIsPurchasing(false);

        if (response.status) {
          //hide member modal
          let bootstrapModal = document.querySelector('#purchaseWarningModal');
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          setSelectedLeadId(null);
          setMessageType('success');
          setAlertMessage(response.message);
          setShowAlert(true);

          setTimeout(() => {
            getAllEcoLeads();
          }, 2500);
        } else {
          setMessageType('error');
          setAlertMessage(response.message);
          setShowAlert(true);
        }
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } else {
      setAlertMessage(t('Please select a ecolead'));
      setMessageType('error');
      setShowAlert(true);
    }
  };

  const purchaseCancelHandler = () => {
    setSelectedLeadId(null);
    //hide member modal
    let bootstrapModal = document.querySelector('#purchaseWarningModal');
    let modal = bootstrap.Modal.getInstance(bootstrapModal);
    modal.hide();
  };

  //function for reset filter
  const resetFilterData = () => {
    setfilterStartDate('');
    setfilterEndDate('');
    setfilterCountry([]);
    setfilterPosition([]);
    setfilterEmployees([]);
    setfilterBillings([]);
    setfilterSearchings([]);
    setfilterCosts([]);
    setfilterComments([]);
    setfilterSectors([]);
    setfilterTags([]);
    setfilterOnwers([]);

    setReloadData(true);

    // reset pagination to default
    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedLeadIds([]);
    setSelectLeadMails('');
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: '#',
      header: t('Actions'),
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#purchaseWarningModal"
          aria-controls="purchaseWarningModal"
          onClick={() => {
            setSelectedLeadId(row.original._id);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: 'ownername',
      header: t('Owner'),
      size: 200,
      Cell: ({ row }) => (
        <div className="d-flex align-items-center gap-2">
          {row.original.ownername}
          {row.original.ispurchased ? (
            <span className="d-block material-symbols-outlined icon-sm text-success">
              check_circle
            </span>
          ) : null}
        </div>
      ),
    },
    {
      accessorKey: 'country',
      header: t('Country'),
    },
    {
      accessorKey: 'position',
      header: t('Position'),
    },
    {
      accessorKey: 'sectornames',
      header: t('Sector'),
    },
    {
      accessorKey: 'employees',
      header: t('Employees'),
    },
    {
      accessorKey: 'billing',
      header: t('Billing'),
    },
    {
      accessorKey: 'searching',
      header: t('Searching my lead'),
    },
    {
      accessorKey: 'costofinterview',
      header: t('Cost'),
    },
    {
      accessorKey: 'comments',
      header: t('Comments'),
    },
    {
      accessorKey: 'opportunitycount',
      header: t('Opportunities'),
    },
    {
      accessorKey: 'totalowncount',
      header: t('Won'),
    },
    {
      accessorKey: 'totallostcount',
      header: t('Lost'),
    },
    {
      accessorKey: 'totalamount',
      header: t('Amount'),
    },
    {
      accessorKey: 'tagnames',
      header: t('Label'),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagnames != '' && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagnames.split(' , ').map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  /**
   * Updates the column visibility state by toggling the visibility of the column with the given accessor key.
   *
   * @param {function} newColumnState - A function that returns the accessor key of the column whose visibility is being changed.
   */
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /**
   * Represents the state of column visibility in the table.
   */
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  /**
   * Updates the column visibility state based on the new column state.
   *
   * @param {function} newColumnState - A function that returns the new column state.
   * @return {void} This function does not return anything.
   */
  const onColumnVisiblityHandler = newColumnState => {
    const newColumnStateName = newColumnState();
    setVisibleColoumns(prev => ({ ...prev, ...newColumnStateName }));
  };

  //initialize the column order
  const initialColumnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ];

  //array of column ids (Initializing is optional as of v2.10.0)
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = changedOrder => {
    setColumnOrder(changedOrder);
  };

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      const selectedLeadIdArr = selectedIdsArray.map(id => {
        const [leadId] = id.split('-');
        return leadId;
      });
      const selectLeadEmails = selectedIdsArray.map(id => {
        const [, leadMail] = id.split('-');
        return leadMail;
      });
      const selectedLeadEmailString = selectLeadEmails.join(',');
      setSelectedLeadIds(selectedLeadIdArr);
      setSelectLeadMails(selectedLeadEmailString);
    } else {
      setSelectedLeadIds([]);
      setSelectLeadMails('');
    }
  }, [rowSelection]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (
      (filterStartDate !== '' && filterEndDate !== '') ||
      filterOnwers.length > 0 ||
      filterCountry.length > 0 ||
      filterPosition.length > 0 ||
      filterEmployees.length > 0 ||
      filterBillings.length > 0 ||
      filterSearchings.length > 0 ||
      filterCosts.length > 0 ||
      filterComments.length > 0 ||
      filterSectors.length > 0 ||
      filterTags.length > 0
    ) {
      getAllEcoLeads(true);
    }
  }, [
    filterStartDate,
    filterEndDate,
    filterOnwers,
    filterCountry,
    filterPosition,
    filterEmployees,
    filterBillings,
    filterSearchings,
    filterCosts,
    filterComments,
    filterSectors,
    filterTags,
  ]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getAllEcoLeads(false);
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllEcoLeads(false);
      setReloadData(false);
    }
  }, [reloadData]);

  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    (moduleAccess.includes('MOD_CRM') && moduleAccess.includes('MOD_ECOLEADS'))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('EcoLeads')}
          />
          <div className="container-fluid px-lg-5">
            <EcoLeadHeader
              reloadLeadList={refreshRecords}
              changeStatusHandler={changeStatusHandler}
            />

            {/* ----- lead list table and pagination section start ----- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={leadsDataList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => `${row._id}-${row.email}`} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                  }}
                  enableColumnOrdering={true}
                  onColumnVisibilityChange={onColumnVisiblityHandler}
                  onColumnOrderChange={changeColumnOrderHandler}
                  // enableRowActions
                  // renderRowActionMenuItems={({ row }) => {
                  //   const [leadId] = row.id.split("-"); // Extracts the _id part
                  //   return [
                  //     <MenuItem key="buy">
                  //       <Link
                  //         className="dropdown-item"
                  //         to="#"
                  //         onClick={() => {
                  //           setSelectedLeadId(leadId);
                  //         }}
                  //       >
                  //         {t("Buy this lead")}
                  //       </Link>
                  //     </MenuItem>,
                  //     <MenuItem key="connect">
                  //       <Link className="dropdown-item" to="#">
                  //         {t("Connect to Owner")}
                  //       </Link>
                  //     </MenuItem>,
                  //   ];
                  // }} // action columns menu items
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
            {/* ----- lead list table and pagination section start ----- */}
          </div>
          {/* ----- all pop up render here ----- */}
          <EcoLeadFilterPopup
            moduleName={'crmecolead'}
            visibleColoumns={visibleColoumns}
            setVisibleColoumns={setVisibleColoumns}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            filterStartDate={filterStartDate}
            setfilterStartDate={setfilterStartDate}
            filterEndDate={filterEndDate}
            setfilterEndDate={setfilterEndDate}
            filterOnwers={filterOnwers}
            setFilterOnwers={setfilterOnwers}
            filterCountry={filterCountry}
            setFilterCountry={setfilterCountry}
            filterPosition={filterPosition}
            setFilterPosition={setfilterPosition}
            filterEmployees={filterEmployees}
            setfilterEmployees={setfilterEmployees}
            filterBillings={filterBillings}
            setFilterBillings={setfilterBillings}
            filterSearchings={filterSearchings}
            setFilterSearchings={setfilterSearchings}
            filterCosts={filterCosts}
            setFilterCosts={setfilterCosts}
            filterComments={filterComments}
            setFilterComments={setfilterComments}
            filterSectors={filterSectors}
            setFilterSectors={setfilterSectors}
            filterTags={filterTags}
            setFilterTags={setfilterTags}
            isFilterReset={isFilterReset}
            setIsFilterReset={setIsFilterReset}
            setIsNoDefaultFilter={setIsNoDefaultFilter}
          />
          {/* ------ add label modal render here ----- */}
          <AddLabelModal
            selectedIds={selectedLeadIds}
            moduleName="crmleadlist"
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllEcoLeads(false);
              }, 2500);
            }}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ------ new mail popup render here ----- */}
          <ConversationNewMailPopup
            contactPersonEmail={selectLeadMails}
            reloadList={afterEmailClose}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ------ add to list modal render here ----- */}
          <AddToListModal
            moduleName="lead"
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
            selectedIds={selectedLeadIds}
            afterModalClose={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
          />

          <AddFollowerModal
            moduleName="crmleadlist"
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
            selectedIds={selectedLeadIds}
            afterModalClose={() => {
              setTimeout(() => {
                getAllEcoLeads(false);
              }, 2500);
            }}
          />

          <AddAlertModal
            selectedIds={selectedLeadIds}
            afterModalClose={() => {
              setSelectedLeadIds([]);
              setRowSelection({});
            }}
            moduleName={'lead'}
          />

          <PurchaseWarningModal
            isPurchasing={isPurchasing}
            onPurchase={purchaseLeadHandler}
            onCancelPurchase={purchaseCancelHandler}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default EcoLeadBody;
