/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const CrmContactDetailsCommonHeader = ({
  moduleName,
  contactName,
  contactImage,
}) => {
  // loggedin user details to get the role
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // get the role and allow for admin or superadmin
  const currentUserRole = userInfo.role ? userInfo.role.slug : "USER";

  // get module access for roles other than admin and superadmin
  const moduleaccess = localStorage.getItem("moduleaccess");

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); // for translations
  const params = useParams();

  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid d-flex align-items-center gap-2 gap-sm-4 px-lg-5">
        <Link
          to="/admin/crm/contact/list"
          className="back-btn d-flex flex-shrink-0 align-items-center justify-content-center body-bg text-gray rounded-circle"
        >
          <span className="d-block material-symbols-outlined">arrow_back</span>
        </Link>
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-lg-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="profile d-flex align-items-center gap-2 text-nowrap">
            <div
              className="avatar rounded-circle-overflow-hidden"
              style={{ width: "38px", height: "38px" }}
            >
              <img
                src={
                  contactImage && contactImage !== ""
                    ? url.SERVER_URL + contactImage
                    : assetImages.defaultUser
                }
                alt="user"
                className="rounded-circle w-100 h-100 object-fit-cover object-center"
              />
            </div>
            <h3 className="mb-0">{contactName}</h3>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/crm/contact/details/timeline/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName == "timeline" ? "active" : ""
              }`}
            >
              {t("Timeline")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/crm/contact/details/task/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName == "task" ? "active" : ""
              }`}
            >
              {t("Task")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/crm/contact/details/recievedmail/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName == "mail" ? "active" : ""
              }`}
            >
              {t("Mail")}
            </Link>
          </li>

          {/* <!-- ========== Start opprtunity Section ========== --> */}
          {currentUserRole === "ADMIN" ||
          currentUserRole === "SUPER_ADMIN" ||
          moduleaccess.includes("MOD_OPPORTUNITY") ? (
            <li className="nav-item">
              <Link
                to={`/admin/crm/contact/details/opportunities/${params.id}`}
                className={`d-block text-black px-3 py-4 position-relative ${
                  moduleName == "opportunity" ? "active" : ""
                }`}
              >
                {t("Opportunities")}
              </Link>
            </li>
          ) : null}
          {/* <!-- ========== End opprtunity Section ========== --> */}
        </ul>
      </div>
    </div>
  );
};
export default CrmContactDetailsCommonHeader;
