import Header from 'components/Common/Header/Header';
import GroupProductVitalInfoBody from 'components/GroupComponents/GroupDetails/GroupProduct/GroupProductDetails/GroupProductVitalInfo/GroupProductVitalInfoBody/GroupProductVitalInfoBody';
import React from 'react';

const GroupProductVitalInfo = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ----------- body section ------------ */}
      <GroupProductVitalInfoBody />
    </>
  );
};

export default GroupProductVitalInfo;
