import Header from "components/Common/Header/Header";
import CourseTeamBody from "components/CourseComponents/CourseDetails/CourseTeamComponents/CourseTeamBody/CourseTeamBody";
import React, { useEffect } from "react";

const CourseTeams = () => {
  useEffect(() => {
    document.title = "Course Teams";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CourseTeamBody />
    </main>
  );
};

export default CourseTeams;
