import Header from "components/Common/Header/Header";
import CustomerQueriesBody from "components/CourseComponents/CourseDetails/CourseLessonComponents/DetailsComponents/CustomerQueriesComponents/CustomerQueriesBody/CustomerQueriesBody";
import React from "react";

const CourseLessonsDetailsCustomerQueries = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CustomerQueriesBody />
    </main>
  );
};

export default CourseLessonsDetailsCustomerQueries;
