import Header from "components/Common/Header/Header";
import CourseSubscriberBody from "components/CourseComponents/CourseDetails/CourseSubscriberComponents/CourseSubscriberBody/CourseSubscriberBody";
import React, { useEffect } from "react";

const CourseSubscriber = () => {
  useEffect(() => {
    document.title = "Course Subscribers";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CourseSubscriberBody />
    </main>
  );
};

export default CourseSubscriber;
