import { assetImages } from "constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DeleteModal = ({ onDelete, onCancelDelete }) => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="modal fade"
      id="eventGallerydeleteModal"
      tabIndex="-1"
      aria-labelledby="eventGallerydeleteModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="Are you sure you want to delete this item?"
              className="img-fluid"
            />
            <h2>{t("Are you sure you want to delete this item")}?</h2>
            <div className="d-flex gap-3 justify-content-center">
              <Link
                to="#"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onDelete}
              >
                {t("Yes Delete")}
              </Link>
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCancelDelete}
              >
                {t("No, Cancel")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
