/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

import BreadCrumb from "../BreadCrumb/BreadCrumb";
import AlertNotification from "../AlertNotification/AlertNotification";
import SuccessProcessModal from "../Modal/SuccessProcessModal";
import ProcessModuleOptions from "data/Prod/ProcessModuleOptions.json";

const ActivityProcessForm = ({
  afterModalClose,
  breadcrumbText,
  isTemplate = false,
  moduleName = "",
  moduleslug = "",
}) => {
  const params = useParams();

  // console.log("moduleName------->", moduleName);

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [followersInput, setFollowersInput] = useState("");
  const [folowersOptions, setFollowersOptions] = useState([]);

  const [tagList, setTagList] = useState([]);

  const [title, settitle] = useState("");
  const [slug, setslug] = useState("");
  const [details, setdetails] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [selectedTags, setselectedTags] = useState([]);
  const [followersValue, setfollowersValue] = useState(null);
  const [selectedFollowers, setselectedFollowers] = useState([]);
  const [moduleValue, setmoduleValue] = useState(null);
  const [selectedModule, setSelectedModule] = useState("");
  const [isProcessOwner, setisProcessOwner] = useState(false);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    slugWarning: false,
    // tagWarning: false,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }
    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    // if (selectedTags.length === 0) {
    //   setValidation((prevState) => ({ ...prevState, tagWarning: true }));
    //   isValid = false;
    // }

    return isValid;
  };

  //function for get followers input
  const followersInputHandler = (val) => {
    setFollowersInput(val);
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TAGS +
        `?token=${token}&modulename=${moduleName}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get followers list
  const getFollowersList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${followersInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setFollowersOptions(response.data);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //select tags
  const tagSelectionHandler = (val) => {
    if (val) {
      settagValue(val);
      setselectedTags(
        val.map((item) => ({
          tagid: item.value,
          componentorderid: item?.componentorderid ?? null,
        }))
      );
    } else {
      settagValue(null);
      setselectedTags([]);
    }
  };

  //select followers
  const followersSelectionHandler = (val) => {
    setfollowersValue(val);
    setselectedFollowers(val.map((item) => item.value));
  };

  //select module
  const moduleSelectionHandler = (val) => {
    if (val) {
      setmoduleValue(val);
      setSelectedModule(val.value);
    } else {
      setmoduleValue(null);
      setSelectedModule("");
    }
  };

  //get process details
  const getProcessDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_PROCESS_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status && response.data) {
        settitle(response.data.title);
        setslug(response.data.slug);
        setdetails(response.data?.description ?? "");
        if (response.data.tagvalues.length > 0) {
          tagSelectionHandler(response.data.tagvalues);
        }

        if (response.data.followersArr) {
          followersSelectionHandler(response.data.followersArr);
        }

        for (let moduleData of ProcessModuleOptions) {
          if (moduleData.value === response.data.moduleslug) {
            moduleSelectionHandler(moduleData);
          }
        }

        setisProcessOwner(response.data.isuserprocessowner);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //save process
  const saveProcessHandler = async () => {
    if (validate()) {
      seterrorMessage("");

      try {
        setIsSaving(true);

        let processData = {
          title,
          slug: slug.toUpperCase(),
          description: details,
          moduleslug: moduleslug === "" ? selectedModule : moduleslug,
          istemplate: isTemplate,
          tags: selectedTags,
          followers: selectedFollowers,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestURL += url.API_UPDATE_PROCESS + `/${params.id}?token=${token}`;

          response = await putData(requestURL, processData);
        } else {
          requestURL += url.API_ADD_NEW_PROCESS + `?token=${token}`;

          response = await postData(requestURL, processData);
        }

        setIsSaving(false);

        // console.log(response);

        if (response.status) {
          setMessageType("success");
          resetHandler();

          setTimeout(() => {
            const bootstrapModal = new bootstrap.Modal(
              document.getElementById("afterSaveProcessModal")
            );

            bootstrapModal.show();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      seterrorMessage(t("Please fill up required fields"));
      setMessageType("error");
      setAlertMessage(t("Please fill up required fields"));
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setFollowersInput("");
    setFollowersOptions([]);
    settitle("");
    setslug("");
    setdetails("");
    settagValue(null);
    setselectedTags([]);
    setfollowersValue(null);
    setselectedFollowers([]);
    setmoduleValue(null);
    setSelectedModule("");
    setValidation({
      titleWarning: false,
      slugWarning: false,
      tagWarning: false,
    });
  };

  useEffect(() => {
    if (params.id) {
      getProcessDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    if (followersInput.length > 0) {
      getFollowersList();
    }
  }, [followersInput]);

  const followersOptionsToShow =
    folowersOptions.length > 0 ? folowersOptions : [];

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  return (
    <div className="container-fluid px-lg-5">
      <BreadCrumb breadCrumbText={breadcrumbText} />
      <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm mt-2">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="fields-container flex-fill">
            {/* title */}
            <div className="form-group mb-4">
              <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
                {t("Process Name")}
              </label>
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                }}
              />
              {/* title warning */}
              {validation.titleWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter name")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* slug */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={slug}
                onChange={(e) => {
                  setslug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              {/* slug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* <!-- ========== Start details Section ========== --> */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Details")}
              </label>
              <textarea
                name="details"
                id="details"
                cols="30"
                rows="6"
                className="form-control border-0 shadow-none"
                placeholder={t("Details")}
                value={details}
                onChange={(e) => setdetails(e.target.value)}
              ></textarea>
            </div>
            {/* <!-- ========== End details Section ========== --> */}

            {/* tags */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Labels")}
              </label>
              <Select
                isMulti
                placeholder={t("Select tags")}
                options={tagList}
                value={tagValue}
                onChange={(val) => {
                  tagSelectionHandler(val);
                }}
              />
            </div>

            {/* <!-- ========== Start followers Section ========== --> */}
            {moduleName === "administration" ? null : (
              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Followers")}
                </label>
                <Select
                  isMulti
                  placeholder={t("Type user name or email")}
                  options={followersOptionsToShow}
                  value={followersValue}
                  onChange={(val) => followersSelectionHandler(val)}
                  onInputChange={(val) => {
                    followersInputHandler(val);
                  }}
                />
              </div>
            )}
            {/* <!-- ========== End followers Section ========== --> */}

            {/* <!-- ========== Start module select Section ========== --> */}
            {moduleName === "administration" ? null : params.id ? (
              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Selected Module")}
                </label>
                <p>{moduleValue?.label}</p>
              </div>
            ) : (
              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Module")}
                </label>
                <Select
                  isClearable
                  placeholder={t("Select Module")}
                  options={ProcessModuleOptions}
                  value={moduleValue}
                  onChange={(val) => moduleSelectionHandler(val)}
                />
              </div>
            )}
            {/* <!-- ========== End module select Section ========== --> */}
          </div>

          <div className="action d-flex justify-content-between gap-3">
            <div className="d-flex align-items-center gap-2">
              <Link
                to={
                  moduleName === "administration"
                    ? "/admin/administration/activityprocess/list"
                    : `/admin/crm/process/list`
                }
                className="btn btn-outline-primary"
                // data-bs-dismiss="offcanvas"
                aria-label="Close"
                // onClick={resetHandler}
              >
                {t("Close")}
              </Link>
              {errorMessage === "" ? null : (
                <div className="error-message">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{errorMessage}!</span>
                  </p>
                </div>
              )}
            </div>
            {params.id ? (
              isProcessOwner ? (
                <button
                  onClick={saveProcessHandler}
                  type="button"
                  className="btn btn-primary"
                  style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                  disabled={isSaving ? true : false}
                >
                  {t("Save")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              ) : null
            ) : (
              <button
                onClick={saveProcessHandler}
                type="button"
                className="btn btn-primary"
                style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                disabled={isSaving ? true : false}
              >
                {t("Save")}
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            )}
          </div>
        </form>
      </div>

      <SuccessProcessModal
        afterSaveModalClose={afterModalClose}
        moduleName={moduleName}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default ActivityProcessForm;
