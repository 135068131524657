import React, { useState } from 'react';
import Select from 'react-select';
import GroupProductDetailsCommonHeader from '../../GroupProductDetailsCommonHeader/GroupProductDetailsCommonHeader';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const GroupProductVitalInfoBody = () => {
  const params = useParams();
  // select data --------------------------
  const options = [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white">
        {/* --------- common header --------- */}
        <GroupProductDetailsCommonHeader activeTab="vitalInfo" />

        {/* --------- form area ---------- */}
        <div className="container-fluid px-lg-5 py-4 py-lg-5">
          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form action="">
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="name"
                      className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                    >
                      <span className="d-block">Name</span>
                      <span className="d-block material-symbols-outlined icon-md text-gray">
                        info
                      </span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control fs-sm shadow-none"
                      placeholder="Name"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor="companyStore"
                      className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                    >
                      <span className="d-block">Company / Store</span>
                      <span className="d-block material-symbols-outlined icon-md text-gray">
                        info
                      </span>
                    </label>
                    <input
                      type="text"
                      id="companyStore"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Store"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="summary"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">Summary</span>
                  <span className="d-block material-symbols-outlined icon-md text-gray">
                    info
                  </span>
                </label>
                <textarea
                  name="summary"
                  id="summary"
                  cols="30"
                  rows="3"
                  className="form-control fs-sm shadow-none"
                  placeholder="Event Summary"
                ></textarea>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="details"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">Details</span>
                  <span className="d-block material-symbols-outlined icon-md text-gray">
                    info
                  </span>
                </label>
                <textarea
                  name="details"
                  id="details"
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder="Deatils..."
                ></textarea>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="price"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Price
                    </label>
                    <input
                      type="text"
                      id="price"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Price"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label
                      htmlFor="vat"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      VAT %
                    </label>
                    <input
                      type="text"
                      id="vat"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Vat"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label
                      htmlFor="stock"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Stock
                    </label>
                    <input
                      type="text"
                      id="stock"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Stock"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label
                      htmlFor="status"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Status
                    </label>
                    <Select
                      placeholder="Select options"
                      options={options}
                      value={selectOptions}
                      onChange={val => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="accordion mb-3 mb-sm-4"
                id="accordionPanelsStayOpenExample"
              >
                <div className="accordion-item bg-transparent border-0">
                  <h3 className="accordion-header">
                    <button
                      className="accordion-button bg-transparent p-0 shadow-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Advance Info
                    </button>
                  </h3>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                  >
                    <div className="accordion-body p-0 pt-4">
                      <div className="form-group mb-3 mb-sm-4">
                        <div className="row">
                          <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                            <label
                              htmlFor="displayStartFrom"
                              className="d-block fs-sm fw-semibold mb-2"
                            >
                              Display Start From
                            </label>
                            <Select
                              placeholder="Select options"
                              options={options}
                              value={selectOptions}
                              onChange={val => {
                                setSelectOptions(val);
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label
                              htmlFor="displayEndFrom"
                              className="d-block fs-sm fw-semibold mb-2"
                            >
                              Display Ends On
                            </label>
                            <Select
                              placeholder="Select options"
                              options={options}
                              value={selectOptions}
                              onChange={val => {
                                setSelectOptions(val);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3 mb-sm-4">
                        <div className="row">
                          <div className="col-lg-4 mb-3 mb-sm-4 mb-lg-0">
                            <label
                              htmlFor="facebookURL"
                              className="d-block fs-sm fw-semibold mb-2"
                            >
                              Facebook URL
                            </label>
                            <input
                              type="url"
                              id="facebookURL"
                              className="form-control fs-sm shadow-none"
                              placeholder="Enter URL"
                            />
                          </div>
                          <div className="col-lg-4">
                            <label
                              htmlFor="pinterestURL"
                              className="d-block fs-sm fw-semibold mb-2"
                            >
                              Pinterest URL
                            </label>
                            <input
                              type="url"
                              id="pinterestURL"
                              className="form-control fs-sm shadow-none"
                              placeholder="Enter URL"
                            />
                          </div>
                          <div className="col-lg-4">
                            <label
                              htmlFor="youTubeURL"
                              className="d-block fs-sm fw-semibold mb-2"
                            >
                              Youtube URL
                            </label>
                            <input
                              type="url"
                              id="youTubeURL"
                              className="form-control fs-sm shadow-none"
                              placeholder="Enter URL"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="survey"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Survey
                        </label>
                        <Select
                          placeholder="Select options"
                          options={options}
                          value={selectOptions}
                          onChange={val => {
                            setSelectOptions(val);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3 mb-sm-4">
                <input
                  type="checkbox"
                  id="agree"
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                />
                <label className="form-check-label" htmlFor="agree">
                  Agree with the{' '}
                  <Link to="#" className="text-primary">
                    Legal Notice
                  </Link>{' '}
                  &amp;{' '}
                  <Link to="#" className="text-primary">
                    Privacy Policy
                  </Link>
                </label>
              </div>
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link
                  to={`/admin/group/product/add/${params.id}`}
                  className="btn btn-outline-primary"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Save Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GroupProductVitalInfoBody;
