/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { ecosystemSlug } from "Config/Config";
import { useTranslation } from "react-i18next";

const SurveyInviteModal = ({ afterCloseModalHandler }) => {
  const token = localStorage.getItem("token");

  const params = useParams();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  const [userInput, setUserInput] = useState("");
  const [userOptions, setuserOptions] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const [userEmails, setUserEmails] = useState([]);

  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  //function for get user details
  const getUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}` +
        `&userstring=${userInput}`;
      //`&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setuserOptions(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user input
  const userInputHandler = (val) => {
    setUserInput(val);
    seterrorMessage("");
  };

  //function user Selection
  const userSelectionHandler = (val) => {
    seterrorMessage("");
    if (val) {
      setUserValue(val);
      const userEmailValues = val.map((item) => {
        return item.email;
      });

      setUserEmails(userEmailValues);
    } else {
      setUserValue(null);
      setUserEmails([]);
      setuserOptions([]);
    }
  };

  //function for add event member
  const inviteSurveyUserHandler = async () => {
    setIsAdding(true);
    try {
      let surveyInviteData = {
        emails: userEmails,
        surveyid: params.id ? params?.id : null,
      };

      console.log("InviteData---->", surveyInviteData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_INVITE_USER_SURVEY +
        `?token=${token}&ecosystemslug=${ecosystemSlug}`;

      const response = await postData(requestUrl, surveyInviteData);

      setIsAdding(false);
      console.log(response);

      if (response.status) {
        resetHandler();

        //hide member modal
        let bootstrapModal = document.querySelector("#inviteSurveyUser");
        let modal = bootstrap.Modal.getInstance(bootstrapModal);
        modal.hide();

        //call member list api function
        afterCloseModalHandler();
      } else {
        seterrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setUserValue(null);
    setuserOptions([]);
    setUserInput("");
    setUserEmails([]);
    seterrorMessage("");
  };

  useEffect(() => {
    if (userInput.length > 3) {
      getUserList();
    }
  }, [userInput]);

  const optionsToShow = userOptions.length > 0 ? userOptions : [];

  return (
    <div
      className="modal fade"
      id="inviteSurveyUser"
      tabIndex="-1"
      aria-labelledby="inviteSurveyUser"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Invite User")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="memberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Invite users (search by name, email)")}
                </label>
                <div className="d-flex align-items-center">
                  <Select
                    isMulti
                    className="w-100"
                    placeholder={t("Search by name, email")}
                    options={optionsToShow}
                    value={userValue}
                    onChange={(val) => {
                      userSelectionHandler(val);
                    }}
                    onInputChange={(val) => {
                      userInputHandler(val);
                    }}
                  />
                </div>
              </div>
              {/* ----- user select section end ----- */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("Cancel")}
                </Link>
                {errorMessage == "" ? null : (
                  <p className="text-danger">* {errorMessage}</p>
                )}
                {/* <Link to="#">
                  <span className="d-block material-symbols-outlined">
                    delete
                  </span>
                </Link> */}
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={inviteSurveyUserHandler}
                  disabled={isAdding ? true : false}
                  style={{
                    cursor: isAdding ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Send Invite")}
                  {isAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyInviteModal;
