import React from 'react';

const ProjectReportGraphBoard = () => {
  return (
    <div className="graph-board mt-3 mt-md-0 mb-1">
      <div className="row gx-3">
        <div className="col-md-6 col-lg-3 mb-3">
          <div className="body-bg p-4 rounded-10">
            <p className="ds-xs mb-0">Planned</p>
            <h3 className="d-flex align-items-center gap-2 m-0">
              <span className="d-block">3500</span>
              <span className="d-block text-gray">Hours</span>
            </h3>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-3">
          <div className="body-bg p-4 rounded-10">
            <p className="ds-xs mb-0">Percentage Worked</p>
            <h3 className="d-flex align-items-center gap-2 m-0">
              <span className="d-block">50</span>
              <span className="d-block text-gray">%</span>
            </h3>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-3">
          <div className="body-bg p-4 rounded-10">
            <p className="ds-xs mb-0">Done Hours</p>
            <h3 className="d-flex align-items-center gap-2 m-0">
              <span className="d-block">1,750</span>
              <span className="d-block text-gray">Hours</span>
            </h3>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-3">
          <div className="gradient-light p-4 rounded-10">
            <p className="ds-xs mb-0">Accumulated</p>
            <h3 className="d-flex align-items-center gap-2 m-0">
              <span className="d-block">1,859</span>
              <span className="d-block text-gray">Hours</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectReportGraphBoard;
