/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const TicketsPerYear = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [avgSales, setAvgSales] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const formatNumber = (unformatNum) => {
    if (unformatNum) {
      return unformatNum.toFixed(2);
    } else {
      return unformatNum;
    }
  };

  const columns = useMemo(
    () => [
      { accessorKey: "year", header: "Year" },
      { accessorKey: "month", header: "Month" },
      { accessorKey: "total_sales", header: "Total Sales" },
    ],
    []
  );

  // get records -----------------------------------
  const getRecords = async () => {
    try {
      setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_BY_YEAR +
        `?token=${token}&userrolestring=${userInfo.role.slug}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      setisLoading(false);
      console.log("response", response);

      if (response.status) {
        setAvgSales(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getRecords();
  }, []);

  return (
    <div className="tickets-year-container py-1">
      <div className="table-responsive">
        
          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={avgSales} // data from api to be displayed
              enableBottomToolbar={false}
              enableGlobalFilterModes={true}
              enablePagination={false}
              enableRowNumbers={true}
              enableRowVirtualization={true}
            />
          </div>
        
      </div>
    </div>
  );
};

export default TicketsPerYear;
