/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { Box, MenuItem } from "@mui/material";

import CrmContactListHeader from "../Header/CrmContactListHeader";
// import AddNewCrmContactPopup from "../Popup/AddNewCrmContactPopup";
import CrmContactFilterPopup from "../Popup/CrmContactFilterPopup";

import AddLabelModal from "components/Common/Modal/AddLabelModal";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";

import AddToListModal from "components/Common/Modal/AddToListModal";
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";
import { useTranslation } from "react-i18next";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddAlertModal from "components/Common/Modal/AddAlertModal";
import AddNewContactModal from "../Modal/AddNewContactModal";

const CrmContactListBody = () => {
  /* ---- bread crumb text ---- */
  const currentEcosystemId = localStorage.getItem("ecosystem");

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  const moduleAccess = localStorage.getItem("moduleaccess");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { reloadContactList, setReloadContactList } =
    useContext(GlobalProvider);

  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);
  const token = localStorage.getItem("token");
  const [tagList, setTagList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [selectUserIds, setSelectUserIds] = useState([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState("");
  const [selectedContactNames, setselectedContactNames] = useState("");

  //filter states
  const [filterName, setfilterName] = useState("");
  const [filterEmail, setfilterEmail] = useState("");
  const [filterPhone, setfilterPhone] = useState("");
  const [filterPostion, setfilterPostion] = useState("");
  const [filterCompany, setfilterCompany] = useState("");
  const [filterLabels, setfilterLabels] = useState([]);

  //** alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllContactList = async () => {
    setRowSelection({});
    setSelectedContactIds([]);
    setSelectUserIds([]);
    setSelectedUserEmails("");

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_CONTACTS + `?token=${token}`;

      if (filterName != "") {
        requestUrl = requestUrl + `&filtername=${filterName}`;
      }

      if (filterEmail != "") {
        requestUrl = requestUrl + `&filteremail=${filterEmail}`;
      }

      if (filterPhone != "") {
        requestUrl = requestUrl + `&filterphone=${filterPhone}`;
      }

      if (filterPostion != "") {
        requestUrl = requestUrl + `&filterposition=${filterPostion}`;
      }

      if (filterCompany != "") {
        requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
      }

      if (filterLabels.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterLabels}`;
      }

      console.log("url of contact list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in contact list------>", response);

      if (response.status) {
        setContactList(response.data);
        resetFilterData();
        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);

      setIsLoading(false);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for unfriend contact
  const unfriendHandler = async (personId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_UNFRIEND_CONTACT +
        `?token=${token}&personid=${personId}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        setMessageType("success");

        setTimeout(() => {
          getAllContactList();
        }, 2500);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for reload all contact
  const reloadList = (e) => {
    e.preventDefault();
    getAllContactList();
  };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedContactIds([]);
    setSelectUserIds([]);
    setSelectedUserEmails("");
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterEmail("");
    setfilterPhone("");
    setfilterPostion("");
    setfilterCompany("");
    setfilterLabels([]);
  };

  useEffect(() => {
    if (reloadContactList) {
      getAllContactList();
      setReloadContactList(false);
    }
  }, [reloadContactList]);

  useEffect(() => {
    if (
      filterName != "" ||
      filterEmail != "" ||
      filterPhone != "" ||
      filterPostion != "" ||
      filterCompany != "" ||
      filterLabels.length > 0
    ) {
      getAllContactList();
    }
  }, [
    filterName,
    filterEmail,
    filterPostion,
    filterCompany,
    filterPhone,
    filterLabels,
  ]);

  useEffect(() => {
    getAllContactList();
    getAllTags();
  }, []);

  //** Material React Table Column and States
  const columns = useMemo(
    () => [
      {
        accessorKey: "contactname",
        header: t("Name"),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "250px",
            }}
          >
            <img
              height={30}
              width={30}
              src={
                row.original.contactimage
                  ? url.SERVER_URL + row.original.contactimage
                  : assetImages.defaultUser
              }
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />

            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "contactemail",
        header: t("Email"),
      },
      {
        accessorKey: "contactposition",
        header: t("Position"),
      },
      {
        accessorKey: "contactcompany",
        header: t("Company"),
      },
      {
        accessorKey: "contactphone",
        header: t("Phone"),
      },
      {
        accessorKey: "contactcity",
        header: t("City"),
      },
      {
        accessorKey: "contactcountry",
        header: t("Country"),
      },
      {
        accessorKey: "lastcontactdate",
        header: t("Last Contact Date"),
      },
      {
        accessorKey: "lastcontactsubject",
        header: t("Last Contact Subject"),
      },
      {
        accessorKey: "lastcontactchannel",
        header: t("Last Contact Channel"),
      },
      {
        accessorKey: "lastresponsedate",
        header: t("Last Response Date"),
      },
      {
        accessorKey: "lastresponsesubject",
        header: t("Last Response Subject"),
      },
      {
        accessorKey: "lastresponsechannel",
        header: t("Last Response Channel"),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectedContactIds = selectedIdsArray.map((id) => {
        const [contactId] = id.split("/");
        return contactId;
      });

      const selectUserIds = selectedIdsArray.map((id) => {
        const [, userId] = id.split("/");
        return userId;
      });

      const selectUserEmails = selectedIdsArray.map((id) => {
        const [, , userEmail] = id.split("/");
        return userEmail;
      });

      const selectUserEmailString = selectUserEmails.join(",");

      setSelectedUserEmails(selectUserEmailString);
      setSelectedContactIds(selectedContactIds);
      setSelectUserIds(selectUserIds);

      if (selectedIdsArray.length === 1) {
        const contactNameObj = selectedIdsArray[0];

        const [, , , contactName] = contactNameObj.split("/");

        setselectedContactNames(contactName);
      } else {
        setselectedContactNames("");
      }
    } else {
      setSelectedContactIds([]);
      setSelectUserIds([]);
      setSelectedUserEmails("");
    }
  }, [rowSelection]);

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_CONTACT"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Contact")}
          />

          <div className="container-fluid px-lg-5">
            <CrmContactListHeader reloadList={reloadList} />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={contactList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) =>
                    `${row._id}/${row.userid}/${row.contactemail}/${row.contactname}`
                  } // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => {
                    const [contactId] = row.id.split("/"); // Extracts the _id part

                    const menuItems = [
                      <MenuItem key="timeline">
                        <Link
                          className="dropdown-item"
                          // to="#"
                          to={`/admin/crm/contact/details/timeline/${contactId}`}
                        >
                          {t("View Timeline")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="contacttask">
                        <Link
                          className="dropdown-item"
                          to={`/admin/crm/contact/details/task/${contactId}`}
                        >
                          {t("View Task")}
                        </Link>
                      </MenuItem>,
                      <MenuItem key="contactmail">
                        <Link
                          className="dropdown-item"
                          to={`/admin/crm/contact/details/recievedmail/${contactId}`}
                        >
                          {t("View Communication")}
                        </Link>
                      </MenuItem>,
                    ];

                    if (
                      userInfo.role.slug === "ADMIN" ||
                      userInfo.role.slug === "SUPER_ADMIN" ||
                      (moduleAccess.includes("MOD_CRM") &&
                        moduleAccess.includes("MOD_OPPORTUNITY"))
                    ) {
                      menuItems.splice(
                        3,
                        0,
                        <MenuItem key="contactopportunity">
                          <Link
                            className="dropdown-item"
                            to={`/admin/crm/contact/details/opportunities/${contactId}`}
                          >
                            {t("View Opportunities")}
                          </Link>
                        </MenuItem>
                      );
                    }

                    menuItems.push(
                      <MenuItem
                        key="unfriend"
                        onClick={() => unfriendHandler(row.original.contactid)}
                      >
                        <Link className="dropdown-item" to="#">
                          {t("Unfriend Connect")}
                        </Link>
                      </MenuItem>
                    );

                    return menuItems;
                  }} // action columns menu items}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
            {/* ------ all modal and pop up here ----- */}
          </div>
        </section>

        <AddToListModal
          moduleName="contact"
          selectedIds={selectUserIds}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          afterModalClose={() => {
            setSelectedContactIds([]);
            setRowSelection({});
            setSelectUserIds([]);
          }}
        />

        {/* <AddNewCrmContactPopup /> */}
        <CrmContactFilterPopup
          tagList={tagList}
          setfilterName={setfilterName}
          setfilterEmail={setfilterEmail}
          setfilterPhone={setfilterPhone}
          setfilterPostion={setfilterPostion}
          setfilterCompany={setfilterCompany}
          setfilterLabels={setfilterLabels}
        />
        {/* ------ all modal render here ----- */}
        <AddLabelModal
          selectedIds={selectedContactIds}
          moduleName="crmcontact"
          afterTagModalClose={getAllContactList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <ConversationNewMailPopup
          contactPersonEmail={selectedUserEmails}
          reloadList={afterEmailClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddAlertModal
          selectedIds={selectedContactIds}
          alertSubject={selectedContactNames}
          afterModalClose={() => {
            setSelectedContactIds([]);
            setRowSelection({});
          }}
          moduleName={"contact"}
        />

        <AddNewContactModal
          afterCloseModalHandler={() => {
            // getAllContactList();
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default CrmContactListBody;
