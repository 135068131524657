import Header from 'components/Common/Header/Header';
import GroupCompaniesAddBody from 'components/GroupComponents/GroupDetails/GroupCompanies/GroupCompaniesAdd/GroupCompaniesAddBody/GroupCompaniesAddBody';
import React from 'react';

const GroupCompaniesAdd = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ----------- body section ----------- */}
      <GroupCompaniesAddBody />
    </>
  );
};

export default GroupCompaniesAdd;
