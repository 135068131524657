/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const ConversationFilterPopup = ({
  tagList,
  templateList,
  setFilterDateRange,
  setfilterSubject,
  setFilterTags,
  setfilterTemplates,
  setFilterHasAttachment,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); // for translations
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [subject, setSubject] = useState("");
  const [templateValue, setTemplateValue] = useState(null);
  const [searchedTemplate, setSearchedTemplate] = useState([]);
  const [tagValues, settagValues] = useState([]);
  const [hasAttachment, setHasAttachment] = useState(false);

  //function for template selection
  const templateSectionHandler = (val) => {
    setTemplateValue(val);
    const templateData = val.map((data) => data.value);
    setSearchedTemplate(templateData);
  };

  //function for select event tags
  const mailTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "recievedtagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");

      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);

      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "recievedtagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");

      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }
    setfilterSubject(subject);
    setFilterTags(tagValues);
    setfilterTemplates(searchedTemplate);
    setFilterHasAttachment(hasAttachment);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    setSubject("");
    setfromDate("");
    settoDate("");
    settagValues([]);
    setSearchedTemplate([]);
    setHasAttachment(false);
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  {t("Dates")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group d-flex gap-2">
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("From date")}
                      value={fromDate}
                      onChange={(e) => setfromDate(e.target.value)}
                    />
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("To date")}
                      value={toDate}
                      onChange={(e) => settoDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTemp"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTemp"
                >
                  {t("Select Template")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTemp"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <Select
                      isMulti
                      placeholder={t("Select Template")}
                      options={templateList}
                      value={templateValue}
                      onChange={(val) => {
                        templateSectionHandler(val);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Subject")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group mb-4">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter Subject")}
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  {/* --------- Labels ------------- */}
                  <div className="accordion-item bg-transparent border-0">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseFive"
                      >
                        {t("Labels")}
                      </button>
                    </h3>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                          {tagList.map((tagData, index) => {
                            return (
                              <li className="tag" key={index}>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    mailTagSelectionHandler(tagData)
                                  }
                                  id={"recievedtagfilter-" + tagData.value}
                                  className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                                >
                                  <span>{tagData.label}</span>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0">
                    <input
                      className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                      type="checkbox"
                      id="agree"
                      checked={hasAttachment}
                      onChange={(e) =>
                        setHasAttachment(e.target.checked ? true : false)
                      }
                    />
                    <label
                      className="form-check-label fs-lg fw-semibold"
                      htmlFor="agree"
                    >
                      {t("Has Attachment")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};
export default ConversationFilterPopup;
