/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const AddToListModal = ({
  moduleName,
  selectedIds,
  afterModalClose,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [allList, setAllList] = useState([]);
  const [listValue, setlistValue] = useState(null);
  const [selectedListId, setselectedListId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  //get all lsit
  const getAllList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_LIST +
        `?token=${token}&roleslug=${userInfo.role.name}`;

      // console.log(requestUrl);
      if (moduleName === "contact") {
        requestUrl += `&typeslug=TYPE_CONTACT`;
      } else if (moduleName === "company") {
        requestUrl += `&typeslug=TYPE_COMPANY`;
      } else if (moduleName === "lead") {
        requestUrl += `&typeslug=TYPE_LEAD`;
      } else {
      }

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setAllList(
          response.data.map((typeData) => ({
            label: typeData.title,
            value: typeData._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select list
  const listSelectionHandler = (val) => {
    if (val) {
      setlistValue(val);
      setselectedListId(val.value);
    } else {
      setlistValue(null);
      setselectedListId(null);
    }
  };

  //add to list function
  const addToListHandler = async () => {
    if (selectedListId && selectedIds.length > 0) {
      try {
        setIsAdding(true);
        let listData = {};

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_A_LIST +
          `/${selectedListId}` +
          `?token=${token}&modulename=${moduleName}`;

        if (moduleName === "contact") {
          listData.contactids = selectedIds;
        } else if (moduleName === "company") {
          listData.companyids = selectedIds;
        } else if (moduleName === "lead") {
          listData.leadids = selectedIds;
        } else {
        }

        const response = await putData(requestUrl, listData);

        setIsAdding(false);
        if (response.status) {
          resetHandler();
          setMessageType("success");
          // close the modal of addLabel
          let listModal = document.querySelector("#addToList");
          let modal = bootstrap.Modal.getInstance(listModal);
          modal.hide();

          afterModalClose();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please select lead from lead list");
      setMessageType("error");
    }

    setShowAlert(true);
  };

  const resetHandler = () => {
    setlistValue(null);
    setselectedListId(null);
  };

  useEffect(() => {
    getAllList();
  }, []);

  return (
    <div
      className="modal fade"
      id="addToList"
      tabIndex="-1"
      aria-labelledby="addToListLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addToListLabel">
              {t("Add to List")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label
                  htmlFor="selectList"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select List")}
                </label>
                <Select
                  isClearable
                  options={allList}
                  value={listValue}
                  onChange={(val) => listSelectionHandler(val)}
                />
              </div>
              <div className="action d-flex align-items-center gap-2">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  {t("Close")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addToListHandler}
                  style={{ cursor: isAdding ? "not-allowed" : "pointer" }}
                  disabled={isAdding ? true : false}
                >
                  {t("Add To List")}
                  {isAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddToListModal;
