import "gantt-task-react/dist/index.css";

import React from "react";
import { ViewMode } from "gantt-task-react";
import { useTranslation } from "react-i18next";

export const GanttViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  onUpdateGanttTask,
  showUpdateButtons,
  resetUpdatedTaskData,
}) => {
  const { t } = useTranslation(); //for translation
  return (
    <div className="d-flex align-items-center" style={{ marginBottom: "15px" }}>
      {/* <button
        className="btn btn-gray align-items-center gap-1 me-1"
        onClick={() => onViewModeChange(ViewMode.Hour)}
      >
        Hour
      </button> */}
      {/* <button
        className="btn btn-gray align-items-center gap-1 me-1"
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Quarter of Day
      </button> */}
      {/* <button
        className="btn btn-gray align-items-center gap-1 me-1"
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Half of Day
      </button> */}
      <button
        className="btn btn-gray align-items-center gap-1 me-1"
        onClick={() => onViewModeChange(ViewMode.Day)}
      >
        {t("Day")}
      </button>
      <button
        className="btn btn-gray align-items-center gap-1 me-1"
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        {t("Week")}
      </button>
      <button
        className="btn btn-gray align-items-center gap-1 me-1 "
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        {t("Month")}
      </button>
      <button
        className="btn btn-gray align-items-center gap-1 me-1 "
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        {t("Year")}
      </button>

      {/* --------------- Reset button --------------- */}
      <button
        className="btn btn-gray d-flex align-items-center"
        onClick={() => {
          onViewModeChange(ViewMode.Day);
          resetUpdatedTaskData();
        }}
      >
        <span className="d-block material-symbols-outlined icon-lg">
          refresh
        </span>
      </button>

      <div className="Switch ms-2">
        <label className="Switch_Toggle me-2">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
        {t("Show Task List")}
      </div>

      {showUpdateButtons ? (
        <div className="d-flex gap-3 justify-content-center ms-auto">
          <button
            className="btn btn-primary"
            onClick={() => {
              onUpdateGanttTask(true, false);
            }}
          >
            Change
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              onUpdateGanttTask(false, true);
            }}
          >
            Unlink
          </button>
        </div>
      ) : null}
    </div>
  );
};
