import AllLogBody from "components/AllLogComponents/AllLogBody/AllLogBody";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const AllLogs = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "All Logs";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="alllogs" />

      {/* --------- body section ------------ */}
      <AllLogBody />
    </main>
  );
};

export default AllLogs;
