import React from 'react';
import { Link } from 'react-router-dom';

const AddFaqPopup = () => {
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasAddFaq"
      aria-labelledby="offcanvasAddFaqLabel"
    >
      <div className="offcanvas-header gap-3 p-4 pb-0">
        <h3 className="offcanvas-title fw-bold" id="offcanvasAddFaqLabel">
          Add FAQ
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={e => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            {/* ---------- questions ------------ */}
            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Question
              </label>
              <input
                type="text"
                id="question"
                className="form-control fs-sm shadow-none"
                placeholder="Enter"
              />
            </div>

            {/* ----------- answers ------- */}
            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Answers
              </label>
              <textarea
                name="answer"
                id="answer"
                cols="30"
                rows="5"
                className="form-control fs-sm shadow-none"
                placeholder="Enter Deatils..."
              ></textarea>
            </div>
          </div>

          {/* ---------- close / add now ------------- */}
          <div className="action d-flex gap-3 justify-content-between">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Close
            </Link>
            <button type="submit" className="btn btn-primary">
              Add Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFaqPopup;
