import React from 'react';

const CrmContactTimeLineFilterPopup = () => {
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasTimeLineFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="">
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Location
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Comapny
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Position
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    <li className="tag">
                      <input
                        type="checkbox"
                        id="allPosition"
                        className="d-none"
                      />
                      <label
                        htmlFor="allPosition"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        All Position
                      </label>
                    </li>
                    <li className="tag">
                      <input type="checkbox" id="manager" className="d-none" />
                      <label
                        htmlFor="manager"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        Manager
                      </label>
                    </li>
                    <li className="tag">
                      <input type="checkbox" id="ceo" className="d-none" />
                      <label
                        htmlFor="ceo"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        Ceo
                      </label>
                    </li>
                    <li className="tag">
                      <input
                        type="checkbox"
                        id="assistant"
                        className="d-none"
                      />
                      <label
                        htmlFor="assistant"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        Assistant
                      </label>
                    </li>
                    <li className="tag">
                      <input type="checkbox" id="hr" className="d-none" />
                      <label
                        htmlFor="hr"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        HR
                      </label>
                    </li>
                    <li className="tag">
                      <input
                        type="checkbox"
                        id="senAssistant"
                        className="d-none"
                      />
                      <label
                        htmlFor="senAssistant"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        Sen.Assistant
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            Go
          </button>
        </form>
      </div>
    </div>
  );
};

export default CrmContactTimeLineFilterPopup;
