/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { deleteData, getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import time calculation
import { getCurrentTime } from "helper/TimeCalculation";
import { getDuration } from "helper/EventHelper/EventHelper";
import { useTranslation } from "react-i18next";

const SaveAgendaModal = ({
  onModalClose,
  setSelectedAgendaId,
  selectedAgendaId,
  setIsDateSpecific,
  isDateSpecific,
  selectedCalendarAgendaDate,
  setSelectedCalendarAgendaDate,
}) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); // for translations
  const [agendaTitle, setagendaTitle] = useState("Available");
  const [agendaDate, setagendaDate] = useState("");
  const [startTime, setstartTime] = useState(getCurrentTime());
  const [endTime, setendTime] = useState(getCurrentTime());
  const [isDateSpecificFromApi, setIsDateSpecificFromApi] = useState(false);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    dateWarning: false,
    startTimeWarning: false,
    endTimeWarning: false,
    timeDiffWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (agendaTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (agendaDate == "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (startTime == "") {
      setValidation((prevState) => ({
        ...prevState,
        startTimeWarning: true,
      }));
      isValid = false;
    }

    if (endTime == "") {
      setValidation((prevState) => ({
        ...prevState,
        endTimeWarning: true,
      }));
      isValid = false;
    }

    if (startTime !== "" && endTime !== "") {
      if (startTime > endTime) {
        setValidation((prevState) => ({
          ...prevState,
          timeDiffWarning: true,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  //get agenda details
  const getAgendaDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DETAILS_AGENDA +
        `/${selectedAgendaId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setagendaTitle(response.data.description);
        setagendaDate(response.data.date);
        setstartTime(response.data.starttime);
        setendTime(response.data.endtime);
        setIsDateSpecificFromApi(response.data.datespecifichours);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save agenda
  const saveAgendaHandler = async () => {
    if (validate()) {
      try {
        let agendaData = {
          description: agendaTitle == "" ? "Available" : agendaTitle,
          location: "",
          userid: params.id,
          date: agendaDate,
          starttime: startTime,
          endtime: endTime,
          duration: getDuration(startTime, endTime),
          availability: true,
          datespecifichours: isDateSpecificFromApi
            ? isDateSpecificFromApi
            : isDateSpecific
            ? true
            : false,
          attendants: [],
        };

        console.log("agenda data----->", agendaData);

        let requestUrl = url.API_BASE_URL;

        let response = null;

        if (selectedAgendaId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_AGENDA +
            `/${selectedAgendaId}?token=${token}`;

          console.log(requestUrl);

          response = await putData(requestUrl, agendaData);

          console.log(response);
        } else {
          requestUrl = requestUrl + url.API_ADD_NEW_AGENDA + `?token=${token}`;

          console.log(requestUrl);

          response = await postData(requestUrl, agendaData);

          console.log(response);
        }

        if (response.status) {
          resetHandler();

          //hide member modal
          let bootstrapModal = document.querySelector("#saveAccountAgenda");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          onModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
    }
  };

  //delete agenda
  const deleteAgendaHandler = async () => {
    if (selectedAgendaId) {
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_AGENDA +
          `/${selectedAgendaId}?token=${token}`;

        const response = await deleteData(requestUrl);

        if (response.status) {
          resetHandler();

          //hide member modal
          let bootstrapModal = document.querySelector("#saveAccountAgenda");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          onModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset
  const resetHandler = () => {
    setValidation({
      titleWarning: false,
      dateWarning: false,
      startTimeWarning: false,
      endTimeWarning: false,
    });

    setagendaTitle("Available");
    setagendaDate("");
    setstartTime(getCurrentTime());
    setendTime(getCurrentTime());
    setSelectedAgendaId(null);
    setIsDateSpecific(false);
    setSelectedCalendarAgendaDate("");
  };

  useEffect(() => {
    if (selectedAgendaId) {
      getAgendaDetails();
    }
  }, [selectedAgendaId]);

  useEffect(() => {
    if (selectedCalendarAgendaDate != "") {
      setagendaDate(selectedCalendarAgendaDate);
    }
  }, [selectedCalendarAgendaDate]);

  return (
    <div
      className="modal fade"
      id="saveAccountAgenda"
      tabIndex="-1"
      aria-labelledby="saveAccountAgenda"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="dateSpecificHoursLabel">
              {isDateSpecific ? t("Date-Specific Hours") : t("Save agenda")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group d-flex mb-4">
                <div className="field-group d-flex flex-column gap-2 flex-fill">
                  <label htmlFor="timing" className="d-block fs-sm fw-semibold">
                    {t("Timings")}
                  </label>
                  <div className="field-container d-flex align-items-center gap-2">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter title")}
                      value={agendaTitle}
                      onChange={(e) => {
                        setagendaTitle(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          titleWarning: false,
                        }));
                      }}
                    />

                    {/* title warning */}
                    {validation.titleWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter a title!")}</span>
                        </p>
                      </div>
                    )}
                    {/* <span className="d-inline-block">To</span> */}
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter Date")}
                      value={agendaDate}
                      onChange={(e) => {
                        setagendaDate(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          dateWarning: false,
                        }));
                      }}
                    />

                    {validation.dateWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter date!")}</span>
                        </p>
                      </div>
                    )}
                    {/* <a href="javascript: void(0);">
                      <span className="d-block material-symbols-outlined">
                        close
                      </span>
                    </a> */}
                  </div>
                  <div className="field-container d-flex align-items-center gap-2">
                    <input
                      type="time"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Start Time")}
                      value={startTime}
                      onChange={(e) => {
                        setstartTime(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          startTimeWarning: false,
                          timeDiffWarning: false,
                        }));
                      }}
                    />

                    {validation.startTimeWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter start time!")}</span>
                        </p>
                      </div>
                    )}
                    {/* <span className="d-inline-block">To</span> */}
                    <input
                      type="time"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("End Time")}
                      value={endTime}
                      onChange={(e) => {
                        setendTime(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          endTimeWarning: false,
                          timeDiffWarning: false,
                        }));
                      }}
                    />

                    {validation.endTimeWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter a end time!")}</span>
                        </p>
                      </div>
                    )}
                    {validation.timeDiffWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>
                            End time should not be less then start time!
                          </span>
                        </p>
                      </div>
                    )}
                    {/* <a href="javascript: void(0);">
                      <span className="d-block material-symbols-outlined">
                        close
                      </span>
                    </a> */}
                  </div>
                </div>
                {/* <a
                  href="javascript: void(0);"
                  className="flex-shrink-0"
                  style={{ marginTop: "42px" }}
                >
                  <span className="d-block material-symbols-outlined">add</span>
                </a> */}
              </div>
              <div className="action d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveAgendaHandler}
                >
                  {t("Save")}
                </button>

                {selectedAgendaId ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={deleteAgendaHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-lg">
                      delete
                    </span>
                  </button>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveAgendaModal;
