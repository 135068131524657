import React from "react";
import Header from "components/Common/Header/Header";
import AppUserCvBody from "components/AppUserComponents/AppUserDetails/AppUserCv/AppUserCvBody/AppUserCvBody";

const AppUserCv = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="appuser" />
      <AppUserCvBody />
    </main>
  );
};

export default AppUserCv;
