/* eslint-disable */
import ActivityProcessForm from "components/Common/ActivityProcessForm/ActivityProcessForm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ActivityProcessTemplateDeatils = () => {
  const history = useHistory();
  const { t } = useTranslation(); //for translation
  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    {
      title: t("Administration"),
      link: "/admin/administration",
    },
    {
      title: t("Activity process Template"),
      link: "/admin/administration/activityprocess/list",
    },
    { title: t("Save") },
  ];

  const afterModalClose = (pathName) => {
    history.push(pathName);
  };

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <ActivityProcessForm
          moduleName="administration"
          moduleslug="MOD_PROCESS_TEMPLATE"
          breadcrumbText={breadcrumbText}
          isTemplate={true}
          afterModalClose={afterModalClose}
        />
      </section>
    </div>
  );
};

export default ActivityProcessTemplateDeatils;
