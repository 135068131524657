import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const ActionLogHeader = ({ reloadList }) => {
  const { t } = useTranslation(); //for translation
  const breadcrumbText = [
    { title: t("Administration"), link: "/admin/administration" },
    { title: t("Action Log") },
  ];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        <div className="col-md-6 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>

          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasActionLogFilter"
            aria-controls="offcanvasActionLogFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ActionLogHeader;
