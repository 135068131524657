/* eslint-disable */
import { assetImages } from "constants";
import React from "react";
import { Link, useHistory } from "react-router-dom";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const CongratsModal = () => {
  const { t } = useTranslation(); // translation marker
  const history = useHistory();
  return (
    <div className="conatct_modal success_popup">
      <div
        className="modal fade"
        id="congrats_success_mdl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success_graphics">
                <img src={assetImages.congratsImage} alt="" />
              </div>
              <div className="success_text">
                <h3>{t("Congratulations")}!</h3>
                <p>{t("You are now part of Lobees")}</p>
                <div className="btns_group d-flex align-items-center justify-content-center gap-2 mt-3">
                  <Link
                    onClick={() => {
                      history.push("/");
                    }}
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn outline"
                  >
                    Close
                  </Link>
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    {t("Lets Begin")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongratsModal;
