// /* eslint-disable */
import Header from "components/Common/Header/Header";
import GroupMembersBody from "components/GroupComponents/GroupDetails/GroupMembers/GroupMembersBody/GroupMembersBody";
import React, { useEffect } from "react";

const GroupMembers = () => {
  useEffect(() => {
    document.title = "Group Members";
  }, []);

  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ------------ table section ------------ */}
      <GroupMembersBody />
    </>
  );
};

export default GroupMembers;
