import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProjectMemberHeader = ({
  changeStatusHandler,
  reloadEventMemberList,
  isProjectModerator,
  title,
  projectMemberStatus,
  projectModStatus,
}) => {
  const { t } = useTranslation(); //for translation
  const breadCrumbText = [
    { title: t("Project"), link: "/admin/projectmanagement/list" },
    { title: t("Project Members") },
  ];
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ---- search section start --- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          {/* --- bread crumb ---- */}
          <BreadCrumb
            breadCrumbText={breadCrumbText}
            displayName={`${title}`}
          />
        </div>
        {/* ---- search section end --- */}

        {isProjectModerator ||
        (projectMemberStatus === "1" && projectModStatus === "1") ? (
          <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
            {/* ------ refresh icon start ------ */}
            <Link
              to="#"
              className="btn btn-gray d-flex align-items-center"
              onClick={reloadEventMemberList}
            >
              <span className="d-block material-symbols-outlined icon-md">
                refresh
              </span>
            </Link>
            {/* ------ refresh icon end ------ */}

            {/* --- action section start --- */}
            {isProjectModerator && (
              <div className="dropdown flex-fill flex-grow-sm-0">
                <button
                  className="btn btn-gray text-start w-100 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("Action")}
                </button>
                <ul className="dropdown-menu w-100 bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                  {/* --- add label start --- */}
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2"
                      data-bs-toggle="modal"
                      data-bs-target="#addLabelModal"
                    >
                      <span className="d-block material-symbols-outlined icon-sm">
                        label
                      </span>
                      <span className="d-block">{t("Add Label")}</span>
                    </Link>
                  </li>
                  {/* --- add label start --- */}

                  {/* --- delete start --- */}
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item d-flex align-items-center gap-2"
                      // onClick={changeStatusHandler}
                      data-bs-toggle="modal"
                      data-bs-target="#projectMemberDeleteModal"
                    >
                      <span className="d-block material-symbols-outlined icon-sm">
                        delete
                      </span>
                      <span className="d-block">{t("Delete")}</span>
                    </Link>
                  </li>
                  {/* --- delete end --- */}
                </ul>
              </div>
            )}
            {/* --- action section end --- */}

            {/* --- add new members start --- */}
            {isProjectModerator && (
              <Link
                to="#"
                className="btn btn-primary d-flex align-items-center gap-1"
                data-bs-toggle="modal"
                data-bs-target="#addProjectMember"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  add
                </span>
                <span className="d-block">{t("Add New")}</span>
              </Link>
            )}
            {/* --- add new members end --- */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectMemberHeader;
