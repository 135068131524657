import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import OfferResponseData from '../../../../data/Offers/OfferResponseData.json';
import OfferResponseHeader from '../OfferResponseHeader/OfferResponseHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import OfferResponseFilterPopup from '../Popup/OfferResponseFilterPopup';
import OfferDetailsModal from '../Popup/OfferDetailsModal';

const OfferResponseBody = () => {
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Offered by',
        header: 'Offered by',
        Cell: ({ row }) => (
          <td className="offered-by border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="profile d-flex align-items-center gap-2">
                <div
                  className="avatar rounded-circle overflow-hidden"
                  style={{ width: '35px', height: '35px' }}
                >
                  <img
                    src={row.original.offerredBy.img}
                    alt="Richard Hawail"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <div>
                  <p className="fs-md fw-semibold mb-0">
                    {row.original.offerredBy.name}
                  </p>
                  <Link
                    to="mailto:richard.hawail@gmail.com"
                    className="d-block lh-1"
                  >
                    {row.original.offerredBy.email}
                  </Link>
                </div>
              </div>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'offerredSold',
        header: 'Offered/Sold',
        Cell: ({ row }) => (
          <td className="offered-sold border-bottom-0">
            <p>{row.original.offerredSold}</p>
          </td>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <td className="status border-bottom-0">
            <p>{row.original.status}</p>
          </td>
        ),
      },
      {
        accessorKey: 'price',
        header: 'Price',
        Cell: ({ row }) => (
          <td className="price border-bottom-0">
            <p>{row.original.price}</p>
          </td>
        ),
      },
      {
        accessorKey: 'Action',
        header: 'Action',
        Cell: () => (
          <td className="action border-bottom-0">
            <Link
              to="offcanvasOffer"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasOffer"
              aria-controls="offcanvasOffer"
            >
              <span className="d-block">Details</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="survey-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          {/* ---- header area ----*/}
          <OfferResponseHeader />

          {/* ----------- table area --------- */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="offered-by bg-transparent fs-md fw-semibold border-0"
                      width="50%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllOffers"
                            value="offer"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Offered by</span>
                      </div>
                    </th>
                    <th className="offered-sold bg-transparent fs-md fw-semibold border-0">
                      Offered/Sold
                    </th>
                    <th className="status bg-transparent fs-md fw-semibold border-0">
                      Status
                    </th>
                    <th className="price bg-transparent fs-md fw-semibold border-0">
                      Price
                    </th>
                    <th className="action bg-transparent fs-md fw-semibold border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer1"
                            value="offer1"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer2"
                            value="offer2"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer3"
                            value="offer3"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer4"
                            value="offer4"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer5"
                            value="offer5"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer6"
                            value="offer6"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer7"
                            value="offer7"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="offered-by border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer8"
                            value="offer8"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              to="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="offered-sold border-bottom-0">
                      <p>€135,990</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>Listed</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="offcanvasOffer"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasOffer"
                        aria-controls="offcanvasOffer"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={OfferResponseData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* ----------- add label modal ----------- */}
      <AddLabelModal moduleName="" />

      {/* ---------- filter popup ------------- */}
      <OfferResponseFilterPopup />

      {/* ---------- offer details modal ----------- */}
      <OfferDetailsModal />
    </div>
  );
};

export default OfferResponseBody;
