import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CustomerQueriesHeader = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <form action="">
            <div className="form-group d-flex align-items-center flex-row-reverse position-relative">
              <input
                type="text"
                className="form-control ps-2 bg-transparent border-0 shadow-none"
                placeholder={t("Search by lessons name")}
              />
              <button type="submit" className="p-0 bg-transparent border-0">
                <span className="d-block material-symbols-outlined icon-lg">
                  search
                </span>
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerQueriesHeader;
