import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ModuleRolePrevilegeBody from "components/AdministrationComponents/ModuleRolePrevilegeComponent/ModuleRolePrevilegeBody";

const ModuleRolePrivilege = () => {
  useEffect(() => {
    document.title = "Administration | Previlege";
  }, []);
  return (
    <main id="app">
      <Header moduleName="administration" />
      <ModuleRolePrevilegeBody />
    </main>
  );
};

export default ModuleRolePrivilege;
