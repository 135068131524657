import React from 'react';

const DashboardSendOfferModal = () => {
  return (
    <div
      className="modal fade"
      id="sendOfferModal"
      tabIndex="-1"
      aria-labelledby="sendOfferModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="sendOfferModalLabel">
              Send Offer
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <p>
              Please ensure that the payment is made in full by the specified
              due date. Should you have any queries or require assistance, do
              not hesitate to contact us.
            </p>
            <p>Thank you for your prompt attention to this matter.</p>
            <form action="" className="mt-4">
              <div className="form-group field-with-icon icon-left position-relative mb-4">
                <input
                  type="text"
                  className="form-control shadow-none"
                  placeholder="Donation Amt."
                />
                <span className="icon d-block material-symbols-outlined icon-sm position-absolute top-50 start-0 translate-middle-y ms-3">
                  euro
                </span>
              </div>
              <div className="action">
                <button type="submit" className="btn btn-primary w-100">
                  Pay
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSendOfferModal;
