  /* eslint-disable */
import React, { useEffect, useState } from "react";

import * as url from "helper/UrlHelper";
import { postData, uploadSingleFile } from "utils/Gateway";
  
const CSVUploadModal = () => {

    const [file, setFile] = useState(null);
    const [ uploadDocUrl, setUploadedDocUrl] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isSavingData, setIsSavingData] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");

    const token = localStorage.getItem("token");

    //** upload doc funtion
    const docUploadHandler = async (e) => {
        try {

        setIsUploading(true);
        setIsSavingData(false);

        // reset dependent data to avoid duplicate
        setUploadedDocUrl('');

        let requestURL =
            url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

        console.log('req url', requestURL);  

        const response = await uploadSingleFile(requestURL, file);

        if (response.status) {

            console.log('file upload response', response);

            // set the uploaded url path
            setUploadedDocUrl(response.data.path);

            setIsUploading(false);

        }

        } catch (error) {

            console.log(error.message);

        }

    };

    const saveUploadedDocData = async() =>{
        try {
        // show wait while we fetch and map the data from AI
        setIsSavingData(true);

        if (uploadDocUrl && uploadDocUrl.length > 5) {

            let postParams = {
                filepath: "public" + uploadDocUrl
            };

            let requestUrl =
            url.API_BASE_URL +
            url.API_SAVE_TICKETS_BYCSV +
            `?token=${token}`;

            console.log("requestUrl", requestUrl);

            const response = await postData(requestUrl, postParams);

            console.log("bulk response data", response);

            setFile(null);

            if (response.status) {

                setIsUploading(false);
                setIsSavingData(false); // stop showing loading

                let uploadModal = document.querySelector("#csvUploadModal");
                let modal = bootstrap.Modal.getInstance(uploadModal);
                modal.hide();

                //setAlertMessage(response.message);
            }
        }

        } catch (error) {
            console.log(error.message);
        }
    }




    //** use effect for send uploaded doc file url to server
    useEffect(() => {
        if (uploadDocUrl && uploadDocUrl.length > 5) {
            console.log('uploadDocUrl', uploadDocUrl);
            saveUploadedDocData();
        }
    }, [uploadDocUrl]);



    
    return (
        <div
        className="modal fade"
        id="csvUploadModal"
        tabIndex="-1"
        aria-labelledby="csvUploadModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="csvUploadModalLabel">
                        Upload CSV File
                        </h5>
                        <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">

                        <label htmlFor="csvFile" className="form-label">
                            Choose a CSV file to upload Tickets
                        </label>

                        <input
                            type="file"
                            className="form-control border-grey"
                            id="csvFile"
                            accept=".csv"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                        </div>

                        <h6 className={`text text-primary mt-3 ${isUploading? '' : 'd-none'}`}>
                            <div className="spinner-border me-4" role="status">
                            <span className="sr-only"></span>
                            </div>
                            File uploading in process
                        </h6>
                        <h6 className={`text text-primary mt-3 ${isSavingData? '' : 'd-none'}`}>
                            1. File Uploaded Successfully. 
                            <br/>
                            2.Saving tickes data. Please wait for while.
                            <div className="spinner-border me-4" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </h6>
                    </div>
                    <div className="modal-footer">
                        {/* <a
                            type="button"
                            className="btn btn-link "
                            href="/sampledata/Tickets/onebox-sample-ticket.csv"
                        >
                            Download Sample Data
                        </a> */}
                        <div>
                            <button
                            type="button"
                            className="btn btn-secondary me-2"
                            data-bs-dismiss="modal"
                            >
                            Close
                            </button>
                            <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => docUploadHandler()}
                            >
                            Upload
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CSVUploadModal