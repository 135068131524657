import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import MailTemplateListBody from "components/AdministrationComponents/MailTemplateComponents/MailTemplateList/MailTemplateListBody";

const MailTemplateList = () => {
  useEffect(() => {
    document.title = "Administration | Mail Template";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <MailTemplateListBody />
    </main>
  );
};

export default MailTemplateList;
