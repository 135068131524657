/* eslint-disable */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';
import { useTranslation } from 'react-i18next';

//import images
import { assetImages } from 'constants';

import AddLabelModal from 'components/Common/Modal/AddLabelModal';

import MemberDetailsPopup from '../Popup/MemberDetailsPopup';
import AddNewMemberModal from '../Modal/AddNewMemberModal';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import ProjectMemberHeader from '../Header/ProjectMemberHeader';
import { projectDetailsHeaderLinks } from 'helper/ProjectHelper/ProjectHelper';
import DeleteMemberWarningModal from '../Modal/DeleteMemberWarningModal';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';

const ProjectMemberBody = () => {
  const { t, i18n } = useTranslation(); //for translation

  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  // -------- tabs header object --------------------
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  const [title, settitle] = useState('');

  // const { screenHeight } = useContext(GlobalProvider);
  const [roleList, setRoleList] = useState([]);
  const [memberList, setMemberList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [isProjectModerator, setisProjectModerator] = useState(false);

  const [memberId, setMemberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [projectMemberStatus, setProjectMemberStatus] = useState('');
  const [projectModStatus, setprojectModStatus] = useState('');

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all roles
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLES +
        `?token=${token}&slug=MOD_PROJECT`;

      const response = await getData(requestUrl);

      if (response.status) {
        setRoleList(response.data);
      } else {
        setAlertMessage(response.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get project details
  const getProjectDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status && response.data) {
        let isModerator = false;

        if (
          response.data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN'
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisProjectModerator(isModerator);
        settitle(response.data.title);
      } else {
        setAlertMessage(response.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get all event members
  const getAllProjectMembers = async () => {
    setRowSelection({});
    setSelectedMemberIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_MEMBER +
        `?token=${token}&project=${params.id}`;

      const response = await getData(requestUrl);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType('success');
        setMemberList(response.data);
      } else {
        setMessageType('error');
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_MEMBER_DETAILS_QUERY +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);
      // console.log(response);

      if (response.status) {
        setProjectMemberStatus(response.data.memberstatus);
        setprojectModStatus(response.data.moderatorstatus);
      } else {
        setAlertMessage(response.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    if (selectedMemberIds.length > 0) {
      let responseArr = [];

      for (let memberId of selectedMemberIds) {
        try {
          let memberStatusData = {
            status: '0',
          };
          let requestUrl =
            url.API_BASE_URL +
            url.API_CHANGE_MEMBER_STATUS +
            `/${memberId}` +
            `?token=${token}&project=${params.id}`;

          const response = await putData(requestUrl, memberStatusData);

          //  console.log(response);
          if (response.status) {
            setMessageType('success');
            responseArr.push(response.status);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllProjectMembers();
        }, 1500);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select member for delete');
      setShowAlert(true);
    }
  };

  useEffect(() => {
    getAllRoles();
    getProjectDetails();
    getAllProjectMembers();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'memebername',
        header: t('Name'),
        Cell: ({ row }) => (
          <div className="name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="profile d-flex align-items-center gap-2">
                <div
                  className="avatar rounded-circle overflow-hidden"
                  style={{ width: '35px', height: '35px' }}
                >
                  <img
                    src={
                      row.original.memberimage == ''
                        ? assetImages.defaultUser
                        : url.SERVER_URL + row.original.memberimage
                    }
                    alt="Richard Hawail"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <div>
                  <p className="fs-md fw-semibold mb-0">
                    {row.original.memebername}
                  </p>
                  <Link
                    to="mailto:richard.hawail@gmail.com"
                    className="d-block lh-1"
                  >
                    {row.original.memberemail}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'rolestring',
        header: t('Role'),
        Cell: ({ row }) => (
          <div className="role border-bottom-0">
            <p className="fw-semibold">{row.original.rolestring}</p>
          </div>
        ),
      },
      {
        accessorKey: 'status',
        header: t('Status'),
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            {row.original.rolestring == 'Moderator' ? (
              <Link to="#">-</Link>
            ) : (
              <ul className="d-flex flex-column flex-wrap gap-1">
                {row.original.moderatorstatus == '' ? (
                  <li className="d-flex align-items-center gap-1">
                    <span className="d-block fw-semibold"></span>
                  </li>
                ) : (
                  <li className="d-flex align-items-center gap-1">
                    <span
                      className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                        row.original.moderatorstatus == '1'
                          ? 'text-success'
                          : row.original.moderatorstatus == '0'
                          ? 'text-primary'
                          : 'text-gray'
                      }`}
                    >
                      check_circle
                    </span>
                    <span className="d-block fw-semibold">Moderator</span>
                  </li>
                )}
                {row.original.memberstatus == '' ? (
                  <li className="d-flex align-items-center gap-1">
                    <span className="d-block fw-semibold">--</span>
                  </li>
                ) : (
                  <li className="d-flex align-items-center gap-1">
                    <span
                      className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                        row.original.memberstatus == '1'
                          ? 'text-success'
                          : row.original.memberstatus == '0'
                          ? 'text-gray'
                          : 'text-warning'
                      }`}
                    >
                      check_circle
                    </span>
                    <span className="d-block fw-semibold">Member</span>
                  </li>
                )}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: '_id',
        header: t('Action'),
        Cell: ({ row }) => (
          <div className="action border-bottom-0">
            {row.original.rolestring == 'Moderator' ? (
              <Link to="#">-</Link>
            ) : (
              <Link
                to="#"
                className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasMemberDetails"
                onClick={() => {
                  setMemberId(row.original._id);
                }}
              >
                <span className="d-block">{t('Details')}</span>
                <span className="d-block material-symbols-outlined">
                  chevron_right
                </span>
              </Link>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedMemberIds(selectedIdsArray);
    } else {
      setSelectedMemberIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_PROJECT')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Members')}
          />

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- filter area --- */}
            <ProjectMemberHeader
              reloadEventMemberList={getAllProjectMembers}
              changeStatusHandler={changeStatusHandler}
              isProjectModerator={isProjectModerator}
              projectMemberStatus={projectMemberStatus}
              projectModStatus={projectModStatus}
              title={title}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isProjectModerator ? (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={memberList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem
                  //     key="edit"
                  //     //onClick={() => console.info(row.id)}
                  //   >
                  //     <Link
                  //       className="dropdown-item"
                  //       to={`/admin/events/info/${row.id}`}
                  //     >
                  //       View Details
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            ) : (
              <div className="table-wrapper">
                {projectMemberStatus === '1' && projectModStatus === '1' ? (
                  <div className="project-members-container mb-4">
                    <h4 className="fs-sm mb-2 fw-semibold">
                      {t('Team Members')}
                    </h4>
                    <div className="row">
                      {memberList.map((member, index) => {
                        return (
                          <div className="col-lg-3 col-sm-6 mb-2" key={index}>
                            <div className="member-box p-3 rounded-5 bg-white d-flex gap-2 align-items-center">
                              <div class="members-pic d-flex align-items-center justify-content-center overflow-hidden body-bg rounded-circle">
                                <img
                                  src={
                                    member.memberimage === ''
                                      ? assetImages.defaultUser
                                      : url.SERVER_URL + member.memberimage
                                  }
                                  alt=""
                                  className="h-100 w-100 object-fit-cover"
                                />
                              </div>
                              <div class="members-info">
                                <h3 class="fs-lg fw-semibold text-black mb-0">
                                  {member.memebername}
                                </h3>
                                <h5 class="fs-sm mb-0 text-black">
                                  {member.rolestring}
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : projectMemberStatus === '' &&
                  projectModStatus === '' ? null : (
                  <div className="team_members mb-4">
                    <div className="challenges_empty text-center">
                      <div className="empty_pic mb-4">
                        {' '}
                        <img src={assetImages.emptyVector} alt="" />
                      </div>
                      <div className="empty_text">
                        <p className="fs-lg text-gray fw-semibold mb-4">
                          {t(
                            "Sorry....! You don't have privilege to see this content"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* ---- list section end---- */}
          </div>

          {/* === popup and modal section === */}
          {/* --- add label modal --- */}
          <AddLabelModal
            moduleName="projectmemeber"
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllProjectMembers();
              }, 2200);
            }}
            selectedIds={selectedMemberIds}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* --- add new member modal --- */}
          <AddNewMemberModal
            roleList={roleList}
            afterCloseModalHandler={getAllProjectMembers}
            title={title}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ---- member details popup ---- */}
          <MemberDetailsPopup
            projectId={params.id}
            roleList={roleList}
            memberId={memberId}
            setMemberId={setMemberId}
            afterPopupClose={() => {
              setTimeout(() => {
                getAllProjectMembers();
              }, 2200);
            }}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          <DeleteMemberWarningModal
            onDelete={changeStatusHandler}
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedMemberIds([]);
            }}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectMemberBody;
