/* eslint-disable */
import Header from "components/Common/Header/Header";
import EventFeedbackBody from "components/EventComponents/EventDetailsComponents/EventFeedbackComponents/EventFeedbackBody/EventFeedbackBody";

const EventFeedback = () => {
  return (
    <main id="app">
      {/* ---- header start ---- */}
      <Header moduleName="events" />
      {/* --- header end --- */}

      <EventFeedbackBody />
    </main>
  );
};

export default EventFeedback;
