/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import {
  challengeHeaderLinks,
  challnegeBreadCrumbText,
  getJobResponseStatus,
} from 'helper/ChallengeHelper/ChallengeHelper';
import { assetImages } from 'constants';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';

import ChallengeResponseFilterPopup from './Popup/ChallengeResponseFilterPopup';
import ChallengeResponseHeader from './Header/ChallengeResponseHeader';
import ResponseDetailsPopup from '../Common/Popup/ResponseDetailsPopup';
import ApproveRejectDetailsPopup from '../Common/Popup/ApproveRejectDetailsPopup';
import ResponseCommentPopup from '../Common/Popup/ResponseCommentPopup';
import { useTranslation } from 'react-i18next';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const ChallengeResponsesListBody = () => {
  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation
  // common header object
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  const [responseList, setResponseList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedResponseIds, setSelectedResponseIds] = useState([]);

  const [respId, setRespId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState('');

  const [approvalStatus, setapprovalStatus] = useState('');
  const [selectedHiringProcessId, setSelectedHiringProcessId] = useState(null);

  const [approveComments, setapproveComments] = useState('');

  /* ---- bread crumb text ---- */
  const breadcrumbText = challnegeBreadCrumbText(t('Response'), title, t);

  //function for get project details
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get response
  const getAllChallengeResponse = async () => {
    setRowSelection({});
    setSelectedResponseIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_RESPONSE +
        `?token=${token}&challenge=${params.id}`;

      console.log('response requestUrl', requestUrl);

      const response = await getData(requestUrl);

      console.log(response);
      setIsLoading(false);
      if (response.status) {
        setResponseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async e => {
    e.preventDefault();

    let responseArr = [];

    for (let memberId of selectedResponseIds) {
      try {
        let eventData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_JOB_MEMBER +
          `/${memberId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, eventData);

        if (response.status) {
          responseArr.push(response.status);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllEventMembers();
  };

  //approval action
  const approvalHandler = async () => {
    try {
      let approvalData = {
        approvalstatus: approvalStatus,
        hiringprocessid: selectedHiringProcessId || null,
        comments: approveComments,
      };

      console.log(approvalData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_CHANGE_APPROVAL_STATUS +
        `/${respId}?token=${token}`;

      const response = await putData(requestUrl, approvalData);

      console.log(response);

      if (response.status) {
        getAllChallengeResponse();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getJobDetails();
      getAllChallengeResponse();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'cvprovidername',
        header: t('Provider'),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '35px', height: '35px' }}
              >
                <img
                  src={
                    row.original.cvproviderimage == ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.cvproviderimage
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.cvprovidername}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.cvprovideremail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'cvcandidatename',
        header: t('Candidate'),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.cvcandidatename}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.cvcandidateemail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'providerbudget',
        header: t('Price'),
      },
      {
        accessorKey: 'responsecode',
        header: t('Response code'),
      },
      {
        accessorKey: 'hiringprocessname',
        header: t('Hiring Process'),
      },
      {
        accessorKey: 'approvalstatus',
        header: t('Status'),
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-2 fw-semibold ${
                row.original.approvalstatus === '0' ||
                row.original.approvalstatus === '2'
                  ? 'text-primary'
                  : 'text-success'
              } `}
            >
              <span className="d-block material-symbols-outlined icon-lg">
                {row.original.approvalstatus === '0'
                  ? 'schedule'
                  : row.original.approvalstatus === '2'
                  ? 'cancel'
                  : 'check_circle'}
              </span>
              <span className="d-block">
                {getJobResponseStatus(row.original.approvalstatus)}
              </span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: t('Labels'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'action',
        header: t('Action'),
        Cell: ({ row }) => (
          <div className="action border-bottom-0">
            <Link
              to={
                row.original.approvalstatus === '0'
                  ? '#offcanvasResponseDetails'
                  : '#offcanvasResponseApproved'
              }
              // to="#offcanvasResponseApproved"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              type="button"
              data-bs-toggle="offcanvas"
              // data-bs-target="#offcanvasResponseApproved"
              data-bs-target={
                row.original.approvalstatus === '0'
                  ? '#offcanvasResponseDetails'
                  : '#offcanvasResponseApproved'
              }
              aria-controls={
                row.original.approvalstatus === '0'
                  ? '#offcanvasresponseDetails'
                  : '#offcanvasResponseApproved'
              }
              onClick={() => {
                setRespId(row.original._id);
              }}
            >
              <span className="d-block">Details</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedResponseIds(selectedIdsArray);
    } else {
      setSelectedResponseIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_CHALLENGE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t('Responses')}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- header section ----------- */}
            <ChallengeResponseHeader
              breadcrumbText={breadcrumbText}
              ModuleName={'List'}
              reloadList={getAllChallengeResponse}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={responseList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="details">
                      <Link className="dropdown-item" to="#">
                        View Details
                      </Link>
                    </MenuItem>,
                    <MenuItem key="feedback">
                      <Link
                        className="dropdown-item"
                        to={`/admin/customerfeedback/${row.id}`}
                      >
                        Customer Feedback
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ------------- add label modal ----------- */}
        <AddLabelModal
          moduleName="challengeresponse"
          selectedIds={selectedResponseIds}
          afterTagModalClose={getAllChallengeResponse}
        />

        {/* ------------ filter popup -------------- */}
        <ChallengeResponseFilterPopup />

        {/* ------------ response details popup ---------------  */}
        <ResponseDetailsPopup
          setRespId={setRespId}
          respId={respId}
          setapprovalStatus={setapprovalStatus}
          setSelectedHiringProcessId={setSelectedHiringProcessId}
        />

        <ApproveRejectDetailsPopup
          setRespId={setRespId}
          respId={respId}
          setapprovalStatus={setapprovalStatus}
          setSelectedHiringProcessId={setSelectedHiringProcessId}
        />

        <ResponseCommentPopup
          setapproveComments={setapproveComments}
          approveComments={approveComments}
          approvalHandler={approvalHandler}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          // onCancelDelete={() => {
          //   setRowSelection({});
          //   setSelectedBlogIds([]);
          // }}
          // onDelete={changeStatusHandler}
          moduleName={t('challenge')}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ChallengeResponsesListBody;
