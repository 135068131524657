import Header from 'components/Common/Header/Header';
import GroupGalleryBody from 'components/GroupComponents/GroupDetails/GroupGallery/GroupGalleryBody/GroupGalleryBody';
import React from 'react';

const GroupGallery = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ---------- body section ------------- */}
      <GroupGalleryBody />
    </>
  );
};

export default GroupGallery;
