import React from "react";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const PurchaseWarningModal = ({
  isPurchasing = false,
  onPurchase,
  onCancelPurchase,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      id="purchaseWarningModal"
      tabIndex="-1"
      aria-labelledby="purchaseWarningModal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="warning"
              className="img-fluid"
            />
            <h2>
              {t("Please confirm to buy selected leads and opportunities")}
            </h2>
            {/* <h3>** Task with logs can't be deleted</h3> */}
            <div className="d-flex gap-3 justify-content-center">
              <button
                className="btn btn-primary"
                //     data-bs-dismiss="modal"
                //     aria-label="Close"
                disabled={isPurchasing ? true : false}
                style={{
                  cursor: isPurchasing ? "not-allowed" : "pointer",
                }}
                onClick={onPurchase}
              >
                {t("Buy Now")}
                {isPurchasing && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
              <button
                className="btn  btn-outline-primary"
                //     data-bs-dismiss="modal"
                //     aria-label="Close"
                disabled={isPurchasing ? true : false}
                style={{
                  cursor: isPurchasing ? "not-allowed" : "pointer",
                }}
                onClick={onCancelPurchase}
              >
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseWarningModal;
