/* eslint-disable */
import { assetImages } from "constants";
import React from "react";
import { Link } from "react-router-dom";

const LessonSuccessModal = ({ afterSaveModalClose }) => {
  return (
    <div
      className="modal fade"
      id="saveLesson"
      tabIndex="-1"
      aria-labelledby="saveLesson"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center p-5">
            <img
              src={assetImages.successCheckPng}
              alt="Congrats!! Course Saved Successfully"
              className="img-fluid mb-2"
            />
            <h2 className="mb-5">Lesson Saved Successfullys</h2>
            <div className="action d-flex justify-content-center gap-3">
              <Link
                to="#"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  afterSaveModalClose("list");
                }}
              >
                Back to List
              </Link>
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  afterSaveModalClose("view");
                }}
              >
                View Details
              </Link>
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  afterSaveModalClose("add");
                }}
              >
                Add Another
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonSuccessModal;
