/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import time calculation
import { getCurrentTime } from "helper/TimeCalculation";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import fileDownloadHandler from "helper/DownloadFile";
import { assetImages } from "constants";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AfterSaveEventModal from "../Modal/AfterSaveEventModal";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { eventDetailsHeaderLinks } from "helper/EventHelper/EventHelper";
import downloadFileHandler from "helper/DownloadFile";
import { ecosystemSlug } from "Config/Config";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import {
  createdAtToDateConverter,
  getDateFormatInSpanish,
} from "helper/Common/CommonHelper";
import EventDetailsForm from "./EventDetailsForm";

const EventInfoBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const { t } = useTranslation(); //for translation

  const params = useParams();
  const history = useHistory();

  // -------- tabs header object --------------------
  const commonHeaderObject = eventDetailsHeaderLinks(params.id, t);

  const breadCrumbText = [
    { title: t("Event"), link: "/admin/events/list" },
    { title: t("Event Info") },
  ];

  const imageFileInputRef = useRef(null);
  const docFileInputRef = useRef(null);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [eventTypeList, seteventTypeList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [parentEventTitle, setparentEventTitle] = useState("");
  const [parentEventOptions, setParentEventOptions] = useState([]);

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [secretCode, setSecretCode] = useState("");

  const [isRoot, setIsRoot] = useState(false);
  const [parentValue, setParentValue] = useState(null);
  const [parentId, setParentId] = useState(null);

  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);

  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);

  const [isEventDetailsLoading, setIsEventDetailsLoading] = useState(false);

  const [eventTypeValue, seteventTypeValue] = useState(null);
  const [eventTypeData, seteventTypeData] = useState(null);
  const [eventTitle, seteventTitle] = useState("");
  const [eventSlug, setEventSlug] = useState("");
  const [eventSummary, seteventSummary] = useState("");
  const [eventDetails, seteventDetails] = useState("");
  const [isVirtual, setisVirtual] = useState(false);
  const [virtualUrl, setvirtualUrl] = useState("");
  const [location, setlocation] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [startDate, setstartDate] = useState(todayValue);
  const [endDate, setendDate] = useState(todayValue);
  const [startTime, setstartTime] = useState(getCurrentTime());
  const [endTime, setendTime] = useState(getCurrentTime());
  const [eventWebsite, seteventWebsite] = useState("");
  const [eventBudget, seteventBudget] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [categoryData, setcategoryData] = useState([]);
  const [searchingValue, setsearchingValue] = useState(null);
  const [searchingData, setsearchingData] = useState([]);
  const [interestValue, setinterestValue] = useState(null);
  const [interestData, setinterestData] = useState([]);
  const [autoAccept, setautoAccept] = useState(false);
  const [isPrivate, setisPrivate] = useState(false);
  const [companyValue, setCompanyValue] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const [errorMessage, seterrorMessage] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const [savedEventId, setSavedEventId] = useState(null);

  const [isEventModerator, setisEventModerator] = useState(false);

  const [eventMemberStatus, setEventMemberStatus] = useState("1");
  const [eventModStatus, setEventModStatus] = useState("1");
  const [inviteDate, setinviteDate] = useState("");
  const [joinDate, setJoinDate] = useState("");
  const [loggedInMemberId, setLoggedInMemberId] = useState(null);
  const [isJoining, setIsJoining] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    slugWarning: false,
    parentWarning: false,
    zipcodeWarning: false,
    startDateWarning: false,
    endDateWarning: false,
    dateDiffWarning: false,
    startTimeWarning: false,
    endTimeWarning: false,
    timeDiffWarning: false,
    categoryWarning: false,
  });

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (eventTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }

    if (eventSlug == "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (!isRoot) {
      if (!parentValue) {
        setValidation((prevState) => ({ ...prevState, parentWarning: true }));
        isValid = false;
      }
    }

    if (zipcode == "") {
      setValidation((prevState) => ({ ...prevState, zipcodeWarning: true }));
      isValid = false;
    }

    if (startDate == "") {
      setValidation((prevState) => ({
        ...prevState,
        startDateWarning: true,
      }));
      isValid = false;
    }

    if (endDate == "") {
      setValidation((prevState) => ({
        ...prevState,
        endDateWarning: true,
      }));
      isValid = false;
    }

    if (startTime == "") {
      setValidation((prevState) => ({
        ...prevState,
        startTimeWarning: true,
      }));
      isValid = false;
    }

    if (endTime == "") {
      setValidation((prevState) => ({
        ...prevState,
        endTimeWarning: true,
      }));
      isValid = false;
    }

    if (startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        setValidation((prevState) => ({
          ...prevState,
          dateDiffWarning: true,
        }));
        isValid = false;
      }
    }

    if (startTime !== "" && endTime !== "" && startDate === endDate) {
      if (startTime > endTime) {
        setValidation((prevState) => ({
          ...prevState,
          timeDiffWarning: true,
        }));
        isValid = false;
      }
    }

    if (!categoryValue) {
      setValidation((prevState) => ({
        ...prevState,
        categoryWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all event type
  const getEventTypes = async () => {
    try {
      let requestUrl = url.API_BASE_URL + url.API_GET_ALL_EVENT_TYPES;

      const response = await getData(requestUrl);

      if (response.status) {
        seteventTypeList(response.data);
        eventTypeSelectionHandler(response.data[0]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change parnet input
  const parnetInputHandler = (val) => {
    setparentEventTitle(val);
  };

  //function for get all lead records
  const getAllParentEvents = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_PARENT_EVENTS + `?token=${token}`;

      if (parentEventTitle !== "") {
        requestUrl += `&eventtitle=${parentEventTitle}`;
      }

      // console.log("url of event list---------->", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in parent event list------->", response);

      if (response.status) {
        setParentEventOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //parent selection
  const parentSelectionHandler = (val) => {
    if (val) {
      setParentValue(val);
      setParentId(val.value);
    } else {
      setParentValue(null);
      setParentId(null);
    }
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "EVENT";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllSearchingInterest = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      if (response) {
        setsearchingList(response);
        setinterestList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_LIST +
        `?token=${token}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select event type
  const eventTypeSelectionHandler = (val) => {
    seteventTypeValue(val);
    seteventTypeData(val.value);
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    if (zipcode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

        const response = await getData(url);

        if (response.status === "OK") {
          const results = response.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setcountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setcityName(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setstateName(item?.long_name);
              }
            });
            // setMapCoordinate(
            //   `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            // );
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for select category
  const categorySelectionHandler = (val) => {
    setcategoryValue(val);
    const categoryArr = val.map((item) => {
      return item.value;
    });
    setcategoryData(categoryArr);
  };

  //function for select search
  const searchingSelectionHandler = (val) => {
    setsearchingValue(val);
    const searchArr = val.map((item) => {
      return item.value;
    });
    setsearchingData(searchArr);
  };

  //function for select interest
  const interestSelectionHandler = (val) => {
    setinterestValue(val);
    const interestArr = val.map((item) => {
      return item.value;
    });
    setinterestData(interestArr);
  };

  const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload doc

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(response.data.path);
        setUploadedImageId(response.data._id);
        resetEventImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeEventImageFileHandler = () => {
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetEventImageFile();
  };

  // function for clear file value
  const resetEventImageFile = () => {
    const file = document.getElementById("uploadedEventImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for open doc imput
  const openDocFileInput = () => {
    docFileInputRef.current.click();
  };

  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);

      const docFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, docFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setDocFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetDocInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);

    resetDocInput();
  };

  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedEventDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setCompanyId(val.value);
    } else {
      setCompanyValue(null);
      setCompanyId(null);
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_MEMBER_DETAILS_BY_QUERY +
        `?token=${token}&eventid=${params.id}`;

      const response = await getData(requestUrl);

      console.log("response in member details", response);

      if (response.status) {
        setLoggedInMemberId(response.data._id);
        setEventMemberStatus(response.data.memberstatus);
        setEventModStatus(response.data.moderatorstatus);
        setinviteDate(response.data.requestdate);
        setJoinDate(response.data.joindate);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get event details
  const getEventDetails = async () => {
    setIsEventDetailsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of event details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of event details------>", response);

      if (response.status) {
        setSecretCode(response.data.secret);

        setIsRoot(response.data.isroot);

        parentSelectionHandler(response.data.parentvalue);
        //assign event type
        response.data?.eventtype &&
          eventTypeSelectionHandler(response.data.eventtype);

        //assign title
        response.data?.title && seteventTitle(response.data?.title);

        //assign slug
        response?.data?.slug && setEventSlug(response?.data?.slug);

        //assign image
        response.data?.eventlogoid &&
          setUploadedImageId(response.data?.eventlogoid);

        setUploadedPreviewImage(response.data?.eventlogoimage);

        companySelectionHandler(response.data?.companyvalue);

        //assing summary
        seteventSummary(response.data?.summary);

        //assign details
        seteventDetails(response.data?.description);

        //assign virtual
        setisVirtual(response.data?.isvirtual);

        //assign meet url
        setvirtualUrl(response.data?.meeturl);

        //assign zipcode
        setzipcode(response.data?.zipcode);

        //assign city
        setcityName(response.data?.city);

        //assign state
        setstateName(response.data?.state);

        //assign country
        setcountry(response.data?.country);

        //assign location
        setlocation(response.data?.address);

        //assign start date
        setstartDate(response.data?.fromdate);

        //assign start time
        setstartTime(response.data?.starttime);

        //assign end date
        setendDate(response.data?.todate);

        //assign end time
        setendTime(response.data?.endtime);

        //assign budget
        seteventBudget(response.data?.budget);

        //assign website
        seteventWebsite(response.data?.website);

        //assign category
        if (response.data?.categories && response.data?.categories.length > 0) {
          categorySelectionHandler(response.data?.categories);
        }

        //assign searchings
        if (response.data?.searchings && response.data?.searchings.length > 0) {
          searchingSelectionHandler(response.data?.searchings);
        }

        //assign interests
        if (response.data?.interests && response.data?.interests.length > 0) {
          interestSelectionHandler(response.data?.interests);
        }

        //assign uploaded files
        setUploadedDocFiles(
          response?.data?.uploadedfiles.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          )
        );
        setUploadedDocFileIds(response?.data?.uploadedfileids);

        //assing auto accept
        setautoAccept(response.data?.autoapproval);

        //assign private
        setisPrivate(response?.data?.isprivate);

        let isModerator = false;

        if (
          response.data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisEventModerator(isModerator);

        setIsEventDetailsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save event
  const saveUpdateEventHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      seterrorMessage("");
      try {
        let eventData = {
          eventtype: eventTypeData,
          fromdate: startDate,
          todate: endDate,
          starttime: startTime,
          endtime: endTime,
          title: eventTitle,
          slug: eventSlug.toUpperCase().replace(/ /g, "_"),
          summary: eventSummary,
          description: eventDetails,
          address: location,
          city: cityName,
          state: stateName,
          zipcode: zipcode,
          country: country,
          budget: eventBudget,
          website: eventWebsite,
          isvirtual: isVirtual,
          meeturl: virtualUrl,
          logoid: uploadedImageId,
          uploads: uploadedDocFileIds,
          categories: categoryData,
          searchings: searchingData,
          interests: interestData,
          autoapproval: autoAccept,
          isprivate: isPrivate,
          isroot: isRoot,
          parentid: isRoot ? null : parentId,
          companyid: companyId,
        };

        let requestUrl = url.API_BASE_URL;

        if (params.id) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_EVENT +
            `/${params.id}` +
            `?token=${token}`;
        } else {
          requestUrl = requestUrl + url.API_CREATE_EVENT + `?token=${token}`;
        }

        let response;

        if (params.id) {
          response = await putData(requestUrl, eventData);
        } else {
          response = await postData(requestUrl, eventData);
          resetHandler();
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          setSavedEventId(response.data._id);
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("saveEventinfo")
          );
          bootstrapModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      seterrorMessage(t("Please fill up required fields"));
    }
  };

  //function for join event
  const joinEventHandler = async () => {
    try {
      setIsJoining(true);
      let eventData = {
        event: params.id,
        useremail: userInfo.email,
        secret: secretCode,
      };

      console.log("Join event Data------>", eventData);

      let requestURL =
        url.API_BASE_URL + url.API_JOIN_EVENT + `?token=${token}`;

      const response = await postData(requestURL, eventData);

      setIsJoining(false);
      console.log(response);
      if (response.status) {
        getEventDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function reset event form
  const resetHandler = () => {
    seteventTitle("");
    setUploadedImageId(null);
    setIsRoot(false);
    setparentEventTitle("");
    setParentEventOptions([]);
    setParentValue(null);
    setParentId(null);
    setUploadedPreviewImage("");
    seteventSummary("");
    seteventDetails("");
    setisVirtual(false);
    setzipcode("");
    setcityName("");
    setstateName("");
    setcountry("");
    setlocation("");
    setstartDate(todayValue);
    setstartTime(getCurrentTime());
    setendTime(todayValue);
    setendTime(getCurrentTime());
    seteventWebsite("");
    setcategoryValue(null);
    setcategoryData([]);
    setsearchingValue(null);
    setsearchingData([]);
    setinterestValue(null);
    setinterestData([]);
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
    setcompanyInput("");
    setCompanyId(null);
    setCompanyValue(null);
    setCompanyList([]);
    setautoAccept(false);
    setisPrivate(false);
    setValidation({
      titleWarning: false,
      slugWarning: false,
      parentWarning: false,
      zipcodeWarning: false,
      startDateWarning: false,
      endDateWarning: false,
      dateDiffWarning: false,
      startTimeWarning: false,
      endTimeWarning: false,
      timeDiffWarning: false,
      categoryWarning: false,
    });
  };

  //function for close afterSaveEventModal
  const afterSaveEventModalClose = (saveType = "list") => {
    if (saveType == "list") {
      history.push("/admin/events/list");
    } else {
      if (params.id) {
        history.push(`/admin/events/info/${params.id}`);
      } else {
        history.push(`/admin/events/info/${savedEventId}`);
      }
    }
  };

  useEffect(() => {
    if (params.id) {
      getEventDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getEventTypes();
    getAllCategoryList();
    getAllSearchingInterest();
  }, []);

  useEffect(() => {
    if (parentEventTitle.length > 0) {
      getAllParentEvents();
    }
  }, [parentEventTitle]);

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_EVENT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- event details common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Event Info")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            <BreadCrumb
              breadCrumbText={breadCrumbText}
              bottom={true}
              displayName={`${eventTitle}`}
            />

            {/* --- add/update section start----- */}
            {params.id ? (
              isEventDetailsLoading ? null : isEventModerator ? (
                <EventDetailsForm
                  isSaving={isSaving}
                  errorMessage={errorMessage}
                  seterrorMessage={seterrorMessage}
                  validation={validation}
                  setValidation={setValidation}
                  eventTypeList={eventTypeList}
                  eventTypeValue={eventTypeValue}
                  eventTypeSelectionHandler={eventTypeSelectionHandler}
                  eventTitle={eventTitle}
                  seteventTitle={seteventTitle}
                  eventSlug={eventSlug}
                  setEventSlug={setEventSlug}
                  isRoot={isRoot}
                  setIsRoot={setIsRoot}
                  parentEventOptions={parentEventOptions}
                  parentValue={parentValue}
                  parentSelectionHandler={parentSelectionHandler}
                  parnetInputHandler={parnetInputHandler}
                  openImageFileInput={openImageFileInput}
                  imageFileInputRef={imageFileInputRef}
                  imageUploadHandler={imageUploadHandler}
                  isUploading={isUploading}
                  closeEventImageFileHandler={closeEventImageFileHandler}
                  uploadedPreviewImage={uploadedPreviewImage}
                  eventSummary={eventSummary}
                  seteventSummary={seteventSummary}
                  eventDetails={eventDetails}
                  seteventDetails={seteventDetails}
                  isVirtual={isVirtual}
                  setisVirtual={setisVirtual}
                  virtualUrl={virtualUrl}
                  setvirtualUrl={setvirtualUrl}
                  zipcode={zipcode}
                  setzipcode={setzipcode}
                  getLocationFromZip={getLocationFromZip}
                  cityName={cityName}
                  setcityName={setcityName}
                  stateName={stateName}
                  setstateName={setstateName}
                  country={country}
                  setcountry={setcountry}
                  location={location}
                  setlocation={setlocation}
                  companyOptionsToShow={companyOptionsToShow}
                  companyValue={companyValue}
                  companySelectionHandler={companySelectionHandler}
                  setcompanyInput={setcompanyInput}
                  startDate={startDate}
                  setstartDate={setstartDate}
                  startTime={startTime}
                  setstartTime={setstartTime}
                  endDate={endDate}
                  setendDate={setendDate}
                  endTime={endTime}
                  setendTime={setendTime}
                  eventBudget={eventBudget}
                  seteventBudget={seteventBudget}
                  eventWebsite={eventWebsite}
                  seteventWebsite={seteventWebsite}
                  categoryList={categoryList}
                  categoryValue={categoryValue}
                  categorySelectionHandler={categorySelectionHandler}
                  searchingList={searchingList}
                  searchingValue={searchingValue}
                  searchingSelectionHandler={searchingSelectionHandler}
                  interestList={interestList}
                  interestValue={interestValue}
                  interestSelectionHandler={interestSelectionHandler}
                  openDocFileInput={openDocFileInput}
                  docFileInputRef={docFileInputRef}
                  docFileUploadHandler={docFileUploadHandler}
                  docFileUploading={docFileUploading}
                  loadingCircle={loadingCircle}
                  uploadedDocFiles={uploadedDocFiles}
                  closeDocFileHandler={closeDocFileHandler}
                  autoAccept={autoAccept}
                  setautoAccept={setautoAccept}
                  isPrivate={isPrivate}
                  setisPrivate={setisPrivate}
                  saveUpdateEventHandler={saveUpdateEventHandler}
                />
              ) : (
                <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
                  {/* title */}
                  <div
                    className={`details_info_heading d-flex align-items-center justify-content-between ${
                      eventMemberStatus === "1" ? "mb-4" : ""
                    }`}
                  >
                    <h2 className="fw-bold mb-0 text-black">{eventTitle}</h2>
                    {eventMemberStatus === "1" && eventModStatus == "1" ? (
                      <div className="d-flex justify-content-end">
                        {joinDate === "" ? null : (
                          <p className="fs-md mb-0 text-black">
                            {t("Joined date")} -{" "}
                            {getDateFormatInSpanish(joinDate)}
                          </p>
                        )}
                      </div>
                    ) : (
                      <button
                        onClick={joinEventHandler}
                        className="btn btn-primary"
                        disabled={isJoining ? true : false}
                        style={{
                          cursor: isJoining ? "not-allowed" : "pointer",
                        }}
                      >
                        {t("Accept Invite")}
                        {isJoining && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    )}
                  </div>

                  {eventMemberStatus === "1" ? null : (
                    <div className="d-flex justify-content-end mt-1 mb-4">
                      <p className="fs-md mb-0 text-black">
                        {t("Invitation sent on")} -{" "}
                        {getDateFormatInSpanish(inviteDate)}
                      </p>
                    </div>
                  )}

                  <ul className="preview-uploaded-files d-flex flex-wrap gap-2 mb-4">
                    <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                      <img
                        src={
                          uploadedPreviewImage == ""
                            ? "https://placehold.co/150x150"
                            : url.SERVER_URL + uploadedPreviewImage
                        }
                        alt="Uploaded"
                        className="w-100 h-100 object-fit-cover object-center"
                      />
                    </li>
                  </ul>

                  {/* type */}
                  {eventTypeValue ? (
                    <div className="descriptiion_bx mb-4">
                      <h4 className="fs-sm mb-2 fw-semibold">{t("Type")}</h4>
                      <div className="details_info_paragraph">
                        <p className="mb-4 text-black fs-md">
                          {eventTypeValue?.label}
                        </p>
                      </div>
                    </div>
                  ) : null}

                  {/* summary */}
                  <div className="descriptiion_bx mb-4">
                    <h4 className="fs-sm mb-2 fw-semibold">{t("Summary")}</h4>
                    <div className="details_info_paragraph">
                      <p className="mb-4 text-black fs-md">{eventSummary}</p>
                    </div>
                  </div>

                  {/* description */}
                  <div className="descriptiion_bx mb-4">
                    <h4 className="fs-sm mb-2 fw-semibold">
                      {t("Description")}
                    </h4>
                    <div className="details_info_paragraph">
                      <p
                        className="mb-4 text-black fs-md"
                        dangerouslySetInnerHTML={{ __html: eventDetails }}
                      ></p>
                    </div>
                  </div>

                  {/* dates */}
                  <div className="descriptiion_bx mb-4">
                    <div className="info_date_outer d-flex flex-wrap gap-2 mt-4">
                      <div className="info_date_bx body-bg rounded-10 px-3 py-2">
                        <label className="mb-1 fs-sm text-black fw-regular">
                          {t("Start Date")}
                        </label>
                        <h5 className="mb-0 text-black fs-sm fw-semibold">
                          {getDateFormatInSpanish(startDate)}
                        </h5>
                      </div>
                      <div className="info_date_bx body-bg rounded-10 px-3 py-2">
                        <label className="mb-1 fs-sm text-black fw-regular">
                          {t("End Date")}
                        </label>
                        <h5 className="mb-0 text-black fs-sm fw-semibold">
                          {getDateFormatInSpanish(endDate)}
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* documents */}

                  <div className="documents">
                    <h4 className="fs-sm mb-2 fw-semibold">{t("Documents")}</h4>
                    <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                      {uploadedDocFiles.map((file, index) => {
                        return (
                          <li
                            className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                            key={index}
                          >
                            <p className="fw-medium">{file.name}</p>

                            <Link
                              to="#"
                              onClick={() => downloadFileHandler(file)}
                            >
                              <span className="d-block material-symbols-outlined">
                                download
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )
            ) : (
              <EventDetailsForm
                isSaving={isSaving}
                errorMessage={errorMessage}
                seterrorMessage={seterrorMessage}
                validation={validation}
                setValidation={setValidation}
                eventTypeList={eventTypeList}
                eventTypeValue={eventTypeValue}
                eventTypeSelectionHandler={eventTypeSelectionHandler}
                eventTitle={eventTitle}
                seteventTitle={seteventTitle}
                eventSlug={eventSlug}
                setEventSlug={setEventSlug}
                isRoot={isRoot}
                setIsRoot={setIsRoot}
                parentEventOptions={parentEventOptions}
                parentValue={parentValue}
                parentSelectionHandler={parentSelectionHandler}
                parnetInputHandler={parnetInputHandler}
                openImageFileInput={openImageFileInput}
                imageFileInputRef={imageFileInputRef}
                imageUploadHandler={imageUploadHandler}
                isUploading={isUploading}
                closeEventImageFileHandler={closeEventImageFileHandler}
                uploadedPreviewImage={uploadedPreviewImage}
                eventSummary={eventSummary}
                seteventSummary={seteventSummary}
                eventDetails={eventDetails}
                seteventDetails={seteventDetails}
                isVirtual={isVirtual}
                setisVirtual={setisVirtual}
                virtualUrl={virtualUrl}
                setvirtualUrl={setvirtualUrl}
                zipcode={zipcode}
                setzipcode={setzipcode}
                getLocationFromZip={getLocationFromZip}
                cityName={cityName}
                setcityName={setcityName}
                stateName={stateName}
                setstateName={setstateName}
                country={country}
                setcountry={setcountry}
                location={location}
                setlocation={setlocation}
                companyOptionsToShow={companyOptionsToShow}
                companyValue={companyValue}
                companySelectionHandler={companySelectionHandler}
                setcompanyInput={setcompanyInput}
                startDate={startDate}
                setstartDate={setstartDate}
                startTime={startTime}
                setstartTime={setstartTime}
                endTime={endTime}
                setendTime={setendTime}
                endDate={endDate}
                setendDate={setendDate}
                eventBudget={eventBudget}
                seteventBudget={seteventBudget}
                eventWebsite={eventWebsite}
                seteventWebsite={seteventWebsite}
                categoryList={categoryList}
                categoryValue={categoryValue}
                categorySelectionHandler={categorySelectionHandler}
                searchingList={searchingList}
                searchingValue={searchingValue}
                searchingSelectionHandler={searchingSelectionHandler}
                interestList={interestList}
                interestValue={interestValue}
                interestSelectionHandler={interestSelectionHandler}
                openDocFileInput={openDocFileInput}
                docFileInputRef={docFileInputRef}
                docFileUploadHandler={docFileUploadHandler}
                docFileUploading={docFileUploading}
                loadingCircle={loadingCircle}
                uploadedDocFiles={uploadedDocFiles}
                closeDocFileHandler={closeDocFileHandler}
                autoAccept={autoAccept}
                setautoAccept={setautoAccept}
                isPrivate={isPrivate}
                setisPrivate={setisPrivate}
                saveUpdateEventHandler={saveUpdateEventHandler}
              />
            )}
            {/* --- add/update section end----- */}
          </div>

          {/* ----- after save event modal ----- */}
          <AfterSaveEventModal
            afterSaveEventModalClose={afterSaveEventModalClose}
          />
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default EventInfoBody;
