import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ModuleRolesBody from "components/AdministrationComponents/ModuleRoleComponents/ModuleRolesBody/ModuleRolesBody";

const ModuleRoles = () => {
  useEffect(() => {
    document.title = "Administration";
  }, []);
  return (
    <main id="app">
      <Header moduleName="administration" />
      <ModuleRolesBody />
    </main>
  );
};

export default ModuleRoles;
