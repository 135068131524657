import CRMLabelBody from "components/CRMComponents/CRMLabelComponents/CRMLabelBody/CRMLabelBody";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const CRMLabel = () => {
  useEffect(() => {
    document.title = "CRM Label";
  }, []);
  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body section --- */}
      <CRMLabelBody />
    </main>
  );
};

export default CRMLabel;
