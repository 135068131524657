import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const GroupProductImageDeleteModal = () => {
  return (
    <div
      className="modal fade"
      id="deleteModal"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src="assets/img/exclamation-icon.png"
              alt="Are you sure you want to delete this item?"
              className="img-fluid"
            />
            <h2>Are you sure you want to delete this item?</h2>
            <div className="d-flex gap-3 justify-content-center">
              <Link to="#" className="btn btn-outline-primary">
                Yes Delete
              </Link>
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No, Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupProductImageDeleteModal;
