/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import CourseFaqHeader from "../CourseFaqHeader/CourseFaqHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import CourseFaqFilterPopup from "../Popup/CourseFaqFilterPopup";
import AddFaqPopup from "../Popup/AddFaqPopup";
import { assetImages } from "constants";
import { courseDetailsHeaderObject } from "helper/CourseHelper/CourseHelper";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const CourseFaqBody = () => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  const [title, settitle] = useState("");

  const [noteList, setNoteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedNoteIds, setselectedNoteIds] = useState([]);
  const [selectedNoteId, setSelectedNoteId] = useState(null);

  //filter
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterRank, setfilterRank] = useState("");
  const [filterUnanswered, setfilterUnanswered] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  // --get course details --
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get notes
  const getAllFaqs = async () => {
    setRowSelection({});
    setselectedNoteIds([]);
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_FAQ_LIST +
        `?token=${token}&filtercourse=${params.id}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterRank != "") {
        requestUrl = requestUrl + `&filterrank=${filterRank}`;
      }

      if (filterUnanswered != "") {
        requestUrl = requestUrl + `&filterunanswered=${filterUnanswered}`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setNoteList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset filter
  const resetFilterData = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterRank("");
    setfilterUnanswered(false);
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasAddFaq"
            aria-controls="offcanvasAddFaq"
            onClick={() => {
              setSelectedNoteId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: t("Question"),
        size: 300,
      },
      {
        accessorKey: "lessonname",
        header: t("Lesson"),
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        Cell: ({ row }) => (
          <div className="ranking border-bottom-0">
            {/* {row.original.moderator.toString() === userInfo._id.toString() ||
            userInfo.role.slug === "ADMIN" ||
            userInfo.role.slug === "SUPER_ADMIN" ? ( */}
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
            />

            {/* <p className="ps-3">{row.original.rank}</p> */}
          </div>
        ),
      },
      {
        accessorKey: "submittedby",
        header: t("Submitted By"),
      },
      {
        accessorKey: "createddate",
        header: t("Question Date"),
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setselectedNoteIds(selectedIdsArray);
    } else {
      setselectedNoteIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterRank !== "" ||
      filterUnanswered
    ) {
      getAllFaqs();
    }
  }, [filterStartDate, filterEndDate, filterRank, filterUnanswered]);

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getAllFaqs();
    }
  }, [params.id]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COURSE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("FAQ")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter  section ----------- */}
            <CourseFaqHeader title={title} reloadList={getAllFaqs} />

            {/* ------- list area ---------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={noteList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }}
                  // onShowColumnFiltersChange={toggleColumnFiltersChange}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ======== popups and modal section =========== */}
        {/* --------- add label modal ------------ */}
        <AddLabelModal moduleName="" />

        {/* ------------- filter popup ------------- */}
        <CourseFaqFilterPopup
          setfilterStartDate={setfilterStartDate}
          setfilterEndDate={setfilterEndDate}
          setfilterRank={setfilterRank}
          setfilterUnanswered={setfilterUnanswered}
        />

        {/* ------- add faq popup --------- */}
        <AddFaqPopup
          noteId={selectedNoteId}
          setNoteId={setSelectedNoteId}
          afterPopupclose={getAllFaqs}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          setShowAlert={setShowAlert}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseFaqBody;
