import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const OfferDetailsModal = () => {
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasOffer"
      aria-labelledby="offcanvasOfferLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <div className="profile d-flex align-items-center gap-2 mb-4">
          <div
            className="avatar rounded-circle overflow-hidden"
            style={{ width: '35px', height: '35px' }}
          >
            <img
              src="/assets/img/dp-1.jpg"
              alt="Richard Hawail"
              className="w-100 h-100 object-fit-cover object-center"
            />
          </div>
          <div>
            <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
            <Link to="mailto:richard.hawail@gmail.com" className="d-block lh-1">
              richard.hawail@gmail.com
            </Link>
          </div>
        </div>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-4">
        <div className="offer-details flex-fill">
          <p className="fw-semibold mb-4">Offered</p>
          <div className="d-flex gap-3 flex-wrap align-items-center mb-4">
            <p className="fs-md fw-semibold text-success mb-0">€135,990</p>
            <p className="fs-sm text-primary lh-1 gradient-light px-2 py-1 rounded-90">
              Highest Offer You got €185,990
            </p>
          </div>
          <div className="message">
            <p className="fs-sm fw-semibold mb-2">Message</p>
            <p className="fs-md">
              The point of using Lorem Ipsum is that it has a more-or-less
              normal distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes
              sites still in their infancy.
            </p>
          </div>
        </div>
        <form action="" className="pt-4 mt-4 border-top border-gray-300">
          <div className="form-group mb-3 mb-sm-4">
            <label
              htmlFor="comments"
              className="d-block fs-sm fw-semibold mb-2"
            >
              Comments
            </label>
            <textarea
              name="comments"
              id="comments"
              cols="30"
              rows="5"
              className="form-control fs-sm shadow-none"
              placeholder="Enter message"
            ></textarea>
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <button type="submit" className="btn btn-primary">
              Accept
            </button>
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Decline
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OfferDetailsModal;
