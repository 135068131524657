import Header from 'components/Common/Header/Header';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import AddInvestmentPopup from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectInvestmentComponents/Popups/AddInvestmentPopup';
import ProjectInvestmentFilterPopup from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectInvestmentComponents/Popups/ProjectInvestmentFilterPopup';
import ProjectInvestmentBody from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectInvestmentComponents/ProjectInvestmentBody/ProjectInvestmentBody';
import React, { useEffect } from 'react';

const ProjectInvestments = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = 'Project Management';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectInvestmentBody />

      {/* ============= popups and modal section =========== */}
      {/* ----------- add label modal --------- */}
      <AddLabelModal moduleName="" />

      {/* --------- add investment popup ------- */}
      <AddInvestmentPopup />

      {/* ------- filter popup -------- */}
      <ProjectInvestmentFilterPopup />
    </main>
  );
};

export default ProjectInvestments;
