import React from 'react';

const AddKPIModal = () => {
  return (
    <div
      className="modal fade"
      id="addKPIs"
      tabIndex="-1"
      aria-labelledby="addKPIsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addKPIsLabel">
              Add KPIs
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form action="">
              <div className="form-group mb-4">
                <label
                  htmlFor="kpis"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  KPIs
                </label>
                <input
                  type="text"
                  id="kpis"
                  value="Sales"
                  className="form-control fs-sm shadow-none"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="value"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Value
                </label>
                <input
                  type="text"
                  id="value"
                  value="Hrs required"
                  className="form-control fs-sm shadow-none"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="date"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  className="form-control fs-sm shadow-none"
                />
              </div>
              <div className="action">
                <button type="submit" className="btn btn-primary">
                  Add KPIs
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddKPIModal;
