import React, { useState } from 'react';
import Select from 'react-select';

const AddInvestmentPopup = () => {
  // select data --------------------------
  const role = [
    { label: 'Manager', value: 'Manager' },
    { label: 'Team Lead', value: 'Team Lead' },
  ];

  // useState to select data ------------------
  const [selectProject, setSelectProject] = useState(null);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasAddInvestments"
      aria-labelledby="offcanvasAddInvestmentsLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasAddInvestmentsLabel">
          Add Investments
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-4">
        <form action="">
          <div className="form-group mb-4">
            <label htmlFor="project" className="d-block fs-sm fw-semibold mb-2">
              Project
            </label>
            <Select
              placeholder="Select Role"
              options={role}
              value={selectProject}
              onChange={val => {
                setSelectProject(val);
              }}
            />
          </div>
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <label
                  htmlFor="startDate"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="DD/MM/YY"
                />
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor="endDate"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="DD/MM/YY"
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <label
                  htmlFor="priceOfOnePercent"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Price of 1%
                </label>
                <div className="field-container d-flex align-items-center ps-3 body-bg rounded-10">
                  <span className="d-block material-symbols-outlined icon-md">
                    euro
                  </span>
                  <input
                    type="text"
                    className="form-control fs-sm border-0 shadow-none"
                    placeholder="Enter amt."
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor="maxInvestmentForThisRound"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Max Investment for this Round
                </label>
                <div className="field-container d-flex align-items-center ps-3 body-bg rounded-10">
                  <span className="d-block material-symbols-outlined icon-md">
                    euro
                  </span>
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder="Enter amt."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <label
                  htmlFor="maxInvestmentForInvestor"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Max Investment for Investor
                </label>
                <div className="field-container d-flex align-items-center ps-3 body-bg rounded-10">
                  <span className="d-block material-symbols-outlined icon-md">
                    euro
                  </span>
                  <input
                    type="text"
                    className="form-control fs-sm border-0 shadow-none"
                    placeholder="Enter amt."
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor="minimumPriceForInvestment"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Minimum Price for Investment
                </label>
                <div className="field-container d-flex align-items-center ps-3 body-bg rounded-10">
                  <span className="d-block material-symbols-outlined icon-md">
                    euro
                  </span>
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder="Enter amt."
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-4">
            <label
              htmlFor="comments"
              className="d-block fs-sm fw-semibold mb-2"
            >
              Comments
            </label>
            <textarea
              name="comments"
              id="comments"
              cols="30"
              rows="5"
              className="form-control fs-sm shadow-none"
              placeholder="Enter name"
            ></textarea>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary">
              Make Investment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddInvestmentPopup;
