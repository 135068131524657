import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ActivityProcessTemplateBody from "components/AdministrationComponents/ActivityProcessTemplate/ActivityProcessTemplateBody";

const ActivityProcessTemplate = () => {
  useEffect(() => {
    document.title = "Administration | Activity process Template";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <ActivityProcessTemplateBody />
    </main>
  );
};

export default ActivityProcessTemplate;
