/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//** import components
import DashboardHeader from "../Header/DashboardHeader";
import DashboardFilterPopup from "components/DashboardComponents/Popup/DashboardFilterPopup";


import DashboardActivityTab from "../Tab/DashboardActivityTab";

import AverageSalesTab from "../Tab/AverageSales";
import TicketsPerYear from "../Tab/TicketsPerYear";
import SalesByChannel from "../Tab/SalesByChannel";
import TicketsPerMonth from "../Tab/TicketsPerMonth";
import TicketsPerDaysOfWeek from "../Tab/TicketsByDayOfWeek";

import TicketsByGender from "../Tab/TicketsByGender";
import TicketsByPrice from "../Tab/TicketsByPrice";
import TicketsByPromotion from "../Tab/TicketsByPromotion";
import TicketsBySector from "../Tab/TicketsBySector";
import TicketsByBill from "../Tab/TicketsByBill";
import TicketsByWork from "../Tab/TicketsPerWork";

import BestBuyerTab from "../Tab/BestBuyerRanking";

const DashboardBody = () => {

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [organizations, setOrganizations] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [operationStartDate, setOperationStartDate] = useState("");
  const [operationEndDate, setOperationEndDate] = useState("");

  const [enclosures, setEnclosures] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [events, setEvents] = useState([]);

  const [channels, setChannels] = useState([]);
  const [zones, setZones] = useState([]);
  
  const [refreshData, setRefreshData] = useState(false);

  const [applyFilter, setApplyFilter] = useState(false);

  return (
    <>
    <div id="content_wrapper">
      <section className="dashboard-wrapper">
        {/* <!-- ========== Start dashboard header component ========== --> */}
        <DashboardHeader 
          setRefreshData={setRefreshData}
        />
        {/* <!-- ========== End dashboard header component ========== --> */}

        {/* <!-- ========== Start dashboard tab components ========== --> */}
        <div className="tab-container">
          <div className="container-fluid px-lg-5">
            <div className="tab-content" id="myTabContent">
              {/* <!-- ========== Start dashboard activity tab Section ========== --> */}
              <div
                className="tab-pane fade show active"
                id="activity"
                role="tabpanel"
                aria-labelledby="activity-tab"
                tabIndex="0"
              >
                <DashboardActivityTab 
                    startDate={startDate}
                    endDate={endDate}
                    operationStartDate={operationStartDate}
                    operationEndDate={operationEndDate}
                    organizations={organizations}
                    enclosures={enclosures}
                    sectors={sectors} 
                    eventTypes={eventTypes}
                    events={events}
                    channels={channels}
                    zones={zones}

                    setApplyFilter={setApplyFilter}
                    applyFilter={applyFilter}    
                    
                    setRefreshData={setRefreshData}
                    refreshData={refreshData}
                />
              </div>

              {ecosystemSlug === "MUSAMERDA" && 
                (userInfo.email === 'marketing@elpavonteatro.es' 
                  || userInfo.role.slug === "ADMIN" 
                  || userInfo.role.slug === "SUPER_ADMIN")? (



                <>
                  <div
                    className="tab-pane fade"
                    id="avg-sales"
                    role="tabpanel"
                    aria-labelledby="avg-sales-tab"
                    tabIndex="0"
                  >
                    <AverageSalesTab />
                  </div>

                  {/* sales per year per month */}
                  <div
                    className="tab-pane fade"
                    id="tickets-year"
                    role="tabpanel"
                    aria-labelledby="tickets-year-tab"
                    tabIndex="0"
                  >
                    <TicketsPerYear />
                  </div>

                  {/* sales per channel */}
                  <div
                    className="tab-pane fade"
                    id="records-channel"
                    role="tabpanel"
                    aria-labelledby="records-channel-tab"
                    tabIndex="0"
                  >
                    <SalesByChannel />
                  </div>

                  {/* sales per month per year */}
                  <div
                    className="tab-pane fade"
                    id="tickets-month"
                    role="tabpanel"
                    aria-labelledby="tickets-month-tab"
                    tabIndex="0"
                  >
                    <TicketsPerMonth />
                  </div>

                  {/* sales per day by week */}
                  <div
                    className="tab-pane fade"
                    id="tickets-days"
                    role="tabpanel"
                    aria-labelledby="tickets-days-tab"
                    tabIndex="0"
                  >
                    <TicketsPerDaysOfWeek />
                  </div>

                  {/* tickets by gender */}
                  <div
                    className="tab-pane fade"
                    id="ticket-gender"
                    role="tabpanel"
                    aria-labelledby="ticket-gender-tab"
                    tabIndex="0"
                  >
                    <TicketsByGender />
                  </div>

                  {/* tickets by price */}
                  <div
                    className="tab-pane fade"
                    id="ticket-price"
                    role="tabpanel"
                    aria-labelledby="ticket-price-tab"
                    tabIndex="0"
                  >
                    <TicketsByPrice />
                  </div>

                  {/* tickets by promotion */}
                  <div
                    className="tab-pane fade"
                    id="ticket-promotion"
                    role="tabpanel"
                    aria-labelledby="ticket-promotion-tab"
                    tabIndex="0"
                  >
                    <TicketsByPromotion />
                  </div>

                  {/* tickets by sector */}
                  <div
                    className="tab-pane fade"
                    id="ticket-sector"
                    role="tabpanel"
                    aria-labelledby="ticket-sector-tab"
                    tabIndex="0"
                  >
                    <TicketsBySector />
                  </div>

                  {/* tickets by bill */}
                  <div
                    className="tab-pane fade"
                    id="ticket-bill"
                    role="tabpanel"
                    aria-labelledby="ticket-bill-tab"
                    tabIndex="0"
                  >
                    <TicketsByBill />
                  </div>

                  {/* tickets by work */}
                  <div
                    className="tab-pane fade"
                    id="ticket-work"
                    role="tabpanel"
                    aria-labelledby="ticket-work-tab"
                    tabIndex="0"
                  >
                    <TicketsByWork />
                  </div>

                  {/* Best Buyer */}
                  <div
                    className="tab-pane fade"
                    id="best-buyer"
                    role="tabpanel"
                    aria-labelledby="best-buyer-tab"
                    tabIndex="0"
                  >
                    <BestBuyerTab />
                  </div>
                </>


              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* <!-- ========== End dashboard tab components ========== --> */}
      </section>
    </div>

    {/* Filter popup for dashboard */}
      <DashboardFilterPopup 
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setOperationStartDate={setOperationStartDate}
        setOperationEndDate={setOperationEndDate}
        setOrganizations={setOrganizations}
        setEnclosures={setEnclosures}
        setSectors={setSectors}
        setEventTypes={setEventTypes}
        setEvents={setEvents}
        setChannels={setChannels}
        setZones={setZones}
        
        applyFilter={applyFilter}
        setApplyFilter={setApplyFilter}
      />

    </>
  );
};

export default DashboardBody;
