/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { assetImages } from "constants";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

import LanguaeOptions from "data/Prod/LanguageOptions.json";

const ResponseDetailsPopup = ({
  setRespId,
  respId,
  setapprovalStatus,
  setSelectedHiringProcessId,
}) => {
  const params = useParams();
  const token = localStorage.getItem("token");

  const [cvTitle, setcvTitle] = useState("");
  const [jobPost, setjobPost] = useState("");
  const [currCtc, setcurrCtc] = useState("");
  const [expCtc, setexpCtc] = useState("");
  const [country, setcountry] = useState("");
  const [userImagePath, setuserImagePath] = useState("");
  const [candidateName, setcandidateName] = useState("");
  const [candiddateSurname, setcandiddateSurname] = useState("");
  const [candidatePosition, setcandidatePosition] = useState("");
  const [candidateEmail, setcandidateEmail] = useState("");
  const [candidatePhone, setcandidatePhone] = useState("");
  const [userSummary, setuserSummary] = useState("");
  const [userDetails, setuserDetails] = useState("");
  const [skilPoints, setskilPoints] = useState([]);
  const [experiences, setexperiences] = useState([]);
  const [educations, seteducations] = useState([]);
  const [certificates, setcertificates] = useState([]);

  const [userLanguages, setUserLanguages] = useState([]);

  const [userStartTime, setuserStartTime] = useState("");
  const [userEndTime, setuserEndTime] = useState("");
  const [userOptionalSkills, setuserOptionalSkills] = useState([]);

  const [userPortfolioLinks, setuserPortfolioLinks] = useState([]);
  const [userHobbies, setuserHobbies] = useState([]);

  const [roles, setRoles] = useState([]);
  const [carrierHighlights, setCarrierHighlights] = useState([]);
  const [experienceInYears, setExperienceInYears] = useState("");
  const [projects, setProjects] = useState([]);
  const [otherSkills, setOtherSkills] = useState([]);

  //function for get project details
  const getJobResponseDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_RESPONSE_DETAILS +
        `/${respId}?token=${token}`;

      console.log('req url', requestUrl);

      const response = await getData(requestUrl);

      console.log("response in details popup", response);

      if (response.status) {
        if (response.data) {
          const data = response.data;
          setcvTitle(data.candidatecvtitle);
          setjobPost(data.candidatejobpost);
          setcurrCtc(data.cvcurrentctc);
          setexpCtc(data.cvexpectedctc);
          setcountry(data.cvcountry);
          setuserImagePath(data.providerimage);
          setcandidateName(data.providername);
          setcandiddateSurname(data.providersurname);
          setcandidatePosition(data.providerpostion);
          setcandidateEmail(data.provideremail);
          setcandidatePhone(data.providerphone);
          setuserSummary(data.cvsummary);
          setuserDetails(data.cvdetails);
          setskilPoints(data.cvskillpoints);
          setexperiences(data.cvexperiences);
          seteducations(data.cveducations);
          setcertificates(data.cvcertifications);

          //mapping only labels
          // if (data.cvlanguages) {
          //   const matchedData = LanguaeOptions.filter((item) =>
          //     data.cvlanguages.includes(item.value)
          //   ).map((item) => item.label);

          //   setUserLanguages(matchedData);
          // }

          setuserOptionalSkills(data.cvoptionalskills);
          setuserPortfolioLinks(data.cvportfoliolinks);
          setuserHobbies(data.cvhobbies);
          setuserStartTime(data.cvstarttime);
          setuserEndTime(data.cvendtime);

          setUserLanguages(data.cvlanguages);
          setRoles(data.cvroles);
          setCarrierHighlights(data.cvcarrierhighlights);
          setExperienceInYears(data.cvexperienceinyears);
          setProjects(data.cvprojects);
          setOtherSkills(data.cvotherskills);

        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //closeHandler
  const closeHandler = () => {
    console.log("close");
  };

  //approve status
  const approveStatusHandler = (status) => {
    setapprovalStatus(status);
    setSelectedHiringProcessId(null);
  };

  useEffect(() => {
    if (respId) {
      getJobResponseDetails();
    }
  }, [respId]);

  return (
    <div
      className="offcanvas lg offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasResponseDetails"
      aria-labelledby="offcanvasResponseDetails"
    >
      <div className="offcanvas-header flex-column align-items-start gradient-light p-3 p-md-4">
        <h3 className="offcanvas-title mb-3" id="offcanvasresponseDetailsLabel">
          {cvTitle}
        </h3>
        <ul className="d-flex flex-wrap align-items-center fs-sm mb-3">
          <li className="position-relative">{jobPost}</li>
          <li className="fw-semibold text-primary position-relative">
            €{currCtc} - €{expCtc}
          </li>
          <li className="d-flex align-items-center gap-1 position-relative">
            <span className="d-blocl material-symbols-outlined icon-lg">
              location_on
            </span>
            <span className="d-block">{country}</span>
          </li>
        </ul>
        <div className="action d-flex gap-3">
          <Link
            to="#offcanvasCommentYourThought"
            className="btn btn-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCommentYourThought"
            aria-controls="offcanvasResponseApproved"
            onClick={() => {
              approveStatusHandler("1");
            }}
          >
            Approve
          </Link>
          <Link
            to="#offcanvasCommentYourThought"
            className="btn btn-outline-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCommentYourThought"
            aria-controls="offcanvasResponseApproved"
            onClick={() => {
              approveStatusHandler("2");
            }}
          >
            Reject
          </Link>
        </div>

        {/* offcalvas close button */}
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none position-absolute top-0 end-0 mt-3 me-3"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closeHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-0">
        {/* profile */}
        <div className="profile p-3 p-md-4 d-flex align-items-center flex-column flex-md-row gap-4 border-bottom border-gray-300 mb-3 mb-md-4">
          <div
            className="avatar rounded-circle overflow-hidden"
            style={{ width: "100px", height: "100px" }}
          >
            {/* candidate image */}
            <img
              src={
                userImagePath === ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + userImagePath
              }
              alt="user"
              className="w-100 h-100 object-fit-cover object-center"
            />
          </div>
          <div className="name">
            <h2 className="mb-0">
              {candidateName} {candiddateSurname}
            </h2>
            <p className="designation fs-sm fw-semibold text-gray-400 mb-2">
              {candidatePosition}
            </p>
            <ul className="contact-info d-flex flex-wrap gap-2 fs-sm">
              <li className="email">
                <Link
                  to="#"
                  className="d-flex align-items-center gap-1 px-3 py-2 body-bg rounded-90"
                >
                  <span className="d-block material-symbols-outlined icon-md text-primary">
                    mail
                  </span>
                  <span className="d-block">{candidateEmail}</span>
                </Link>
              </li>
              <li className="email">
                <Link
                  to="#"
                  className="d-flex align-items-center gap-1 px-3 py-2 body-bg rounded-90"
                >
                  <span className="d-block material-symbols-outlined icon-md text-primary">
                    phone_in_talk
                  </span>
                  <span className="d-block">{candidatePhone}</span>
                </Link>
              </li>

              <li className="email">
                <Link
                  to="#"
                  className="d-flex align-items-center gap-1 px-3 py-2 body-bg rounded-90"
                >
                  <span className="d-block material-symbols-outlined icon-md text-primary">
                    work_history
                  </span>
                  <span className="d-block">{experienceInYears} Years</span>
                </Link>
              </li>


            </ul>
          </div>
        </div>
        
        {/* hobbies */}
        <div className="skills-container p-3 p-md-4">
          <h3>Roles</h3>
          <ul className="skills d-flex flex-wrap gap-2 fs-sm fw-semibold mb-3">
            {roles.map((role, index) => {
              return (
                <li
                  key={index}
                  className="px-3 py-2 border border-gray-300 rounded-10"
                >
                  {role}
                </li>
              );
            })}
          </ul>
        </div>

        {/* carrier highlights */}
        <div className="skills-container p-3 p-md-4">
          <h3>Carrier Highlights</h3>
          <ul className="skills d-flex flex-wrap gap-2 fs-sm fw-semibold mb-3">
            {carrierHighlights.map((highlight, index) => {
              return (
                <li
                  key={index}
                  className="px-3 py-2 border border-gray-300 rounded-10"
                >
                  {highlight}
                </li>
              );
            })}
          </ul>
        </div>


        {/* summary */}
        <div className="resume-summary p-3 p-md-4">
          <h3>Resume Summary</h3>
          <p>{userSummary}</p>
        </div>

        {/* details */}
        <div className="resume-details p-3 p-md-4">
          <h3>Resume Details</h3>
          <p dangerouslySetInnerHTML={{ __html: userDetails }}></p>
        </div>

        {/* skill points */}
        <div className="skills-container p-3 p-md-4">
          <h3>Skill Points</h3>
          {skilPoints.map((skillBlock, index) => {
            return (
              <div className="field" key={index}>
                <div className="form-group">
                  <div className="row">
                    {/* ------ skill name section start ------ */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="skill" className="text-nowrap me-3">
                          Skill
                        </label>
                        <p className="form-control border-0 rounded-0 shadow-none">
                          {skillBlock?.skilltitle}
                        </p>
                      </div>
                    </div>
                    {/* ------ skill name section end ------ */}

                    {/* ------ skill point section start ------ */}
                    <div className="col-lg-4">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="point" className="text-nowrap me-3">
                          Point
                        </label>
                        <p className="form-control border-0 rounded-0 shadow-none">
                          {skillBlock.points} / 10
                        </p>
                      </div>
                    </div>
                    {/* ------ skill point section end ------ */}

                    {/* ------ experienceyear point section start ------ */}
                    <div className="col-lg-4">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="point" className="text-nowrap me-3">
                          Total Experience
                        </label>
                        <p className="form-control border-0 rounded-0 shadow-none">
                          {skillBlock.experienceyear}
                        </p>
                      </div>
                    </div>
                    {/* ------ experienceyear point section end ------ */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Project */}
        <div className="skills-container p-3 p-md-4">
          <h3>Project References</h3>
          {projects.map((project, index) => {
            return (
              <div className="field" key={index}>
                <div className="form-group">
                  <div className="row">
                    {/* ----- company section start ----- */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Company
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {project.company ?? ""}
                        </p>
                      </div>
                    </div>
                    {/* ----- company section end ----- */}

                    {/* ----- position section start ----- */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Role
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {project.position}
                        </p>
                      </div>
                    </div>
                    {/* ----- position section end ----- */}

                    {/* ----- start year section start ----- */}
                    <div className="col-lg-2 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Start Year
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {project.start}
                        </p>
                      </div>
                    </div>
                    {/* ----- start year section end ----- */}

                    {/* ----- end year section start ----- */}
                    <div className="col-lg-2">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          To Year
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {project.end}
                        </p>
                      </div>
                    </div>
                    {/* ----- end year section end ----- */}

                    <div className="col-lg-12 mt-1">
                      <p className="form-control border-0 shadow-none bg-transparent">
                        Technologies : {project.technologies}
                      </p>
                      
                    </div>
                    <div className="col-lg-12 mt-1">
                      <p className="form-control border-0 shadow-none  bg-transparent">
                        Responsibilities  : {project.responsibilities}
                      </p>                      
                    </div>

                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* experience */}
        <div className="skills-container p-3 p-md-4">
          <h3>Experience</h3>
          {experiences.map((experience, index) => {
            return (
              <div className="field" key={index}>
                <div className="form-group">
                  <div className="row">
                    {/* ----- company section start ----- */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Company
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {experience.company ?? ""}
                        </p>
                      </div>
                    </div>
                    {/* ----- company section end ----- */}

                    {/* ----- position section start ----- */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Position
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {experience.position}
                        </p>
                      </div>
                    </div>
                    {/* ----- position section end ----- */}

                    {/* ----- start year section start ----- */}
                    <div className="col-lg-2 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Start Year
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {experience.start}
                        </p>
                      </div>
                    </div>
                    {/* ----- start year section end ----- */}

                    {/* ----- end year section start ----- */}
                    <div className="col-lg-2">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          To Year
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {experience.end}
                        </p>
                      </div>
                    </div>
                    {/* ----- end year section end ----- */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* education */}
        <div className="skills-container p-3 p-md-4">
          <h3>Education</h3>
          {educations.map((education, index) => {
            return (
              <div className="field" key={index}>
                <div className="form-group">
                  <div className="row">
                    {/* ------ degree section start ------ */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Degree
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {education.degree}
                        </p>
                      </div>
                    </div>
                    {/* ------ degree section end ------ */}

                    {/* ------ institute section start ------ */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Institute
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {education.institute}
                        </p>
                      </div>
                    </div>
                    {/* ------ institute section end ------ */}

                    {/* ------ year section start ------ */}
                    <div className="col-lg-2 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Year
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {education.year}
                        </p>
                      </div>
                    </div>
                    {/* ------ year section end ------ */}

                    {/* ------ grade section start ------ */}
                    <div className="col-lg-2">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Grade
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {education.grade}
                        </p>
                      </div>
                    </div>
                    {/* ------ grade section end ------ */}

                    
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        

        {/* certificates */}
        <div className="skills-container p-3 p-md-4">
          <h3>Certificates</h3>
          {certificates.map((certificate, index) => {
            return (
              <div className="field" key={index}>
                <div className="form-group">
                  <div className="row">
                    {/* ----- course section start ----- */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Course
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {certificate.name}
                        </p>
                      </div>
                    </div>
                    {/* ----- course section end ----- */}

                    {/* ----- company section start ----- */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          Company
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {certificate.companyname}
                        </p>
                      </div>
                    </div>
                    {/* ----- company section end ----- */}

                    {/* ----- start date section start ----- */}
                    <div className="col-lg-2 mb-3 mb-lg-0">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          From
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {certificate.start}
                        </p>
                      </div>
                    </div>
                    {/* ----- start date section end ----- */}

                    {/* ----- end date section start ----- */}
                    <div className="col-lg-2">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                        <label htmlFor="degree" className="text-nowrap me-3">
                          To
                        </label>
                        <p className="form-control border-0 shadow-none">
                          {certificate.end}
                        </p>
                      </div>
                    </div>
                    {/* ----- end date section end ----- */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* language */}
        <div className="skills-container p-3 p-md-4">
          <h3>Language</h3>
          <ul className="skills d-flex flex-wrap gap-2 fs-sm fw-semibold mb-3">
            {userLanguages.map((lang, index) => {
              return (
                <li
                  key={index}
                  className="px-3 py-2 border border-gray-300 rounded-10"
                >
                  {lang}
                </li>
              );
            })}
          </ul>
        </div>

        {/* Optional Skills */}
        <div className="skills-container p-3 p-md-4">
          <h3>Optional Skills</h3>
          <ul className="skills d-flex flex-wrap gap-2 fs-sm fw-semibold mb-3">
            {otherSkills.map((skill, index) => {
              return (
                <li
                  key={index}
                  className="px-3 py-2 border border-gray-300 rounded-10"
                >
                  {skill}
                </li>
              );
            })}
          </ul>
        </div>

        {/* Potfolio links */}
        <div className="skills-container p-3 p-md-4">
          <h3>Portfolio links</h3>
          <ul className="skills d-flex flex-wrap gap-2 fs-sm fw-semibold mb-3">
            {userPortfolioLinks.map((linkText, index) => {
              return (
                <li
                  key={index}
                  className="px-3 py-2 border border-gray-300 rounded-10"
                >
                  {linkText}
                </li>
              );
            })}
          </ul>
        </div>

        {/* hobbies */}
        <div className="skills-container p-3 p-md-4">
          <h3>Hobbies</h3>
          <ul className="skills d-flex flex-wrap gap-2 fs-sm fw-semibold mb-3">
            {userHobbies.map((hobby, index) => {
              return (
                <li
                  key={index}
                  className="px-3 py-2 border border-gray-300 rounded-10"
                >
                  {hobby}
                </li>
              );
            })}
          </ul>
        </div>

        {/* ctc */}
        <div className="expected-ctc p-3 p-md-4">
          <h3>Expected CTC</h3>
          <p className="mb-1">Min: {currCtc}</p>
          <p>Max: {expCtc}</p>
        </div>

        {/* working hours and timings */}
        <div className="expected-ctc p-3 p-md-4">
          <h3>Timings</h3>
          <p className="mb-1">Start: {userStartTime}</p>
          <p>End: {userEndTime}</p>
        </div>

        <div className="action p-3 p-md-4 d-flex justify-content-between flex-wrap gap-3">
          <Link
            to="#offcanvasCommentYourThought"
            className="btn btn-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCommentYourThought"
            aria-controls="offcanvasResponseApproved"
            onClick={() => {
              approveStatusHandler("1");
            }}
          >
            Approve
          </Link>
          <Link
            to="#offcanvasCommentYourThought"
            className="btn btn-outline-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasCommentYourThought"
            aria-controls="offcanvasResponseApproved"
            onClick={() => {
              approveStatusHandler("1");
            }}
          >
            Reject
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResponseDetailsPopup;
