/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

// Material UI table
import { MaterialReactTable } from 'material-react-table';

//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import { assetImages } from 'constants';

import GroupMembers from '../../../../../data/Groups/GroupMembers.json';
import GroupMembersHeader from '../GroupMembersHeader/GroupMembersHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import MemberDetailsModal from '../Modals/MemberDetailsModal';
import ChangeRoleModal from '../Modals/ChangeRoleModal';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import AddNewMemberModal from '../Modals/AddNewMemberModal';
import MemberDetailsPopup from '../Popup/MemberDetailsPopup';
import { groupDetailsHeaderLinks } from 'helper/GroupHelper/GroupHelper';
import { useTranslation } from 'react-i18next';

const GroupMembersBody = () => {
  const params = useParams();
  const token = localStorage.getItem('token');
  const { t, i18n } = useTranslation(); //for translation
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  // -------- tabs header object --------------------
  const commonHeaderObject = groupDetailsHeaderLinks(params.id, t);

  // const { screenHeight } = useContext(GlobalProvider);
  const [roleList, setRoleList] = useState([]);
  const [memberList, setMemberList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);

  const [memberId, setMemberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //function for get all roles
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLES +
        `?token=${token}&slug=MOD_GROUP`;

      const response = await getData(requestUrl);

      if (response.status) {
        setRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllGroupMembers = async () => {
    setRowSelection({});
    setSelectedMemberIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_GROUP_MEMBERS +
        `?token=${token}&group=${params.id}`;

      const response = await getData(requestUrl);

      // console.log(response);
      setIsLoading(false);
      if (response.status) {
        setMemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async e => {
    e.preventDefault();

    let responseArr = [];

    for (let memberId of selectedMemberIds) {
      try {
        let eventData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_EVENT_MEMBER +
          `/${memberId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, eventData);

        if (response.status) {
          responseArr.push(response.status);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllGroupMembers();
  };

  useEffect(() => {
    getAllRoles();
    getAllGroupMembers();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '35px', height: '35px' }}
              >
                <img
                  src={
                    row.original.image == ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.image
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">{row.original.name}</p>
                <Link to="#" className="d-block lh-1">
                  {row.original.email}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'role',
        header: t('Role'),
      },
      {
        accessorKey: 'moderatorstatus',
        header: t('Status'),
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <ul className="d-flex flex-wrap gap-3">
              {row.original.moderatorstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold"></span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.moderatorstatus == '1'
                        ? 'text-success'
                        : row.original.moderatorstatus == '0'
                        ? 'text-primary'
                        : 'text-gray'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Moderator</span>
                </li>
              )}
              {row.original.memberstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold">--</span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.memberstatus == '1'
                        ? 'text-success'
                        : row.original.memberstatus == '0'
                        ? 'text-gray'
                        : 'text-warning'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Member</span>
                </li>
              )}
            </ul>
          </div>
        ),
      },
      {
        accessorKey: 'requestdate',
        header: t('Date'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Labels'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: '#',
        header: t('Action'),
        Cell: ({ row }) => (
          <div className="action border-bottom-0">
            {' '}
            {row.original.role == 'Moderator' ? (
              <Link to="#">-</Link>
            ) : (
              <Link
                to="#offcanvasMemberDetails"
                className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasMemberDetails"
                aria-controls="offcanvasMemberDetails"
                onClick={() => {
                  setMemberId(row.original._id);
                }}
              >
                <span className="d-block">{t('Details')}</span>
                <span className="d-block material-symbols-outlined">
                  chevron_right
                </span>
              </Link>
            )}
          </div>
        ),
      },
    ],
    [memberList, i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedMemberIds(selectedIdsArray);
    } else {
      setSelectedMemberIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_GROUP')
  ) {
    return (
      <div id="content_wrapper">
        <section className="group-details-wrapper bg-white pb-5">
          {/* --- common header --- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t('Members')}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- search / filter */}
            <GroupMembersHeader reloadList={getAllGroupMembers} />

            {/* ----- table section ----- */}

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={memberList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem
                  //     key="edit"
                  //     //onClick={() => console.info(row.id)}
                  //   >
                  //     <li>
                  //       <Link to="#" className="dropdown-item">
                  //         <div className="form-check form-switch d-flex align-items-center gap-3 justify-content-between p-0 cursor-pointer m-0">
                  //           <label
                  //             className="form-check-label"
                  //             htmlFor="flexSwitchCheckDefault"
                  //           >
                  //             Enable/Disable
                  //           </label>
                  //           <input
                  //             className="form-check-input float-none m-0 shadow-none"
                  //             type="checkbox"
                  //             role="switch"
                  //             id="flexSwitchCheckDefault"
                  //           />
                  //         </div>
                  //       </Link>
                  //     </li>
                  //   </MenuItem>,
                  //   <MenuItem
                  //     key="delete"
                  //     //onClick={() => console.info(row.id)}
                  //   >
                  //     <Link
                  //       className="dropdown-item"
                  //       to="#"
                  //       data-bs-toggle="modal"
                  //       data-bs-target="#changeRole"
                  //     >
                  //       Change the Role
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== modals and popup section ==== */}
        {/* ---- add label modal --- */}
        <AddLabelModal moduleName="" />

        {/* add new member modal */}
        <AddNewMemberModal
          roleList={roleList}
          afterCloseModalHandler={getAllGroupMembers}
        />

        {/* --- member details popup ---- */}
        <MemberDetailsPopup
          groupId={params.id}
          roleList={roleList}
          memberId={memberId}
          setMemberId={setMemberId}
          afterPopupClose={getAllGroupMembers}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default GroupMembersBody;
