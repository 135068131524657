import React from 'react';
import GroupProductDetailsCommonHeader from '../../GroupProductDetailsCommonHeader/GroupProductDetailsCommonHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import GroupProductImageDeleteModal from '../Modals/GroupProductImageDeleteModal';

const GroupProductImagesBody = () => {
  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white">
        {/* --------- common header --------- */}
        <GroupProductDetailsCommonHeader activeTab="images" />

        {/* ----------- images ----------- */}
        <div className="container-fluid px-lg-5 py-4 py-lg-5">
          <div className="p-3 p-lg-4 pb-1 pb-lg-2 border border-gray-300 rounded-10 shadow-sm">
            <div className="row gx-3">
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="file border border-gray-300 rounded-10 overflow-hidden">
                  <div className="img-container ratio ratio-4x3">
                    <img
                      src="/assets/img/uploaded-image.jpg"
                      alt="Uploaded"
                      className="img-fluid"
                    />
                  </div>
                  <div className="desc p-3 d-flex align-items-center justify-content-between gap-2">
                    <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                      <input
                        type="checkbox"
                        id="uploaded1"
                        className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="uploaded1"
                      >
                        Make Cover
                      </label>
                    </div>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                    >
                      <span className="d-block material-symbols-outlined">
                        delete
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="file border border-gray-300 rounded-10 overflow-hidden">
                  <div className="img-container ratio ratio-4x3">
                    <img
                      src="/assets/img/uploaded-image-2.jpg"
                      alt="Uploaded"
                      className="img-fluid"
                    />
                  </div>
                  <div className="desc p-3 d-flex align-items-center justify-content-between gap-2">
                    <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                      <input
                        type="checkbox"
                        id="uploaded2"
                        className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="uploaded2"
                      >
                        Make Cover
                      </label>
                    </div>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                    >
                      <span className="d-block material-symbols-outlined">
                        delete
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="ratio ratio-4x3 h-100">
                  <label
                    htmlFor="uploadFile1"
                    className="file h-100 d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <p className="text-center">
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </p>
                    <span className="fs-xs">Max 2mb jpg</span>
                    <input type="file" id="uploadFile1" className="d-none" />
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="ratio ratio-4x3 h-100">
                  <label
                    htmlFor="uploadFile1"
                    className="file h-100 d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <p className="text-center">
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </p>
                    <span className="fs-xs">Max 2mb jpg</span>
                    <input type="file" id="uploadFile1" className="d-none" />
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="ratio ratio-4x3 h-100">
                  <label
                    htmlFor="uploadFile1"
                    className="file h-100 d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <p className="text-center">
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </p>
                    <span className="fs-xs">Max 2mb jpg</span>
                    <input type="file" id="uploadFile1" className="d-none" />
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="ratio ratio-4x3 h-100">
                  <label
                    htmlFor="uploadFile1"
                    className="file h-100 d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <p className="text-center">
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </p>
                    <span className="fs-xs">Max 2mb jpg</span>
                    <input type="file" id="uploadFile1" className="d-none" />
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="ratio ratio-4x3 h-100">
                  <label
                    htmlFor="uploadFile1"
                    className="file h-100 d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <p className="text-center">
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </p>
                    <span className="fs-xs">Max 2mb jpg</span>
                    <input type="file" id="uploadFile1" className="d-none" />
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="ratio ratio-4x3 h-100">
                  <label
                    htmlFor="uploadFile1"
                    className="file h-100 d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <p className="text-center">
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </p>
                    <span className="fs-xs">Max 2mb jpg</span>
                    <input type="file" id="uploadFile1" className="d-none" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------ delete modal ------------ */}
      <GroupProductImageDeleteModal />
    </div>
  );
};

export default GroupProductImagesBody;
