/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import translation function for language translation
import { useTranslation } from "react-i18next";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";

import SaveTaskTab from "./Tabs/SaveTaskTab";
import ViewTaskTab from "./Tabs/ViewTaskTab";
import LogTab from "./Tabs/LogTab";
import AlertNotification from "../AlertNotification/AlertNotification";
import NoteSection from "../NoteComponent/NoteSection";

const SaveTaskBody = () => {
  const params = useParams();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = localStorage.getItem("token"); // token

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [showTaskTab, setshowTaskTab] = useState(false);
  const [showLogTab, setshowLogTab] = useState(false);

  const [isModerator, setIsModerator] = useState(false);

  const [taskProjectId, setTaskProjectId] = useState(null);
  const [taskLeadId, settaskLeadId] = useState(null);
  const [taskContactId, settaskContactId] = useState(null);
  const [taskCustomId, setTaskCustomId] = useState(null);

  const [isMilestone, setIsMilestone] = useState(false);

  const [taskTitle, setTaskTitle] = useState("");
  const [assignedUserValue, setassignedUserValue] = useState(null);
  const [assignedUserId, setassignedUserId] = useState(null);

  const [taskStatusValue, settaskStatusValue] = useState(null);
  const [taskStatus, settaskStatus] = useState("");
  const [taskStatusResponse, setTaskStatusResponse] = useState("");

  const [followrsValue, setFollowrsValue] = useState(null);
  const [selectedFollowers, setSelectedFollowers] = useState([]);

  const [details, setdetails] = useState("");

  const [sectionValue, setsectionValue] = useState(null);
  const [selectedSectionId, setselectedSectionId] = useState(null);

  const [prevTaskValue, setPrevTaskValue] = useState(null);
  const [previousTaskId, setPreviousTaskId] = useState(null);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [hours, sethours] = useState("");
  const [price, setPrice] = useState("");

  const [priorityValue, setPriorityValue] = useState(null);
  const [taskPriority, setTaskPriority] = useState("");
  const [taskViewPriority, settaskViewPriority] = useState("");

  const [taskPercent, settaskPercent] = useState(0);
  const [rank, setRank] = useState(1);

  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);

  const [assignedUserName, setAssignedUserName] = useState("");
  const [assignedUserImage, setAssignedUserImage] = useState("");
  const [taskFollowers, setTaskFollowers] = useState([]);
  const [projectName, setprojectName] = useState("");

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //* Switch between task and log tab based on the tabName parameter */
  const switchTabs = (tabName) => {
    setshowTaskTab(false);
    setshowLogTab(false);
    if (tabName == "task") {
      setshowTaskTab(true);
    }
    if (tabName == "log") {
      setshowLogTab(true);
    }
  };

  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in view task details", response);

      if (response.status && response.data) {
        if (
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          userInfo._id.toString() === response.data.moderator.toString()
        ) {
          setIsModerator(true);
        } else {
          setIsModerator(false);
        }

        setTaskProjectId(
          response.data.project ? response.data.project?._id : null
        );
        settaskLeadId(response.data.leadid);
        settaskContactId(response.data.contact ?? null);
        setTaskCustomId(response.data.customid);

        setTaskTitle(response.data.title);

        setIsMilestone(
          response.data.ismilestone ? response.data.ismilestone : false
        );

        setassignedUserValue(response.data.assigneduserdata);
        setassignedUserId(
          response.data.assigneduser ? response.data.assigneduser._id : null
        );

        settaskStatus(response.data.taskstatus);
        setTaskStatusResponse(response.data.completedpercent);
        TaskStatusOptions.map((statusData) => {
          if (statusData.label === response.data.taskstatus) {
            settaskStatusValue(statusData);
          }
        });

        settaskViewPriority(response.data.taskpriority);

        setFollowrsValue(response.data.followersarr);
        setSelectedFollowers(
          response.data.followersarr.map((item) => item.value)
        );

        setTaskFollowers(response.data.followersarr);

        setdetails(response.data.description);

        setsectionValue(response.data.sectiondata ?? null);
        setselectedSectionId(
          response.data.sectiondata ? response.data.sectiondata.value : null
        );

        if (response.data.previoustaskvalue) {
          setPrevTaskValue(response.data.previoustaskvalue);
          setPreviousTaskId(response.data.previoustaskvalue.value);
        }

        setAssignedUserName(response.data.assignedusername);
        setAssignedUserImage(response.data.assigneduserimage);

        setprojectName(response.data.projectname);

        sethours(response.data.hours);
        setPrice(response.data.price ?? 0);
        setstartDate(response.data.startdate);
        setendDate(response.data.enddate);

        TaskPriority.map((priorityData) => {
          if (priorityData.value === response.data.priority) {
            setPriorityValue(priorityData);
            setTaskPriority(response.data.priority);
          }
        });

        settaskPercent(response.data.taskpercent ?? 0);

        setRank(response.data.rank || 1);

        //assign uploaded files
        if (
          response.data.uploadedfiles &&
          response.data.uploadedfiles.length > 0
        ) {
          const uploadedFileValues = response.data.uploadedfiles.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          );

          setUploadedDocFiles(uploadedFileValues);
        }

        if (
          response.data.uploadedfileids &&
          response.data.uploadedfileids.length > 0
        ) {
          const fileIds = response.data.uploadedfileids.map(
            ({ _id, ...other }) => ({
              _id,
            })
          );

          setUploadedDocFileIds(fileIds);
        }

        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  useEffect(() => {
    switchTabs("task");
  }, []);

  useEffect(() => {
    if (params.id) {
      getTaskDetails();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5 pt-3">
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                <ul className="nav nav-tabs w-100 flex-nowrap fs-sm lh-1 fw-semibold text-center border-0 text-nowrap overflow-auto mt-3">
                  {/* task tab title */}
                  <li className="nav-item flex-fill" role="presentation">
                    <Link
                      to="#"
                      className={`text-black d-block px-3 py-3 position-relative ${
                        showTaskTab && "active"
                      }`}
                      onClick={() => {
                        switchTabs("task");
                      }}
                    >
                      {t("Tasks Details")}
                    </Link>
                  </li>
                  {/* log tab title */}

                  {params.id && (
                    <li className="nav-item flex-fill" role="presentation">
                      <Link
                        to="#"
                        className={`text-black d-block px-3 py-3 position-relative ${
                          showLogTab && "active"
                        }`}
                        onClick={() => {
                          switchTabs("log");
                        }}
                      >
                        {t("Logs")}
                      </Link>
                    </li>
                  )}
                </ul>

                <div className="tab-content h-100" id="myTabContent">
                  {/* task view tab */}
                  {showTaskTab ? (
                    isModerator ? (
                      <SaveTaskTab
                        moduleName={"taskdetails"}
                        taskContactId={taskContactId}
                        taskLeadId={taskLeadId}
                        memberList={[]}
                        taskProjectId={taskProjectId}
                        sectionOptions={[]}
                        taskMileStone={isMilestone}
                        taskCustomId={taskCustomId}
                        taskTitle={taskTitle}
                        taskAssignedUserValue={assignedUserValue}
                        taskAssignedUserId={assignedUserId}
                        taskCurrentStatusValue={taskStatusValue}
                        taskCurrentStatus={taskStatusResponse}
                        taskFollowersvalue={followrsValue}
                        taskFollowersSelected={selectedFollowers}
                        taskDetails={details}
                        taskSectionValue={sectionValue}
                        taskSelectedSectionId={selectedSectionId}
                        previousTaskValue={prevTaskValue}
                        previousTaskIdOfTask={previousTaskId}
                        taskHours={hours}
                        taskPrice={price}
                        taskStartDate={startDate}
                        taskEndDate={endDate}
                        taskPriorityValue={priorityValue}
                        taskPriorityData={taskPriority}
                        taskPercent={taskPercent}
                        taskRank={rank}
                        taskFiles={uploadedDocFiles}
                        taskFileIds={uploadedDocFileIds}
                        setShowAlert={setShowAlert}
                        setAlertMessage={setAlertMessage}
                        setMessageType={setMessageType}
                      />
                    ) : (
                      <ViewTaskTab
                        taskCustomId={taskCustomId}
                        assignedUserName={assignedUserName}
                        assignedUserImage={assignedUserImage}
                        taskFollowers={taskFollowers}
                        taskTitle={taskTitle}
                        taskStatus={taskStatus}
                        projectName={projectName}
                        hours={hours}
                        startDate={startDate}
                        endDate={endDate}
                        priority={taskViewPriority}
                        price={price}
                        details={details}
                        uploadedDocFiles={uploadedDocFiles}
                      />
                    )
                  ) : null}
                  {/* log tab */}
                  {showLogTab && (
                    <LogTab
                      taskContactId={taskContactId}
                      taskLeadId={taskLeadId}
                      taskProjectId={taskProjectId}
                      taskHours={hours}
                      taskStartDate={startDate}
                      taskEndDate={endDate}
                      taskPercentResponse={taskPercent}
                      taskStatusValueResponse={taskStatusValue}
                      taskStatusDataResponse={taskStatus}
                      setShowAlert={setShowAlert}
                      setAlertMessage={setAlertMessage}
                      setMessageType={setMessageType}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <NoteSection
                taskId={params.id}
                setShowAlert={setShowAlert}
                setAlertMessage={setAlertMessage}
                setMessageType={setMessageType}
              />
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default SaveTaskBody;
