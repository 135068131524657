/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import MailTemlplateDetailsBody from "components/AdministrationComponents/MailTemplateComponents/MailTemplateDetails/MailTemlplateDetailsBody";

const SaveMailTemplateDetails = () => {
  useEffect(() => {
    document.title = "Administration | Mail template";
    window.scroll(0, 0);
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <MailTemlplateDetailsBody />
    </main>
  );
};

export default SaveMailTemplateDetails;
