/* eslint-disable */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./Routes";

const Authmiddleware = () => {
  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  // console.log(parsedURL.href);

  // if (window.location.href.split("/").pop() != "logout") {
  //   localStorage.setItem("startingURL", window.location.href);
  // }

  // const refParam = searchParams.get("ref");
  // const secretParam = searchParams.get("secret");

  // let redirectUrl = "";
  // let pathWithoutDomain = "";

  // if (refParam && secretParam) {
  //   redirectUrl = new URL(refParam);
  //   pathWithoutDomain = redirectUrl.pathname;
  // }

  let token = null;

  token = localStorage.getItem("token"); //get token from local storage

  return (
    <Switch>
      {/* <!-- ========== Start non authenticated route Section ========== --> */}
      {!token &&
        publicRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}
      {/* <!-- ========== End non authenticated route Section ========== --> */}

      {/* <!-- ========== Start authenticated route Section ========== --> */}
      {token &&
        protectedRoutes.map((routename, index) => (
          <Route
            path={routename.path}
            component={routename.component}
            exact
            key={index}
          />
        ))}
      {/* <!-- ========== End authenticated route Section ========== --> */}

      {/* <!-- ========== Start default redirect to dashboard ========== --> */}
      {token ? <Redirect to="/admin/dashboard" /> : <Redirect to="/" />}
      {/* {token ? (
        refParam && secretParam ? (
          <Redirect to={`${pathWithoutDomain}?secret=${secretParam}`} />
        ) : (
          <Redirect to="/admin/dashboard" />
        )
      ) : (
        <Redirect to="/" />
      )} */}
      {/* <!-- ========== End default redirect to dashboard ========== --> */}
    </Switch>
  );
};

export default Authmiddleware;
