/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import VatOptions from "data/Invoice/VAT.json";

import * as url from "helper/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";

import { ecosystemSlug, invoiceOwnerEmail, invoiceBillingAddress } from "Config/Config";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";
import { assetImages } from "constants";

const InvoiceDetailsBody = () => {
  const token = localStorage.getItem("token");
  const ecosystemLogo = localStorage.getItem("ecosystemlogo");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation
  const history = useHistory();
  const params = useParams();

  const breadcrumbText = [
    { title: t("Invoice"), link: "/admin/invoice/list" },
    { title: t("Save/Details") },
  ];

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(params.id);

  const [allowModification, setAllowModification] = useState(true);

  const [clientList, setClientList] = useState([]); //client option
  const [addClient, setAddClient] = useState(true); // to check if client added or not
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const [invoiceNote, setInvoiceNote] = useState("");

  // validation of invoice
  const [validation, setValidation] = useState({
    customerWarning: true,
    invoiceDateWarning: true,
    noteWarning: true,
  });

  // invoice default schema
  const [invoice, setInvoice] = useState({
    id: "",
    customer: {},
    plan: {},
    payment: {},
    invoiceid: "",
    invoicedate: getCurrentDateInString(),
    duedate: getCurrentDateInString(),
    amount: "",
    totaltax: 0,
    totaldiscount: 0,
    payableamount: 0,
    currency: "EURO",
    items: [],
    status: "",
    notes: [],
  });

  // this will hold the item which i am editing or adding
  const [item, setItem] = useState({
    id: "",
    description: "",
    quantity: 0,
    price: 0,
    discountpercent: 0,
    discount: 0,
    taxpercent: 0,
    tax: 0,
    amount: 0,
    taxselectedoption: "", // non-functional used for mapping react select
    descriptionwarning: false, // non-functional used for showing warning
    quantitywarning: false, // non-functional, used for showing warning
    pricewarning: false, // non-functional, used for showing warning
  });

  // copy of item, to add as blank record while add new
  const resetItem = () => {
    setItem({
      id: "",
      description: "",
      quantity: 0,
      price: 0,
      discountpercent: 0,
      discount: 0,
      taxpercent: 0,
      tax: 0,
      amount: 0,
      taxselectedoption: "", // non-functional used for mapping react select
      descriptionwarning: false, // non-functional used for showing warning
      quantitywarning: false, // non-functional, used for showing warning
      pricewarning: false, // non-functional, used for showing warning
    });
  };

  // on click of Add item
  const addItem = () => {
    console.log("item", item);

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      items: [...prevInvoice.items, item],
    }));

    resetItem();
  };

  // update invoice calculation
  const handleInvoiceItemUpdate = () => {
    const updatedItems = [...invoice.items];
    console.log("updatedItems", updatedItems);

    const totalAmount = updatedItems.reduce(
      (amount, item) => amount + item.price * item.quantity,
      0
    );

    const totalTax = updatedItems.reduce((tax, item) => tax + item.tax, 0);

    const totalDiscount = updatedItems.reduce(
      (disount, item) => disount + item.discount,
      0
    );

    const payableAmount = updatedItems.reduce(
      (totalamount, item) => totalamount + item.amount,
      0
    );

    console.log("totalAmount", totalAmount);

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      amount: totalAmount,
      totaltax: totalTax,
      totaldiscount: totalDiscount,
      payableamount: payableAmount,
    }));
  };

  // on description change, update invoice items in invoice
  const handleItemDescriptionChange = (e, index) => {
    const { value } = e.target;
    const updatedItems = [...invoice.items];
    updatedItems[index].description = value;

    if (value.length > 1) {
      updatedItems[index].descriptionwarning = false;
    } else {
      updatedItems[index].descriptionwarning = true;
    }

    setInvoice((prevInvoice) => ({ ...prevInvoice, items: updatedItems }));
  };

  // on quantity change, update invoice items in invoice
  const handleItemQuantityChange = (e, index) => {
    const { value } = e.target;
    const updatedItems = [...invoice.items];
    updatedItems[index].quantity = parseInt(value, 10);

    if (parseInt(value) > 0) {
      updatedItems[index].quantitywarning = false;
    } else {
      updatedItems[index].quantitywarning = true;
    }

    setInvoice((prevInvoice) => ({ ...prevInvoice, items: updatedItems }));

    updateItemGrossVlues(index);
  };

  // on price change, update invoice items in invoice
  const handleItemPriceChange = (e, index) => {
    const { value } = e.target;
    const updatedItems = [...invoice.items];

    updatedItems[index].price = parseInt(value, 10);

    if (parseInt(value) > 0) {
      updatedItems[index].pricewarning = false;
    } else {
      updatedItems[index].pricewarning = true;
    }

    setInvoice((prevInvoice) => ({ ...prevInvoice, items: updatedItems }));

    console.log("invoice in handleItemPriceChange", invoice);

    updateItemGrossVlues(index);
  };

  // on tax change, update invoice items in invoice
  const handleItemTaxChange = (e, index) => {
    // console.log('e:',e)
    // console.log('index', index)
    const updatedItems = [...invoice.items];

    const { value } = e;

    updatedItems[index].taxselectedoption = e; // to show in react select

    updatedItems[index].taxpercent = parseInt(value); // to get the value for calculate

    updatedItems[index].tax = parseInt(
      (updatedItems[index].price * updatedItems[index].taxpercent) / 100
    );
    setInvoice((prevInvoice) => ({ ...prevInvoice, items: updatedItems }));

    updateItemGrossVlues(index);
  };

  // on update or view, show the pre-selected value of Tax for each invoice items
  const getTaxSelectedSelectOptionValue = (val) => {
    let selectedVatOption = {};

    for (let vatOption of VatOptions) {
      if (vatOption.value === val.toString()) {
        selectedVatOption = vatOption;
      }
    }

    return selectedVatOption;
  };

  // set dependent item value on params change
  const updateItemGrossVlues = (index) => {
    const updatedItems = [...invoice.items];
    const quantity = parseInt(updatedItems[index].quantity);
    const price = parseInt(updatedItems[index].price);
    const tax = parseInt(updatedItems[index].taxpercent);

    if (quantity > 0 && price > 0) {
      const taxToPay = parseInt((quantity * price * tax) / 100, 10);
      const totalPrice = parseInt(quantity * price, 10) + taxToPay;

      updatedItems[index].tax = taxToPay;
      updatedItems[index].amount = totalPrice;

      setInvoice((prevInvoice) => ({ ...prevInvoice, items: updatedItems }));
    }
  };

  // delete any item from Invoice
  const removeInvoiceItem = (index, itemId) => {
    console.log("remove index", index);
    console.log("remove item id", itemId);
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      items: prevInvoice.items.filter((item, i) => i !== index),
    }));
  };

  //function for get all client list
  const getAllClientList = async () => {
    try {
      let clientRequestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&isselect=${true}&slug=client`;

      const clientResponse = await getData(clientRequestUrl);

      console.log("getAllClientList", clientResponse);

      if (clientResponse.status) {
        setClientList(clientResponse.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select client
  const clientSelectionHandler = (val) => {
    console.log("selected client value", val);

    setSelectedClient(val);
    setSelectedClientId(val.value);
    setAddClient(false);

    const newCustomer = {
      id: val.value,
      name: val.name,
      surname: val.surname,
      email: val.email,
      city: val.city,
      state: val.state,
      country: val.country,
      zipcode: val.zipcode,
    };

    setInvoice({
      ...invoice,
      customer: newCustomer,
    });
  };

  // function for validation
  const validateForm = () => {
    let isValid = true;

    if (validation.customerWarning === true) {
      isValid = false;
    }

    // if (validation.invoiceDateWarning === true) {
    //     isValid = false;
    // }

    //console.log('isValid', isValid);

    return isValid;
  };

  // save the invoice record ======================================
  const saveInvoice = async () => {
    console.log("invoice ===>", invoice);

    let requestUrl =
      url.API_BASE_URL + url.API_CREATE_INVOICE + `?token=${token}`;

    try {
      const response = await postData(requestUrl, invoice);

      console.log("requestURL ", requestUrl);
      console.log("response ", response);

      if (response.status) {
        history.push("/admin/invoice/list");
      }
    } catch (error) {
      console.log("ERROR ", error.message);
    }
  };

  // get the invoice details =======================================
  const getInvoiceDetails = async () => {
    let requestUrl =
      url.API_BASE_URL +
      url.API_INVOICE_DETAILS +
      `/${selectedInvoiceId}` +
      `?token=${token}`;

    try {
      const response = await getData(requestUrl);

      console.log("invoice details ----->", response);

      if (response.status) {
        const invoiceData = response.data;

        const invoiceItemsData = [];

        // map customer ----------------------
        const invoiceCustomer = {
          id: invoiceData.client?._id,
          name: invoiceData.client?.name,
          surname: invoiceData.client?.surname,
          email: invoiceData.client?.email,
          city: invoiceData.client?.city,
          state: invoiceData.client?.state,
          country: invoiceData.client?.country,
          zipcode: invoiceData.client?.zipcode,
        };

        // map invoice items --------------------
        invoiceData.invoiceitems.length > 0 &&
          invoiceData.invoiceitems.map((invoiceItem, index) => {
            const itemData = {
              id: invoiceItem._id,
              description: invoiceItem.description,
              quantity: invoiceItem.unit,
              price: invoiceItem.price,
              discountpercent: invoiceItem.discountpercent,
              discount: invoiceItem.discountamount,
              taxpercent: invoiceItem.taxpercent,
              tax: invoiceItem.taxamount,
              amount: invoiceItem.totalprice,
              taxselectedoption: getTaxSelectedSelectOptionValue(
                invoiceItem.taxpercent
              ),
              descriptionwarning: false,
              quantitywarning: false,
              pricewarning: false,
            };

            invoiceItemsData.push(itemData);
          });

        // map invoice data ---------------------
        setInvoice((prevInvoice) => ({
          ...prevInvoice,
          id: selectedInvoiceId,
          plan: invoiceData.plan,
          payment: invoiceData.payment,
          customer: invoiceCustomer,
          invoiceid: invoiceData.invoiceid,
          invoicedate: invoiceData.invoicedate,
          duedate: invoiceData.duedate,
          items: invoiceItemsData,
          amount: invoiceData.totalprice,
          totaltax: invoiceData.totaltax,
          totaldiscount: invoiceData.totaldiscount,
          payableamount: invoiceData.totalpayable,
          notes: invoiceData.notes,
        }));

        // disable modification on certain condition ---------
        if (invoiceData.plan) {
          setAllowModification(false);
        }

        // validation mapping false as if edit, we assume we already have customer and invoice data -----
        if (invoiceData.client) {
          setValidation((prevState) => ({
            ...prevState,
            customerWarning: false,
          }));
        }

        if (invoiceData.invoicedate) {
          setValidation((prevState) => ({
            ...prevState,
            invoiceDateWarning: false,
          }));
        }
      }
    } catch (error) {
      console.log("error", error.message);
    }
  };

  // save not to invoice ================================
  const saveNote = async () => {
    let requestUrl =
      url.API_BASE_URL +
      url.API_INVOICE_ADD_NOTE +
      `/${selectedInvoiceId}` +
      `?token=${token}`;

    try {
      const data = {
        message: invoiceNote,
      };

      const response = await postData(requestUrl, data);

      setInvoiceNote("");

      //console.log('requestURL ', requestUrl);
      console.log("response ", response);

      if (response.status) {
        const existingNotes = invoice.notes;

        existingNotes.push(response.data);

        setInvoice((prevInvoice) => ({ ...prevInvoice, notes: existingNotes }));
      }
    } catch (error) {
      console.log("ERROR ", error.message);
    }
  };

  // get client list and default UI
  useEffect(() => {
    getAllClientList();

    if (invoice.items.length === 0) {
      addItem();
    }
  }, []);

  // update cost and other info when any invoiceitem data changes in invoice
  useEffect(() => {
    handleInvoiceItemUpdate();
  }, [invoice.items]);

  // get saved invoice data to show and update

  useEffect(() => {
    console.log("selectedInvoiceId", selectedInvoiceId);

    if (selectedInvoiceId) {
      getInvoiceDetails();
    }
  }, [selectedInvoiceId]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_INVOICE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="invoice-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <BreadCrumb breadCrumbText={breadcrumbText} />

            {/* Invoice sender and basic info */}
            <div className="invoice mt-4">
              <div className="d-flex align-items-center justify-content-between gap-3 mb-1">
                <img
                  src={url.SERVER_URL + ecosystemLogo}
                  alt="FindelOI"
                  className="img-fluid"
                  style={{ maxHeight: "28px" }}
                />
                <div className="d-flex align-items-center gap-3">
                  {/* <Link to="#" className="text-black">
                    <span className="d-block material-symbols-outlined">
                      print
                    </span>
                  </Link>
                  <Link to="#" className="text-black">
                    <span className="d-block material-symbols-outlined">
                      download
                    </span>
                  </Link> */}
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row">
                {invoiceBillingAddress.address ? (
                  <p className="fs-md flex-fill mb-3">
                    <span className="d-block">
                      {invoiceBillingAddress.address}
                    </span>
                    <span className="d-block text-black">{invoiceBillingAddress.supportmail}</span>
                    <span className="d-block text-black">{invoiceBillingAddress.phone}</span>
                    <span className="d-block text-black">{invoiceBillingAddress.website}</span>
                  </p>
                ): (
                  <p className="fs-md flex-fill mb-3">
                    <span className="d-block">
                      Padre Piquer, 14, 28024, Madrid, Espafia
                    </span>
                    <span className="d-block text-black">support@lobees.com</span>
                    <span className="d-block text-black">(+34) 615 43 94 22</span>
                    <span className="d-block text-black">
                      www.lobees.com
                    </span>
                  </p>

                )}
                
                <ul className="flex-fill h3 d-flex flex-column gap-4 fw-semibold">
                  <li className="d-flex align-items-center gap-3">
                    <span className="d-block">{t("Invoice Number")}: </span>
                    <span className="d-block text-primary">
                      {invoice.invoiceid}
                    </span>
                  </li>
                  {invoice.plan ? (
                    <li className="d-flex align-items-center gap-3">
                      <span className="d-block">
                        {t("Subscription Plan")}:{" "}
                      </span>
                      <span className="d-block text-primary">
                        {invoice.plan.title} ({invoice.plan.duration} days)
                      </span>
                    </li>
                  ) : (
                    ""
                  )}

                  <li className="d-flex align-items-center gap-3">
                    <span className="d-block">{t("Amount Payable")}: </span>
                    <span className="d-block text-primary">
                      {invoice.payableamount} €
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Invoice details */}
            <div className="p-3 border border-gray-300 rounded-8 mb-3">
              <div className="row">
                {/* Client or customer details */}
                <div className="col-md mb-4 mb-md-0">
                  <h5 className="fw-semibold mb-2 mb-md-3">
                    {t("Customer Information")}
                  </h5>
                  {invoice.id.length < 1 ? (
                    <Select
                      options={clientList}
                      value={selectedClient}
                      onChange={(val) => {
                        setValidation((prevState) => ({
                          ...prevState,
                          customerWarning: false,
                        }));
                        clientSelectionHandler(val);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {invoice.customer && invoice.customer.id ? (
                    <p className="fs-sm mt-2">
                      <span className="d-block text-uppercase">
                        {invoice.customer.name} {invoice.customer.surname}{" "}
                      </span>
                      <span className="d-block text-black">
                        {invoice.customer.email}
                      </span>
                      <span className="d-block text-black">
                        {invoice.customer.address}
                      </span>
                      <span className="d-block text-black">
                        {invoice.customer.city} {invoice.customer.country}{" "}
                        {invoice.customer.zipcode}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {/* Order or Invoice info */}
                <div className="col-md mb-4 mb-md-0">
                  <h5 className="fw-semibold mb-2 mb-md-3">
                    {t("Order Information")}
                  </h5>
                  <div className="form-group d-flex align-items-center mb-2">
                    <label
                      htmlFor=""
                      className="d-block fs-sm fw-semibold mb-2"
                      style={{ minWidth: "6.25rem" }}
                    >
                      {t("Invoice No")}:
                    </label>
                    <input
                      type="text"
                      className="form-control px-3 py-2 shadow-none"
                      value={invoice.invoiceid}
                    />
                  </div>
                  <div className="form-group d-flex align-items-center mb-2">
                    <label
                      for=""
                      className="d-block fs-sm fw-semibold mb-2"
                      style={{ minWidth: "6.25rem" }}
                    >
                      {t("Order Date")}:
                    </label>
                    <input
                      type="date"
                      className="form-control px-3 py-2 shadow-none"
                      value={invoice.invoicedate}
                      onChange={(e) => {
                        setInvoice({
                          ...invoice,
                          invoicedate: e.target.value,
                        });

                        setValidation((prevState) => ({
                          ...prevState,
                          invoiceDateWarning: false,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-group d-flex align-items-center">
                    <label
                      for=""
                      className="d-block fs-sm fw-semibold mb-2"
                      style={{ minWidth: "6.25rem" }}
                    >
                      {t("Due Date")}:
                    </label>
                    <input
                      type="date"
                      className="form-control px-3 py-2 shadow-none"
                      value={invoice.duedate}
                      onChange={(e) => {
                        setInvoice({
                          ...invoice,
                          duedate: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                {/* Payment info */}
                <div className="col-md">
                  <h5 className="fw-semibold mb-2 mb-md-3">
                    {t("Payment Information")}
                  </h5>
                  <div className="form-group d-flex align-items-center mb-2">
                    <label
                      for=""
                      className="d-block fs-sm fw-semibold mb-2"
                      style={{ minWidth: "6.25rem" }}
                    >
                      {t("Status")}:
                    </label>
                    <span className="d-block">
                      {invoice.payment ? "DONE" : "PENDING"}
                    </span>
                  </div>
                  <div className="form-group d-flex align-items-center mb-2">
                    <label
                      for=""
                      className="d-block fs-sm fw-semibold mb-2"
                      style={{ minWidth: "6.25rem" }}
                    >
                      {t("Amount")}:
                    </label>
                    <span className="d-block">
                      {invoice.payment && invoice.payment.amount
                        ? invoice.payment.amount
                        : "PENDING"}
                    </span>
                  </div>
                  <div className="form-group d-flex align-items-center">
                    <label
                      for=""
                      className="d-block fs-sm fw-semibold mb-2"
                      style={{ minWidth: "6.25rem" }}
                    >
                      {t("Payment Date")}:
                    </label>
                    <span className="d-block">
                      {invoice.payment
                        ? getCurrentDateInString(
                            invoice.payment.createdAt,
                            "dd/mm/yyyy"
                          )
                        : " --- "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Invoice Items */}
            <div className="table-wrapper pb-5 mb-5 border-bottom border-gray">
              <div className="table-responsive">
                <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                  <thead>
                    <tr>
                      <th
                        width="40%"
                        className="description bg-transparent fs-md fw-semibold border-0"
                      >
                        {t("Description")}
                      </th>
                      <th className="quantity bg-transparent fs-md fw-semibold border-0">
                        {t("Quantity")}
                      </th>
                      <th className="amt bg-transparent fs-md fw-semibold border-0">
                        {t("Price")}
                      </th>
                      <th className="taxes bg-transparent fs-md fw-semibold border-0">
                        {t("Tax (IVA)")}
                      </th>
                      <th className="pay-amt bg-transparent fs-md fw-semibold border-0">
                        {t("Total Amount")}
                      </th>
                      <th className="bg-transparent fs-md fw-semibold border-0"></th>
                    </tr>
                  </thead>
                  <tbody style={{ height: "200px" }}>
                    {invoice.items.map((item, index) => {
                      return (
                        <tr key={index} style={{ verticalAlign: "top" }}>
                          <td className="description border-bottom-0 p-1">
                            <input
                              type="text"
                              className={`form-control bg-white shadow-none ${
                                item.descriptionwarning
                                  ? "border border-danger"
                                  : ""
                              }`}
                              value={item.description}
                              onChange={(e) =>
                                handleItemDescriptionChange(e, index)
                              }
                            />
                          </td>
                          <td className="quantity border-bottom-0 p-1">
                            <input
                              className={`form-control bg-white shadow-none ${
                                item.quantitywarning
                                  ? "border border-danger"
                                  : ""
                              }`}
                              type="number"
                              value={item.quantity}
                              onChange={(e) =>
                                handleItemQuantityChange(e, index)
                              }
                            />
                          </td>
                          <td className="amt border-bottom-0 p-1">
                            <input
                              className={`form-control bg-white shadow-none ${
                                item.pricewarning ? "border border-danger" : ""
                              }`}
                              type="number"
                              value={item.price}
                              onChange={(e) => handleItemPriceChange(e, index)}
                            />
                          </td>
                          <td className="taxes border-bottom-0 p-0">
                            <Select
                              placeholder="IVA %"
                              options={VatOptions}
                              className="form-control "
                              value={item.taxselectedoption}
                              onChange={(e) => handleItemTaxChange(e, index)}
                            />
                          </td>
                          <td className="pay-amt border-bottom-0 p-1">
                            <span
                              className={`form-control bg-white shadow-none ${
                                item.pricewarning ? "border border-danger" : ""
                              }`}
                            >
                              {item.amount}
                            </span>
                          </td>
                          <td className="action border-bottom-0">
                            <Link
                              className={`text-black ${
                                allowModification ? "" : "d-none"
                              }`}
                              to="#"
                              onClick={() => removeInvoiceItem(index, item.id)}
                            >
                              <span className="d-block material-symbols-outlined">
                                delete
                              </span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <Link
                to="#"
                className={`btn btn-outline-primary ${
                  allowModification ? "" : "d-none"
                }`}
                onClick={() => {
                  addItem();
                }}
              >
                + {t("Add Item")}
              </Link>

              <Link
                to="#"
                className={`btn btn-primary ms-5 ${
                  allowModification ? "" : "d-none"
                }`}
                onClick={() => {
                  if (validateForm() === true) {
                    saveInvoice();
                  } else {
                    alert("client or invoice date is not selected");
                  }
                }}
              >
                {t("Save Invoice")}
              </Link>
            </div>

            {/* Invoice final amount */}
            <div className="table-wrapper pb-3 mb-3 border-bottom border-gray">
              <div className="table-responsive">
                <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                  <tbody>
                    <tr>
                      <td className="p-0 fw-semibold bg-transparent border-0">
                        {t("Total Discount")}
                      </td>
                      <td className="p-0 bg-transparent text-end border-0">
                        {invoice.totaldiscount}€
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0 fw-semibold bg-transparent border-0">
                        {t("Total Tax (VAT)")}
                      </td>
                      <td className="p-0 bg-transparent text-end border-0">
                        {invoice.totaltax}€
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0 fs-lg fw-semibold bg-transparent border-0">
                        {t("Total Payable Amount")}
                      </td>
                      <td className="p-0 fs-lg fw-semibold text-success bg-transparent text-end border-0">
                        {invoice.payableamount}€
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Notes regarding invoice only when invoice is saved */}
            <div
              className={`notes d-flex flex-column flex-md-row align-items-center gap-4 ${
                invoice.id ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label for="notes" className="d-block fs-lg fw-semibold mb-2">
                  Add Notes to invoice
                </label>
                <textarea
                  name="notes"
                  id="notes"
                  cols="30"
                  rows="5"
                  className="form-control shadow-none mb-3"
                  placeholder="Add Notes here"
                  value={invoiceNote}
                  onChange={(e) => {
                    setInvoiceNote(e.target.value);

                    if (e.target.value) {
                      setValidation((prevState) => ({
                        ...prevState,
                        noteWarning: false,
                      }));
                    } else {
                      setValidation((prevState) => ({
                        ...prevState,
                        noteWarning: true,
                      }));
                    }
                  }}
                ></textarea>
                <button
                  type="button"
                  className={`btn btn-outline-primary ${
                    validation.noteWarning ? "d-none" : ""
                  }`}
                  onClick={() => {
                    saveNote();
                  }}
                >
                  Save Note
                </button>
              </div>

              <ul className="flex-fill fw-medium text-start">
                {invoice.notes.map((note, index) => {
                  return (
                    <li>
                      {getCurrentDateInString(note.createdAt, "dd/mm/yyyy")} -{" "}
                      {note.details}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default InvoiceDetailsBody;
