import React from "react";
import { useTranslation } from "react-i18next";

const AddressAccordion = ({
  zipCode = "",
  setZipCode,
  getLocationFromZip,
  city = "",
  setCity,
  stateName = "",
  setStateName,
  country = "",
  setCountry,
  address = "",
  setAddress,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseAddress"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseAddress"
        >
          {t("Address Data")}
        </button>
      </h3>
      <div
        id="panelsStayOpen-collapseAddress"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          {/* ----- zipcode,city,state,country section start ------ */}
          <div className="form-group mb-3">
            <div className="row">
              {/* ----- zipcode section start ------ */}
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control border-0 shadow-none"
                      placeholder={t("Zipcode")}
                      value={zipCode}
                      onChange={(e) => {
                        setZipCode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-primary"
                      onClick={getLocationFromZip}
                    >
                      {t("Fetch Location by zipcode")}
                    </button>
                  </div>
                </div>
              </div>
              {/* ----- zipcode section end ------ */}
              {/* ----- city,state,country section start ------ */}
              <div className="col-lg-8">
                <div className="row">
                  {/* ----- city section start ------ */}
                  <div className="col-lg-4 mb-3 mb-lg-0">
                    <input
                      type="text"
                      className="form-control border-0 shadow-none"
                      placeholder={t("City")}
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </div>
                  {/* ----- city section end ------ */}
                  {/* ----- state section start ------ */}
                  <div className="col-lg-4 mb-3 mb-lg-0">
                    <input
                      type="text"
                      className="form-control border-0 shadow-none"
                      placeholder={t("State")}
                      value={stateName}
                      onChange={(e) => {
                        setStateName(e.target.value);
                      }}
                    />
                  </div>
                  {/* ----- state section end ------ */}
                  {/* ----- country section start ------ */}
                  <div className="col-lg-4">
                    <input
                      type="text"
                      className="form-control border-0 shadow-none"
                      placeholder={t("Country")}
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                  </div>
                  {/* ----- country section end ------ */}
                </div>
              </div>
              {/* ----- city,state,country section end ------ */}
            </div>
          </div>
          {/* ----- zipcode,city,state,country section end ------ */}
          {/* ----- address section start ------ */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center pe-3 body-bg rounded-10">
              <input
                type="text"
                className="form-control border-0 shadow-none"
                placeholder={t("Location")}
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
              <span className="d-block material-symbols-outlined icon-lg text-gray">
                my_location
              </span>
            </div>
          </div>
          {/* ----- address section end ------ */}
        </div>
      </div>
    </div>
  );
};

export default AddressAccordion;
