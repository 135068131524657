/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import images
import { assetImages } from 'constants';
import EventListHeader from '../Header/EventListHeader';
import EventListFilterPopup from '../Popup/EventListFilterPopup';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import { useTranslation } from 'react-i18next';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';
// import AddNewEventModal from "../Modal/AddNewEventModal";

const EventListBody = () => {
  const { t, i18n } = useTranslation(); //for translation
  // const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [eventTypeList, seteventTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [eventDataList, setEventDataList] = useState([]);

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterEventTitle, setFilterEventTitle] = useState('');
  const [filterEventType, setFilterEventType] = useState([]);
  const [filterEventTags, setFilterEventTags] = useState([]);
  const [filterEventCity, setfilterEventCity] = useState('');
  const [filterEventCountry, setfilterEventCountry] = useState('');
  const [filterCompanies, setfilterCompanies] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedEventIds, setSelectedEventIds] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all event type
  const getEventTypes = async () => {
    try {
      let requestUrl = url.API_BASE_URL + url.API_GET_ALL_EVENT_TYPES;

      const response = await getData(requestUrl);

      if (response.status) {
        seteventTypeList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get all lead records
  const getAllEvents = async () => {
    setRowSelection({});
    setSelectedEventIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_EVENTS + `?token=${token}`;

      if (filterEventTitle != '') {
        requestUrl = requestUrl + `&filtertitle=${filterEventTitle}`;
      }

      if (filterEventCity != '') {
        requestUrl = requestUrl + `&filtercity=${filterEventCity}`;
      }

      if (filterEventCountry != '') {
        requestUrl = requestUrl + `&filtercountry=${filterEventCountry}`;
      }

      if (filterDateRange != '') {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }

      if (filterEventTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterEventTags}`;
      }

      if (filterEventType.length > 0) {
        requestUrl = requestUrl + `&filtertype=${filterEventType}`;
      }

      if (filterCompanies.length > 0) {
        requestUrl = requestUrl + `&filtercompanyid=${filterCompanies}`;
      }

      console.log('url of event list---------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in event list------->', response);

      setIsLoading(false);
      if (response.status) {
        setEventDataList(response.data);
        resetFilterData();
        setMessageType('success');
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedEventList = [...eventDataList];

    const rowIndex = updatedEventList.findIndex(
      event => event._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedEventList[rowIndex].rank = value;
      setEventDataList(updatedEventList);
    }
  };

  //function for update rank
  const updateRankHandler = async e => {
    e.preventDefault();

    let responseArr = [];

    //run loop checkbox wise
    for (let eventId of selectedEventIds) {
      //check if checkbox was checked.If checked then proceed

      //run loop of updated bloglist
      for (let event of eventDataList) {
        let eventData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (event._id.toString() == eventId.toString()) {
          eventData.rank = event.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_EVENT +
              `/${eventId}` +
              `?token=${token}`;

            const response = await putData(requestUrl, eventData);

            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }

    if (responseArr.length > 0) {
      getAllEvents();
    }
  };

  //function for change status
  const deleteEventHandler = async () => {
    if (selectedEventIds.length > 0) {
      try {
        let eventData = {
          eventids: selectedEventIds,
        };

        let requestUrl =
          url.API_BASE_URL + url.API_DELETE_EVENTS + `?token=${token}`;

        const response = await putData(requestUrl, eventData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setAlertMessage('Please select atleast one event');
      setMessageType('error');
      setShowAlert(true);
      return;
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setFilterEventTitle('');
    setfilterEventCity('');
    setfilterEventCountry('');
    setFilterEventTags([]);
    setFilterEventType([]);
    setfilterCompanies([]);
  };

  //use effect for search filter popup
  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterEventTitle != '' ||
      filterEventCity != '' ||
      filterEventCountry != '' ||
      filterEventTags.length > 0 ||
      filterEventType.length > 0 ||
      filterCompanies.length > 0
    ) {
      getAllEvents();
    }
  }, [
    filterDateRange,
    filterEventTitle,
    filterEventCity,
    filterEventCountry,
    filterEventType,
    filterEventTags,
    filterCompanies,
  ]);

  useEffect(() => {
    getEventTypes();
    getAllTags();
    getAllEvents();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t('Title'),
        size: 200,
      },
      {
        accessorKey: 'eventtypename',
        header: t('Type'),
      },
      {
        accessorKey: 'eventmembercount',
        header: t('Members'),
      },
      {
        accessorKey: 'rank',
        header: t('Rank'),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              width: '120px',
            }}
          >
            {userInfo.role.slug === 'ADMIN' ||
            userInfo.role.slug === 'SUPER_ADMIN' ||
            row.original.moderatorid.toString() === userInfo._id.toString() ? (
              <input
                type="number"
                name="rank"
                id="rank"
                value={row.original.rank}
                onChange={e => rankInputChangeHandler(row, e.target.value)}
                className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              />
            ) : (
              <p>{row.original.rank}</p>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'moderatorname',
        header: t('Moderator'),
        Cell: ({ renderedCellValue, row }) => (
          <div className="moderator border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '22px', height: '22px' }}
              >
                <img
                  src={
                    row.original.moderatorimage == ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.moderatorimage
                  }
                  alt="admin"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{row.original.moderatorname}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'eventstartdate',
        header: t('Start Date'),
      },
      {
        accessorKey: 'eventenddate',
        header: t('End Date'),
      },
      {
        accessorKey: 'eventparentname',
        header: t('Parent Event'),
      },
      {
        accessorKey: 'eventlocation',
        header: t('Place'),
        Cell: ({ row }) => (
          <div className="place border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-lg">
                pin_drop
              </span>
              <span>{row.original.eventlocation}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(', ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'eventstatusname',
        header: t('Status'),
        Cell: ({ renderedCellValue, row }) => (
          <div className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-1 fw-semibold ${
                row.original.eventstatus == '0'
                  ? 'text-warning'
                  : row.original.eventstatus == '1'
                  ? 'text-primary'
                  : 'text-success'
              }`}
            >
              <span className="d-block material-symbols-outlined icon-lg">
                {row.original.eventstatus == '0'
                  ? 'error'
                  : row.original.eventstatus == '1'
                  ? 'schedule'
                  : 'check_circle'}
              </span>
              <span>{renderedCellValue}</span>
            </p>
          </div>
        ),
      },
    ],
    [eventDataList, i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedEventIds(selectedIdsArray);
    } else {
      setSelectedEventIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_EVENT')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <EventListHeader
              updateRankHandler={updateRankHandler}
              reloadEventList={getAllEvents}
            />

            {/* --- event list table start --- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={eventDataList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      key="edit"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/info/${row.id}`}
                      >
                        {t('View Details')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="subevents"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/subevents/${row.id}`}
                      >
                        {t('View Sub Events')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="member"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/members/${row.id}`}
                      >
                        {t('View Members')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="partner"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/eventpartner/${row.id}`}
                      >
                        {t('View Partners')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="agenda"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/agenda/${row.id}`}
                      >
                        {t('View Agenda')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="gallery"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/gallery/${row.id}`}
                      >
                        {t('View Gallery')}
                      </Link>
                    </MenuItem>,
                    // <MenuItem
                    //   key="feedback"
                    //   //onClick={() => console.info(row.id)}
                    // >
                    //   <Link
                    //     className="dropdown-item"
                    //     to={`/admin/events/feedback/${row.id}`}
                    //   >
                    //     View Feedbacks
                    //   </Link>
                    // </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
            {/* --- event list table end --- */}
          </div>

          {/* ----- all pop up render here ----- */}
          <EventListFilterPopup
            tagList={tagList}
            eventTypeList={eventTypeList}
            setfilterEventCity={setfilterEventCity}
            setfilterEventCountry={setfilterEventCountry}
            setFilterDateRange={setFilterDateRange}
            setFilterEventTags={setFilterEventTags}
            setFilterEventType={setFilterEventType}
            setFilterEventTitle={setFilterEventTitle}
            setfilterCompanies={setfilterCompanies}
          />

          {/* ----- add new event modal ----- */}
          {/* <AddNewEventModal /> */}

          {/* ------ add label modal render here ----- */}
          <AddLabelModal
            moduleName="event"
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllEvents();
              }, 2500);
            }}
            selectedIds={selectedEventIds}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedEventIds([]);
            }}
            onDelete={deleteEventHandler}
            moduleName={t('event')}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default EventListBody;
