/* eslint-disable */
import AppUserListBody from "components/AppUserComponents/AppUserList/AppUserListBody/AppUserListBody";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const AppUserList = () => {
  useEffect(() => {
    document.title = "App User";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="appuser" />

      {/* ---- body section ---- */}
      <AppUserListBody />
    </main>
  );
};

export default AppUserList;
