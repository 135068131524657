import Header from 'components/Common/Header/Header';
import GroupAddEventBody from 'components/GroupComponents/GroupDetails/GroupEvent/GroupAddEvent/GroupAddEventBody/GroupAddEventBody';
import React from 'react';

const GroupAddEvent = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ------------ body section ------------------ */}
      <GroupAddEventBody />
    </>
  );
};

export default GroupAddEvent;
