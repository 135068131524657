/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

//import membership options from json
import EventMembershipStatusOption from "data/Prod/MembershipStatus.json";
import { useTranslation } from "react-i18next";

const MemberDetailsPopup = ({
  roleList,
  memberId,
  setMemberId,
  afterPopupClose,
  eventId,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [eventMemeberId, setEventMemeberId] = useState(null);
  const [memberImage, setmemberImage] = useState("");
  const [memberName, setmemberName] = useState("");
  const [memberEmail, setmemberEmail] = useState("");
  const [memberRole, setmemberRole] = useState("");
  const [roleValue, setRoleValue] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [memberStatusValue, setmemberStatusValue] = useState(null);
  const [memberStatusData, setmemberStatusData] = useState(null);
  const [eventMemberStatus, setEventMemberStatus] = useState("0");

  const [isSaving, setIsSaving] = useState(false);

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_MEMBER_DETAILS +
        `/${memberId}?token=${token}`;

      const response = await getData(requestUrl);
      console.log(response);

      if (response.status) {
        setEventMemeberId(response.data.eventmemberid);
        setmemberImage(response.data.image);
        setmemberName(response.data.name);
        setmemberEmail(response.data.email);
        setmemberRole(response.data.rolestring);
        roleSelectionHandler(response.data.roledata);
        EventMembershipStatusOption.map((membership) => {
          if (response.data.moderatorstatus == membership.value) {
            memberShipStatusSelectionHandler(membership);
          }
        });
        setEventMemberStatus(response.data.memberstatus);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setRoleData(val.value);
  };

  //function for select memebership status
  const memberShipStatusSelectionHandler = (val) => {
    setmemberStatusValue(val);
    setmemberStatusData(val.value);
  };

  //function for update membership
  const updateMemberHandler = async () => {
    setIsSaving(true);
    try {
      let memberData = {
        role: roleData,
        moderatorstatus: memberStatusData,
        joiningdate: memberStatusData == "1" ? getCurrentDateInString() : "",
      };

      // console.log(memberData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_EVENT_MEMBER +
        `/${memberId}?token=${token}&event=${eventId}&eventmemberid=${eventMemeberId}&eventmemberstatus=${eventMemberStatus}`;

      const response = await putData(requestUrl, memberData);

      setIsSaving(false);
      // console.log(response);

      if (response.status) {
        //hide member modal
        let offCanvasPopup = document.querySelector("#offcanvasMemberDetails");
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();

        closePopupHandler();
        afterPopupClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for after close popup
  const closePopupHandler = () => {
    setMemberId(null);
    setEventMemeberId(null);
    setmemberImage("");
    setmemberName("");
    setmemberEmail("");
    setmemberRole("");
    setRoleValue(null);
    setRoleData([]);
    setmemberStatusValue(null);
    setmemberStatusData(null);
  };

  useEffect(() => {
    if (memberId) {
      getMemberDetails();
    }
  }, [memberId]);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasMemberDetails"
      aria-labelledby="offcanvasMemberDetailsLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasMemberDetailsLabel">
          {t("Member Details")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closePopupHandler}
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-4">
        {/* ----- member name,email,image section start ----- */}
        <div className="profile d-flex align-items-center gap-2 mb-4">
          <div
            className="avatar rounded-circle overflow-hidden"
            style={{ width: "35px", height: "35px" }}
          >
            <img
              src={
                memberImage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + memberImage
              }
              alt="user"
              className="w-100 h-100 object-fit-cover object-center"
            />
          </div>
          <div>
            <p className="fs-md fw-semibold mb-0">{memberName}</p>
            <Link to="#" className="d-block lh-1">
              {memberEmail}
            </Link>
          </div>
        </div>
        {/* ----- member name,email,image section end ----- */}

        {/* ----- member role and other information section start ----- */}
        <div className="table-responsive flex-fill">
          <table className="table align-middle fs-sm">
            <tbody>
              <tr>
                <td className="bg-transparent">{t("Current Role")}</td>
                <td className="bg-transparent fw-semibold">{memberRole}</td>
              </tr>
              {/* <tr>
                <td className="bg-transparent">{t("Last Activity")}</td>
                <td className="bg-transparent fw-semibold">
                  15-12-2022 at 9:50 am
                </td>
              </tr> */}
              {/* <tr>
                <td className="bg-transparent">{t("Feedback Received")}</td>
                <td className="bg-transparent fw-semibold">589</td>
              </tr> */}
              {/* <tr>
                <td className="bg-transparent">{t("Feedback Given")}</td>
                <td className="bg-transparent fw-semibold">2589</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        {/* ----- member role and other information section start ----- */}

        {/* ----- member edit form section start ----- */}
        <form
          onSubmit={(e) => e.preventDefault()}
          className="pt-4 mt-4 border-top border-gray-300"
        >
          <div className="form-group mb-3 mb-sm-4">
            <div className="row">
              {/* ---- select role section start ----- */}
              <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Role")}
                </label>

                <Select
                  className="w-100"
                  placeholder={t("Select role")}
                  options={roleList}
                  value={roleValue}
                  onChange={(val) => roleSelectionHandler(val)}
                />
              </div>
              {/* ---- select role section end ----- */}

              {/* ---- select status section start ----- */}
              <div className="col-lg-6">
                <label
                  htmlFor="status"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Status")}
                </label>

                <Select
                  className="w-100"
                  placeholder={t("Select status")}
                  options={EventMembershipStatusOption}
                  value={memberStatusValue}
                  onChange={(val) => memberShipStatusSelectionHandler(val)}
                />
              </div>
              {/* ---- select status section end ----- */}
            </div>
          </div>
          <div className="form-group mb-3 mb-sm-4">
            <label
              htmlFor="comments"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Comments")}
            </label>
            <textarea
              name="comments"
              id="comments"
              cols="30"
              rows="5"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter message")}
            ></textarea>
          </div>
          {/* <p className="d-flex align-items-center gap-1 fs-sm text-gray mb-3 mb-sm-4">
            <span className="d-block material-symbols-outlined icon-md">
              info
            </span>
            <span className="d-block">
              The library also has delightful and beautifully{" "}
            </span>
          </p> */}
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateMemberHandler}
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
        {/* ----- member edit form section end ----- */}
      </div>
    </div>
  );
};

export default MemberDetailsPopup;
