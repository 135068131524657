/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";

const ModuleRoleFilterPopup = ({
  moduleList,
  setFilterModuleName,
  setFilterModuleSlug,
  setfilterModules,
}) => {
  const { t } = useTranslation(); //for translation
  const [name, setname] = useState("");
  const [slug, setslug] = useState("");
  const [moduleValue, setmoduleValue] = useState(null);
  const [moduleIds, setmoduleIds] = useState([]);

  const moduleSelectionHandler = (val) => {
    setmoduleValue(val);
    setmoduleIds(val.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setFilterModuleName(name);
    setFilterModuleSlug(slug);
    setfilterModules(moduleIds);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#moduleRoleFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    setname("");
    setslug("");
    setmoduleValue(null);
    setmoduleIds([]);
  };
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="moduleRoleFilter"
      aria-labelledby="moduleRoleFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  {t("Name")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter")}
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  {t("Slug")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter")}
                      value={slug}
                      onChange={(e) => {
                        setslug(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Module")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <Select
                      placeholder={t("Select Modules")}
                      isMulti
                      options={moduleList}
                      value={moduleValue}
                      onChange={(val) => moduleSelectionHandler(val)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={searchHandler}
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ModuleRoleFilterPopup;
