import Header from "components/Common/Header/Header";
import ProjectLogBody from "components/ProjectManagementComponents/ProjectDetailsComponents/ProjectLogsComponents/ProjectLogBody/ProjectLogBody";
import React, { useEffect } from "react";

const ProjectLogs = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectLogBody />
    </main>
  );
};

export default ProjectLogs;
