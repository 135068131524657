import Header from "components/Common/Header/Header";
import CourseTaskSubmittedBody from "components/CourseComponents/CourseDetails/CourseTaskSubmittedComponents/CourseTaskSubmittedBody/CourseTaskSubmittedBody";
import React, { useEffect } from "react";

const CourseTaskSubmitted = () => {
  useEffect(() => {
    document.title = "Course Task Submitted";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CourseTaskSubmittedBody />
    </main>
  );
};

export default CourseTaskSubmitted;
