import { Link } from "react-router-dom";

const JobFeedbackRightPanel = ({ cvResponseData = {} }) => {
  return (
    <div className="right_survey right_cv_info bg-white px-3 py-4">
      <div className="overlay"></div>
      <div className="cv_review text-right bg-white p-3 mb-3">
        <Link
          className="d-inline-flex align-items-center gap-2 text-black fs-lg fw-semibold"
          to="#"
          data-bs-toggle="offcanvas"
          data-bs-target="#review_canvas"
        >
          <i className="material-symbols-outlined fs-xl">filter_list</i>
          <span>Show Review</span>
        </Link>
      </div>
      <div className="highlights_wrapper body-bg p-4 rounded-10 mb-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="highlghts_item mb-5">
              <h5 className="text-black fw-semibold mb-3">
                Career Highlights{" "}
              </h5>

              {cvResponseData?.carrierhighlights ? (
                <ul>
                  {cvResponseData?.carrierhighlights?.map((career, index) => {
                    return (
                      <li className="mb-2" key={index}>
                        {career}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>

            <div className="highlghts_item">
              <h5 className="text-black fw-semibold mb-3">
                Technical certificates / abilities{" "}
              </h5>
              {cvResponseData?.certifications ? (
                <ul>
                  {cvResponseData?.certifications?.map((cert, index) => {
                    return (
                      <li className="mb-2" key={index}>
                        {cert?.name} from {cert?.companyname}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="project_refence">
              <h5 className="text-black fw-semibold mb-3">
                Main project references{" "}
              </h5>

              {cvResponseData?.projectids
                ? cvResponseData?.projectids?.map((project, index) => {
                    return (
                      <div key={index}>
                        <h6 className="text-black fw-semibold mb-3">
                          {project?.company}{" "}
                          <em className="fw-regular">
                            {project?.start} - {project?.end},
                          </em>{" "}
                        </h6>

                        <p className="text-gray fs-md mb-3">
                          <strong className="text-black fw-semibold">
                            Role
                          </strong>{" "}
                          : {project?.position}
                        </p>

                        <p className="text-gray fs-md mb-3">
                          <strong className="text-black fw-semibold">
                            Main technologies/Methodologies:
                          </strong>{" "}
                          : {project?.technologies}
                        </p>

                        <p className="text-gray fs-md mb-4">
                          <strong className="text-black fw-semibold">
                            Responsibilities :{" "}
                          </strong>
                          {project?.responsibilities}{" "}
                        </p>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className="other_projects body-bg p-4 rounded-10 mb-4">
        <div className="project_refence">
          <h5 className="text-black fw-semibold mb-4">Work Experiences</h5>

          {cvResponseData?.cvexperience
            ? cvResponseData?.cvexperience?.map((project, index) => {
                return (
                  <div key={index}>
                    <h6 className="text-black fw-semibold mb-3">
                      {project?.company}{" "}
                      <em className="fw-regular">
                        {project?.start} - {project?.end},
                      </em>{" "}
                    </h6>

                    <p className="text-gray fs-md mb-3">
                      <strong className="text-black fw-semibold">Role</strong> :{" "}
                      {project?.position}
                    </p>

                    <p className="text-gray fs-md mb-4">
                      <strong className="text-black fw-semibold">
                        Responsibilities :{" "}
                      </strong>
                      {project?.responsibilities}{" "}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="skil_table_innr rounded-10 body-bg p-4 overflow-hidden">
        <div className="scroll_table_innr">
          <h5 className="text-black fw-semibold mb-3">Skill Points </h5>

          {cvResponseData?.cvskillpoint ? (
            <table>
              <thead className="bg-primary">
                <tr>
                  <th className="text-white fs-md p-2 fw-semibold">SKILL</th>
                  <th className="text-white fs-md p-2 fw-semibold">
                    Point (10){" "}
                  </th>
                  <th className="text-white fs-md p-2 fw-semibold">
                    Total Experience
                  </th>
                </tr>
              </thead>
              <tbody>
                {cvResponseData?.cvskillpoint?.map((skill, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-black fs-md p-2">
                        {skill?.skillname}
                      </td>
                      <td className="text-black fs-md p-2">
                        {skill?.points}/10
                      </td>
                      <td className="text-black fs-md p-2">
                        {skill?.experienceyear}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : null}
        </div>
      </div>

      <div className="note_list mt-4">
        <h5 className="text-black fw-semibold mb-3">Survey Response </h5>

        <ol>
          <li className="text-black fs-md fw-semibold mb-3">
            <p className="text-gray">
              Total experiencia, autonomía, capacidad de liderar un equipo y de
              formar a personas.
            </p>

            <p className="text-black">Por lo general &gt; 4 años</p>
          </li>

          <li className="text-black fs-md fw-semibold mb-3">
            <p className="text-gray">
              Total experiencia, autonomía, capacidad de liderar un equipo y de
              formar a personas.
            </p>

            <p className="text-black">Por lo general &gt; 4 años</p>
          </li>

          <li className="text-black fs-md fw-semibold mb-3">
            <p className="text-gray">
              Total experiencia, autonomía, capacidad de liderar un equipo y de
              formar a personas.
            </p>

            <p className="text-black">Por lo general &gt; 4 años</p>
          </li>

          <li className="text-black fs-md fw-semibold mb-3">
            <p className="text-gray">
              Total experiencia, autonomía, capacidad de liderar un equipo y de
              formar a personas.
            </p>

            <p className="text-black">Por lo general &gt; 4 años</p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default JobFeedbackRightPanel;
