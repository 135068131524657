import ChallengeResponsesListBody from "components/ChallengeComponents/ChallengeDetailsComponents/ChallengeResponse/List/ChallengeResponsesListBody";
import Header from "components/Common/Header/Header";
import React from "react";

const ChallengeResponseList = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="challenges" />

      {/* ---------- body section ------------- */}
      <ChallengeResponsesListBody />
    </main>
  );
};

export default ChallengeResponseList;
