import Header from 'components/Common/Header/Header';
import GroupProductAddBody from 'components/GroupComponents/GroupDetails/GroupProduct/GroupProductAdd/GroupProductAddBody/GroupProductAddBody';
import React from 'react';

const GroupProductAdd = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ---------- body section ------------- */}
      <GroupProductAddBody />
    </>
  );
};

export default GroupProductAdd;
