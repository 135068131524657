import Header from 'components/Common/Header/Header';
import ProjectReportBody from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectReportComponents/ProjectReportBody/ProjectReportBody';
import React, { useEffect } from 'react';

const ProjectReport = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = 'Project Management';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectReportBody />
    </main>
  );
};

export default ProjectReport;
