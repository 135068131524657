/* eslint-disable */
import { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
  deleteData,
} from "utils/Gateway";

import CommonTabs from "../../CommonTabs/CommonTabs";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { ckEditorConfig } from "Config/Config";
import UploadContentModal from "components/Common/Modal/UploadContentModal";
import LessonSuccessModal from "../Modals/LessonSuccessModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const ContentBody = () => {
  const { t } = useTranslation(); //for translation
  const params = useParams();
  const history = useHistory();
  const token = localStorage.getItem("token");

  const breadcrumbText = [
    { title: t("Course"), link: "/admin/courses" },
    {
      title: t("Lessons"),
      link: `/admin/course/lessons/list/${params.id}`,
    },
    { title: t("Details") },
  ];

  const [chapterList, setchapterList] = useState([]);
  const [memberList, setmemberList] = useState([]);

  const [chapterValue, setchapterValue] = useState(null);
  const [selectedChapterId, setselectedChapterId] = useState(null);
  const [memberValue, setmemberValue] = useState(null);
  const [memberIds, setmemberIds] = useState([]);
  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [uploadedFileIds, setuploadedFileIds] = useState([]);
  const [videoUrl, setvideoUrl] = useState("");
  const [duration, setduration] = useState("");
  const [passingScore, setpassingScore] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [savedLessonId, setSavedLessonId] = useState(null);

  const [selectedUploadId, setSelectedUploadId] = useState(null);
  const [currentContentIndex, setCurrentContentIndex] = useState(null);
  const [currentContentIdForDelete, setCurrentContentIdForDelete] =
    useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [passSampleData, setPassSampleData] = useState({
    content: "",
    index: "",
  });

  //get chapter list
  const getChapterList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_CHAPTER_LIST_OPTIONS +
        `?token=${token}&courseid=${params.id}`;

      const response = await getData(requestURL);

      if (response.data) {
        setchapterList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllMemberList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_MEMBER_LIST_OPTIONS +
        `?token=${token}&courseid=${params.id}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setmemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select chapter
  const chapterSelectionHandler = (val) => {
    if (val) {
      setchapterValue(val);
      setselectedChapterId(val.value);
    } else {
      setchapterValue(null);
      setselectedChapterId(null);
    }
  };

  //function for select members
  const memberSelectionHandler = (val) => {
    setmemberValue(val);
    setmemberIds(val.map((item) => item.value));
  };

  //function for delete content
  const deleteContentHandler = async () => {
    if (currentContentIdForDelete && currentContentIndex) {
      try {
        setIsDeleting(true);

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_CONTENT +
          `/${currentContentIdForDelete}?token=${token}`;

        const response = await deleteData(requestURL);

        setIsDeleting(false);

        if (response.status) {
          const updatedFileids = [...uploadedFileIds];
          const updateFiles = [...uploadedFiles];

          updatedFileids.splice(currentContentIndex, 1);
          updateFiles.splice(currentContentIndex, 1);

          setuploadedFileIds(updatedFileids);
          setuploadedFiles(updateFiles);

          setCurrentContentIndex(null);
          setCurrentContentIdForDelete(null);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
    }
  };

  //get lesson details
  const getLessonDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_DETAILS +
        `/${params.lessonid}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data.chapteridvalue) {
          chapterSelectionHandler(response.data.chapteridvalue);
        }

        if (response.data.membervalues) {
          memberSelectionHandler(response.data.membervalues);
        }

        settitle(response.data.title);
        setdetails(response.data.description);
        setuploadedFileIds(response.data?.uploadcontentids);
        setuploadedFiles(response.data?.uploadcontentlist);

        setvideoUrl(response.data.videourl);
        setduration(response.data.duration);
        setpassingScore(response.data.passingscore);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save lesson
  const saveLessonHandler = async () => {
    try {
      setIsSaving(true);

      let chapterData = {
        course: params.id,
        chapterid: selectedChapterId,
        title,
        description: details,
        passingscore: passingScore,
        videourl: videoUrl,
        duration,
        members: memberIds,
        uploads: uploadedFileIds,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (params.lessonid) {
        requestURL +=
          url.API_UPDATE_COURSE_LESSON + `/${params.lessonid}?token=${token}`;

        response = await putData(requestURL, chapterData);
      } else {
        requestURL += url.API_ADD_NEW_COURSE_LESSON + `?token=${token}`;

        response = await postData(requestURL, chapterData);
      }

      console.log(response);

      setIsSaving(false);

      if (response.status) {
        setSavedLessonId(response.data._id);
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("saveLesson")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const afterSaveLessonModalClose = (saveType = "list") => {
    if (saveType == "list") {
      history.push(`/admin/course/lessons/list/${params.id}`);
    } else if (saveType == "add") {
      history.push(`/admin/course/lessons/content/${params.id}`);
    } else {
      if (params.lessonid) {
        history.push(
          `/admin/course/lessons/content/${params.id}/${params.lessonid}`
        );
      } else {
        history.push(
          `/admin/course/lessons/content/${params.id}/${savedLessonId}`
        );
      }
    }
  };

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  useEffect(() => {
    if (params.lessonid) {
      getLessonDetails();
    }
  }, [params.lessonid]);

  useEffect(() => {
    if (params.id) {
      getChapterList();
      getAllMemberList();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="course-details-wrapper bg-white pb-5">
        {/* -------------- common tab ----------- */}
        {params.lessonid && <CommonTabs activeTab="content" />}

        {/* --------- form area ------------ */}
        <div className="container-fluid px-lg-5 pt-4">
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            bottom={true}
            displayName={title}
          />

          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* <!-- ========== Start chapter Section ========== --> */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="code"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">{t("Chapter")}</span>
                </label>

                <Select
                  placeholder={t("Select chapter")}
                  isClearable
                  options={chapterList}
                  value={chapterValue}
                  onChange={(val) => {
                    chapterSelectionHandler(val);
                  }}
                />
              </div>
              {/* <!-- ========== End chapter Section ========== --> */}

              {/* <!-- ========== Start member Section ========== --> */}
              <div className="form-group mb-4">
                <label
                  htmlFor="labelName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Members")}
                </label>
                <Select
                  isMulti
                  placeholder={t("Select course members")}
                  options={memberList}
                  value={memberValue}
                  onChange={(val) => {
                    memberSelectionHandler(val);
                  }}
                />
              </div>
              {/* <!-- ========== End member Section ========== --> */}

              {/* <!-- ========== Start title Section ========== --> */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="code"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">{t("Ttile")}</span>
                </label>

                <input
                  type="text"
                  id="name"
                  className="form-control fs-sm shadow-none"
                  value={title}
                  onChange={(e) => {
                    settitle(e.target.value);
                  }}
                />
              </div>
              {/* <!-- ========== End title Section ========== --> */}

              {/* <!-- ========== Start details Section ========== --> */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="code"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">{t("Details")}</span>
                </label>
                <CKEditor
                  className="form-control fs-sm shadow-none"
                  editor={ClassicEditor}
                  config={ckEditorConfig(t("Lesson details"))}
                  data={details ? details : ""}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    let editorContent = editor.getData();
                    setdetails(editorContent);
                  }}
                />
              </div>
              {/* <!-- ========== End details Section ========== --> */}

              {/* <!-- ========== Start uploaded files Section ========== --> */}
              <div className="form-group mb-3 mb-sm-4">
                <label className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2">
                  <span className="d-block">{t("Attach Content")}</span>
                </label>
                <div className="table-wrapper">
                  <div className="table-responsive mb-4">
                    <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                      <thead>
                        <tr>
                          <th className="course-name bg-transparent fs-md fw-semibold border-0">
                            Rank
                          </th>
                          <th className="duration-min bg-transparent fs-md fw-semibold border-0">
                            File
                          </th>
                          <th className="assignments bg-transparent fs-md fw-semibold border-0">
                            Title
                          </th>
                          <th
                            className="questions bg-transparent fs-md fw-semibold border-0"
                            style={{ width: "40%" }}
                          >
                            Description
                          </th>
                          <th className="action bg-transparent fs-md fw-semibold border-0">
                            Action
                          </th>
                        </tr>
                      </thead>
                      {uploadedFiles.length === 0 ? null : (
                        <tbody>
                          {uploadedFiles.map((content, index) => {
                            return (
                              <tr key={index}>
                                <td className="course-name border-bottom-0">
                                  <div className="d-flex align-items-center gap-4">
                                    <p>{content.rank}</p>
                                  </div>
                                </td>
                                <td className="assignments border-bottom-0">
                                  <p>{content.name}</p>
                                </td>
                                <td className="questions border-bottom-0">
                                  <p className="mb-0">{content.title}</p>
                                </td>
                                <td className="created border-bottom-0">
                                  <p>{content.description}</p>
                                </td>
                                <td className="action border-bottom-0">
                                  <div className="d-flex align-items-center gap-2">
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upload_content"
                                      onClick={() => {
                                        setSelectedUploadId(content._id);
                                      }}
                                    >
                                      <span className="d-block material-symbols-outlined">
                                        edit
                                      </span>
                                    </Link>
                                    <button
                                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary border-0"
                                      data-bs-toggle="modal"
                                      data-bs-target="#deletewarning"
                                      onClick={() => {
                                        setCurrentContentIndex(index);
                                        setCurrentContentIdForDelete(
                                          content._id
                                        );
                                      }}
                                      disabled={isDeleting ? true : false}
                                      style={{
                                        cursor: isDeleting
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    >
                                      {isDeleting &&
                                      currentContentIndex === index ? (
                                        <div
                                          className="mx-2 spinner-border spinner-border-sm"
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      ) : (
                                        <span className="d-block material-symbols-outlined">
                                          delete
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="add_another">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#upload_content"
                      className="btn btn-outline-primary"
                    >
                      Add Content
                    </Link>
                  </div>
                </div>
              </div>
              {/* <!-- ========== End uploaded files Section ========== --> */}

              {/* <!-- ========== Start video url Section ========== --> */}
              {/* <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="code"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">{t('Video url')}</span>
                </label>

                <input
                  type="text"
                  id="name"
                  className="form-control fs-sm shadow-none"
                  value={videoUrl}
                  onChange={e => {
                    setvideoUrl(e.target.value);
                  }}
                />
              </div> */}
              {/* <!-- ========== End video url Section ========== --> */}

              {/* <!-- ========== Start duration Section ========== --> */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="code"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">{t("Duration")}</span>
                </label>

                <input
                  type="number"
                  min={0}
                  id="name"
                  className="form-control fs-sm shadow-none"
                  value={duration}
                  onChange={(e) => {
                    setduration(e.target.value);
                  }}
                />
              </div>
              {/* <!-- ========== End duration Section ========== --> */}

              {/* <!-- ========== Start passing score Section ========== --> */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="code"
                  className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                >
                  <span className="d-block">{t("Passing score")} (%)</span>
                </label>

                <input
                  type="number"
                  id="name"
                  className="form-control fs-sm shadow-none"
                  value={passingScore}
                  onChange={(e) => {
                    setpassingScore(e.target.value);
                  }}
                />
              </div>
              {/* <!-- ========== End passing score Section ========== --> */}

              {/* <!-- ========== Start button Section ========== --> */}
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between mt-5">
                <Link
                  to={`/admin/course/lessons/list/${params.id}`}
                  className="btn btn-outline-primary"
                >
                  {t("Cancel")}
                </Link>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveLessonHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save Now")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* <!-- ========== End button Section ========== --> */}
            </form>
          </div>
        </div>
      </section>

      {/* -- ==========  Upload Content Modal ========== */}
      <UploadContentModal
        selectedUploadId={selectedUploadId}
        setSelectedUploadId={setSelectedUploadId}
        uploadedFiles={uploadedFiles}
        setuploadedFiles={setuploadedFiles}
        uploadedFileIds={uploadedFileIds}
        setuploadedFileIds={setuploadedFileIds}
        setShowAlert={setShowAlert}
        setMessageType={setMessageType}
        setAlertMessage={setAlertMessage}
      />

      {/* -- ========== Lesson Success Modal ========== */}
      <LessonSuccessModal afterSaveModalClose={afterSaveLessonModalClose} />

      {/* ----------- delete warning modal ------------ */}
      <DeleteWarningModal
        onCancelDelete={() => {
          setCurrentContentIndex(null);
          setCurrentContentIdForDelete(null);
        }}
        onDelete={deleteContentHandler}
        moduleName={t("content")}
      />

      {/* ------- Alert Notification ------ */}
      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default ContentBody;
