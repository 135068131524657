import JobResponseOptions from "data/Prod/JobResponseStatus.json";

//common header links
export const challengeHeaderLinks = (id, t) => {
  return [
    {
      title: t("Job Information"),
      link: `/admin/challenges/jobinformation/${id}`,
    },
    {
      title: t("Hiring Process"),
      link: `/admin/challenges/hiringprocess/${id}`,
    },
    {
      title: t("Hiring Team"),
      link: `/admin/challenges/hiringteam/${id}`,
    },
    {
      title: t("Responses"),
      link: `/admin/challenges/responses/list/${id}`,
    },
    {
      title: t("Survey"),
      link: `/admin/challenges/survey/${id}`,
    },
  ];
};

export const challnegeBreadCrumbText = (moduleText, challengeTitle, t) => {
  return [
    { title: t("Challenges"), link: "/admin/challenges/list" },
    { title: `${moduleText} / ${challengeTitle}` },
  ];
};

export const getJobResponseStatus = (jobStatus) => {
  let statusData = "";

  for (let status of JobResponseOptions) {
    if (status.value === jobStatus) {
      statusData = status.label;
    }
  }

  return statusData;
};

export const convertToSpanishFormat = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = date.getFullYear();

  const formattedDate = `${day}/${month < 10 ? "0" : ""}${month}/${year}`;

  return formattedDate; // Output: 07/03/2024
};
