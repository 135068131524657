/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { ecosystemSlug } from "Config/Config";

import contactOptions from "data/Prod/ContactOptions.json";
import knowingOptions from "data/Prod/RelationOptions.json";

import { GlobalProvider } from "context/GlobalContext";

const AddNewContactModal = ({
  afterCloseModalHandler,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { setreloadRequestList } = useContext(GlobalProvider);

  const [userInput, setUserInput] = useState("");
  const [userOptions, setuserOptions] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [selectContactOptionValue, setSelectContactOptionValue] =
    useState(null);
  const [selectContactOption, setSelectContactOption] = useState("");
  const [selectKnowingOptionValue, setselectKnowingOptionValue] =
    useState(null);
  const [selectKnowingOption, setselectKnowingOption] = useState("");
  const [connectMessage, setConnectMessage] = useState("");

  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    userWarning: false,
    contactError: false,
    knowingError: false,
    messageError: false,
  });

  //** Function for Validation */
  const validationHandler = () => {
    let isValid = true;

    if (!userValue) {
      setValidation((prevState) => ({ ...prevState, userWarning: true }));
      isValid = false;
    }

    if (selectContactOption === "") {
      setValidation((prevState) => ({ ...prevState, contactError: true }));
      isValid = false;
    }

    if (selectKnowingOption === "") {
      setValidation((prevState) => ({ ...prevState, knowingError: true }));
      isValid = false;
    }

    if (connectMessage === "") {
      setValidation((prevState) => ({ ...prevState, messageError: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get user input
  const userInputHandler = (val) => {
    setUserInput(val);
    setErrorMessage("");
  };

  //function for get user details
  const getUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}` +
        `&userstring=${userInput}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setuserOptions(response.data);
        }
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function user Selection
  const userSelectionHandler = (val) => {
    setErrorMessage("");
    if (val) {
      setUserValue(val);
      val._id ? setUserId(val._id) : setUserId(null); // if new user or other than contact user email is added
      val.email ? setUserEmail(val.email) : setUserEmail(val.value); // if new user or other than contact, set value as email
    } else {
      setUserValue(null);
      setUserId(null);
      setUserEmail("");
      setuserOptions([]);
    }
  };

  //** Function for add/invite for contact
  const sendInviteHandler = async () => {
    if (validationHandler()) {
      setIsAdding(true);
      try {
        let connectData = {
          member: userId,
          reason: selectContactOption,
          source: selectKnowingOption,
          message: connectMessage,
        };

        console.log("MemberData---->", connectData);

        let requestUrl =
          url.API_BASE_URL + url.API_SEND_REQUEST_USER + `?token=${token}`;

        const response = await postData(requestUrl, connectData);

        setIsAdding(false);
        // console.log(response);

        if (response.status) {
          setMessageType("success");

          setreloadRequestList(true);

          //hide member modal
          let bootstrapModal = document.querySelector("#addContactModal");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          //call member list api function
          resetHandler();
          afterCloseModalHandler();
        } else {
          setMessageType("error");
          setErrorMessage(response.message);
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up the required fields"));
      setErrorMessage(t("Please fill up the required fields"));
    }

    setShowAlert(true);
  };

  //** Function for reset
  const resetHandler = () => {
    setUserInput("");
    setUserId(null);
    setUserEmail("");
    setuserOptions([]);
    setUserValue(null);
    setSelectContactOptionValue(null);
    setSelectContactOption("");
    setselectKnowingOptionValue(null);
    setselectKnowingOption("");
    setConnectMessage("");
    setErrorMessage("");
    setValidation({
      userWarning: false,
      contactError: false,
      knowingError: false,
      messageError: false,
    });
  };

  useEffect(() => {
    if (userInput.length > 0) {
      getUserList();
    }
  }, [userInput]);

  const optionsToShow = userOptions.length > 0 ? userOptions : [];

  return (
    <div
      className="modal fade"
      id="addContactModal"
      tabIndex="-1"
      aria-labelledby="addContactModal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            {/* <!-- ========== Start modal title  ========== --> */}
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Invite / Add Contact")}
            </h2>
            {/* <!-- ========== End modal title  ========== --> */}

            {/* <!-- ========== Start modal close button ========== --> */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
            {/* <!-- ========== End modal close button ========== --> */}
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="memberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Invite app users / guest (search by name, email)")}
                </label>

                <Select
                  isClearable
                  className="w-100"
                  placeholder={t("Search by name, email")}
                  options={optionsToShow}
                  value={userValue}
                  onChange={(val) => {
                    userSelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      userWarning: false,
                    }));
                    setErrorMessage("");
                  }}
                  onInputChange={(val) => {
                    userInputHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      userWarning: false,
                    }));
                    setErrorMessage("");
                  }}
                />

                {/* <!-- ========== Start user validation Section ========== --> */}
                {validation.userWarning && (
                  <p className="text-danger fw-semibold">
                    * {t("Please type or select user name or email")}
                  </p>
                )}
                {/* <!-- ========== End user validation Section ========== --> */}
              </div>
              {/* ----- user select section end ----- */}

              {/* <!-- ========== Start contact type Section ========== --> */}
              {userValue ? (
                <div className="form-group mb-4">
                  <label
                    htmlFor="memberName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Contact Type")}
                  </label>

                  <Select
                    isClearable
                    className="w-100"
                    placeholder={t("Select Contact Type")}
                    options={contactOptions}
                    value={selectContactOptionValue}
                    onChange={(val) => {
                      if (val) {
                        setSelectContactOptionValue(val);
                        setSelectContactOption(val.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          contactError: false,
                        }));
                        setErrorMessage("");
                      } else {
                        setSelectContactOptionValue(null);
                        setSelectContactOption("");
                      }
                    }}
                  />

                  {/* <!-- ========== Start user validation Section ========== --> */}
                  {validation.contactError && (
                    <p className="text-danger fw-semibold">
                      * {t("Please select a contact type")}
                    </p>
                  )}
                  {/* <!-- ========== End user validation Section ========== --> */}
                </div>
              ) : null}
              {/* <!-- ========== End contact type Section ========== --> */}

              {/* <!-- ========== Start knowing type Section ========== --> */}
              {userValue ? (
                <div className="form-group mb-4">
                  <label
                    htmlFor="memberName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Knowing Type")}
                  </label>

                  <Select
                    isClearable
                    className="w-100"
                    placeholder={t("Select Knowing Type")}
                    options={knowingOptions}
                    value={selectKnowingOptionValue}
                    onChange={(val) => {
                      if (val) {
                        setselectKnowingOptionValue(val);
                        setselectKnowingOption(val.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          knowingError: false,
                        }));
                        setErrorMessage("");
                      } else {
                        setselectKnowingOptionValue(null);
                        setselectKnowingOption("");
                      }
                    }}
                  />

                  {/* <!-- ========== Start user validation Section ========== --> */}
                  {validation.knowingError && (
                    <p className="text-danger fw-semibold">
                      * {t("Please select a knowing type")}
                    </p>
                  )}
                </div>
              ) : null}
              {/* <!-- ========== End knowing type Section ========== --> */}

              {/* <!-- ========== Start message Section ========== --> */}
              {userValue ? (
                <div className="form-group mb-4">
                  <label
                    htmlFor="memberName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Connect Message")}
                  </label>
                  <textarea
                    className="form-control shadow-none"
                    placeholder={t("Message of contact")}
                    rows={5}
                    value={connectMessage}
                    onChange={(e) => {
                      setConnectMessage(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        messageError: false,
                      }));
                      setErrorMessage("");
                    }}
                  ></textarea>

                  {validation.messageError && (
                    <p className="text-danger fw-semibold">
                      * {t("Please enter message")}
                    </p>
                  )}
                </div>
              ) : null}
              {/* <!-- ========== End message Section ========== --> */}

              {/* <!-- ========== Start button and error message Section ========== --> */}
              {userValue ? (
                <div className="action d-flex align-items-center gap-3 justify-content-end">
                  {/* <!-- ========== Start cancel modal button ========== --> */}
                  {/* <button
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button> */}
                  {/* <!-- ========== End cancel modal button ========== --> */}

                  {/* <!-- ========== Start error message Section ========== --> */}
                  {errorMessage == "" ? null : (
                    <p className="text-danger fw-semibold">* {errorMessage}</p>
                  )}
                  {/* <!-- ========== End error message Section ========== --> */}

                  {/* <!-- ========== Start save button Section ========== --> */}
                  <button
                    type="button"
                    className="btn btn-primary ms-auto"
                    onClick={sendInviteHandler}
                    disabled={isAdding ? true : false}
                    style={{
                      cursor: isAdding ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Send Request")}
                    {isAdding && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {/* <!-- ========== End save button Section ========== --> */}
                </div>
              ) : null}
              {/* <!-- ========== End button and error message Section ========== --> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewContactModal;
