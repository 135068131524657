/* eslint-disable */
import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
import { MenuItem } from '@mui/material';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import { assetImages } from 'constants';
import MailTemplateHeader from './Header/MailTemplateHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import MailTemplateFilterPopup from 'components/Common/Popup/MailTemplateFilterPopup';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';

const MailTemplateListBody = () => {
  const token = localStorage.getItem('token'); // token
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const { t, i18n } = useTranslation(); //for translation

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    {
      title: t('Administration'),
      link: '/admin/administration',
    },
    { title: t('Mail Template') },
  ];

  const [tagList, setTagList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const [templateId, setTemplateId] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);

  const [filterDates, setfilterDates] = useState('');
  const [filterName, setfilterName] = useState('');
  const [filterSlug, setfilterSlug] = useState('');
  const [filterSubject, setfilterSubject] = useState('');
  const [filterLabels, setfilterLabels] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  const getAllMailTemplates = async () => {
    setRowSelection({});
    setSelectedTemplateIds([]);
    setIsLoading(true);

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MAIL_TEMPLATES +
        `?token=${token}&modulename=administration`;

      //search by date
      if (filterDates != '') {
        requestUrl = requestUrl + `&templatedate=${filterDates}`;
      }
      //search by name
      if (filterName != '') {
        requestUrl = requestUrl + `&templatename=${filterName}`;
      }
      if (filterSubject != '') {
        requestUrl = requestUrl + `&templatesubject=${filterSubject}`;
      }
      //search by category
      if (filterSlug != '') {
        requestUrl = requestUrl + `&templateslug=${filterSlug}`;
      }
      //search by tags
      if (filterLabels.length > 0) {
        requestUrl = requestUrl + `&templatelabels=${filterLabels}`;
      }
      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType('success');
        setTemplateList(response.data);
        resetFilterData();
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    if (selectedTemplateIds.length > 0) {
      let responseArr = [];

      for (let selectedId of selectedTemplateIds) {
        try {
          let templateData = {
            status: '0',
          };

          console.log(templateData);

          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_MAIL_TEMPLATE +
            `/${selectedId}` +
            `?token=${token}`;

          console.log('requesturl', requestUrl);

          const response = await putData(requestUrl, templateData);

          if (response.status) {
            setMessageType('success');
            responseArr.push(true);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllMailTemplates();
        }, 2000);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select template for delete');
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setfilterDates('');
    setfilterName('');
    setfilterSubject('');
    setfilterSlug('');
    setfilterLabels([]);
  };

  useEffect(() => {
    if (
      filterName != '' ||
      filterSlug != '' ||
      filterSubject != '' ||
      filterDates != '' ||
      filterLabels.length > 0
    ) {
      getAllMailTemplates();
    }
  }, [filterName, filterSubject, filterSlug, filterDates, filterLabels]);

  useEffect(() => {
    getAllMailTemplates();
    getAllTags();
  }, []);

  /*
   * Material React Table Column and States
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Template Name'),
      },
      {
        accessorKey: 'slug',
        header: t('Slug'),
      },
      {
        accessorKey: 'createdate',
        header: t('Date'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedTemplateIds(selectedIdsArray);
    } else {
      setSelectedTemplateIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (userInfo.role.slug === 'ADMIN' || userInfo.role.slug === 'SUPER_ADMIN') {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <MailTemplateHeader
              breadcrumbText={breadcrumbText}
              reloadMailTemplate={getAllMailTemplates}
              changeStatusHandler={changeStatusHandler}
            />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={templateList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => {
                    return [
                      <MenuItem
                        key="update"
                        //onClick={() => console.info(row.id)}
                      >
                        <Link
                          className="dropdown-item"
                          to={`/admin/administration/mailtemplate/save/${row.id}`}
                        >
                          {t('Update Mail Template')}
                        </Link>
                      </MenuItem>,
                    ];
                  }} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
          {/* ==== modals and popup section === */}
          {/* --- filter popup --- */}
          <MailTemplateFilterPopup
            tagList={tagList}
            setfilterDates={setfilterDates}
            setfilterName={setfilterName}
            setfilterSubject={setfilterSubject}
            setfilterSlug={setfilterSlug}
            setfilterLabels={setfilterLabels}
          />
          {/* --- edit template popup --- */}
          {/* <SaveMailTemplatePopup
            afterPopupClose={getAllMailTemplates}
            templateId={templateId}
            setTemplateId={setTemplateId}
          /> */}
          <AddLabelModal
            moduleName="crmtemplate"
            selectedIds={selectedTemplateIds}
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllMailTemplates();
              }, 2200);
            }}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default MailTemplateListBody;
