import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { useTranslation } from "react-i18next";

const GroupGalleryHeader = ({ breadCrumbText, addGalleryImageHandler }) => {
  const { t } = useTranslation(); //for translation
  /* ---- bread crumb text ---- */
  const breadcrumbTxt = [
    { title: t("Group"), link: "/admin/group/list" },
    { title: t("Group Gallery") },
  ];
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ============= search section ============ */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          {/* --- bread crumb ---- */}
          <BreadCrumb breadCrumbText={breadcrumbTxt} />
        </div>

        {/* =========== Action / add new section ============= */}
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <button
            onClick={addGalleryImageHandler}
            className="btn btn-primary d-flex align-items-center gap-1"
          >
            <span className="d-block">{t("Save")}</span>
            <span className="d-block material-symbols-outlined icon-md">
              save
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupGalleryHeader;
