/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AdministrationBody from "components/AdministrationComponents/AdministrationBody/AdministrationBody";

const Administration = () => {
  useEffect(() => {
    document.title = "Administration";
  }, []);
  return (
    <main id="app">
      <Header moduleName="administration" />
      <AdministrationBody />
    </main>
  );
};

export default Administration;
