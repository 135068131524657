/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";

import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import SurveyQuestionResponseHeader from "../../Header/SurveyQuestionResponseHeader";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import AddUpdateQuestionPopup from "../Popup/AddUpdateQuestionPopup";
import DeleteModal from "../Modals/DeleteModal";
import DeleteMessageModal from "../Modals/DeleteMessageModal";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const SurveyQuestionBody = ({ moduleName }) => {
  const token = localStorage.getItem("token");
  const params = useParams();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation
  const [name, setname] = useState("");

  const [questionTypes, setquestionTypes] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [editedQuestionId, setEditedQuestionId] = useState(null);
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Survey"), link: "/admin/survey" },
    { title: `${name}` },
  ];

  //function for get survey details
  const getSurveyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status) {
        setname(response.data.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all question types
  const getAllQuestionTypes = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_QUESTIONTYPES + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setquestionTypes(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all questions from survey details
  const getAllQuestions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status) {
        setQuestionList(response.data?.questionlist);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for cancel delete
  const cancelDeleteHandler = () => {
    setDeleteQuestionId(null);
  };

  //function for delete question from survey
  const deleteQuestionHandler = async () => {
    try {
      let questionData = {
        surveyid: params.id,
        questionid: deleteQuestionId,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_DELETE_QUESTION + `?token=${token}`;

      const response = await putData(requestUrl, questionData);

      if (response.status) {
        setDeleteQuestionId(null);
        let myModal = new bootstrap.Modal(
          document.getElementById("deleteSuccessModal")
        );
        myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getSurveyDetails();
    getAllQuestionTypes();
    getAllQuestions();
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_SURVEY")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pt-2 pt-lg-0 pb-5">
          {/* --- survey question / responses common header start --- */}
          <SurveyQuestionResponseHeader moduleName={moduleName} />
          {/* --- survey question / responses common header end --- */}

          <div className="container-fluid pt-4 pt-md-0 px-lg-5">
            {/* --- bread crumb start --- */}
            <BreadCrumb breadCrumbText={breadcrumbText} />
            {/* --- bread crumb end --- */}

            {/* --- survey container start ---- */}
            <div className="survey-container mt-3">
              {questionList.map((question, index) => {
                return (
                  <div
                    className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-4"
                    key={index}
                  >
                    <div
                      className="accordion"
                      id={`accordionPanelsStayOpenExample${index}`}
                    >
                      <div className="accordion-item bg-transparent border-0">
                        <div className="d-flex align-items-center gap-3">
                          <Link to="#">
                            <span className="d-block material-symbols-outlined">
                              drag_indicator
                            </span>
                          </Link>
                          <h3 className="accordion-header">
                            <button
                              className="accordion-button gap-2 bg-transparent p-0 shadow-none"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#panelsStayOpen-collapse${index}`}
                              aria-expanded="true"
                              aria-controls={`#panelsStayOpen-collapse${index}`}
                            >
                              {question?.questiontype?.name}
                            </button>
                          </h3>
                          <div className="d-flex align-items-center gap-3 ms-auto">
                            <Link
                              to="#"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasSurveyQuestion"
                              aria-controls="offcanvasSurveyQuestion"
                              onClick={() => {
                                setEditedQuestionId(question._id);
                              }}
                            >
                              <span className="d-block material-symbols-outlined">
                                edit
                              </span>
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteQuestionModal"
                              aria-controls="deleteQuestionModal"
                              onClick={() => {
                                setDeleteQuestionId(question._id);
                              }}
                            >
                              <span className="d-block material-symbols-outlined">
                                delete
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div
                          id={`panelsStayOpen-collapse${index}`}
                          className="accordion-collapse collapse show"
                        >
                          <div className="accordion-body p-0 pt-4">
                            <div className="form-group mb-4">
                              <label
                                htmlFor=""
                                className="d-block fs-md fw-semibold text-gray mb-2"
                              >
                                Question Name
                              </label>
                              <p className="fs-lg fw-semibold">
                                {question.name}?
                              </p>
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="d-block fs-md fw-semibold text-gray mb-2"
                              >
                                Answer{" "}
                                {/* <span className="fs-sm fw-normal">(Correct)</span> */}
                              </label>
                              <p className="d-flex align-items-center gap-2 fs-lg fw-semibold">
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">
                                  {question.correctanswer}
                                </span>
                              </p>
                            </div>
                            {question.answers && question.answers.length > 0 ? (
                              <div className="form-group mb-4">
                                <label
                                  htmlFor=""
                                  className="d-block fs-md fw-semibold text-gray mb-2"
                                >
                                  Options
                                </label>
                                <ul className="d-flex flex-wrap gap-2 fs-sm fw-semibold">
                                  {question.answers.map((item, index2) => {
                                    return (
                                      <li
                                        className="d-flex align-items-center gap-2 body-bg rounded-5 px-2 py-1"
                                        key={index2}
                                      >
                                        <span className="d-block">{item}</span>
                                        {/* <Link
                                        to="#"
                                        className="d-block material-symbols-outlined icon-md text-gray"
                                      >
                                        close
                                      </Link> */}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="action d-flex align-items-center gap-3 flex-wrap justify-content-between">
                <Link
                  to="#"
                  className="btn btn-outline-primary d-inline-flex align-items-center gap-1"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasSurveyQuestion"
                  aria-controls="offcanvasSurveyQuestion"
                >
                  <span className="d-block material-symbols-outlined">add</span>
                  <span className="d-block">{t("Add Question")}</span>
                </Link>
                {/* <Link
                to="#"
                className="btn btn-primary d-inline-flex align-items-center gap-1"
                data-bs-toggle="modal"
                data-bs-target="#saveSurvey"
              >
                <span className="d-block material-symbols-outlined">save</span>
                <span className="d-block">Save</span>
              </Link> */}
              </div>
            </div>

            {/* --- survey container end ---- */}
          </div>
        </section>

        {/* ==== modals and popup section === */}
        {/* delete question modal */}
        <DeleteModal
          onDelete={deleteQuestionHandler}
          onCancelDelete={cancelDeleteHandler}
        />

        <AddUpdateQuestionPopup
          questionTypes={questionTypes}
          afterPopupClose={getAllQuestions}
          selectedQuestionId={editedQuestionId}
          setSelectedQuestionId={setEditedQuestionId}
        />

        <DeleteMessageModal afterModalClose={getAllQuestions} />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default SurveyQuestionBody;
