/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const BestBuyerRanking = () => {

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [bestBuyers, setBestBuyers] = useState([]);

  const formatNumber = (unformatNum) =>{
    if(unformatNum){
      return unformatNum.toFixed(2);
    }else{
      return unformatNum;
    }
    
  }

  const columns = useMemo(() => [
    { accessorKey: "_id", header: "Buyer"},    
    { accessorKey: "totalEntradas", header: "Total Tickets",
      // Cell: ({ row }) => (
      //   formatNumber(row.original.average_days_difference)
      // )
    }
  ], [],)

  const getBestBuyer = async () => {

    try {

      let requestUrl =
        url.API_BASE_URL + url.API_GET_BEST_BUYER + `?token=${token}&userrolestring=${userInfo.role.slug}`;
      
      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log('response',response)

      if (response.status) {
        setBestBuyers(response.data);
      }


    } catch (error) {
      console.log(error.message);
    }

  }

  useEffect(() => {
    getBestBuyer();
  }, []);


  return (
    <div className="best-buyer-container py-1">
      <div className="table-responsive">
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={bestBuyers} // data from api to be displayed
            enableBottomToolbar = {false}
            enableGlobalFilterModes = {true}
            enablePagination= {false}
            enableRowNumbers= {true}
            enableRowVirtualization= {true}
          />
        </div>
      </div>
    </div>
  );
};

export default BestBuyerRanking;
