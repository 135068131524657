/* eslint-disable */
import { assetImages } from "constants";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

import {
  getUserBrowser,
  getPublicIP,
  getTimeStamp,
} from "helper/AuthHelper/AuthHelper";

import { ecosystemSlug } from "Config/Config";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

const VerifyEmail = () => {
  const { t } = useTranslation(); // translation marker

  const history = useHistory();

  const {
    userEmailforOtp,
    setuserEmailforOtp,
    verifyModuleName,
    setVerifyModuleName,
  } = useContext(GlobalProvider);

  const fullURL = window.location.href;

  const parsedURL = new URL(fullURL);
  const searchParams = parsedURL.searchParams;

  const refParam = searchParams.get("ref");
  const secretParam = searchParams.get("secret");

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);

  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");
  const [otpFailedMessage, setotpFailedMessage] = useState("");
  const [otpSending, setotpSending] = useState(false);
  const [otpResendMessage, setotpResendMessage] = useState("");

  // function for backspace reference second digit
  const secondCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (secondCode == "" && secondCode.length == 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (thirdCode == "" && thirdCode.length == 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (fourthCode == "" && fourthCode.length == 0) {
        code3Ref.current?.focus();
      }
    }
  };

  //otp login function
  const validateOtpHandler = async () => {
    try {
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      let userData = {
        email: userEmailforOtp,
        otp: finalCode,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_VALIDATE_OTP +
        `?ecosystemslug=${ecosystemSlug}`;

      // console.log("requestURL", requestURL);
      // console.log("userData", userData);

      const response = await postData(requestURL, userData);

      console.log(response);

      if (response.status) {
        setotpFailedMessage("");

        if (verifyModuleName === "OTP") {
          // assigning the loggedin user's id,token,all data to the browser's local storage.
          localStorage.setItem("token", response.token);
          localStorage.setItem("userInfo", JSON.stringify(response.data));
          localStorage.setItem("userID", JSON.stringify(response.data._id));
          localStorage.setItem("ecosystem", response.ecosystemid);
          localStorage.setItem("ecosystemlogo", response.ecosystemlogo);
          localStorage.setItem("ecosystemslug", response.ecosystemslug);
          localStorage.setItem("moduleaccess", response.data.moduleaccessdata);

          if (refParam) {
            window.location.href = `${refParam}?secret=${secretParam}`;
          } else {
            window.location.reload();
          }
        } else if (verifyModuleName === "FORGOT_PASS") {
          resetOtpData();
          history.push("/admin/resetpassword");
        } else {
          console.log("No module selected");
        }
      } else {
        setotpFailedMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //otp resend function
  const resendOtpHandler = async () => {
    try {
      setotpSending(true);
      let userData = {
        email: userEmailforOtp,
      };
      let requestURL = url.API_BASE_URL + url.API_GET_OTP;

      const response = await postData(requestURL, userData);

      console.log(response);

      setotpSending(false);

      if (response.status) {
        setotpResendMessage(t("We have sent OTP in your email"));
      } else {
        setotpResendMessage("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset function
  const resetOtpData = () => {
    setotpFailedMessage(false);
    // setuserEmailforOtp("");
  };

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== "" && firstCode.length == 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== "" && secondCode.length == 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== "" && thirdCode.length == 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  useEffect(() => {
    if (fourthCode != "") {
      validateOtpHandler();
    }
  }, [fourthCode]);

  return (
    <main id="app">
      <div id="content_wrapper">
        <div className="authentication-wrapper verify-email min-h-screen py-5 d-flex align-items-center justify-content-center">
          <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto">
            <div className="title text-center mb-4">
              {/* <img
                src={assetImages.findelOiLogo}
                alt="FindelOI"
                className="img-fluid d-block mx-auto mb-3"
                style={{ maxHeight: "1.25rem" }}
              /> */}
              <h1 className="h2 mb-2">Verify Email</h1>
              <p className="fs-sm text-gray">
                Please enter OTP we just sent you on
              </p>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <p className="d-flex align-items-center justify-content-center gap-2">
                  <Link
                    to="mailto:mariadsouza@email.com"
                    className="text-primary"
                  >
                    {userEmailforOtp}
                  </Link>
                  {/* <span className="d-block material-symbols-outlined text-primary cursor-pointer">
                    edit
                  </span> */}
                </p>
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="enterOTP"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Enter OTP
                </label>
                <div className="d-flex gap-2">
                  <input
                    type="number"
                    ref={code1Ref}
                    value={firstCode}
                    onChange={(e) => {
                      setotpFailedMessage(false);
                      setotpResendMessage("");
                      setFirstCode(e.target.value.slice(0, e.target.maxLength));
                    }}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />

                  <input
                    type="number"
                    ref={code2Ref}
                    value={secondCode}
                    onChange={(e) => {
                      setotpFailedMessage(false);
                      setotpResendMessage("");
                      setSecondCode(
                        e.target.value.slice(0, e.target.maxLength)
                      );
                    }}
                    onKeyDown={secondCodeKey}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code3Ref}
                    value={thirdCode}
                    onChange={(e) => {
                      setotpFailedMessage(false);
                      setotpResendMessage("");
                      setThirdCode(e.target.value.slice(0, e.target.maxLength));
                    }}
                    onKeyDown={thirdCodeKey}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code4Ref}
                    value={fourthCode}
                    onChange={(e) => {
                      setotpFailedMessage(false);
                      setotpResendMessage("");
                      setFourthCode(
                        e.target.value.slice(0, e.target.maxLength)
                      );
                    }}
                    onKeyDown={fourthCodeKey}
                    className="form-control fs-sm shadow-none text-center"
                    placeholder="-"
                    maxLength="1"
                  />
                </div>
              </div>

              {otpFailedMessage == "" ? null : (
                <div className="signin_hdng">
                  <span className="email_info">{otpFailedMessage}.</span>
                </div>
              )}

              {otpResendMessage == "" ? null : (
                <div className="signin_hdng">
                  <span className="email_info">{otpResendMessage}.</span>
                </div>
              )}

              <div className="action d-flex gap-3 justify-content-between">
                <button
                  className="btn btn-outline-primary d-flex align-items-center gap-2"
                  onClick={resendOtpHandler}
                  disabled={otpSending ? true : false}
                  style={{
                    cursor: otpSending ? "not-allowed" : "pointer",
                  }}
                >
                  <span className="d-block material-symbols-outlined">
                    sync
                  </span>
                  <span className="d-block">Resend OTP</span>
                </button>
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  onClick={validateOtpHandler}
                >
                  Next
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default VerifyEmail;
