import React from 'react';
import { Link } from 'react-router-dom';

const ProjectReportHeader = () => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <h3>Details Logs</h3>
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link to="#" className="btn btn-gray d-flex align-items-center gap-1">
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">Filter</span>
          </Link>
          <Link to="#" className="btn btn-gray d-flex align-items-center gap-1">
            <span className="d-block material-symbols-outlined icon-md">
              leaderboard
            </span>
            <span className="d-block">Plan Chart</span>
          </Link>
          <Link to="#" className="btn btn-gray d-flex align-items-center">
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectReportHeader;
