/* eslint-disable */
import React from 'react';

const CourseLessonsFilterPopup = () => {
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="" className="d-flex flex-column h-100">
          <div
            className="accordion flex-fill"
            id="accordionPanelsStayOpenExample"
          >
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Dur. Min.
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group mb-3 mb-sm-4">
                    <label htmlFor="duration" className="d-block fs-sm mb-2">
                      34 - 48
                    </label>
                    <input
                      type="text"
                      id="duration"
                      className="d-none"
                      readOnly
                    />
                    <div
                      id="durationValue"
                      className="d-flex justify-content-between gap-2 fs-sm text-gray-400 mb-2"
                    >
                      <span className="d-block min"></span>
                      <span className="d-block max"></span>
                    </div>
                    <div
                      id="durationSlider"
                      className="bg-gray-300 border-0 rounded-90"
                    ></div>
                  </div>
                  <div className="form-group mb-3 mb-sm-4">
                    <label
                      htmlFor="questionNumber"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Question Number
                    </label>
                    <input
                      type="text"
                      id="questionNumber"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter"
                    />
                  </div>
                  <div className="form-group mb-3 mb-sm-4">
                    <label
                      htmlFor="subscriberNumber"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Subscriber Number
                    </label>
                    <input
                      type="text"
                      id="subscriberNumber"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter"
                    />
                  </div>
                  <div className="form-group mb-3 mb-sm-4">
                    <label
                      htmlFor="dates"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Dates
                    </label>
                    <div className="field-container d-flex gap-2">
                      <input
                        type="date"
                        className="form-control fs-sm shadow-none"
                        placeholder="From"
                      />
                      <input
                        type="date"
                        className="form-control fs-sm shadow-none"
                        placeholder="To"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            Go
          </button>
        </form>
      </div>
    </div>
  );
};

export default CourseLessonsFilterPopup;
