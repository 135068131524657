/* eslint-disable */
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { assetImages } from "constants";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const history = useHistory();

  const { setuserEmailforOtp, setcreateAccountMessage, setVerifyModuleName } =
    useContext(GlobalProvider);

  const { t } = useTranslation(); // translation marker

  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [otpSending, setotpSending] = useState(false);

  //otp function
  const otpHandler = async () => {
    if (userEmail == "") {
      setEmailWarning(true);
    } else {
      setEmailWarning(false);

      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (userEmail.match(mailformat)) {
        setNotValidEMail(false);
        try {
          setotpSending(true);
          let userData = {
            email: userEmail,
          };
          let requestURL = url.API_BASE_URL + url.API_GET_OTP;

          const response = await postData(requestURL, userData);

          console.log(response);

          setotpSending(false);

          if (response.status) {
            setcreateAccountMessage("");
            setuserEmailforOtp(userEmail);
            setVerifyModuleName("FORGOT_PASS");
            history.push("/admin/verifyemail");
          } else {
            setVerifyModuleName("");
            setuserEmailforOtp("");
            setcreateAccountMessage(
              "Email does not exist.Please create a new account."
            );
          }
          setUserEmail("");
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setNotValidEMail(true);
      }
    }
  };

  //reset function
  const resetForgotPass = () => {
    setUserEmail("");
    setuserEmailforOtp("");
    setcreateAccountMessage("");
  };

  return (
    <main id="app">
      <div id="content_wrapper">
        <div className="authentication-wrapper forgot-password min-h-screen py-5 d-flex align-items-center justify-content-center">
          <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto">
            <div className="title text-center mb-4">
              {/* <img
                src={assetImages.findelOiLogo}
                alt="FindelOI"
                className="img-fluid d-block mx-auto mb-3"
                style={{ maxHeight: '1.25rem' }}
              /> */}
              <h1 className="h2 mb-2">Forgot Password</h1>
              <p className="fs-sm text-gray">
                Please enter email id we will send you a OTP
              </p>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label
                  htmlFor="email"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter email")}
                  value={userEmail}
                  onChange={(e) => {
                    setEmailWarning(false);
                    setUserEmail(e.target.value);
                  }}
                />

                {/* ------ email warning start ------ */}
                {emailWarning && (
                  <p className="error mt-1">
                    <span className="material-icons-outlined me-1">info</span>
                    {t("Please enter email")}
                  </p>
                )}
                {/* ----- valid email warning ------ */}
                {notValidEMail && (
                  <p className="error mt-1">
                    <span className="material-icons-outlined me-1">info</span>
                    {t("Please enter valid email")}
                  </p>
                )}
              </div>
              <div className="action d-flex gap-3 justify-content-between">
                <Link to="/" className="btn btn-outline-primary">
                  Back
                </Link>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={otpHandler}
                  disabled={otpSending ? true : false}
                  style={{
                    cursor: otpSending ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Send OTP")}
                  {otpSending && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
