import React from "react";
import { Link, useParams } from "react-router-dom";

const CompanyDetailsCommonHeader = ({ moduleName }) => {
  const params = useParams();

  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid px-lg-5">
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-xl-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="nav-item flex-fill">
            <Link
              to={`/admin/companies/info/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName === "info" && "active"
              }`}
            >
              General Info
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to={`/admin/companies/teammembers/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName === "teammembers" && "active"
              }`}
            >
              Team Members
            </Link>
          </li>
          {/* <li className="nav-item flex-fill">
            <Link
              to={`/admin/companies/faq/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName === 'faq' && 'active'
              }`}
            >
              FAQ
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default CompanyDetailsCommonHeader;
