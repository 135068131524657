/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

//import dnds
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";

import {
  challengeHeaderLinks,
  challnegeBreadCrumbText,
} from "helper/ChallengeHelper/ChallengeHelper";

import SaveColumnModal from "components/Common/Modal/SaveColumnModal";
import AddProcessFromTemplateModal from "components/Common/Modal/AddProcessFromTemplateModal";
import SaveActivityProcessPopup from "components/Common/Popup/ActivityProcessPopup/SaveActivityProcessPopup";
import KanbanHeader from "./Header/KanbanHeader";
import ApproveRejectDetailsPopup from "../Common/Popup/ApproveRejectDetailsPopup";
import ResponseCommentPopup from "../Common/Popup/ResponseCommentPopup";
import ResponseDetailsPopup from "../Common/Popup/ResponseDetailsPopup";
import KanbanFilterPopup from "./Popup/KanbanFilterPopup";
import { useTranslation } from "react-i18next";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const ChallengeResponseKanbanBody = () => {
  // custom style for select process
  const customProcessStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      color: "#666666",
      fontSize: "0.875rem",
      fontWeight: "normal",
      display: "block",
      minHeight: "1.2em",
      // whiteSpace: "nowrap",
    }),

    // Value style
    control: (styles, state) => ({
      ...styles,
      fontSize: "0.875rem",
      borderRadius: "0.5rem",
      minWidth: 296,
      border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? "1px solid #D4D4D4" : "1px solid #D4D4D4",
      },
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "0.875rem",
      };
    },

    // Indicator style
    // dropdownIndicator: (base, state) => {
    //   let changes = { color: "#505050" };
    //   return Object.assign(base, changes);
    // },
  };

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation
  const [title, setTitle] = useState("");

  /* ---- bread crumb text ---- */
  const breadcrumbText = challnegeBreadCrumbText(t("Kanban"), title, t);

  // common header object
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  const [hideEmptyProcessMessage, setHideEmptyProcessMessage] = useState(true);
  const [processListLoading, setprocessListLoading] = useState(false);
  const [processList, setProcessList] = useState([]);

  const [kanbanData, setKanbanData] = useState([]);

  const [reloadKanbanData, setReloadKanbanData] = useState(false);

  const [processDetailsLoading, setProcessDetailsLoading] = useState(false);

  //selected process
  const [selectedProcessvalue, setSelectedProcessvalue] = useState(null);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  const [respId, setRespId] = useState(null);
  const [approvalStatus, setapprovalStatus] = useState("");
  const [selectedHiringProcessId, setSelectedHiringProcessId] = useState(null);

  const [approveComments, setapproveComments] = useState("");

  //function for get project details
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all process
  const getAllProcess = async () => {
    try {
      setprocessListLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PROCESS +
        `?token=${token}&ismod=${true}&istemplate=${false}&moduleslug=MOD_CHALLENGE`;

      const response = await getData(requestURL);

      setprocessListLoading(false);
      console.log("response process----->", response);
      if (response.status) {
        if (response.data.length === 0) {
          setHideEmptyProcessMessage(false);
        }
        const processOptions = response.data.map((item) => ({
          label: item.title,
          value: item._id,
        }));
        proecessSelectionHandler(processOptions[processOptions.length - 1]);
        setProcessList(processOptions);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select process
  const proecessSelectionHandler = (val) => {
    if (val) {
      setSelectedProcessvalue(val);
      getProcessDetails(val.value);
      setSelectedProcessId(val.value);
    } else {
      setSelectedProcessvalue(null);
      getProcessDetails(null);
      setSelectedProcessId(null);
    }
  };

  //get process details
  const getProcessDetails = async (selectedProcessId) => {
    if (selectedProcessId) {
      setProcessDetailsLoading(true);
      try {
        let requestURL =
          url.API_BASE_URL +
          url.API_GET_PROCESS_DETAILS +
          `/${selectedProcessId}?token=${token}&moduleslug=MOD_CHALLENGE`;

        const response = await getData(requestURL);

        setProcessDetailsLoading(false);

        console.log("Process Details Response >> ", response);

        if (response.status && response.data) {
          if (response.data.processlabels.length === 0) {
            setHideEmptyProcessMessage(false);
          }

          setKanbanData(response.data.processlabels);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setKanbanData([]);
    }
  };

  //after Add New  Column
  const afterAddNewColumn = () => {
    if (selectedProcessId) {
      getProcessDetails(selectedProcessId);
    }
  };

  //delete response from board
  const deleteResponseHandler = async (responseData, tagId) => {
    // console.log(responseData);
    let responseUpdatedData = {
      responseid: responseData._id,
      componentorderitemid: responseData.componentorderitemid,
      tagid: tagId,
    };

    let requestURL =
      url.API_BASE_URL + url.API_REMOVE_CARD_ITEM_KANBAN + `?token=${token}`;

    const response = await putData(requestURL, responseUpdatedData);

    console.log(response);

    if (response.status) {
      getProcessDetails(selectedProcessId);
    }
  };

  //approval action
  const approvalHandler = async () => {
    try {
      let approvalData = {
        approvalstatus: approvalStatus,
        hiringprocessid: selectedHiringProcessId || null,
        comments: approveComments,
      };

      console.log(approvalData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_CHANGE_APPROVAL_STATUS +
        `/${respId}?token=${token}`;

      const response = await putData(requestUrl, approvalData);

      console.log(response);

      if (response.status) {
        getAllChallengeResponse();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //drag and drop operation
  const onDragEnd = async (result) => {
    console.log("onDragEnd", result);
    const { destination, source, draggableId, type } = result;

    //if no destination or no drag and drop return.
    if (!destination) {
      return;
    }

    //update kanban table
    let requestURL =
      url.API_BASE_URL +
      url.API_UPDATE_KANBAN +
      `?token=${token}&changetype=${type}`;

    //check the drag and drop type is column
    if (type === "column") {
      // Reordering columns
      const newColumnOrder = Array.from(kanbanData);
      newColumnOrder.splice(source.index, 1)[0]; // Remove from source index
      newColumnOrder.splice(
        destination.index,
        0,
        kanbanData.find((column) => column.componentid === draggableId)
      ); // Insert at destination index

      // Update componentorder field based on new positions
      newColumnOrder.forEach((column, index) => {
        column.componentorder = index + 1; // Adding 1 because componentorder starts from 1
      });

      //update local state
      setKanbanData(newColumnOrder);

      //send data to server for update changes
      const updateKanbanData = {
        source: null,
        destination: null,
        processid: selectedProcessId,
        processlist: newColumnOrder,
        draggableid: draggableId,
        updatedcolumn: null,
      };

      await putData(requestURL, updateKanbanData);
    }

    if (type === "job_response") {
      // Moving task within a column or to another column
      const sourceColumn = kanbanData.find(
        (column) => column.tagid.toString() === source.droppableId.toString()
      );

      const destinationColumn = kanbanData.find(
        (column) =>
          column.tagid.toString() === destination.droppableId.toString()
      );

      // console.log("Source column", sourceColumn);
      // console.log("Destination column", destinationColumn);

      // If the task is moved within the same column
      if (source.droppableId === destination.droppableId) {
        const newSourceColumnResponse = sourceColumn.challengeresponse;

        const updatedTaskData = sourceColumn.challengeresponse.filter(
          (item) => item._id === draggableId
        );

        newSourceColumnResponse.splice(source.index, 1); // Remove from source index

        newSourceColumnResponse.splice(
          destination.index,
          0,
          updatedTaskData[0]
        ); // Insert at destination index

        newSourceColumnResponse.forEach((task, index) => {
          task.order = index + 1;
        });

        const updatedColumn = {
          ...sourceColumn,
          challengeresponse: newSourceColumnResponse,
        };

        const newColumnResponses = kanbanData.map((column) => {
          return column.componentid === sourceColumn.componentid
            ? updatedColumn
            : column;
        });

        setKanbanData(newColumnResponses);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnResponses,
          updatedsourcecolumn: updatedColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        await putData(requestURL, updateKanbanData);
      } else {
        // If the task is moved to another column
        const sourceResponseList = sourceColumn.challengeresponse;
        const destinationResponseList = destinationColumn.challengeresponse;

        const updatedResponses = sourceColumn.challengeresponse.filter(
          (item) => item._id === draggableId
        );

        sourceResponseList.splice(source.index, 1); // Remove from source index

        destinationResponseList.splice(
          destination.index,
          0,
          updatedResponses[0]
        ); // Insert at destination index

        sourceResponseList.forEach((responseData, index) => {
          responseData.order = index + 1;
        });

        const updatedSourceColumn = {
          ...sourceColumn,
          challengeresponse: sourceResponseList,
        };

        //add tag of destination component id which is tag id.
        destinationResponseList.forEach((responseData, index) => {
          if (responseData.tags.includes(source.droppableId)) {
            responseData.tags.splice(source.droppableId, 1);
            responseData.tags.push(destination.droppableId);
          }
          responseData.order = index + 1;
        });

        // console.log(sourceResponseList);
        // console.log(destinationResponseList);

        const updatedDestinationColumn = {
          ...destinationColumn,
          challengeresponse: destinationResponseList,
        };

        const newColumnTasks = kanbanData.map((column) => {
          if (column.componentid === sourceColumn.componentid) {
            return updatedSourceColumn;
          }
          if (column.componentid === destinationColumn.componentid) {
            return updatedDestinationColumn;
          }
          return column;
        });

        setKanbanData(newColumnTasks);

        //send data to server for update changes
        const updateKanbanData = {
          source: source.droppableId,
          destination: destination.droppableId,
          processlist: newColumnTasks,
          updatedsourcecolumn: updatedSourceColumn,
          updateddestinationcolumn: updatedDestinationColumn,
          processid: selectedProcessId,
          draggableid: draggableId,
        };

        await putData(requestURL, updateKanbanData);
      }
    }
  };

  //function for close process template modal
  const afterProcessModalClose = () => {
    getAllProcess();
  };

  useEffect(() => {
    if (params.id) {
      getJobDetails();
      getAllProcess();
    }
  }, [params.id]);

  useEffect(() => {
    if (reloadKanbanData) {
      getJobDetails(selectedProcessId);
      setReloadKanbanData(false);
    }
  }, [reloadKanbanData]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Responses")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- header section ----------- */}
            <KanbanHeader
              breadcrumbText={breadcrumbText}
              ModuleName={"Kanban"}
            />

            {/* -------- board here---------- */}
            {processListLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : kanbanData.length === 0 ? (
              hideEmptyProcessMessage ? null : (
                <div className="challenges_empty text-center">
                  <div className="empty_pic mb-4">
                    {" "}
                    <img src={assetImages.emptyVector} alt="" />
                  </div>
                  <div className="empty_text">
                    <p className="fs-lg text-gray fw-semibold mb-4">
                      {t(
                        "You have not created any process yet please create one to start"
                      )}
                    </p>
                    <Link
                      to="#activityProcessOffCanvas"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                      className="btn btn-primary d-inline-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">{t("Add Process")}</span>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <div className="project-tasks-container">
                <div className="challenges-process-row d-flex align-items-center justify-content-between mb-4 gap-3">
                  <div className="left-process-select">
                    <Select
                      // className="form-select fs-sm shadow-none"
                      isClearable
                      placeholder="Select process"
                      options={processList}
                      value={selectedProcessvalue}
                      onChange={(val) => proecessSelectionHandler(val)}
                      styles={customProcessStyle}
                    />
                  </div>
                  <div className="right-process-btn d-flex align-items-center gap-2">
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        Add Process From Templates
                      </span>
                    </Link>

                    <Link
                      to="#activityProcessOffCanvas"
                      className="btn btn-primary d-flex align-items-center gap-1"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">Add Process</span>
                    </Link>
                  </div>
                </div>

                {processDetailsLoading ? (
                  <div className="board_outer mt-5">
                    <div className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view">
                      {[1, 2, 3, 4].map((item, index) => {
                        return (
                          <div
                            className="col-lg-3 px-2 d-flex flex-column gap-2"
                            key={index}
                          >
                            <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center mb-2">
                              <h3 className="lh-1 mb-0">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{ width: "15rem", height: "0.5rem" }}
                                ></span>
                              </h3>
                            </div>

                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                            <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                              <div className="title d-flex align-items-center gap-3 justify-content-between">
                                <p className="fs-md fw-semibold">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{ width: "15rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <div className="d-flex ms-auto">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "0.1rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  calendar_month
                                </span>
                                <span
                                  className="d-block placeholder"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                                <span
                                  className="d-block placeholder ms-2"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex align-items-center gap-3">
                                <div className="profile d-flex align-items-center gap-2">
                                  <div
                                    className="avatar rounded-circle overflow-hidden"
                                    style={{
                                      width: "22px",
                                      height: "22px",
                                    }}
                                  >
                                    <img
                                      src={assetImages.defaultUser}
                                      alt="User"
                                      className="w-100 h-100 object-fit-cover object-center"
                                    />
                                  </div>
                                  <p className="fs-sm">
                                    <span
                                      className="d-block placeholder text-gray"
                                      style={{
                                        width: "5rem",
                                        height: "0.5rem",
                                      }}
                                    ></span>
                                  </p>
                                </div>
                                <p className="d-flex align-items-center gap-1">
                                  <span className="d-block material-symbols-outlined icon-sm">
                                    schedule
                                  </span>
                                  <span
                                    className="d-block fs-sm placeholder text-gray"
                                    style={{ width: "5rem", height: "0.5rem" }}
                                  ></span>
                                </p>
                                <span
                                  className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                  style={{ width: "5rem", height: "1rem" }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="board_outer mt-5">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable
                        droppableId="all-columns"
                        direction="horizontal"
                        type="column"
                      >
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view"
                          >
                            {kanbanData.map((columnData, index) => {
                              return (
                                <Draggable
                                  key={columnData.componentid}
                                  draggableId={columnData.componentid}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      className="col-lg-3 px-2 d-flex flex-column gap-2"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center">
                                        <h3 className="lh-1 mb-0">
                                          {columnData.processtitle}
                                        </h3>
                                      </div>

                                      <Droppable
                                        droppableId={columnData.tagid}
                                        type="job_response"
                                      >
                                        {(provided) => (
                                          <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            // style={{
                                            //   maxHeight: "400px",
                                            //   overflowY: "scroll",
                                            // }}
                                            className="pt-1"
                                          >
                                            {columnData.challengeresponse.map(
                                              (
                                                challengeResponse,
                                                taskIndex
                                              ) => {
                                                return (
                                                  <Draggable
                                                    key={challengeResponse._id}
                                                    draggableId={
                                                      challengeResponse._id
                                                    }
                                                    index={taskIndex}
                                                  >
                                                    {(provided) => (
                                                      <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                        className="single-card p-3 border border-gray-300 rounded-10 mb-2"
                                                        style={{
                                                          ...provided
                                                            .draggableProps
                                                            .style,
                                                        }}
                                                      >
                                                        <div className="title d-flex align-items-center gap-3 justify-content-between">
                                                          <p className="fs-md fw-semibold">
                                                            {
                                                              challengeResponse.user
                                                            }
                                                          </p>
                                                          <div className="dropdown d-flex ms-auto">
                                                            <button
                                                              className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                                                              type="button"
                                                              data-bs-toggle="dropdown"
                                                              aria-expanded="false"
                                                            >
                                                              <span className="d-block material-symbols-outlined icon-md">
                                                                more_vert
                                                              </span>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm border-0 rounded-10 shadow-sm">
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="#"
                                                                  data-bs-toggle="offcanvas"
                                                                  data-bs-target={
                                                                    challengeResponse.approvalstatus ===
                                                                    "0"
                                                                      ? "#offcanvasResponseDetails"
                                                                      : "#offcanvasResponseApproved"
                                                                  }
                                                                  aria-controls={
                                                                    challengeResponse.approvalstatus ===
                                                                    "0"
                                                                      ? "#offcanvasResponseDetails"
                                                                      : "#offcanvasResponseApproved"
                                                                  }
                                                                  onClick={() => {
                                                                    setRespId(
                                                                      challengeResponse._id
                                                                    );
                                                                  }}
                                                                >
                                                                  View Details
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="#"
                                                                  onClick={() => {
                                                                    deleteResponseHandler(
                                                                      challengeResponse,
                                                                      columnData.tagid
                                                                    );
                                                                  }}
                                                                >
                                                                  Delete
                                                                  Response
                                                                </Link>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                        <p className="fs-sm text-gray d-flex align-items-center gap-1 mb-3">
                                                          <span className="d-block material-symbols-outlined icon-sm">
                                                            calendar_month
                                                          </span>
                                                          <span className="d-block">
                                                            {
                                                              challengeResponse.responsedate
                                                            }
                                                          </span>
                                                        </p>
                                                        <div className="d-flex align-items-center gap-3">
                                                          <div className="profile d-flex align-items-center gap-2">
                                                            <div
                                                              className="avatar rounded-circle overflow-hidden"
                                                              style={{
                                                                width: "22px",
                                                                height: "22px",
                                                              }}
                                                            >
                                                              <img
                                                                src={
                                                                  challengeResponse.userimage ===
                                                                  ""
                                                                    ? assetImages.defaultUser
                                                                    : url.SERVER_URL +
                                                                      challengeResponse.userimage
                                                                }
                                                                alt="User"
                                                                className="w-100 h-100 object-fit-cover object-center"
                                                              />
                                                            </div>
                                                            <p className="fs-sm">
                                                              {
                                                                challengeResponse.responsecode
                                                              }
                                                            </p>
                                                          </div>
                                                          <p className="d-flex align-items-center gap-1">
                                                            <span className="d-block material-symbols-outlined icon-sm">
                                                              euro_symbol
                                                            </span>
                                                            <span className="d-block fs-sm">
                                                              {
                                                                challengeResponse.duration
                                                              }{" "}
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                );
                                              }
                                            )}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}

                            {provided.placeholder}

                            <div className="col-md-3 col-lg-3 mb-3 mb-lg-0">
                              <Link
                                to="#"
                                className="d-flex align-items-center gap-2 fs-lg fw-semibold text-gray mb-3"
                                data-bs-toggle="modal"
                                data-bs-target="#saveColumn"
                              >
                                <span className="d-block material-symbols-outlined icon-lg">
                                  add_circle
                                </span>
                                <span className="d-block">Add Column</span>
                              </Link>
                              <div className="black-space">
                                <img src={assetImages.blankSpace} />
                              </div>
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>

        {/* ------------ filter popup -------------- */}
        <KanbanFilterPopup />

        {/* ------------ response details popup ---------------  */}

        {/* dnd modals */}
        <SaveColumnModal
          selectedProcessId={selectedProcessId}
          setSelectedProcessId={setSelectedProcessId}
          afterModalClose={afterAddNewColumn}
        />

        <SaveActivityProcessPopup
          moduleSlug={"MOD_CHALLENGE"}
          afterPopupClose={getAllProcess}
          setSelectProcessId={() => {}}
          selectedProcessId={null}
          isTemplate={false}
        />

        <AddProcessFromTemplateModal
          moduleSlug={"MOD_CHALLENGE"}
          afterProcessModalClose={afterProcessModalClose}
        />

        <ResponseDetailsPopup
          setRespId={setRespId}
          respId={respId}
          setapprovalStatus={setapprovalStatus}
          setSelectedHiringProcessId={setSelectedHiringProcessId}
        />

        <ApproveRejectDetailsPopup
          setRespId={setRespId}
          respId={respId}
          setapprovalStatus={setapprovalStatus}
          setSelectedHiringProcessId={setSelectedHiringProcessId}
        />

        <ResponseCommentPopup
          setapproveComments={setapproveComments}
          approveComments={approveComments}
          approvalHandler={approvalHandler}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          // onCancelDelete={() => {
          //   setRowSelection({});
          //   setSelectedBlogIds([]);
          // }}
          // onDelete={changeStatusHandler}
          moduleName={t("challenge")}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ChallengeResponseKanbanBody;
