/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const AddEditCetificateModal = ({
  technicalExpIndex,
  settechnicalExpIndex,
  technicalExperienceData,
  settechnicalExperienceData,
  technicalExpBlock,
  settechnicalExpBlock,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_BY_SEARCH +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      settechnicalExperienceData((prevData) => ({
        ...prevData,
        companyvalue: val,
        companyname: val.label,
      }));
    } else {
      settechnicalExperienceData((prevData) => ({
        ...prevData,
        companyvalue: null,
        companyname: "",
      }));
    }
  };

  //function for add edcuation block
  const addTechExpHandler = () => {
    settechnicalExpBlock([...technicalExpBlock, technicalExperienceData]);
    closeModalHandler();
  };

  //function for edit education block
  const editTechExpBlockHandler = () => {
    const updatedTechExpList = [...technicalExpBlock];
    updatedTechExpList[technicalExpIndex] = technicalExperienceData;
    settechnicalExpBlock(updatedTechExpList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    settechnicalExperienceData({
      name: "",
      companyvalue: null,
      companyname: "",
      start: "",
      end: "",
      description: "",
      grade: "",
    });
    settechnicalExpIndex(null);
  };

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="modal fade"
      id="addCertificateModal"
      tabIndex="-1"
      aria-labelledby="addCertificateModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          {/* ------ modal head section start ----- */}
          <div className="modal-header p-4 pb-0 border-0">
            {/* ------ modal title start ----- */}
            <h2 className="fw-bold mb-0" id="addCertificateModal">
              {technicalExpIndex != null
                ? t("Update Certificate")
                : t("Add Certificate")}
            </h2>
            {/* ------ modal title end ----- */}

            {/* ------ modal close button start ----- */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
            {/* ------ modal close button end ----- */}
          </div>
          {/* ------ modal head section end ----- */}

          {/* ------ modal body section start ----- */}
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ------ course section start ----- */}

              <div className="form-group mb-4">
                <label
                  htmlFor="selectTags"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Name of Course")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Course")}
                  value={technicalExperienceData.name}
                  onChange={(e) => {
                    settechnicalExperienceData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              {/* ------ course section end ----- */}

              {/* ------ company selection section start ------ */}
              <div className="form-group mb-4">
                <label
                  htmlFor="selectTags"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Company")}
                </label>
                <CreatableSelect
                  placeholder={t("Type Company name")}
                  isClearable
                  options={companyOptionsToShow}
                  value={technicalExperienceData.companyvalue}
                  onChange={(val) => {
                    companySelectionHandler(val);
                  }}
                  onInputChange={(val) => {
                    setcompanyInput(val);
                  }}
                />
              </div>
              {/* ------ company selection section end ------ */}

              {/* ------ Date section start ----- */}
              <div className="form-group mb-4">
                <div className="row">
                  {/* ------ from year section start ----- */}
                  <div className="col-6">
                    <label
                      htmlFor="grade"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Start Date")}
                    </label>
                    <input
                      className="form-control fs-sm shadow-none"
                      type="date"
                      placeholder={t("Start Date")}
                      value={technicalExperienceData.start}
                      onChange={(e) => {
                        settechnicalExperienceData((prevData) => ({
                          ...prevData,
                          start: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {/* ------ from year section end ----- */}

                  {/* ------ end year section start ----- */}
                  <div className="col-6">
                    <label
                      htmlFor="university"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("To Date")}
                    </label>
                    <input
                      className="form-control fs-sm shadow-none"
                      type="date"
                      placeholder={t("End date")}
                      value={technicalExperienceData.end}
                      onChange={(e) => {
                        settechnicalExperienceData((prevData) => ({
                          ...prevData,
                          end: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {/* ------ end year section end ----- */}
                </div>
              </div>
              {/* ------ Date section end ----- */}

              {/* ------ Details section start ----- */}
              <div className="form-group mb-4">
                {" "}
                <label
                  htmlFor="year"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Details")}
                </label>
                <textarea
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter Details")}
                  value={technicalExperienceData.description}
                  onChange={(e) => {
                    settechnicalExperienceData((prevData) => ({
                      ...prevData,
                      description: e.target.value,
                    }));
                  }}
                ></textarea>
              </div>
              {/* ------ Details section end ----- */}

              {/* ------ Grade section start ----- */}
              <div className="form-group mb-4">
                {" "}
                <label
                  htmlFor="Grade"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Grade")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Grade")}
                  value={technicalExperienceData.grade}
                  onChange={(e) => {
                    settechnicalExperienceData((prevData) => ({
                      ...prevData,
                      grade: e.target.value,
                    }));
                  }}
                />
              </div>
              {/* ------ Grade section end ----- */}

              {/* ------ add/edit button start ----- */}
              <div className="action">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={
                    technicalExpIndex != null
                      ? editTechExpBlockHandler
                      : addTechExpHandler
                  }
                >
                  {technicalExpIndex != null
                    ? t("Update Certificates")
                    : t("Add Certificate")}
                </button>
              </div>
              {/* ------ add/edit button end ----- */}
            </form>
          </div>
          {/* ------ modal body section end ----- */}
        </div>
      </div>
    </div>
  );
};

export default AddEditCetificateModal;
