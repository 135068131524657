/* eslint-disable */
import Header from "components/Common/Header/Header";
import EventListBody from "components/EventComponents/EventListComponents/EventListBody/EventListBody";
import { useEffect } from "react";

const EventsList = () => {
  useEffect(() => {
    document.title = "Events";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="events" />

      {/* ----- event list body ----- */}
      <EventListBody />
    </main>
  );
};

export default EventsList;
