/* eslint-disable */
import Header from "components/Common/Header/Header";
import CompanyGeneralInfoBody from "components/CompaniesComponents/CompanyDetails/CompanyGeneralInfoComponents/CompanyGeneralInfoBody/CompanyGeneralInfoBody";
import React, { useEffect } from "react";
const CompanyGeneralInfo = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Companies";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="companies" />

      {/* ------ body section --------- */}
      <CompanyGeneralInfoBody />
    </main>
  );
};

export default CompanyGeneralInfo;
