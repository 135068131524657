/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import ActivityProcessForm from "components/Common/ActivityProcessForm/ActivityProcessForm";

const SaveProcessBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  const history = useHistory();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    {
      title: "CRM",
      link: "/admin/crm/list",
    },
    {
      title: "Process",
      link: "/admin/crm/process/list",
    },
    { title: "Save" },
  ];

  const afterModalClose = (pathName) => {
    history.push(pathName);
  };

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption={t("Activity Process")}
        />
        <div className="container-fluid px-3">
          <ActivityProcessForm
            moduleName="crm"
            moduleslug=""
            breadcrumbText={breadcrumbText}
            isTemplate={false}
            afterModalClose={afterModalClose}
          />
        </div>
      </section>
    </div>
  );
};

export default SaveProcessBody;
