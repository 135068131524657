export const appUserHeaderLinks = (id, t) => {
  return [
    { title: t("Profile info"), link: `/admin/appuser/profile/${id}` },
    { title: t("CV"), link: `/admin/appuser/cv/${id}` },
    { title: t("Privacy"), link: `/admin/appuser/privacy/${id}` },
    { title: t("Password"), link: `/admin/appuser/password/${id}` },
    { title: t("Availability"), link: `/admin/appuser/availability/${id}` },
    { title: t("Settings"), link: `/admin/appuser/settings/${id}` },
    { title: t("Survey Invites"), link: `/admin/appuser/surveyinvites/${id}` },
  ];
};
