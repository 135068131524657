import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
const CourseCertificationFilterPopup = () => {
  const { t } = useTranslation(); //for translation
  // select data --------------------------
  const options = [
    { label: "Option 1", value: "Option 1" },
    { label: "Option 2", value: "Option 2" },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="" className="d-flex flex-column h-100">
          <div className="field-container flex-fill">
            <div className="form-group mb-3 mb-sm-4">
              <label htmlFor="dates" className="d-block fs-sm fw-semibold mb-2">
                {t("Dates")}
              </label>
              <div className="field-container d-flex gap-2">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From")}
                />
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To")}
                />
              </div>
            </div>
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="points"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Points")}
              </label>
              <label htmlFor="points" className="d-block fs-sm mb-2">
                34 - 48
              </label>
              <input type="text" id="points" className="d-none" readOnly />
              <div
                id="pointsValue"
                className="d-flex justify-content-between gap-2 fs-sm text-gray-400 mb-2"
              >
                <span className="d-block min"></span>
                <span className="d-block max"></span>
              </div>
              <div
                id="pointsSlider"
                className="bg-gray-300 border-0 rounded-90"
              ></div>
            </div>
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="status"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Status")}
              </label>
              <Select
                placeholder={t("Select options")}
                options={options}
                value={selectOptions}
                onChange={(val) => {
                  setSelectOptions(val);
                }}
              />
            </div>
            <div className="form-group mb-3 mb-sm-4">
              <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
                {t("Email")}
              </label>
              <input
                type="email"
                id="email"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Email")}
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Go")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CourseCertificationFilterPopup;
