import Header from 'components/Common/Header/Header';
import CourseChapterList from 'components/CourseComponents/CourseDetails/CourseChapterComponents/CourseChapterList/CourseChapterList';
import React, { useEffect } from 'react';

const CourseChapters = () => {
  useEffect(() => {
    document.title = 'Course Chapters';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CourseChapterList />
    </main>
  );
};

export default CourseChapters;
