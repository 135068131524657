/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const SaveCategoryPopup = ({
  afterPopupCLose,
  parentCategories,
  categroyId,
  setCategoryId,
}) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isRoot, setIsRoot] = useState(false);
  const [parentValue, setParentValue] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [isFeatured, setIsFeatured] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    parentwarning: false,
  });

  //parent selection
  const parentSelectionHandler = (val) => {
    setValidation((prevState) => ({
      ...prevState,
      parentwarning: false,
    }));
    if (val) {
      setParentValue(val);
      setParentId(val.value);

      if (name !== "") {
        setSlug(`${val.slug}_${name === "" ? "" : name.toUpperCase()}`);
      }
    } else {
      setParentValue(null);
      setParentId(null);
    }
  };

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (isRoot === false && parentValue === null) {
      setValidation((prevState) => ({ ...prevState, parentwarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //get details
  const getDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORY_DETAILS +
        `/${categroyId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setName(response.data.name);
        setSlug(response.data.slug);
        setIsRoot(response.data.isroot);
        setParentValue(response.data.parentdata);
        setParentId(response.data.parentdata.value);
        // parentSelectionHandler(response.data.parentdata);
        setIsFeatured(response.data.isfeatured);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        let categoryData = {
          name,
          slug,
          isroot: isRoot,
          parent: parentId,
          isfeatured: isFeatured,
        };

        console.log("categoryData------>", categoryData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (categroyId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_CATEGORY +
            `/${categroyId}` +
            `?token=${token}`;

          response = await putData(requestUrl, categoryData);
        } else {
          requestUrl = requestUrl + url.API_ADD_CATEGORY + `?token=${token}`;

          response = await postData(requestUrl, categoryData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#saveCategory")
          ).hide();
          resetHandler();
          afterPopupCLose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setName("");
    setSlug("");
    setParentValue(null);
    setParentId(null);
    setIsRoot(true);
    setIsFeatured(false);
    setValidation({
      nameWarning: false,
      slugWarning: false,
      parentwarning: false,
    });
    setCategoryId(null);
  };

  useEffect(() => {
    if (categroyId) {
      getDetails();
    }
  }, [categroyId]);

  useEffect(() => {
    setIsRoot(true);
  }, []);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveCategory"
      aria-labelledby="saveCategory"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Save Category")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Category Name")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                }}
              />
              {/* level warning */}
              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter label")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* slug */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              {/* slug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* is root */}
            <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
              <input
                className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                type="checkbox"
                checked={isRoot}
                onChange={(e) => {
                  if (e.target.checked) {
                    parentSelectionHandler(null);
                    setValidation((prevState) => ({
                      ...prevState,
                      parentwarning: false,
                    }));
                  }
                  setIsRoot(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="private">
                {t("Is Root")}
              </label>
            </div>

            {/* select parent */}
            {isRoot ? null : (
              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Parent")}
                </label>
                <Select
                  isClearable
                  placeholder={t("Select Parent")}
                  options={parentCategories}
                  value={parentValue}
                  onChange={(val) => parentSelectionHandler(val)}
                />
                {validation.parentwarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please select parent")}!</span>
                    </p>
                  </div>
                )}
              </div>
            )}

            {/* is featured */}
            <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
              <input
                className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                type="checkbox"
                checked={isFeatured}
                onChange={(e) => setIsFeatured(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="private">
                {t("Is Featured")}
              </label>
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveCategoryPopup;
