/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ContactOpportunityBody from "components/CRMComponents/CRMContactDetailsComponents/CRMContactOpportunityComponents/ContactOpportunityBody/ContactOpportunityBody";

const CRMContactOpportunities = () => {
  useEffect(() => {
    document.title = "CRM Lead Contact | Opportunity";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <ContactOpportunityBody />
    </main>
  );
};

export default CRMContactOpportunities;
