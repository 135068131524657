/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import { assetImages } from 'constants';
import { courseDetailsHeaderObject } from 'helper/CourseHelper/CourseHelper';

import CourseChapterHeader from '../Header/CourseChapterHeader';
import MaterialReactTable from 'material-react-table';
import SaveChapterPopup from '../Popup/SaveChapterPopup';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';

const CourseChapterList = () => {
  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const { t, i18n } = useTranslation(); //for translation
  const dummyId = '1234';
  const [title, settitle] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [courseChapters, setcourseChapters] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedChapterIds, setselectedChapterIds] = useState([]);
  const [selectedChapterId, setselectedChapterId] = useState(null);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get course list
  const getAllCoursesChapters = async () => {
    setRowSelection({});
    setselectedChapterIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_CHAPTERS +
        `?token=${token}&courseid=${params.id}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType('success');
        setcourseChapters(response.data);
      } else {
        setAlertMessage('error');
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType('error');
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //finction for delete course
  const deleteMassiveChapterHandler = async () => {
    if (selectedChapterIds.length > 0) {
      const courseChapterListToBeDelete = courseChapters.filter(item =>
        selectedChapterIds.includes(item._id)
      );

      try {
        let courseData = {
          chapterlist: courseChapterListToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_COURSE_CHAPTERS +
          `?token=${token}`;

        const response = await putData(requestURL, courseData);

        console.log(response);

        if (response.status) {
          setMessageType('success');
          setTimeout(() => {
            getAllCoursesChapters();
          }, 2500);
        } else {
          setMessageType('error');
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
      }

      setShowAlert(true);
    } else {
      setMessageType('error');
      setAlertMessage('Please select atleast one course');
      setShowAlert(true);
    }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedcourseChapterList = [...courseChapters];

    const rowIndex = updatedcourseChapterList.findIndex(
      course => course._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedcourseChapterList[rowIndex].rank = value;
      setcourseChapters(updatedcourseChapterList);
    }
  };

  //function for update rank
  const updateBulkChapterHandler = async e => {
    if (selectedChapterIds.length > 0) {
      try {
        const courseChapterListToBeUpdate = courseChapters.filter(item =>
          selectedChapterIds.includes(item._id)
        );

        let courseData = {
          chapterlist: courseChapterListToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_MASSIVE_COURSE_CHAPTERS +
          `?token=${token}`;

        const response = await putData(requestURL, courseData);

        console.log(response);

        if (response.status) {
          setMessageType('success');

          setTimeout(() => {
            getAllCoursesChapters();
          }, 1500);
        } else {
          setMessageType('error');
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select atleast one course');
    }
    setShowAlert(true);
  };

  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#addUpdateChapterOffCanvas"
            aria-controls="addUpdateChapterOffCanvas"
            onClick={() => {
              setselectedChapterId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'title',
        header: t('Title'),
        size: 250,
      },
      {
        accessorKey: 'rank',
        header: t('Rank'),
        Cell: ({ row }) => (
          <div className="ranking border-bottom-0">
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '100px' }}
              value={row.original.rank}
              onChange={e => rankInputChangeHandler(row, e.target.value)}
            />
          </div>
        ),
      },
      {
        accessorKey: 'code',
        header: t('Code'),
      },
      {
        accessorKey: 'membernames',
        header: t('Member Name'),
        size: 250,
      },
      {
        accessorKey: 'lessoncount',
        header: t('Lessons'),
      },
      {
        accessorKey: 'createddate',
        header: t('Date'),
      },
    ],
    [courseChapters, i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setselectedChapterIds(selectedIdsArray);
    } else {
      setselectedChapterIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getAllCoursesChapters();
    }
  }, [params.id]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_COURSE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t('Chapters')}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ------- header area ---------- */}
            <CourseChapterHeader
              title={title}
              reloadList={getAllCoursesChapters}
              deleteHandler={deleteMassiveChapterHandler}
              bulkUpdateHandler={updateBulkChapterHandler}
            />

            {/* ------- list area ---------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={courseChapters} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  enableRowActions={false}
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- save chapter popup ---------- */}
        <SaveChapterPopup
          afterPopupClose={getAllCoursesChapters}
          selectedChapterId={selectedChapterId}
          setSelectedChapterId={setselectedChapterId}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseChapterList;
