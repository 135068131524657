/* eslint-disable */
import { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

// Import CKEditor here (both required).
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//import time calculation
import { getCurrentTime } from 'helper/TimeCalculation';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from 'utils/Gateway';

import fileDownloadHandler from 'helper/DownloadFile';
import { assetImages } from 'constants';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import { eventDetailsHeaderLinks } from 'helper/EventHelper/EventHelper';
import downloadFileHandler from 'helper/DownloadFile';
import SubEventHeader from '../Header/SubEventHeader';

const SubEventList = () => {
  const { t, i18n } = useTranslation(); //for translation
  const dateOfToday = new Date().toISOString().split('T')[0];

  const params = useParams();
  const history = useHistory();

  // -------- tabs header object --------------------
  const commonHeaderObject = eventDetailsHeaderLinks(params.id, t);

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [isLoading, setIsLoading] = useState(false);
  const [eventDataList, setEventDataList] = useState([]);
  const [parentEventTitle, setParentEventTitle] = useState('');

  const [isEventDetailsLoading, setIsEventDetailsLoading] = useState(false);

  const [isEventModerator, setisEventModerator] = useState(false);

  const [eventMemberStatus, setEventMemberStatus] = useState('');
  const [eventModStatus, setEventModStatus] = useState('');

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedEventIds, setSelectedEventIds] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get event details
  const getEventDetails = async () => {
    setIsEventDetailsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${params.id}?token=${token}`;

      console.log('url of event details------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response of event details------>', response);

      if (response.status) {
        //assign title
        response.data?.title && setParentEventTitle(response.data?.title);

        let isModerator = false;

        if (
          response.data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN'
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisEventModerator(isModerator);

        setIsEventDetailsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_MEMBER_DETAILS_BY_QUERY +
        `?token=${token}&eventid=${params.id}`;

      const response = await getData(requestUrl);

      console.log('response in member details', response);

      if (response.status) {
        setEventMemberStatus(response.data.memberstatus);
        setEventModStatus(response.data.moderatorstatus);
        setinviteDate(response.data.requestdate);
        setJoinDate(response.data.joindate);
      } else {
        setAlertMessage(response.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get all lead records
  const getAllEvents = async () => {
    setRowSelection({});
    setSelectedEventIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&filterparentid=${params.id}`;

      console.log('url of event list---------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in event list------->', response);

      setIsLoading(false);
      if (response.status) {
        setEventDataList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getEventDetails();
      getAllEvents();
    }
  }, [params.id]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t('Title'),
        size: 200,
      },
      {
        accessorKey: 'eventtypename',
        header: t('Type'),
      },
      {
        accessorKey: 'eventmembercount',
        header: t('Members'),
      },
      {
        accessorKey: 'moderatorname',
        header: t('Moderator'),
        Cell: ({ renderedCellValue, row }) => (
          <div className="moderator border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '22px', height: '22px' }}
              >
                <img
                  src={
                    row.original.moderatorimage == ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.moderatorimage
                  }
                  alt="admin"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{renderedCellValue}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'eventstartdate',
        header: t('Start Date'),
      },
      {
        accessorKey: 'eventenddate',
        header: t('End Date'),
      },
      {
        accessorKey: 'eventlocation',
        header: t('Place'),
        Cell: ({ row }) => (
          <div className="place border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-lg">
                pin_drop
              </span>
              <span>{row.original.eventlocation}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'eventstatusname',
        header: t('Status'),
        Cell: ({ renderedCellValue, row }) => (
          <div className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-1 fw-semibold ${
                row.original.eventstatus == '0'
                  ? 'text-warning'
                  : row.original.eventstatus == '1'
                  ? 'text-primary'
                  : 'text-success'
              }`}
            >
              <span className="d-block material-symbols-outlined icon-lg">
                {row.original.eventstatus == '0'
                  ? 'error'
                  : row.original.eventstatus == '1'
                  ? 'schedule'
                  : 'check_circle'}
              </span>
              <span>{renderedCellValue}</span>
            </p>
          </div>
        ),
      },
    ],
    [eventDataList, i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedEventIds(selectedIdsArray);
    } else {
      setSelectedEventIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_EVENT')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- event details common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t('Sub Events')}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            <SubEventHeader
              reloadEventList={getAllEvents}
              parentEventTitle={parentEventTitle}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isEventDetailsLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isEventModerator ||
              (eventMemberStatus === '1' && eventModStatus === '1') ? (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={eventDataList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      key="edit"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/info/${row.id}`}
                      >
                        {t('View Details')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="member"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/members/${row.id}`}
                      >
                        {t('View Members')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="partner"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/eventpartner/${row.id}`}
                      >
                        {t('View Partners')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="agenda"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/agenda/${row.id}`}
                      >
                        {t('View Agenda')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="gallery"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/events/gallery/${row.id}`}
                      >
                        {t('View Gallery')}
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            ) : eventMemberStatus === '' && eventModStatus === '' ? null : (
              <div className="table-wrapper">
                <div className="team_members mb-4">
                  <div className="challenges_empty text-center">
                    <div className="empty_pic mb-4">
                      {' '}
                      <img src={assetImages.emptyVector} alt="" />
                    </div>
                    <div className="empty_text">
                      <p className="fs-lg text-gray fw-semibold mb-4">
                        {t(
                          "Sorry....! You don't have privilege to see this content"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default SubEventList;
