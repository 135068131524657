/* eslint-disable */
import Header from 'components/Common/Header/Header';
import OfferListBody from 'components/OfferComponents/OfferListComponents/OfferListBody/OfferListBody';
import React, { useEffect } from 'react';

const OfferList = () => {
  useEffect(() => {
    document.title = 'Offers';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="offers" />

      {/* ---- body section ---- */}
      <OfferListBody />
    </main>
  );
};

export default OfferList;
