import React, { useState } from 'react';
import Select from 'react-select';

const ProjectInvestmentFilterPopup = () => {
  // select data --------------------------
  const role = [
    { label: 'Manager', value: 'Manager' },
    { label: 'Team Lead', value: 'Team Lead' },
  ];

  // useState to select data ------------------
  const [selectStatus, setSelectStatus] = useState(null);
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="">
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Status
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <div className="form-group">
                      <Select
                        placeholder="Select Role"
                        options={role}
                        value={selectStatus}
                        onChange={val => {
                          setSelectStatus(val);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Dates
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group d-flex gap-2">
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder="From date"
                    />
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder="To date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Investment
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <label htmlFor="investment" className="d-block fs-sm mb-2">
                      2,000 - 9,000
                    </label>
                    <input
                      type="text"
                      id="investment"
                      className="d-none"
                      readOnly
                    />
                    <div
                      id="investmentValue"
                      className="d-flex justify-content-between gap-2 fs-sm text-gray-400 mb-2"
                    >
                      <span className="d-block min"></span>
                      <span className="d-block max"></span>
                    </div>
                    <div
                      id="investmentSlider"
                      className="bg-gray-300 border-0 rounded-90"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            Go
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProjectInvestmentFilterPopup;
