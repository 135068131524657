/* eslint-disable */
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const ChallengeResponseHeader = ({
  breadcrumbText,
  ModuleName,
  reloadList,
}) => {
  const params = useParams();
  const { t } = useTranslation(); //for translation
  // const [listCanvanToggle, setListCanvanToggle] = useState("List");

  const listCanban = [
    {
      name: t("List"),
      path: `/admin/challenges/responses/list/${params.id}`,
    },
    {
      name: t("Kanban"),
      path: `/admin/challenges/responses/kanban/${params.id}`,
    },
  ];
  // const [listCanban, setListCanban] = useState(['List', 'Canvan']);

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ----------- bread crumb ------------- */}
        <div className="col-md-6 mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          {/* -------------- refresh ----------------- */}
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("List")}{" "}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
              {listCanban.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      // onClick={() => setListCanvanToggle(item)}
                      to={item.path}
                      className="dropdown-item"
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFilter"
            aria-controls="offcanvasFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChallengeResponseHeader;
