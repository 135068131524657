import Header from "components/Common/Header/Header";
import SurveyQuestionBody from "components/SurveyComponents/SurveyQuestionResponseComponents/SurveyQuestionComponents/SurveyQuestionBody/SurveyQuestionBody";
import React from "react";

const SurveyQuestion = () => {
  return (
    <>
      <main id="app">
        {/* ---- header start ---- */}
        <Header moduleName="survey" />
        {/* ---- header end ---- */}

        {/* --- body section start ---- */}
        <SurveyQuestionBody moduleName="question" />
        {/* --- body section end ---- */}
      </main>
    </>
  );
};

export default SurveyQuestion;
