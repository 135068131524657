import Header from 'components/Common/Header/Header';
import CourseDetailBody from 'components/CourseComponents/CourseDetails/CourseDetailBody/CourseDetailBody';
import React, { useEffect } from 'react';

const CourseDetail = () => {
  useEffect(() => {
    document.title = 'Course Details';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CourseDetailBody />
    </main>
  );
};

export default CourseDetail;
