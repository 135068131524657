/* eslint-disable */
import React, { useEffect } from "react";

//** import components */
import Header from "components/Common/Header/Header";
import AvailabilityCalendarBody from "components/AvailabilityCalendar/AvailabilityCalendarBody";

const AvailabilityCalendar = () => {
  //assign browser title is dashboard
  useEffect(() => {
    document.title = "Calendar";
  }, []);

  return (
    <main id="app">
      <Header moduleName="dashboard" />
      <AvailabilityCalendarBody />
    </main>
  );
};

export default AvailabilityCalendar;
