/* eslint-disable */
import React from 'react';

const OfferListFilterPopup = () => {
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="">
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Price
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <label htmlFor="price" className="d-block fs-sm mb-2">
                      €0 - €10,00,000
                    </label>
                    <input type="text" id="price" className="d-none" readOnly />
                    <div
                      id="priceValue"
                      className="d-flex justify-content-between gap-2 fs-sm text-gray-400 mb-2"
                    >
                      <span className="d-block min"></span>
                      <span className="d-block max"></span>
                    </div>
                    <div
                      id="priceSlider"
                      className="bg-gray-300 border-0 rounded-90"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Status
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    <li className="tag">
                      <input
                        type="checkbox"
                        id="allPosition"
                        className="d-none"
                      />
                      <label
                        htmlFor="allPosition"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        All Status
                      </label>
                    </li>
                    <li className="tag">
                      <input type="checkbox" id="manager" className="d-none" />
                      <label
                        htmlFor="manager"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        Listed
                      </label>
                    </li>
                    <li className="tag">
                      <input type="checkbox" id="ceo" className="d-none" />
                      <label
                        htmlFor="ceo"
                        className="d-block px-3 py-2 cursor-pointer border border-gray-300 rounded-10"
                      >
                        Sold
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Dates
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group d-flex gap-2">
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder="From date"
                    />
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder="To date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  Quantity
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <label htmlFor="quantity" className="d-block fs-sm mb-2">
                      0 - 10,000
                    </label>
                    <input
                      type="text"
                      id="quantity"
                      className="d-none"
                      readOnly
                    />
                    <div
                      id="quantityValue"
                      className="d-flex justify-content-between gap-2 fs-sm text-gray-400 mb-2"
                    >
                      <span className="d-block min"></span>
                      <span className="d-block max"></span>
                    </div>
                    <div
                      id="quantitySlider"
                      className="bg-gray-300 border-0 rounded-90"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  Responses
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <label htmlFor="response" className="d-block fs-sm mb-2">
                      0 - 10,000
                    </label>
                    <input
                      type="text"
                      id="response"
                      className="d-none"
                      readOnly
                    />
                    <div
                      id="responseValue"
                      className="d-flex justify-content-between gap-2 fs-sm text-gray-400 mb-2"
                    >
                      <span className="d-block min"></span>
                      <span className="d-block max"></span>
                    </div>
                    <div
                      id="responseSlider"
                      className="bg-gray-300 border-0 rounded-90"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            Go
          </button>
        </form>
      </div>
    </div>
  );
};

export default OfferListFilterPopup;
