/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AddUpdateBlogBody from "components/BlogComponents/AddUpdateBlogBody/AddUpdateBlogBody";

const AddUpdateBlog = () => {
  useEffect(() => {
    document.title = "Add New Blog";
  }, []);

  return (
    <main id="app">
      <Header moduleName="blogs" />
      <AddUpdateBlogBody />
    </main>
  );
};

export default AddUpdateBlog;
