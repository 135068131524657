/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import time calculation
import { getCurrentTime } from "helper/TimeCalculation";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import fileDownloadHandler from "helper/DownloadFile";
import { assetImages } from "constants";
import { ecosystemSlug } from "Config/Config";
import CurrencyOptions from "data/Prod/Currency.json";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import downloadFileHandler from "helper/DownloadFile";

import { productDetailsHeaderLinks } from "helper/ProductHelper/ProductHelper";
import AfterSaveProductModal from "../Modals/AfterSaveProductModal";

const ProductInfoBody = () => {
  const dateOfToday = new Date().toISOString().split("T")[0];

  const { t } = useTranslation(); //for translation

  const params = useParams();
  const history = useHistory();

  // -------- tabs header object --------------------
  const commonHeaderObject = productDetailsHeaderLinks(params.id, t);

  const breadCrumbText = [
    { title: t("Event"), link: "/admin/events/list" },
    { title: t("Event Info") },
  ];

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type details here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  const imageFileInputRef = useRef(null);

  const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload image

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [productTypeList, setproductTypeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [productTypeValue, setproductTypeValue] = useState(null);
  const [selectedProductTypeId, setselectedProductTypeId] = useState(null);
  const [companyValue, setcompanyValue] = useState(null);
  const [selectedCompanyId, setselectedCompanyId] = useState(null);
  const [name, setname] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [price, setprice] = useState("");
  const [currencyValue, setcurrencyValue] = useState(null);
  const [selectedCurrency, setselectedCurrency] = useState("");
  const [taxPercent, settaxPercent] = useState("");
  const [stock, setstock] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);

  //get all product types
  const getAllProductType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PRODUCT_TYPE +
        `?ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        const productTypeListArr = response.data.map(
          ({ name, _id, ...other }) => ({
            label: name,
            value: _id,
            other,
          })
        );

        setproductTypeList(productTypeListArr);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role.name}`;

      console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in app user list------>", response);

      if (response.status) {
        const companyListArr = response.data.map(({ name, _id, ...other }) => ({
          label: name,
          value: _id,
          other,
        }));
        setCompanyList(companyListArr);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(response.data.path);
        setUploadedImageId(response.data._id);
        resetImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeImageFileHandler = () => {
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetImageFile();
  };

  // function for clear file value
  const resetImageFile = () => {
    const file = document.getElementById("uploadedImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for close afterSaveEventModal
  const afterSaveModalClose = () => {
    history.push("/admin/marketplace/list");
  };

  useEffect(() => {
    getAllProductType();
    getAllCompanyList();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_MARKETPLACE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="marketplace-wrapper bg-white">
          {/* ---- event details common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Vital Info")}
            />
          )}

          <div className="container-fluid px-lg-5 py-4 py-lg-5">
            <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="name"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        <span className="d-block">{t("Product Type")}</span>
                        <span className="d-block material-symbols-outlined icon-md text-gray">
                          info
                        </span>
                      </label>
                      <Select
                        isClearable
                        placeholder={t("Select Product type")}
                        options={productTypeList}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="companyStore"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        <span className="d-block">{t("Company / Store")}</span>
                        <span className="d-block material-symbols-outlined icon-md text-gray">
                          info
                        </span>
                      </label>
                      <Select
                        isClearable
                        placeholder={t("Select Company")}
                        options={companyList}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="summary"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Name")}</span>
                    <span className="d-block material-symbols-outlined icon-md text-gray">
                      info
                    </span>
                  </label>
                  <input
                    type="text"
                    id="companyStore"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Enter Store")}
                  />
                </div>

                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="summary"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Summary")}</span>
                    <span className="d-block material-symbols-outlined icon-md text-gray">
                      info
                    </span>
                  </label>
                  <textarea
                    name="summary"
                    id="summary"
                    cols="30"
                    rows="3"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Event Summary")}
                  ></textarea>
                </div>
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="details"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Details")}</span>
                    <span className="d-block material-symbols-outlined icon-md text-gray">
                      info
                    </span>
                  </label>
                  <textarea
                    name="details"
                    id="details"
                    cols="30"
                    rows="5"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Details")}
                  ></textarea>
                </div>
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="price"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Price")}
                      </label>
                      <input
                        type="text"
                        id="price"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Price")}
                      />
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="currency"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Currency")}
                      </label>

                      <Select
                        isClearable
                        placeholder={t("Select Currency")}
                        options={CurrencyOptions}
                      />
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="vat"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("VAT %")}
                      </label>
                      <input
                        type="text"
                        id="vat"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter VAT")}
                      />
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="stock"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Stock")}
                      </label>
                      <input
                        type="text"
                        id="stock"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Stock")}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="accordion mb-3 mb-sm-4"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item bg-transparent border-0">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        {t("Advance Info")}
                      </button>
                    </h3>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="form-group mb-3 mb-sm-4">
                          <div className="row">
                            <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                              <label
                                htmlFor="displayStartFrom"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Display Start From")}
                              </label>
                              <input
                                type="date"
                                className="form-control fs-sm shadow-none"
                              />
                            </div>
                            <div className="col-lg-6">
                              <label
                                htmlFor="displayEndFrom"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Display Ends On")}
                              </label>
                              <input
                                type="date"
                                className="form-control fs-sm shadow-none"
                              />
                            </div>
                          </div>
                        </div>

                        {/* ------ uploaded image section start ------ */}
                        <div className="form-group">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10 mb-3">
                                  <label
                                    htmlFor="uploadDp"
                                    className="text-nowrap me-3"
                                  >
                                    {t("Upload Product Image")}
                                  </label>
                                  <p className="form-control text-gray-600 border-0 rounded-0 shadow-none">
                                    JPG, PNG, JPEG
                                  </p>
                                  <Link
                                    to="#"
                                    className="upload-file"
                                    data-target="uploadResume"
                                    onClick={openImageFileInput}
                                  >
                                    <span className="d-block material-symbols-outlined icon-lg">
                                      upload
                                    </span>
                                  </Link>
                                  <input
                                    type="file"
                                    data-id="uploadResume"
                                    id="uploadedImageFile"
                                    className="d-none"
                                    ref={imageFileInputRef}
                                    onChange={imageUploadHandler}
                                  />
                                </div>

                                <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
                                  {isUploading ? (
                                    <li>
                                      <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                                        <p className="fw-bold">
                                          {t("Please wait while uploading")}
                                        </p>{" "}
                                        {loadingCircle.map((count, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="spinner-grow spinner-grow-sm"
                                              role="status"
                                              style={{
                                                height: "5px",
                                                width: "5px",
                                              }}
                                            >
                                              <span className="visually-hidden">
                                                Loading...
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </li>
                                  ) : (
                                    <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                                      <Link
                                        to="#"
                                        onClick={closeImageFileHandler}
                                      >
                                        <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                                          delete
                                        </span>
                                      </Link>
                                      <img
                                        src={
                                          uploadedPreviewImage == ""
                                            ? "https://placehold.co/150x150"
                                            : url.SERVER_URL +
                                              uploadedPreviewImage
                                        }
                                        alt="Uploaded"
                                        className="w-100 h-100 object-fit-cover object-center"
                                      />
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ------ uploaded image section end ------ */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3 mb-sm-4">
                  <input
                    type="checkbox"
                    id="agree"
                    className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  />
                  <label className="form-check-label" htmlFor="agree">
                    {t("Agree with the")}{" "}
                    <Link to="#" className="text-primary">
                      {t("Legal Notice")}
                    </Link>{" "}
                    &amp;{" "}
                    <Link to="#" className="text-primary">
                      {t("Privacy Policy")}
                    </Link>
                  </label>
                </div>
                <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                  <Link
                    to="marketplace-product-category.html"
                    className="btn btn-outline-primary"
                  >
                    {t("Cancel")}
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    {t("Save Now")}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* ----- after save event modal ----- */}
          <AfterSaveProductModal afterSaveModalClose={afterSaveModalClose} />
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProductInfoBody;
