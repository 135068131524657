/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";
const CrmContactFilterPopup = ({
  tagList,
  setfilterName,
  setfilterEmail,
  setfilterPhone,
  setfilterPostion,
  setfilterLabels,
  setfilterCompany,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [position, setposition] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [company, setcompany] = useState("");
  const [tags, setTags] = useState([]);
  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;
      console.log("url of company------>", requestUrl);
      const response = await getData(requestUrl);
      console.log("response in company------>", response);
      if (response.status) {
        setCompanyList(
          response.data.map((item) => ({
            label: item.name,
            value: item.name,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setcompany(val.value);
    } else {
      setCompanyValue(null);
      setcompany("");
    }
  };
  // function for set tags
  const tagselectedHandler = (data) => {
    let prevTags = tags;
    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById(
        "contacttagfilter-" + data.value
      );
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "contacttagfilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setTags(prevTags);
  };
  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };
  // reset selected skills
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };
  //function for search
  const searchHandler = () => {
    setfilterName(name);
    setfilterEmail(email);
    setfilterPhone(phone);
    setfilterPostion(position);
    setfilterCompany(company);
    setfilterLabels(tags);
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasCrmContactFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };
  //function for reset
  const resetHandler = () => {
    setname("");
    setemail("");
    setphone("");
    setposition("");
    setcompanyInput("");
    setCompanyValue(null);
    setcompany("");
    setTags([]);
    removeActiveClass();
    resetSelectTags();
  };
  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);
  const companyOptionsToShow = companyList.length > 0 ? companyList : [];
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasCrmContactFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* name */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Name")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Name")}
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>
          {/* email */}
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Email")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Email")}
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          {/* Phone */}
          <div className="form-group mb-4">
            <label htmlFor="Phone" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Phone")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Phone")}
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
          </div>
          {/* Position */}
          <div className="form-group mb-4">
            <label
              htmlFor="Position"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Position")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Position")}
              value={position}
              onChange={(e) => setposition(e.target.value)}
            />
          </div>
          {/* Company */}
          <div className="form-group mb-4">
            <label htmlFor="Company" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Company")}</h3>{" "}
            </label>
            <CreatableSelect
              placeholder={t("Type Company name")}
              isClearable
              options={companyOptionsToShow}
              value={companyValue}
              onChange={(val) => {
                companySelectionHandler(val);
              }}
              onInputChange={(val) => {
                setcompanyInput(val);
              }}
            />
          </div>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* ------ label accordion start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSix"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseSix"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseSix"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {tagList.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagselectedHandler(tag)}
                            id={"contacttagfilter-" + tag.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ------ label accordion end ------ */}
          </div>
          <button
            onClick={searchHandler}
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};
export default CrmContactFilterPopup;