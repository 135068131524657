import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AvailibilityHeader = ({ taskDependOnCalender, changeTaskDependency }) => {
  const { t } = useTranslation(); // for translations
  return (
    <div className="filter-container d-flex flex-wrap align-items-center justify-content-between gap-3 py-3">
      <ul
        className="nav nav-tabs d-inline-flex p-1 body-bg flex-nowrap fs-md lh-1 fw-semibold border-0 text-nowrap rounded-10 overflow-auto"
        id="viewTab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <Link
            to="#"
            className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10 active"
            id="list-view-tab"
            data-bs-toggle="tab"
            data-bs-target="#list-view"
            type="button"
            role="tab"
            aria-controls="list-view"
            aria-selected="true"
          >
            <span className="d-block material-symbols-outlined icon-lg">
              list_alt
            </span>
            <span className="d-block">{t("List View")}</span>
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link
            className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10"
            id="calendar-view-tab"
            data-bs-toggle="tab"
            data-bs-target="#calendar-view"
            type="button"
            role="tab"
            aria-controls="calendar-view"
            aria-selected="false"
            to="#"
          >
            <span className="d-block material-symbols-outlined icon-lg">
              calendar_month
            </span>
            <span className="d-block">{t("Calendar View")}</span>
          </Link>
        </li>
      </ul>
      <form onSubmit={(e) => e.preventDefault()} className="d-flex gap-2">
        <div className="form-group d-flex align-items-center gap-2">
          <div className="form-check">
            <input
              className="form-check-input check-success border-primary"
              type="checkbox"
              // name={previlegeData.name}
              // id={previlegeData.slug}
              checked={taskDependOnCalender}
              onChange={(e) => changeTaskDependency(e)}
            />
          </div>
          <span className="d-block fs-sm text-nowrap fw-semibold">
            {t("Assign Task Depend on My Calendar")}
          </span>
        </div>
        {/* <div className="action">
          <button
            className="btn btn-primary ms-auto"
            data-bs-toggle="modal"
            data-bs-target="#scheduleWarning"
          >
            Add Setting to My Calender
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default AvailibilityHeader;
