/* eslint-disable */
import React, { useState } from 'react';
import Select from 'react-select';

const AddProcessPopup = () => {
  // select data --------------------------
  const options = [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasProcess"
      aria-labelledby="offcanvasProcessLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasProcessLabel">
          Process
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="" className="d-flex flex-column h-100">
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label htmlFor="name" className="d-block fs-sm fw-semibold mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-control fs-sm shadow-none"
                //value="Fases"
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="slug" className="d-block fs-sm fw-semibold mb-2">
                Slug
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                //value="Month"
              />
            </div>
            <div className="form-group mb-4">
              <label
                htmlFor="addTagsToProcess"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Add tags to process
              </label>
              <Select
                placeholder="Select options"
                options={options}
                //value={selectOptions}
                onChange={val => {
                  setSelectOptions(val);
                }}
              />
            </div>
            <div className="form-group mb-4">
              <label
                htmlFor="associateModulesToProcess"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Associate modules to process
              </label>
              <Select
                placeholder="Select options"
                options={options}
                //value={selectOptions}
                onChange={val => {
                  setSelectOptions(val);
                }}
              />
            </div>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProcessPopup;
