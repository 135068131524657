/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

import clipboardCopy from "clipboard-copy";

const LinkedinHelperModal = () => {
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const copyMessage =
    url.API_BASE_URL + url.API_LINKEDIN_HELPER + `/${userInfo._id}`;
  return (
    <div
      className="modal fade"
      id="linkedinHelperModal"
      tabIndex="-1"
      aria-labelledby="linkedinHelperModal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="linkedinHelperModal">
              {t("Import from Linkedin")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label
                  htmlFor="selectList"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Webhook Url")}
                </label>
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="text"
                    className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                    value={copyMessage}
                    readOnly
                  />
                  <Link
                    className="d-flex align-items-center justify-content-center"
                    to="#"
                    onClick={() => {
                      clipboardCopy(copyMessage);
                    }}
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      content_copy
                    </span>
                  </Link>
                </div>
              </div>
              <div className="action d-flex align-items-center gap-2">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("Close")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    clipboardCopy(copyMessage);
                  }}
                >
                  {t("Copy Url")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedinHelperModal;
