import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import ProjectKPIData from '../../../../../data/ProjectManagement/ProjectKPIData.json';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import { useParams } from 'react-router-dom';
import ProjectKPIHeader from '../ProjectKPIHeader/ProjectKPIHeader';

const ProjectKPIBody = () => {
  const params = useParams();

  // -------- tabs header object --------------------
  const commonHeaderObject = [
    {
      title: 'Details',
      link: `/admin/projectmanagement/details/${params.id}`,
    },
    { title: 'Members', link: `/admin/projectmanagement/members/${params.id}` },
    { title: 'Tasks', link: `/admin/projectmanagement/tasks/${params.id}` },
    { title: 'Logs', link: `/admin/projectmanagement/logs/${params.id}` },
    { title: 'ODS', link: `/admin/projectmanagement/ods/${params.id}` },
    {
      title: 'Investments',
      link: `/admin/projectmanagement/investment/${params.id}`,
    },
    { title: 'KPIs', link: `/admin/projectmanagement/kpis/${params.id}` },
    { title: 'Report', link: `/admin/projectmanagement/report/${params.id}` },
  ];

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: 'Projects', link: '/admin/projectmanagement/list' },
    { title: 'KPIs' },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'slKpi',
        header: 'SL KPIs',
        Cell: ({ row }) => (
          <td className="sl-kpis border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div>
                <p className="fs-md fw-semibold">{row.original.slKpi}</p>
              </div>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'date',
        header: 'Date',
        Cell: ({ row }) => (
          <td className="date border-bottom-0">
            <p>{row.original.date}</p>
          </td>
        ),
      },
      {
        accessorKey: 'value',
        header: '	Value',
        Cell: ({ row }) => (
          <td className="value border-bottom-0">
            <p>{row.original.value}</p>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ------ common header -------- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption="KPIs"
          />
        )}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ------- bread crumb -------- */}
          <BreadCrumb breadCrumbText={breadCrumbText} bottom={true} />

          {/* ------- filter area --------- */}
          <ProjectKPIHeader />

          {/* ------ table area ------- */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={ProjectKPIData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectKPIBody;
