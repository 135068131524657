import React from "react";

import Header from "components/Common/Header/Header";
import SentMailList from "components/CRMComponents/CRMContactDetailsComponents/CRMContactDetailsMailComponents/SentMail/SentMailList/SentMailList";

const CrmContactDetailsSentMail = () => {
  return (
    <main id="app">
      <Header moduleName="crm" />

      <SentMailList />
    </main>
  );
};

export default CrmContactDetailsSentMail;
