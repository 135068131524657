/* eslint-disable */
import React, { useEffect } from 'react';
import Header from 'components/Common/Header/Header';
import CrmContactListBody from 'components/CRMComponents/CRMContactListComponents/CRMContactListBody/CrmContactListBody';

const CrmContactList = () => {
  useEffect(() => {
    document.title = 'CRM Contact List';
  }, []);
  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmContactListBody />
    </main>
  );
};

export default CrmContactList;
