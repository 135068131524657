import { useState } from "react";
import { assetImages } from "constants";
import { Link } from "react-router-dom";
import * as url from "helper/UrlHelper";

const SurveyLeftPanel = ({
  surveyInviteDetailsLoading,
  responseListLoading,
  showEmptyMessage,
  surveyResponseList,
  surveyInviteeImage,
  surveyInviteeName,
  surveyInviteeMemberFrom,
  totalNoOfSurveyInvites,
  totalNoOfGivenSurveys,
}) => {
  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  return (
    <div className="left_survey">
      {/* <!-- ========== Start survey invitee information Section ========== --> */}
      <div className="survey_topbx p-4">
        {/* <!-- ========== Start search Section ========== --> */}
        <div className="survey_srch_row d-flex align-items-center mb-4 gap-3">
          <div className="survey_srch w-100">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
        {/* <!-- ========== End search Section ========== --> */}

        {/* <!-- ========== Start search and survey invitee information Section ========== --> */}
        <div className="survey_profile_row">
          <div className="survey_profile">
            {/* <!-- ========== Start invitee image Section ========== --> */}
            <div className="survey_pic overflow-hidden rounded-circle mb-3">
              {surveyInviteDetailsLoading ? (
                <span
                  className="placeholder d-block rounded-circle"
                  style={{ width: "78px", height: "78px" }}
                ></span>
              ) : (
                <img
                  src={
                    surveyInviteeImage === ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + surveyInviteeImage
                  }
                  className="h-100 w-100 object-fit-cover"
                  alt=""
                />
              )}
            </div>
            {/* <!-- ========== End invitee image Section ========== --> */}

            {/* <!-- ========== Start invitee name and registration date Section ========== --> */}
            {surveyInviteDetailsLoading ? (
              <div className="survey_info text-center">
                <span
                  className="placeholder d-table mx-auto mb-2"
                  style={{ width: "300px", maxWidth: "90%", height: "20px" }}
                ></span>
                <span
                  className="placeholder d-table mx-auto"
                  style={{ width: "25%", minHeight: "unset", height: "10px" }}
                ></span>
              </div>
            ) : (
              <div className="survey_info text-center">
                <h3 className="fs-xl text-black mb-2">{surveyInviteeName}</h3>
                <h5 className="text-black-gray fs-xs">
                  Member from {surveyInviteeMemberFrom}
                </h5>
              </div>
            )}
            {/* <!-- ========== End invitee name and registration date Section ========== --> */}

            {/* <!-- ========== Start invitee review Section ========== --> */}
            {surveyInviteDetailsLoading ? (
              <div className="survey_progress mt-2">
                <div className="d-flex justify-content-between gap-2 mb-2">
                  <span
                    className="placeholder col-2"
                    style={{ minHeight: "unset", height: "10px" }}
                  ></span>
                  <span
                    className="placeholder col-2"
                    style={{ minHeight: "unset", height: "10px" }}
                  ></span>
                </div>
                <span
                  className="placeholder d-block w-100"
                  style={{ minHeight: "unset", height: "5px" }}
                ></span>
              </div>
            ) : (
              <div className="survey_progress mt-2">
                <div className="progress_text_row d-flex align-items-center justify-content-between mb-2">
                  <h6 className="fs-xs fw-medium text-black-gray">
                    Reviews {totalNoOfGivenSurveys}/{totalNoOfSurveyInvites}
                  </h6>
                  {/* <h4 className="fw-medium fs-sm text text-black-gray">
                  500 Points
                </h4> */}
                </div>
                <div className="survey_progress_outer position-relative">
                  <span
                    className="s_progress_bar"
                    style={{ width: "30%", backgroundColor: "#DD6E2F" }}
                  ></span>
                </div>
              </div>
            )}
            {/* <!-- ========== End invitee review Section ========== --> */}
          </div>
        </div>
        {/* <!-- ========== End survey invitee information Section ========== --> */}
      </div>
      {/* <!-- ========== End search and survey invitee information Section ========== --> */}

      {/* <!-- ========== Start survey feedback list of invitee Section ========== --> */}
      <div className="survey_bttmbx p-4">
        {surveyInviteDetailsLoading || responseListLoading ? (
          <div className="survey_revw_innr">
            <div className="survey_rvw_item mb-4">
              <div className="top_rvw_info d-flex align-items-center justify-content-between mb-2">
                <div className="left_survey_info ">
                  <span
                    className="placeholder d-block mb-2"
                    style={{ width: "200px", maxWidth: "90%", height: "16px" }}
                  ></span>
                  <span
                    className="placeholder d-block"
                    style={{
                      width: "100px",
                      maxWidth: "90%",
                      minHeight: "unset",
                      height: "8px",
                    }}
                  ></span>
                </div>
                <div className="right_info user_right">
                  <ul className="list_stye_none">
                    <li className="dropdown">
                      <span
                        className="placeholder d-block"
                        style={{ width: "10px", height: "20px" }}
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bttm_rvw_bx bg-white p-3 rounded-16 mb-2">
                <div className="text mb-3">
                  <span
                    className="placeholder d-block col-12 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-10 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-8 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-6 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-4 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-3 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-2 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-10 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-12 "
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                </div>
              </div>
              <div className="bttm_rvw_bx bg-white p-3 rounded-16">
                <div className="text mb-3">
                  <span
                    className="placeholder d-block col-12 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-10 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-8 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-6 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-4 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-3 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-2 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-10 mb-2"
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                  <span
                    className="placeholder d-block col-12 "
                    style={{ minHeight: "unset", height: "8px" }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="survey_revw_innr">
            {showEmptyMessage ? (
              <div className="blank_graphics_info text-center">
                <div className="blank_graphics_pic mb-3">
                  <img src={assetImages.leftBlank} alt="" />
                </div>
                <div className="blank_graphics_text">
                  <h4 className="text-black fs-lg mb-3">No survey done yet</h4>
                  <p className="fs-xs text-black-gray">
                    It's been long waiting for your first points
                  </p>
                </div>
              </div>
            ) : (
              surveyResponseList.map((responseData, index) => {
                return (
                  <div
                    className={
                      responseData.surveytitle
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                        ? "survey_rvw_item mb-4"
                        : "d-none"
                    }
                    key={index}
                  >
                    <div className="top_rvw_info d-flex align-items-center justify-content-between mb-2">
                      <div className="left_survey_info ">
                        <h4 className="fs-lg fw-semibold text-black mb-1">
                          {responseData.surveytitle}
                          <span className="d-block text-gray fs-sm fw-regular">
                            {responseData.responsetime}
                          </span>
                        </h4>
                      </div>
                      <div className="right_info user_right">
                        <ul className="list_stye_none">
                          <li className="dropdown">
                            <Link to="#" data-bs-toggle="dropdown">
                              <span className="material-symbols-outlined text-black-gray fs-lg">
                                more_vert{" "}
                              </span>
                            </Link>
                            <ul className="list_stye_none dropdown-menu px-3 py-1">
                              <li>
                                <Link
                                  to={`/admin/survey/invite/${responseData._id}`}
                                >
                                  <i className="material-symbols-outlined">
                                    restore_page
                                  </i>
                                  <span>View Survey</span>
                                </Link>
                              </li>
                              {/* <li>
                                <Link to="#">
                                  <i className="material-symbols-outlined">
                                    delete
                                  </i>
                                  <span>Delete</span>
                                </Link>
                              </li> */}
                              {/* <li>
                                <Link to="#">
                                  <i className="material-symbols-outlined">
                                    share
                                  </i>
                                  <span>Share</span>
                                </Link>
                              </li> */}
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="bttm_rvw_bx bg-white p-3 rounded-16">
                      {responseData.responselist.map(
                        (responseAnswer, index2) => {
                          const starCount = [1, 2, 3, 4, 5];

                          /**
                           * check whether question type is rating.
                           * If yes then display the start rating block
                           */
                          if (responseAnswer.questiontypeslug === "RATING") {
                            return (
                              <div className="survey-rating mb-2" key={index2}>
                                <p className="text-gray  text-black fw-semibold mb-2">
                                  {responseAnswer.surveyquestionname}
                                </p>

                                <ul className="list_stye_none d-flex align-items-center">
                                  {starCount.map((count, index) => {
                                    return (
                                      <li key={index}>
                                        <i
                                          className={`material-symbols-outlined  ${
                                            index <
                                            parseInt(
                                              responseAnswer.surveyanswer
                                            )
                                              ? "text-primary icon-fill"
                                              : "text-gray"
                                          }  `}
                                        >
                                          star
                                        </i>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          } else {
                            return (
                              <p className="text-gray  text-black fw-semibold mb-2">
                                {responseAnswer.surveyquestionname}
                                <span className="d-block text-gray fw-medium">
                                  {responseAnswer.surveyanswer}
                                </span>
                              </p>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
      {/* <!-- ========== End survey feedback list of invitee Section ========== --> */}
    </div>
  );
};

export default SurveyLeftPanel;
