import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EducationAccordion = ({
  educationListBlock = [],
  updateEductionBlock,
  onDeleteEducationBlock,
  setEducationIndex,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      {/* ------ toggle accordion button start ------ */}
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseThree"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseThree"
        >
          {t("Education")}
        </button>
      </h3>
      {/* ------ toggle accordion button end ------ */}
      {/* ----- eduction block list section start ----- */}
      <div
        id="panelsStayOpen-collapseThree"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          <div className="fields-group">
            {/* ------ education list section start ------ */}
            <div className="fields d-flex flex-column gap-3">
              {educationListBlock.map((education, index) => {
                return (
                  <div className="field" key={index}>
                    <div className="form-group">
                      <div className="row">
                        {/* ------ degree section start ------ */}
                        <div className="col-lg-3 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("Degree")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {education.degree}
                            </p>
                          </div>
                        </div>
                        {/* ------ degree section end ------ */}
                        {/* ------ year section start ------ */}
                        <div className="col-lg-2 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("Year")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {education.year}
                            </p>
                          </div>
                        </div>
                        {/* ------ year section end ------ */}
                        {/* ------ grade section start ------ */}
                        <div className="col-lg-2 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("Grade")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {education.grade}
                            </p>
                          </div>
                        </div>
                        {/* ------ grade section end ------ */}
                        {/* ------ institute section start ------ */}
                        <div className="col-lg-4">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("Institute")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {education.institute}
                            </p>
                          </div>
                        </div>
                        {/* ------ institute section end ------ */}
                        {/* ------ edit and delete icon section start ------ */}
                        <div className="col-lg-1 action d-flex align-items-center justify-content-end gap-3 mb-3">
                          {/* ------ edit icon start ------ */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#addEducationModal"
                            onClick={() => {
                              updateEductionBlock(education, index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              edit
                            </span>
                          </Link>
                          {/* ------ edit icon end ------ */}
                          {/* ------ delete icon start ------ */}
                          <Link
                            to="#"
                            onClick={() => {
                              onDeleteEducationBlock(index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          {/* ------ delete icon end ------ */}
                        </div>
                        {/* ------ edit and delete icon section end ------ */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* ------ education list section end ------ */}
            {/* ------ add education modal button start ------ */}
            <Link
              to="#"
              className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
              data-bs-toggle="modal"
              data-bs-target="#addEducationModal"
              onClick={() => {
                setEducationIndex(null);
              }}
            >
              <span className="d-block material-symbols-outlined">add</span>
              <span className="d-block">{t("Add Education")}</span>
            </Link>
            {/* ------ add education modal button end ------ */}
          </div>
        </div>
      </div>
      {/* ----- eduction block list section end ----- */}
    </div>
  );
};

export default EducationAccordion;
