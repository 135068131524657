import Header from 'components/Common/Header/Header';
import GroupProductListBody from 'components/GroupComponents/GroupDetails/GroupProduct/GroupProductList/GroupProductListBody/GroupProductListBody';
import React from 'react';

const GroupProductList = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ---------- body section ------------- */}
      <GroupProductListBody />
    </>
  );
};

export default GroupProductList;
