/* eslint-disable */
import React from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const OpportunityHeader = ({
  userInfo,
  reloadList,

  isAuthenticatedUser,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Opportunity") }];

  const params = useParams();

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 mb-3 mb-md-0">
          {/* --- breadcrumb start --- */}
          <BreadCrumb breadCrumbText={breadcrumbText} />
          {/* --- breadcrumb end --- */}
        </div>
        <div className="col-md-6 d-flex justify-content-end gap-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>

          {/* <!-- ========== Start view switch Section ========== --> */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("List View")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/lead/opportunities/${params.id}`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("List View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/crm/lead/opportunity/kanban/${params.id}`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    view_kanban
                  </span>
                  <span className="d-block">{t("Kanban View")}</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/projectmanagement/gantt/`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    insert_chart_outlined
                  </span>
                  <span className="d-block">Gantt View</span>
                </Link>
              </li> */}
            </ul>
          </div>
          {/* <!-- ========== End view switch Section ========== --> */}

          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start dropdown-toggle w-100"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addFollowers"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    person
                  </span>
                  <span className="d-block">{t("Add Followers")}</span>
                </Link>
              </li>{" "}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasInvoiceListFilter"
            aria-controls="offcanvasInvoiceListFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          {isAuthenticatedUser ? (
            <Link
              to={`/admin/crm/lead/opportunity/save/${params.id}`}
              // data-bs-toggle="offcanvas"
              // data-bs-target="#saveInvoice"
              aria-controls="saveInvoice"
              className="btn btn-primary d-flex align-items-center gap-1"
            >
              <span className="d-block material-symbols-outlined icon-md">
                add
              </span>
              <span className="d-block">{t("Add New")}</span>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OpportunityHeader;
