import React from "react";
import Header from "components/Common/Header/Header";
import ChallengeResponseKanbanBody from "components/ChallengeComponents/ChallengeDetailsComponents/ChallengeResponse/Kanban/ChallengeResponseKanbanBody";

const ChallengeResponseKanban = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="challenges" />

      {/* ---------- body section ------------- */}

      <ChallengeResponseKanbanBody />
    </main>
  );
};

export default ChallengeResponseKanban;
