import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DashboardHeader = ({ setRefreshData }) => {
  const { t } = useTranslation(); //for translation

  const ecosystemSlug = localStorage.getItem("ecosystemslug");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  console.log("ecosystemSlug", ecosystemSlug);
  console.log("userInfo role slug", userInfo.role.slug);

  return (
    <div className="tab-links-container bg-white py-4 shadow-sm">
      <div className="container-fluid px-lg-5">
        {/* <!-- ========== Start header title Section ========== --> */}
        <h1 className="h2 mb-3">{t("Dashboard")}</h1>
        {/* <!-- ========== End header title Section ========== --> */}

        {/* <!-- ========== Start list of tab links section ========== --> */}
        <div className="d-flex align-items-end align-items-md-center justify-content-between flex-column flex-md-row gap-3">
          <ul
            className="nav nav-tabs mw-100 pb-3 pb-md-0 flex-nowrap gap-5 flex-fill fs-sm fw-semibold border-0 text-nowrap overflow-auto"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <Link
                to="#"
                className="text-black active"
                id="activity-tab"
                data-bs-toggle="tab"
                data-bs-target="#activity"
                type="button"
                role="tab"
                aria-controls="activity"
                aria-selected="true"
              >
                {t("Activity")}
              </Link>
            </li>

            {ecosystemSlug === "MUSAMERDA" &&
            (userInfo.email === "marketing@elpavonteatro.es" ||
              userInfo.role.slug === "ADMIN" ||
              userInfo.role.slug === "SUPER_ADMIN") ? (
              <>
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="avg-sales-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#avg-sales"
                    type="button"
                    role="tab"
                    aria-controls="avg-sales"
                    aria-selected="false"
                  >
                    Average Sales
                  </Link>
                </li>

                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="tickets-year-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tickets-year"
                    type="button"
                    role="tab"
                    aria-controls="tickets-year"
                    aria-selected="false"
                  >
                    Tickets sold (year / month)
                  </Link>
                </li>

                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="records-channel-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#records-channel"
                    type="button"
                    role="tab"
                    aria-controls="records-channel"
                    aria-selected="false"
                  >
                    Sales by Channel
                  </Link>
                </li>

                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="tickets-month-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tickets-month"
                    type="button"
                    role="tab"
                    aria-controls="tickets-month"
                    aria-selected="false"
                  >
                    Tickets sold (month / year)
                  </Link>
                </li>

                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="tickets-days-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#tickets-days"
                    type="button"
                    role="tab"
                    aria-controls="tickets-days"
                    aria-selected="false"
                  >
                    Tickets sold (days of week)
                  </Link>
                </li>

                {/* BY GENDER */}
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="ticket-gender-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ticket-gender"
                    type="button"
                    role="tab"
                    aria-controls="ticket-gender"
                    aria-selected="false"
                  >
                    By Gender
                  </Link>
                </li>

                {/* BY PRICE */}
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="ticket-price-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ticket-price"
                    type="button"
                    role="tab"
                    aria-controls="ticket-price"
                    aria-selected="false"
                  >
                    By Price
                  </Link>
                </li>

                {/* BY Promotion */}
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="ticket-promotion-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ticket-promotion"
                    type="button"
                    role="tab"
                    aria-controls="ticket-promotion"
                    aria-selected="false"
                  >
                    By Promotion
                  </Link>
                </li>

                {/* BY Sector */}
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="ticket-sector-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ticket-sector"
                    type="button"
                    role="tab"
                    aria-controls="ticket-sector"
                    aria-selected="false"
                  >
                    By Sector
                  </Link>
                </li>

                {/* BY Bill */}
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="ticket-bill-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ticket-bill"
                    type="button"
                    role="tab"
                    aria-controls="ticket-bill"
                    aria-selected="false"
                  >
                    By Bill
                  </Link>
                </li>

                {/* BY Bill */}
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="ticket-work-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ticket-work"
                    type="button"
                    role="tab"
                    aria-controls="ticket-work"
                    aria-selected="false"
                  >
                    By Work
                  </Link>
                </li>

                {/* best buyer */}
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="text-black"
                    id="best-buyer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#best-buyer"
                    type="button"
                    role="tab"
                    aria-controls="best-buyer"
                    aria-selected="false"
                  >
                    Best Buyer
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
          <Link
            to="#"
            onClick={() => {
              setRefreshData(true);
            }}
            className="body-bg d-flex align-items-center gap-1 px-3 py-2 fs-sm fw-semibold text-black rounded-10"
            type="button"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <Link
            to="#"
            className="body-bg d-flex align-items-center gap-1 px-3 py-2 fs-sm fw-semibold text-black rounded-10"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDashboardFilter"
            aria-controls="offcanvasFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
        </div>
        {/* <!-- ========== End list of tab links section ========== --> */}
      </div>
    </div>
  );
};

export default DashboardHeader;
