/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
import InvoiceListHeader from './InvoiceListHeader';
import { Link } from 'react-router-dom';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';

//import current date
import { getCurrentDateInString } from 'helper/getcurrentdatestring';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import images
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';
//import AddToListModal from "components/Common/Modal/AddToListModal";

const InvoiceListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation
  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [filterCompanyName, setFilterCompanyName] = useState('');
  const [filterCompanyTypes, setFilterCompanyTypes] = useState([]);
  const [filterCompanyTags, setFilterCompanyTags] = useState([]);

  // on
  const onSaveInvoiceClose = () => {
    setShowOffcanvas(false);
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getInvoiceList = async () => {
    setRowSelection({});
    setSelectedInvoiceIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_MY_INVOICES + `?token=${token}`;

      //   if (filterCompanyName != "") {
      //     requestUrl = requestUrl + `&filtername=${filterCompanyName}`;
      //   }

      //   if (filterCompanyTypes.length > 0) {
      //     requestUrl = requestUrl + `&filtertypes=${filterCompanyTypes}`;
      //   }

      //   if (filterCompanyTags.length > 0) {
      //     requestUrl = requestUrl + `&filterlabels=${filterCompanyTags}`;
      //   }

      console.log('request url------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response ------>', response);

      setIsLoading(false);

      if (response.status) {
        setInvoiceList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedInvoiceIds) {
      try {
        let statusData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_INVOICE +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getInvoiceList();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCompanyName('');
    setFilterCompanyTypes([]);
    setFilterCompanyTags([]);
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'clientfullname',
        header: t('Customer'),
        Cell: ({ row }) => (
          <div className="client border-bottom-0 d-flex gap-1">
            <img
              className="rounded-circle"
              height={30}
              width={30}
              src={
                row.original.clientimage === ''
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.clientimage
              }
              alt=""
            />
            <p>
              <span className="d-block">{row.original.clientfullname}</span>
              <span className="d-block">{row.original.clientemail}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'ownername',
        header: t('Created By'),
        Cell: ({ row }) =>
          row.original.user ? (
            <div className="owner border-bottom-0 d-flex gap-1">
              <img
                className="rounded-circle"
                height={30}
                width={30}
                src={
                  row.original.ownerimage === ''
                    ? assetImages.defaultUser
                    : url.SERVER_URL + row.original.user.photoimage.path
                }
                alt=""
              />
              <p>
                <span className="d-block">{row.original.ownername}</span>
                <span className="d-block">{row.original.owneremail}</span>
              </p>
            </div>
          ) : (
            'System Generate'
          ),
      },
      {
        accessorKey: 'invoiceitems',
        header: t('Invoice Items'),
        Cell: ({ row }) => <>{row.original.invoiceitems?.length}</>,
      },
      {
        accessorKey: 'plan',
        header: t('Plan'),
        Cell: ({ row }) =>
          row.original.plan ? (
            <div className="plan border-bottom-0">
              <p className="d-flex align-items-center gap-1">
                {row.original.plan.title}
                <br />
                {row.original.plan.duration} days
              </p>
            </div>
          ) : (
            ' X '
          ),
      },
      {
        accessorKey: 'amount',
        header: t('Amount'),
      },
      {
        accessorKey: 'createdAt',
        header: t('Date'),
        Cell: ({ row }) =>
          row.original.createdAt
            ? getCurrentDateInString(row.original.createdAt, 'dd/mm/yyyy')
            : '',
      },
      {
        accessorKey: 'Payment',
        header: t('Payment'),
        Cell: ({ row }) =>
          row.original.payment ? (
            <div className="payment border-bottom-0">
              <p className="d-flex align-items-center gap-1">DONE</p>
            </div>
          ) : (
            ''
          ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInvoiceIds(selectedIdsArray);
    } else {
      setSelectedInvoiceIds([]);
    }
  }, [rowSelection]);

  //   useEffect(() => {
  //     if (
  //       filterCompanyName != "" ||
  //       filterCompanyTypes.length > 0 ||
  //       filterCompanyTags.length > 0
  //     ) {
  //       getAllCompanyList();
  //     }
  //   }, [filterCompanyName, filterCompanyTypes, filterCompanyTags]);

  useEffect(() => {
    getInvoiceList();
    getAllTags();
  }, []);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_INVOICE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <InvoiceListHeader reloadList={getInvoiceList} />

            {/* --------- table area --------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={invoiceList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => {
                    return [
                      <MenuItem key="edit">
                        <Link
                          to={`/admin/invoice/details/${row.id}`}
                          // onClick={()=>{
                          //   console.log(row);
                          // }}
                        >
                          {t('Details')}
                        </Link>
                      </MenuItem>,

                      <MenuItem
                        key="members"
                        //onClick={() => console.info(row.id)}
                      >
                        <Link to="#">{t('Send Mail')}</Link>
                      </MenuItem>,
                    ];
                  }} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area ------------- */}
        {/* -------- add label modal ------- */}
        <AddLabelModal
          selectedIds={selectedInvoiceIds}
          moduleName="invoice"
          afterTagModalClose={getInvoiceList}
        />

        {/* ------- filter popup ------- */}
        {/* <CompanyListFilterPopup
        setFilterCompanyName={setFilterCompanyName}
        setFilterCompanyTypes={setFilterCompanyTypes}
        setFilterCompanyTags={setFilterCompanyTags}
        tagList={tagList}
        companyTypeList={companyTypeList}
      /> */}

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedInvoiceIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('invoice')}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default InvoiceListBody;
