import Header from 'components/Common/Header/Header';
import ProcessListBody from 'components/ProcessComponents/ProcessListBody/ProcessListBody';
import React, { useEffect } from 'react';

const ProcessList = () => {
  useEffect(() => {
    document.title = 'Process';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="process" />

      {/* ---- body section ---- */}
      <ProcessListBody />
    </main>
  );
};

export default ProcessList;
