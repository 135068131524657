/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import { ecosystemSlug } from "Config/Config";
import { useTranslation } from "react-i18next";

const SaveHiringProcessPopup = ({ processId, setProcessId, onModalClose }) => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  const params = useParams();
  const { t } = useTranslation(); //for translation
  const [allSkills, setAllSkills] = useState([]);

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [order, setOrder] = useState(1);
  const [startDate, setStartDate] = useState(todayValue);
  const [endDate, setEndDate] = useState(todayValue);

  //template for kpis block
  const kpisBlockTemplate = {
    skillValue: null,
    selectedSkill: null,
    point: "",
  };

  const [kpisBlock, setKpisBlock] = useState([]);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    locationWarning: false,
    dateDiffWarning: false,
    kpisBlockWarning: false,
    skillWarning: false,
    pointWarning: false,
  });

  const [isSaving, setIsSaving] = useState(false);

  //function for get all category
  const getAllSkills = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setAllSkills(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //change form inputs values
  const kpisFormInputChangeHandler = (e) => {
    switch (e.target.name) {
      case "title":
        setTitle(e.target.value);
        setValidation((prevState) => ({
          ...prevState,
          titleWarning: false,
        }));
        break;
      case "location":
        setLocation(e.target.value);
        setValidation((prevState) => ({
          ...prevState,
          locationWarning: false,
        }));
        break;
      case "order":
        setOrder(e.target.value);
        break;
      case "startdate":
        setStartDate(e.target.value);
        setValidation((prevState) => ({
          ...prevState,
          dateDiffWarning: false,
        }));
        break;
      case "enddate":
        setEndDate(e.target.value);
        setValidation((prevState) => ({
          ...prevState,
          dateDiffWarning: false,
        }));
        break;
      default:
        console.log("Input section not found");
        break;
    }
  };

  //add kpis block
  const addNewKpisBlock = () => {
    setKpisBlock([...kpisBlock, kpisBlockTemplate]);
  };

  //function for delete action link block
  const onDeleteKpisBlock = (index) => {
    const filteredKpisBlock = [...kpisBlock];
    filteredKpisBlock.splice(index, 1);
    setKpisBlock(filteredKpisBlock);
  };

  //select skillpoint
  const selectKpisSkills = (index, field, field2, value) => {
    const updatedFormValues = kpisBlock.map((block, i) =>
      index === i
        ? value
          ? Object.assign(block, { [field]: value, [field2]: value.value })
          : Object.assign(block, { [field]: null, [field2]: null })
        : block
    );
    setKpisBlock(updatedFormValues);
    setValidation((prevState) => ({
      ...prevState,
      kpisBlockWarning: false,
      skillWarning: false,
      pointWarning: false,
    }));
  };

  //change points
  const changeSkillPointInputs = (index, field, value) => {
    const updatedFormValues = kpisBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    setKpisBlock(updatedFormValues);
    setValidation((prevState) => ({
      ...prevState,
      kpisBlockWarning: false,
      skillWarning: false,
      pointWarning: false,
    }));
  };

  //update kpis block from details
  const updatedKpisBlock = (kpisBlockArray) => {
    setKpisBlock(
      kpisBlockArray.map((kpis) => ({
        _id: kpis?._id,
        skillValue: kpis?.skillpointvalue,
        selectedSkill: kpis?.skillpointvalue?.value,
        point: kpis?.points,
      }))
    );
  };

  //validation
  const validate = () => {
    let isValid = true;
    const validationUpdate = {};

    if (title === "") {
      validationUpdate.titleWarning = true;
      isValid = false;
    }

    if (location === "") {
      validationUpdate.locationWarning = true;
      isValid = false;
    }

    if (startDate !== "" && endDate !== "" && startDate > endDate) {
      validationUpdate.dateDiffWarning = true;
      isValid = false;
    }

    // if (kpisBlock.length > 0) {
    //   for (const block of kpisBlock) {
    //     if (block.skillValue === null || block.point === "") {
    //       validationUpdate.skillWarning = block.skillValue === null;
    //       validationUpdate.pointWarning = block.point === "";
    //       isValid = false;
    //       break; // exit loop early if any block is invalid
    //     }
    //   }
    // } else {
    //   validationUpdate.kpisBlockWarning = true;
    //   isValid = false;
    // }

    setValidation((prevState) => ({ ...prevState, ...validationUpdate }));
    return isValid;
  };

  //get process details
  const getProcessDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_JOB_PROCESS_DETAILS +
        `/${processId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setTitle(data.title || "");
        setLocation(data.location || "");
        setOrder(data.order || 1);
        setStartDate(data.startdate);
        setEndDate(data.enddate);

        updatedKpisBlock(data.kpisarr);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save process
  const saveProcessHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        let processFormData = {
          challengeid: params.id,
          title,
          location,
          order: order || 1,
          startdate: startDate,
          enddate: endDate,
          kpisblock: kpisBlock || [],
        };

        console.log(processFormData);

        let requestUrl = url.API_BASE_URL;
        let response = null;

        if (processId) {
          requestUrl +=
            url.API_UPDATE_JOB_PROCESS + `/${processId}?token=${token}`;

          response = await putData(requestUrl, processFormData);
        } else {
          requestUrl += url.API_ADD_NEW_JOB_PROCESS + `?token=${token}`;

          response = await postData(requestUrl, processFormData);
        }

        setIsSaving(false);
        resetHandler();

        console.log(response);

        if (response.status) {
          let offCanvasPopup = document.querySelector(
            "#offcanvasHiringProcess"
          );
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
          onModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setTitle("");
    setLocation("");
    setOrder(1);
    setStartDate(todayValue);
    setEndDate(todayValue);
    setKpisBlock([]);

    setValidation({
      titleWarning: false,
      locationWarning: false,
      dateDiffWarning: false,
      kpisBlockWarning: false,
      skillWarning: false,
      pointWarning: false,
    });
    setProcessId(null);
  };

  useEffect(() => {
    if (processId) {
      getProcessDetails();
    }
  }, [processId]);

  useEffect(() => {
    getAllSkills();
  }, []);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasHiringProcess"
      aria-labelledby="offcanvasHiringProcess"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-4">
            <label
              htmlFor="projectName"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Process Name")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control fs-sm shadow-none"
              placeholder={t("Process title")}
              value={title}
              onChange={kpisFormInputChangeHandler}
            />
            {/* warning */}
            {validation.titleWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter title")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <label
              htmlFor="projectName"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Preferred Location")}
            </label>
            <input
              type="text"
              name="location"
              className="form-control fs-sm shadow-none"
              placeholder={t("Preferred Location")}
              value={location}
              onChange={kpisFormInputChangeHandler}
            />
            {/* warning */}
            {validation.locationWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter location")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <label
              htmlFor="projectName"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Order")}
            </label>
            <input
              type="number"
              name="order"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter order")}
              value={order}
              onChange={kpisFormInputChangeHandler}
            />
          </div>

          <div className="form-group mb-4">
            <label htmlFor="date" className="d-block fs-sm fw-semibold mb-2">
              {t("Dates")}
            </label>
            <div className="field-container d-flex flex-column flex-lg-row gap-4 gap-lg-0 body-lg-bg rounded-10">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("From")}
                name="startdate"
                value={startDate}
                onChange={kpisFormInputChangeHandler}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("To")}
                name="enddate"
                value={endDate}
                onChange={kpisFormInputChangeHandler}
              />
            </div>
            {/* warning */}
            {validation.dateDiffWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>
                    {t("End date should not be less than start date")}!
                  </span>
                </p>
              </div>
            )}
          </div>

          {kpisBlock.map((kpisData, index) => {
            return (
              <div
                className="form-group p-3 bg-gray-500 mb-2 action_link_block_bg"
                key={index}
              >
                {/* ------ skill point link close button start ------ */}
                <div className="d-flex align-items-center justify-content-end">
                  <Link
                    to="#"
                    className="text-secondary"
                    onClick={() => {
                      onDeleteKpisBlock(index);
                    }}
                  >
                    <span className="material-symbols-outlined">close</span>
                  </Link>
                </div>
                {/* ------ skill point link close button end ------ */}

                <div className="row">
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <label
                      htmlFor="kpiName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Select Skill")}
                    </label>
                    <Select
                      isClearable
                      placeholder={t("Select skill")}
                      options={allSkills}
                      value={kpisData.skillValue}
                      onChange={(value) => {
                        selectKpisSkills(
                          index,
                          "skillValue",
                          "selectedSkill",
                          value
                        );
                      }}
                    />
                    {/* warning */}
                    {validation.skillWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please select skill")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <label
                      htmlFor="points"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Points")}
                    </label>
                    <div className="field-container d-flex align-items-center body-bg border border-gray-bg rounded-10">
                      <input
                        type="text"
                        className="form-control fs-sm border-0 shadow-none bg-white w-100"
                        placeholder={t("Point")}
                        value={kpisData.point}
                        onChange={(e) => {
                          changeSkillPointInputs(
                            index,
                            "point",
                            e.target.value
                          );
                        }}
                      />
                      {/* warning */}
                      {validation.pointWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter point")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {/* warning */}
          {validation.kpisBlockWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please add KPIs")}!</span>
              </p>
            </div>
          )}

          <Link
            to="#"
            onClick={addNewKpisBlock}
            className="text-primary d-inline-flex align-items-center gap-1"
          >
            <span className="d-block material-symbols-outlined">add</span>
            <span className="d-block">{t("Add KPIs")}</span>
          </Link>

          <div className="my-2 d-flex align-items-center justify-content-end">
            <button
              className="btn btn-primary"
              onClick={saveProcessHandler}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              {t("Save Proecess")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveHiringProcessPopup;
