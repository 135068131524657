/* eslint-disable */
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const EventListHeader = ({
  updateRankHandler,

  reloadEventList,
}) => {
  const { t } = useTranslation(); //for translation
  const breadcrumbText = [{ title: t("Event") }];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* --- search section start ---- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        {/* --- search section end ---- */}

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          {/* ------ refresh icon start ------ */}
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center"
            onClick={reloadEventList}
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          {/* ------ refresh icon end ------ */}

          {/* --- action section start --- */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              {/* ----- update rank section start -----  */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  onClick={updateRankHandler}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    progress_activity
                  </span>
                  <span className="d-block">{t("Update Rank")}</span>
                </Link>
              </li>
              {/* ----- update rank section end -----  */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* --- action section end --- */}

          {/* ---- filter section start ---- */}
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasEventFilter"
            aria-controls="offcanvasEventFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          {/* ---- filter section end ---- */}

          {/* ---- add new event section start ---- */}
          <Link
            to="/admin/events/info"
            className="btn btn-primary d-flex align-items-center gap-1"
            // data-bs-toggle="modal"
            // data-bs-target="#eventType"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Add New")}</span>
          </Link>
          {/* ---- add new event section end ---- */}
        </div>
      </div>
    </div>
  );
};

export default EventListHeader;
