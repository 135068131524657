/* eslint-disable */

import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import CompanyDetailsCommonHeader from '../../CompanyDetailsCommonHeader/CompanyDetailsCommonHeader';
import CompanyTeamMembersHeader from '../CompanyTeamMembersHeader/CompanyTeamMembersHeader';
import { Link, useParams } from 'react-router-dom';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import MemberDetailsModal from '../Modals/MemberDetailsModal';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';

const CompanyTeamMembersBody = () => {
  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t } = useTranslation(); //for translation
  const [isLoading, setIsLoading] = useState(false);
  const [companyMemberList, setCompanyMemberList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedCompanyMemberIds, setSelectedCompanyMemberIds] = useState([]);

  const [selectedMemberId, setSelectedMemberId] = useState(null);

  //function for get all contacts
  const getAllCompanyMemberList = async () => {
    setRowSelection({});
    setSelectedCompanyMemberIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_MEMBERS +
        `?token=${token}&company=${params.id}`;

      // if (filterName != "") {
      //   requestUrl = requestUrl + `&filtername=${filterName}`;
      // }

      // if (filterEmail != "") {
      //   requestUrl = requestUrl + `&filteremail=${filterEmail}`;
      // }

      // if (filterPhone != "") {
      //   requestUrl = requestUrl + `&filterphone=${filterPhone}`;
      // }

      // if (filterPostion != "") {
      //   requestUrl = requestUrl + `&filterposition=${filterPostion}`;
      // }

      // if (filterLabels.length > 0) {
      //   requestUrl = requestUrl + `&filterlabels=${filterLabels}`;
      // }

      console.log('url of company member list------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in company member list------>', response);

      setIsLoading(false);
      if (response.status) {
        setCompanyMemberList(response.data);
        // resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedCompanyMemberIds) {
      try {
        let statusData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_COMPANY_MEMBER +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllCompanyMemberList();
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'memebername',
        header: 'Name',
        Cell: ({ row }) => (
          <div className="name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="profile d-flex align-items-center gap-2">
                <div
                  className="avatar rounded-circle overflow-hidden"
                  style={{ width: '35px', height: '35px' }}
                >
                  <img
                    src={
                      row.original.user.photoimage
                        ? url.SERVER_URL + row.original.user.photoimage.path
                        : assetImages.defaultUser
                    }
                    alt="Richard Hawail"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <div>
                  <p className="fs-md fw-semibold mb-0">
                    {row.original.memebername}
                  </p>
                  <Link
                    to="mailto:richard.hawail@gmail.com"
                    className="d-block lh-1"
                  >
                    {row.original.user.email}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'rolestring',
        header: 'Role',
        Cell: ({ row }) => (
          <div className="role border-bottom-0">
            <p className="fw-semibold">{row.original.rolestring}</p>
          </div>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <ul className="d-flex flex-column flex-wrap gap-1">
              {row.original.moderatorstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold"></span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.moderatorstatus == '1'
                        ? 'text-success'
                        : row.original.moderatorstatus == '0'
                        ? 'text-primary'
                        : 'text-gray'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Moderator</span>
                </li>
              )}
              {row.original.memberstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold">--</span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.memberstatus == '1'
                        ? 'text-success'
                        : row.original.memberstatus == '0'
                        ? 'text-gray'
                        : 'text-warning'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Member</span>
                </li>
              )}
            </ul>
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: 'Label',
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: '_id',
        header: 'Action',
        Cell: ({ row }) => (
          <div className="action border-bottom-0">
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              data-bs-toggle="modal"
              data-bs-target="#addCompanyMember"
              onClick={() => {
                setSelectedMemberId(row.original._id);
              }}
            >
              <span className="d-block">Details</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedCompanyMemberIds(selectedIdsArray);
    } else {
      setSelectedCompanyMemberIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    getAllCompanyMemberList();
  }, []);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_COMPANY')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- companies common header ---- */}
          {params.id && <CompanyDetailsCommonHeader moduleName="teammembers" />}
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ------- filter area --------- */}
            <CompanyTeamMembersHeader
              reloadCompanyMemberList={getAllCompanyMemberList}
              changeStatusHandler={changeStatusHandler}
            />

            {/* ------- list area ---------- */}

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={companyMemberList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area --------- */}
        {/* --------- add label modal ------------ */}
        <AddLabelModal
          moduleName="companymember"
          selectedIds={selectedCompanyMemberIds}
          afterTagModalClose={getAllCompanyMemberList}
        />

        {/* ---------- member details modal ----------- */}
        <MemberDetailsModal
          afterModalClose={getAllCompanyMemberList}
          selectedMemberId={selectedMemberId}
          setSelectedMemberId={setSelectedMemberId}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CompanyTeamMembersBody;
