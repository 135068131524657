/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import CustomerQueriesData from '../../../../../../../data/Courses/CustomerQueriesData.json';
import { useParams } from 'react-router-dom';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AddTaskPopup from '../Popup/AddTaskPopup';
import CommonTabs from '../../CommonTabs/CommonTabs';
import CustomerQueriesHeader from '../CustomerQueriesHeader/CustomerQueriesHeader';
import { useTranslation } from 'react-i18next';

const CustomerQueriesBody = () => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Question Name'),
        size: 800,
        Cell: ({ row }) => (
          <td className="question-name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <p className="fs-md fw-semibold">{row.original.name}</p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'Send By',
        header: t('Send By'),
        Cell: ({ row }) => (
          <td className="send-by border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '35px', height: '35px' }}
              >
                <img
                  src={row.original.sendBy.img}
                  alt="Richard Hawail"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p className="fs-md fw-semibold mb-0">
                {row.original.sendBy.name}
              </p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'date',
        header: t('Date'),
        Cell: ({ row }) => (
          <td className="date border-bottom-0">
            <p className="d-flex align-items-center gap-2">
              <span className="d-block material-symbols-outlined icon-md">
                calendar_month
              </span>
              <span className="d-block">{row.original.date}</span>
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'Action',
        header: t('Action'),
        Cell: () => (
          <td className="action border-bottom-0">
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#addTask"
              aria-controls="addTask"
            >
              <span className="d-block">{t('Details')}</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="course-details-wrapper bg-white pb-5">
        {/* -------------- common tab ----------- */}
        {params.id && <CommonTabs activeTab="customerQueries" />}

        <div className="container-fluid px-lg-5">
          {/* ----------- filter area ---------- */}
          <CustomerQueriesHeader />

          {/* -------- table area ------------ */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="question-name bg-transparent fs-md fw-semibold border-0"
                      width="60%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllQueries"
                            name="query"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Question Name</span>
                      </div>
                    </th>
                    <th className="send-by bg-transparent fs-md fw-semibold border-0">
                      Send By
                    </th>
                    <th className="date bg-transparent fs-md fw-semibold border-0">
                      Date
                    </th>
                    <th className="action bg-transparent fs-md fw-semibold border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query1"
                            value="query1"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query2"
                            value="query2"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query3"
                            value="query3"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query4"
                            value="query4"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query5"
                            value="query5"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query6"
                            value="query6"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query7"
                            value="query7"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="query"
                            id="query8"
                            value="query8"
                          />
                        </div>
                        <p className="fs-md fw-semibold">
                          How can you put question here?
                        </p>
                      </div>
                    </td>
                    <td className="send-by border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '35px', height: '35px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">Richard Hawail</p>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#addTask"
                        aria-controls="addTask"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={CustomerQueriesData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* ------------ add label modal --------------- */}
      <AddLabelModal moduleName="" />

      {/* --------- add task popup -------------- */}
      <AddTaskPopup />
    </div>
  );
};

export default CustomerQueriesBody;
