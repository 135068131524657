import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProjectGanttHeader = ({
  breadCrumbText,
  title,
  reloadList,
  projectid,
}) => {
  const { t } = useTranslation(); //for translation
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb
            breadCrumbText={breadCrumbText}
            bottom={true}
            displayName={`${title}`}
          />
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Gantt View")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/projectmanagement/tasksection/${projectid}`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("Section View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/projectmanagement/tasklist/${projectid}`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    list
                  </span>
                  <span className="d-block">{t("List View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/projectmanagement/kanban/${projectid}`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    view_kanban
                  </span>
                  <span className="d-block">{t("Kanban View")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to={`/admin/projectmanagement/gantt/${projectid}`}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    insert_chart_outlined
                  </span>
                  <span className="d-block">{t("Gantt View")}</span>
                </Link>
              </li>
            </ul>
          </div>

          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTaskFilter"
            aria-controls="offcanvasTaskFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectGanttHeader;
