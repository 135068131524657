/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import clipboardCopy from "clipboard-copy";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

import smtpSecureOptions from "data/Prod/smtpSecureOptions.json";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";

import {
  getData,
  uploadSingleFile,
  uploadMultipleFile,
  postData,
  putData,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { appUserHeaderLinks } from "helper/AppUserHelper/AppUserHelper";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";

import SaveSettingsSuccessModal from "./Modal/SaveSettingsSuccessModal";

const AppUserSettingsBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];
  const { t } = useTranslation(); //for translation

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App User"), link: "/admin/appuser/list" },
    { title: t("Settings") },
  ];

  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = appUserHeaderLinks(params.id, t);

  const copyMessage =
    url.API_BASE_URL + url.API_LINKEDIN_HELPER + `/${userInfo._id}`;

  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");

  // smtp config
  const [smtpHost, setSmtpHost] = useState(null);
  const [smtpUser, setSmtpUser] = useState(null);
  const [smtpPassword, setSmtpPassword] = useState(null);
  const [smtpPort, setSmtpPort] = useState(null);
  const [smtpSecure, setSmtpSecure] = useState(null);
  const [useSmtpConfigValue, setUseSmtpConfigValue] = useState(false);
  const [useSmtpConfig, setUseSmtpConfig] = useState(null);

  // sendgrid config
  const [sendgridmail, setSendgridmail] = useState(null);
  const [sendgridkey, setSendgridkey] = useState(null);
  const [usesendgrid, setUsesendgrid] = useState(null);
  const [usesendgridSelectOption, setUsesendgridSelectOption] = useState(null);

  //out of field requirements
  const [telephone, setTelephone] = useState("");
  const [position, setPosition] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [website, setWebsite] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");

  const [isSaving, setisSaving] = useState(false);

  //function for get user details
  const getUserDetails = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}&userip=${userPublicIp}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setName(data.name || "");
        setSurName(data.surname || "");

        setTelephone(data.phone ?? "");
        setPosition(data.position ?? "");
        setAddress(data.address || "");
        setWebsite(data.website || "");
        setRate(data.costperhour || "");
        setstateName(data.state || "");
        setcityName(data.city || "");
        setcountry(data.country || "");
        setzipcode(data.zipcode || "");

        // mail configuration -----------------
        if (data.smtphost && data.smtphost.length > 0) {
          setSmtpHost(data.smtphost);
        }
        if (data.smtpuser && data.smtpuser.length > 0) {
          setSmtpUser(data.smtpuser);
        }
        if (data.smtppass && data.smtppass.length > 0) {
          setSmtpPassword(data.smtppass);
        }
        if (data.smtpport && data.smtpport.length > 0) {
          setSmtpPort(data.smtpport);
        }

        if (data.sendgridmail && data.sendgridmail.length > 0) {
          setSendgridmail(data.sendgridmail);
        }

        if (data.sendgridkey && data.sendgridkey.length > 0) {
          setSendgridkey(data.sendgridkey);
        }

        for (let smtpSecureOption of smtpSecureOptions) {
          // set secure smtp connect option YES/NO
          if (smtpSecureOption.value == data.smtpsecure) {
            setSmtpSecure(smtpSecureOption);
          }

          // set either to use this config or not, YES / NO
          if (smtpSecureOption.value == data.usesmtpmail) {
            setUseSmtpConfig(smtpSecureOption);
            setUseSmtpConfigValue(smtpSecureOption.value);
          }

          // use sendgrid
          if (smtpSecureOption.value == data.usesendgrid) {
            setUsesendgridSelectOption(smtpSecureOption);
            setUsesendgrid(smtpSecureOption.value);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // send a mail to check if config is validated
  const validateSmtpConfig = async () => {
    alert("send test mail and check connection");
  };

  //function for save/update user
  const saveUserHandler = async () => {
    setisSaving(true);
    const userPublicIp = await getPublicIP();

    try {
      let userData = {
        position,
        phone: telephone,
        address,
        city: cityName,
        state: stateName,
        country,
        zipcode,
        costperhour: rate,
        website,

        smtphost: smtpHost && smtpHost.length > 2 ? smtpHost.trim() : null,
        smtpuser: smtpUser && smtpUser.length > 2 ? smtpUser.trim() : null,
        smtppass:
          smtpPassword && smtpPassword.length > 2 ? smtpPassword.trim() : null,
        smtpport: smtpPort && smtpPort.length > 1 ? smtpPort.trim() : null,
        smtpsecure: smtpSecure ? smtpSecure.value : null,
        usesmtpmail: useSmtpConfig ? useSmtpConfig.value : null,

        sendgridmail:
          sendgridmail && sendgridmail.length > 2 ? sendgridmail.trim() : null,
        sendgridkey:
          sendgridkey && sendgridkey.length > 2 ? sendgridkey.trim() : null,
        usesendgrid: usesendgridSelectOption
          ? usesendgridSelectOption.value
          : null,
      };

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl =
          requestUrl +
          url.API_UPDATE_APP_USER +
          `/${params.id}` +
          `?token=${token}&userip=${userPublicIp}`;
        response = await putData(requestUrl, userData);
      }

      console.log(response);

      setisSaving(false);

      if (response.status) {
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("saveSettingsModal")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getUserDetails();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ---- expert details common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Settings")}
          />
        )}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---- bread crumb ------- */}
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            bottom={true}
            displayName={`${name} ${surName}`}
          />

          {/* -------- table section ---------- */}

          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            {/* Mail sending configuration for this user ---------------------- */}
            <h3>{t("SMTP Configuration (for sending mails)")}</h3>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">{t("SMTP Host")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("SMTP Host")}
                    value={smtpHost}
                    onChange={(e) => setSmtpHost(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">{t("SMTP User")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("SMTP User Email")}
                    value={smtpUser}
                    onChange={(e) => setSmtpUser(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">{t("SMTP Password")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("SMTP Password")}
                    value={smtpPassword}
                    onChange={(e) => setSmtpPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("SMTP Port")} (<i>{t("587 for non-SSL, 465 for SSL")}</i>{" "}
                    )
                  </p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Set Port")}
                    value={smtpPort}
                    onChange={(e) => setSmtpPort(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Is secure connection?")} (<i>{t("true if SSL/TLS")}</i>{" "}
                    )
                  </p>
                </div>
                <div className="col-lg-4">
                  <Select
                    options={smtpSecureOptions}
                    value={smtpSecure}
                    onChange={(val) => {
                      setSmtpSecure(val);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Use this configuration to send mails")}
                  </p>
                </div>
                <div className="col-lg-4">
                  <Select
                    options={smtpSecureOptions}
                    value={useSmtpConfig}
                    onChange={(val) => {
                      setUseSmtpConfig(val);
                    }}
                  />
                </div>

                <div className="col-lg-2">
                  {useSmtpConfigValue == true ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      //onClick={validateSmtpConfig}
                    >
                      <span class="d-block material-symbols-outlined">
                        check
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {/* SEND GRID  ---------------------- */}
            <hr />
            <h3>{t("SendGrid Configuration (for sending mails)")}</h3>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">SendGrid {t("Email")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Email")}
                    value={sendgridmail}
                    onChange={(e) => setSendgridmail(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">{t("SendGrid API Key")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("API Key")}
                    value={sendgridkey}
                    onChange={(e) => setSendgridkey(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Use SendGrid for sending mail")}
                  </p>
                </div>
                <div className="col-lg-4">
                  <Select
                    options={smtpSecureOptions}
                    value={usesendgridSelectOption}
                    onChange={(val) => {
                      setUsesendgridSelectOption(val);
                    }}
                  />
                </div>

                <div className="col-lg-2">
                  {usesendgrid == true ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      //onClick={validateSmtpConfig}
                    >
                      <span class="d-block material-symbols-outlined">
                        check
                      </span>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="action">
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveUserHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                {t("Save Settings")}
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>

          <div className="mt-4 p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <h3>{t("LinkedIn Helper Webhook")}</h3>

            <div className="form-group mb-4">
              <label
                htmlFor="selectList"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Webhook URL")}
              </label>
              <div className="d-flex align-items-center gap-2">
                <input
                  type="text"
                  className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                  value={copyMessage}
                  readOnly
                />
                <Link
                  className="d-flex align-items-center justify-content-center"
                  to="#"
                  onClick={() => {
                    clipboardCopy(copyMessage);
                  }}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    content_copy
                  </span>
                </Link>
              </div>
            </div>
            <div className="action d-flex align-items-center gap-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  clipboardCopy(copyMessage);
                }}
              >
                {t("Copy URL")}
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* ========= popups and modals area =========== */}
      {/* -------- add label modal ------------ */}
      <SaveSettingsSuccessModal afterModalClose={getUserDetails} />

      {/* --------- filter modal ----------- */}
    </div>
  );
};

export default AppUserSettingsBody;
