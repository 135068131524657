/* eslint-disable */
import AffiliateProposalBody from 'components/AffiliateComponents/AffiliateProposalComponents/AffiliateProposalBody/AffiliateProposalBody';
import Header from 'components/Common/Header/Header';
import React, { useEffect } from 'react';

const AffiliateProposals = () => {
  useEffect(() => {
    document.title = 'Affiliate Proposals';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="affiliation" />

      {/* ---- body section ---- */}
      <AffiliateProposalBody />
    </main>
  );
};

export default AffiliateProposals;
