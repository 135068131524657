/* eslint-disable */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

// url and get params in urls
import queryString from "query-string";
import { ecosystemSlug } from "Config/Config";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";

const AuthValidate = () => {
  let token = localStorage.getItem("token");

  const location = useLocation();

  let searchQuery = queryString.parse(location.search);

  const validateUserHandler = async () => {
    const userToken = searchQuery?.token;
    const redirectTo = searchQuery?.redirectto;

    const userPublicIp = await getPublicIP();

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_AUTH_VALIDATE +
        `?token=${userToken}&ecosystemslug=${ecosystemSlug}&userip=${userPublicIp}`;

      console.log("request url -->", requestURL);

      const response = await getData(requestURL);

      console.log("response -->", response);

      if (response.status) {
        localStorage.setItem("token", searchQuery.token);
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        localStorage.setItem("userID", JSON.stringify(response.data._id));
        localStorage.setItem("ecosystem", response.ecosystemid);
        localStorage.setItem("ecosystemlogo", response.ecosystemlogo);
        localStorage.setItem("moduleaccess", response.data.moduleaccessdata);

        if (redirectTo && redirectTo == "MY_PROFILE") {
          window.location.href = `/admin/appuser/profile/${response.data._id}`;
        } else {
          window.location.href = "/admin/dashboard";
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    try {
      if (token) {
        localStorage.clear();
      }
      validateUserHandler();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  return null;
};

export default AuthValidate;
