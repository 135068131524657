import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const PortfolioAccordion = ({
  portfolioBlock = [],
  updatePortfolioBlock,
  onDeletePortfolioBlock,
  setPortfolioIndex,
  allSkills = [],
  skillValue = null,
  skillSelectionHandler,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      {/* ------ toggle accordion button start ------ */}
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseSummary"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseSummary"
        >
          {t("Portfolio Links")}
        </button>
      </h3>
      {/* ------ toggle accordion button end ------ */}

      <div
        id="panelsStayOpen-collapseSummary"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          <div className="fields-group mb-5">
            <div className="fields d-flex flex-column gap-3">
              {/* ------ links start ------ */}
              {portfolioBlock.map((portfolioBlockData, index) => {
                return (
                  <div className="field" key={index}>
                    <div className="form-group">
                      <div className="row">
                        {/* ----- course section start ----- */}
                        <div className="col-lg-11 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            {/* <label
                                          htmlFor="degree"
                                          className="text-nowrap me-3 mb-0"
                                        >
                                          {t("Link")}-{index + 1}
                                        </label> */}
                            <p className="form-control border-0 shadow-none">
                              {portfolioBlockData}
                            </p>
                          </div>
                        </div>
                        {/* ----- course section end ----- */}

                        {/* ------ edit and delete icon section start ------ */}
                        <div className="col action d-flex align-items-center justify-content-end gap-3 mb-3">
                          {/* ------ edit icon start ------ */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#savePortfolioLinkModal"
                            onClick={() => {
                              updatePortfolioBlock(portfolioBlockData, index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              edit
                            </span>
                          </Link>
                          {/* ------ edit icon end ------ */}
                          {/* ------ delete icon start ------ */}
                          <Link
                            to="#"
                            onClick={() => {
                              onDeletePortfolioBlock(index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          {/* ------ delete icon end ------ */}
                        </div>
                        {/* ------ edit and delete icon section end ------ */}
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* ------ experience list section end ------ */}
            </div>

            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#savePortfolioLinkModal"
              onClick={() => {
                setPortfolioIndex(null);
              }}
              className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
            >
              <span className="d-block material-symbols-outlined">add</span>
              <span className="d-block">{t("Add Portfolio Link")}</span>
            </Link>
          </div>

          {/* ------ skill selection section start ------ */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Additional Skills")}
              </label>
              <Select
                isMulti
                className="w-100"
                placeholder={t("Select Skills")}
                options={allSkills}
                value={skillValue}
                onChange={(val) => {
                  skillSelectionHandler(val);
                }}
              />
            </div>
          </div>
          {/* ------ skill selection section end ------ */}
        </div>
      </div>
    </div>
  );
};

export default PortfolioAccordion;
