/* eslint-disable */
import { assetImages } from "constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AfterSaveProductModal = ({ afterSaveModalClose }) => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="modal fade"
      id="saveProductinfo"
      tabIndex="-1"
      aria-labelledby="saveEventinfoLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center p-5">
            <img
              src={assetImages.successCheckPng}
              alt="Your Company Details Saved Successfullys"
              className="img-fluid mb-2"
            />
            <h2 className="mb-5">{t("Product Saved Successfully")}</h2>
            <div className="action d-flex justify-content-center gap-3">
              <Link
                to="#"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  afterSaveModalClose();
                }}
              >
                {t("Back to List")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfterSaveProductModal;
