/* eslint-disable */
import Header from 'components/Common/Header/Header';
import CouponListBody from 'components/CouponComponents/CouponListComponents/CouponListBody/CouponListBody';
import React, { useEffect } from 'react';

const CouponList = () => {
  useEffect(() => {
    document.title = 'Coupons';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="coupons" />

      {/* ---- body section ---- */}
      <CouponListBody />
    </main>
  );
};

export default CouponList;
