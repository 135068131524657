/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import CrmLeadAddTaskBody from "components/CRMComponents/CRMLeadInformationComponents/CRMLeadAddTaskComponents/CrmLeadAddTaskBody/CrmLeadAddTaskBody";

const CrmLeadAddTask = () => {
  useEffect(() => {
    document.title = "CRM Lead Information | Add task";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmLeadAddTaskBody />
    </main>
  );
};

export default CrmLeadAddTask;
