/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';

/*import url and gateway methods */
import { putData, getData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import { appUserHeaderLinks } from 'helper/AppUserHelper/AppUserHelper';
import { useTranslation } from 'react-i18next';
import { getPublicIP } from 'helper/AuthHelper/AuthHelper';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';

const AppUserPasswordBody = () => {
  const token = localStorage.getItem('token');
  const { t } = useTranslation(); //for translation
  const [name, setName] = useState('');
  const [surName, setSurName] = useState('');

  // State to manage form inputs
  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  /* hook for validation */
  const [validation, setValidation] = useState({
    passwordWarning: false,
    confirmPasswordWarning: false,
  });

  const params = useParams();

  const commonHeaderObject = appUserHeaderLinks(params.id, t);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t('App User'), link: '/admin/appuser/list' },
    { title: t('Password') },
  ];

  //function for get user details
  const getUserDetails = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setName(data.name || '');
        setSurName(data.surname || '');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // Function to handle form input changes
  const handleInputChange = e => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });

    setValidation({
      passwordWarning: false,
      confirmPasswordWarning: false,
    });
  };

  /* function to validate password */
  const validate = () => {
    let isValid = true;

    if (
      passwordData.newPassword === '' &&
      passwordData.confirmPassword === ''
    ) {
      setValidation(prevState => ({ ...prevState, passwordWarning: true }));

      isValid = false;
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setValidation(prevState => ({
        ...prevState,
        confirmPasswordWarning: true,
      }));
      setShowAlert(true);
      setAlertMessage(t('Password does not match'));
      setMessageType('error');
      isValid = false;
    }

    return isValid;
  };

  // Function to handle password change
  const changePasswordHandler = async () => {
    if (validate()) {
      const userPublicIp = await getPublicIP();

      setIsLoading(true);
      try {
        let passwordUpdatedData = {
          password: passwordData.confirmPassword,
        };

        // Construct the request URL by combining the API base URL, the specific API endpoint (API_UPDATE_APP_USER),
        // and including a token as a query parameter for authentication.
        let requestUrl =
          url.API_BASE_URL +
          url.API_CHANGE_USER_PASSWORD +
          `/${params.id}` +
          `?token=${token}&userip=${userPublicIp}`;

        // Send a request to change the password using putData or your preferred method
        const response = await putData(requestUrl, passwordUpdatedData);

        console.log(response);

        setIsLoading(false);
        // Handle the response as needed, e.g., check for success or errors
        if (response.status) {
          resetHandler();

          setMessageType('success');
          setAlertMessage(t('Password changed successfully.'));
          setShowAlert(true);
        } else {
          setMessageType('error');
          setAlertMessage(t('Password change failed. Please try again.'));
          setShowAlert(true);
        }
      } catch (error) {
        // Handle any network or request errors

        setMessageType('error');
        setAlertMessage(t('An error occurred while changing the password.'));
        setShowAlert(true);
      }
    } else {
      setMessageType('error');
      setAlertMessage(t('Please enter password and confirm password.'));
      setShowAlert(true);
    }
  };

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  // Function to reset form inputs and message state
  const resetHandler = () => {
    setPasswordData({
      newPassword: '',
      confirmPassword: '',
    });

    onAlertClose();
  };

  useEffect(() => {
    if (params.id) {
      getUserDetails();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ---- expert details common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Password')}
          />
        )}

        {/* ----- change password form start -------- */}
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---- bread crumb ------- */}
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            bottom={true}
            displayName={`${name} ${surName}`}
          />

          {/* ----- change password form -------- */}
          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <h3 className="mb-4">{t('Change Password')}</h3>
            <form onSubmit={e => e.preventDefault()}>
              {/* New Password Input start */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  <div className="col-lg-2">
                    <label
                      htmlFor="newPassword"
                      className="d-block fs-sm fw-semibold"
                    >
                      {t('New Password')}
                    </label>
                  </div>
                  <div className="col-lg-10">
                    <input
                      type="password"
                      id="newPassword"
                      name="newPassword"
                      className="form-control fs-sm shadow-none"
                      placeholder={t('Enter New Password')}
                      value={passwordData.newPassword}
                      onChange={handleInputChange}
                    />
                    {/* new password warning */}
                    {validation.passwordWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t('Please enter password')}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* New Password Input end */}

              {/* Confirm Password Input start */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  <div className="col-lg-2">
                    <label
                      htmlFor="confirmPassword"
                      className="d-block fs-sm fw-semibold"
                    >
                      {t('Confirm Password')}
                    </label>
                  </div>
                  <div className="col-lg-10">
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      className="form-control fs-sm shadow-none"
                      placeholder={t('Enter New Password')}
                      value={passwordData.confirmPassword}
                      onChange={handleInputChange}
                    />

                    {validation.passwordWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t('Enter confirm password')}!</span>
                        </p>
                      </div>
                    )}
                    {/* confirm password warning */}
                    {validation.confirmPasswordWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t('Password does not match')}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {/* level warning */}
                {validation.passwordWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter password')}!</span>
                    </p>
                  </div>
                )}

                {/* level warning */}
                {validation.confirmPasswordWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Passwords do not match')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* Confirm Password Input end */}

              {/* Action Buttons start */}
              <div className="action d-flex flex-wrap gap-3 align-items-center">
                <button
                  onClick={changePasswordHandler}
                  type="button"
                  className="btn btn-primary"
                  disabled={isLoading ? true : false}
                  style={{
                    cursor: isLoading ? 'not-allowed' : 'pointer',
                  }}
                >
                  {t('Update Password')}
                  {isLoading && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* Action Buttons end */}
            </form>
          </div>
        </div>
        {/* ----- change password form end -------- */}
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default AppUserPasswordBody;
