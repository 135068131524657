import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";
import SurveyListBody from "components/SurveyComponents/SurveyListComponents/SurveyListBody/SurveyListBody";

const SurveyList = () => {
  useEffect(() => {
    document.title = "Survey";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="survey" />

      {/* --------- body section ------------ */}
      <SurveyListBody />
    </main>
  );
};

export default SurveyList;
