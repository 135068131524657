/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import membership options from json
import membershipStatusOption from "data/Prod/MembershipStatus.json";
import { useTranslation } from "react-i18next";

const AddNewSubscriberModal = ({ afterCloseModalHandler }) => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [userInput, setUserInput] = useState("");
  const [userOptions, setuserOptions] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [roleValue, setRoleValue] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [errorMessage, seterrorMessage] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  //function for get user details
  const getUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${userInput}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setuserOptions(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user input
  const userInputHandler = (val) => {
    setUserInput(val);
    seterrorMessage("");
  };

  //function user Selection
  const userSelectionHandler = (val) => {
    seterrorMessage("");
    if (val) {
      setUserValue(val);
      setUserId(val._id);
      setUserEmail(val.email);
    } else {
      setUserValue(null);
      setUserId(null);
      setUserEmail("");
      setuserOptions([]);
    }
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    if (val) {
      setRoleValue(val);
      setRoleData(val.value);
      seterrorMessage("");
    } else {
      setRoleValue(null);
      setRoleData(null);
    }
  };

  //function for select memebership status
  const statusSelectionHandler = (val) => {
    if (val) {
      setStatusValue(val);
      setStatusData(val.value);
      seterrorMessage("");
    } else {
      setStatusValue(null);
      setStatusData(null);
    }
  };

  //function for add
  const addSubscriberHandler = async () => {
    try {
      setIsAdding(true);

      let courseSubsriberData = {
        user: userId,
        course: params.id ? params?.id : null,
        role: roleData,
        useremail: userEmail,
        moderatorstatus: statusData,
        memberstatus: "0",
      };

      console.log("---->", courseSubsriberData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_ADD_NEW_COURSE_SUBSCRIBER +
        `?token=${token}`;

      const response = await postData(requestUrl, courseSubsriberData);

      setIsAdding(false);

      // console.log(response);

      if (response.status) {
        resetHandler();

        //hide member modal
        let bootstrapModal = document.querySelector("#addCourseSubscriber");
        let modal = bootstrap.Modal.getInstance(bootstrapModal);
        modal.hide();

        //call member list api function
        afterCloseModalHandler();
      } else {
        seterrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setUserValue(null);
    setuserOptions([]);
    setStatusValue(null);
    setStatusData(null);
    setRoleData(null);
    setRoleValue(null);
    setUserInput("");
    setUserId(null);
    setUserEmail("");
    seterrorMessage("");
    for (let memberStatus of membershipStatusOption) {
      if (memberStatus.value === "1") {
        statusSelectionHandler(memberStatus);
      }
    }
  };

  useEffect(() => {
    if (userInput.length > 0) {
      getUserList();
    }
  }, [userInput]);

  useEffect(() => {
    for (let memberStatus of membershipStatusOption) {
      if (memberStatus.value === "1") {
        statusSelectionHandler(memberStatus);
      }
    }
  }, []);

  return (
    <div
      className="modal fade"
      id="addCourseSubscriber"
      tabIndex="-1"
      aria-labelledby="addSubscriberLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Add New Subscriber")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="subscriberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Subscriber (search by name, email)")}
                </label>

                <Select
                  isClearable
                  className="w-100"
                  placeholder={t("Search by name, email")}
                  options={userOptions}
                  value={userValue}
                  onChange={(val) => {
                    userSelectionHandler(val);
                  }}
                  onInputChange={(val) => {
                    userInputHandler(val);
                  }}
                />
              </div>
              {/* ----- user select section end ----- */}

              {/* ----- status select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Subscriber Status")}
                </label>

                <Select
                  className="w-100"
                  placeholder={t("Select status")}
                  options={membershipStatusOption}
                  value={statusValue}
                  onChange={(val) => statusSelectionHandler(val)}
                />
              </div>
              {/* ----- status select section end ----- */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("Cancel")}
                </Link>
                {errorMessage == "" ? null : (
                  <p className="text-danger">* {errorMessage}</p>
                )}
                {/* <Link to="#">
                  <span className="d-block material-symbols-outlined">
                    delete
                  </span>
                </Link> */}
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={addSubscriberHandler}
                  disabled={isAdding ? true : false}
                  style={{
                    cursor: isAdding ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {isAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewSubscriberModal;
