import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const SurveyResponseFilterPopup = () => {
  const { t } = useTranslation(); //for translation
  // select data --------------------------
  const options = [
    { label: "Option 1", value: "Option 1" },
    { label: "Option 2", value: "Option 2" },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="" className="d-flex flex-column h-100">
          <div
            className="accordion flex-fill"
            id="accordionPanelsStayOpenExample"
          >
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  {t("Created Dates")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <div className="row gx-2">
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          placeholder="From date"
                        />
                      </div>
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          placeholder="To date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  {t("Role")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <Select
                      placeholder={t("Select Role")}
                      options={options}
                      value={selectOptions}
                      onChange={(val) => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Question Attended")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <label
                      htmlFor="questionAttached"
                      className="d-block fs-sm mb-2"
                    >
                      0 - 15
                    </label>
                    <input
                      type="text"
                      id="questionAttached"
                      className="d-none"
                      readOnly
                    />
                    <div
                      id="questionAttachedValue"
                      className="d-flex justify-content-between gap-2 fs-sm text-gray-400 mb-2"
                    >
                      <span className="d-block min"></span>
                      <span className="d-block max"></span>
                    </div>
                    <div
                      id="questionAttachedSlider"
                      className="bg-gray-300 border-0 rounded-90"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Go")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SurveyResponseFilterPopup;
