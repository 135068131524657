/* eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';
import downloadFileHandler from 'helper/DownloadFile';
import TaskStatusOptions from 'data/Prod/TaskStatus.json';
import TaskPriority from 'data/Prod/TaskPriority.json';
import { assetImages } from 'constants';
import SaveTaskTab from './Tabs/SaveTaskTab';
import ViewTaskTab from './Tabs/ViewTaskTab';
import LogTab from './Tabs/LogTab';

const TaskManagementPopup = ({
  moduleName,
  contactId,
  leadId,
  projectId,
  selectedTaskId,
  onPopUpClose,
  selectedTaskModerator,
  isProjectModerator,
  getTaskList,
  memberList,
  selectedSectionValue,
  sectionOptions,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [showTaskTab, setshowTaskTab] = useState(false);
  const [showLogTab, setshowLogTab] = useState(false);
  const [isReset, setIsReset] = useState(false);

  //* Switch between task and log tab based on the tabName parameter */
  const switchTabs = tabName => {
    setshowTaskTab(false);
    setshowLogTab(false);
    if (tabName == 'task') {
      setshowTaskTab(true);
    }
    if (tabName == 'log') {
      setshowLogTab(true);
    }
  };

  const closePopup = () => {
    onPopUpClose();
    setIsReset(true);
    switchTabs('task');
  };

  // const onPopupClose = () => {
  //   let offCanvasPopup = document.querySelector("#addTask_view");
  //   let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
  //   offCanvas.hide();
  //   closePopup();
  //   getTaskList();
  // };

  useEffect(() => {
    // console.log("Selected task id", selectedTaskId);
    if (selectedTaskId) {
      switchTabs('task');
    }
  }, [selectedTaskId]);

  useEffect(() => {
    switchTabs('task');
  }, []);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="addTask_view"
      aria-labelledby="addTaskLabel"
    >
      <div className="offcanvas-header flex-wrap p-4 pb-0 shadow-sm">
        <h3 className="offcanvas-title" id="addTaskLabel">
          {t('Task Details')}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closePopup}
        ></button>
        <ul className="nav nav-tabs w-100 flex-nowrap fs-sm lh-1 fw-semibold text-center border-0 text-nowrap overflow-auto mt-3">
          {/* task tab title */}
          <li className="nav-item flex-fill" role="presentation">
            <Link
              to="#"
              className={
                selectedTaskId
                  ? `text-black d-block px-3 py-3 position-relative ${
                      showTaskTab && 'active'
                    }`
                  : `text-black d-block px-3 py-3 position-relative`
              }
              onClick={() => {
                switchTabs('task');
              }}
            >
              {t('Tasks Details')}
            </Link>
          </li>
          {/* log tab title */}
          {selectedTaskId && (
            <li className="nav-item flex-fill" role="presentation">
              <Link
                to="#"
                className={`text-black d-block px-3 py-3 position-relative ${
                  showLogTab && 'active'
                }`}
                onClick={() => {
                  switchTabs('log');
                }}
              >
                {t('Logs')}
              </Link>
            </li>
          )}
        </ul>
      </div>
      <div className="offcanvas-body p-0">
        <div className="tab-content h-100" id="myTabContent">
          {/* task view tab */}
          {showTaskTab ? (
            selectedTaskModerator?.toString() === userInfo?._id?.toString() ||
            isProjectModerator ||
            userInfo.role.slug === 'ADMIN' ||
            userInfo.role.slug === 'SUPER_ADMIN' ? (
              <SaveTaskTab
                moduleName={moduleName}
                contactId={contactId}
                leadId={leadId}
                projectId={projectId}
                selectedTaskId={selectedTaskId}
                onPopupClose={onPopUpClose}
                getTaskList={getTaskList}
                closePopup={closePopup}
                memberList={memberList}
                selectedSectionValue={selectedSectionValue}
                sectionOptions={sectionOptions}
                isReset={isReset}
                setIsReset={setIsReset}
                setShowAlert={setShowAlert}
                setAlertMessage={setAlertMessage}
                setMessageType={setMessageType}
              />
            ) : (
              <ViewTaskTab
                selectedTaskId={selectedTaskId}
                setShowAlert={setShowAlert}
                setAlertMessage={setAlertMessage}
                setMessageType={setMessageType}
              />
            )
          ) : null}
          {/* log tab */}
          {selectedTaskId && showLogTab && (
            <LogTab
              contactId={contactId}
              leadId={leadId}
              projectId={projectId}
              selectedTaskId={selectedTaskId}
              isReset={isReset}
              setIsReset={setIsReset}
              onPopupClose={onPopUpClose}
              getTaskList={getTaskList}
              setShowAlert={setShowAlert}
              setAlertMessage={setAlertMessage}
              setMessageType={setMessageType}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default TaskManagementPopup;
