/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
import AffiliateProposalHeader from '../AffiliateProposalHeader/AffiliateProposalHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';

import AffiliateProposalFilterPopup from '../Popup/AffiliateProposalFilterPopup';
import AffiliateDetailsPopup from '../Popup/AffiliateDetailsPopup';
/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

const AffiliateProposalBody = () => {
  // common header object
  const commonHeaderObject = [
    {
      title: 'Affiliate Proposals on my Products',
      link: '/admin/affiliate-proposals',
    },
    { title: 'My Request for Affiliation', link: '/admin/affiliate-requests' },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'affiliates',
        header: 'Affiliates',
      },
      {
        accessorKey: 'module',
        header: '	Module',
      },
      {
        accessorKey: 'component',
        header: 'Component',
      },
      {
        accessorKey: 'myStatus',
        header: 'My Status',
        Cell: () => (
          <div className="d-flex align-items-center gap-2">
            <div
              className="avatar rounded-circle overflow-hidden"
              style={{ width: '22px', height: '22px' }}
            >
              <img
                src="/assets/img/dp-1.jpg"
                alt="Richard Hawail"
                className="w-100 h-100 object-fit-cover object-center"
              />
            </div>
            <p>Tom Hanks</p>
          </div>
        ),
      },
      {
        accessorKey: 'recommender',
        header: 'Recommender',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        accessorKey: 'code',
        header: 'Code',
      },
      {
        accessorKey: 'action',
        header: 'Action',
        Cell: () => (
          <Link
            to="#"
            className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#affiliateDetails"
            aria-controls="affiliateDetails"
          >
            <span className="d-block">Details</span>
            <span className="d-block material-symbols-outlined">
              chevron_right
            </span>
          </Link>
        ),
      },
    ],
    []
  );

  const dummyData = [
    {
      affiliates: 'Affiliate 1',
      module: 'Module A',
      component: 'Component X',
      myStatus: 'Active',
      recommender: 'User123',
      status: 'Pending',
      code: '123456',
      action: 'Approve',
    },
    {
      affiliates: 'Affiliate 2',
      module: 'Module B',
      component: 'Component Y',
      myStatus: 'Inactive',
      recommender: 'User456',
      status: 'Approved',
      code: '789012',
      action: 'Reject',
    },
    {
      affiliates: 'Affiliate 3',
      module: 'Module C',
      component: 'Component Z',
      myStatus: 'Pending',
      recommender: 'User789',
      status: 'Pending',
      code: '345678',
      action: 'Review',
    },
  ];

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  // Function to get all records
  const getAllAffiliateProposals = () => {};
  useEffect(() => getAllAffiliateProposals(), []);

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption="Affiliate Proposals on my Products"
        />

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ----------- header seaction ---------------- */}
          <AffiliateProposalHeader />

          {/* ---------- table section ----------- */}
          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={dummyData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              enableRowActions // show the action column
              renderRowActionMenuItems={({ row }) => [
                <MenuItem
                  key="edit"
                  //onClick={() => console.info(row.id)}
                >
                  <Link className="dropdown-item" to="#">
                    View Details
                  </Link>
                </MenuItem>,
                <MenuItem
                  key="delete"
                  //onClick={() => console.info(row.id)}
                >
                  <Link className="dropdown-item" to="#">
                    Update
                  </Link>
                </MenuItem>,
              ]} // action columns menu items
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* -------------- add label modal ------------- */}
      <AddLabelModal moduleName="" />

      {/* ---------- filter popup ------------ */}
      <AffiliateProposalFilterPopup />

      {/* --------- details popup ------------ */}
      <AffiliateDetailsPopup />
    </div>
  );
};

export default AffiliateProposalBody;
