import SentListBody from "components/CRMComponents/CRMConversationComponents/SentComponents/SentListBody/SentListBody";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const CrmConversationSentList = () => {
  useEffect(() => {
    document.title = "Sent Mail Conversation";
  }, []);
  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body component --- */}
      <SentListBody />
    </main>
  );
};

export default CrmConversationSentList;
