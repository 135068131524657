import React from 'react';
import Header from 'components/Common/Header/Header';
import AppUserPasswordBody from 'components/AppUserComponents/AppUserDetails/AppUserPassword/AppUserPasswordBody/AppUserPasswordBody';

const AppUserPassword = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="appuser" />

      {/* -------- body section ----------- */}
      <AppUserPasswordBody />
    </main>
  );
};

export default AppUserPassword;
