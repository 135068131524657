/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import SaveContactOpportunityBody from "components/CRMComponents/CRMContactDetailsComponents/CRMContactOpportunityComponents/SaveContactOpportunity/SaveContactOpportunityBody";

const SaveContactOpportunities = () => {
  useEffect(() => {
    document.title = "CRM Contact Information | Opportunity";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <SaveContactOpportunityBody />
    </main>
  );
};

export default SaveContactOpportunities;
