/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';
import ModuleListHeader from '../Header/ModuleListHeader';
import SaveModulePopup from '../Popup/SaveModulePopup';
import ModuleListFilterPopup from '../Popup/ModuleListFilterPopup';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';

const ModuleListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { t, i18n } = useTranslation(); //for translation
  const [moduleList, setModuleList] = useState([]);
  const [parentModules, setparentModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [moduleId, setModuleId] = useState(null);

  const [filterModuleName, setFilterModuleName] = useState('');
  const [filterModuleSlug, setFilterModuleSlug] = useState('');

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectModuleIds, setSelectModuleIds] = useState([]);

  //function for get all modules
  const getAllModules = async () => {
    setRowSelection({});
    setSelectModuleIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_MODULES + `?token=${token}`;

      if (filterModuleName !== '') {
        requestUrl = requestUrl + `&filtername=${filterModuleName}`;
      }

      if (filterModuleSlug !== '') {
        requestUrl = requestUrl + `&filterslug=${filterModuleSlug}`;
      }

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setModuleList(response.data);
        setparentModules(response.data.filter(item => item.isroot));
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectModuleIds) {
      try {
        let updatedData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_MODULE +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, updatedData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllModules();
  };

  // Update the rank value in the blogList state array
  const rankInputChangeHandler = (row, value) => {
    const updatedModuleList = [...moduleList];
    const rowIndex = updatedModuleList.findIndex(
      module => module._id === row.original._id
    );
    if (rowIndex !== -1) {
      updatedModuleList[rowIndex].rank = value;
      setModuleList(updatedModuleList);
    }
  };

  //function for update rank
  const updateRankHandler = async () => {
    let responseArr = [];

    //run loop checkbox wise
    for (let selectedModuleId of selectModuleIds) {
      //check if checkbox was checked.If checked then proceed

      //run loop of updated bloglist
      for (let module of moduleList) {
        let moduleData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (module._id == selectedModuleId) {
          moduleData.rank = module.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_MODULE +
              `/${selectedModuleId}` +
              `?token=${token}`;
            const response = await putData(requestUrl, moduleData);
            console.log(moduleData);
            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }

    if (responseArr.length > 0) {
      getAllModules();
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterModuleName('');
    setFilterModuleSlug('');
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#saveModule"
            aria-controls="saveModule"
            onClick={() => {
              setModuleId(row.original.value);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'label',
        header: t('Name'),
      },
      {
        accessorKey: 'rank',
        header: t('Rank'),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              width: '100px',
            }}
          >
            <input
              // loading="lazy"
              type="number"
              name="rank"
              id="rank"
              value={row.original?.rank || 1}
              onChange={e => rankInputChangeHandler(row, e.target.value)}
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
            />
          </Box>
        ),
      },
      {
        accessorKey: 'slug',
        header: t('Slug'),
      },
      {
        accessorKey: 'parentname',
        header: t('Parent'),
      },
      {
        accessorKey: 'feturedstring',
        header: t('Is Featured'),
      },
    ],
    [moduleList, i18n.language]
  );

  useEffect(() => {
    if (filterModuleName != '' || filterModuleSlug != '') {
      getAllModules();
    }
  }, [filterModuleName, filterModuleSlug]);

  useEffect(() => {
    getAllModules();
  }, []);

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectModuleIds(selectedIdsArray);
    } else {
      setSelectModuleIds([]);
    }
  }, [rowSelection]);

  //initialize the column order
  const initialColumnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  if (userInfo.role.slug === 'ADMIN' || userInfo.role.slug === 'SUPER_ADMIN') {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <ModuleListHeader
              reloadList={getAllModules}
              changeStatusHandler={changeStatusHandler}
              updateRankHandler={updateRankHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={moduleList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== popup and modal section ==== */}
        <SaveModulePopup
          afterPopupCLose={getAllModules}
          moduleId={moduleId}
          setModuleId={setModuleId}
          parentModules={parentModules}
        />

        <ModuleListFilterPopup
          setFilterModuleName={setFilterModuleName}
          setFilterModuleSlug={setFilterModuleSlug}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ModuleListBody;
