/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import images
import { assetImages } from 'constants';
import ProductListHeader from '../Header/ProductListHeader';
import { useTranslation } from 'react-i18next';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const ProductListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation
  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  const productJson = [
    {
      name: 'Premium Leather Wallet',
      image_url: 'https://example.com/wallet.jpg',
      owner_name: 'John Doe',
      owner_image_url: 'https://example.com/johndoe.jpg',
      sold_unit: 100,
      price: 49.99,
      category: 'Accessories',
      stock: 50,
      tag: 'leather wallet premium',
      created_date: '2023-05-15',
    },
    {
      name: 'Wireless Bluetooth Headphones',
      image_url: 'https://example.com/headphones.jpg',
      owner_name: 'Emily Smith',
      owner_image_url: 'https://example.com/emilysmith.jpg',
      sold_unit: 200,
      price: 79.99,
      category: 'Electronics',
      stock: 30,
      tag: 'wireless headphones bluetooth',
      created_date: '2023-07-20',
    },
    {
      name: 'Vintage Leather Messenger Bag',
      image_url: 'https://example.com/messengerbag.jpg',
      owner_name: 'Michael Johnson',
      owner_image_url: 'https://example.com/michaeljohnson.jpg',
      sold_unit: 50,
      price: 129.99,
      category: 'Bags',
      stock: 20,
      tag: 'vintage leather messenger',
      created_date: '2023-04-10',
    },
    {
      name: 'Organic Cotton T-Shirt',
      image_url: 'https://example.com/tshirt.jpg',
      owner_name: 'Sarah Adams',
      owner_image_url: 'https://example.com/sarahadams.jpg',
      sold_unit: 150,
      price: 19.99,
      category: 'Clothing',
      stock: 100,
      tag: 'organic cotton t-shirt',
      created_date: '2023-06-25',
    },
    {
      name: 'Stainless Steel Water Bottle',
      image_url: 'https://example.com/waterbottle.jpg',
      owner_name: 'David Brown',
      owner_image_url: 'https://example.com/davidbrown.jpg',
      sold_unit: 80,
      price: 14.99,
      category: 'Home & Kitchen',
      stock: 40,
      tag: 'stainless steel water bottle reusable',
      created_date: '2023-08-12',
    },
    {
      name: 'Handcrafted Wooden Sunglasses',
      image_url: 'https://example.com/sunglasses.jpg',
      owner_name: 'Emma Wilson',
      owner_image_url: 'https://example.com/emmawilson.jpg',
      sold_unit: 120,
      price: 59.99,
      category: 'Accessories',
      stock: 60,
      tag: 'handcrafted wooden sunglasses',
      created_date: '2023-09-30',
    },
    {
      name: 'Artisanal Ceramic Mug Set',
      image_url: 'https://example.com/mugset.jpg',
      owner_name: 'Daniel Taylor',
      owner_image_url: 'https://example.com/danieltaylor.jpg',
      sold_unit: 30,
      price: 34.99,
      category: 'Home & Kitchen',
      stock: 10,
      tag: 'artisanal ceramic mug set',
      created_date: '2023-03-08',
    },
    {
      name: 'Fitness Tracker Smartwatch',
      image_url: 'https://example.com/smartwatch.jpg',
      owner_name: 'Olivia Martinez',
      owner_image_url: 'https://example.com/oliviamartinez.jpg',
      sold_unit: 90,
      price: 99.99,
      category: 'Electronics',
      stock: 25,
      tag: 'fitness tracker smartwatch wearable',
      created_date: '2023-10-18',
    },
    {
      name: 'Aromatherapy Essential Oil Diffuser',
      image_url: 'https://example.com/diffuser.jpg',
      owner_name: 'James Wilson',
      owner_image_url: 'https://example.com/jameswilson.jpg',
      sold_unit: 70,
      price: 29.99,
      category: 'Home & Kitchen',
      stock: 15,
      tag: 'aromatherapy essential oil diffuser',
      created_date: '2023-11-22',
    },
    {
      name: 'Gourmet Coffee Beans Sampler Pack',
      image_url: 'https://example.com/coffee.jpg',
      owner_name: 'Sophia Brown',
      owner_image_url: 'https://example.com/sophiabrown.jpg',
      sold_unit: 110,
      price: 39.99,
      category: 'Food & Beverages',
      stock: 45,
      tag: 'gourmet coffee beans sampler pack',
      created_date: '2023-12-05',
    },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <img
              height={30}
              src={assetImages.defaultUser}
              loading="lazy"
              style={{ borderRadius: '50%' }}
            />
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: 'owner_name',
        header: t('Owner'),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              width: '150px',
            }}
          >
            <img
              height={30}
              src={assetImages.defaultUser}
              loading="lazy"
              style={{ borderRadius: '50%' }}
            />
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: 'sold_unit',
        header: t('Sold unit'),
      },
      {
        accessorKey: 'price',
        header: t('Price'),
      },
      {
        accessorKey: 'category',
        header: t('Category'),
      },

      {
        accessorKey: 'stock',
        header: t('Stock'),
      },

      {
        accessorKey: 'created_date',
        header: t('Created Date'),
      },
      {
        accessorKey: 'tag',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tag != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tag.split(' ').map((tagArr, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tagArr}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    //     if (selectedIdsArray.length > 0) {
    //       setSelectedEventIds(selectedIdsArray);
    //     } else {
    //       setSelectedEventIds([]);
    //     }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_MARKETPLACE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* header */}
            <ProductListHeader />

            {/* table */}
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={productJson} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={row => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                enableRowActions // show the action column
                renderRowActionMenuItems={({ row }) => [
                  <MenuItem
                    key="edit"
                    //onClick={() => console.info(row.id)}
                  >
                    <Link
                      className="dropdown-item"
                      to={`/admin/marketplace/information/${row.id}`}
                    >
                      {t('View Details')}
                    </Link>
                  </MenuItem>,
                ]} // action columns menu items
                muiTableContainerProps={{
                  sx: {
                    maxHeight: '60vh',
                  },
                }}
                enableStickyHeader
              />
            </div>
          </div>

          {/* ----- all pop up render here ----- */}
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            // onCancelDelete={() => {
            //   setRowSelection({});
            //   setSelectedBlogIds([]);
            // }}
            // onDelete={changeStatusHandler}
            moduleName={t('product')}
          />
          {/* ------ add label modal render here ----- */}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProductListBody;
