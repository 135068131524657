import Header from 'components/Common/Header/Header';
import GroupCompaniesListBody from 'components/GroupComponents/GroupDetails/GroupCompanies/GroupCompaniesList/GroupCompaniesListBody/GroupCompaniesListBody';
import React from 'react';

const GroupCompaniesList = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* -------------- body area ------------- */}
      <GroupCompaniesListBody />
    </>
  );
};

export default GroupCompaniesList;
