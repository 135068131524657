/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData, postData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const SaveListPopup = ({
  afterSave,
  listId,
  resetListId,
  listTypeOptions,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [listTypeValue, setlistTypeValue] = useState(null);
  const [listType, setlistType] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [companyList, setcompanyList] = useState([]);
  const [contactList, setcontactList] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    typeWarning: false,
  });

  const typeSelectionHandler = (val) => {
    setValidation((prevState) => ({ ...prevState, typeWarning: false }));
    setlistTypeValue(val);
    setlistType(val.value);
  };

  //get details of list
  const getDetailsOfList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_LIST_DETAILS + `/${listId}?token=${token}`;
      const response = await getData(requestURL);

      if (response.status) {
        settitle(response.data.title);
        setdetails(response.data.details);
        setcompanyList(response.data.companyids);
        setcontactList(response.data.contactids);
        typeSelectionHandler(response.data.listtypedata);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //validation
  const validate = () => {
    let isValid = true;
    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }
    if (listType == null) {
      setValidation((prevState) => ({ ...prevState, typeWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //save function
  const saveListHandler = async () => {
    if (validate()) {
      try {
        setIsSaving(true);

        let listData = {
          title,
          details,
          listtype: listType,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (listId) {
          requestURL += url.API_UPDATE_A_LIST + `/${listId}?token=${token}`;
          response = await putData(requestURL, listData);
        } else {
          requestURL += url.API_CREATE_LIST + `?token=${token}`;
          response = await postData(requestURL, listData);
        }

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          resetHandler();
          afterSave();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);

        window.bootstrap.Offcanvas.getInstance(
          document.querySelector("#offcanvasSaveList")
        ).hide();
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please fill up required fields");
      setShowAlert(true);
    }
  };

  const resetHandler = () => {
    settitle("");
    setdetails("");
    setlistTypeValue(null);
    setlistType(null);
    setValidation({
      titleWarning: false,
      typeWarning: false,
    });
    resetListId();
  };

  useEffect(() => {
    if (listId) {
      getDetailsOfList();
    }
  }, [listId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0 w-75"
      tabIndex="-1"
      id="offcanvasSaveList"
      aria-labelledby="offcanvasUpdateListLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasUpdateListLabel">
          {t("Add or Update Lists")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            {/* title */}
            <div className="form-group mb-4">
              <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
                {t("Title")}
              </label>
              <input
                type="text"
                id="title"
                className="form-control fs-sm shadow-none"
                placeholder={t("Task Title")}
                value={title}
                onChange={(e) => {
                  setValidation((prevState) => ({
                    ...prevState,
                    titleWarning: false,
                  }));
                  settitle(e.target.value);
                }}
              />
              {/* ---------- mail warning --------- */}
              {validation.titleWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter title!")}</span>
                  </p>
                </div>
              )}
            </div>
            {/* details */}
            <div className="form-group mb-4">
              <label
                htmlFor="description"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Description")}
              </label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="5"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Description...")}
                value={details}
                onChange={(e) => setdetails(e.target.value)}
              ></textarea>
            </div>
            {/* select type */}
            <div className="form-group mb-4">
              <label
                htmlFor="typeOfList"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Select List Type")}
              </label>
              <Select
                placeholder={t("Select List Type")}
                options={listTypeOptions}
                value={listTypeValue}
                onChange={(val) => {
                  typeSelectionHandler(val);
                }}
              />
              {/* ---------- mail warning --------- */}
              {validation.typeWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select a type!")}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="action">
            <button
              type="button"
              className="btn btn-primary"
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
              onClick={saveListHandler}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SaveListPopup;
