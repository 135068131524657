import { assetImages } from "constants";
import React from "react";

const VeriFyEmailModal = ({
  isVerifying = false,
  onVeriFy,
  onCancelVeriFy,
}) => {
  return (
    <div
      className="modal fade"
      id="veriFyEmail"
      tabIndex="-1"
      aria-labelledby="veriFyEmail"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="warning"
              className="img-fluid"
            />
            <h2>Are you sure you want to verify this emails?</h2>
            <div className="d-flex gap-3 justify-content-center">
              <button
                className="btn btn-outline-primary"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                disabled={isVerifying ? true : false}
                style={{
                  cursor: isVerifying ? "not-allowed" : "pointer",
                }}
                onClick={onVeriFy}
              >
                Yes Verify
                {isVerifying && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
              <button
                className="btn btn-primary"
                onClick={onCancelVeriFy}
                disabled={isVerifying ? true : false}
                style={{
                  cursor: isVerifying ? "not-allowed" : "pointer",
                }}
              >
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VeriFyEmailModal;
