/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

//calendar --------------------------------------------
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// -----------------------------------------------

import {
  getData,
  uploadSingleFile,
  uploadMultipleFile,
  postData,
  putData,
  deleteData,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

//import colors
import ColorCodes from "data/Prod/ColorCodeForAgenda.json";

//import components
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";
import { shuffleColors } from "helper/AgendaHelper/AgendaHelper";

const AvailabilityCalendarBody = () => {
  const { t } = useTranslation(); //for translation
  const breadcrumbText = [{ title: t("My Calendar") }];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const localizer = momentLocalizer(moment);

  const [contactInput, setContactInput] = useState("");
  const [contactOptions, setContactOptions] = useState([]);

  const [contactValue, setcontactValue] = useState(null);
  const [selectedContacts, setselectedContacts] = useState([]);
  const [selectedContactIds, setselectedContactIds] = useState([]);

  const [selectedContactList, setSelectedContactList] = useState([]);

  const [agendaLoading, setAgnedaLoading] = useState(false);

  const [calenderAgendaDates, setCalenderAgendaDates] = useState([]);
  const [selectedCalendarAgendaDate, setSelectedCalendarAgendaDate] =
    useState("");
  const [selectedAgendaId, setSelectedAgendaId] = useState(null);

  const [isReset, setIsReset] = useState(false);

  const contactSearchInputHandler = (val) => {
    setContactInput(val);
  };

  //function for get all contacts
  const getAllContacts = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CONTACTS_OPTIONS +
        `?token=${token}&userstring=${contactInput}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setContactOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select contacts
  const contactSelectionHandler = (val) => {
    shuffleColors(ColorCodes);
    setcontactValue(val);

    const updatedUser = val.map((item, index) => {
      const bgColor = findColorCodeByContactValue(selectedContacts, item.value);
      return {
        ...item,
        bgcolor:
          bgColor === "" ? ColorCodes[index % ColorCodes.length] : bgColor,
      };
    });
    setselectedContacts(updatedUser);
    setselectedContactIds(val.map((item) => item.value));
  };

  //get all agenda date specific
  const getAllAgenda = async (isLoading) => {
    if (isLoading) {
      setAgnedaLoading(true);
    }
    const userPublicIp = await getPublicIP();

    try {
      let useraccountArr = [userInfo._id];

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_AGENDA +
        `?token=${token}&userip=${userPublicIp}`;

      if (selectedContactIds.length > 0) {
        const mergedUserAccount = [...useraccountArr, ...selectedContactIds];

        requestUrl += `&useraccount=${mergedUserAccount}`;
      } else {
        requestUrl += `&useraccount=${useraccountArr}`;
      }

      console.log("agenda url", requestUrl);

      const response = await getData(requestUrl);

      console.log("agenda records", response);

      if (isLoading) {
        setAgnedaLoading(false);
      }

      if (response.status) {
        // filter data as per format
        setCalendarEventData(response.data);

        //setCalenderAgendaDates(response.data);

        if (selectedContactIds.length > 0) {
          setSelectedContactList(selectedContacts.map((item) => item));
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // arrange the event data for big calendar
  const setCalendarEventData = (agendaRecords) => {
    let filteredEventData = [];

    if (agendaRecords.length > 0) {
      for (let agenda of agendaRecords) {
        const agendaId = agenda._id;

        //const agendaTitle = agenda.starttime + ' - ' + agenda.endtime;
        const agendaTitle = agenda.description;

        const startAgendaTime = agenda.date + "T" + agenda.starttime + ":00";

        const endAgendaTime = agenda.date + "T" + agenda.endtime + ":00";

        const event = {
          id: agendaId,
          title: agendaTitle,
          start: new Date(startAgendaTime),
          end: new Date(endAgendaTime),
          availability: agenda.availability,
          bgcolor:
            agenda.userid.toString() === userInfo._id.toString()
              ? agenda.availability
                ? "#05843e"
                : "#DF3429"
              : findColorCodeByContactValue(selectedContacts, agenda.userid),
        };

        filteredEventData.push(event);
      }
    }

    // console.log("filteredEventData", filteredEventData);

    setCalenderAgendaDates(filteredEventData);
  };

  //function for search colors in selected contacts
  const findColorCodeByContactValue = (contactArr = [], idValue) => {
    const foundColorCode = contactArr.find((obj) => obj.value === idValue);
    return foundColorCode ? foundColorCode.bgcolor : "";
  };

  // set color to event
  const eventStyleGetter = (event) => {
    const backgroundColor = event.bgcolor; // set green or red
    return { style: { backgroundColor } };
  };

  //function for update agenda
  const onSelectEvent = (event) => {
    setSelectedAgendaId(event.id);

    //show otpconfirm modal
    let myModal = new bootstrap.Modal(
      document.getElementById("saveAccountAgenda")
    );
    myModal.show();
  };

  //create new agenda
  const onSelectSlot = (event) => {
    const dateObj = new Date(event.start);

    // Extract year, month, and day from the date object
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    setSelectedCalendarAgendaDate(formattedDate);

    let myModal = new bootstrap.Modal(
      document.getElementById("saveAccountAgenda")
    );
    myModal.show();
  };
  // ----------------------------------------------------

  const resetSelectHandler = () => {
    shuffleColors(ColorCodes);
    setContactInput("");
    setContactOptions([]);
    setcontactValue(null);
    setselectedContactIds([]);
    setselectedContacts([]);
    setSelectedContactList([]);
    setIsReset(true);
  };

  useEffect(() => {
    if (contactInput.length > 0) {
      getAllContacts();
    }
  }, [contactInput]);

  useEffect(() => {
    if (isReset) {
      getAllAgenda(false);
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    getAllAgenda(false);
  }, []);

  return (
    <div id="content_wrapper">
      <section className="survey-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          <BreadCrumb breadCrumbText={breadcrumbText} />

          <div className="row">
            {/* <!-- ========== Start calendar Section ========== --> */}
            <div className="col-lg-8 mb-4 mb-lg-0">
              <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-4">
                <div className="caender_plugin">
                  <div style={{ height: 500 }}>
                    <Calendar
                      localizer={localizer}
                      events={calenderAgendaDates} // Make sure you're passing events data
                      startAccessor="start"
                      endAccessor="end"
                      //     onSelectEvent={onSelectEvent}
                      //     onSelectSlot={onSelectSlot}
                      selectable
                      style={{ flex: 1 }}
                      eventPropGetter={eventStyleGetter}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ========== End calendar Section ========== --> */}

            {/* <!-- ========== Start contact Section ========== --> */}
            <div className="col-lg-4">
              <div className="border border-gray-300 h-100 rounded-10 shadow-sm overflow-hidden">
                <div className="members_avalability p-3 p-lg-4">
                  <h4 className="fs-md text-black fw-semibold">
                    Contacts Availability
                  </h4>
                  <div className="calendar_srch_filter">
                    <div className="filter_input mb-3">
                      <Select
                        isMulti
                        placeholder="Search by name,surname or email"
                        options={contactOptions}
                        value={contactValue}
                        onChange={(val) => {
                          contactSelectionHandler(val);
                        }}
                        onInputChange={(val) => {
                          contactSearchInputHandler(val);
                        }}
                      />
                    </div>

                    <div className="d-flex align-items-center gap-2">
                      <button
                        className="btn btn-primary"
                        disabled={agendaLoading ? true : false}
                        style={{
                          cursor: agendaLoading ? "not-allowed" : "pointer",
                        }}
                        onClick={() => {
                          getAllAgenda(true);
                        }}
                      >
                        Search Availibility
                        {agendaLoading && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={resetSelectHandler}
                        disabled={agendaLoading ? true : false}
                        style={{
                          cursor: agendaLoading ? "not-allowed" : "pointer",
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className="calendar_user_list_wrapper">
                  {selectedContactList.length > 0 ? (
                    selectedContactList.map((contact, index) => {
                      return (
                        <div
                          className="calendar_user_list_row p-3 d-flex align-items-center gap-3"
                          key={index}
                        >
                          <div
                            className="calendar_user rounded-circle overflow-hidden"
                            style={{ border: `5px solid ${contact.bgcolor}` }}
                          >
                            <img
                              height={35}
                              width={35}
                              className="rounded-circle"
                              src={
                                contact.contactimage === ""
                                  ? assetImages.defaultUser
                                  : url.SERVER_URL + contact.contactimage
                              }
                              alt=""
                            />
                          </div>
                          <div className="calendar_user_info">
                            <h3 className="text-black fw-semibold mb-1">
                              {contact.label}
                            </h3>
                            <h5 className="text-gray fs-sm mb-0">
                              {contact.position} at {contact.company}
                            </h5>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="py-5 d-flex flex-column align-items-center justify-content-center">
                      <img
                        height={250}
                        width={250}
                        src={assetImages.noAlert}
                        alt="noalert"
                      />
                      <h6 className="text-secondary fw-semibold">
                        {" "}
                        Oops... No contact selected
                      </h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <!-- ========== End contact Section ========== --> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AvailabilityCalendarBody;
