import React from "react";
import { useTranslation } from "react-i18next";

const AddTaskPopup = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="addTask"
      aria-labelledby="addTaskLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="addTaskLabel">
          {t("Add Task")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="" className="d-flex flex-column h-100">
          <div className="field-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="questionName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Question Name")}
              </label>
              <h3 className="mb-0">How can you put question here?</h3>
            </div>
            <div className="form-group mb-4">
              <label
                htmlFor="sendBy"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Send By")}
              </label>
              <div className="profile d-flex align-items-center gap-2">
                <div
                  className="avatar rounded-circle overflow-hidden"
                  style={{ width: "35px", height: "35px" }}
                >
                  <img
                    src="/assets/img/dp-1.jpg"
                    alt="Richard Hawail"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <p className="fs-sm fw-semibold mb-0">Richard Hawail</p>
              </div>
            </div>
            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Answer")}
              </label>
              <textarea
                name="answer"
                id="answer"
                cols="30"
                rows="5"
                className="form-control fs-sm shadow-none"
                placeholder={t("Detail answer...")}
              ></textarea>
            </div>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary">
              {t("Submit Answer")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTaskPopup;
