import React from 'react';
import { Link } from 'react-router-dom';

const GroupCompaniesAddHeader = () => {
  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid d-flex align-items-center gap-3 gap-sm-4 px-lg-5 py-2 py-sm-3">
        <Link
          to="/admin/group/companies"
          className="back-btn d-flex flex-shrink-0 align-items-center justify-content-center body-bg text-gray rounded-circle"
        >
          <span className="d-block material-symbols-outlined">arrow_back</span>
        </Link>
        <h3 className="mb-0">Add Company</h3>
        {/* ------------- save now btn ------------- */}
        <button type="submit" className="btn btn-primary ms-auto">
          Save Now
        </button>
      </div>
    </div>
  );
};

export default GroupCompaniesAddHeader;
