/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { assetImages } from "constants";

const SucessStorySuccessModal = ({ afterSuccessModalClose }) => {
  return (
    <div
      className="modal fade"
      id="afterSaveSuccessStoryModal"
      tabIndex="-1"
      aria-labelledby="saveInfoLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center p-5">
            <img
              src={assetImages.congratulationsIcon}
              alt="You have saved a lead successfuly."
              className="img-fluid mb-2"
            />
            <h2 className="mb-2">Congratulations</h2>
            <p className="fs-lg fw-semibold mb-5">
              You have added a successstory successfuly.
            </p>
            <div className="action d-flex justify-content-center gap-3">
              <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-outline-primary"
                onClick={() => {
                  afterSuccessModalClose("list");
                }}
              >
                Go to List
              </Link>
              {/* <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-primary"
                onClick={() => {
                  afterSaveLeadModalClose("details");
                }}
              >
                Preview Lead Details
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SucessStorySuccessModal;
