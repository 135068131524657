/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import GroupListHeader from '../Header/GroupListHeader';
import FilterPopup from 'components/GroupComponents/GroupList/Popup/FilterPopup';

import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import GroupListData from '../../../../data/Groups/GroupListData.json';
import { assetImages } from 'constants';
import GroupListFilterPopup from '../Popup/GroupListFilterPopup';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import { useTranslation } from 'react-i18next';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const GroupListBody = () => {
  // const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem('token');
  const { t, i18n } = useTranslation(); //for translation
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterGroupTitle, setFilterGroupTitle] = useState('');
  const [filterGroupTags, setFilterGroupTags] = useState([]);
  const [filterGroupCity, setfilterGroupCity] = useState('');
  const [filterGroupCountry, setfilterGroupCountry] = useState('');
  const [filterCompanies, setfilterCompanies] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all lead records
  const getAllGroups = async () => {
    setRowSelection({});
    setSelectedGroupIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_GROUPS + `?token=${token}`;

      if (filterGroupTitle != '') {
        requestUrl = requestUrl + `&filtertitle=${filterGroupTitle}`;
      }
      if (filterGroupCity != '') {
        requestUrl = requestUrl + `&filtercity=${filterGroupCity}`;
      }
      if (filterGroupCountry != '') {
        requestUrl = requestUrl + `&filtercountry=${filterGroupCountry}`;
      }
      if (filterDateRange != '') {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }
      if (filterGroupTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterGroupTags}`;
      }

      if (filterCompanies.length > 0) {
        requestUrl = requestUrl + `&filtercompanyid=${filterCompanies}`;
      }

      console.log('url of group list---------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in group list------->', response);

      setIsLoading(false);
      if (response.status) {
        setGroupList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedGroupList = [...groupList];

    const rowIndex = updatedGroupList.findIndex(
      group => group._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedGroupList[rowIndex].rank = value;
      setGroupList(updatedGroupList);
    }
  };

  //function for update rank
  const updateRankHandler = async e => {
    e.preventDefault();

    let responseArr = [];

    //run loop checkbox wise
    for (let groupId of selectedGroupIds) {
      //check if checkbox was checked.If checked then proceed

      //run loop of updated bloglist
      for (let group of groupList) {
        let eventData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (group._id.toString() == groupId.toString()) {
          eventData.rank = group.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_GROUP +
              `/${groupId}` +
              `?token=${token}`;

            const response = await putData(requestUrl, eventData);

            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }

    if (responseArr.length > 0) {
      getAllGroups();
    }
  };

  //function for change status
  const changeStatusHandler = async e => {
    e.preventDefault();

    let responseArr = [];

    for (let groupId of selectedGroupIds) {
      try {
        let eventData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_GROUP +
          `/${groupId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, eventData);

        if (response.status) {
          responseArr.push(response.status);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    if (responseArr.length > 0) {
      getAllGroups();
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setFilterGroupTitle('');
    setfilterGroupCity('');
    setfilterGroupCountry('');
    setFilterGroupTags([]);
    setfilterCompanies([]);
  };

  //use effect for search filter popup
  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterGroupTitle != '' ||
      filterGroupCity != '' ||
      filterGroupCountry != '' ||
      filterGroupTags.length > 0 ||
      filterCompanies.length > 0
    ) {
      getAllGroups();
    }
  }, [
    filterDateRange,
    filterGroupTitle,
    filterGroupCity,
    filterGroupCountry,
    filterGroupTags,
    filterCompanies,
  ]);

  useEffect(() => {
    getAllTags();
    getAllGroups();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t('Title'),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <p className="fs-md fw-semibold mb-1">{row.original.title}</p>
            <p>{row.original.groupmembercount} Members</p>
          </div>
        ),
      },
      {
        accessorKey: 'rank',
        header: t('Rank'),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              width: '120px',
            }}
          >
            <input
              // loading="lazy"
              type="number"
              name="rank"
              id="rank"
              value={row.original.rank}
              onChange={e => rankInputChangeHandler(row, e.target.value)}
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
            />
          </Box>
        ),
      },
      {
        accessorKey: 'moderatorname',
        header: t('Moderator'),
        Cell: ({ row }) => (
          <div className="moderator border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '22px', height: '22px' }}
              >
                <img
                  src={
                    row.original.moderatorimage == ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.moderatorimage
                  }
                  alt="Richard Hawail"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{row.original.moderatorname}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'createdate',
        header: t('Date'),
      },
      {
        accessorKey: 'categorystring',
        header: t('Category'),
      },
      {
        accessorKey: 'tags',
        header: t('Tags'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [groupList, i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedGroupIds(selectedIdsArray);
    } else {
      setSelectedGroupIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_GROUP')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* --- group list filter --- */}
            <GroupListHeader
              reloadList={getAllGroups}
              updateRankHandler={updateRankHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={groupList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      key="edit"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/group/info/${row.id}`}
                      >
                        {t('View Details')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="members"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/group/members/${row.id}`}
                      >
                        {t('View Members')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="gallery"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/group/gallery/${row.id}`}
                      >
                        {t('View Gallery')}
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== modals and popup area === */}
        {/* --- add lable modal --- */}
        <AddLabelModal
          moduleName="group"
          selectedIds={selectedGroupIds}
          afterTagModalClose={() => {
            setTimeout(() => {
              getAllGroups();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* --- filter popup --- */}
        {/* <FilterPopup /> */}
        <GroupListFilterPopup
          tagList={tagList}
          setfilterGroupCity={setfilterGroupCity}
          setfilterGroupCountry={setfilterGroupCountry}
          setFilterDateRange={setFilterDateRange}
          setFilterGroupTags={setFilterGroupTags}
          setFilterGroupTitle={setFilterGroupTitle}
          setfilterCompanies={setfilterCompanies}
        />
        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedGroupIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('group')}
        />
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default GroupListBody;
