import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ProjectLogHeader = ({
  breadCrumbText,
  reloadList,
  changeStatusHandler,
  isProjectModerator,
  title,
  projectMemberStatus,
  projectModStatus,
}) => {
  const { t } = useTranslation(); //for translation
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb
            breadCrumbText={breadCrumbText}
            displayName={`${title}`}
          />
        </div>
        {isProjectModerator ||
        (projectMemberStatus === "1" && projectModStatus === "1") ? (
          <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
            {/* -------------- refresh ----------------- */}
            <Link
              onClick={reloadList}
              to="#"
              className="btn btn-gray d-flex align-items-center"
            >
              <span className="d-block material-symbols-outlined icon-md">
                refresh
              </span>
            </Link>
            <div className="dropdown flex-fill flex-grow-sm-0">
              <button
                className="btn btn-gray text-start w-100 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("Action")}
              </button>
              <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center gap-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addLabelModal"
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      label
                    </span>
                    <span className="d-block">{t("Add Label")}</span>
                  </Link>
                </li>
                {isProjectModerator && (
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center gap-1"
                      to="#"
                      onClick={changeStatusHandler}
                    >
                      <span className="d-block material-symbols-outlined icon-sm">
                        delete
                      </span>
                      <span className="d-block">{t("Delete")}</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <Link
              to="#"
              className="btn btn-gray d-flex align-items-center gap-1"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasLogFilter"
              aria-controls="offcanvasLogFilter"
            >
              <span className="d-block material-symbols-outlined icon-md">
                tune
              </span>
              <span className="d-block">{t("Filter")}</span>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProjectLogHeader;
