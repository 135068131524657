import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import PlanListBody from "components/AdministrationComponents/PlanComponents/PlanListBody/PlanListBody";

const Plans = () => {
  useEffect(() => {
    document.title = "Administration";
  }, []);
  return (
    <main id="app">
      <Header moduleName="administration" />
      <PlanListBody />
    </main>
  );
};

export default Plans;
