/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";
import TicketsListHeader from "../TicketsListHeader/TicketsListHeader";
import { Link } from "react-router-dom";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import TicketsListFilterPopup from "../Popup/TicketsListFilterPopup";
import CSVUploadModal from "../Modals/CSVUploadModal";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";
import ImportModal from "../Modals/ImportModal";
//import AddToListModal from "components/Common/Modal/AddToListModal";

const TicketListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedTicketsIds, setSelectedTicketsIds] = useState([]);

  //filter options
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterOpStartDate, setfilterOpStartDate] = useState("");
  const [filterOpEndDate, setfilterOpEndDate] = useState("");

  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterEvent, setFilterEvent] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterEntrycode, setFilterEntrycode] = useState("");
  const [filterCompanyIds, setFilterCompanyIds] = useState([]);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tickets
  const getTicketsList = async (isFilter = false) => {
    setRowSelection({});
    setSelectedTicketsIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_MY_TICKETS +
        `?token=${token}&userrolestring=${userInfo.role.slug}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterOpStartDate !== "" && filterOpEndDate !== "") {
        requestUrl += `&filteropstartdate=${filterOpStartDate}&filteropenddate=${filterOpEndDate}`;
      }

      if (filterEvent !== "") {
        requestUrl += `&filterevent=${filterEvent}`;
      }

      if (filterCompanyIds.length > 0) {
        requestUrl += `&filtercompanyids=${filterCompanyIds}`;
      }

      if (filterName !== "") {
        requestUrl += `&clientname=${filterName}`;
      }

      if (filterEmail !== "") {
        requestUrl += `&clientemail=${filterEmail}`;
      }

      if (filterLocation !== "") {
        requestUrl += `&location=${filterLocation}`;
      }

      if (filterEntrycode !== "") {
        requestUrl += `&entrycode=${filterEntrycode}`;
      }

      requestUrl += `&isfilter=${isFilter}`;

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setTicketsList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    // for (let selectedId of selectedCompanyIds) {
    //   try {
    //     let statusData = {
    //       status: "0",
    //     };
    //     let requestUrl =
    //       url.API_BASE_URL +
    //       url.API_UPDATE_COMPANY +
    //       `/${selectedId}` +
    //       `?token=${token}`;
    //     const response = await putData(requestUrl, statusData);
    //     if (response.status) {
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // }
    // getAllCompanyList();
  };

  //refresh record
  const refreshRecords = async () => {
    resetFilterData();
    getTicketsList(false);
  };

  //function for reset filter
  const resetFilterData = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterOpStartDate("");
    setfilterOpEndDate("");

    setFilterEvent("");
    setFilterCompanyIds([]);

    setFilterName("");
    setFilterEmail("");
    setFilterLocation("");
    setFilterEntrycode("");
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(() => [
    { accessorKey: "source", header: "Source" },
    { accessorKey: "session_date", header: "Fecha sesión" },
    { accessorKey: "company_name", header: "Organizador" },
    { accessorKey: "producer", header: "Productor" },
    { accessorKey: "group", header: "Agrupación" },
    { accessorKey: "event", header: "Evento" },
    {
      accessorKey: "event_reference",
      header: "Referencia de evento",
      size: 300,
    },
    { accessorKey: "encloser", header: "Recinto" },
    { accessorKey: "session", header: "Sesión" },
    { accessorKey: "sector", header: "Sector" },

    { accessorKey: "access", header: "Acceso" },
    { accessorKey: "seat_row", header: "Fila" },
    { accessorKey: "seat", header: "Asiento" },
    { accessorKey: "zone", header: "Zona no num." },
    { accessorKey: "tier", header: "Tier" },
    { accessorKey: "tier_section", header: "Nombre Tramo (Tier)" },
    { accessorKey: "price_zone", header: "Zona de precio" },
    { accessorKey: "onebox_category", header: "Categoría Onebox" },
    { accessorKey: "own_category", header: "Categoría propia" },
    { accessorKey: "impressions_count", header: "Nº Impresiones" },

    { accessorKey: "access_count", header: "Nº Accesos" },
    { accessorKey: "entrycode", header: "Cod. Entrada" },
    { accessorKey: "fee", header: "Tarifa" },
    { accessorKey: "attendent_type", header: "Individual/Grupos" },
    { accessorKey: "external_entry_code", header: "Cod.Entrada externo" },
    { accessorKey: "external_access", header: "Acceso externo" },
    { accessorKey: "external_reference", header: "Referencia externa" },
    { accessorKey: "external_door", header: "Puerta externa" },
    { accessorKey: "external_zone", header: "Zona externa" },
    { accessorKey: "entry_type", header: "Tipo de entrada" },

    { accessorKey: "name", header: "Nombre" },
    { accessorKey: "surname", header: "Apellidos" },
    { accessorKey: "phone_client", header: "Teléfono cliente" },
    { accessorKey: "email_client", header: "Email cliente" },
    { accessorKey: "address_client", header: "Dirección cliente" },
    { accessorKey: "country_client", header: "País cliente" },
    { accessorKey: "gender_client", header: "Género cliente" },
    { accessorKey: "language_client", header: "Idioma cliente" },
    { accessorKey: "purchasemodule", header: "Module" },
    { accessorKey: "purchasecomponent", header: "Component" },
    {
      accessorKey: "dob_client",
      header: "Fecha de nacimiento cliente",
      size: 300,
    },
    {
      accessorKey: "authorize_communication",
      header: "Autoriza comunicaciones",
      size: 300,
    },

    { accessorKey: "group_name", header: "Nombre Grupo" },
    { accessorKey: "group_attendees", header: "Asistentes grupo" },
    { accessorKey: "group_companions", header: "Acompañantes grupo" },
    { accessorKey: "province", header: "Provincia" },
    { accessorKey: "zipcode", header: "Codigo postal" },
    { accessorKey: "population", header: "Población" },
    { accessorKey: "doc_id", header: "Doc.id" },
    {
      accessorKey: "additional_info",
      header: "Información adicional",
      size: 300,
    },
    {
      accessorKey: "professional_client_name",
      header: "Nombre Cliente Profesional",
      size: 300,
    },
    { accessorKey: "channel_entity", header: "Entidad canal" },

    { accessorKey: "channel", header: "Canal" },
    { accessorKey: "operation_type", header: "Tipo operación" },
    { accessorKey: "operation_code", header: "Código de operación" },
    { accessorKey: "operation_date", header: "Fecha operación" },
    { accessorKey: "operation_time", header: "Hora op." },
    { accessorKey: "parent_operation_type", header: "Tipo ope. Padre" },
    { accessorKey: "parent_operation_code", header: "Cod. Op. Padre" },
    { accessorKey: "method_delivery", header: "Método de entrega" },
    { accessorKey: "sure", header: "Seguro" },
    { accessorKey: "insurance_carrier", header: "Aseguradora" },

    { accessorKey: "policy", header: "Póliza" },
    {
      accessorKey: "professional_client_category",
      header: "Categoría cliente profesional",
      size: 300,
    },
    { accessorKey: "client_type", header: "Tipo de cliente" },
    { accessorKey: "base_price", header: "Precio base" },
    { accessorKey: "automatic_promotion", header: "Promoción Automatica" },
    { accessorKey: "pa_amount", header: "Importe PA" },
    { accessorKey: "discount_client", header: "Descuento cliente" },
    { accessorKey: "dc_activator", header: "Activador DC" },
    { accessorKey: "dc_amount", header: "Importe DC" },
    { accessorKey: "dc_collective", header: "Colectivo DC" },

    { accessorKey: "client_promotion", header: "Promoción cliente" },
    { accessorKey: "pc_activator", header: "Activador PC" },
    { accessorKey: "pc_amount", header: "Importe PC" },
    { accessorKey: "pc_collective", header: "Colectivo PC" },
    {
      accessorKey: "auto_promotion_operation",
      header: "Promoción automática de operación",
      size: 350,
    },
    {
      accessorKey: "name_auto_promotion_operation",
      header: "Nombre promoción automática de operación",
      size: 350,
    },
    {
      accessorKey: "manual_promotion_operation",
      header: "Promoción manual de operación",
      size: 350,
    },
    {
      accessorKey: "name_manual_promotion_operation",
      header: "Nombre promoción manual de operación",
      size: 350,
    },
    {
      accessorKey: "collective_manual_promotion_operation",
      header: "Colectivo promoción manual de operación ",
      size: 350,
    },
    {
      accessorKey: "code_manual_promotion_operation",
      header: "Código promoción manual de operación",
      size: 350,
    },

    { accessorKey: "entrance_price", header: "Precio entrada" },
    { accessorKey: "organizer_surcharge", header: "Recargos organizador" },
    { accessorKey: "channel_surcharge", header: "Recargos canal" },
    { accessorKey: "tax", header: "Impuesto" },
    { accessorKey: "purchase_reference", header: "Referencia de compra" },
    { accessorKey: "pos_code", header: "Código TPV" },
    { accessorKey: "delivery_cost", header: "Gastos de entrega" },
    { accessorKey: "safe_amount", header: "Importe seguro" },
    { accessorKey: "way_to_pay", header: "Forma de pago" },
    { accessorKey: "payment_gateway", header: "Pasarela de pago" },

    { accessorKey: "final_price", header: "Precio final" },
    { accessorKey: "channel_cost", header: "Costes del canal" },
    { accessorKey: "currency", header: "Moneda" },
  ]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTicketsIds(selectedIdsArray);
    } else {
      setSelectedTicketsIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    getTicketsList(false);
    getAllTags();
  }, []);

  useEffect(() => {
    if (
      (filterStartDate !== "" && filterEndDate !== "") ||
      (filterOpStartDate !== "" && filterOpEndDate !== "") ||
      filterEvent != "" ||
      filterCompanyIds.length > 0 ||
      filterLocation != "" ||
      filterName != "" ||
      filterEmail != "" ||
      filterEntrycode != ""
    ) {
      getTicketsList(true);
    }
  }, [
    filterStartDate,
    filterEndDate,
    filterOpStartDate,
    filterOpEndDate,
    filterEvent,
    filterCompanyIds,
    filterLocation,
    filterName,
    filterEmail,
    filterEntrycode,
  ]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_TICKET")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <TicketsListHeader
              reloadList={refreshRecords}
              changeStatusHandler={changeStatusHandler}
            />

            {/* --------- table area --------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={ticketsList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableColumnOrdering={true}
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area ------------- */}
        {/* -------- add label modal ------- */}
        <AddLabelModal
          selectedIds={selectedTicketsIds}
          moduleName="tickets"
          afterTagModalClose={getTicketsList}
        />

        {/* ------- filter popup ------- */}
        <TicketsListFilterPopup
          setfilterStartDate={setfilterStartDate}
          setfilterEndDate={setfilterEndDate}
          setFilterEvent={setFilterEvent}
          setFilterCompanyIds={setFilterCompanyIds}
          setFilterName={setFilterName}
          setFilterEmail={setFilterEmail}
          setFilterLocation={setFilterLocation}
          setFilterEntrycode={setFilterEntrycode}
          setfilterOpStartDate={setfilterOpStartDate}
          setfilterOpEndDate={setfilterOpEndDate}
        />

        {/* CVS upload modal */}
        <CSVUploadModal />

        <ImportModal
          afterModalClose={() => {
            console.log("Success");
          }}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! You don't have privilege to see this content
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default TicketListBody;
