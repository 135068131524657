/* eslint-disable */
import { useState, useContext, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { putData } from "utils/Gateway";
import { useTranslation } from "react-i18next";

import {
  getUserBrowser,
  getPublicIP,
  getTimeStamp,
} from "helper/AuthHelper/AuthHelper";

import { ecosystemSlug } from "Config/Config";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { assetImages } from "constants";

const SubscribeToMail = () => {

  const { t } = useTranslation();

  const params = useParams();

  const SubsribeHandler = async () => {
    try {

      let mailData = {
        mailid: params.id,
      };

      let requestURL = url.API_BASE_URL + url.API_SUBSCRIBE_TO_MAIL;

      const response = await putData(requestURL, mailData);

      console.log(response);

    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(()=>{

    console.log('subscribe to user by mail');
    SubsribeHandler();

  }, [])



  return (
    <main id="app">
      <div id="content_wrapper">
        <div className="authentication-wrapper login min-h-screen py-5 d-flex align-items-center justify-content-center">
      
            <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto text-center">
              
              <div className="unsubdcribe-text">
                <h3 className="mb-3 fw-bold fs-xl">
                  {t('Its Great!')}
                </h3>
                <p className="mb-0 text-gray fs-md">
                  {t('Thanks for confirming your email and subscribing with us')}
                </p>
              </div>
            </div>
       
        </div>
      </div>
    </main>
  );
};

export default SubscribeToMail;
