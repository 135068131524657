import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ActionLogBody from "components/AdministrationComponents/ActionLogComponents/ActionLogBody";

const ActionLogs = () => {
  useEffect(() => {
    document.title = "Administration | Action logs";
  }, []);
  return (
    <main id="app">
      <Header moduleName="administration" />
      <ActionLogBody />
    </main>
  );
};

export default ActionLogs;
