/* eslint-disable */
import Header from 'components/Common/Header/Header';
import PaymentListBody from 'components/PaymentComponents/PaymentListBody/PaymentListBody';
import React, { useEffect } from 'react';

const Payment = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = 'My Payments';
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="payment" />

      {/* --------- body section ------------ */}
      <PaymentListBody />
    </main>
  );
};

export default Payment;
