/* eslint-disable */
import Header from "components/Common/Header/Header";
import InvoiceDetailsBody from "components/InvoiceComponents/InvoiceDetails/InvoiceDetailsBody";
import React, { useEffect } from "react";

const InvoiceDetails = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Invoice";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="invoice" />

      {/* --------- body section ------------ */}
      <InvoiceDetailsBody />
    </main>
  );
};

export default InvoiceDetails;
