import CRMMailTemplateBody from "components/CRMComponents/CRMMailTemplateComponents/CRMMailTemplateBody/CRMMailTemplateBody";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const CrmMailTemplate = () => {
  useEffect(() => {
    document.title = "CRM Mail Template";
  }, []);
  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* ---- body section ---- */}
      <CRMMailTemplateBody />
    </main>
  );
};

export default CrmMailTemplate;
