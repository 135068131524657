import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import SaveProcessBody from "components/CRMComponents/CRMProcessComponents/ProcessDetailsBody/SaveProcessBody";

const SaveProcess = () => {
  useEffect(() => {
    document.title = "Crm | Process Template";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />
      <SaveProcessBody />
    </main>
  );
};

export default SaveProcess;
