import Header from "components/Common/Header/Header";
import MyTaskKanbanBody from "components/MyTaskComponents/MyTaskKanban/MyTaskKanbanBody";
import React, { useEffect } from "react";

const MyTaskKanban = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "My Task";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="mytask" />

      <MyTaskKanbanBody />
    </main>
  );
};

export default MyTaskKanban;
