/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData, deleteData } from "utils/Gateway";

import AddEditEducationModal from "components/Common/Modal/AddEditEducationModal";
import AddEditExperienceModal from "components/Common/Modal/AddEditExperienceModal";
import AfterSaveUserModal from "components/Common/Modal/AfterSaveUserModal";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { appUserHeaderLinks } from "helper/AppUserHelper/AppUserHelper";
import AddEditCetificateModal from "components/Common/Modal/AddEditCetificateModal";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
import AddEditSkillPointModal from "components/Common/Modal/AddEditSkillPointModal";
import AddEditPortfolioLinkModal from "components/Common/Modal/AddEditPortfolioLinkModal";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const AppUserCvBody = () => {
  const { t } = useTranslation(); //for translation

  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = appUserHeaderLinks(params.id, t);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App User"), link: "/admin/appuser/list" },
    { title: t("CV") },
  ];

  const [isSending, setIsSending] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");

  const [summary, setSummary] = useState("");

  /* ----- skill point block requirement start ------*/
  const [skillPointData, setSkillPointData] = useState({
    skill: null,
    skillvalue: null,
    skillname: "",
    points: "",
    experienceyear: "",
  });
  const [skillPointListBlock, setSkillPointListBlock] = useState([]);
  const [skillPointIndex, setSkillPointIndex] = useState(null);
  /* ----- skill point block requirement end ------*/

  /* ----- education block requirement start ------*/
  const [educationData, setEducationData] = useState({
    degree: "",
    year: "",
    grade: "",
    institute: "",
  });
  const [educationListBlock, setEducationListBlock] = useState([]);
  const [educationIndex, setEducationIndex] = useState(null);
  /* ----- education block requirement end ------*/

  /* ----- experience block requirement start ------*/
  const [experienceData, setExperienceData] = useState({
    companyid: null,
    company: "",
    position: "",
    start: "",
    end: "",
    ctc: "",
    responsibilities: "",
  });
  const [experienceListBlock, setExperienceListBlock] = useState([]);
  const [experienceIndex, setExperienceIndex] = useState(null);
  /* ----- experience block requirement end ------*/

  /* ----- Certificates block requirement start ------*/
  const [technicalExperienceData, settechnicalExperienceData] = useState({
    name: "",
    companyvalue: null,
    companyname: "",
    start: "",
    end: "",
    description: "",
    grade: "",
  });
  const [technicalExpBlock, settechnicalExpBlock] = useState([]);
  const [technicalExpIndex, settechnicalExpIndex] = useState(null);
  /* ----- Certificates block requirement end ------*/

  /* ----- portfoilio requiremnet start -----*/
  const [portfolioData, setPortfolioData] = useState("");
  const [portfolioBlock, setPortfolioBlock] = useState([]);
  const [portfolioIndex, setPortfolioIndex] = useState(null);
  /* ----- portfoilio requiremnet end -----*/

  //requriement for right panel
  const [userEducations, setuserEducations] = useState([]);
  const [userExperiences, setuserExperiences] = useState([]);
  const [userCertificates, setuserCertificates] = useState([]);

  //out of field requirements
  const [telephone, setTelephone] = useState("");
  const [position, setPosition] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [website, setWebsite] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");

  //function for get all company list
  const getAllCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_COMPANY_LIST + `?token=${token}`;

      console.log("url of company list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company list------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for edit eduction block
  const updateEductionBlock = (education, index) => {
    setEducationIndex(index);
    setEducationData(education);
  };

  //function for delete eduction block
  const onDeleteEducationBlock = (index) => {
    const filteredEducationBlock = [...educationListBlock];
    filteredEducationBlock.splice(index, 1);
    setEducationListBlock(filteredEducationBlock);
  };

  //function for view education blocks
  const getEducationBlock = (educations) => {
    const educationArr = educations.map((education) => {
      return {
        _id: education?._id,
        degree: education?.degree,
        grade: education?.grade,
        year: education?.year,
        institute: education?.institute,
      };
    });
    setEducationListBlock(educationArr);
  };

  //function for edit experience block
  const updateExperienceBlock = (experience, index) => {
    setExperienceIndex(index);
    setExperienceData(experience);
  };

  //function for delete experience block
  const onDeleteExperienceBlock = (index) => {
    const filteredExperienceBlock = [...experienceListBlock];
    filteredExperienceBlock.splice(index, 1);
    setExperienceListBlock(filteredExperienceBlock);
  };

  //function for view experience blocks
  const getExperienceBlock = (experiences) => {
    let experienceArr = [];
    experiences.map((experience) => {
      let expSavedData = {
        _id: experience?._id,
        companyid: experience.companyid,
        company: experience?.company,
        end: experience?.end,
        position: experience?.position,
        start: experience?.start,
        ctc: experience?.ctc,
        responsibilities: experience?.responsibilities,
      };
      experienceArr.push(expSavedData);
    });
    setExperienceListBlock(experienceArr);
  };

  //function for edit technical experence block
  const updateTechnicalExpBlock = (techExp, index) => {
    settechnicalExperienceData(techExp);
    settechnicalExpIndex(index);
  };

  //delete tech exp block
  const onDeleteTechExpBlock = (index) => {
    const filteredTechExpBlock = [...technicalExpBlock];
    filteredTechExpBlock.splice(index, 1);
    settechnicalExpBlock(filteredTechExpBlock);
  };

  //function for view tech exp blocks
  const getTechExpBlock = (techExp) => {
    let techExpArr = [];
    techExp.map((techExpData) => {
      const companyvalue = {
        label: techExpData?.companyname,
        value: techExpData?.companyname,
      };
      let certificateSavedData = {
        _id: techExpData?._id,
        name: techExpData?.name,
        companyname: techExpData?.companyname,
        companyvalue,
        start: techExpData?.start,
        end: techExpData?.end,
        description: techExpData?.description,
        grade: techExpData?.grade,
      };
      techExpArr.push(certificateSavedData);
    });
    settechnicalExpBlock(techExpArr);
  };

  //update skillpoint block
  const updateSkillPointBlock = (skillpoint, index) => {
    setSkillPointIndex(index);
    setSkillPointData(skillpoint);
  };

  //function for delete skillpoint block
  const onDeleteSkillPointBlock = (index) => {
    const filteredSkillPointBlock = [...skillPointListBlock];
    filteredSkillPointBlock.splice(index, 1);
    setSkillPointListBlock(filteredSkillPointBlock);
  };

  //function for view skillpoint blocks
  const getSkillPointsBlock = (skillPoints) => {
    const skillPintArr = skillPoints.map((skillPoint) => {
      let skillPointSavedData = {
        _id: skillPoint?._id,
        skill: skillPoint?.skill,
        skillvalue: skillPoint?.skillvalue,
        skillname: skillPoint?.skillname,
        points: skillPoint?.points,
        experienceyear: skillPoint?.experienceyear,
      };
      return skillPointSavedData;
    });
    setSkillPointListBlock(skillPintArr);
  };

  //function for edit eduction block
  const updatePortfolioBlock = (portfolio, index) => {
    setPortfolioIndex(index);
    setPortfolioData(portfolio);
  };

  //function for delete eduction block
  const onDeletePortfolioBlock = (index) => {
    const filteredPortfolioBlock = [...portfolioBlock];
    filteredPortfolioBlock.splice(index, 1);
    setPortfolioBlock(filteredPortfolioBlock);
  };

  //function for get user details
  const getAppUserDetails = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}&userip=${userPublicIp}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;
        setName(data.name || "");
        setSurName(data.surname || "");

        setTelephone(data.phone ?? "");
        setPosition(data.position ?? "");
        setAddress(data.address || "");
        setWebsite(data.website || "");
        setRate(data.costperhour || "");
        setstateName(data.state || "");
        setcityName(data.city || "");
        setcountry(data.country || "");
        setzipcode(data.zipcode || "");

        setSummary(data.summary || "");
        getEducationBlock(data.educationids);
        setuserEducations(data.educationids);
        getExperienceBlock(data.experienceids);
        setuserExperiences(data.experienceids);
        getTechExpBlock(data.certifications);
        setPortfolioBlock(data.portfoliolink || []);

        /*------ assing skillpoints block start -----*/

        if (response.data.skillpointidsextended.length > 0) {
          getSkillPointsBlock(data.skillpointidsextended);
        }
        /*------ assing skillpoints block end -----*/
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save/update user
  const saveUserHandler = async () => {
    const userPublicIp = await getPublicIP();

    try {
      setIsSending(true);

      let userData = {
        position,
        phone: telephone,
        address,
        city: cityName,
        state: stateName,
        country,
        zipcode,
        costperhour: rate,
        website,

        summary,
        educations: educationListBlock,
        experiences: experienceListBlock,
        certifications: technicalExpBlock,
        skillpointids: skillPointListBlock,
        portfoliolink: portfolioBlock,
      };

      // console.log(userData);

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl =
          requestUrl +
          url.API_UPDATE_APP_USER +
          `/${params.id}` +
          `?token=${token}&userip=${userPublicIp}`;
        response = await putData(requestUrl, userData);
      }

      console.log(response);
      setIsSending(false);

      if (response.status) {
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("afterSaveUserModal")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for afterSaveModalClose
  const afterSaveModalClose = (pageName) => {
    if (pageName == "details") {
      getAppUserDetails();
    } else {
      // resetCVFormHandler();
      history.push("/admin/appuser/list");
    }
  };

  //function for reset handler
  // const resetCVFormHandler = () => {
  //   setEducationData({
  //     degree: "",
  //     year: "",
  //     grade: "",
  //     institute: "",
  //   });
  //   setEducationListBlock([]);
  //   setEducationIndex(null);
  //   setExperienceData({
  //     companyid: null,
  //     company: "",
  //     position: "",
  //     start: "",
  //     end: "",
  //     ctc: "",
  //     responsibilities: "",
  //   });
  //   setExperienceListBlock([]);
  //   setExperienceIndex(null);
  //   settechnicalExperienceData({
  //     name: "",
  //     companyvalue: null,
  //     companyname: "",
  //     start: "",
  //     end: "",
  //     description: "",
  //     grade: "",
  //   });
  //   settechnicalExpBlock([]);
  //   settechnicalExpIndex(null);

  //   setSkillPointListBlock([]);
  //   setSkillPointIds([]);
  //   setSkillPointIndex(null);
  // };

  useEffect(() => {
    if (params.id) {
      getAllCompanyList();
      getAppUserDetails();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ---- expert details common header ---- */}
        {/* {params.id && <ExpertDetailsCommonHeader moduleName="cv" />} */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("CV")}
          />
        )}

        {/* <!-- ========== Start main form Section ========== --> */}
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---- bread crumb ------- */}

          {/* <!-- ========== Start breadcrumb component ========== --> */}
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            bottom={true}
            displayName={`${name} ${surName}`}
          />
          {/* <!-- ========== End breadcrumb component ========== --> */}

          {/* <!-- ========== Start form Section ========== --> */}
          <div className="row">
            {/* <!-- ========== Start form left panel ========== --> */}
            <div className="col-lg-9 mb-4 mb-lg-0">
              <div className="p-3 p-lg-4 border border-gray-300 h-100 rounded-10 shadow-sm">
                <h3>{t("Brief about your experience (in 150 characters)")}</h3>
                <div className="form-group mb-3">
                  <textarea
                    className="form-control"
                    rows={6}
                    placeholder={t("Summary")}
                    value={summary}
                    onChange={(e) => {
                      setSummary(e.target.value);
                    }}
                  ></textarea>
                </div>

                {/* <!-- ========== Start accordion Section ========== --> */}
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  {/* ------ skillpoint accordion section start ------ */}
                  <div className="accordion-item bg-transparent border-0">
                    {/* ------ toggle accordion button start ------ */}
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseSix"
                      >
                        {t("Skill Points")}
                      </button>
                    </h3>
                    {/* ------ toggle accordion button end ------ */}
                    {/* ----- skill point block list section start ----- */}
                    <div
                      id="panelsStayOpen-collapseSix"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="fields-group">
                          <div className="fields d-flex flex-column gap-3">
                            {/* ----- skill point list section start ------ */}
                            {skillPointListBlock.map((skillBlock, index) => {
                              return (
                                <div className="field" key={index}>
                                  <div className="form-group">
                                    <div className="row">
                                      {/* ------ skill name section start ------ */}
                                      <div className="col-lg-3 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="skill"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Skill")}
                                          </label>
                                          <p className="form-control border-0 rounded-0 shadow-none">
                                            {skillBlock?.skillname}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ------ skill name section end ------ */}
                                      {/* ------ skill point section start ------ */}
                                      <div className="col-lg-4">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="point"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Point")}
                                          </label>
                                          <p className="form-control border-0 rounded-0 shadow-none">
                                            {skillBlock.points}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ------ skill point section end ------ */}
                                      {/* ------ experienceyear point section start ------ */}
                                      <div className="col-lg-4">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="point"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Total Experience")}
                                          </label>
                                          <p className="form-control border-0 rounded-0 shadow-none">
                                            {skillBlock.experienceyear}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ------ experienceyear point section end ------ */}
                                      {/* ----- edit and delete icon section start ------ */}
                                      <div className="col-lg-1 action d-flex align-items-center justify-content-end gap-3 mb-3">
                                        {/* ----- edit icon start ------ */}
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#addSkillPointModal"
                                          onClick={() => {
                                            updateSkillPointBlock(
                                              skillBlock,
                                              index
                                            );
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            edit
                                          </span>
                                        </Link>
                                        {/* ----- edit icon end ------ */}
                                        {/* ----- delete icon start ------ */}
                                        <button
                                          className="d-flex align-items-center gap-1 fs-md fw-semibold text-black border-0 bg-white"
                                          // data-bs-toggle="modal"
                                          // data-bs-target="#deletewarning"
                                          onClick={() => {
                                            onDeleteSkillPointBlock(index);
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            delete
                                          </span>
                                        </button>
                                        {/* ----- delete icon end ------ */}
                                      </div>
                                      {/* ----- edit and delete icon section end ------ */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* ----- skill point list section end ------ */}
                          </div>
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#addSkillPointModal"
                            onClick={() => {
                              setSkillPointIndex(null);
                            }}
                            className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
                          >
                            <span className="d-block material-symbols-outlined">
                              add
                            </span>
                            <span className="d-block">
                              {t("Add Skill Points")}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ------ skillpoint accordion section end ------ */}

                  {/* <!-- ========== Start eduction accordion Section ========== --> */}
                  <div className="accordion-item bg-transparent border-0">
                    {/* ------ toggle accordion button start ------ */}
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        {t("Education")}
                      </button>
                    </h3>
                    {/* ------ toggle accordion button end ------ */}
                    {/* ----- eduction block list section start ----- */}
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="fields-group">
                          {/* ------ education list section start ------ */}
                          <div className="fields d-flex flex-column gap-3">
                            {educationListBlock.map((education, index) => {
                              return (
                                <div className="field" key={index}>
                                  <div className="form-group">
                                    <div className="row">
                                      {/* ------ degree section start ------ */}
                                      <div className="col-lg-3 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Degree")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {education.degree}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ------ degree section end ------ */}
                                      {/* ------ year section start ------ */}
                                      <div className="col-lg-2 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Year")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {education.year}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ------ year section end ------ */}
                                      {/* ------ grade section start ------ */}
                                      <div className="col-lg-2 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Grade")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {education.grade}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ------ grade section end ------ */}
                                      {/* ------ institute section start ------ */}
                                      <div className="col-lg-4">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Institute")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {education.institute}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ------ institute section end ------ */}
                                      {/* ------ edit and delete icon section start ------ */}
                                      <div className="col-lg-1 action d-flex align-items-center justify-content-end gap-3 mb-3">
                                        {/* ------ edit icon start ------ */}
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#addEducationModal"
                                          onClick={() => {
                                            updateEductionBlock(
                                              education,
                                              index
                                            );
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            edit
                                          </span>
                                        </Link>
                                        {/* ------ edit icon end ------ */}
                                        {/* ------ delete icon start ------ */}
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            onDeleteEducationBlock(index);
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            delete
                                          </span>
                                        </Link>
                                        {/* ------ delete icon end ------ */}
                                      </div>
                                      {/* ------ edit and delete icon section end ------ */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* ------ education list section end ------ */}
                          {/* ------ add education modal button start ------ */}
                          <Link
                            to="#"
                            className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
                            data-bs-toggle="modal"
                            data-bs-target="#addEducationModal"
                            onClick={() => {
                              setEducationIndex(null);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              add
                            </span>
                            <span className="d-block">
                              {t("Add Education")}
                            </span>
                          </Link>
                          {/* ------ add education modal button end ------ */}
                        </div>
                      </div>
                    </div>
                    {/* ----- eduction block list section end ----- */}
                  </div>
                  {/* <!-- ========== End eduction accordion Section ========== --> */}

                  {/* ------ education accordion section end ------ */}

                  {/* ------ experience accordion section start ------ */}
                  <div className="accordion-item bg-transparent border-0">
                    {/* ------ toggle accordion button start ------ */}
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseFour"
                      >
                        {t("Experience")}
                      </button>
                    </h3>
                    {/* ------ toggle accordion button end ------ */}
                    {/* ----- experience block list section start ----- */}
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="fields-group">
                          <div className="fields d-flex flex-column gap-3">
                            {/* ------ experience list section start ------ */}
                            {experienceListBlock.map((experience, index) => {
                              return (
                                <div className="field" key={index}>
                                  <div className="form-group">
                                    <div className="row">
                                      {/* ----- company section start ----- */}
                                      <div className="col-lg-3 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Company")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {experience.companyid
                                              ? experience.companyid.label
                                              : experience.company != ""
                                              ? experience.company
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- company section end ----- */}

                                      {/* ----- position section start ----- */}
                                      <div className="col-lg-4 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Position")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {experience.position}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- position section end ----- */}

                                      {/* ----- start year section start ----- */}
                                      <div className="col-lg-2 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Start Year")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {experience.start}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- start year section end ----- */}

                                      {/* ----- end year section start ----- */}
                                      <div className="col-lg-2">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("To Year")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {experience.end}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- end year section end ----- */}
                                      {/* ------ edit and delete icon section start ------ */}
                                      <div className="col action d-flex align-items-center justify-content-end gap-3 mb-3">
                                        {/* ------ edit icon start ------ */}
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#addExperienceModal"
                                          onClick={() => {
                                            updateExperienceBlock(
                                              experience,
                                              index
                                            );
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            edit
                                          </span>
                                        </Link>
                                        {/* ------ edit icon end ------ */}
                                        {/* ------ delete icon start ------ */}
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            onDeleteExperienceBlock(index);
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            delete
                                          </span>
                                        </Link>
                                        {/* ------ delete icon end ------ */}
                                      </div>
                                      {/* ------ edit and delete icon section end ------ */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* ------ experience list section end ------ */}
                          </div>
                          {/* ------ add experience modal button start ------ */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#addExperienceModal"
                            onClick={() => {
                              setExperienceIndex(null);
                            }}
                            className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
                          >
                            <span className="d-block material-symbols-outlined">
                              add
                            </span>
                            <span className="d-block">
                              {t("Add Experience")}
                            </span>
                          </Link>
                          {/* ------ add experience modal button end ------ */}
                        </div>
                      </div>
                    </div>
                    {/* ----- experience block list section end ----- */}
                  </div>
                  {/* ------ experience accordion section end ------ */}

                  {/* technical certificate */}
                  <div className="accordion-item bg-transparent border-0">
                    {/* ------ toggle accordion button start ------ */}
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseFive"
                      >
                        {t("Certificate")}
                      </button>
                    </h3>
                    {/* ------ toggle accordion button end ------ */}
                    {/* ----- experience block list section start ----- */}
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="fields-group">
                          <div className="fields d-flex flex-column gap-3">
                            {/* ------ experience list section start ------ */}
                            {technicalExpBlock.map((techExpData, index) => {
                              return (
                                <div className="field" key={index}>
                                  <div className="form-group">
                                    <div className="row">
                                      {/* ----- course section start ----- */}
                                      <div className="col-lg-2 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Course")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {techExpData.name}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- course section end ----- */}
                                      {/* ----- company section start ----- */}
                                      <div className="col-lg-3 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("Company")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {techExpData.companyname}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- company section end ----- */}
                                      {/* ----- start date section start ----- */}
                                      <div className="col-lg-3 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("From")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {getDateFormatInSpanish(
                                              techExpData.start
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- start date section end ----- */}
                                      {/* ----- end date section start ----- */}
                                      <div className="col-lg-3">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <label
                                            htmlFor="degree"
                                            className="text-nowrap me-3"
                                          >
                                            {t("To")}
                                          </label>
                                          <p className="form-control border-0 shadow-none">
                                            {getDateFormatInSpanish(
                                              techExpData.end
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- end date section end ----- */}
                                      {/* ------ edit and delete icon section start ------ */}
                                      <div className="col action d-flex align-items-center justify-content-end gap-3 mb-3">
                                        {/* ------ edit icon start ------ */}
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#addCertificateModal"
                                          onClick={() => {
                                            updateTechnicalExpBlock(
                                              techExpData,
                                              index
                                            );
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            edit
                                          </span>
                                        </Link>
                                        {/* ------ edit icon end ------ */}
                                        {/* ------ delete icon start ------ */}
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            onDeleteTechExpBlock(index);
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            delete
                                          </span>
                                        </Link>
                                        {/* ------ delete icon end ------ */}
                                      </div>
                                      {/* ------ edit and delete icon section end ------ */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {/* ------ experience list section end ------ */}
                          </div>
                          {/* ------ add experience modal button start ------ */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#addCertificateModal"
                            onClick={() => {
                              settechnicalExpIndex(null);
                            }}
                            className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
                          >
                            <span className="d-block material-symbols-outlined">
                              add
                            </span>
                            <span className="d-block">
                              {t("Add Certificate")}
                            </span>
                          </Link>
                          {/* ------ add experience modal button end ------ */}
                        </div>
                      </div>
                    </div>
                    {/* ----- experience block list section end ----- */}
                  </div>

                  {/* <!-- ========== Start portfolio Section ========== --> */}
                  <div className="accordion-item bg-transparent border-0">
                    {/* ------ toggle accordion button start ------ */}
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSummary"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseSummary"
                      >
                        {t("Portfolio Links")}
                      </button>
                    </h3>
                    {/* ------ toggle accordion button end ------ */}

                    <div
                      id="panelsStayOpen-collapseSummary"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="fields-group">
                          <div className="fields d-flex flex-column gap-3">
                            {/* ------ links start ------ */}
                            {portfolioBlock.map((portfolioBlockData, index) => {
                              return (
                                <div className="field" key={index}>
                                  <div className="form-group">
                                    <div className="row align-items-center">
                                      {/* ----- course section start ----- */}
                                      <div className="col-lg-11 mb-3 mb-lg-0">
                                        <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                          <p className="form-control border-0 shadow-none">
                                            {portfolioBlockData}
                                          </p>
                                        </div>
                                      </div>
                                      {/* ----- course section end ----- */}

                                      {/* ------ edit and delete icon section start ------ */}
                                      <div className="col-lg-1 action d-flex align-items-center justify-content-end gap-3 mb-3">
                                        {/* ------ edit icon start ------ */}
                                        <Link
                                          to="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#savePortfolioLinkModal"
                                          onClick={() => {
                                            updatePortfolioBlock(
                                              portfolioBlockData,
                                              index
                                            );
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            edit
                                          </span>
                                        </Link>
                                        {/* ------ edit icon end ------ */}
                                        {/* ------ delete icon start ------ */}
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            onDeletePortfolioBlock(index);
                                          }}
                                        >
                                          <span className="d-block material-symbols-outlined">
                                            delete
                                          </span>
                                        </Link>
                                        {/* ------ delete icon end ------ */}
                                      </div>
                                      {/* ------ edit and delete icon section end ------ */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                            {/* ------ experience list section end ------ */}
                          </div>

                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#savePortfolioLinkModal"
                            onClick={() => {
                              setPortfolioIndex(null);
                            }}
                            className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
                          >
                            <span className="d-block material-symbols-outlined">
                              add
                            </span>
                            <span className="d-block">
                              {t("Add Portfolio Link")}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- ========== End portfolio Section ========== --> */}
                </div>
                {/* <!-- ========== End accordion Section ========== --> */}

                {/* <!-- ========== Start save button Section ========== --> */}
                <div className="mt-5 action d-flex flex-wrap gap-3 justify-content-between">
                  <button
                    className="btn btn-primary d-inline-flex align-items-center gap-1"
                    onClick={saveUserHandler}
                    style={{ cursor: isSending ? "not-allowed" : "pointer" }}
                    disabled={isSending ? true : false}
                  >
                    {isSending ? (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <span className="d-block material-symbols-outlined">
                        save
                      </span>
                    )}
                    <span className="d-block">{t("Save CV")}</span>
                  </button>
                </div>
                {/* <!-- ========== End save button Section ========== --> */}
              </div>
            </div>

            {/* <!-- ========== End form left panel ========== --> */}

            {/* <!-- ========== Start form right panel ========== --> */}
            <div className="col-lg-3">
              <div className="p-3 p-lg-4 border border-gray-300 h-100 rounded-10 shadow-sm">
                {/* <!-- ========== Start eduction Section ========== --> */}
                <div className="mb-4">
                  <p className="fw-bold mb-3">{t("Educations")}</p>
                  {userEducations.map((education, index) => {
                    return (
                      <ul className="work-experience-flow" key={index}>
                        <li className="step position-relative active">
                          <p className="fs-sm fw-semibold mb-2">
                            {education.degree}
                          </p>
                          <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-3">
                            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                              <span className="d-block material-symbols-outlined icon-md">
                                school
                              </span>
                              <span className="d-block">
                                {education.institute}
                              </span>
                            </li>
                            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                              <span className="d-block material-symbols-outlined icon-md">
                                event_note
                              </span>
                              <span className="d-block">{education.year}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    );
                  })}
                </div>
                {/* <!-- ========== End eduction Section ========== --> */}

                {/* <!-- ========== Start experience Section ========== --> */}
                <div className="mb-4">
                  <p className="fw-bold mb-2">{t("Experiences")}</p>
                  {userExperiences.map((experience, index) => {
                    return (
                      <ul className="work-experience-flow" key={index}>
                        <li className="step position-relative active">
                          <p className="fs-sm fw-semibold mb-2">
                            {experience.company}
                          </p>
                          <ul className="tags d-flex gap-2 flex-wrap fs-sm lh-1 mb-3">
                            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                              <span className="d-block material-symbols-outlined icon-md">
                                store
                              </span>
                              <span className="d-block">
                                {experience.position}
                              </span>
                            </li>
                            <li className="tag d-flex align-items-center gap-2 gradient-light py-2 px-3 rounded-90">
                              <span className="d-block material-symbols-outlined icon-md">
                                event_note
                              </span>
                              <span className="d-block">
                                {experience.start} - {experience.end}
                              </span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    );
                  })}
                </div>
                {/* <!-- ========== End experience Section ========== --> */}
              </div>
            </div>
            {/* <!-- ========== End form right panel ========== --> */}
          </div>
          {/* <!-- ========== End form Section ========== --> */}
        </div>
        {/* <!-- ========== End main form Section ========== --> */}

        {/* ------ add/edit skillpoint modal ----- */}
        <AddEditSkillPointModal
          setSkillPointData={setSkillPointData}
          setSkillPointIndex={setSkillPointIndex}
          setSkillPointListBlock={setSkillPointListBlock}
          skillPointData={skillPointData}
          skillPointIndex={skillPointIndex}
          skillPointListBlock={skillPointListBlock}
        />
        {/* ------ add/edit education modal ----- */}
        <AddEditEducationModal
          educationIndex={educationIndex}
          setEducationIndex={setEducationIndex}
          educationData={educationData}
          setEducationData={setEducationData}
          educationListBlock={educationListBlock}
          setEducationListBlock={setEducationListBlock}
        />
        {/* ------ add/edit experience modal ----- */}
        <AddEditExperienceModal
          companyList={companyList}
          experienceData={experienceData}
          setExperienceData={setExperienceData}
          experienceListBlock={experienceListBlock}
          setExperienceListBlock={setExperienceListBlock}
          experienceIndex={experienceIndex}
          setExperienceIndex={setExperienceIndex}
        />
        <AddEditCetificateModal
          technicalExpIndex={technicalExpIndex}
          settechnicalExpIndex={settechnicalExpIndex}
          technicalExperienceData={technicalExperienceData}
          settechnicalExperienceData={settechnicalExperienceData}
          technicalExpBlock={technicalExpBlock}
          settechnicalExpBlock={settechnicalExpBlock}
        />

        <AddEditPortfolioLinkModal
          portfolioIndex={portfolioIndex}
          setPortfolioIndex={setPortfolioIndex}
          portfolioData={portfolioData}
          setPortfolioData={setPortfolioData}
          portfolioBlock={portfolioBlock}
          setPortfolioBlock={setPortfolioBlock}
        />
        <AfterSaveUserModal
          userId={params.id}
          afterSaveModalClose={afterSaveModalClose}
          moduleName={"CV"}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setSkillPointIndex(null);
            setSkillIdForDelete(null);
          }}
          onDelete={onDeleteSkillPointBlock}
          moduleName={t("skillpoint")}
        />
      </section>
    </div>
  );
};
export default AppUserCvBody;
