import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import TagListBody from "components/AdministrationComponents/TagComponents/TagListBody/TagListBody";

const Tags = () => {
  useEffect(() => {
    document.title = "Administration | Tags";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <TagListBody />
    </main>
  );
};

export default Tags;
