import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const EcoleadAccordion = ({
  validation = {},
  setValidation,
  meetingTypeList = [],
  previousRelationList = [],
  possibleServiceList = [],
  ecoleadsshowoptions,
  shareLeadsValue = null,
  shareSelectionHandler,
  searching = "",
  setsearching,
  interviewCost = "",
  setinterviewCost,
  comments = "",
  setcomments,
  opportunityTitle = "",
  setopportunityTitle,
  responsibility = "",
  setresponsibility,
  meetingTypeValue = null,
  meetingTypeSelectionHandler,
  relationshipValue = null,
  relationshipSelectionHandler,
  possibleServiceValue = null,
  serviceSelectionHandler,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseTen"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseTen"
        >
          {t("Ecoleads")}
        </button>
      </h3>
      <div
        id="panelsStayOpen-collapseTen"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          {/* ------ select share with section start ----- */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center py-1 ps-3 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Show this lead with")}
              </label>
              <Select
                isClearable
                className="w-100"
                placeholder={t("Select share this leads with")}
                options={ecoleadsshowoptions}
                value={shareLeadsValue}
                onChange={(val) => {
                  shareSelectionHandler(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    searchingWarning: false,
                    costWarning: false,
                    commentWarning: false,
                    responsibilityWarning: false,
                    meetingTypeWarning: false,
                    relationshipWarning: false,
                    serviceTypeWarning: false,
                    opportunityTitleWarning: false,
                  }));
                }}
              />
            </div>
          </div>
          {/* ------ select share with section end ----- */}

          {/* ------ searching section start ------ */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Searching")}
              </label>
              <input
                type="text"
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Add searching")}
                value={searching}
                onChange={(e) => {
                  setsearching(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    searchingWarning: false,
                  }));
                }}
              />
            </div>
            {validation.searchingWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a searching")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------ searching section end ------ */}
          {/* ------ cost section start ------ */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="costinterview" className="text-nowrap me-3">
                {t("Cost of interview")}
              </label>
              <input
                type="number"
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Enter cost")}
                value={interviewCost}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setinterviewCost(e.target.value);
                  }

                  setValidation((prevState) => ({
                    ...prevState,
                    costWarning: false,
                  }));
                }}
              />
            </div>
            {validation.costWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a interview cost")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------ linkdin section end ------ */}
          {/* ------ comments section start ------ */}
          <div className="form-group mb-3">
            <textarea
              name="resumeSummery"
              id="resumeSummery"
              cols="30"
              rows="5"
              className="form-control border-0 shadow-none"
              placeholder={t("Comments")}
              value={comments}
              onChange={(e) => {
                setcomments(e.target.value);

                setValidation((prevState) => ({
                  ...prevState,
                  commentWarning: false,
                }));
              }}
            ></textarea>
            {validation.commentWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a comments")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ------ comments section end ------ */}

          {/* <!-- ========== Start Responsibility title Section ========== --> */}
          <div className="form-group mb-3">
            <textarea
              cols="30"
              rows="5"
              className="form-control border-0 shadow-none"
              placeholder={t("Responsibility")}
              value={responsibility}
              onChange={(e) => {
                setresponsibility(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  responsibilityWarning: false,
                }));
              }}
            ></textarea>
            {validation.responsibilityWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a responsibility")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End Responsibility title Section ========== --> */}

          {/* <!-- ========== Start meeting type Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center gap-1 ps-3 py-1 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-5">
                {t("Meeting Type")}
              </label>

              <Select
                className="w-100 ms-4"
                placeholder={t("Select Meeting Type")}
                options={meetingTypeList}
                isMulti
                value={meetingTypeValue}
                onChange={(val) => {
                  meetingTypeSelectionHandler(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    meetingTypeWarning: false,
                  }));
                }}
              />
            </div>
            {validation.meetingTypeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select meeting type")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End meeting type Section ========== --> */}

          {/* <!-- ========== Start relationship Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Previous Relationship")}
              </label>
              <Select
                className="w-100 ms-1"
                placeholder={t("Select Relationship")}
                options={previousRelationList}
                isMulti
                value={relationshipValue}
                onChange={(val) => {
                  relationshipSelectionHandler(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    relationshipWarning: false,
                  }));
                }}
              />
            </div>
            {validation.relationshipWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select relationship")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End relationship Section ========== --> */}

          {/* <!-- ========== Start service Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-5">
                {t("Possible Services")}
              </label>
              <Select
                className="w-100"
                placeholder={t("Select Services")}
                options={possibleServiceList}
                isMulti
                value={possibleServiceValue}
                onChange={(val) => {
                  serviceSelectionHandler(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    serviceTypeWarning: false,
                  }));
                }}
              />
            </div>
            {validation.serviceTypeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select service type")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End service Section ========== --> */}

          {/* <!-- ========== Start opoortunity title Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Opportunity Title")}
              </label>
              <input
                type="text"
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Enter Opportunity Title")}
                value={opportunityTitle}
                onChange={(e) => {
                  setopportunityTitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    opportunityTitleWarning: false,
                  }));
                }}
              />
            </div>
            {validation.opportunityTitleWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter opportunity title")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End opoortunity title Section ========== --> */}
        </div>
      </div>
    </div>
  );
};

export default EcoleadAccordion;
