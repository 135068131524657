/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData, uploadMultipleFile } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import GroupGalleryHeader from "../GroupGalleryHeader/GroupGalleryHeader";
import DeleteModal from "../Modals/DeleteModal";
import EmptyImageModal from "../Modals/EmptyImageModal";
import GallerySuccesModal from "../Modals/GallerySuccesModal";
import { groupDetailsHeaderLinks } from "helper/GroupHelper/GroupHelper";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const GroupGalleryBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  // -------- tabs header object --------------------
  const commonHeaderObject = groupDetailsHeaderLinks(params.id, t);

  const [isLoading, setIsLoading] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const imageFileInputRef = useRef(null);

  const [uploadedPreviewImages, setUploadedPreviewImages] = useState([]);
  const [uploadedImageIds, setUploadedImageIds] = useState([]); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);

  const [deleteImageId, setDeleteImageId] = useState(null);

  const [addSuccess, setAddSuccess] = useState(false);

  //function for get event details
  const getGroupDetails = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of event gallery------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of event gallery------>", response);

      setIsLoading(false);
      if (response.status && response.data) {
        setGalleryImages(
          response.data.gallery.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files;
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, file);

      // console.log("upload response-------->", response);

      setIsUploading(false);
      resetGalleryImageFile();
      if (response.status) {
        setUploadedImageIds(response.data._id);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedImageValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new images will concat with old images
        if (uploadedPreviewImages.length == 0) {
          setUploadedPreviewImages(uploadedImageValues);
        } else {
          let fileArr = uploadedPreviewImages.concat(uploadedImageValues);
          setUploadedPreviewImages(fileArr);
        }
        /*----- assign uploaded images with path for display end ------*/

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedImageIds.length == 0) {
          setUploadedImageIds(fileIds);
        } else {
          let fileArr = uploadedImageIds.concat(fileIds);
          setUploadedImageIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeGalleryImageFileHandler = (index) => {
    const closedFile = [...uploadedPreviewImages];
    const removedFileId = [...uploadedImageIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedPreviewImages(closedFile);
    setUploadedImageIds(removedFileId);

    resetGalleryImageFile();
  };

  // function for clear file value
  const resetGalleryImageFile = () => {
    const file = document.getElementById("uploadedGroupImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for add uploaded images to gallery
  const addGalleryImageHandler = async () => {
    if (uploadedImageIds.length > 0) {
      try {
        let imageData = {
          galleryimages: uploadedImageIds,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_ADD_GROUP_GALLERY_IMAGES +
          `/${params.id}?token=${token}`;

        const response = await putData(requestUrl, imageData);

        console.log(response);

        if (response.status) {
          setAddSuccess(true);
          let successModal = new bootstrap.Modal(
            document.getElementById("groupGallerySuccessModal")
          );
          successModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      let emptyModal = new bootstrap.Modal(
        document.getElementById("groupGalleryEmptyModal")
      );
      emptyModal.show();
    }
  };

  //function for delete image from gallery
  const deleteImageHandler = async () => {
    if (deleteImageId) {
      try {
        let imageData = {
          galleryimageid: deleteImageId,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_GROUP_GALLERY_IMAGE +
          `/${params.id}?token=${token}`;

        const response = await putData(requestUrl, imageData);

        console.log(response);

        if (response.status) {
          let successModal = new bootstrap.Modal(
            document.getElementById("groupGallerySuccessModal")
          );
          successModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function afterCallApi
  const afterCallApi = () => {
    setAddSuccess(false);
    resetGalleryImageFile();
    setUploadedPreviewImages([]);
    setUploadedImageIds([]);
    getGroupDetails();
  };

  useEffect(() => {
    if (params.id) {
      getGroupDetails();
    }
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_GROUP")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Gallery")}
          />
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* --- bread crumb ---- */}

            {/* --- galler header ---- */}
            <GroupGalleryHeader
              addGalleryImageHandler={addGalleryImageHandler}
            />

            {/* ---- gallery section start ---- */}
            <div className="gallery-container">
              <div className="row">
                {/* ---- gallery data starts, use the js map loop here ---- */}
                {galleryImages.map((image, index) => {
                  return (
                    <div
                      className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-4"
                      key={index}
                    >
                      <div className="gallery-content border border-gray-300 rounded-10 overflow-hidden">
                        <div className="img-container">
                          <img src={image.path} alt="" className="img-fluid" />
                        </div>
                        <div className="desc d-flex gap-3 align-items-center justify-content-between p-3">
                          <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                            {/* <input
                        className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                        type="checkbox"
                        
                        id=""
                      /> */}
                            <label className="form-check-label" htmlFor="">
                              {image.name}
                            </label>
                          </div>
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#groupGallerydeleteModal"
                            onClick={() => {
                              setDeleteImageId(image._id);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {uploadedPreviewImages.map((image, index) => {
                  return (
                    <div
                      className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-4"
                      key={index}
                    >
                      <div className="gallery-content border border-gray-300 rounded-10 overflow-hidden">
                        <div className="img-container">
                          <img
                            src={image.path}
                            alt="image"
                            className="img-fluid"
                          />
                        </div>
                        <div className="desc d-flex gap-3 align-items-center justify-content-between p-3">
                          <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                            {/* <input
                            className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            
                            id=""
                          /> */}
                            <label className="form-check-label" htmlFor="">
                              {image.name}
                            </label>
                          </div>
                          <Link
                            to="#"
                            onClick={() => {
                              closeGalleryImageFileHandler(index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* upload button */}
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-4">
                  <div className="upload-container h-100 d-flex flex-column align-items-center border justify-content-center border-gray-300 border-dashed rounded-10">
                    <Link
                      to="#"
                      data-target="upload"
                      className="upload-file text-center"
                      onClick={openImageFileInput}
                    >
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">{t("Upload")}</span>
                    </Link>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input
                      type="file"
                      data-id="upload"
                      className="d-none"
                      id="uploadedGroupImageFile"
                      ref={imageFileInputRef}
                      onChange={imageUploadHandler}
                      multiple
                    />
                  </div>
                </div>
                {/* ---- gallery data ends ---- */}
              </div>
            </div>
            {/* ---- gallery section end ---- */}
          </div>
        </section>
        {/* === modals and popup section === */}
        <DeleteModal
          onDelete={deleteImageHandler}
          onCancelDelete={() => {
            setDeleteImageId(null);
          }}
        />
        <EmptyImageModal />
        <GallerySuccesModal
          afterModalClose={afterCallApi}
          addSuccess={addSuccess}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default GroupGalleryBody;
