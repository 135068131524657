import { assetImages } from "constants";
import * as url from "helper/UrlHelper";

const JobFeedbackLeftPanel = ({ cvResponseData }) => {
  const jobData = cvResponseData.challengeid
    ? `${cvResponseData?.challengeid?.name} ${cvResponseData?.challengeid?.jobcode}`
    : "";

  const imageUrl = cvResponseData.cvimagepath
    ? cvResponseData.cvimagepath === ""
      ? assetImages.defaultUser
      : url.SERVER_URL + cvResponseData.cvimagepath
    : assetImages.defaultUser;

  return (
    <div className="left_survey left_cv_info">
      <div className="cv_topbx p-3 body-bg">
        <div className="cv_top_info px-0 py-2 mb-3">
          <h3 className="text-primary fw-semibold mb-0 fs-md">{jobData}</h3>
        </div>
        <div className="profile_wrap">
          <h5 className="text-black fw-semibold mb-3">Consultant Summary </h5>
          <div className="cv_profile_row d-flex gap-2">
            <div className="cv_pic overflow-hidden rounded-circle">
              <img
                src={imageUrl}
                className="h-100 w-100 object-fit-cover"
                alt=""
              />
            </div>
            <div className="survey_info">
              <h3 className="fs-md text-black mb-1">
                <strong>{cvResponseData?.resumetitle?.toUpperCase()}</strong>
              </h3>
              <h6 className="text-gray fs-sm fw-medium mb-1">
                {cvResponseData?.candidateposition}
              </h6>
              {/* <h6 className="text-black fs-sm fw-medium mb-0">
                {" "}
                <em>
                  Certified ISTQB Certified PMP. Microsoft Certified: Azure
                  Fundamentals AZ-900
                </em>{" "}
              </h6> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cv_bttmbx p-4 bg-primary">
        <div className="cv_innr">
          {/* <!-- ========== Start role Section ========== --> */}
          <div className="cv_info_row d-flex gap-3 mb-4">
            <label htmlFor="" className="text-white fw-semibold fs-lg">
              Role
            </label>
            {cvResponseData?.roles ? (
              <ul>
                {cvResponseData?.roles.map((role, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {role}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          {/* <!-- ========== End role Section ========== --> */}

          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Skills
            </label>
            {cvResponseData.cvskillpoint ? (
              <ul>
                {cvResponseData.cvskillpoint?.map((skillPoint, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {skillPoint?.skillname}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>

          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Education
            </label>
            {cvResponseData?.educationids ? (
              <ul>
                {cvResponseData?.educationids?.map((education, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {education?.degree} from {education?.institute}{" "}
                      {education?.year}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Experience
            </label>
            {cvResponseData?.experience && cvResponseData?.experience !== "" ? (
              <ul>
                <li className="fs-sm text-white fs-md">
                  {" "}
                  {cvResponseData?.experience} years{" "}
                </li>
              </ul>
            ) : null}
          </div>
          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Languages
            </label>
            {cvResponseData?.languages ? (
              <ul>
                {cvResponseData?.languages?.map((language, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {language}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Soft <br /> skills{" "}
            </label>
            {cvResponseData?.otherskills ? (
              <ul>
                {cvResponseData?.otherskills?.map((skill, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {skill}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>

          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Technical <br /> Certifications{" "}
            </label>
            {cvResponseData?.certifications ? (
              <ul>
                {cvResponseData?.certifications?.map((course, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {course?.name}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Hobbies{" "}
            </label>
            {cvResponseData?.hobbies ? (
              <ul>
                {cvResponseData?.hobbies?.map((hobby, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {hobby}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
          <div className="cv_info_row d-flex gap-3 mb-4">
            <label className="text-white fw-semibold fs-lg" htmlFor="">
              Portfolio Links
            </label>
            {cvResponseData?.portfoliolink ? (
              <ul>
                {cvResponseData?.portfoliolink?.map((linkText, index) => {
                  return (
                    <li className="fs-sm text-white fs-md" key={index}>
                      {linkText}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobFeedbackLeftPanel;
