import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import React from 'react';
import { Link } from 'react-router-dom';

const CompanyFAQHeader = () => {
  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: 'Company', link: '/admin/companies' },
    { title: 'FAQs' },
  ];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          {/* --------- bread crumb ----------- */}
          <BreadCrumb breadCrumbText={breadCrumbText} />
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            to="#"
            className="btn btn-primary d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasAddFaq"
            aria-controls="offcanvasAddFaq"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">Add New</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CompanyFAQHeader;
