import Header from 'components/Common/Header/Header';
import CompanyFAQBody from 'components/CompaniesComponents/CompanyDetails/CompanyFAQComponents/CompanyFAQBody/CompanyFAQBody';
import AddFaqPopup from 'components/CompaniesComponents/CompanyDetails/CompanyFAQComponents/Popup/AddFaqPopup';
import React from 'react';

const CompanyFAQ = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="companies" />

      {/* ------ body section --------- */}
      <CompanyFAQBody />

      {/* ======== modals and popup area ======== */}
      {/* ------- add faq popup ----------- */}
      <AddFaqPopup />
    </main>
  );
};

export default CompanyFAQ;
