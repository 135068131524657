/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const PostOfferPopup = () => {
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasOfferPost"
      aria-labelledby="offcanvasOfferPostLabel"
    >
      <div className="offcanvas-header flex-wrap p-4 pb-0 shadow-sm">
        <h3 className="offcanvas-title" id="offcanvasOfferPostLabel">
          Post Your Offer
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <ul
          className="nav nav-tabs w-100 flex-nowrap fs-sm lh-1 fw-semibold text-center border-0 text-nowrap overflow-auto mt-3"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item flex-fill" role="presentation">
            <Link
              className="text-black d-block px-3 py-3 position-relative active"
              id="buy-tab"
              data-bs-toggle="tab"
              data-bs-target="#buy"
              type="button"
              role="tab"
              aria-controls="buy"
              aria-selected="true"
            >
              I want to buy something
            </Link>
          </li>
          <li className="nav-item flex-fill" role="presentation">
            <Link
              className="text-black d-block px-3 py-3 position-relative"
              id="sell-tab"
              data-bs-toggle="tab"
              data-bs-target="#sell"
              type="button"
              role="tab"
              aria-controls="sell"
              aria-selected="false"
              tabIndex="-1"
            >
              I want to sell something
            </Link>
          </li>
        </ul>
      </div>
      <div className="offcanvas-body d-flex flex-column p-4">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="buy"
            role="tabpanel"
            aria-labelledby="buy-tab"
            tabIndex="0"
          >
            <form action="">
              <div className="form-group mb-4">
                <label
                  htmlFor="productName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Product Name
                </label>
                <input
                  type="text"
                  id="productName"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Name"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="productDetails"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Product Details
                </label>
                <textarea
                  name="productDetails"
                  id="productDetails"
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder="Deatils..."
                ></textarea>
              </div>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-md-6 mb-4 mb-md-0">
                    <label
                      htmlFor="minOffer"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Your Min Offer
                    </label>
                    <input
                      type="text"
                      id="minOffer"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter amt"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor="maxOffer"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Your Max Offer
                    </label>
                    <input
                      type="text"
                      id="maxOffer"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter amt"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label
                      htmlFor="recommendedEmail"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Recommended Email
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="email"
                      id="recommendedEmail"
                      className="form-control fs-sm shadow-none"
                      placeholder="maria.roy@email.com"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label
                      htmlFor="productImage"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Attach Product Image
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <div className="upload-files d-flex flex-wrap gap-3 fs-sm">
                      <label
                        htmlFor="productImg"
                        className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                      >
                        <span className="d-block material-symbols-outlined text-gray-300">
                          upload
                        </span>
                        <span className="d-block fw-semibold">Upload</span>
                        <span className="fs-xs">Max 20mb doc/mp4</span>
                        <input type="file" id="productImg" className="d-none" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label
                      htmlFor="attachDocument"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Attach Documents
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <div className="upload-files d-flex flex-wrap gap-3 fs-sm">
                      <label
                        htmlFor="attachDoc"
                        className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                      >
                        <span className="d-block material-symbols-outlined text-gray-300">
                          upload
                        </span>
                        <span className="d-block fw-semibold">Upload</span>
                        <span className="fs-xs">Max 20mb doc/mp4</span>
                        <input type="file" id="attachDoc" className="d-none" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="action">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div
            className="tab-pane fade"
            id="sell"
            role="tabpanel"
            aria-labelledby="sell-tab"
            tabIndex="0"
          >
            <form action="">
              <div className="form-group mb-4">
                <label
                  htmlFor="productName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Product Name
                </label>
                <input
                  type="text"
                  id="productName"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Name"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="features"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Features
                </label>
                <div className="field-container mb-3">
                  <input
                    type="text"
                    id="features"
                    className="form-control fs-sm shadow-none"
                    placeholder="Enter details"
                  />
                </div>
                <Link to="#" className="btn btn-outline-primary">
                  Add Another
                </Link>
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="attachProductImage"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Attach Product Image
                </label>
                <div className="upload-files d-flex flex-wrap gap-3 fs-sm">
                  <label
                    htmlFor="productImg1"
                    className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <span className="d-block material-symbols-outlined text-gray-300">
                      upload
                    </span>
                    <span className="d-block fw-semibold">Upload</span>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input type="file" id="productImg1" className="d-none" />
                  </label>
                  <label
                    htmlFor="productImg2"
                    className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <span className="d-block material-symbols-outlined text-gray-300">
                      upload
                    </span>
                    <span className="d-block fw-semibold">Upload</span>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input type="file" id="productImg2" className="d-none" />
                  </label>
                  <label
                    htmlFor="productImg3"
                    className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <span className="d-block material-symbols-outlined text-gray-300">
                      upload
                    </span>
                    <span className="d-block fw-semibold">Upload</span>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input type="file" id="productImg3" className="d-none" />
                  </label>
                  <label
                    htmlFor="productImg4"
                    className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <span className="d-block material-symbols-outlined text-gray-300">
                      upload
                    </span>
                    <span className="d-block fw-semibold">Upload</span>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input type="file" id="productImg4" className="d-none" />
                  </label>
                  <label
                    htmlFor="productImg5"
                    className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <span className="d-block material-symbols-outlined text-gray-300">
                      upload
                    </span>
                    <span className="d-block fw-semibold">Upload</span>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input type="file" id="productImg5" className="d-none" />
                  </label>
                </div>
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="productDetails"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Product Details
                </label>
                <textarea
                  name="productDetails"
                  id="productDetails"
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder="Deatils..."
                ></textarea>
              </div>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-md-6 mb-4 mb-md-0">
                    <label
                      htmlFor="minOffer"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Your Min Offer
                    </label>
                    <input
                      type="text"
                      id="minOffer"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter amt"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor="maxOffer"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Your Max Offer
                    </label>
                    <input
                      type="text"
                      id="maxOffer"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter amt"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label
                      htmlFor="recommendedEmail"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Recommended Email
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="email"
                      id="recommendedEmail"
                      className="form-control fs-sm shadow-none"
                      placeholder="maria.roy@email.com"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label
                      htmlFor="attachDocument"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Attach Documents
                    </label>
                  </div>
                  <div className="col-lg-8">
                    <div className="upload-files d-flex flex-wrap gap-3 fs-sm">
                      <label
                        htmlFor="attachDoc"
                        className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                      >
                        <span className="d-block material-symbols-outlined text-gray-300">
                          upload
                        </span>
                        <span className="d-block fw-semibold">Upload</span>
                        <span className="fs-xs">Max 20mb doc/mp4</span>
                        <input type="file" id="attachDoc" className="d-none" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="action">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostOfferPopup;
