/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import images
import { assetImages } from "constants";

//import options
import publishOptions from "data/Prod/PublishOptions.json";

//import download file
import fileDownloadHandler from "helper/DownloadFile";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

import SucessStorySuccessModal from "../Modal/SucessStorySuccessModal";
import downloadFileHandler from "helper/DownloadFile";

const AddUpdateSuccessStoryBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const breadcrumbTxt = [{ title: "Success Story" }];

  const params = useParams();
  const history = useHistory();
  const loadingCircle = [1, 2, 3, 4, 5, 6];

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: "Type details here...",
  };
  /*---- CKEditor classic editor configuration end ----- */

  const uploadFileInputRef = useRef(null);

  const [companyList, setCompanyList] = useState([]);

  const [storyName, setStoryName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [successStorySummary, setSuccessStorySummary] = useState("");
  const [successStoryDetails, setSuccessStoryDetails] = useState("");
  const [successStorySitutation, setSuccessStorySitutation] = useState("");
  const [successStoryObjectives, setSuccessStoryObjectives] = useState("");
  const [successStoryProcess, setSuccessStoryProcess] = useState("");
  const [successStoryResults, setsuccessStoryResults] = useState("");
  const [ownerComments, setOwnerComments] = useState("");
  const [successStoryUrl, setSuccessStoryUrl] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [publishValue, setPublishValue] = useState(null);
  const [publishStatus, setPublishStatus] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    projectWarning: false,
    companyWarning: false,
    situtationWarning: false,
    summaryWarning: false,
    detailsWarning: false,
    categoryWarning: false,
    objectivesWarning: false,
    processWarning: false,
    resultWarning: false,
    // commentWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (storyName === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (projectName == "") {
      setValidation((prevState) => ({ ...prevState, projectWarning: true }));
      isValid = false;
    }

    if (!companyValue) {
      setValidation((prevState) => ({ ...prevState, companyWarning: true }));
      isValid = false;
    }

    if (successStorySummary === "") {
      setValidation((prevState) => ({ ...prevState, summaryWarning: true }));
      isValid = false;
    }

    if (successStoryDetails === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    if (successStorySitutation === "") {
      setValidation((prevState) => ({ ...prevState, situtationWarning: true }));
      isValid = false;
    }

    if (successStoryObjectives === "") {
      setValidation((prevState) => ({ ...prevState, objectivesWarning: true }));
      isValid = false;
    }

    if (successStoryProcess === "") {
      setValidation((prevState) => ({ ...prevState, processWarning: true }));
      isValid = false;
    }
    if (successStoryResults === "") {
      setValidation((prevState) => ({ ...prevState, resultWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all company list
  const getAllCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_LIST +
        `?token=${token}&roleslug=${userInfo.role.name}`;

      console.log("url of company list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company list------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setCompanyId(val.value);
    }
  };

  //function for open image imput
  const openUploadFileInput = () => {
    uploadFileInputRef.current.click();
  };

  //function for image upload
  const fileUploadHandler = async (e) => {
    try {
      setIsFileUploading(true);

      const uploadedStoryFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, uploadedStoryFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetsuccessstoryfileinput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setUploadedFileIds(removedFileId);

    resetsuccessstoryfileinput();
  };

  //function for reset video inputs
  const resetsuccessstoryfileinput = () => {
    const file = document.getElementById("successstoryfileinput");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select publish
  const publishSelectionHandler = (val) => {
    setPublishValue(val);
    setPublishStatus(val.value);
  };

  //close modal function
  const afterSuccessModalClose = () => {
    resetStoryHandler();
    history.push("/admin/successstories/list");
  };

  //function for reset
  const resetStoryHandler = () => {
    setStoryName("");
    setProjectName("");
    setCompanyValue(null);
    setCompanyId(null);
    setSuccessStorySummary("");
    setSuccessStoryDetails("");
    setSuccessStorySitutation("");
    setSuccessStoryObjectives("");
    setSuccessStoryProcess("");
    setsuccessStoryResults("");
    setSuccessStoryUrl("");
    setUploadedFiles([]);
    setUploadedFileIds([]);
    setPublishValue(null);
    setPublishStatus(null);
    setValidation({
      nameWarning: false,
      projectWarning: false,
      companyWarning: false,
      situtationWarning: false,
      summaryWarning: false,
      detailsWarning: false,
      categoryWarning: false,
      objectivesWarning: false,
      processWarning: false,
      resultWarning: false,
    });
  };

  //function for get blog details
  const getSuccessStoryDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SUCCESS_STORY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response story details---->", response);

      if (response.status) {
        setStoryName(response.data?.name);
        setProjectName(response.data.projectname);
        companySelectionHandler(response.data.companydata);
        setSuccessStorySummary(response.data?.summary);
        setSuccessStoryDetails(response.data?.description);
        setSuccessStorySitutation(response.data?.previoussituation);
        setSuccessStoryObjectives(response.data?.previousobjectives);
        setSuccessStoryProcess(response.data?.processdata);
        setsuccessStoryResults(response.data?.resultsdata);
        setOwnerComments(response.data?.ownertestimonial);
        setSuccessStoryUrl(response.data?.url);

        setUploadedFiles(
          response.data?.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
        const fileIds = response.data?.uploads.map(({ _id, ...other }) => ({
          _id,
        }));
        setUploadedFileIds(fileIds);

        //assing publish
        if (response.data?.publish) {
          publishOptions.map((publishData) => {
            if (publishData.value == response.data.publish) {
              publishSelectionHandler(publishData);
            }
          });
        }
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create/update blog
  const saveStoryHandler = async () => {
    if (validate()) {
      try {
        setIsSaving(true);

        let storyData = {
          userid: userInfo._id,
          name: storyName,
          companyid: companyId,
          projectname: projectName,
          summary: successStorySummary,
          description: successStoryDetails,
          previoussituation: successStorySitutation,
          previousobjectives: successStoryObjectives,
          processdata: successStoryProcess,
          resultsdata: successStoryResults,
          ownertestimonial: ownerComments,
          uploads: uploadedFileIds,
          publish: publishStatus,
          url: successStoryUrl,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_SUCCESS_STORY +
            `/${params.id}` +
            `?token=${token}`;

          response = await putData(requestUrl, storyData);
        } else {
          requestUrl =
            requestUrl + url.API_ADD_NEW_SUCCESS_STORY + `?token=${token}`;

          response = await postData(requestUrl, storyData);
        }

        console.log("response in save blog---->", response);

        if (response.status) {
          setIsSaving(false);
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("afterSaveSuccessStoryModal")
          );
          bootstrapModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrorMessage("Please fill up the required fields");
    }
  };

  useEffect(() => {
    if (params.id) {
      getSuccessStoryDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllCompanyList();
  }, []);

  return (
    <div id="content_wrapper">
      <section className="blog-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          <BreadCrumb breadCrumbText={breadcrumbTxt} />

          <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
            {/* ------ title section start ------- */}
            <div className="form-group mb-3 mb-sm-4">
              <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
                Title
              </label>
              <input
                type="text"
                id="title"
                className="form-control fs-sm shadow-none"
                placeholder="Enter"
                value={storyName}
                onChange={(e) => {
                  setStoryName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />
              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter a title!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ title section end ------- */}

            {/* ------ Project name section start ------- */}
            <div className="form-group mb-3 mb-sm-4">
              <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
                Project name
              </label>
              <input
                type="text"
                id="title"
                className="form-control fs-sm shadow-none"
                placeholder="Enter"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    projectWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />
              {validation.projectWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter a project name!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ Projet name section end ------- */}

            {/* ------ Company selection section start ------- */}
            <div className="form-group mb-3 mb-sm-4">
              <label htmlFor="title" className="d-block fs-sm fw-semibold mb-2">
                Company
              </label>
              <Select
                options={companyList}
                value={companyValue}
                onChange={(val) => {
                  companySelectionHandler(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    companyWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />

              {validation.companyWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please select company!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ Company selection section end ------- */}

            {/* ------ seo summary section start ------- */}
            <div className="form-group mb-3 mb-sm-4">
              <label htmlFor="seo" className="d-block fs-sm fw-semibold mb-2">
                Summary
              </label>
              <textarea
                name="details"
                id="details"
                cols="30"
                rows="5"
                className="form-control fs-sm shadow-none"
                placeholder="Seo summary..."
                value={successStorySummary}
                onChange={(e) => {
                  setSuccessStorySummary(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    summaryWarning: false,
                  }));
                  setErrorMessage("");
                }}
              ></textarea>
              {validation.summaryWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter summary!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ seo summary section end ------- */}

            {/* ------ details section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="details"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Details
              </label>
              <CKEditor
                className="form-control border-0 shadow-none"
                editor={ClassicEditor}
                config={editorConfig}
                data={successStoryDetails ? successStoryDetails : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setSuccessStoryDetails(editorContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    detailsWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />

              {validation.detailsWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter details!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ details section end ------ */}

            {/* ------ Previous Situation details section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="details"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Previous Situation
              </label>
              <CKEditor
                className="form-control border-0 shadow-none"
                editor={ClassicEditor}
                config={editorConfig}
                data={successStorySitutation ? successStorySitutation : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setSuccessStorySitutation(editorContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    situtationWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />

              {validation.situtationWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter situtation!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ Previous Situation details section end ------ */}

            {/* ------ Previous Objectives details section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="details"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Previous Objectives
              </label>
              <CKEditor
                className="form-control border-0 shadow-none"
                editor={ClassicEditor}
                config={editorConfig}
                data={successStoryObjectives ? successStoryObjectives : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setSuccessStoryObjectives(editorContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    objectivesWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />
              {validation.objectivesWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter objectives!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ Previous Objectives details section end ------ */}

            {/* ------ Process details section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="details"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Process
              </label>
              <CKEditor
                className="form-control border-0 shadow-none"
                editor={ClassicEditor}
                config={editorConfig}
                data={successStoryProcess ? successStoryProcess : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setSuccessStoryProcess(editorContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    processWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />
              {validation.processWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter process!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ Process details section end ------ */}

            {/* ------ Results details section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="details"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Results
              </label>
              <CKEditor
                className="form-control border-0 shadow-none"
                editor={ClassicEditor}
                config={editorConfig}
                data={successStoryResults ? successStoryResults : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setsuccessStoryResults(editorContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    resultWarning: false,
                  }));
                  setErrorMessage("");
                }}
              />
              {validation.resultWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter results!</span>
                  </p>
                </div>
              )}
            </div>
            {/* ------ Results details section end ------ */}

            {/* ------ Owner comment section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="details"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Company Owner Comments
              </label>
              <CKEditor
                className="form-control border-0 shadow-none"
                editor={ClassicEditor}
                config={editorConfig}
                data={ownerComments ? ownerComments : ""}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setOwnerComments(editorContent);
                }}
              />
            </div>
            {/* ------ Owner comment section end ------ */}

            {/* ------ url section start ------- */}
            <div className="form-group mb-3 mb-sm-4">
              <div className="row">
                {/* ------ facebook section start ------- */}
                <div className="col-lg-12 mb-3 mb-sm-4 mb-lg-0">
                  <label
                    htmlFor="facebook"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    Url
                  </label>
                  <input
                    type="text"
                    id="facebook"
                    className="form-control fs-sm shadow-none"
                    placeholder="Paste URL"
                    value={successStoryUrl}
                    onChange={(e) => setSuccessStoryUrl(e.target.value)}
                  />
                </div>
                {/* ------ facebook section end ------- */}
              </div>
            </div>
            {/* ------ url section end ------- */}

            {/* ------ attachment section start ------- */}
            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="attachments"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Attachments
              </label>
              <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                  <Link
                    to="#"
                    data-target="upload"
                    className="upload-file text-center"
                    onClick={openUploadFileInput}
                  >
                    <span className="d-block material-symbols-outlined">
                      upload
                    </span>
                    <span className="d-block fw-semibold">Upload</span>
                  </Link>
                  <span className="fs-xs">Max 20mb doc/img/mp4</span>
                  <input
                    type="file"
                    id="successstoryfileinput"
                    className="d-none"
                    ref={uploadFileInputRef}
                    onChange={fileUploadHandler}
                    multiple
                  />
                </li>
              </ul>

              {isFileUploading ? (
                <div className="d-flex align-items-center mt-3">
                  <p className="mb-0 me-1">Uploading Files</p>
                  {loadingCircle.map((circle) => {
                    return (
                      <div
                        key={circle}
                        className="spinner-grow mx-1"
                        style={{ height: "6px", width: "6px" }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                  {uploadedFiles.map((file, index) => {
                    return (
                      <li
                        className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                        key={index}
                      >
                        <Link to="#" onClick={() => downloadFileHandler(file)}>
                          <span className="d-block material-symbols-outlined">
                            download
                          </span>
                        </Link>
                        <p className="fw-medium">{file.name}</p>
                        <Link to="#" onClick={() => closeFileHandler(index)}>
                          <span className="d-block material-symbols-outlined">
                            close
                          </span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            {/* ------ attachment section end ------- */}

            {/* ------ publish selection section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <div className="row">
                <div className="col-lg-4">
                  <label
                    htmlFor="categories"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    Publish
                  </label>
                  <Select
                    options={publishOptions}
                    value={publishValue}
                    onChange={(val) => publishSelectionHandler(val)}
                  />
                </div>
              </div>
            </div>
            {/* ------ publish selection section end ------ */}

            {/* ------ agree checkbox section start ------ */}
            <div className="form-group mb-3 mb-sm-4">
              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                <input
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  type="checkbox"
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Agree with the <Link to="#">Legal Notice</Link> &amp;{" "}
                  <Link to="#">Privacy Policy</Link>
                </label>
              </div>
            </div>
            {/* ------ agree checkbox section end ------ */}

            {/* ------ save and cancel button section start ------ */}
            <div className="action d-flex flex-wrap justify-content-between gap-3">
              <Link
                onClick={resetStoryHandler}
                to="/admin/successstories/list"
                className="btn btn-outline-primary"
              >
                Cancel
              </Link>
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveStoryHandler}
                style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                disabled={isSaving ? true : false}
              >
                Save Now
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
            {errorMessage == "" ? null : (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{errorMessage}!</span>
                </p>
              </div>
            )}
            {/* ------ save and cancel button section end ------ */}
          </div>
        </div>
      </section>

      {/* ------ success message modal ------ */}
      <SucessStorySuccessModal
        afterSuccessModalClose={afterSuccessModalClose}
      />
    </div>
  );
};

export default AddUpdateSuccessStoryBody;
