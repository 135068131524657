import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import ProjectInvestmentData from '../../../../../data/ProjectManagement/ProjectInvestmentData.json';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import { useParams } from 'react-router-dom';
import ProjectInvestmentHeader from '../ProjectInvestmentHeader/ProjectInvestmentHeader';

const ProjectInvestmentBody = () => {
  const params = useParams();

  // -------- tabs header object --------------------
  const commonHeaderObject = [
    {
      title: 'Details',
      link: `/admin/projectmanagement/details/${params.id}`,
    },
    { title: 'Members', link: `/admin/projectmanagement/members/${params.id}` },
    { title: 'Tasks', link: `/admin/projectmanagement/tasks/${params.id}` },
    { title: 'Logs', link: `/admin/projectmanagement/logs/${params.id}` },
    { title: 'ODS', link: `/admin/projectmanagement/ods/${params.id}` },
    {
      title: 'Investments',
      link: `/admin/projectmanagement/investment/${params.id}`,
    },
    { title: 'KPIs', link: `/admin/projectmanagement/kpis/${params.id}` },
    { title: 'Report', link: `/admin/projectmanagement/report/${params.id}` },
  ];

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: 'Projects', link: '/admin/projectmanagement/list' },
    { title: 'Investments' },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'fromdate',
        header: 'From Date',
        Cell: ({ row }) => (
          <td className="from-date border-bottom-0">
            <p>{row.original.fromdate}</p>
          </td>
        ),
      },
      {
        accessorKey: 'toDate',
        header: 'To Date',
        Cell: ({ row }) => (
          <td className="to-date border-bottom-0">
            <p>{row.original.toDate}</p>
          </td>
        ),
      },
      {
        accessorKey: 'price',
        header: 'Price 1% (€‎)',
        Cell: ({ row }) => (
          <td className="price border-bottom-0">
            <p>{row.original.price}</p>
          </td>
        ),
      },
      {
        accessorKey: 'maxInvest',
        header: 'Max Invst. (€‎)',
        Cell: ({ row }) => (
          <td className="max-invst border-bottom-0">
            <p>{row.original.maxInvest}</p>
          </td>
        ),
      },
      {
        accessorKey: 'minInvest',
        header: 'Min Invst. (€‎)',
        Cell: ({ row }) => (
          <td className="min-invst border-bottom-0">
            <p>{row.original.minInvest}</p>
          </td>
        ),
      },
      {
        accessorKey: 'topInspector',
        header: 'Top Inspector',
        Cell: ({ row }) => (
          <td className="top-inspector border-bottom-0">
            <p>{row.original.topInspector}</p>
          </td>
        ),
      },
      {
        accessorKey: 'created',
        header: 'Created',
        Cell: ({ row }) => (
          <td className="created border-bottom-0">
            <p>{row.original.created}</p>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ------ common header -------- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption="Investments"
          />
        )}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ------- bread crumb -------- */}
          <BreadCrumb breadCrumbText={breadCrumbText} bottom={true} />

          {/* ------- filter area --------- */}
          <ProjectInvestmentHeader />

          {/* ------ table area ------- */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={ProjectInvestmentData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectInvestmentBody;
