import Header from "components/Common/Header/Header";
import SurveyResponseBody from "components/SurveyComponents/SurveyQuestionResponseComponents/SurveyResponseComponents/SurveyResponseBody/SurveyResponseBody";
import React from "react";

const SurveyResponse = () => {
  return (
    <>
      <main id="app">
        {/* ---- header start ---- */}
        <Header moduleName="survey" />
        {/* ---- header end ---- */}

        {/* --- body section start ---- */}
        <SurveyResponseBody moduleName="response" />
        {/* --- body section end ---- */}
      </main>
    </>
  );
};

export default SurveyResponse;
