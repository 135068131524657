/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import SaveMailTemplateBody from "components/CRMComponents/CRMMailTemplateComponents/SaveMailTemplateBody/SaveMailTemplateBody";

const SaveMailTemplate = () => {
  useEffect(() => {
    document.title = "CRM Mail template";
    window.scroll(0, 0);
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <SaveMailTemplateBody />
    </main>
  );
};

export default SaveMailTemplate;
