/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import SaveLeadOpportunityBody from "components/CRMComponents/CRMLeadInformationComponents/CRMLeadOpportunity/SaveLeadOpportunities/SaveLeadOpportunityBody";

const SaveLeadOpportunities = () => {
  useEffect(() => {
    document.title = "CRM Lead Information | Opportunity";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <SaveLeadOpportunityBody />
    </main>
  );
};

export default SaveLeadOpportunities;
