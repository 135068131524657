/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";

import { useTranslation } from "react-i18next";

import MailTemplateForm from "components/Common/MailTemplateForm/MailTemplateForm";

const SaveMailTemplateBody = () => {
  const history = useHistory();
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "CRM", link: "/admin/crm/mailtemplate/list" },
    { title: "Mail Template Save" },
  ];

  const afterModalClose = (pathName) => {
    history.push(pathName);
  };

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption={t("Mail Templates")}
        />
        <MailTemplateForm
          moduleName="CRM"
          breadcrumbText={breadcrumbText}
          afterModalClose={afterModalClose}
        />
      </section>
    </div>
  );
};
export default SaveMailTemplateBody;
