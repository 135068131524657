//function common header crm links
const eventDetailsHeaderLinks = (id, t) => {
  return [
    { title: t("Event Info"), link: `/admin/events/info/${id}` },
    { title: t("Sub Events"), link: `/admin/events/subevents/${id}` },
    { title: t("Members"), link: `/admin/events/members/${id}` },
    { title: t("Partners"), link: `/admin/events/eventpartner/${id}` },
    { title: t("Agenda"), link: `/admin/events/agenda/${id}` },
    { title: t("Gallery"), link: `/admin/events/gallery/${id}` },
    // { title: "Feedback", link: `/admin/events/feedback/${id}` },
  ];
};

//get duration
const getDuration = (startTimeStr, endTimeStr) => {
  // Convert start time and end time to Date objects
  const startTime = new Date(`2000-01-01T${startTimeStr}:00`);
  const endTime = new Date(`2000-01-01T${endTimeStr}:00`);

  // Calculate the duration in milliseconds
  const durationMs = endTime - startTime;

  // Convert the duration to hours and minutes
  const hours = Math.floor(durationMs / (1000 * 60 * 60));
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

  // Format the duration as "hh:mm"
  const durationStr = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return durationStr;
};

export { eventDetailsHeaderLinks, getDuration };
