import { assetImages } from "constants";
import React from "react";
import { useTranslation } from "react-i18next";

const ScheduleWarningModal = ({ onConfirm }) => {
  const { t } = useTranslation(); // for translations
  return (
    <div
      className="modal fade"
      id="scheduleWarning"
      tabIndex="-1"
      aria-labelledby="scheduleWarning"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center p-5">
            <img src={assetImages.scheduleWarning} alt="Schedule warning" />
            <h2 className="mb-2">{t("Warning")}</h2>
            <p className="fs-lg fw-semibold mb-5">
              {t(
                "This action will replace your availability records for selected date range with below hours."
              )}{" "}
              <br /> {t("Are you sure?")}
            </p>
            <div className="action d-flex justify-content-center gap-3">
              <button
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-outline-primary"
              >
                {t("Cancel")}
              </button>
              <button
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-primary"
                onClick={onConfirm}
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleWarningModal;
