/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const ImportModal = ({ afterModalClose }) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [companyValue, setCompanyValue] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const [errorMessage, seterrorMessage] = useState("");

  const [isAdding, setIsAdding] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    dateCompareWarning: false,
    companyWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (startDate === "") {
      setValidation((prevState) => ({
        ...prevState,
        startDateWarning: true,
      }));
      isValid = false;
    }

    if (endDate === "") {
      setValidation((prevState) => ({
        ...prevState,
        endDateWarning: true,
      }));

      isValid = false;
    }

    //validation for date compare.Start date must less then end date
    if (startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        setValidation((prevState) => ({
          ...prevState,
          dateCompareWarning: true,
        }));
        isValid = false;
      }
    }

    console.log('companyId set', companyId);

    if(companyId == "" || companyId == null){
      setValidation((prevState) => ({
          ...prevState,
          companyWarning: true,
        }));
        isValid = false;
    }


    return isValid;
  };

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_BY_SEARCH +
        `?token=${token}&filtertypeslug=THEATER&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setCompanyId(val.value);
    } else {
      setCompanyValue(null);
      setCompanyId(null);
      setcompanyInput("");
      setCompanyList([]);
    }
  };

  const saveImportHandler = async () => {
    if (validationHandler()) {
      try {
        setIsAdding(true);

        let importData = {
          company: companyId,
          startdate: startDate,
          enddate: endDate,
        };

        console.log('importData', importData);

        let requestUrl = url.API_BASE_URL + url.API_ONEBOX_IMPORT + `?token=${token}`;

        const response = await postData(requestUrl, importData);

        console.log('response', response);

        setIsAdding(false);
        if (response.status) {
          resetHandler();

          let myModal = document.querySelector("#importModal");
          let modal = bootstrap.Modal.getInstance(myModal);
          modal.hide();

          afterModalClose();
        }

      } catch (error) {
        console.log(error.message);
      }
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //function for reset
  const resetHandler = () => {
    setStartDate("");
    setEndDate("");
    setcompanyInput("");
    setCompanyList([]);
    setCompanyValue(null);
    setCompanyId(null);
  };

  useEffect(() => {
    if (companyInput.length > 0) {
      getCompanyList();
    }
  }, [companyInput]);

  return (
    <div
      className="modal fade"
      id="importModal"
      tabIndex="-1"
      aria-labelledby="importModal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addToListLabel">
              {t("Sync from ONEBOX")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* <!-- ========== Start date range Section ========== --> */}
              <div className="form-group mb-4">
                <label
                  htmlFor="Name"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  <h3>{t("Select Operation Date Range")} *</h3>{" "}
                </label>

                <div className="d-flex gap-2 ">
                  <input
                    type="date"
                    className="form-control fs-sm shadow-none"
                    placeholder="Start date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        startDateWarning: false,
                        endDateWarning: false,
                        dateCompareWarning: false,
                        companyWarning:false,
                      }));
                      seterrorMessage("");
                    }}
                  />
                  <input
                    type="date"
                    className="form-control fs-sm shadow-none"
                    placeholder="End date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        startDateWarning: false,
                        endDateWarning: false,
                        dateCompareWarning: false,
                        companyWarning:false,
                      }));
                      seterrorMessage("");
                    }}
                  />
                </div>
                {validation.startDateWarning && (
                  <div className="error-message mt-3">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>Please enter start date!</span>
                    </p>
                  </div>
                )}

                {validation.endDateWarning && (
                  <div className="error-message mt-3">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>Please enter end date!</span>
                    </p>
                  </div>
                )}

                {validation.dateCompareWarning && (
                  <div className="error-message mt-3">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>End date should be grater then start date!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* <!-- ========== End date range Section ========== --> */}

              {/* <!-- ========== Start company Section ========== --> */}
              <div className="form-group mb-4">
                <label
                  htmlFor="Company"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  <h3>{t("Select Theater")} *</h3>{" "}
                  <i className="text-danger">{t("Make sure to add Onebox Secret Key in Theater/Company as AuthKey")}</i>
                </label>
                <Select
                  placeholder={t("Type Theater name")}
                  isClearable
                  options={companyList}
                  value={companyValue}
                  onChange={(val) => {
                    companySelectionHandler(val);

                    setValidation((prevState) => ({
                        ...prevState,
                        startDateWarning: false,
                        endDateWarning: false,
                        dateCompareWarning: false,
                        companyWarning:false,
                      }));

                    seterrorMessage("");

                  }}
                  onInputChange={(val) => {
                    setcompanyInput(val);
                  }}
                />

                {validation.companyWarning && (
                  <div className="error-message mt-3">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Select the theater whos data you want to sync")}</span>
                    </p>
                  </div>
                )}

              </div>
              {/* <!-- ========== End company Section ========== --> */}

              <div className="action d-flex align-items-center gap-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveImportHandler}
                  style={{ cursor: isAdding ? "not-allowed" : "pointer" }}
                  disabled={isAdding ? true : false}
                >
                  {t("Import purchase data from OneBox")}
                  {isAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportModal;
