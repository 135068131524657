/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { assetImages } from "constants";

import CrmLeadInformationCommonHeader from "../../common/CrmLeadInformationCommonHeader";
import SaveInvoiceForm from "components/Common/SaveInvoiceForm/SaveInvoiceForm";
import SaveOpportunityHeader from "../Header/SaveOpportunityHeader";

const SaveLeadOpportunityBody = () => {
  const moduleSlug = "opportunity";
  const moduleName = "lead";

  const params = useParams();
  const history = useHistory();

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <CrmLeadInformationCommonHeader moduleName="opportunity" />
          <div className="container-fluid px-lg-5">
            <SaveOpportunityHeader />
            <SaveInvoiceForm
              leadId={params.id}
              contactPersonId={null}
              contactId={null}
              moduleName={moduleName}
              moduleSlug={moduleSlug}
            />
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {" "}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};

export default SaveLeadOpportunityBody;
