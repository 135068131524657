import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ExperienceAccordion = ({
  experienceListBlock = [],
  updateExperienceBlock,
  onDeleteExperienceBlock,
  setExperienceIndex,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      {/* ------ toggle accordion button start ------ */}
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseFour"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseFour"
        >
          {t("Experience")}
        </button>
      </h3>
      {/* ------ toggle accordion button end ------ */}
      {/* ----- experience block list section start ----- */}
      <div
        id="panelsStayOpen-collapseFour"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          <div className="fields-group">
            <div className="fields d-flex flex-column gap-3">
              {/* ------ experience list section start ------ */}
              {experienceListBlock.map((experience, index) => {
                return (
                  <div className="field" key={index}>
                    <div className="form-group">
                      <div className="row">
                        {/* ----- company section start ----- */}
                        <div className="col-lg-3 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("Company")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {experience.companyid
                                ? experience.companyid.label
                                : experience.company !== ""
                                ? experience.company
                                : ""}
                            </p>
                          </div>
                        </div>
                        {/* ----- company section end ----- */}
                        {/* ----- position section start ----- */}
                        <div className="col-lg-4 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("Position")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {experience.position}
                            </p>
                          </div>
                        </div>
                        {/* ----- position section end ----- */}
                        {/* ----- start year section start ----- */}
                        <div className="col-lg-2 mb-3 mb-lg-0">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("Start Year")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {experience.start}
                            </p>
                          </div>
                        </div>
                        {/* ----- start year section end ----- */}
                        {/* ----- end year section start ----- */}
                        <div className="col-lg-2">
                          <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                            <label
                              htmlFor="degree"
                              className="text-nowrap me-3"
                            >
                              {t("To Year")}
                            </label>
                            <p className="form-control border-0 shadow-none">
                              {experience.end}
                            </p>
                          </div>
                        </div>
                        {/* ----- end year section end ----- */}
                        {/* ------ edit and delete icon section start ------ */}
                        <div className="col-lg action d-flex align-items-center justify-content-end gap-3 mb-3">
                          {/* ------ edit icon start ------ */}
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#addExperienceModal"
                            onClick={() => {
                              updateExperienceBlock(experience, index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              edit
                            </span>
                          </Link>
                          {/* ------ edit icon end ------ */}
                          {/* ------ delete icon start ------ */}
                          <Link
                            to="#"
                            onClick={() => {
                              onDeleteExperienceBlock(index);
                            }}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          {/* ------ delete icon end ------ */}
                        </div>
                        {/* ------ edit and delete icon section end ------ */}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* ------ experience list section end ------ */}
            </div>
            {/* ------ add experience modal button start ------ */}
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#addExperienceModal"
              onClick={() => {
                setExperienceIndex(null);
              }}
              className="btn btn-outline-primary d-inline-flex align-items-center gap-1 mt-3"
            >
              <span className="d-block material-symbols-outlined">add</span>
              <span className="d-block">{t("Add Experience")}</span>
            </Link>
            {/* ------ add experience modal button end ------ */}
          </div>
        </div>
      </div>
      {/* ----- experience block list section end ----- */}
    </div>
  );
};

export default ExperienceAccordion;
