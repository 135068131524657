/* eslint-disable */
import { useState, createContext, useEffect } from "react";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

export const GlobalProvider = createContext();

const GlobalContext = (props) => {
  let screenHeight = window.innerHeight;

  /*======= login requirement start =======*/
  const [verifyModuleName, setVerifyModuleName] = useState("");
  const [createAccountMessage, setcreateAccountMessage] = useState("");
  const [userEmailforOtp, setuserEmailforOtp] = useState("");
  /*======= login requirement end =======*/

  // <!-- ========== Start alert requirements ========== -->
  const [reloadAlertList, setReloadAlertList] = useState(false);
  // <!-- ========== End alert requirements ========== -->

  /**====== connection requirement start ========*/
  const [reloadRequestList, setreloadRequestList] = useState(false);
  /**====== connection requirement end ========*/

  /**======= contact requirement start =======*/
  const [reloadContactList, setReloadContactList] = useState(false);
  /**======= contact requirement end =======*/

  return (
    <GlobalProvider.Provider
      value={{
        /* ============== screen height ============================*/
        screenHeight,

        /*======= login requirement start =======*/
        verifyModuleName,
        setVerifyModuleName,
        createAccountMessage,
        setcreateAccountMessage,
        userEmailforOtp,
        setuserEmailforOtp,
        /*======= login requirement end =======*/

        /* ============== Start alert requirements ============== */
        reloadAlertList,
        setReloadAlertList,
        /* ============== End alert requirements ============== */

        /**====== connection requirement start ========*/
        reloadRequestList,
        setreloadRequestList,
        /**====== connection requirement end ========*/

        /**======= contact requirement start =======*/
        reloadContactList,
        setReloadContactList,
        /**======= contact requirement end =======*/
      }}
    >
      {props.children}
    </GlobalProvider.Provider>
  );
};

export default GlobalContext;
