/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { assetImages } from "constants";
import { projectDetailsHeaderLinks } from "helper/ProjectHelper/ProjectHelper";

import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";

import { GanttViewSwitcher } from "./GanttViewSwitcher";
import ProjectGanttHeader from "../ProjectGanttHeader/ProjectGanttHeader";
import TaskManagementPopup from "components/Common/Popup/TaskManagementPopup/TaskManagementPopup";
import { getConvertedGanttDate } from "helper/TimeCalculation";
import MyTaskFilterPopup from "components/Common/Popup/MyTaskFilterPopup";
import UpdateGanttTaskModal from "components/Common/Modal/UpdateGanttTaskModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ProjectTaskGanttBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation
  const [isLoading, setIsLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);

  const [selectedEditTaskId, setSelectedEditTaskId] = useState(null);

  /* -------------- Requirements for view change -------------- */
  const [view, setView] = useState(ViewMode.Day);
  const [isChecked, setIsChecked] = useState(true);

  const [title, settitle] = useState("");

  const [isProjectModerator, setisProjectModerator] = useState(false);
  const [selectedTaskModerator, setselectedTaskModerator] = useState(null);

  const [hideEmptyMessage, setHideEmptyMessage] = useState(true);
  const [taskList, setTaskList] = useState([]);

  const [errorMessage, seterrorMessage] = useState("");

  const [filterTaskDateRangeType, setFilterTaskDateRangeType] = useState("");
  const [filterTaskFromDate, setfilterTaskFromDate] = useState("");
  const [filterTaskToDate, setfilterTaskToDate] = useState("");

  const [filterTaskCustomIds, setfilterTaskCustomIds] = useState([]);
  const [filterTaskTitle, setfilterTaskTitle] = useState("");
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskAssignedToUsers, setfilterTaskAssignedToUsers] = useState(
    []
  );
  const [filterTaskAssignedByUsers, setfilterTaskAssignedByUsers] = useState(
    []
  );
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);

  const [showUpdateButtons, setShowUpdateButtons] = useState(false);
  const [updatedTaskId, setUpdatedTaskId] = useState(null);
  const [updatedStartDate, setUpdatedStartDate] = useState("");
  const [updatedEndDate, setUpdatedEndDate] = useState("");

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // -------- tabs header object --------------------
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: t("Projects"), link: "/admin/projectmanagement/list" },
    { title: t("Details") },
  ];

  let columnWidth = 65;

  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  //function for get project details
  const getProjectDetails = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      setIsLoading(false);

      // console.log(response);
      if (response.status && response.data) {
        const data = response.data;

        setselectedTaskModerator(data.moderator._id);

        let isModerator = false;

        if (
          data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        }
        getAllTask(isModerator);
        setisProjectModerator(isModerator);

        settitle(response.data.title);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  /* -------------------------------------------------------- */

  //function for get all event members
  const getAllProjectMembers = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_MEMBER +
        `?token=${token}&project=${params.id}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setMemberList(
          response.data.map((memberData) => ({
            label: memberData.memebername,
            value: memberData.user._id,
          }))
        );
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //get all tasks
  const getAllTask = async (isModerator) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&projectid=${params.id}&ismoderator=${isModerator}`;

      if (filterTaskDateRangeType != "") {
        requestUrl =
          requestUrl + `&filterdaterangetype=${filterTaskDateRangeType}`;
      }

      if (filterTaskFromDate != "" || filterTaskToDate != "") {
        requestUrl =
          requestUrl +
          `&filterfromdate=${filterTaskFromDate}&filtertodate=${filterTaskToDate}`;
      }

      if (filterTaskTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
      }

      if (filterTaskCustomIds.length > 0) {
        requestUrl = requestUrl + `&filtertaskcustomids=${filterTaskCustomIds}`;
      }

      if (filterTaskLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
      }
      if (filterTaskStatus.length > 0) {
        requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
      }
      if (filterTaskPriorities.length > 0) {
        requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
      }

      if (filterTaskAssignedByUsers.length > 0) {
        requestUrl =
          requestUrl + `&filtermoderators=${filterTaskAssignedByUsers}`;
      }

      if (filterTaskAssignedToUsers.length > 0) {
        requestUrl = requestUrl + `&filterusers=${filterTaskAssignedToUsers}`;
      }

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in task list------->", response);

      if (response.status) {
        if (response.data.length === 0) {
          setHideEmptyMessage(false);
        }

        const newGanttTasks = response.data.map((task) => {
          const taskTitle = task.ismilestone
            ? `${task.title}`
            : `${task.customid} ${task.title}`;

          return {
            start: new Date(task.startdate), // task start date
            end: new Date(task.enddate), // task end date,
            name: taskTitle, // task title
            project: task.projectname, // project title
            moderator: task.moderator._id,
            id: task._id, // task mongo id
            type: task.ismilestone ? "milestone" : "task", // type = task, milestone, project
            progress: task.taskpercent, // % done
            isDisabled: false,
            hideChildren: false,
            styles: {
              progressColor: "#ffbb54",
              progressSelectedColor: "#ff9e0d",
            },
            dependencies: task.previoustask ? [task.previoustask] : [], // previous task mongo id
          };
        });

        setTaskList(newGanttTasks);
        // resetFilterData();

        setAlertMessage(response.message);
        setMessageType("success");
        setShowAlert(true);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for update date of task
  const onTaskDateChange = (e) => {
    const startDate = getConvertedGanttDate(e.start);
    const endDate = getConvertedGanttDate(e.end);

    setUpdatedTaskId(e.id);
    setUpdatedStartDate(startDate);
    setUpdatedEndDate(endDate);
    setShowUpdateButtons(true);

    // const bootstrapModal = new bootstrap.Modal(
    //   document.getElementById("projectTaskGanttModal")
    // );
    // bootstrapModal.show();
  };

  const onUpdateGanttTask = async (change, unlink) => {
    try {
      let taskData = {
        startdate: updatedStartDate,
        enddate: updatedEndDate,
        ischange: change,
        isunlink: unlink,
      };

      // console.log(taskData);

      let response = {};

      if (updatedTaskId) {
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_GANTT_TASK +
          `/${updatedTaskId}?token=${token}`;

        response = await putData(requestUrl, taskData);
      }

      // console.log(response);

      if (response.status) {
        resetUpdatedTaskData();
        seterrorMessage("");
        getAllTask(isProjectModerator);

        setMessageType("success");
      } else {
        seterrorMessage(response.message);
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //function for change progress
  const onTaskProgressChange = async (e) => {
    try {
      let taskData = {
        taskpercent: e.progress,
      };

      // console.log(taskData);

      let response = {};

      if (e.id) {
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_PROJECT_TASK +
          `/${e.id}?token=${token}`;

        response = await putData(requestUrl, taskData);
      }

      // console.log(response);

      if (response.status) {
        seterrorMessage("");
        setMessageType("success");
      } else {
        seterrorMessage(response.message);
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //fucntion for open task popup and update
  const onClickTask = (e) => {
    setSelectedEditTaskId(e.id);
    // Get the offcanvas element
    var taskCanvas = document.getElementById("addTask_view");
    // Trigger the offcanvas using Bootstrap's built-in methods
    var offcanvas = new bootstrap.Offcanvas(taskCanvas);
    offcanvas.toggle(); // Toggle the offcanvas
  };

  const refreshRecords = () => {
    resetFilterData();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterTaskDateRangeType("");
    setfilterTaskFromDate("");
    setfilterTaskToDate("");
    setfilterTaskCustomIds([]);
    setfilterTaskTitle("");
    setfilterTaskLabels([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setfilterTaskProjects([]);
    setfilterTaskAssignedToUsers([]);
    setfilterTaskAssignedByUsers([]);

    setReloadData(true);
  };

  //reset update task data from date
  const resetUpdatedTaskData = () => {
    setUpdatedTaskId(null);
    setUpdatedStartDate("");
    setUpdatedEndDate("");
    setShowUpdateButtons(false);
  };

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllTask(isProjectModerator);
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    if (
      filterTaskDateRangeType != "" ||
      filterTaskFromDate != "" ||
      filterTaskToDate != "" ||
      filterTaskCustomIds.length > 0 ||
      filterTaskTitle != "" ||
      filterTaskLabels.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskAssignedByUsers.length > 0 ||
      filterTaskAssignedToUsers.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      getAllTask(isProjectModerator);
    }
  }, [
    filterTaskDateRangeType,
    filterTaskFromDate,
    filterTaskToDate,
    filterTaskCustomIds,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskAssignedByUsers,
    filterTaskAssignedToUsers,
  ]);

  useEffect(() => {
    if (params.id) {
      resetUpdatedTaskData();
      getAllProjectMembers();
      getProjectDetails();
    }
  }, [params.id]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ------ common header -------- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Tasks")}
            />
          )}
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter area ------- */}
            <ProjectGanttHeader
              projectid={params.id}
              isProjectModerator={isProjectModerator}
              breadCrumbText={breadCrumbText}
              title={title}
              reloadList={() => {
                refreshRecords();
                resetUpdatedTaskData();
              }}
            />

            {/* -------- gantt board here---------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="project-tasks-container">
                <GanttViewSwitcher
                  onViewModeChange={(viewMode) => setView(viewMode)}
                  onViewListChange={setIsChecked}
                  isChecked={isChecked}
                  showUpdateButtons={showUpdateButtons}
                  resetUpdatedTaskData={resetUpdatedTaskData}
                  onUpdateGanttTask={onUpdateGanttTask}
                />

                {taskList.length === 0 ? (
                  hideEmptyMessage ? null : (
                    <div className="challenges_empty text-center">
                      <div className="empty_pic mb-4">
                        {" "}
                        <img src={assetImages.emptyVector} alt="" />
                      </div>
                      <div className="empty_text">
                        <p className="fs-lg text-gray fw-semibold mb-4">
                          {t("Sorry! No Tasks available at this moment")}.
                        </p>
                      </div>
                    </div>
                  )
                ) : (
                  <Gantt
                    ganttHeight={400}
                    tasks={taskList}
                    viewMode={view}
                    onDateChange={(e) => {
                      onTaskDateChange(e);
                    }}
                    onTaskDelete={(e) => {
                      console.log("onTaskDelete fired ---->");
                      console.log(e);
                    }}
                    onProgressChange={(e) => {
                      onTaskProgressChange(e);
                    }} // returns the progress
                    onDoubleClick={(e) => {
                      console.log("onDoubleClick fired ---->");
                      console.log(e);
                    }} // event is firing and it returns the task
                    onClick={(e) => {
                      onClickTask(e);
                    }} // event is firing and it returns the task
                    columnWidth={columnWidth}
                    listCellWidth={isChecked ? "200px" : ""}
                  />
                )}
              </div>
            )}
          </div>
        </section>

        {/* ========== popups and modal area ======== */}
        <TaskManagementPopup
          projectId={null}
          leadId={null}
          contactId={null}
          selectedTaskModerator={selectedTaskModerator}
          selectedSectionValue={null}
          sectionOptions={[]}
          memberList={memberList}
          selectedTaskId={selectedEditTaskId}
          onPopUpClose={() => {
            setSelectedEditTaskId(null);
            // setselectedTaskModerator(null);
            // setSelectedSectionValue(null);
          }}
          getTaskList={() => {
            setTimeout(() => {
              getAllTask(isProjectModerator);
            }, 2200);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ------- filter popup --------- */}
        {/* ------- filter popup --------- */}
        <MyTaskFilterPopup
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          filterTaskDateRangeType={filterTaskDateRangeType}
          setFilterTaskDateRangeType={setFilterTaskDateRangeType}
          filterTaskFromDate={filterTaskFromDate}
          setfilterTaskFromDate={setfilterTaskFromDate}
          filterTaskToDate={filterTaskToDate}
          setfilterTaskToDate={setfilterTaskToDate}
          filterTaskCustomIds={filterTaskCustomIds}
          setfilterTaskCustomIds={setfilterTaskCustomIds}
          filterTaskTitle={filterTaskTitle}
          setfilterTaskTitle={setfilterTaskTitle}
          filterTaskProjects={filterTaskProjects}
          setfilterTaskProjects={setfilterTaskProjects}
          filterTaskAssignedByUsers={filterTaskAssignedByUsers}
          setfilterTaskAssignedToUsers={setfilterTaskAssignedToUsers}
          filterTaskAssignedToUsers={filterTaskAssignedToUsers}
          setfilterTaskAssignedByUsers={setfilterTaskAssignedByUsers}
          filterTaskStatus={filterTaskStatus}
          setfilterTaskStatus={setfilterTaskStatus}
          filterTaskPriorities={filterTaskPriorities}
          setfilterTaskPriorities={setfilterTaskPriorities}
          filterTaskLabels={filterTaskLabels}
          setfilterTaskLabels={setfilterTaskLabels}
          isModerator={isProjectModerator}
          moduleName="projectgantt"
        />

        <UpdateGanttTaskModal onUpdateGanttTask={onUpdateGanttTask} />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectTaskGanttBody;
