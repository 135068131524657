/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const LogFilterPopup = ({
  tagList,
  taskList,
  // projectList,
  setFilterTitle,
  setFilterDateRange,
  setFilterTags,
  setfilterTaskIds,
  setfilterSectionIds,
  setfilterUserIds,
  setfilterProjectIds,
}) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [projectInput, setprojectInput] = useState("");
  const [projectList, setProjectList] = useState([]);

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [title, settitle] = useState("");

  const [logOwnerInput, setlogOwnerInput] = useState("");
  const [logOwnerOptions, setlogOwnerOptions] = useState([]);

  const [logOwnerValue, setlogOwnerValue] = useState(null);
  const [logOwnerIds, setlogOwnerIds] = useState([]);

  const [taskValue, settaskValue] = useState(null);
  const [taskIds, settaskIds] = useState([]);

  const [sectionValue, setsectionValue] = useState(null);
  const [sectionIds, setsectionIds] = useState([]);

  const [userValue, setuserValue] = useState(null);
  const [userIds, setuserIds] = useState([]);

  const [projectValue, setprojectValue] = useState(null);
  const [projectIds, setprojectIds] = useState([]);

  const [tagValues, settagValues] = useState([]);

  //search input
  const projectInputHandler = (val) => {
    setprojectInput(val);
  };

  //function for get project list
  const getProjectList = async () => {
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_PROJECTLIST_BY_SEARCH}?token=${token}&searchtitle=${projectInput}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setProjectList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user details
  const getLogOwnerList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${logOwnerInput}&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setlogOwnerOptions(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get input
  const logOwnerInputHandler = (val) => {
    setlogOwnerInput(val);
  };

  //function Selection
  const logOwnerSelectionHandler = (val) => {
    setlogOwnerValue(val);
    setlogOwnerIds(val.map((item) => item.value));
  };

  //function for select event tags
  const logTagSelectionHandler = (data) => {
    let prevTagValues = tagValues;

    if (prevTagValues.includes(data.value)) {
      let selectedTypeItem = document.getElementById(
        "projectlogtagfilter-" + data.value
      );

      selectedTypeItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTagValues.indexOf(data.value);
      prevTagValues.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "projectlogtagfilter-" + data.value
      );

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTagValues.push(data.value);
    }
    settagValues(prevTagValues);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  const projectSelectionHandler = (val) => {
    setprojectValue(val);
    setprojectIds(val.map((item) => item.value));
  };

  //   const sectionSelectionHandler = (val) => {
  //     setsectionValue(val);
  //     setsectionIds(val.map((item) => item.value));
  //   };

  //   const userSelectionHandler = (val) => {
  //     setuserValue(val);
  //     setuserIds(val.map((item) => item.value));
  //   };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }

    //     setFilterTitle(title);
    setFilterTags(tagValues);
    //     setfilterTaskIds(taskIds);
    //     setfilterSectionIds(sectionIds);
    setfilterUserIds(logOwnerIds);
    setfilterProjectIds(projectIds);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasLogFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    removeActiveClass();
    //     settitle("");
    setfromDate("");
    settoDate("");
    settagValues([]);

    setlogOwnerInput("");
    setlogOwnerOptions([]);

    setlogOwnerValue(null);
    setlogOwnerIds([]);

    setprojectValue(null);
    setprojectIds([]);
    //     setuserValue(null);
    //     setuserIds([]);
    //     settaskValue(null);
    //     settaskIds([]);
  };

  useEffect(() => {
    if (projectInput.length > 1) {
      getProjectList();
    }
  }, [projectInput]);

  useEffect(() => {
    if (logOwnerInput.length > 3) {
      getLogOwnerList();
    }
  }, [logOwnerInput]);

  const projectOptionsToShow = projectList.length > 0 ? projectList : [];

  const logOwnerOptionsToShow =
    logOwnerOptions.length > 0 ? logOwnerOptions : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasLogFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* dates */}
          <div className="form-group mb-4">
            <label htmlFor="Dates" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From date")}
                  value={fromDate}
                  onChange={(e) => setfromDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To date")}
                  value={toDate}
                  onChange={(e) => settoDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* project */}
          <div className="form-group mb-4">
            <label htmlFor="Project" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Project")}</h3>{" "}
            </label>

            <Select
              isMulti
              placeholder={t("Type Project name")}
              options={projectOptionsToShow}
              value={projectValue}
              onChange={(val) => {
                projectSelectionHandler(val);
              }}
              onInputChange={(val) => {
                projectInputHandler(val);
              }}
            />
          </div>

          {/* log owner */}
          <div className="form-group mb-4">
            <label
              htmlFor="Added By (log owner)"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Added By (log owner)")}</h3>{" "}
            </label>

            <Select
              isMulti
              placeholder={t("Select User")}
              options={logOwnerOptionsToShow}
              value={logOwnerValue}
              onChange={(val) => {
                logOwnerSelectionHandler(val);
              }}
              onInputChange={(val) => {
                logOwnerInputHandler(val);
              }}
            />
          </div>

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* --------- Labels ------------- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {tagList.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => logTagSelectionHandler(tagData)}
                            id={"projectlogtagfilter-" + tagData.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LogFilterPopup;
