/* eslint-disable */
import Header from 'components/Common/Header/Header';
import CompanyTeamMembersBody from 'components/CompaniesComponents/CompanyDetails/CompanyTeamMembersComponents/CompanyTeamMembersBody/CompanyTeamMembersBody';
import React, { useEffect } from 'react';

const CompanyTeamMembers = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = 'Companies';
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="companies" />

      {/* ------ body section --------- */}
      <CompanyTeamMembersBody />
    </main>
  );
};

export default CompanyTeamMembers;
