/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';

// import for excel download
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from 'utils/Gateway';

import TaskStatusOptions from 'data/Prod/TaskStatus.json';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import LogHeader from '../Header/LogHeader';
import SaveLogPopup from '../Popup/SaveLogPopup';
import LogFilterPopup from '../Popup/LogFilterPopup';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const AllLogBody = () => {
  const params = useParams();
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const { t, i18n } = useTranslation(); //for translation
  // -------- breadcrumb text --------
  const breadCrumbText = [{ title: t('Logs') }];

  const [tagList, setTagList] = useState([]);
  const [taskList, settaskList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [logList, setLogList] = useState([]);
  const [selectLogIds, setSelectLogIds] = useState([]);
  const [selectedLogId, setSelectedLogId] = useState(null);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterTags, setFilterTags] = useState([]);
  const [filterUserIds, setfilterUserIds] = useState([]);
  const [filterProjectIds, setfilterProjectIds] = useState([]);

  // download to excel
  const [excelData, setExcelData] = useState([]);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all task
  const getAllTasks = async () => {
    try {
      let isModerator = false;

      if (
        userInfo.role.slug === 'ADMIN' ||
        userInfo.role.slug === 'SUPER_ADMIN'
      ) {
        isModerator = true;
      }

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&ismoderator=${isModerator}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        settaskList(
          response.data.map(taskData => ({
            label: taskData.title,
            value: taskData._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all section with task
  const getAllProjects = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_PROJECTS + `?token=${token}`;

      const response = await getData(requestUrl);

      setProjectList(
        response.data.map(sectionData => ({
          label: sectionData.title,
          value: sectionData._id,
          startdate: sectionData.startdate,
          enddate: sectionData.enddate,
        }))
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  //get task logs
  const getTaskLogs = async () => {
    setRowSelection({});
    setSelectLogIds([]);
    try {
      let isModerator = false;

      if (
        userInfo.role.slug === 'ADMIN' ||
        userInfo.role.slug === 'SUPER_ADMIN'
      ) {
        isModerator = true;
      }

      setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&ismoderator=${isModerator}`;

      if (filterDateRange != '') {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      if (filterUserIds.length > 0) {
        requestUrl = requestUrl + `&filterlogusers=${filterUserIds}`;
      }

      if (filterProjectIds.length > 0) {
        requestUrl = requestUrl + `&filterlogprojects=${filterProjectIds}`;
      }

      console.log('request url', requestUrl);

      const response = await getData(requestUrl);

      console.log('response', response);

      setisLoading(false);

      console.log(response);

      if (response.status) {
        resetFilterData();
        setLogList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectLogIds) {
      try {
        let statusData = {
          status: '0',
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_TASK_LOG +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getTaskLogs();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setFilterTags([]);
    setfilterUserIds([]);
    setfilterProjectIds([]);
  };

  //download as excel
  const downloadAsExcel = () => {
    // if (logList.length > 0) {
    //   if (selectLogIds.length > 0) {
    //     logList
    //       .filter((logItem) => selectLogIds.includes(logItem._id))
    //       .map((apiData) => {
    //         // add only those data which are required to show
    //         let rowData = [
    //           apiData.projectname,
    //           apiData.sectionname,
    //           apiData.tasktitle,
    //           apiData.projectmoderator,
    //           apiData.username,
    //           apiData.startdate,
    //           apiData.hours,
    //           apiData.price,
    //           apiData.taskpercent,
    //           apiData.taskstatus,
    //           apiData.tagstring,
    //           apiData.description.length > 32000
    //             ? "Too large to display"
    //             : apiData.description,
    //         ];

    //         setExcelData((prevState) => [...prevState, rowData]);
    //       });
    //   } else {
    //     logList.map((apiData) => {
    //       // add only those data which are required to show
    //       let rowData = [
    //         apiData.projectname,
    //         apiData.sectionname,
    //         apiData.tasktitle,
    //         apiData.projectmoderator,
    //         apiData.username,
    //         apiData.startdate,
    //         apiData.hours,
    //         apiData.price,
    //         apiData.taskpercent,
    //         apiData.taskstatus,
    //         apiData.tagstring,
    //         apiData.description.length > 32000
    //           ? "Too large to display"
    //           : apiData.description,
    //       ];

    //       setExcelData((prevState) => [...prevState, rowData]);
    //     });
    //   }
    // }
    if (logList.length > 0) {
      const createRowData = apiData => [
        apiData.projectname,
        apiData.sectionname,
        apiData.tasktitle,
        apiData.projectmoderator,
        apiData.username,
        apiData.startdate,
        apiData.hours,
        apiData.price,
        apiData.taskpercent,
        apiData.taskstatus,
        apiData.tagstring,
        apiData.description.length > 32000
          ? 'Too large to display'
          : apiData.description,
      ];

      const filteredLogList =
        selectLogIds.length > 0
          ? logList.filter(logItem => selectLogIds.includes(logItem._id))
          : logList;

      filteredLogList.map(apiData => {
        const rowData = createRowData(apiData);
        setExcelData(prevState => [...prevState, rowData]);
      });
    }
  };

  const createExcel = () => {
    const fileName = 'logs';
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const Heading = [
      [
        'Project',
        'Section',
        'Task',
        'Owner',
        'Provider',
        'Date',
        'Hours',
        'Price',
        '% Complete',
        'Status',
        'Label',
        'Task Description',
      ],
    ];
    const ws = XLSX.utils.json_to_sheet(excelData, {
      origin: 'A2',
      skipHeader: true,
    });
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const filedata = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(filedata, fileName + fileExtension);
  };

  useEffect(() => {
    // console.log("excelData", excelData);

    if (excelData.length > 0) {
      createExcel();
      setExcelData([]);
    }
  }, [excelData]);

  // download excel ends

  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterTags.length > 0 ||
      filterUserIds.length > 0 ||
      filterProjectIds.length > 0
    ) {
      getTaskLogs();
    }
  }, [filterDateRange, filterUserIds, filterTags, filterProjectIds]);

  useEffect(() => {
    getAllTags();
    getAllTasks();
    getAllProjects();
    getTaskLogs();
  }, []);

  /*
   * Material React Table Column and States
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#saveLog"
            aria-controls="saveLog"
            onClick={() => {
              setSelectedLogId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'taskcustomid',
        header: t('Task Id'),
      },
      // {
      //   accessorKey: "description",
      //   header: "Log",
      // },
      {
        accessorKey: 'tasktitle',
        header: t('Task'),
        size: 200,
      },
      {
        accessorKey: 'projectname',
        header: t('Project'),
      },
      {
        accessorKey: 'leadtitle',
        header: t('Lead'),
      },
      {
        accessorKey: 'contactname',
        header: t('Contact'),
      },
      {
        accessorKey: 'username',
        header: t('Log Added By'),
      },
      {
        accessorKey: 'startdate',
        header: t('From Date'),
      },
      {
        accessorKey: 'enddate',
        header: t('To Date'),
      },
      {
        accessorKey: 'hours',
        header: t('Hours'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      // {
      //   accessorKey: "#",
      //   header: "Action",
      //   Cell: ({ row }) => (
      //     <div className="action border-bottom-0">
      //       <Link
      //         to="#"
      //         className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
      //         data-bs-toggle="offcanvas"
      //         data-bs-target="#saveLog"
      //         onClick={() => {
      //           setSelectedLogId(row.original._id);
      //         }}
      //       >
      //         <span className="d-block">Details</span>
      //         <span className="d-block material-symbols-outlined">
      //           chevron_right
      //         </span>
      //       </Link>
      //     </div>
      //   ),
      // },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectLogIds(selectedIdsArray);
    } else {
      setSelectLogIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_LOGS')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ------- bread crumb -------- */}

            {/* ------- filter area --------- */}
            <LogHeader
              breadCrumbText={breadCrumbText}
              reloadList={getTaskLogs}
              downloadAsExcel={downloadAsExcel}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={logList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem key="edit">
                  //     <Link className="dropdown-item" to="#">
                  //       View Details
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Update
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Add to Challenge
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link
                  //       className="dropdown-item"
                  //       to="#"
                  //       data-bs-toggle="modal"
                  //       data-bs-target="#addLabelModal"
                  //     >
                  //       Add to Tag
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Add to List
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ========= popups and modals area ========= */}
        {/* --------- Add label modal ---------- */}
        <AddLabelModal
          moduleName="tasklog"
          selectedIds={selectLogIds}
          afterTagModalClose={getTaskLogs}
        />

        {/* ----- log details modal ------- */}
        {/* <LogDetailsModal
        selectedLogId={selectedLogId}
        onModalClose={() => {
          setSelectedLogId(null);
        }}
      /> */}

        <SaveLogPopup
          selectedLogId={selectedLogId}
          onPopUpClose={() => {
            setSelectedLogId(null);
            getTaskLogs();
          }}
        />

        {/* ---------- filter popup --------- */}
        <LogFilterPopup
          taskList={taskList}
          // projectList={projectList}
          tagList={tagList}
          setFilterDateRange={setFilterDateRange}
          setFilterTags={setFilterTags}
          setfilterUserIds={setfilterUserIds}
          setfilterProjectIds={setfilterProjectIds}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectLogIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('log')}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default AllLogBody;
