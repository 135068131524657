const getCurrentDateInString = (date = null, format = null) => {
  let today = new Date();

  if (date) {
    today = new Date(date);
  }

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;

  if (format === "dd/mm/yyyy") {
    formattedDate = `${day}/${month}/${year}`;
  }

  return formattedDate;
};

export { getCurrentDateInString };
