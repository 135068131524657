/* eslint-disable */
import { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";

const SuccessStoryFilterpopup = ({
  tagList,
  setFilterDateRange,
  setFilterStoryTitle,
  setFilterStoryLabels,
}) => {
  const [storyFromDate, setStoryFromDate] = useState("");
  const [storyToDate, setStoryToDate] = useState("");
  const [storyTitle, setStoryTitle] = useState("");
  const [selectedStoryLabels, setselectedStoryLabels] = useState([]);

  // function for set tags
  const tagselectionHandler = (data) => {
    let prevTags = selectedStoryLabels;

    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById(
        "storytagfilter-" + data.value
      );
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById(
        "storytagfilter-" + data.value
      );
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }

    setselectedStoryLabels(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("storytagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (
      storyFromDate != "" &&
      storyToDate != "" &&
      storyFromDate < storyToDate
    ) {
      setFilterDateRange(storyFromDate + "," + storyToDate);
    } else if (storyFromDate != "") {
      setFilterDateRange(storyFromDate);
    }

    setFilterStoryTitle(storyTitle);
    setFilterStoryLabels(selectedStoryLabels);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasSuccessStoryFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    setStoryFromDate("");
    setStoryToDate("");
    setStoryTitle("");
    setselectedStoryLabels([]);
    removeActiveClass();
    resetSelectTags();
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasSuccessStoryFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        {/* ----- search form section start ------ */}
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div
            className="accordion flex-fill"
            id="accordionPanelsStayOpenExample"
          >
            {/* ------ accordion for created date range start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  Created Dates
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <div className="row gx-2">
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          placeholder="From date"
                          value={storyFromDate}
                          onChange={(e) => setStoryFromDate(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          placeholder="To date"
                          value={storyToDate}
                          onChange={(e) => setStoryToDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ------ accordion for created date range end ------ */}

            {/* ------ accordion for blog title start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTitle"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTitle"
                >
                  Success story Title
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTitle"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder="Blog title"
                      value={storyTitle}
                      onChange={(e) => setStoryTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ------ accordion for blog title end ------ */}

            {/* ------ accordion for labels start ------ */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Labels
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {tagList.map((tag, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagselectionHandler(tag)}
                            id={"storytagfilter-" + tag.value}
                            className="storytagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ------ accordion for labels end ------ */}
          </div>
          {/* ----- search button start ----- */}
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            Go
          </button>
          {/* ----- search button end ----- */}
        </form>
        {/* ----- search form section start ------ */}
      </div>
    </div>
  );
};

export default SuccessStoryFilterpopup;
