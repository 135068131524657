/* eslint-disable */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

import { assetImages } from 'constants';

import ActionLogHeader from './Header/ActionLogHeader';
import ActionLogFilterPopup from './Popup/ActionLogFilterPopup';
import { useTranslation } from 'react-i18next';

const ActionLogBody = () => {
  const todayValue = new Date().toISOString().split('T')[0];

  const token = localStorage.getItem('token');
  const savedEcosystemId = localStorage.getItem('ecosystem');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { t, i18n } = useTranslation(); //for translation
  const [actionLogList, setActionLogList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [actionLogId, setActionLogId] = useState(null);

  const [filterActionLogDates, setfilterActionLogDates] = useState('');

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedActionLogIds, setSelectedActionLogIds] = useState([]);

  //* Function for get all modules
  const getAllActionLogs = async () => {
    setRowSelection({});
    setSelectedActionLogIds([]);
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ACTION_LOGS + `?token=${token}`;

      if (filterActionLogDates && filterActionLogDates !== '') {
        requestUrl += `&filterdates=${filterActionLogDates}`;
      } else {
        requestUrl += `&filterdates=${todayValue}`;
      }

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setActionLogList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* Function for reset filter
  const resetFilterData = () => {
    setfilterActionLogDates('');
  };

  /*
   * Material React Table Column and States
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'fullname',
        header: t('User'),
      },
      {
        accessorKey: 'email',
        header: t('Email'),
      },
      {
        accessorKey: 'module',
        header: t('Module'),
      },
      {
        accessorKey: 'action',
        header: t('Action'),
      },
      {
        accessorKey: 'ipaddress',
        header: t('IP Address'),
      },
      {
        accessorKey: 'systeminfo',
        header: t('System Information'),
      },
      {
        accessorKey: 'actiondateformated',
        header: t('Date'),
      },
      {
        accessorKey: 'url',
        header: t('Url'),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    if (filterActionLogDates != '') {
      getAllActionLogs();
    }
  }, [filterActionLogDates]);

  useEffect(() => {
    getAllActionLogs();
  }, []);

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedActionLogIds(selectedIdsArray);
    } else {
      setSelectedActionLogIds([]);
    }
  }, [rowSelection]);

  if (userInfo.role.slug === 'ADMIN' || userInfo.role.slug === 'SUPER_ADMIN') {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <ActionLogHeader reloadList={getAllActionLogs} />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={actionLogList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  // enableRowSelection // enable showing checkbox
                  // getRowId={(row) => row._id} // map which value to select with row checkbox
                  // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  // state={{ rowSelection }} //pass our managed row selection state to the table to use
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>

          <ActionLogFilterPopup setFilterDateRange={setfilterActionLogDates} />
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ActionLogBody;
