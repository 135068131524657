/* eslint-disable */
import React, { useEffect } from "react";

import Header from "components/Common/Header/Header";
import ProjectMemberBody from "components/ProjectManagementComponents/ProjectDetailsComponents/ProjectMemberComponents/ProjectMemberBody/ProjectMemberBody";

const ProjectMembers = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Members";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectMemberBody />
    </main>
  );
};

export default ProjectMembers;
