/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import CrmLeadCommunicationBody from "components/CRMComponents/CRMLeadInformationComponents/CRMLeadCommunicationComponents/CrmLeadCommunicationBody/CrmLeadCommunicationBody";

const CrmLeadCommunication = () => {
  useEffect(() => {
    document.title = "CRM Lead Information | Communication";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmLeadCommunicationBody />
      {/* ----- add label modal ----- */}
      <AddLabelModal moduleName="crmleadcommunication" />
    </main>
  );
};

export default CrmLeadCommunication;
