import React from "react";

const CrmContactTimeLineLeftPanel = ({ timeLinesData }) => {
  return (
    <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
      {/* <ul className="timeline">
        <li className="position-relative">
          <span className="d-inline-block fs-md fw-semibold px-2 py-1 bg-gray-300 rounded-90 position-sm-absolute top-0 start-0 mb-3 mb-sm-0">
            Today
          </span>
          <ul className="d-flex flex-column">
            <li className="comment position-relative">
              <div className="icon d-inline-block p-1 gradient-light rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">
                  mark_unread_chat_alt
                </span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">Pierre</span>
                <span>Comments on your project</span>
              </p>
              <p className="date fs-xs mb-2">Mar 27 2:35 PM</p>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text.
              </p>
            </li>
          </ul>
        </li>
        <li className="position-relative">
          <span className="d-inline-block fs-md fw-semibold px-2 py-1 bg-gray-300 rounded-90 position-sm-absolute top-0 start-0 mb-3 mb-sm-0">
            Tomorrow
          </span>
          <ul className="d-flex flex-column">
            <li className="check position-relative">
              <div className="icon d-inline-block p-1 gradient-dark text-white rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">
                  done_all
                </span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">Pierre</span>
                <span>subscribed a course</span>
              </p>
              <p className="date fs-xs mb-2">Mar 27 2:35 PM</p>
              <p>Subscribed the you sent</p>
            </li>
            <li className="check position-relative">
              <div className="icon d-inline-block p-1 gradient-dark text-white rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">
                  done_all
                </span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">You</span>
                <span>Accepted the Project</span>
              </p>
              <p className="date fs-xs mb-2">Mar 26 12:45 PM</p>
            </li>
            <li className="sent-project-request position-relative">
              <div className="icon d-inline-block p-1 gradient-light rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">
                  description
                </span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">Pierre</span>
                <span>sent you a project request</span>
              </p>
              <p className="date fs-xs mb-2">Mar 26 10:32 PM</p>
            </li>
          </ul>
        </li>
        <li className="position-relative">
          <span className="d-inline-block fs-md fw-semibold px-2 py-1 bg-gray-300 rounded-90 position-sm-absolute top-0 start-0 mb-3 mb-sm-0">
            Mar 26,2023
          </span>
          <ul className="d-flex flex-column">
            <li className="check position-relative">
              <div className="icon d-inline-block p-1 gradient-dark text-white rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">
                  done_all
                </span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">Pierre</span>
                <span>joined a group</span>
              </p>
              <p className="date fs-xs mb-2">Mar 27 2:35 PM</p>
              <p>Successfully joined to Lobees group</p>
            </li>
            <li className="mail position-relative">
              <div className="icon d-inline-block p-1 gradient-light rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">mail</span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">You</span>
                <span>sent you a mail</span>
              </p>
              <p className="date fs-xs">Mar 27 2:35 PM</p>
            </li>
            <li className="mail position-relative">
              <div className="icon d-inline-block p-1 gradient-light rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">mail</span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">Pierre</span>
                <span>sent you a connect request</span>
              </p>
              <p className="date fs-xs mb-2">Mar 27 2:35 PM</p>
              <p>Hi Dan please send accept my request</p>
            </li>
          </ul>
        </li>
        <li className="position-relative">
          <span className="d-inline-block fs-md fw-semibold px-2 py-1 bg-gray-300 rounded-90 position-sm-absolute top-0 start-0 mb-3 mb-sm-0">
            Mar 26,2023
          </span>
          <ul className="d-flex flex-column">
            <li className="check position-relative">
              <div className="icon d-inline-block p-1 gradient-dark text-white rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">
                  done_all
                </span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">Pierre</span>
                <span>joined a group</span>
              </p>
              <p className="date fs-xs mb-2">Mar 27 2:35 PM</p>
              <p>Successfully joined to Lobees group</p>
            </li>
            <li className="mail position-relative">
              <div className="icon d-inline-block p-1 gradient-light rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">mail</span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">You</span>
                <span>sent you a mail</span>
              </p>
              <p className="date fs-xs">Mar 27 2:35 PM</p>
            </li>
            <li className="mail position-relative">
              <div className="icon d-inline-block p-1 gradient-light rounded-circle position-absolute start-0 top-0">
                <span className="d-block material-symbols-outlined">mail</span>
              </div>
              <p className="fs-md fw-semibold mb-0">
                <span className="text-primary">Pierre</span>
                <span>sent you a connect request</span>
              </p>
              <p className="date fs-xs mb-2">Mar 27 2:35 PM</p>
              <p>Hi Dan please send accept my request</p>
            </li>
          </ul>
        </li>
      </ul> */}
      <ul className="timeline">
        {timeLinesData.map((timeLineInfo, index) => {
          return (
            <li className="position-relative" key={index}>
              <span className="d-inline-block fs-md fw-semibold px-2 py-1 bg-gray-300 rounded-90 position-sm-absolute top-0 start-0 mb-3 mb-sm-0">
                {timeLineInfo.month}, {timeLineInfo.year}
              </span>
              <ul className="d-flex flex-column">
                {timeLineInfo.timelines.map((timeData, index2) => {
                  return (
                    <li className="mail position-relative" key={index2}>
                      <div className="icon d-inline-block p-1 gradient-light rounded-circle position-absolute start-0 top-0">
                        <span className="d-block material-symbols-outlined">
                          person
                        </span>
                      </div>
                      <p className="fs-md fw-semibold mb-0">
                        <span className="text-primary me-1">
                          {timeData.person}
                        </span>
                        <span>{timeData.description}</span>
                      </p>
                      <p className="date fs-xs mb-2">{timeData.timetext}</p>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CrmContactTimeLineLeftPanel;
