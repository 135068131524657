import React from "react";
import { useTranslation } from "react-i18next";

const AddEditPortfolioLinkModal = ({
  portfolioIndex,
  setPortfolioIndex,
  portfolioData,
  setPortfolioData,
  portfolioBlock,
  setPortfolioBlock,
}) => {
  const { t } = useTranslation(); //for translation

  //function for add edcuation block
  const addPortfolioLinkHandler = () => {
    setPortfolioBlock([...portfolioBlock, portfolioData]);
    closeModalHandler();
  };

  //function for edit education block
  const editPortfolioBlockHandler = () => {
    const updatedExperienceList = [...portfolioBlock];
    updatedExperienceList[portfolioIndex] = portfolioData;
    setPortfolioBlock(updatedExperienceList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    setPortfolioData("");
    setPortfolioIndex(null);
  };

  return (
    <div
      className="modal fade"
      id="savePortfolioLinkModal"
      tabIndex="-1"
      aria-labelledby="addEducationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          {/* ------ modal head section start ----- */}
          <div className="modal-header p-4 pb-0 border-0">
            {/* ------ modal title start ----- */}
            <h2 className="fw-bold mb-0" id="addEducationModalLabel">
              {portfolioIndex != null
                ? t("Update Portfolio Link")
                : t("Add Portfolio Link")}
            </h2>
            {/* ------ modal title end ----- */}

            {/* ------ modal close button start ----- */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
            {/* ------ modal close button end ----- */}
          </div>
          {/* ------ modal head section end ----- */}

          {/* ------ modal body section start ----- */}
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* <!-- ========== Start link input Section ========== --> */}
              <div className="form-group mb-4">
                {" "}
                <label
                  htmlFor="Grade"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Enter portfolio link")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Link")}
                  value={portfolioData}
                  onChange={(e) => {
                    setPortfolioData(e.target.value);
                  }}
                />
              </div>

              {/* <!-- ========== End link input Section ========== --> */}

              {/* ------ add/edit button start ----- */}
              <div className="action">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={
                    portfolioIndex != null
                      ? editPortfolioBlockHandler
                      : addPortfolioLinkHandler
                  }
                >
                  {portfolioIndex != null
                    ? t("Update Portfolio Link")
                    : t("Add Portfolio Link")}
                </button>
              </div>
              {/* ------ add/edit button end ----- */}
            </form>
          </div>
          {/* ------ modal body section end ----- */}
        </div>
      </div>
    </div>
  );
};

export default AddEditPortfolioLinkModal;
