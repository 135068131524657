import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ModuleListBody from "components/AdministrationComponents/ModuleComponents/ModuleListBody/ModuleListBody";

const ModuleList = () => {
  useEffect(() => {
    document.title = "Administration | Modules";
  }, []);
  return (
    <main id="app">
      <Header moduleName="administration" />
      <ModuleListBody />
    </main>
  );
};

export default ModuleList;
