import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import CompanyDetailsCommonHeader from '../../CompanyDetailsCommonHeader/CompanyDetailsCommonHeader';
import { useParams } from 'react-router-dom';
import CompanyFAQHeader from '../CompanyFAQHeader/CompanyFAQHeader';

const CompanyFAQBody = () => {
  const params = useParams();

  // dummy data --------------------
  const dummyData = [
    {
      qa: {
        question: 'How can you put question here?',
        answer:
          'Material Symbols are our newest icons consolidating over 2,500 glyphs in a single font file with a wide range of design variants. Symbols are available',
      },
      date: '15-12-2022',
      number: '01',
    },
    {
      qa: {
        question: 'How can you put question here?',
        answer:
          'Material Symbols are our newest icons consolidating over 2,500 glyphs in a single font file with a wide range of design variants. Symbols are available',
      },
      date: '15-12-2022',
      number: '01',
    },
    {
      qa: {
        question: 'How can you put question here?',
        answer:
          'Material Symbols are our newest icons consolidating over 2,500 glyphs in a single font file with a wide range of design variants. Symbols are available',
      },
      date: '15-12-2022',
      number: '01',
    },
    {
      qa: {
        question: 'How can you put question here?',
        answer:
          'Material Symbols are our newest icons consolidating over 2,500 glyphs in a single font file with a wide range of design variants. Symbols are available',
      },
      date: '15-12-2022',
      number: '01',
    },
    {
      qa: {
        question: 'How can you put question here?',
        answer:
          'Material Symbols are our newest icons consolidating over 2,500 glyphs in a single font file with a wide range of design variants. Symbols are available',
      },
      date: '15-12-2022',
      number: '01',
    },
    {
      qa: {
        question: 'How can you put question here?',
        answer:
          'Material Symbols are our newest icons consolidating over 2,500 glyphs in a single font file with a wide range of design variants. Symbols are available',
      },
      date: '15-12-2022',
      number: '01',
    },
    {
      qa: {
        question: 'How can you put question here?',
        answer:
          'Material Symbols are our newest icons consolidating over 2,500 glyphs in a single font file with a wide range of design variants. Symbols are available',
      },
      date: '15-12-2022',
      number: '01',
    },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: 'Question / Answer',
        size: 800,
        Cell: ({ row }) => (
          <td className="question-answer border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.qa.question}
                </p>
                <p className="text-gray">{row.original.qa.answer}</p>
              </div>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'date',
        header: 'To Date',
        Cell: ({ row }) => (
          <td className="to-date border-bottom-0">
            <p>{row.original.date}</p>
          </td>
        ),
      },
      {
        accessorKey: 'number',
        header: 'Question Number',
        Cell: ({ row }) => (
          <td className="question-number border-bottom-0">
            <p>{row.original.number}</p>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ---- companies common header ---- */}
        {params.id && <CompanyDetailsCommonHeader moduleName="faq" />}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* --------- filter area -------- */}
          <CompanyFAQHeader />

          {/* --------- question area --------- */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="question-answer bg-transparent fs-md fw-semibold border-0"
                      width="100%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllQuestions"
                            name="question"
                          />
                        </div>
                        <span className="d-block ms-3">Question / Answer</span>
                      </div>
                    </th>
                    <th className="to-date bg-transparent fs-md fw-semibold border-0">
                      To Date
                    </th>
                    <th className="question-number bg-transparent fs-md fw-semibold border-0">
                      Question Number
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question1"
                            value="question1"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question2"
                            value="question2"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question3"
                            value="question3"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question4"
                            value="question4"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question5"
                            value="question5"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question6"
                            value="question6"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question7"
                            value="question7"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question8"
                            value="question8"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question9"
                            value="question9"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="question-answer border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="question"
                            id="question10"
                            value="question10"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            How can you put question here?
                          </p>
                          <p className="text-gray">
                            Material Symbols are our newest icons consolidating
                            over 2,500 glyphs in a single font file with a wide
                            range of design variants. Symbols are available
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="question-number border-bottom-0">
                      <p>01</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={dummyData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompanyFAQBody;
