/* eslint-disable */
import React, { useState, useEffect } from "react";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";
import { assetImages } from "constants";
import TaskStatusOptions from "data/Prod/TaskStatus.json";
import { useTranslation } from "react-i18next";

const LogDetailsModal = ({ selectedLogId, onModalClose }) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [taskTitle, setTaskTitle] = useState("");
  const [taskSection, settaskSection] = useState("");
  const [endDate, setendDate] = useState("");
  const [hours, setHours] = useState("");
  const [taskModerator, setTaskModerator] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [assigndUser, setAssigndUser] = useState("");
  const [assignedUserImage, setAssignedUserImage] = useState("");
  const [taskDetails, settaskDetails] = useState("");

  const getLogDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LOG_DETAILS +
        `/${selectedLogId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTaskTitle(response.data.tasktitle);
        settaskSection(response.data.tasksection);
        setendDate(response.data.enddate);
        setHours(response.data.hours);
        setTaskModerator(response.data.projectmoderator);
        {
          TaskStatusOptions.map((taskStatus) => {
            if (taskStatus.value == response.data.projectstatus) {
              setTaskStatus(taskStatus.label);
            }
          });
        }
        setAssigndUser(response.data.username);
        setAssignedUserImage(response.data.userimage);
        settaskDetails(response.data.description);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedLogId) {
      getLogDetails();
    }
  }, [selectedLogId]);

  return (
    <div
      className="modal fade"
      id="logDetails"
      tabIndex="-1"
      aria-labelledby="logDetailsLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="logDetailsLabel">
              {t("Log Details")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onModalClose}
            ></button>
          </div>
          <div className="modal-body p-4">
            <p className="fs-lg fw-semibold mb-0">{taskTitle}</p>
            <p className="fs-md fw-semibold text-gray">{taskSection}</p>
            <div className="row gx-2 mb-2">
              <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">{t("Deadline")}</p>
                  <p className="fs-md fw-semibold lh-1">{endDate}</p>
                </div>
              </div>
              <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">{t("Hours")}</p>
                  <p className="fs-md fw-semibold lh-1">
                    {hours} {t("Hours")}
                  </p>
                </div>
              </div>
              {/* <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">Moderator</p>
                  <p className="fs-md fw-semibold lh-1">{taskModerator}</p>
                </div>
              </div>
              <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">Status</p>
                  <p className="fs-md fw-semibold lh-1">{taskStatus}</p>
                </div>
              </div> */}
            </div>
            <div className="row gx-2 mb-3">
              {/* <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">Dedline</p>
                  <p className="fs-md fw-semibold lh-1">{endDate}</p>
                </div>
              </div>
              <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">Hours</p>
                  <p className="fs-md fw-semibold lh-1">{hours} Hours</p>
                </div>
              </div> */}
              <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">{t("Moderator")}</p>
                  <p className="fs-md fw-semibold lh-1">{taskModerator}</p>
                </div>
              </div>
              <div className="col-6">
                <div className="p-3 body-bg rounded-10">
                  <p className="fs-sm mb-0">{t("Status")}</p>
                  <p className="fs-md fw-semibold lh-1">{taskStatus}</p>
                </div>
              </div>
            </div>
            <div className="profile d-flex gap-2 align-items-center mb-3">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "32px", height: "32px" }}
              >
                <img
                  src={
                    assignedUserImage == ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + assignedUserImage
                  }
                  alt="Roma Keller"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div className="name">
                <p className="fs-md fw-bold mb-0">{assigndUser}</p>
              </div>
            </div>
            <p dangerouslySetInnerHTML={{ __html: taskDetails }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogDetailsModal;
