import React from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const GroupProductDetailsCommonHeader = ({ activeTab }) => {
  const params = useParams();
  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid d-flex align-items-center gap-2 gap-sm-4 px-lg-5">
        <Link
          to={`/admin/group/product/add/${params.id}`}
          className="back-btn d-flex flex-shrink-0 align-items-center justify-content-center body-bg text-gray rounded-circle"
        >
          <span className="d-block material-symbols-outlined">arrow_back</span>
        </Link>
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-lg-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="nav-item">
            <Link
              to={`/admin/group/product/vital-info/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === 'vitalInfo' && 'active'
              }`}
            >
              Vital Info
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/group/product/variations/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === 'variations' && 'active'
              }`}
            >
              Variations
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/group/product/offers/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === 'offers' && 'active'
              }`}
            >
              Offers
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/group/product/images/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === 'images' && 'active'
              }`}
            >
              Images
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/group/product/warranty/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === 'warranty' && 'active'
              }`}
            >
              Warranty
            </Link>
          </li>
        </ul>
        <button type="submit" className="btn btn-primary text-nowrap ms-auto">
          Save Now
        </button>
      </div>
    </div>
  );
};

export default GroupProductDetailsCommonHeader;
