import Header from 'components/Common/Header/Header';
import GroupEventListBody from 'components/GroupComponents/GroupDetails/GroupEvent/GroupEventList/GroupEventListBody/GroupEventListBody';

import React from 'react';

const GroupEventList = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ----------- body section ------------ */}
      <GroupEventListBody />
    </>
  );
};

export default GroupEventList;
