/* eslint-disable */
import React, { useEffect } from "react";

import Header from "components/Common/Header/Header";
import ProjectSectionBody from "components/ProjectManagementComponents/ProjectDetailsComponents/ProjectTaskComponents/ProjectSectionBody/ProjectSectionBody";

const ProjectSectionTask = () => {
  useEffect(() => {
    document.title = "Project Management";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      <ProjectSectionBody />
    </main>
  );
};

export default ProjectSectionTask;
