import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const OfferDiscountModal = () => {
  return (
    <div
      className="modal fade"
      id="addOffer"
      tabIndex="-1"
      aria-labelledby="addOfferLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addOfferLabel">
              Add offer and Discounts
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form action="">
              <div className="form-group mb-4">
                <label
                  htmlFor="startDate"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control fs-sm shadow-none"
                  placeholder="Select Date"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="endDate"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control fs-sm shadow-none"
                  placeholder="Select Date"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="minUnit"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Min Unit
                </label>
                <input
                  type="text"
                  id="minUnit"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Unit"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="maxUnit"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Max Unit
                </label>
                <input
                  type="text"
                  id="maxUnit"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Unit"
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="discount"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Discount %
                </label>
                <input
                  type="text"
                  id="discount"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Amount"
                />
              </div>
              <div className="action d-flex gap-3 flex-wrap justify-content-between">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferDiscountModal;
