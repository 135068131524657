import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AppUserAvailibilityBody from "components/AppUserComponents/AppUserDetails/AppUserAvailibility/AppUserAvailibilityBody/AppUserAvailibilityBody";

const AppUserAvailibility = () => {
  useEffect(() => {
    document.title = "App user | Agenda";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="appuser" />

      <AppUserAvailibilityBody />
    </main>
  );
};

export default AppUserAvailibility;
