/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
import SaveRolePopup from '../Popup/SaveRolePopup';
import RoleHeader from '../Header/RoleHeader';
import RoleFilterPopup from '../Popup/RoleFilterPopup';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';

const RoleListBody = () => {
  const token = localStorage.getItem('token');
  const currentEcosystemSlug = localStorage.getItem('ecosystemslug');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { t, i18n } = useTranslation(); //for translation
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [roleId, setRoleId] = useState(null);

  const [filterRoleName, setFilterRoleName] = useState('');
  const [filterRoleSlug, setFilterRoleSlug] = useState('');
  const [filterFeatured, setfilterFeatured] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectRoleIds, setSelectRoleIds] = useState([]);

  //function for get all tags
  const getAllRoles = async () => {
    setRowSelection({});
    setSelectRoleIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_ROLES + `?token=${token}`;

      if (filterRoleName !== '') {
        requestUrl = requestUrl + `&filtername=${filterRoleName}`;
      }

      if (filterRoleSlug !== '') {
        requestUrl = requestUrl + `&filterslug=${filterRoleSlug}`;
      }

      if (filterFeatured === true) {
        requestUrl = requestUrl + `&filterfeatured=${filterFeatured}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setRoleList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectRoleIds) {
      try {
        let tagData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_ROLE +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, tagData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllRoles();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterRoleName('');
    setFilterRoleSlug('');
    setfilterFeatured(false);
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#saveRole"
            aria-controls="saveRole"
            onClick={() => {
              setRoleId(row.original.value);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'label',
        header: t('Name'),
        size: 250,
      },
      {
        accessorKey: 'slug',
        header: t('Slug'),
        size: 250,
      },
      {
        accessorKey: 'feturedstring',
        header: t('Is Featured'),
        size: 300,
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    if (
      filterRoleName != '' ||
      filterRoleSlug != '' ||
      filterFeatured === true
    ) {
      getAllRoles();
    }
  }, [filterRoleName, filterRoleSlug, filterFeatured]);

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectRoleIds(selectedIdsArray);
    } else {
      setSelectRoleIds([]);
    }
  }, [rowSelection]);

  //initialize the column order
  const initialColumnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  if (userInfo.role.slug === 'ADMIN' || userInfo.role.slug === 'SUPER_ADMIN') {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <RoleHeader
              reloadList={getAllRoles}
              changeStatusHandler={changeStatusHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={roleList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== popup and modal section ==== */}
        <SaveRolePopup
          afterPopupCLose={getAllRoles}
          roleId={roleId}
          setRoleId={setRoleId}
        />

        <RoleFilterPopup
          setFilterRoleName={setFilterRoleName}
          setFilterRoleSlug={setFilterRoleSlug}
          setfilterFeatured={setfilterFeatured}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default RoleListBody;
