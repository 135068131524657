/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { assetImages } from "constants";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import SaveInvoiceForm from "components/Common/SaveInvoiceForm/SaveInvoiceForm";
import SaveOpportunityHeader from "../Header/SaveOpportunityHeader";

import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";

const SaveOpportunityBody = () => {
  const moduleSlug = "opportunity";

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LEAD"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Opportunities")}
          />
          <div className="container-fluid px-lg-5">
            <SaveOpportunityHeader />
            <SaveInvoiceForm
              leadId={null}
              contactPersonId={null}
              contactId={null}
              moduleName={""}
              moduleSlug={moduleSlug}
            />
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {" "}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};

export default SaveOpportunityBody;
