import { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AppUserProfileInfoBody from "components/AppUserComponents/AppUserDetails/AppUserProfileInfo/AppUserProfileInfoBody/AppUserProfileInfoBody";

const AppUserProfileInfo = () => {
  useEffect(() => {
    document.title = "App user";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="appuser" />
      <AppUserProfileInfoBody />
    </main>
  );
};

export default AppUserProfileInfo;
