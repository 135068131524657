import React from 'react';
import { useHistory } from 'react-router-dom';

const EventTypeModal = () => {
  const history = useHistory(); // Get the history object from the hook
  return (
    <div
      className="modal fade"
      id="eventType"
      tabIndex="-1"
      aria-labelledby="eventTypeLabel"
      aria-hidden="true"
      data-bs-dismiss="modal"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="eventTypeLabel">
              Event Type
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form action="">
              <p className="fs-lg mb-4">Select one from below to proceed</p>
              <div className="form-group mb-4">
                <div className="row gx-3">
                  <div className="col-6">
                    <input
                      type="radio"
                      name="eventType"
                      id="crowdfunding"
                      className="d-none"
                    />
                    <label
                      htmlFor="crowdfunding"
                      className="type d-block p-4 text-center body-bg border border-2 border-gray-bg rounded-10 cursor-pointer"
                    >
                      <img
                        src="/assets/img/crowdfunding.png"
                        alt="Crowdfunding"
                        className="img-fluid mb-4"
                      />
                      <h3 className="mb-0">Crowdfunding</h3>
                    </label>
                  </div>
                  <div className="col-6">
                    <input
                      type="radio"
                      name="eventType"
                      id="networking"
                      className="d-none"
                    />
                    <label
                      htmlFor="networking"
                      className="type d-block p-4 text-center body-bg border border-2 border-gray-bg rounded-10 cursor-pointer"
                    >
                      <img
                        src="/assets/img/networking.png"
                        alt="Networking"
                        className="img-fluid mb-4"
                      />
                      <h3 className="mb-0">Networking</h3>
                    </label>
                  </div>
                </div>
              </div>
              <div className="action">
                <button
                  onClick={() => history.push('/admin/group/events/add')}
                  type="button"
                  onclick="window.location.href='group-add-event.html'"
                  className="btn btn-primary w-100"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTypeModal;
