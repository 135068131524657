import Header from 'components/Common/Header/Header';
import ProjectODSBody from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectODSComponents/ProjectODSBody/ProjectODSBody';
import React, { useEffect } from 'react';

const ProjectODS = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = 'Project Management';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectODSBody />
    </main>
  );
};

export default ProjectODS;
