/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';

//Material UI components for rendring menu and others
import { Box, MenuItem } from '@mui/material';

import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import SuccessStoriesHeader from '../Header/SuccessStoriesHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import SuccessStoryFilterpopup from '../Popup/SuccessStoryFilterpopup';

//import images
import { assetImages } from 'constants';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData, putData } from 'utils/Gateway';

const SuccessStoriesListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [isLoading, setIsLoading] = useState(false);
  const [storyList, setStoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterStoryTitle, setFilterStoryTitle] = useState('');
  const [filterStoryLabels, setFilterStoryLabels] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedStoryIds, setSelectedStoryIds] = useState([]);

  // Update the rank value in the blogList state array
  const rankInputChangeHandler = (row, value) => {
    const updatedStoryList = [...storyList];
    const rowIndex = updatedStoryList.findIndex(
      story => story._id === row.original._id
    );
    if (rowIndex !== -1) {
      updatedStoryList[rowIndex].rank = value;
      setStoryList(updatedStoryList);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all blogs
  const getAllSuccessStories = async () => {
    setRowSelection({});
    setSelectedStoryIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_SUCCESS_STORIES +
        `?token=${token}&roleslug=${userInfo.role.name}&ecosystemid=${userInfo.ecosystemid}`;

      //search by date
      if (filterDateRange != '') {
        requestUrl = requestUrl + `&storydates=${filterDateRange}`;
      }

      //search by story name
      if (filterStoryTitle != '') {
        requestUrl = requestUrl + `&storytitle=${filterStoryTitle}`;
      }

      //search by tags
      if (filterStoryLabels.length > 0) {
        requestUrl = requestUrl + `&tags=${filterStoryLabels}`;
      }

      console.log('url of all success story ------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('success story list response------>', response);

      if (response.status) {
        setIsLoading(false);
        setStoryList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status zero for deactivate
  const changeStatusHandler = async () => {
    // Initialize an array to store response statuses from API calls.
    let responseArr = [];

    // Loop through each checkbox.
    for (let storyId of selectedStoryIds) {
      // Check if the checkbox is checked.

      try {
        // Create an object with the desired data to send to the API.
        let storyData = {
          status: '0',
        };

        // Build the URL for the API request, including the checkbox value and token.
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_SUCCESS_STORY +
          `/${storyId}` +
          `?token=${token}`;

        // Send a PUT request to the API with the storyData.
        const response = await putData(requestUrl, storyData);

        // If the API call is successful (status exists), add the status to responseArr.
        if (response.status) {
          responseArr.push(response.status);
        }
      } catch (error) {
        // Handle any errors that occur during the API call.
        console.log(error.message);
      }
    }

    // If there are successful responses, trigger the getAllSuccessStories function.
    if (responseArr.length > 0) {
      getAllSuccessStories();
    }
  };

  //function for update rank
  const updateRankHandler = async () => {
    let responseArr = [];

    //run loop checkbox wise
    for (let storyId of selectedStoryIds) {
      //check if checkbox was checked.If checked then proceed

      //run loop of updated bloglist
      for (let story of storyList) {
        let storyData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (story._id == storyId) {
          storyData.rank = story.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_SUCCESS_STORY +
              `/${storyId}` +
              `?token=${token}`;

            const response = await putData(requestUrl, storyData);

            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }

    if (responseArr.length > 0) {
      getAllSuccessStories();
    }
  };

  //function for after tag modal close
  const afterTagModalClose = () => {
    getAllTags();
    getAllSuccessStories();
  };

  //function for reload lead list by clicking refresh icon
  const reloadStoryList = () => {
    getAllSuccessStories();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setFilterStoryTitle('');
    setFilterStoryLabels([]);
  };

  //use effect for search filter popup
  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterStoryTitle != '' ||
      filterStoryLabels.length > 0
    ) {
      getAllSuccessStories();
    }
  }, [filterDateRange, filterStoryTitle, filterStoryLabels]);

  useEffect(() => {
    getAllTags();
    getAllSuccessStories();
  }, []);

  /*------ Material React Table Column and States ---- */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Title',
      },
      {
        accessorKey: 'rank',
        header: 'Rank',
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              width: '150px',
            }}
          >
            <input
              // loading="lazy"
              type="number"
              name="rank"
              id="rank"
              value={row.original.rank}
              onChange={e => rankInputChangeHandler(row, e.target.value)}
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
            />
          </Box>
        ),
      },
      {
        accessorKey: 'projectname',
        header: 'Project',
      },
      {
        accessorKey: 'companyname',
        header: 'Company',
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              width: '200px',
            }}
          >
            <img
              height={30}
              width={30}
              src={url.SERVER_URL + row.original.companylogo}
              loading="lazy"
              style={{ borderRadius: '50%' }}
            />
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: 'createddate',
        header: 'Date',
      },
      {
        accessorKey: 'tagstring',
        header: 'Label',
      },
    ],
    [storyList]
  );

  useEffect(() => {
    //do something when the row selection changes...
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedStoryIds(selectedIdsArray);
    } else {
      setSelectedStoryIds([]);
    }
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="blog-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          <SuccessStoriesHeader
            reloadStoryList={reloadStoryList}
            changeStatusHandler={changeStatusHandler}
            updateRankHandler={updateRankHandler}
          />

          {/* ----- story list table start ----- */}
          {isLoading ? null : (
            <div className="table-wrapper">
              {/* ------ pagination section end ------ */}
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={storyList} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                getRowId={row => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                enableRowActions // show the action column
                renderRowActionMenuItems={({ row }) => [
                  <MenuItem
                    key="edit"
                    //onClick={() => console.info(row.id)}
                  >
                    <Link
                      className="dropdown-item"
                      to={`/admin/successstories/save/${row.id}`}
                    >
                      View Details
                    </Link>
                  </MenuItem>,
                  ,
                ]} // action columns menu items
                muiTableContainerProps={{
                  sx: {
                    maxHeight: '60vh',
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
          {/* ----- story list table end ----- */}
        </div>
      </section>

      {/* ======== all modal and pop up render here ======= */}
      <AddLabelModal
        selectedIds={selectedStoryIds}
        moduleName="successstory"
        afterTagModalClose={afterTagModalClose}
      />

      {/* successstory filter search popup */}
      <SuccessStoryFilterpopup
        tagList={tagList}
        setFilterDateRange={setFilterDateRange}
        setFilterStoryTitle={setFilterStoryTitle}
        setFilterStoryLabels={setFilterStoryLabels}
      />
    </div>
  );
};

export default SuccessStoriesListBody;
