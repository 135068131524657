/* eslint-disable */
import AffiliateRequestBody from 'components/AffiliateComponents/AffiliateRequestComponents/AffiliateRequestBody/AffiliateRequestBody';
import Header from 'components/Common/Header/Header';
import React, { useEffect } from 'react';

const AffiliateRequests = () => {
  useEffect(() => {
    document.title = 'Affiliate Requests';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="affiliation" />

      {/* ---- body section ---- */}
      <AffiliateRequestBody />
    </main>
  );
};

export default AffiliateRequests;
