/* eslint-disable */
import { assetImages } from "constants";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const CrmLeadInformationCommonHeader = ({ moduleName }) => {
  // loggedin user details to get the role
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // get the role and allow for admin or superadmin
  const currentUserRole = userInfo.role ? userInfo.role.slug : "USER";

  // get module access for roles other than admin and superadmin
  const moduleaccess = localStorage.getItem("moduleaccess");

  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  const [leadName, setleadName] = useState("");
  const [leadImage, setleadImage] = useState("");
  const [isAuthenticatedUser, setisAuthenticatedUser] = useState(false);

  const params = useParams();

  //function for get lead details
  const getLeadDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LEAD_DETAILS +
        `/${params.id}?token=${token}`;

      // console.log("url of lead details------>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response of lead details------>", response);

      if (response.status) {
        if (
          response.data.moderator._id.toString() === userInfo._id.toString()
        ) {
          setisAuthenticatedUser(true);
        }

        const respondedLeadName = response.data.name
          ? `${response.data.name ?? ""} ${response.data.surname ?? ""}`
          : `${response.data.email}`;
        setleadName(respondedLeadName);
        if (response.data?.photoimage) {
          setleadImage(response.data?.photoimage?.path);
        } else {
          setleadImage("");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getLeadDetails();
    }
  }, [params.id]);

  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid d-flex align-items-center gap-2 gap-sm-4 px-lg-5">
        {/* ------- back arrow icon link start -------- */}
        <Link
          to="/admin/crm/lead/list"
          className="back-btn d-flex flex-shrink-0 align-items-center justify-content-center body-bg text-gray rounded-circle"
        >
          <span className="d-block material-symbols-outlined">arrow_back</span>
        </Link>
        {/* ------- back arrow icon link end -------- */}
        {/* ------- all crm lead information link start -------- */}
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-lg-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="profile d-flex align-items-center gap-2 text-nowrap">
            <div
              className="avatar rounded-circle-overflow-hidden"
              style={{ width: "38px", height: "38px" }}
            >
              <img
                src={
                  leadImage === ""
                    ? assetImages.defaultUser
                    : url.SERVER_URL + leadImage
                }
                alt="Pierre Gagnaire"
                className="rounded-circle w-100 h-100 object-fit-cover object-center"
              />
            </div>
            <h3 className="mb-0">{leadName}</h3>
          </li>
          {/* ------- lead information details link start -------- */}
          <li className="nav-item">
            <Link
              to={`/admin/crm/lead/information/${params.id}`}
              className={`d-block text-black px-3 py-4 position-relative ${
                moduleName == "leadinformation" ? "active" : ""
              }`}
            >
              {t("Lead Information")}
            </Link>
          </li>
          {/* ------- lead information details link end -------- */}
          {/* ------- lead information add task link start -------- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          isAuthenticatedUser ? (
            <li className="nav-item">
              <Link
                to={`/admin/crm/lead/addtask/${params.id}`}
                className={`d-block text-black px-3 py-4 position-relative ${
                  moduleName == "addtask" ? "active" : ""
                }`}
              >
                {t("Add Task")}
              </Link>
            </li>
          ) : null}
          {/* ------- lead information add task link end -------- */}
          {/* ------- lead information communication link start -------- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          isAuthenticatedUser ? (
            <li className="nav-item">
              <Link
                to={`/admin/crm/lead/communication/${params.id}`}
                className={`d-block text-black px-3 py-4 position-relative ${
                  moduleName == "communication" ? "active" : ""
                }`}
              >
                {t("Communication")}
              </Link>
            </li>
          ) : null}
          {/* ------- lead information communication link end -------- */}

          {/* <!-- ========== Start opprtunity Section ========== --> */}
          {currentUserRole === "ADMIN" ||
          currentUserRole === "SUPER_ADMIN" ||
          moduleaccess.includes("MOD_OPPORTUNITY") ? (
            <li className="nav-item">
              <Link
                to={`/admin/crm/lead/opportunities/${params.id}`}
                className={`d-block text-black px-3 py-4 position-relative ${
                  moduleName == "opportunity" ? "active" : ""
                }`}
              >
                {t("Opportunities")}
              </Link>
            </li>
          ) : null}
          {/* <!-- ========== End opprtunity Section ========== --> */}
        </ul>
        {/* ------- all crm lead information link end -------- */}
      </div>
    </div>
  );
};
export default CrmLeadInformationCommonHeader;
