/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import time calculation
import { getCurrentTime } from "helper/TimeCalculation";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import fileDownloadHandler from "helper/DownloadFile";
import { assetImages } from "constants";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AfterSaveGroupModal from "../Modals/AfterSaveGroupModal";
import { groupDetailsHeaderLinks } from "helper/GroupHelper/GroupHelper";
import downloadFileHandler from "helper/DownloadFile";
import { ecosystemSlug } from "Config/Config";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

const GroupInfoBody = () => {
  const dateOfToday = new Date().toISOString().split("T")[0];
  const { t } = useTranslation(); //for translation
  const params = useParams();
  const history = useHistory();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Group"), link: "/admin/group/list" },
    { title: t("Group Info") },
  ];

  // -------- tabs header object --------------------
  const commonHeaderObject = groupDetailsHeaderLinks(params.id, t);

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type details here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const imageFileInputRef = useRef(null);
  const docFileInputRef = useRef(null);

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);

  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);

  const [groupTitle, setgroupTitle] = useState("");
  const [groupSummary, setgroupSummary] = useState("");
  const [groupDetails, setgroupDetails] = useState("");
  const [groupObjective, setgroupObjective] = useState("");
  const [isVirtual, setisVirtual] = useState(false);
  const [virtualUrl, setvirtualUrl] = useState("");
  const [location, setlocation] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [groupBudget, setgroupBudget] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [categoryData, setcategoryData] = useState([]);
  const [searchingValue, setsearchingValue] = useState(null);
  const [searchingData, setsearchingData] = useState([]);
  const [interestValue, setinterestValue] = useState(null);
  const [interestData, setinterestData] = useState([]);
  const [autoAccept, setautoAccept] = useState(false);
  const [isPrivate, setisPrivate] = useState(false);
  const [companyValue, setCompanyValue] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "GROUP";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllSearchingInterest = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      if (response) {
        setsearchingList(response);
        setinterestList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(
          response.data.map((item) => ({
            label: item.name,
            value: item._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    if (zipcode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

        const response = await getData(url);

        if (response.status === "OK") {
          const results = response.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setcountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setcityName(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setstateName(item?.long_name);
              }
              setlatitude(results[0]?.geometry?.location?.lat);
              setlongitude(results[0]?.geometry?.location?.lng);
            });
            // setMapCoordinate(
            //   `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            // );
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for select category
  const categorySelectionHandler = (val) => {
    setcategoryValue(val);
    const categoryArr = val.map((item) => {
      return item.value;
    });
    setcategoryData(categoryArr);
  };

  //function for select search
  const searchingSelectionHandler = (val) => {
    setsearchingValue(val);
    const searchArr = val.map((item) => {
      return item.value;
    });
    setsearchingData(searchArr);
  };

  //function for select interest
  const interestSelectionHandler = (val) => {
    setinterestValue(val);
    const interestArr = val.map((item) => {
      return item.value;
    });
    setinterestData(interestArr);
  };

  const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload doc

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(url.SERVER_URL + response.data.path);
        setUploadedImageId(response.data._id);
        resetgroupImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closegroupImageFileHandler = () => {
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetgroupImageFile();
  };

  // function for clear file value
  const resetgroupImageFile = () => {
    const file = document.getElementById("uploadedgroupImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for open doc imput
  const openDocFileInput = () => {
    docFileInputRef.current.click();
  };

  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);

      const docFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, docFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setDocFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetDocInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);

    resetDocInput();
  };

  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedgroupDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setCompanyValue(val);
      setCompanyId(val.value);
    } else {
      setCompanyValue(null);
      setCompanyId(null);
    }
  };

  //function for get group details
  const getgroupDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of group details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of group details------>", response);

      if (response.status) {
        //assign title
        response.data?.title && setgroupTitle(response.data?.title);

        //assign image
        response.data?.grouplogoid &&
          setUploadedImageId(response.data?.grouplogoid);
        setUploadedPreviewImage(url.SERVER_URL + response.data?.grouplogoimage);

        //assing summary
        setgroupSummary(response.data?.summary);

        companySelectionHandler(response.data?.companyvalue);

        //assign details
        setgroupDetails(response.data?.description);

        //assign objective
        setgroupObjective(response.data?.objective);

        //assign zipcode
        setzipcode(response.data?.zipcode);

        //assign city
        setcityName(response.data?.city);

        //assign state
        setstateName(response.data?.state);

        //assign country
        setcountry(response.data?.country);

        //assign location
        setlocation(response.data?.address);

        //assing lat
        setlatitude(response.data?.latitude);

        //assign long
        setlongitude(response.data?.longitude);

        //assign budget
        setgroupBudget(response.data?.budget);

        //assign category
        if (response.data?.categories && response.data?.categories.length > 0) {
          categorySelectionHandler(response.data?.categories);
        }

        //assign searchings
        if (response.data?.searchings && response.data?.searchings.length > 0) {
          searchingSelectionHandler(response.data?.searchings);
        }

        //assign interests
        if (response.data?.interests && response.data?.interests.length > 0) {
          interestSelectionHandler(response.data?.interests);
        }

        //assign uploaded files
        setUploadedDocFiles(
          response?.data?.uploadedfiles.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          )
        );
        setUploadedDocFileIds(response?.data?.uploadedfileids);

        //assing auto accept
        setautoAccept(response.data?.autoapproval);

        //assign private
        setisPrivate(response?.data?.isprivate);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save group
  const saveUpdategroupHandler = async () => {
    try {
      let groupData = {
        createdate: dateOfToday,
        title: groupTitle,
        summary: groupSummary,
        description: groupDetails,
        objective: groupObjective,
        address: location,
        city: cityName,
        state: stateName,
        zipcode: zipcode,
        country: country,
        latitude: latitude,
        longitude: longitude,
        budget: groupBudget,
        logoid: uploadedImageId,
        uploads: uploadedDocFileIds,
        categories: categoryData,
        searchings: searchingData,
        interests: interestData,
        autoapproval: autoAccept,
        isprivate: isPrivate,
        companyid: companyId,
      };

      let requestUrl = url.API_BASE_URL;

      if (params.id) {
        requestUrl =
          requestUrl +
          url.API_UPDATE_GROUP +
          `/${params.id}` +
          `?token=${token}`;
      } else {
        requestUrl = requestUrl + url.API_CREATE_GROUP + `?token=${token}`;
      }

      let response;

      if (params.id) {
        response = await putData(requestUrl, groupData);
      } else {
        response = await postData(requestUrl, groupData);
      }

      console.log(response);

      if (response.status) {
        resetHandler();
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("saveGroupModal")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function reset group form
  const resetHandler = () => {
    setgroupTitle("");
    setUploadedImageId(null);
    setUploadedPreviewImage("");
    setgroupSummary("");
    setgroupDetails("");
    setgroupObjective("");
    setcompanyInput("");
    setCompanyId(null);
    setCompanyValue(null);
    setzipcode("");
    setcityName("");
    setstateName("");
    setcountry("");
    setlocation("");
    setlatitude("");
    setlongitude("");
    setgroupBudget("");

    setcategoryValue(null);
    setcategoryData([]);
    setsearchingValue(null);
    setsearchingData([]);
    setinterestValue(null);
    setinterestData([]);
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
    setautoAccept(false);
    setisPrivate(false);
  };

  //function for close afterSavegroupModal
  const afterSavegroupModalClose = () => {
    history.push("/admin/group/list");
  };

  useEffect(() => {
    if (params.id) {
      getgroupDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllCategoryList();
    getAllSearchingInterest();
  }, []);

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_GROUP")
  ) {
    return (
      <div id="content_wrapper">
        <section className="group-details-wrapper bg-white pb-5">
          {/* --- common header --- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Group Info")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* --- bread crumb ---- */}
            <BreadCrumb breadCrumbText={breadcrumbText} />

            {/* --- form section ---- */}
            {/* <GroupInfoForm /> */}
            <div className="mt-3 p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              {/* ====== title section ======*/}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="title"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Title")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Name")}
                  value={groupTitle}
                  onChange={(e) => setgroupTitle(e.target.value)}
                />
              </div>

              {/* ===== summary section ====== */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="summary"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Summary")}
                </label>
                <textarea
                  name="summary"
                  id="summary"
                  cols="30"
                  rows="3"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Group Summary")}
                  value={groupSummary}
                  onChange={(e) => setgroupSummary(e.target.value)}
                ></textarea>
              </div>

              {/* ==== details section ==== */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="details"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Details")}
                </label>
                <CKEditor
                  className="form-control fs-sm shadow-none"
                  editor={ClassicEditor}
                  config={editorConfig}
                  data={groupDetails ? groupDetails : ""}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(group, editor) => {
                    let editorContent = editor.getData();
                    setgroupDetails(editorContent);
                  }}
                />
              </div>

              {/* ----- address section start ------ */}
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="address"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Address")}
                    </label>
                    <div className="field-container d-flex align-items-center pe-3 body-bg rounded-10">
                      <input
                        type="text"
                        className="form-control border-0 shadow-none"
                        placeholder={t("Location")}
                        value={location}
                        onChange={(e) => {
                          setlocation(e.target.value);
                        }}
                      />
                      <span className="d-block material-symbols-outlined icon-lg text-gray">
                        my_location
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="address"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Company")}
                    </label>

                    <Select
                      placeholder={t("Type Company name")}
                      isClearable
                      options={companyOptionsToShow}
                      value={companyValue}
                      onChange={(val) => {
                        companySelectionHandler(val);
                      }}
                      onInputChange={(val) => {
                        setcompanyInput(val);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* ----- address section end ------ */}

              {/* ----- zipcode,city,state,country section start ------ */}
              <div className="form-group mb-3">
                <div className="row">
                  {/* ----- zipcode section start ------ */}
                  <div className="col-lg-4 mb-3 mb-lg-0">
                    <div className="row">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder={t("Zipcode")}
                          value={zipcode}
                          onChange={(e) => {
                            setzipcode(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col">
                        <button
                          className="btn btn-primary"
                          onClick={getLocationFromZip}
                        >
                          {t("Fetch Location by zipcode")}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* ----- zipcode section end ------ */}

                  {/* ----- city,state,country section start ------ */}
                  <div className="col-lg-8">
                    <div className="row">
                      {/* ----- city section start ------ */}
                      <div className="col-lg-4 mb-3 mb-lg-0">
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder={t("City")}
                          value={cityName}
                          onChange={(e) => {
                            setcityName(e.target.value);
                          }}
                        />
                      </div>
                      {/* ----- city section end ------ */}

                      {/* ----- state section start ------ */}
                      <div className="col-lg-4 mb-3 mb-lg-0">
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder={t("State")}
                          value={stateName}
                          onChange={(e) => {
                            setstateName(e.target.value);
                          }}
                        />
                      </div>
                      {/* ----- state section end ------ */}

                      {/* ----- country section start ------ */}
                      <div className="col-lg-4">
                        <input
                          type="text"
                          className="form-control border-0 shadow-none"
                          placeholder={t("Country")}
                          value={country}
                          onChange={(e) => {
                            setcountry(e.target.value);
                          }}
                        />
                      </div>
                      {/* ----- country section end ------ */}
                    </div>
                  </div>
                  {/* ----- city,state,country section end ------ */}
                </div>
              </div>
              {/* ----- zipcode,city,state,country section end ------ */}

              {/* ===== geo location section ===== */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="geoLocation"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Geo Location")}
                </label>
                <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                  <input
                    type="text"
                    className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                    placeholder={t("Latitude")}
                    value={latitude}
                    onChange={(e) => setlatitude(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                    placeholder={t("Longitude")}
                    value={longitude}
                    onChange={(e) => setlongitude(e.target.value)}
                  />
                </div>
              </div>

              {/* ===== objectives section ====== */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="objective"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Objective")}
                </label>
                <CKEditor
                  className="form-control fs-sm shadow-none"
                  editor={ClassicEditor}
                  config={editorConfig}
                  data={groupObjective ? groupObjective : ""}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(group, editor) => {
                    let editorContent = editor.getData();
                    setgroupObjective(editorContent);
                  }}
                />
              </div>

              {/* ===== category section ======= */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="category"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Category")}
                </label>
                <Select
                  isMulti
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => categorySelectionHandler(val)}
                />
              </div>

              {/* ====== searching / offering section ====== */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-12 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="searching"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Searching")}
                    </label>
                    <Select
                      isMulti
                      options={searchingList}
                      value={searchingValue}
                      onChange={(val) => searchingSelectionHandler(val)}
                    />
                  </div>
                </div>
              </div>

              {/* ===== interest / budget section ====== */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="interest"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Interest")}
                    </label>
                    <Select
                      isMulti
                      options={interestList}
                      value={interestValue}
                      onChange={(val) => interestSelectionHandler(val)}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor="budget"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Budget")}
                    </label>
                    <input
                      type="text"
                      id="budget"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Budget")}
                      value={groupBudget}
                      onChange={(e) => setgroupBudget(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* ------ uploaded image section start ------ */}
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="field-container d-flex align-items-center px-3 body-bg rounded-10 mb-3">
                        <label htmlFor="uploadDp" className="text-nowrap me-3">
                          {t("Featured Photo")}
                        </label>
                        <p className="form-control text-gray-600 border-0 rounded-0 shadow-none">
                          JPG, PNG, JPEG
                        </p>
                        <Link
                          to="#"
                          className="upload-file"
                          data-target="uploadResume"
                          onClick={openImageFileInput}
                        >
                          <span className="d-block material-symbols-outlined icon-lg">
                            upload
                          </span>
                        </Link>
                        <input
                          type="file"
                          id="uploadedgroupImageFile"
                          className="d-none"
                          onChange={imageUploadHandler}
                          data-id="uploadResume"
                          ref={imageFileInputRef}
                        />
                      </div>

                      <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
                        {isUploading ? (
                          <li>
                            <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                              <p className="fw-bold">
                                {t("Please wait while uploading")}
                              </p>{" "}
                              {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    style={{
                                      height: "5px",
                                      width: "5px",
                                    }}
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </li>
                        ) : (
                          <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                            <Link to="#" onClick={closegroupImageFileHandler}>
                              <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                                delete
                              </span>
                            </Link>
                            <img
                              src={
                                uploadedPreviewImage == ""
                                  ? "https://placehold.co/150x150"
                                  : uploadedPreviewImage
                              }
                              alt="Uploaded"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ uploaded image section end ------ */}

              {/* ====== documents section ======== */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="documents"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Upload Documents")}
                </label>

                <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                  <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                    <Link
                      to="#"
                      data-target="upload"
                      className="upload-file text-center"
                      onClick={openDocFileInput}
                    >
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">{t("Upload")}</span>
                    </Link>
                    <span className="fs-xs">Max 20mb doc/img/mp4</span>
                    <input
                      type="file"
                      data-id="upload"
                      id="uploadedgroupDoc"
                      className="d-none"
                      ref={docFileInputRef}
                      onChange={docFileUploadHandler}
                      multiple
                    />
                  </li>
                </ul>

                {docFileUploading ? (
                  <div className="d-flex align-items-center mt-3">
                    <p className="mb-0 me-1">{t("Uploading Files")}</p>
                    {loadingCircle.map((circle) => {
                      return (
                        <div
                          key={circle}
                          className="spinner-grow mx-1"
                          style={{ height: "6px", width: "6px" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                    {uploadedDocFiles.map((file, index) => {
                      return (
                        <li
                          className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                          key={index}
                        >
                          <Link
                            to="#"
                            onClick={() => downloadFileHandler(file)}
                          >
                            <span className="d-block material-symbols-outlined">
                              download
                            </span>
                          </Link>
                          <p className="fw-medium">{file.name}</p>
                          <Link
                            to="#"
                            onClick={() => closeDocFileHandler(index)}
                          >
                            <span className="d-block material-symbols-outlined">
                              close
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>

              {/* ======== searching section ========= */}
              <div className="form-group mb-3 mb-sm-4">
                {/* <label
                htmlFor="searching"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Auto Aproval
              </label> */}
                <ul className="d-flex flex-wrap gap-3 gap-lg-5">
                  <li>
                    <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                      <input
                        type="checkbox"
                        id="portfolio"
                        className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                        checked={autoAccept}
                        onChange={(e) => setautoAccept(e.target.checked)}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="portfolio"
                      >
                        {t("Auto accept joining invitations")}
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                      <input
                        type="checkbox"
                        id="equipoProfesional"
                        className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                        checked={isPrivate}
                        onChange={(e) => setisPrivate(e.target.checked)}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="equipoProfesional"
                      >
                        {t("Mark as private group")}
                      </label>
                    </div>
                  </li>
                  {/* <li>
                  <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                    <input
                      type="checkbox"
                      id="faq"
                      className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                    />
                    <label
                      className="form-check-label cursor-pointer"
                      htmlFor="faq"
                    >
                      FAQ
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                    <input
                      type="checkbox"
                      id="documentosDeCalidad"
                      className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                    />
                    <label
                      className="form-check-label cursor-pointer"
                      htmlFor="documentosDeCalidad"
                    >
                      Documentos de Calidad
                    </label>
                  </div>
                </li> */}
                </ul>
              </div>

              {/* ========= save / cancel section ========= */}
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link
                  to="/admin/group/list"
                  className="btn btn-outline-primary"
                >
                  {t("Cancel")}
                </Link>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveUpdategroupHandler}
                >
                  {t("Save Now")}
                </button>
              </div>
            </div>
          </div>
        </section>
        <AfterSaveGroupModal afterSaveModalClose={afterSavegroupModalClose} />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default GroupInfoBody;
