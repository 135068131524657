/* eslint-disable */
import React from "react";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const SaveOpportunityHeader = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "CRM", link: "/admin/crm/lead/list" },
    {
      title: t("Opportunity"),
      link: `/admin/crm/opportunity/list`,
    },
    {
      title: t("Update"),
    },
  ];

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 mb-3 mb-md-0">
          {/* --- breadcrumb start --- */}
          <BreadCrumb breadCrumbText={breadcrumbText} />
          {/* --- breadcrumb end --- */}
        </div>
      </div>
    </div>
  );
};

export default SaveOpportunityHeader;
