import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';

const CouponCreateBody = () => {
  // ---------- bread crumb text ----------
  const breadcrumbTxt = [
    { title: 'Coupons', link: '/admin/coupons/list' },
    { title: 'Create' },
  ];

  // select data --------------------------
  const options = [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div id="content_wrapper">
      <section class="coupon-wrapper bg-white pb-5">
        <div class="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---------- bread crumb ---------- */}
          <BreadCrumb breadCrumbText={breadcrumbTxt} bottom={true} />
          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form action="">
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="couponCode"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Coupons Code
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="fromDate"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      From Date
                    </label>
                    <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                      <input
                        type="date"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Date"
                      />
                      <input
                        type="date"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Time"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor="endDate"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      End Date
                    </label>
                    <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                      <input
                        type="date"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Date"
                      />
                      <input
                        type="date"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Time"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="type"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Type
                    </label>
                    <Select
                      placeholder="Select options"
                      options={options}
                      value={selectOptions}
                      onChange={val => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                  <div className="col-lg-9">
                    <label
                      htmlFor="discount"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Discount
                    </label>
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      // value="€50"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="productLink"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Product Link <span className="fw-normal">(if any)</span>
                </label>
                <div className="field-container mb-3">
                  <input
                    type="url"
                    id="productLink"
                    className="form-control fs-sm shadow-none"
                    placeholder="Paste link"
                  />
                </div>
                <Link to="#" className="btn btn-outline-primary">
                  Add Another
                </Link>
              </div>
              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3 mb-sm-4">
                <input
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  type="checkbox"
                />
                <label className="form-check-label" htmlFor="">
                  Agree with the{' '}
                  <Link to="#" className="text-primary">
                    Legal Notice
                  </Link>{' '}
                  &amp;{' '}
                  <Link to="#" className="text-primary">
                    Privacy Policy
                  </Link>
                </label>
              </div>
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link
                  to="/admin/coupons/list"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CouponCreateBody;
