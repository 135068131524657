/* eslint-disable */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { putData } from "utils/Gateway";

//import context
import { GlobalProvider } from "context/GlobalContext";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import CongratsModal from "components/Common/Modal/CongratsModal";

const ResetPassword = () => {
  const { userEmailforOtp, setuserEmailforOtp } = useContext(GlobalProvider);
  const { t } = useTranslation(); // translation marker
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordNotMatched, setPasswordNotMatched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // function for toggle passowrd to text
  const togglePassowrd = () => {
    setShowPassword(!showPassword);
  };

  // function for change text type
  const toggleConfirmPassowrd = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //validation function
  const validateForm = () => {
    let isValid = true;

    if (userPassword === "") {
      setPasswordWarning(true);
      isValid = false;
    }

    if (userConfirmPassword === "") {
      setConfirmPasswordWarning(true);
      isValid = false;
    }

    return isValid;
  };

  //chnage passowrd function
  const changePasswordHandler = async () => {
    if (validateForm()) {
      if (userPassword === userConfirmPassword) {
        setPasswordNotMatched(false);
        try {
          setIsLoading(true);
          let userData = {
            email: userEmailforOtp,
            password: userPassword,
          };

          console.log(userData);

          let requestURL = url.API_BASE_URL + url.API_UPDATE_PASSSWORD;

          const response = await putData(requestURL, userData);

          console.log(response);

          setIsLoading(false);
          if (response.status) {
            resetModal();

            //show success_modal modal
            let myModal = new bootstrap.Modal(
              document.getElementById("congrats_success_mdl")
            );
            myModal.show();
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setPasswordNotMatched(true);
      }
    }
  };

  //function for reset modal
  const resetModal = () => {
    setuserEmailforOtp("");
    setUserPassword("");
    setUserConfirmPassword("");
  };

  return (
    <main id="app">
      <div id="content_wrapper">
        <div className="authentication-wrapper reset-password min-h-screen py-5 d-flex align-items-center justify-content-center">
          <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto">
            <div className="title text-center mb-4">
              {/* <img
                src={assetImages.findelOiLogo}
                alt="FindelOI"
                className="img-fluid d-block mx-auto mb-3"
                style={{ maxHeight: "1.25rem" }}
              /> */}
              <h1 className="h2 mb-2">Reset Password</h1>
              <p className="fs-sm text-gray">Enter your new password</p>
            </div>
            <form action="">
              <div className="form-group mb-4">
                <label
                  htmlFor="nPassword"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Password
                </label>
                <div className="field-container field-with-icon icon-right position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control fs-sm shadow-none"
                    placeholder={t("New Password")}
                    value={userPassword}
                    onChange={(e) => {
                      setPasswordWarning(false);
                      setUserPassword(e.target.value);
                    }}
                  />
                  <span
                    onClick={togglePassowrd}
                    className="d-block material-symbols-outlined icon-sm text-gray position-absolute top-50 end-0 translate-middle-y me-3"
                  >
                    visibility
                  </span>
                </div>
                {/* ------ passowrd section end ----- */}
                {passwordWarning && (
                  <p className="error">
                    <span className="material-icons-outlined">info</span>
                    {t("Please enter password")}
                  </p>
                )}
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="cPassword"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Confirm Password
                </label>
                <div className="field-container field-with-icon icon-right position-relative">
                  <input
                    className="form-control fs-sm shadow-none"
                    type={showConfirmPassword ? "text" : "password"}
                    value={userConfirmPassword}
                    placeholder={t("Re-enter Password")}
                    onChange={(e) => {
                      setConfirmPasswordWarning(false);
                      setUserConfirmPassword(e.target.value);
                    }}
                  />
                  <span
                    onClick={toggleConfirmPassowrd}
                    className="d-block material-symbols-outlined icon-sm text-gray position-absolute top-50 end-0 translate-middle-y me-3"
                  >
                    visibility
                  </span>
                </div>
                {confirmPasswordWarning && (
                  <p className="error">
                    <span className="material-icons-outlined">info</span>
                    {t("Please enter confirm password")}
                  </p>
                )}

                {passwordNotMatched && (
                  <p className="error">
                    <span className="material-icons-outlined">info</span>
                    {t("Password and confirm password not matched")}
                  </p>
                )}
              </div>
              <div className="action d-flex gap-3 justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={isLoading ? true : false}
                  style={{
                    cursor: isLoading ? "not-allowed" : "pointer",
                  }}
                  onClick={changePasswordHandler}
                >
                  {t("Update Password")}
                  {isLoading && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CongratsModal />
    </main>
  );
};

export default ResetPassword;
