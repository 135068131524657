import Header from "components/Common/Header/Header";
import OpportunityKanbanBody from "components/CRMComponents/CrmOpportunity/OpportunityKanban/OpportunityKanbanBody";
import React, { useEffect } from "react";

const CrmOpportunityKanban = () => {
  useEffect(() => {
    document.title = "CRM Opportunity";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body section --- */}
      <OpportunityKanbanBody />
    </main>
  );
};

export default CrmOpportunityKanban;
