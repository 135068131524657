/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box, TextField, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData, putData } from 'utils/Gateway';

import { assetImages } from 'constants';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import TaskManagementPopup from 'components/Common/Popup/TaskManagementPopup/TaskManagementPopup';
import MyTaskFilterPopup from 'components/Common/Popup/MyTaskFilterPopup';
import MyTaskListHeader from './Header/MyTaskListHeader';
import { getDateFormatInSpanish } from 'helper/Common/CommonHelper';
import TaskStatusOptions from 'data/Prod/TaskStatus.json';
import TaskPriority from 'data/Prod/TaskPriority.json';
import AssignBulkTaskModal from 'components/Common/Modal/AssignBulkTaskModal';
import SuccessBulkTaskModal from 'components/Common/Modal/SuccessBulkTaskModal';
import DeleteTaskWarningModal from 'components/Common/Modal/DeleteTaskWarningModal';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import AddAlertModal from 'components/Common/Modal/AddAlertModal';
import AddFollowerModal from 'components/Common/Modal/AddFollowerModal';
import { useTranslation } from 'react-i18next';

const MyTaskListBody = () => {
  const todayValue = new Date().toISOString().split('T')[0];

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedTaskModerator, setselectedTaskModerator] = useState(null);

  const [filterTaskDateRangeType, setFilterTaskDateRangeType] = useState('');
  const [filterTaskFromDate, setfilterTaskFromDate] = useState('');
  const [filterTaskToDate, setfilterTaskToDate] = useState('');
  const [filterTaskCustomIds, setfilterTaskCustomIds] = useState([]);
  const [filterTaskTitle, setfilterTaskTitle] = useState('');
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskAssignedToUsers, setfilterTaskAssignedToUsers] = useState(
    []
  );
  const [filterTaskAssignedByUsers, setfilterTaskAssignedByUsers] = useState(
    []
  );
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);

  const [showTodaysTasks, setShowTodaysTasks] = useState(false);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [bulkSavedTaskList, setBulkSavedTaskList] = useState([]);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  // paginatin ends -----------------------------------

  const [windowScreen, setwindowScreen] = useState(0);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //get all tasks
  const getAllTask = async () => {
    setSelectedTaskIds([]);
    setRowSelection({});

    try {
      setIsLoading(true);

      let isModerator = false;

      if (
        userInfo.role.slug === 'ADMIN' ||
        userInfo.role.slug === 'SUPER_ADMIN'
      ) {
        isModerator = true;
      }

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&ismoderator=${isModerator}`;

      if (filterTaskDateRangeType != '') {
        requestUrl =
          requestUrl + `&filterdaterangetype=${filterTaskDateRangeType}`;
      }

      if (filterTaskFromDate != '' || filterTaskToDate != '') {
        requestUrl =
          requestUrl +
          `&filterfromdate=${filterTaskFromDate}&filtertodate=${filterTaskToDate}`;
      }

      if (filterTaskCustomIds.length > 0) {
        requestUrl = requestUrl + `&filtertaskcustomids=${filterTaskCustomIds}`;
      }

      if (filterTaskTitle != '') {
        requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
      }

      if (filterTaskLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
      }

      if (filterTaskStatus.length > 0) {
        requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
      }

      if (filterTaskPriorities.length > 0) {
        requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
      }

      if (filterTaskProjects.length > 0) {
        requestUrl = requestUrl + `&filterprojects=${filterTaskProjects}`;
      }

      if (filterTaskAssignedByUsers.length > 0) {
        requestUrl =
          requestUrl + `&filtermoderators=${filterTaskAssignedByUsers}`;
      }

      if (filterTaskAssignedToUsers.length > 0) {
        requestUrl = requestUrl + `&filterusers=${filterTaskAssignedToUsers}`;
      }

      console.log(`getAllTask requestUrl--->`, requestUrl);

      const response = await getData(requestUrl);

      console.log('response in task list------->', response);
      setIsLoading(false);

      if (response.status) {
        setMessageType('success');
        setTaskList(response.data);
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
    // if (windowScreen !== 0) {
    //   // Scroll smoothly to the bottom
    //   window.scrollTo({
    //     top: windowScreen,
    //     behavior: "smooth",
    //   });
    // }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      task => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].rank = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change complete percent
  const completePercentInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      task => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].taskpercent = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change planed hours
  const plannedHoursInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      task => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].hours = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change start date
  const startDateInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      task => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].startdate = value;

      console.log(updatedTaskList);

      setTaskList(updatedTaskList);
    }
  };

  //function for change end date
  const endDateInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      task => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].enddate = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for update rank
  const updateBulkTaskHandler = async e => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        //run loop of updated company
        for (let task of taskList) {
          let taskData = {};

          //check the cheked blog id match with changed rank filed blog id.If check then proceed
          if (task._id == updatedTaskId) {
            taskData.rank = task.rank;
            taskData.taskpercent = task.taskpercent;
            taskData.startdate = task.startdate;
            taskData.enddate = task.enddate;
            taskData.hours = task.hours;

            try {
              let requestUrl =
                url.API_BASE_URL +
                url.API_UPDATE_PROJECT_TASK +
                `/${updatedTaskId}` +
                `?token=${token}`;

              const response = await putData(requestUrl, taskData);

              // console.log(response);

              if (response.status) {
                setMessageType('success');
                responseArr.push(response.status);
              } else {
                setMessageType('error');
              }

              setAlertMessage(response.message);
            } catch (error) {
              setAlertMessage(error.message);
              setMessageType('error');
            }

            setShowAlert(true);
          }
        }
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTask();
        }, 2200);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select atleast one task');
      setShowAlert(true);
    }
  };

  //delete multiple tasks
  const deActivateBulkTaskHandler = async e => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        try {
          let requestUrl =
            url.API_BASE_URL +
            url.API_DEACTIVATE_PROJECT_TASK +
            `/${updatedTaskId}` +
            `?token=${token}`;

          const response = await putData(requestUrl);

          if (response.status) {
            setMessageType('success');
            responseArr.push(response.status);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType('error');
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTask();
        }, 1500);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select atleast one task');
      setShowAlert(true);
    }
  };

  //* Function for reset filter
  const resetFilterData = () => {
    setFilterTaskDateRangeType('');
    setfilterTaskFromDate('');
    setfilterTaskToDate('');
    setfilterTaskCustomIds([]);
    setfilterTaskTitle('');
    setfilterTaskLabels([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setfilterTaskProjects([]);
    setfilterTaskAssignedToUsers([]);
    setfilterTaskAssignedByUsers([]);

    setReloadData(true);
    // reset pagination to default
    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const afterCloseModalHandler = isGetAllList => {
    if (isGetAllList) {
      getAllTask();
    } else {
      setRowSelection({});
      setSelectedTaskIds([]);
    }
  };

  useEffect(() => {
    if (
      filterTaskDateRangeType != '' ||
      filterTaskFromDate != '' ||
      filterTaskToDate != '' ||
      filterTaskTitle != '' ||
      filterTaskLabels.length > 0 ||
      filterTaskCustomIds.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskProjects.length > 0 ||
      filterTaskAssignedByUsers.length > 0 ||
      filterTaskAssignedToUsers.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      // console.log(filterTaskDateRangeType);
      // console.log(filterTaskFromDate);
      // console.log(filterTaskToDate);
      // console.log(filterTaskTitle);
      // console.log(filterTaskLabels);
      // console.log(filterTaskCustomIds);
      // console.log(filterTaskPriorities);
      // console.log(filterTaskProjects);
      // console.log(filterTaskAssignedByUsers);
      // console.log(filterTaskAssignedToUsers);
      // console.log(filterTaskStatus);
      getAllTask();
    }
  }, [
    filterTaskDateRangeType,
    filterTaskFromDate,
    filterTaskToDate,
    filterTaskCustomIds,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskProjects,
    filterTaskAssignedByUsers,
    filterTaskAssignedToUsers,
  ]);

  useEffect(() => {
    getAllTask();
  }, []);

  // State to manage the filter values for each column
  const [columnFilterValues, setColumnFilterValues] = useState({});
  const [showGlobalFilters, setShowGlobalFilters] = useState(false);

  const globalFilterTextHandler = ({ column }) => (
    <Box>
      <TextField
        value={columnFilterValues[column.id] || ''}
        onChange={e => {
          // console.log(column);
          setColumnFilterValues({
            ...columnFilterValues,
            [column.id]: e.target.value,
          });

          column.setFilterValue(e.target.value || undefined); // Apply the filter to the table or remove filter if empty
        }}
        placeholder={`Filter by ${column.columnDef.header}`}
        variant="standard"
      />
      {columnFilterValues[column.id] && ( // Display clear filter button only if filter is applied
        <IconButton onClick={() => clearGlobalFilterhandler(column)}>
          <Clear />
        </IconButton>
      )}
    </Box>
  );

  const clearGlobalFilterhandler = column => {
    column.setFilterValue('');
    setColumnFilterValues({
      ...columnFilterValues,
      [column.id]: '', // Clear filter value for the specified column
    });
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: '#',
      header: t('Actions'),
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="offcanvas"
          data-bs-target="#addTask_view"
          aria-controls="addTask_view"
          onClick={() => {
            setSelectedTaskId(row.original._id);
            setselectedTaskModerator(row.original.moderator._id);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: 'customid',
      header: t('ID'),
      size: 100,
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'title',
      header: t('Title'),
      size: 200,
      Cell: ({ row }) => (
        <Box className="title border-bottom-0" sx={{ width: '200px' }}>
          <div className="d-flex align-items-center gap-4">
            {/* <p className="fs-md fw-semibold"></p> */}
            <Link
              to={`/admin/task/details/${row.original._id}`}
              className="d-flex align-items-center gap-1 fs-md"
              // data-bs-toggle="offcanvas"
              // role="button"
              // aria-controls="addTask_view"
              // onClick={() => {
              //   setwindowScreen(window.scrollY);
              //   setSelectedTaskId(row.original._id);
              //   setselectedTaskModerator(row.original.moderator._id);
              // }}
            >
              {row.original.title}
            </Link>
          </div>
        </Box>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'rank',
      header: t('Rank'),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {row.original.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN' ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '100px' }}
              value={row.original.rank}
              onChange={e => rankInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-3">{row.original.rank}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'taskstatus',
      header: t('Task Status'),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'taskpercent',
      header: t('Complete %'),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN' ||
          row.original.moderator._id.toString() === userInfo._id.toString() ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '100px' }}
              value={row.original.taskpercent}
              onChange={e =>
                completePercentInputChangeHandler(row, e.target.value)
              }
            />
          ) : (
            <p className="ps-3">{row.original.taskpercent}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'projectname',
      header: t('Project'),
      size: 250,
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'taskpriority',
      header: t('Priority'),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'hours',
      header: t('Planned Hours'),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {row.original.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN' ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '100px' }}
              value={row.original.hours}
              onChange={e =>
                plannedHoursInputChangeHandler(row, e.target.value)
              }
            />
          ) : (
            <p className="ps-3">{row.original.hours}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'loggedhours',
      header: t('Dedicated Hours'),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'balancehours',
      header: t('Balance Hours'),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          <p
            className={`ps-3 ${
              row.original.balancehours < 0 ? 'text-danger' : ''
            }`}
          >
            {row.original.balancehours}
          </p>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'startdate',
      header: t('Start Date'),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN' ||
          row.original.moderator._id.toString() === userInfo._id.toString() ? (
            <input
              type="date"
              className="form-control fs-sm bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '140px' }}
              value={row.original.startdate}
              onChange={e => startDateInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-2">
              {getDateFormatInSpanish(row.original.startdate)}
            </p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'enddate',
      header: t('End Date'),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === 'ADMIN' ||
          userInfo.role.slug === 'SUPER_ADMIN' ||
          row.original.moderator._id.toString() === userInfo._id.toString() ? (
            <input
              type="date"
              className="form-control fs-sm bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '140px' }}
              value={row.original.enddate}
              onChange={e => endDateInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-2">
              {getDateFormatInSpanish(row.original.enddate)}
            </p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'tasknumberofdaysleft',
      header: t('Days Left'),
      Cell: ({ row }) => (
        <Box className="title border-bottom-0" sx={{ width: '200px' }}>
          <div className="d-flex align-items-center gap-4">
            <p
              className={
                row.original.tasknumberofdaysleft <= 3 ? 'text-primary' : ''
              }
            >
              {' '}
              {row.original.tasknumberofdaysleft}
            </p>
          </div>
        </Box>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'moderatorname',
      header: t('Assigned By'),
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          <img
            className="rounded-circle"
            height={35}
            width={35}
            src={
              row.original.moderatorimage == ''
                ? assetImages.defaultUser
                : url.SERVER_URL + row.original.moderatorimage
            }
            alt="Babcock"
          />
          <p>
            <span className="d-block">{row.original.moderatorname}</span>
            <span className="d-block">{row.original.moderatoremail}</span>
          </p>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'assignedusername',
      header: t('Assigned To'),
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          <img
            className="rounded-circle"
            height={35}
            width={35}
            src={
              row.original.assigneduserimage == ''
                ? assetImages.defaultUser
                : url.SERVER_URL + row.original.assigneduserimage
            }
            alt="Babcock"
          />
          <p>
            <span className="d-block">{row.original.assignedusername}</span>
            <span className="d-block">{row.original.assigneduser?.email}</span>
          </p>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'followersnames',
      header: t('Followers'),
      size: 250,
    },
    {
      accessorKey: 'projectsectionname',
      header: t('Section'),
      size: 200,
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'tasklogupdatedperson',
      header: t('Log Updated By'),
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          {row.original.tasklogupdatedpersonimage == '' ? null : (
            <img
              className="rounded-circle"
              height={35}
              width={35}
              src={url.SERVER_URL + row.original.tasklogupdatedpersonimage}
              alt="Babcock"
            />
          )}
          <p>
            <span className="d-block">
              {row.original.tasklogupdatedpersonname}
            </span>
            <span className="d-block">
              {row.original.tasklogupdatedpersonemail}
            </span>
          </p>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'tasklogupdatedon',
      header: t('Log updated on'),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'leadtitle',
      header: t('Lead'),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'contactname',
      header: t('Contact'),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: 'tagstring',
      header: t('Label'),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != '' && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(' , ').map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
  ];

  //* Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    // [taskList, columnFilterValues]
    [taskList, i18n.language]
  );

  /**
   * Updates the column visibility state by toggling the visibility of the column with the given accessor key.
   *
   * @param {function} newColumnState - A function that returns the accessor key of the column whose visibility is being changed.
   */
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /**
   ** Represents the state of column visibility in the table.
   */
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  /**
   * Updates the column visibility state based on the new column state.
   *
   * @param {function} newColumnState - A function that returns the new column state.
   * @return {void} This function does not return anything.
   */
  const onColumnVisiblityHandler = newColumnState => {
    const newColumnStateName = newColumnState();
    setVisibleColoumns(prev => ({ ...prev, ...newColumnStateName }));
  };

  //initialize the column order
  const initialColumnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = changedOrder => {
    setColumnOrder(changedOrder);
  };

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllTask();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTaskIds(selectedIdsArray);
    } else {
      setSelectedTaskIds([]);
    }
  }, [rowSelection]);

  // useEffect(() => {
  //   console.log(taskList);
  // }, [taskList]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  //refresh icon function
  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  // const initialGlobalFilter = Object.entries(columnFilterValues).map(
  //   ([id, value]) => ({ id, value })
  // );

  // const toggleColumnFiltersChange = () => {
  //   setShowGlobalFilters(!showGlobalFilters);
  // };

  // const testingFunction = () => {
  //   console.log("testing");
  // };

  // useEffect(() => {
  //   console.log(columnFilterValues);
  // }, [columnFilterValues]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_TASK')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <MyTaskListHeader
              userInfo={userInfo}
              reloadList={refreshRecords}
              updateBulkTaskHandler={updateBulkTaskHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                {/* <div className="d-flex align-items-center gap-2">
                  <button className="btn btn-primary" onClick={testingFunction}>
                    Testing Button
                  </button>
                </div> */}
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={taskList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  enableColumnOrdering={true}
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }}
                  onColumnVisibilityChange={onColumnVisiblityHandler}
                  onColumnOrderChange={changeColumnOrderHandler}
                  // onShowColumnFiltersChange={toggleColumnFiltersChange}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ========= popups and modals area ======== */}
        {/* -------- add label modal ---------- */}
        <AddLabelModal
          moduleName="task"
          selectedIds={selectedTaskIds}
          afterTagModalClose={getAllTask}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ---------- filter popup ---------- */}
        <MyTaskFilterPopup
          visibleColoumns={visibleColoumns}
          setVisibleColoumns={setVisibleColoumns}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          columnFilterValues={columnFilterValues}
          setColumnFilterValues={setColumnFilterValues}
          showGlobalFilters={showGlobalFilters}
          setShowGlobalFilters={setShowGlobalFilters}
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          filterTaskDateRangeType={filterTaskDateRangeType}
          setFilterTaskDateRangeType={setFilterTaskDateRangeType}
          filterTaskFromDate={filterTaskFromDate}
          setfilterTaskFromDate={setfilterTaskFromDate}
          filterTaskToDate={filterTaskToDate}
          setfilterTaskToDate={setfilterTaskToDate}
          filterTaskCustomIds={filterTaskCustomIds}
          setfilterTaskCustomIds={setfilterTaskCustomIds}
          filterTaskTitle={filterTaskTitle}
          setfilterTaskTitle={setfilterTaskTitle}
          filterTaskProjects={filterTaskProjects}
          setfilterTaskProjects={setfilterTaskProjects}
          filterTaskAssignedByUsers={filterTaskAssignedByUsers}
          setfilterTaskAssignedToUsers={setfilterTaskAssignedToUsers}
          filterTaskAssignedToUsers={filterTaskAssignedToUsers}
          setfilterTaskAssignedByUsers={setfilterTaskAssignedByUsers}
          filterTaskStatus={filterTaskStatus}
          setfilterTaskStatus={setfilterTaskStatus}
          filterTaskPriorities={filterTaskPriorities}
          setfilterTaskPriorities={setfilterTaskPriorities}
          filterTaskLabels={filterTaskLabels}
          setfilterTaskLabels={setfilterTaskLabels}
          moduleName="mytask"
        />

        {/* ---------- add project popup ------------ */}

        <TaskManagementPopup
          moduleName={'mytask'}
          contactId={null}
          leadId={null}
          projectId={null}
          selectedTaskId={selectedTaskId}
          selectedTaskModerator={selectedTaskModerator}
          memberList={[]}
          selectedSectionValue={null}
          sectionOptions={[]}
          onPopUpClose={() => {
            setSelectedTaskId(null);
            setselectedTaskModerator(null);
          }}
          getTaskList={() => {
            setTimeout(() => {
              getAllTask();
            }, 2200);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AssignBulkTaskModal
          moduleName={'mytask'}
          userInfo={userInfo}
          afterCloseModalHandler={afterCloseModalHandler}
          selectedTasks={selectedTaskIds}
          setBulkSavedTaskList={setBulkSavedTaskList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddAlertModal
          selectedIds={selectedTaskIds}
          afterModalClose={() => {
            setSelectedTaskIds([]);
            setRowSelection({});
          }}
          moduleName={'task'}
        />

        <SuccessBulkTaskModal
          bulkSavedTaskList={bulkSavedTaskList}
          afterModalClose={afterCloseModalHandler}
        />

        <DeleteTaskWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedTaskIds([]);
          }}
          onDelete={deActivateBulkTaskHandler}
        />

        <AddFollowerModal
          moduleName="task"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          selectedIds={selectedTaskIds}
          afterModalClose={() => {
            setTimeout(() => {
              getAllTask();
            }, 2500);
          }}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default MyTaskListBody;
