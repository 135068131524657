/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box } from '@mui/material';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData, putData } from 'utils/Gateway';

import ChallengeListHeader from '../ChallengeListHeader/ChallengeListHeader';

import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import ChallengeListFilterPopup from '../Popup/ChallengeListFilterPopup';
import AddIndustryModal from '../Modals/AddIndustryModal';
import AddNewMailPopup from '../Popup/AddNewMailPopup';
import AddToListModal from '../Modals/AddToListModal';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const ChallengeListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation
  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterTitle, setFilterTitle] = useState('');
  const [filterTags, setFilterTags] = useState([]);
  const [filterMod, setfilterMod] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      } else {
        setAlertMessage(response.message);
        setMessageType('error');
        setShowAlert(true);
      }
    } catch (error) {
      //show alert notification if error
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get project list
  const getAllChallenges = async () => {
    setRowSelection({});
    setSelectedJobIds([]);
    setIsLoading(true);

    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_CHALLENGE}?token=${token}`;

      requestUrl +=
        filterDateRange != '' ? `&filterdates=${filterDateRange}` : '';

      if (filterTitle != '') {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }
      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }
      if (filterMod) {
        requestUrl = requestUrl + `&filtermod=${filterMod}`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);
      setIsLoading(false);

      console.log(response);

      if (response.status) {
        setMessageType('success');
        resetFilterData();
        setJobList(response.data);
      } else {
        setMessageType('error');
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType('error');
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedJobList = [...jobList];

    const rowIndex = updatedJobList.findIndex(
      jobData => jobData._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedJobList[rowIndex].rank = value;
      setJobList(updatedJobList);
    }
  };

  //function for update rank
  const updateRankHandler = async e => {
    let responseArr = [];

    //run loop checkbox wise
    for (let selectedId of selectedJobIds) {
      //run loop of updated bloglist
      for (let job of jobList) {
        let jobData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (job._id == selectedId) {
          jobData.rank = job.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_CHALLENGE +
              `/${selectedId}` +
              `?token=${token}`;

            const response = await putData(requestUrl, jobData);

            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            setAlertMessage(error.message);
            setMessageType('error');
            setShowAlert(true);
          }
        }
      }
    }

    setRowSelection({});
    setSelectedJobIds([]);
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedJobIds) {
      try {
        let statusData = {
          status: '0',
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_CHALLENGE +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
        setShowAlert(true);
      }
    }

    getAllChallenges();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setFilterTitle('');
    setFilterTags([]);
    setfilterMod(null);
  };

  //use effect for search filter popup
  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterTitle != '' ||
      filterTags.length > 0 ||
      filterMod
    ) {
      getAllChallenges();
    }
  }, [filterDateRange, filterTitle, filterTags, filterMod]);

  useEffect(() => {
    getAllTags();
    getAllChallenges();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Title'),
      },
      {
        accessorKey: 'challengeresponsecount',
        header: t('Responses'),
      },
      {
        accessorKey: 'rank',
        header: t('Rank'),
        Cell: ({ row }) => (
          <div className="ranking border-bottom-0">
            <input
              type="text"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '80px' }}
              value={row.original.rank}
              onChange={e => rankInputChangeHandler(row, e.target.value)}
            />
          </div>
        ),
      },
      {
        accessorKey: 'moderatorname',
        header: t('Posted By'),
        size: 250,
        Cell: ({ row }) => (
          <div className="owner border-bottom-0 d-flex gap-1">
            <img
              className="rounded-circle"
              height={30}
              src={
                row.original.moderatorimage == ''
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.moderatorimage
              }
              alt="Babcock"
            />
            <p>
              <span className="d-block">{row.original.moderatorname}</span>
              <span className="d-block">{row.original.moderatoremail}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'createddate',
        header: t('Posted on'),
      },
      {
        accessorKey: 'jobcode',
        header: t('Code'),
      },
      {
        accessorKey: 'jobtype',
        header: t('Job Type'),
      },
      {
        accessorKey: 'companynamestring',
        header: t('Company'),
      },
      {
        accessorKey: 'minsalary',
        header: t('Min Salary'),
      },
      {
        accessorKey: 'maxsalary',
        header: t('Max Salary'),
      },
      {
        accessorKey: 'paymentduration',
        header: t('Payment Type'),
      },
      {
        accessorKey: 'remotejob',
        header: t('Remote Job'),
      },
      {
        accessorKey: 'city',
        header: t('City'),
      },

      // {
      //   accessorKey: "_id",
      //   header: "V. Chat",
      //   Cell: () => (
      //     <div className="d-flex align-items-center gap-2">
      //       <p className="mb-0">V</p>
      //       <p className="position-relative mb-0">
      //         <span className="d-block material-symbols-outlined icon-md">
      //           sms
      //         </span>
      //         <span className="notification-dot d-block position-absolute bg-primary border border border-white rounded-circle"></span>
      //       </p>
      //       <p className="mb-0">12</p>
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "#",
      //   header: "C. Chat",
      //   Cell: () => (
      //     <div className="d-flex align-items-center gap-2">
      //       <p className="mb-0">V</p>
      //       <p className="position-relative mb-0">
      //         <span className="d-block material-symbols-outlined icon-md">
      //           sms
      //         </span>
      //         <span className="notification-dot d-block position-absolute bg-primary border border border-white rounded-circle"></span>
      //       </p>
      //       <p className="mb-0">12</p>
      //     </div>
      //   ),
      // },
      {
        accessorKey: 'requiredskillstring',
        header: t('Required Skills'),
      },
      {
        accessorKey: 'optionalskillstring',
        header: t('Optional Skills'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [jobList, i18n.language]
  );

  useEffect(() => {
    //do something when the row selection changes...
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedJobIds(selectedIdsArray);
    } else {
      setSelectedJobIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_CHALLENGE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* <div className="gradient-dark px-3 py-2 fs-lg fw-semibold text-white d-flex align-items-center justify-content-between gap-3 flex-wrap rounded-10">
            <span>
              You have not added your skills tet please add skills to add
              challenges
            </span>
            <button
              type="button"
              className="btn btn-gray"
              data-bs-toggle="modal"
              data-bs-target="#addDetails"
            >
              Add Details
            </button>
          </div> */}

            {/* ------------- header section ---------------- */}
            <ChallengeListHeader
              reloadList={getAllChallenges}
              updateRankHandler={updateRankHandler}
              changeStatusHandler={changeStatusHandler}
            />

            {/* --------- table section ------------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={jobList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="details">
                      <Link to={`/admin/challenges/jobinformation/${row.id}`}>
                        {t('View Details')}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="process">
                      <Link to={`/admin/challenges/hiringprocess/${row.id}`}>
                        {t('View Hiring Process')}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="team">
                      <Link to={`/admin/challenges/hiringteam/${row.id}`}>
                        {t('View Hiring Team')}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="response">
                      <Link to={`/admin/challenges/responses/list/${row.id}`}>
                        {t('View Responses')}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="survey">
                      <Link to={`/admin/challenges/survey/${row.id}`}>
                        {t('View Survey')}
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ======== popups and modals area ======== */}
        {/* ------ add label modal-------- */}
        <AddLabelModal
          moduleName="challenge"
          afterTagModalClose={getAllChallenges}
          selectedIds={selectedJobIds}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* -------- filter popup ------------- */}
        <ChallengeListFilterPopup
          tagList={tagList}
          setFilterTitle={setFilterTitle}
          setFilterDateRange={setFilterDateRange}
          setFilterTags={setFilterTags}
          setfilterMod={setfilterMod}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedJobIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('challenge')}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ChallengeListBody;
