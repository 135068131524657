/* eslint-disable */
import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

// Import Editor here (both required).
// import { Editor } from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

import downloadFileHandler from "helper/DownloadFile";
import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
import { assetImages } from "constants";
import { joditEditorConfig } from "Config/Config";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

//image resize for editor
import { resizeOrCompressImage } from "helper/Common/CommonHelper";

const LogTab = ({
  taskContactId,
  taskLeadId,
  taskProjectId,
  taskHours = "",
  taskStartDate = "",
  taskEndDate = "",
  taskPercentResponse = "",
  taskStatusValueResponse = null,
  taskStatusDataResponse = "",
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const params = useParams();

  const { t } = useTranslation(); // translation

  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [taskStatusValue, settaskStatusValue] = useState(
    taskStatusValueResponse
  );
  const [taskStatusData, settaskStatusData] = useState(taskStatusDataResponse);
  const [taskPercent, settaskPercent] = useState(taskPercentResponse);
  const [logHours, setlogHours] = useState(taskHours);
  const [logStartDate, setlogStartDate] = useState(taskStartDate);
  const [logEndDate, setlogEndDate] = useState(taskEndDate);

  const [logDetails, setlogDetails] = useState("");
  const [logList, setLogList] = useState([]);
  const [logAdding, setlogAdding] = useState(false);

  //* hook for validation */
  const [validation, setValidation] = useState({
    hoursWarning: false,
    detailsWarning: false,
    statusWarning: false,
  });

  const editor = useRef(null);

  const editorCustomConfig = joditEditorConfig(300);

  const editorConfig = useMemo(() => editorCustomConfig, []);

  //* function to validate form */
  const validate = () => {
    let isValid = true;

    if (logHours === "") {
      setValidation((prevState) => ({ ...prevState, hoursWarning: true }));
      isValid = false;
    }

    if (taskStatusData === null) {
      setValidation((prevState) => ({ ...prevState, userWarning: true }));
      isValid = false;
    }

    if (logDetails === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //* select status */
  const statusSelectionHandler = (val) => {
    settaskStatusValue(val);
    settaskStatusData(val.value);
  };

  //* reset */
  const resetLogFormHandler = () => {
    setlogDetails("");
  };

  //save log
  const saveLogHandler = async () => {
    if (validate()) {
      setlogAdding(true);

      try {
        let taskData = {
          leadid: taskLeadId,
          project: taskProjectId,
          contact: taskContactId,
          projecttask: params.id,
          completedpercent: taskStatusData,
          description: logDetails,
          startdate: logStartDate,
          enddate: logEndDate,
          hours: logHours,
          taskpercent: taskPercent,
          price: 0,
          uploads: [],
        };

        let response = {};

        let requestUrl =
          url.API_BASE_URL + url.API_ADD_PROJECT_TASK_LOG + `?token=${token}`;

        response = await postData(requestUrl, taskData);

        setlogAdding(false);

        // console.log(response);
        if (response.status) {
          setMessageType("success");
          resetLogFormHandler();
          getTaskLogs();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up required fields");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //get task logs
  const getTaskLogs = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&taskid=${params.id}`;

      // console.log("log list url----->", requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setLogList(response.data);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (params.id) {
      getTaskLogs();
    }
  }, [params.id]);

  return (
    <div className="d-flex flex-column h-100">
      {/* <!-- ========== Start log list Section ========== --> */}
      <div className="logs flex-fill overflow-auto p-4 task_logs">
        {logList.map((logData, index) => {
          return (
            <div key={index}>
              <div className="log-date pb-3">
                <p className="d-flex gap-2 align-items-center justify-content-center fs-md fw-semibold">
                  <span className="d-block">
                    {logData.logmonthstring} {logData.logyear}
                  </span>
                  <span className="d-flex align-items-center gap-1 text-gray ">
                    <span className="d-block material-symbols-outlined icon-lg">
                      schedule
                    </span>
                    <span>{logData.logmonthhour}Hr</span>
                  </span>
                </p>
              </div>
              {logData.logs.map((log, index2) => {
                return (
                  <div className="log mt-3" key={index2}>
                    <div className="d-flex gap-2">
                      <div
                        className="avatar rounded-circle overflow-hidden"
                        style={{ width: "24px", height: "24px" }}
                      >
                        <img
                          src={
                            log.userimage == ""
                              ? assetImages.defaultUser
                              : url.SERVER_URL + log.userimage
                          }
                          alt="Richard"
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </div>
                      <div>
                        <p className="d-flex align-items-center gap-2 mb-2">
                          <span className="d-block fs-md fw-semibold">
                            {log.username}
                          </span>
                          <span className="d-block fs-sm text-gray">
                            {getDateFormatInSpanish(log.startdate)}
                          </span>
                          <span className="d-block fs-xs lh-1 text-primary gradient-light px-2 py-1 rounded-90">
                            {log.hours}hr
                          </span>
                        </p>
                        <p
                          className="message body-bg px-3 py-2 fs-sm rounded-10"
                          dangerouslySetInnerHTML={{
                            __html: log.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* <!-- ========== End log list Section ========== --> */}

      {/* <!-- ========== Start log save Section ========== --> */}
      <div className="log-form body-bg px-4 py-3">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-2 mb-3 mb-lg-0">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Time (Hours)")}
                </label>
                <input
                  type="number"
                  className={`form-control bg-white fs-sm shadow-none ${
                    validation.hoursWarning
                      ? "border-danger"
                      : "border-gray-300"
                  }`}
                  placeholder="Hours required"
                  value={logHours}
                  onChange={(e) => {
                    setlogHours(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      hoursWarning: false,
                    }));
                  }}
                />
              </div>
              <div className="col-lg-5">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Dates")}
                </label>
                <div className="field-container d-flex flex-column flex-lg-row gap-lg-0 gap-2 align-items-lg-center bg-lg-white border-lg border-gray-300 rounded-10 mb-lg-0 mb-2">
                  <input
                    type="date"
                    id="startdate"
                    className="form-control bg-white border-gray-300 fs-sm shadow-none me-3"
                    placeholder={t("Start Date")}
                    value={logStartDate}
                    onChange={(e) => {
                      setlogStartDate(e.target.value);
                    }}
                  />
                  <input
                    type="date"
                    id="enddate"
                    className="form-control bg-white border-gray-300 fs-sm shadow-none"
                    placeholder={t("End Date")}
                    value={logEndDate}
                    onChange={(e) => {
                      setlogEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-2 mb-3 mb-lg-0">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Complete %")}
                </label>
                <input
                  type="number"
                  className="form-control bg-white fs-sm shadow-none border-gray-300"
                  value={taskPercent}
                  onChange={(e) => {
                    // if (e.target.value !== "") {
                    //   const convertedNumber = parseInt(e.target.value);
                    //   if (convertedNumber >= 0 && convertedNumber <= 100) {
                    //     settaskPercent(convertedNumber);
                    //   }
                    // }
                    if (e.target.value >= 0 && e.target.value <= 100) {
                      settaskPercent(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-lg-3 mb-3 mb-lg-0">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Task Status")}
                </label>
                <Select
                  placeholder="Select task status"
                  options={TaskStatusOptions}
                  value={taskStatusValue}
                  onChange={(val) => {
                    statusSelectionHandler(val);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
              {t("Details")}
            </label>

            {/* <Editor
              apiKey={editorConfig}
              init={{
                placeholder: t("Enter Details"),
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat  | help",
                height: 200,
              }}
              value={logDetails}
              onEditorChange={(newContent, editor) => {
                setlogDetails(newContent);
                setValidation((prevState) => ({
                  ...prevState,
                  detailsWarning: false,
                }));
              }}
            /> */}

            <JoditEditor
              ref={editor}
              value={logDetails}
              config={editorConfig}
              onChange={(newContent, event) => {
                setlogDetails(newContent);
              }}
            />

            {validation.detailsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter details!")}</span>
                </p>
              </div>
            )}
          </div>
          <div className="action d-flex align-items-center gap-3">
            {/* <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                <input
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  type="checkbox"
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label fs-md fw-semibold"
                  htmlFor="flexCheckDefault"
                >
                  Complete Task
                </label>
              </div>
              <p className="d-flex align-items-center gap-2 fs-xs">
                <span className="d-block material-symbols-outlined icon-md">
                  schedule
                </span>
                <span className="d-block">58Hrs / 100Hrs</span>
              </p> */}
            <button
              type="button"
              className="btn btn-primary ms-auto"
              onClick={saveLogHandler}
              disabled={logAdding ? true : false}
              style={{
                cursor: logAdding ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {logAdding && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
      {/* <!-- ========== End log save Section ========== --> */}
    </div>
  );
};
export default LogTab;
