/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Material UI table
import { MaterialReactTable } from "material-react-table";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import CourseTaskSubmittedHeader from "../CourseTaskSubmittedHeader/CourseTaskSubmittedHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import CourseTaskSubmittedFilterPopup from "../Popup/CourseTaskSubmittedFilterPopup";
import ViewTaskPopup from "../Popup/ViewTaskPopup";
import { assetImages } from "constants";
import { courseDetailsHeaderObject } from "helper/CourseHelper/CourseHelper";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteTaskWarningModal from "components/Common/Modal/DeleteTaskWarningModal";

const CourseTaskSubmittedBody = () => {
  const params = useParams();
  const { t, i18n } = useTranslation(); //for translation

  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [title, settitle] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [taskList, settaskList] = useState([]);

  //filter
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterStatus, setfilterStatus] = useState("");

  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [rowSelection, setRowSelection] = useState({});

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // --get course details --
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //get all submitted tasks
  const getAllSubmittedTasks = async () => {
    try {
      setisLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_SUBMITTED_TASKS +
        `?token=${token}&filtercourse=${params.id}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestURL += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterStatus !== "") {
        requestURL += `&filterstatus=${filterStatus}`;
      }

      const response = await getData(requestURL);

      console.log(response);

      setisLoading(false);

      if (response.status) {
        settaskList(response.data);
        setMessageType("success");
        resetFilter();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //delete multiple tasks
  const deActivateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        try {
          let requestUrl =
            url.API_BASE_URL +
            url.API_DEACTIVATE_PROJECT_TASK +
            `/${updatedTaskId}` +
            `?token=${token}`;

          const response = await putData(requestUrl);

          if (response.status) {
            setMessageType("success");
            responseArr.push(response.status);
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType("error");
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllSubmittedTasks();
        }, 1500);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //reset filter
  const resetFilter = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterStatus("");
  };

  //Material React Table Column and States
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#viewTask"
            aria-controls="viewTask"
            onClick={() => {
              setSelectedTaskId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: t("Title"),
        size: 250,
      },
      {
        accessorKey: "lessonname",
        header: t("Lesson"),
        size: 250,
      },
      {
        accessorKey: "submittedusername",
        header: t("Submitted By"),
        size: 200,
        Cell: ({ row }) => (
          <div className="moderator border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "22px", height: "22px" }}
              >
                <img
                  src={
                    row.original.submitteduserimage == ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.submitteduserimage
                  }
                  alt="admin"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{row.original.submittedusername}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "taskstartdate",
        header: t("Start Date"),
      },
      {
        accessorKey: "taskenddate",
        header: t("End Date"),
      },
      {
        accessorKey: "approvalstatus",
        header: t("Status"),
        size: 200,
        Cell: ({ row }) => (
          <td className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-2 fs-md fw-semibold ${
                row.original.approvalstatus === "Approved"
                  ? "text-success"
                  : "text-danger"
              }  `}
            >
              <span className="d-block material-symbols-outlined icon-md">
                {row.original.approvalstatus === "Approved"
                  ? "check_circle"
                  : "cancel"}
              </span>
              <span className="d-block">{row.original.approvalstatus}</span>
            </p>
          </td>
        ),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        size: 250,
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
        // Filter: globalFilterTextHandler,
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTaskIds(selectedIdsArray);
    } else {
      setSelectedTaskIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getAllSubmittedTasks();
    }
  }, [params.id]);

  useEffect(() => {
    if (filterStartDate !== "" || filterEndDate !== "" || filterStatus !== "") {
      getAllSubmittedTasks();
    }
  }, [filterStartDate, filterEndDate, filterStatus]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COURSE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Task Submitted")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter  section ----------- */}
            <CourseTaskSubmittedHeader
              title={title}
              reloadList={getAllSubmittedTasks}
              onDelete={deActivateBulkTaskHandler}
            />

            {/* ------- list area ---------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={taskList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,

                    columnOrder: initialColumnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,

                    columnOrder: initialColumnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ======== popups and modal section =========== */}
        {/* --------- add label modal ------------ */}
        <AddLabelModal
          moduleName="task"
          selectedIds={selectedTaskIds}
          afterTagModalClose={getAllSubmittedTasks}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* -------------- filter popup ---------------- */}
        <CourseTaskSubmittedFilterPopup
          setfilterStartDate={setfilterStartDate}
          setfilterEndDate={setfilterEndDate}
          setfilterStatus={setfilterStatus}
        />

        {/* ---------- view task popup ---------- */}
        <ViewTaskPopup
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          afterPopupclose={getAllSubmittedTasks}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <DeleteTaskWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedTaskIds([]);
          }}
          onDelete={deActivateBulkTaskHandler}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseTaskSubmittedBody;
