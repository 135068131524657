import Header from "components/Common/Header/Header";
import CrmOpportunityBody from "components/CRMComponents/CrmOpportunity/OpportunityBody/CrmOpportunityBody";
import React, { useEffect } from "react";

const CrmOpportunies = () => {
  useEffect(() => {
    document.title = "CRM Opportunity";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body section --- */}
      <CrmOpportunityBody />
    </main>
  );
};

export default CrmOpportunies;
