import Header from "components/Common/Header/Header";
import CourseLessonBody from "components/CourseComponents/CourseDetails/CourseLessonComponents/ListComponents/CourseLessonBody/CourseLessonBody";
import React, { useEffect } from "react";

const CourseLessonsList = () => {
  useEffect(() => {
    document.title = "Course Lessons | Task";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CourseLessonBody />
    </main>
  );
};

export default CourseLessonsList;
