/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';

// import CRMProceessData from "../../../../data/CRM/CRMProcessData.json";
import CRMProcessHeader from '../CRMProcessHeader/CRMProcessHeader';
// import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import { crmCommonHeaderLinkLists } from 'helper/CrmHelper/CrmHelper';
import { assetImages } from 'constants';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';

import { useTranslation } from 'react-i18next';
import AddProcessFromTemplateModal from 'components/Common/Modal/AddProcessFromTemplateModal';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const CRMProcessBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);
  const token = localStorage.getItem('token');
  const ecosystemSlug = localStorage.getItem('ecosystemslug');
  const moduleAccess = localStorage.getItem('moduleaccess');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [isLoading, setisLoading] = useState(false);
  const [processList, setprocessList] = useState([]);
  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectProcessIds, setSelectProcessIds] = useState([]);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const getAllProcess = async () => {
    setRowSelection({});
    setSelectProcessIds([]);
    try {
      setisLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PROCESS +
        `?token=${token}&ismod=${true}&istemplate=${false}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      setisLoading(false);
      console.log('response process----->', response);

      if (response.status) {
        setMessageType('success');
        setprocessList(response.data);
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  //function for change status = 0
  const changeStatusHandler = async () => {
    if (selectProcessIds.length > 0) {
      let responseArr = [];

      for (let processId of selectProcessIds) {
        try {
          let tagData = {
            status: '0',
          };
          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_PROCESS +
            `/${processId}` +
            `?token=${token}`;

          const response = await putData(requestUrl, tagData);

          if (response.status) {
            setMessageType('success');
            responseArr.push(true);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllProcess();
        }, 2200);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select process for delete');
      setShowAlert(true);
    }
  };

  useEffect(() => {
    getAllProcess();
  }, []);
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t('Name'),
      },
      {
        accessorKey: 'slug',
        header: t('Slug'),
      },
      {
        accessorKey: 'moduleslug',
        header: t('Module'),
      },
      {
        accessorKey: 'ownername',
        header: t('Owner'),
      },
      {
        accessorKey: 'followersnames',
        header: t('Followers'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Labels'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      // {
      //   accessorKey: "#",
      //   header: "Action",
      //   Cell: ({ row }) => (
      //     <div className="label border-bottom-0">
      //       <Link
      //         to="#activityProcessOffCanvas"
      //         className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
      //         data-bs-toggle="offcanvas"
      //         role="button"
      //         aria-controls="activityProcessOffCanvas"
      //         onClick={() => {
      //           setSelectedProcessId(row.original._id);
      //         }}
      //       >
      //         <span className="d-block">Details</span>
      //         <span className="d-block material-symbols-outlined">
      //           chevron_right
      //         </span>
      //       </Link>
      //     </div>
      //   ),
      // },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectProcessIds(selectedIdsArray);
    } else {
      setSelectProcessIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    (moduleAccess.includes('MOD_CRM') && moduleAccess.includes('MOD_PROCESS'))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Activity Process')}
          />
          <div className="container-fluid px-5">
            <CRMProcessHeader reloadList={getAllProcess} />
            {/* ------------- table section ------------------ */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={processList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit">
                      <Link
                        className="dropdown-item"
                        to={`/admin/crm/process/save/${row.id}`}
                        // data-bs-toggle="offcanvas"
                        // role="button"
                        // aria-controls="offcanvasLabelDetails"
                      >
                        View Details
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}

        <AddProcessFromTemplateModal
          moduleSlug={''}
          afterProcessModalClose={() => {
            setTimeout(() => {
              getAllProcess();
            }, 2000);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectProcessIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('process')}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default CRMProcessBody;
