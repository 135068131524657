/* eslint-disable */
import React from 'react';

const FeedbackDetailsPopup = () => {
  return (
    <div
      className="offcanvas sm offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFeedbackDetails"
      aria-labelledby="offcanvasFeedbackDetailsLabel"
    >
      <div className="offcanvas-header gap-3 p-4 pb-0">
        <h3
          className="offcanvas-title fw-bold"
          id="offcanvasFeedbackDetailsLabel"
        >
          Feedback
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="">
          <div className="form-group mb-3 mb-sm-4">
            <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
              20/07/2021 at 17:31
            </label>
            <div className="row gx-2">
              <div className="col-lg-6 mb-2 mb-lg-0">
                <div className="fs-sm p-3 body-bg rounded-10">
                  <p className="mb-0">Provider</p>
                  <p className="fw-semibold">Richard Hawail</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="fs-sm p-3 body-bg rounded-10">
                  <p className="mb-0">Receiver</p>
                  <p className="fw-semibold">Margarita Segoui</p>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-3 mb-sm-4">
            <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
              Rating
            </label>
            <ul className="stars d-flex">
              <li>
                <span className="d-block material-symbols-outlined icon-fill text-primary">
                  star
                </span>
              </li>
              <li>
                <span className="d-block material-symbols-outlined icon-fill text-primary">
                  star
                </span>
              </li>
              <li>
                <span className="d-block material-symbols-outlined icon-fill text-primary">
                  star
                </span>
              </li>
              <li>
                <span className="d-block material-symbols-outlined text-gray">
                  star
                </span>
              </li>
              <li>
                <span className="d-block material-symbols-outlined text-gray">
                  star
                </span>
              </li>
            </ul>
          </div>
          <div className="form-group mb-3 mb-sm-4">
            <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
              Area
            </label>
            <p className="text-gray">Communication and network</p>
          </div>
          <div className="form-group mb-3 mb-sm-4">
            <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
              Message
            </label>
            <p className="text-gray">
              My bad, I don't actually have those Processor's to test, but, the
              i5 9600k is about ( a little worse) on par with the Ryzen 5 2600,
              so I am just going to go with it. I read on some website that the
              1600 and 2600 was better, but, they are both worse than the 9600k.
              And, anyways I already have a B365 LGA 1151 Gen 2 motherboard, so,
              it's the most logical upgrade.
            </p>
          </div>
          <div className="form-group pb-3 pb-sm-4 mb-3 mb-sm-4 border-bottom border-gray-300">
            <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
              Brands
            </label>
            <p className="text-gray">Nike, Zara, Adidas</p>
          </div>
          <div className="form-group mb-3 mb-sm-4">
            <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
              Reply
            </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="2"
              className="form-control fs-sm shadow-none"
              placeholder="Enter message"
            ></textarea>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary w-100">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackDetailsPopup;
