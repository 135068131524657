import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import RoleListBody from "components/AdministrationComponents/RoleComponents/RoleListBody/RoleListBody";

const Roles = () => {
  useEffect(() => {
    document.title = "Administration | Roles";
  }, []);
  return (
    <main id="app">
      <Header moduleName="administration" />
      <RoleListBody />
    </main>
  );
};

export default Roles;
