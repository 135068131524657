import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const GroupProductOfferHeader = () => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <form action="">
            <div className="form-group d-flex align-items-center flex-row-reverse position-relative">
              <input
                type="text"
                className="form-control ps-2 bg-transparent border-0 shadow-none"
                placeholder="Search by group name"
              />
              <button type="submit" className="p-0 bg-transparent border-0">
                <span className="d-block material-symbols-outlined icon-lg">
                  search
                </span>
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            to="#"
            className="btn btn-outline-primary d-flex align-items-center gap-1"
            data-bs-toggle="modal"
            data-bs-target="#addOffer"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">Add New</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GroupProductOfferHeader;
