import CRMListBody from "components/CRMComponents/CRMListComponents/CRMListBody/CRMListBody";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const CrmList = () => {
  useEffect(() => {
    document.title = "CRM List";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body section --- */}
      <CRMListBody />
    </main>
  );
};

export default CrmList;
