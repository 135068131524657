/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LabelFilterPopup = ({ setFilterLabelName, setFilterLabelSlug }) => {
  const [labelName, setLabelName] = useState("");
  const [labelSlug, setLabelSlug] = useState("");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();
  //function for search

  const searchHandler = () => {
    setFilterLabelName(labelName);
    setFilterLabelSlug(labelSlug);
    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasLabelFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };
  //function for reset
  const resetHandler = () => {
    setLabelName("");
    setLabelSlug("");
  };
  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasLabelFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* label name */}
          <div className="form-group mb-4">
            <label htmlFor="Label" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Label Title")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Title")}
              value={labelName}
              onChange={(e) => setLabelName(e.target.value)}
            />
          </div>
          {/* label slug */}
          <div className="form-group mb-4">
            <label htmlFor="Slug" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Label Slug")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Slug")}
              value={labelSlug}
              onChange={(e) => setLabelSlug(e.target.value)}
            />
          </div>
          <button
            onClick={searchHandler}
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};
export default LabelFilterPopup;
