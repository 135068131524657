import HiringTeamBody from "components/ChallengeComponents/ChallengeDetailsComponents/HiringTeam/HiringTeamBody/HiringTeamBody";
import Header from "components/Common/Header/Header";
import { useEffect } from "react";

const HiringTeam = () => {
  useEffect(() => {
    document.title = "Challenge | Hiring Team";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="challenges" />

      {/* ---------- body section ------------- */}
      <HiringTeamBody />
    </main>
  );
};

export default HiringTeam;
