import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import ProjectODSData from '../../../../../data/ProjectManagement/ProjectODSData.json';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import { useParams } from 'react-router-dom';
import ProjectODSHeader from '../ProjectODSHeader/ProjectODSHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import ODSConfirmationModal from '../Modals/ODSConfirmationModal';

const ProjectODSBody = () => {
  const params = useParams();

  // -------- tabs header object --------------------
  const commonHeaderObject = [
    {
      title: 'Details',
      link: `/admin/projectmanagement/details/${params.id}`,
    },
    { title: 'Members', link: `/admin/projectmanagement/members/${params.id}` },
    { title: 'Tasks', link: `/admin/projectmanagement/tasks/${params.id}` },
    { title: 'Logs', link: `/admin/projectmanagement/logs/${params.id}` },
    { title: 'ODS', link: `/admin/projectmanagement/ods/${params.id}` },
    {
      title: 'Investments',
      link: `/admin/projectmanagement/investment/${params.id}`,
    },
    { title: 'KPIs', link: `/admin/projectmanagement/kpis/${params.id}` },
    { title: 'Report', link: `/admin/projectmanagement/report/${params.id}` },
  ];

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: 'Projects', link: '/admin/projectmanagement/list' },
    { title: 'Details' },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'task',
        header: 'Task',
        Cell: ({ row }) => (
          <td
            className="task border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <div className="d-flex align-items-center gap-4">
              <p className="fs-md fw-semibold mb-0">{row.original.task}</p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'owner',
        header: 'Owner',
        Cell: ({ row }) => (
          <td
            className="owner border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <p>{row.original.owner}</p>
          </td>
        ),
      },
      {
        accessorKey: 'provider',
        header: 'Provider',
        Cell: ({ row }) => (
          <td
            className="provider border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <p>{row.original.provider}</p>
          </td>
        ),
      },
      {
        accessorKey: 'auditor',
        header: 'Auditor',
        Cell: ({ row }) => (
          <td
            className="auditor border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <p>{row.original.auditor}</p>
          </td>
        ),
      },
      {
        accessorKey: 'cost',
        header: 'Cost',
        Cell: ({ row }) => (
          <td
            className="cost border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <p>{row.original.cost}</p>
          </td>
        ),
      },
      {
        accessorKey: 'hours',
        header: 'Hours',
        Cell: ({ row }) => (
          <td
            className="hours border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <p>{row.original.hours}</p>
          </td>
        ),
      },
      {
        accessorKey: 'ecoin',
        header: 'Ecoin',
        Cell: ({ row }) => (
          <td
            className="ecoin border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <p>{row.original.ecoin}</p>
          </td>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <td
            className="status border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <p
              className={
                row.original.status === 'Pending'
                  ? 'text-primary'
                  : 'text-success'
              }
            >
              {row.original.status}
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'ODS',
        header: 'ODS',
        Cell: () => (
          <td
            className="ods border-bottom-0 cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#odsConfirmation"
          >
            <div className="d-flex gap-1">
              <div
                className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                style={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#E5233D',
                }}
              >
                <img
                  src="/assets/img/family.png"
                  alt="Fin De La Pobreza"
                  className="img-fluid"
                />
              </div>
              <div
                className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                style={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#DDA73A',
                }}
              >
                <img
                  src="/assets/img/hot-soup.png"
                  alt="Hambre Cero"
                  className="img-fluid"
                />
              </div>
              <div
                className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                style={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#4DA247',
                }}
              >
                <img
                  src="/assets/img/heart-report.png"
                  alt="Salud Y Bienestar"
                  className="img-fluid"
                />
              </div>
            </div>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ------ common header -------- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption="ODS"
          />
        )}
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ------- bread crumb -------- */}
          <BreadCrumb breadCrumbText={breadCrumbText} bottom={true} />

          {/* ------- filter area --------- */}
          <ProjectODSHeader />

          {/* ------ table area ------- */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="task bg-transparent fs-md fw-semibold border-0"
                      width="25%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllODS"
                            name="ods"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Task</span>
                      </div>
                    </th>
                    <th className="owner bg-transparent fs-md fw-semibold border-0">
                      Owner
                    </th>
                    <th className="provider bg-transparent fs-md fw-semibold border-0">
                      Provider
                    </th>
                    <th className="auditor bg-transparent fs-md fw-semibold border-0">
                      Auditor
                    </th>
                    <th className="cost bg-transparent fs-md fw-semibold border-0">
                      Cost
                    </th>
                    <th className="hours bg-transparent fs-md fw-semibold border-0">
                      Hours
                    </th>
                    <th className="ecoin bg-transparent fs-md fw-semibold border-0">
                      Ecoin
                    </th>
                    <th className="status bg-transparent fs-md fw-semibold border-0">
                      Status
                    </th>
                    <th className="ods bg-transparent fs-md fw-semibold border-0">
                      ODS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods1"
                            value="ods1"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500</p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-primary">Pending</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#4DA247',
                          }}
                        >
                          <img
                            src="/assets/img/heart-report.png"
                            alt="Salud Y Bienestar"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods2"
                            value="ods2"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#FCC314',
                          }}
                        >
                          <img
                            src="/assets/img/sun.png"
                            alt="Energía Asequible Y No Contaminante"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#A31C45',
                          }}
                        >
                          <img
                            src="/assets/img/chart.png"
                            alt="Trabajo Decente Y Crecimiento Económico"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods3"
                            value="ods3"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods4"
                            value="ods4"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#4DA247',
                          }}
                        >
                          <img
                            src="/assets/img/heart-report.png"
                            alt="Salud Y Bienestar"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods5"
                            value="ods5"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#28C0E7',
                          }}
                        >
                          <img
                            src="/assets/img/clean-water.png"
                            alt="Agua Limpia Y Saneamiento"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods6"
                            value="ods6"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#4DA247',
                          }}
                        >
                          <img
                            src="/assets/img/heart-report.png"
                            alt="Salud Y Bienestar"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods7"
                            value="ods7"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#FCC314',
                          }}
                        >
                          <img
                            src="/assets/img/sun.png"
                            alt="Energía Asequible Y No Contaminante"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods8"
                            value="ods8"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#4DA247',
                          }}
                        >
                          <img
                            src="/assets/img/heart-report.png"
                            alt="Salud Y Bienestar"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods9"
                            value="ods9"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#A31C45',
                          }}
                        >
                          <img
                            src="/assets/img/chart.png"
                            alt="Trabajo Decente Y Crecimiento Económico"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#odsConfirmation"
                  >
                    <td className="task border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="ods"
                            id="ods10"
                            value="ods10"
                          />
                        </div>
                        <p className="fs-md fw-semibold mb-0">
                          Green plantation
                        </p>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <p>Jhon Smith</p>
                    </td>
                    <td className="provider border-bottom-0">
                      <p>Mori Kallies</p>
                    </td>
                    <td className="auditor border-bottom-0">
                      <p>Mono Dey</p>
                    </td>
                    <td className="cost border-bottom-0">
                      <p>$100</p>
                    </td>
                    <td className="hours border-bottom-0">
                      <p>120</p>
                    </td>
                    <td className="ecoin border-bottom-0">
                      <p>500 </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="text-success">Completed</p>
                    </td>
                    <td className="ods border-bottom-0">
                      <div className="d-flex gap-1">
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#E5233D',
                          }}
                        >
                          <img
                            src="/assets/img/family.png"
                            alt="Fin De La Pobreza"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#DDA73A',
                          }}
                        >
                          <img
                            src="/assets/img/hot-soup.png"
                            alt="Hambre Cero"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#FCC314',
                          }}
                        >
                          <img
                            src="/assets/img/sun.png"
                            alt="Energía Asequible Y No Contaminante"
                            className="img-fluid"
                          />
                        </div>
                        <div
                          className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden"
                          style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#28C0E7',
                          }}
                        >
                          <img
                            src="/assets/img/clean-water.png"
                            alt="Agua Limpia Y Saneamiento"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={ProjectODSData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* ========= popups and modal area ========== */}
      {/* ------- add label modal ------------ */}
      <AddLabelModal moduleName="" />

      {/* -------- ods confirmation modal ------------- */}
      <ODSConfirmationModal />
    </div>
  );
};

export default ProjectODSBody;
