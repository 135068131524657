/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material UI table
import { MaterialReactTable } from 'material-react-table';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import CourseTeamHeader from '../CourseTeamHeader/CourseTeamHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import CourseTeamFilterPopup from '../Popup/CourseTeamFilterPopup';
import { assetImages } from 'constants';
import AddNewMemberModal from '../Modals/AddNewMemberModal';
import { courseDetailsHeaderObject } from 'helper/CourseHelper/CourseHelper';
import MemberDetailsPopup from '../Popup/MemberDetailsPopup';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';

const CourseTeamBody = () => {
  const params = useParams();

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const { t, i18n } = useTranslation(); //for translation

  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  const [roleList, setRoleList] = useState([]);

  const [title, settitle] = useState('');

  const [isLoading, setisLoading] = useState(false);
  const [memberList, setmemberList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setselectedMemberIds] = useState([]);

  const [selectedMemberId, setselectedMemberId] = useState(null);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all roles
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLES +
        `?token=${token}&slug=MOD_COURSE`;

      const response = await getData(requestUrl);

      if (response.status) {
        setRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all member
  const getAllCourseMembers = async () => {
    setisLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_MEMBERS +
        `?token=${token}&courseid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      setisLoading(false);

      if (response.status) {
        setmemberList(response.data);
        setShowAlert(true);
        setMessageType('success');
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage('error');
      setAlertMessage(error.message);
    }
  };

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getAllCourseMembers();
      getAllRoles();
    }
  }, [params.id]);

  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),

        Cell: ({ row }) => {
          if (row.original.role === 'Moderator') {
            return (
              <button className="action_btn_mui">
                <span className="d-block material-symbols-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          } else {
            return (
              <button
                className="action_btn_mui"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasMemberDetails"
                aria-controls="offcanvasMemberDetails"
                onClick={() => {
                  setselectedMemberId(row.original._id);
                }}
              >
                <span className="d-block material-symbols-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          }
        },
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'name',
        header: t('Name'),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '35px', height: '35px' }}
              >
                <img
                  src={
                    row.original.image == ''
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.image
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">{row.original.name}</p>
                <Link to="#" className="d-block lh-1">
                  {row.original.email}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'role',
        header: t('Role'),
      },
      {
        accessorKey: 'moderatorstatus',
        header: t('Status'),
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <ul className="d-flex flex-wrap gap-3">
              {row.original.moderatorstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold"></span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.moderatorstatus == '1'
                        ? 'text-success'
                        : row.original.moderatorstatus == '0'
                        ? 'text-primary'
                        : 'text-gray'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Moderator</span>
                </li>
              )}
              {row.original.memberstatus == '' ? (
                <li className="d-flex align-items-center gap-1">
                  <span className="d-block fw-semibold">--</span>
                </li>
              ) : (
                <li className="d-flex align-items-center gap-1">
                  <span
                    className={`d-block material-symbols-outlined icon-fill icon-lg  ${
                      row.original.memberstatus == '1'
                        ? 'text-success'
                        : row.original.memberstatus == '0'
                        ? 'text-gray'
                        : 'text-warning'
                    }`}
                  >
                    check_circle
                  </span>
                  <span className="d-block fw-semibold">Member</span>
                </li>
              )}
            </ul>
          </div>
        ),
      },
      {
        accessorKey: 'requestdate',
        header: t('Date'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Labels'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [memberList, i18n.language]
  );

  const initialColumnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //optionally, you can manage the row selection state yourself

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_COURSE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t('Team')}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter  section ----------- */}
            <CourseTeamHeader title={title} reloadList={getAllCourseMembers} />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={memberList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ======== popups and modal section =========== */}
        {/* --------- add label modal ------------ */}
        <AddLabelModal moduleName="" />

        {/* ---------- filter popup -------------- */}
        <CourseTeamFilterPopup />

        <AddNewMemberModal
          roleList={roleList}
          afterCloseModalHandler={getAllCourseMembers}
        />

        <MemberDetailsPopup
          roleList={roleList}
          courseId={params.id}
          setMemberId={setselectedMemberId}
          memberId={selectedMemberId}
          afterPopupClose={getAllCourseMembers}
        />

        {/* --------- alert popup ------------ */}
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseTeamBody;
