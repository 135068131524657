import axios from "axios";

export const getUserBrowser = () => {
  const userAgent = navigator.userAgent;

  const browserPatterns = {
    Chrome: /Chrome/i,
    Firefox: /Firefox/i,
    Safari: /Safari/i,
    Edge: /Edg/i,
    IE: /MSIE|Trident\//i,
    Opera: /Opera|OPR\//i,
    Brave: /Brave/i,
    Vivaldi: /Vivaldi/i,
    UCBrowser: /UCBrowser/i,
    SamsungBrowser: /SamsungBrowser/i,
    // Add more browser patterns as needed
  };

  let detectedBrowser = "Unknown Browser";

  for (const browser in browserPatterns) {
    if (userAgent.match(browserPatterns[browser])) {
      detectedBrowser = browser;
      break;
    }
  }

  return detectedBrowser;
};

export const getPublicIP = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getTimeStamp = () => {
  const currentTimeDate = new Date();

  const loginDate = currentTimeDate.toISOString().split("T")[0];

  const hours = String(currentTimeDate.getHours()).padStart(2, "0");
  const minutes = String(currentTimeDate.getMinutes()).padStart(2, "0");

  const loginTime = `${hours}:${minutes}`;

  const loginTimeStamp = currentTimeDate.toISOString().replace("Z", "+00:00");

  return {
    loginDate,
    loginTime,
    loginTimeStamp,
  };
};
