import Header from "components/Common/Header/Header";
import CourseFaqBody from "components/CourseComponents/CourseDetails/CourseFaqComponents/CourseFaqBody/CourseFaqBody";
import React, { useEffect } from "react";

const CourseFaq = () => {
  useEffect(() => {
    document.title = "Course Faq";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <CourseFaqBody />
    </main>
  );
};

export default CourseFaq;
