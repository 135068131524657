/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import EventgalleryHeader from "../Header/EventgalleryHeader";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData, uploadMultipleFile } from "utils/Gateway";

import DeleteModal from "../Modal/DeleteModal";
import EmptyImageModal from "../Modal/EmptyImageModal";
import GallerySuccesModal from "../Modal/GallerySuccesModal";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { eventDetailsHeaderLinks } from "helper/EventHelper/EventHelper";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const EventGalleryBody = () => {
  const { t } = useTranslation(); //for translation
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  // -------- tabs header object --------------------
  const commonHeaderObject = eventDetailsHeaderLinks(params.id, t);

  const breadCrumbText = [
    { title: t("Event"), link: "/admin/events/list" },
    { title: t("Gallery") },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const [parentEventTitle, setParentEventTitle] = useState("");

  const imageFileInputRef = useRef(null);

  const [uploadedPreviewImages, setUploadedPreviewImages] = useState([]);
  const [uploadedImageIds, setUploadedImageIds] = useState([]); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);

  const [deleteImageId, setDeleteImageId] = useState(null);

  const [addSuccess, setAddSuccess] = useState(false);

  const [isEventModerator, setisEventModerator] = useState(false);

  const [eventMemberStatus, setEventMemberStatus] = useState("");
  const [eventModStatus, setEventModStatus] = useState("");

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_MEMBER_DETAILS_BY_QUERY +
        `?token=${token}&eventid=${params.id}`;

      const response = await getData(requestUrl);

      console.log("response in member details", response);

      if (response.status) {
        setEventMemberStatus(response.data.memberstatus);
        setEventModStatus(response.data.moderatorstatus);
        setinviteDate(response.data.requestdate);
        setJoinDate(response.data.joindate);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get event details
  const getEventDetails = async () => {
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of event gallery------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of event gallery------>", response);

      setIsLoading(false);
      if (response.status && response.data) {
        setParentEventTitle(response.data?.title);
        setGalleryImages(
          response.data.gallery.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );

        let isModerator = false;

        if (
          response.data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisEventModerator(isModerator);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files;
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, file);

      // console.log("upload response-------->", response);

      setIsUploading(false);
      resetGalleryImageFile();
      if (response.status) {
        setUploadedImageIds(response.data._id);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedImageValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new images will concat with old images
        if (uploadedPreviewImages.length == 0) {
          setUploadedPreviewImages(uploadedImageValues);
        } else {
          let fileArr = uploadedPreviewImages.concat(uploadedImageValues);
          setUploadedPreviewImages(fileArr);
        }
        /*----- assign uploaded images with path for display end ------*/

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedImageIds.length == 0) {
          setUploadedImageIds(fileIds);
        } else {
          let fileArr = uploadedImageIds.concat(fileIds);
          setUploadedImageIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeGalleryImageFileHandler = (index) => {
    const closedFile = [...uploadedPreviewImages];
    const removedFileId = [...uploadedImageIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedPreviewImages(closedFile);
    setUploadedImageIds(removedFileId);

    resetGalleryImageFile();
  };

  // function for clear file value
  const resetGalleryImageFile = () => {
    const file = document.getElementById("uploadedEventImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for add uploaded images to gallery
  const addGalleryImageHandler = async () => {
    if (uploadedImageIds.length > 0) {
      try {
        let imageData = {
          galleryimages: uploadedImageIds,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_ADD_EVENT_GALLERY_IMAGES +
          `/${params.id}?token=${token}`;

        const response = await putData(requestUrl, imageData);

        console.log(response);

        if (response.status) {
          setAddSuccess(true);
          let successModal = new bootstrap.Modal(
            document.getElementById("eventGallerySuccessModal")
          );
          successModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      let emptyModal = new bootstrap.Modal(
        document.getElementById("eventGalleryEmptyModal")
      );
      emptyModal.show();
    }
  };

  //function for delete image from gallery
  const deleteImageHandler = async () => {
    if (deleteImageId) {
      try {
        let imageData = {
          galleryimageid: deleteImageId,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_EVENT_GALLERY_IMAGE +
          `/${params.id}?token=${token}`;

        const response = await putData(requestUrl, imageData);

        console.log(response);

        if (response.status) {
          let successModal = new bootstrap.Modal(
            document.getElementById("eventGallerySuccessModal")
          );
          successModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function afterCallApi
  const afterCallApi = () => {
    setAddSuccess(false);
    resetGalleryImageFile();
    setUploadedPreviewImages([]);
    setUploadedImageIds([]);
    getEventDetails();
  };

  useEffect(() => {
    if (params.id) {
      getEventDetails();
    }
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_EVENT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Gallery")}
          />
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* --- bread crumb ---- */}

            {/* --- galler header ---- */}
            <EventgalleryHeader
              isEventModerator={isEventModerator}
              parentEventTitle={parentEventTitle}
              breadCrumbText={breadCrumbText}
              addGalleryImageHandler={addGalleryImageHandler}
            />

            {/* ---- gallery section start ---- */}
            {isEventModerator ||
            (eventMemberStatus === "1" && eventModStatus === "1") ? (
              <div className="gallery-container">
                <div className="row">
                  {/* ---- gallery data starts, use the js map loop here ---- */}
                  {galleryImages.map((image, index) => {
                    return (
                      <div
                        className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-4"
                        key={index}
                      >
                        <div className="gallery-content border border-gray-300 rounded-10 overflow-hidden">
                          <div className="img-container">
                            <img
                              src={image.path}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="desc d-flex gap-3 align-items-center justify-content-between p-3">
                            <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                              {/* <input
                        className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                        type="checkbox"
                        
                        id=""
                      /> */}
                              <label className="form-check-label" htmlFor="">
                                {image.name}
                              </label>
                            </div>
                            {isEventModerator ? (
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#eventGallerydeleteModal"
                                onClick={() => {
                                  setDeleteImageId(image._id);
                                }}
                              >
                                <span className="d-block material-symbols-outlined">
                                  delete
                                </span>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {uploadedPreviewImages.map((image, index) => {
                    return (
                      <div
                        className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-4"
                        key={index}
                      >
                        <div className="gallery-content border border-gray-300 rounded-10 overflow-hidden">
                          <div className="img-container">
                            <img
                              src={image.path}
                              alt="image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="desc d-flex gap-3 align-items-center justify-content-between p-3">
                            <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                              {/* <input
                            className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            
                            id=""
                          /> */}
                              <label className="form-check-label" htmlFor="">
                                {image.name}
                              </label>
                            </div>

                            <Link
                              to="#"
                              onClick={() => {
                                closeGalleryImageFileHandler(index);
                              }}
                            >
                              <span className="d-block material-symbols-outlined">
                                delete
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* upload button */}
                  {isEventModerator ? (
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-4">
                      <div className="upload-container h-100 d-flex flex-column align-items-center border justify-content-center border-gray-300 border-dashed rounded-10">
                        <Link
                          to="#"
                          data-target="upload"
                          className="upload-file text-center"
                          onClick={openImageFileInput}
                        >
                          <span className="d-block material-symbols-outlined">
                            upload
                          </span>
                          <span className="d-block fw-semibold">
                            {t("Upload")}
                          </span>
                        </Link>
                        <span className="fs-xs">Max 20mb doc/mp4</span>
                        <input
                          type="file"
                          data-id="upload"
                          className="d-none"
                          id="uploadedEventImageFile"
                          ref={imageFileInputRef}
                          onChange={imageUploadHandler}
                          multiple
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* ---- gallery data ends ---- */}
                </div>
              </div>
            ) : eventMemberStatus === "" && eventModStatus === "" ? null : (
              <div className="gallery-container">
                <div className="team_members mb-4">
                  <div className="challenges_empty text-center">
                    <div className="empty_pic mb-4">
                      {" "}
                      <img src={assetImages.emptyVector} alt="" />
                    </div>
                    <div className="empty_text">
                      <p className="fs-lg text-gray fw-semibold mb-4">
                        {t(
                          "Sorry....! You don't have privilege to see this content"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* ---- gallery section end ---- */}
          </div>
        </section>
        {/* === modals and popup section === */}
        <DeleteModal
          onDelete={deleteImageHandler}
          onCancelDelete={() => {
            setDeleteImageId(null);
          }}
        />
        <EmptyImageModal />
        <GallerySuccesModal
          afterModalClose={afterCallApi}
          addSuccess={addSuccess}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default EventGalleryBody;
