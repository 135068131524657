/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";
import { getTomorrowDate, hoursArr } from "helper/Common/CommonHelper";

import { GlobalProvider } from "context/GlobalContext";

const AddAlertModal = ({
  alertSubject = "",
  moduleName,
  afterModalClose,
  selectedIds,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const { setReloadAlertList } = useContext(GlobalProvider);

  const [subject, setsubject] = useState("");
  const [alertDate, setalertDate] = useState(getTomorrowDate());
  const [alertTime, setAlertTime] = useState("");

  const [validation, setValidation] = useState({
    subjectWarning: false,
    dateWarning: false,
    timeWarning: false,
  });

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (subject === "") {
      setValidation((prevState) => ({ ...prevState, subjectWarning: true }));
      isValid = false;
    }

    if (alertDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (alertTime === "") {
      setValidation((prevState) => ({ ...prevState, timeWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  const closeModalhandler = () => {};

  const createAlertHandler = async () => {
    if (validationHandler()) {
      if (selectedIds && selectedIds.length > 0) {
        let responseArr = [];

        for (let selectedId of selectedIds) {
          let alertData = {
            title: subject,
            date: alertDate,
            time: alertTime,
            leadid: null,
            contactid: null,
            taskid: null,
            status: "1",
          };

          if (moduleName && moduleName === "lead") {
            alertData.leadid = selectedId;
          }

          if (moduleName && moduleName === "contact") {
            alertData.contactid = selectedId;
          }

          if (moduleName && moduleName === "task") {
            alertData.taskid = selectedId;
          }

          try {
            let requestUrl =
              url.API_BASE_URL + url.API_ADD_NEW_ALERT + `?token=${token}`;

            const response = await postData(requestUrl, alertData);

            // console.log(response);

            if (response.status) {
              responseArr.push(true);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
        if (responseArr.length > 0) {
          resetHandler();
          let alertModal = document.querySelector("#createAlertModal");
          let modal = bootstrap.Modal.getInstance(alertModal);
          modal.hide();
          setReloadAlertList(true);
          afterModalClose();
        }
      }
    }
  };

  const resetHandler = () => {
    setsubject("");
    setalertDate(getTomorrowDate());
    // setAlertTime("");
    setValidation({
      subjectWarning: false,
      dateWarning: false,
      timeWarning: false,
    });
  };

  const getAlertsByDate = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_OTHERDAYS_ALERT +
        `?token=${token}&filterdate=${alertDate}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          let found = false;
          const alerts = response.data;

          for (let i = 0; i < hoursArr.length; i++) {
            const hourToCheck = hoursArr[i];
            found = alerts.some((alert) => alert.time.startsWith(hourToCheck));

            if (!found) {
              setAlertTime(`${hourToCheck}:00`);
              break;
            }
          }
        } else {
          setAlertTime(`00:00`);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (alertDate !== "") {
      getAlertsByDate();
    }
  }, [alertDate]);

  useEffect(() => {
    if (selectedIds.length === 1) {
      if (alertSubject !== "") {
        setsubject(alertSubject);
      }
    } else {
      setsubject("");
    }
  }, [selectedIds]);

  return (
    <div
      className="modal fade"
      id="createAlertModal"
      tabIndex="-1"
      aria-labelledby="createAlertModal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="createAlertModal">
              {t("Alert")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* <!-- ========== Start alert subject Section ========== --> */}
              <div className="form-group mb-4">
                <label
                  htmlFor="memberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Subject")}
                </label>

                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter Subject")}
                  value={subject}
                  onChange={(e) => setsubject(e.target.value)}
                />

                {validation.subjectWarning && (
                  <div className="error-message my-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter subject")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* <!-- ========== End alert subject Section ========== --> */}

              {/* <!-- ========== Start date and hour Section ========== --> */}
              <div className="row mb-4">
                <div className="col">
                  <div className="form-group">
                    <label
                      htmlFor="memberName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Date")}
                    </label>

                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      value={alertDate}
                      onChange={(e) => setalertDate(e.target.value)}
                    />

                    {validation.dateWarning && (
                      <div className="error-message my-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter date")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label
                      htmlFor="memberName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Time")}
                    </label>

                    <input
                      type="time"
                      className="form-control fs-sm shadow-none"
                      value={alertTime}
                      onChange={(e) => setAlertTime(e.target.value)}
                    />

                    {validation.timeWarning && (
                      <div className="error-message my-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter time")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- ========== End date and hour Section ========== --> */}
            </form>
          </div>
          <div className="modal-footer p-4 border-0 ">
            <div className="d-flex w-100 gap-2 align-items-center">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={createAlertHandler}
              >
                {t("Create Alert")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAlertModal;
