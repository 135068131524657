/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData, uploadSingleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import smtpSecureOptions from "data/Prod/smtpSecureOptions.json";
import { ckEditorConfig } from "Config/Config";

const SaveEcosystemPopup = ({
  afterPopupCLose,
  ecosystemId,
  setEcosystemId,
}) => {
  const token = localStorage.getItem("token"); // token

  const { t } = useTranslation(); // for translations

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [details, setdetails] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id

  const [smtpHost, setSmtpHost] = useState("");
  const [smtpUser, setSmtpUser] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const [smtpSecure, setSmtpSecure] = useState(null);
  const [useSmtpConfigValue, setUseSmtpConfigValue] = useState(false);
  const [useSmtpConfig, setUseSmtpConfig] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];

    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log(response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(response.data.path);
        setUploadedImageId(response.data._id);
      }
      resetFile();
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadLogoEcosystem");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // send a mail to check if config is validated
  const validateSmtpConfig = async () => {
    alert("send test mail and check connection");
  };

  //get details
  const getEcosystemDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ECOSYSTEM_DETAILS +
        `/${ecosystemId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setName(response.data.name);
        setSlug(response.data.slug);
        setdetails(response.data.description);
        setIsFeatured(response.data.featured);
        setIsDefault(response.data.isdefault);
        setUploadedImageId(response.data.logo._id);
        setUploadedPreviewImage(
          response.data.logo ? response.data.logo.path : ""
        );

        // mail configuration -----------------
        if (response.data.smtphost && response.data.smtphost.length > 0) {
          setSmtpHost(response.data.smtphost);
        }

        if (response.data.smtpuser && response.data.smtpuser.length > 0) {
          setSmtpUser(response.data.smtpuser);
        }

        if (response.data.smtppass && response.data.smtppass.length > 0) {
          setSmtpPassword(response.data.smtppass);
        }

        if (response.data.smtpport && response.data.smtpport.length > 0) {
          setSmtpPort(response.data.smtpport);
        }

        for (let smtpSecureOption of smtpSecureOptions) {
          // set secure smtp connect option YES/NO
          if (smtpSecureOption.value == response.data.smtpsecure) {
            setSmtpSecure(smtpSecureOption);
          }

          // set either to use this config or not, YES / NO
          if (smtpSecureOption.value == response.data.usesmtpmail) {
            setUseSmtpConfig(smtpSecureOption);
            setUseSmtpConfigValue(smtpSecureOption.value);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        const randomNum = Math.random() * 1000;

        let sendingData = {
          name,
          slug,
          secret: randomNum.toString(),
          description: details,
          featured: isFeatured,
          isdefault: isDefault,
          logo: uploadedImageId,
          smtphost: smtpHost && smtpHost.length > 2 ? smtpHost.trim() : "",
          smtpuser: smtpUser && smtpUser.length > 2 ? smtpUser.trim() : "",
          smtppass:
            smtpPassword && smtpPassword.length > 2 ? smtpPassword.trim() : "",
          smtpport: smtpPort && smtpPort.length > 1 ? smtpPort.trim() : "",
          smtpsecure: smtpSecure ? smtpSecure.value : false,
          usesmtpmail: useSmtpConfig ? useSmtpConfig.value : false,
        };

        console.log(sendingData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (ecosystemId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_ECOSYSTEM +
            `/${ecosystemId}` +
            `?token=${token}`;

          response = await putData(requestUrl, sendingData);
        } else {
          requestUrl = requestUrl + url.API_ADD_ECOSYSTEM + `?token=${token}`;

          response = await postData(requestUrl, sendingData);
        }

        setIsSaving(false);
        console.log(response);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#saveEcosystem")
          ).hide();
          resetHandler();
          afterPopupCLose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    resetFile();
    setName("");
    setSlug("");
    setdetails("");
    setIsFeatured(false);
    setIsDefault(false);
    setUploadedImageId(null);
    setUploadedPreviewImage("");
    setEcosystemId(null);
    setSmtpHost("");
    setSmtpUser("");
    setSmtpPassword("");
    setSmtpPort("");
    setSmtpSecure(null);
    setUseSmtpConfigValue(false);
    setUseSmtpConfig(null);
  };

  useEffect(() => {
    if (ecosystemId) {
      getEcosystemDetails();
    }
  }, [ecosystemId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0 mobile_offcanvas"
      tabIndex="-1"
      id="saveEcosystem"
      aria-labelledby="saveEcosystem"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Save Ecosystem")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Title")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                }}
              />
              {/* lebel warning */}
              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter label")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* slug */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Slug")}
              </label>
              <input
                type="text"
                id="slug"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    slugWarning: false,
                  }));
                }}
              />
              {/* slug warning */}
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* ------- editor section start ------- */}
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Details")}
              </label>
              <CKEditor
                className="form-control fs-sm shadow-none"
                editor={ClassicEditor}
                config={ckEditorConfig(t("Type description here..."))}
                data={details}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  let editorContent = editor.getData();
                  setdetails(editorContent);
                }}
              />
            </div>
            {/* ------- editor section end ------- */}

            {/* feature and default  */}
            <div className="form-group mb-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                    <input
                      className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                      type="checkbox"
                      checked={isFeatured}
                      onChange={(e) => setIsFeatured(e.target.checked)}
                    />
                    <label
                      className="form-check-label fw-semibold"
                      htmlFor="private"
                    >
                      {t("Featured")}
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                    <input
                      className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                      type="checkbox"
                      checked={isDefault}
                      onChange={(e) => setIsDefault(e.target.checked)}
                    />
                    <label
                      className="form-check-label fw-semibold"
                      htmlFor="private"
                    >
                      {t("Is Default")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* ========= file upload start ========== */}
            <div className="form-group mb-4">
              {isUploading ? (
                <div className="d-flex align-items-center gap-1 mb-1">
                  <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                    return (
                      <div
                        key={index}
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        style={{ height: "6px", width: "6px" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <label
                  htmlFor="logo"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Logo")}
                </label>
              )}

              <input
                type="file"
                className="form-control"
                id="uploadLogoEcosystem"
                onChange={imageUploadHandler}
              />

              <div
                className="img-preview border border-2 border-gray rounded-1 overflow-hidden"
                style={{ width: "120px", height: "120px" }}
              >
                <img
                  src={
                    uploadedPreviewImage == ""
                      ? "https://placehold.co/150x150"
                      : url.SERVER_URL + uploadedPreviewImage
                  }
                  alt="user"
                  className="w-100 h-100 object-cover object-center"
                />
              </div>
            </div>
            {/* ========= file upload end ===========*/}

            {/* Mail sending configuration for this user ---------------------- */}
            <h3>{t("Send Mail Configuration (for sending mails)")}</h3>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">{t("SMTP Host")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("SMTP Host")}
                    value={smtpHost}
                    onChange={(e) => setSmtpHost(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">{t("SMTP User")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("SMTP User Email")}
                    value={smtpUser}
                    onChange={(e) => setSmtpUser(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">{t("SMTP Password")}</p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("SMTP Password")}
                    value={smtpPassword}
                    onChange={(e) => setSmtpPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("SMTP Port")} (
                    <i>
                      {t("587 for non-SSL")}, {t("465 for SSL")}
                    </i>
                    )
                  </p>
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Set port")}
                    value={smtpPort}
                    onChange={(e) => setSmtpPort(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Is secure connection?")} (<i>{t("true if SSL/TLS")}</i>)
                  </p>
                </div>
                <div className="col-lg-6">
                  <Select
                    isClearable
                    placeholder={t("Is secure connection?")}
                    options={smtpSecureOptions}
                    value={smtpSecure}
                    onChange={(val) => {
                      setSmtpSecure(val);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mb-sm-4">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                  <p className="fs-md fw-semibold">
                    {t("Use this configuration to send mails")}
                  </p>
                </div>
                <div className="col-lg-4">
                  <Select
                    options={smtpSecureOptions}
                    value={useSmtpConfig}
                    onChange={(val) => {
                      setUseSmtpConfig(val);
                    }}
                  />
                </div>

                <div className="col-lg-2">
                  {useSmtpConfigValue == true ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      onClick={validateSmtpConfig}
                    >
                      {t("Check Connection")}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3 pb-4">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveEcosystemPopup;
