import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ActivityProcessTemplateDeatils from "components/AdministrationComponents/ActivityProcessTemplate/ActivityProcessTemplateDetails/ActivityProcessTemplateDeatils";

const SaveProcessTemplate = () => {
  useEffect(() => {
    document.title = "Administration | Save Activity process Template";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <ActivityProcessTemplateDeatils />
    </main>
  );
};

export default SaveProcessTemplate;
