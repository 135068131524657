//function for get current time
const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const timenow = `${hours}:${minutes}`;
  return timenow;
};

//function for calculate and get end time
const getEndTime = (duration, startingTime) => {
  const additionalTime = parseInt(duration, 10);

  let timeParts = startingTime.split(":");
  let hours = parseInt(timeParts[0], 10);
  let minutes = parseInt(timeParts[1], 10);
  let timeDate = new Date();

  timeDate.setHours(hours);
  timeDate.setMinutes(minutes);

  // Calculate the result by adding the number to the time
  let resultDate = new Date(timeDate.getTime() + additionalTime * 60 * 1000);

  // Extract the individual components (hours, minutes) from the result date
  let resultHours = resultDate.getHours();
  let resultMinutes = resultDate.getMinutes();

  // Format the result as a string
  let newHour = resultHours.toString().padStart(2, "0");
  let newMinute = resultMinutes.toString().padStart(2, "0");

  const timeExtended = `${newHour}:${newMinute}`;

  return timeExtended;
};

//get converted gantt time
const getConvertedGanttDate = (dateObject) => {
  // Extract date components
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(dateObject.getDate()).padStart(2, "0");

  // Formatted date string in yyyy-mm-dd format
  const formattedDate = `${year}-${month}-${day}`;

  // Output the formatted date
  return formattedDate; // Output: 2024-04-13
};

//get converted format for alert
const getAlertDateFormat = (dateString) => {
  if (!dateString || dateString === "") {
    return "";
  }
  // Parse the input date string
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Function to get ordinal suffix for day
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return "th"; // Special case for 11th, 12th, 13th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Construct formatted date string
  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};

export {
  getCurrentTime,
  getEndTime,
  getConvertedGanttDate,
  getAlertDateFormat,
};
