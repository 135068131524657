/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// import { Editor } from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

/*import url and gateway methods */
import {
  getData,
  postData,
  uploadMultipleFile,
  uploadSingleFile,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

// import mail variables from json file
import mailVariables from "data/Prod/MailVariables.json";

//import file download function
import downloadFileHandler from "helper/DownloadFile";
import { joditEditorConfig } from "Config/Config";
import { useTranslation } from "react-i18next";

const ConversationNewMailPopup = ({
  reloadList,
  contactPersonEmail,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token"); // token

  // loggedin user information
  const currentUser = JSON.parse(localStorage.getItem("userInfo"));

  // ------------ hooks for user Inputs --------------------------------
  const [mailTemplateList, setMailTemplateList] = useState([]);
  /* ------------- Language translation imports starts here ------------- */
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [receivermails, setReceivermails] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [mailDescription, setMailDescription] = useState(""); //for ck editor
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadIds, setFileUploadIds] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [mailSending, setMailSending] = useState(false);
  const [linkTrackerBlock, setlinkTrackerBlock] = useState([]);

  //link tracker block
  const linkTrackerTemplate = {
    link: "",
    label: "",
  };

  /* hook for validation */
  const [validation, setValidation] = useState({
    emailWarning: false,
    subjectWarning: false,
  });

  const [warningMessage, setWarningMessage] = useState("");

  const editor = useRef(null);

  // const editorCustomConfig = joditEditorConfig();
  const editorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 400,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestUrl =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestUrl, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setMailDescription(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const editorConfig = useMemo(() => editorCustomConfig, []);

  //function for get all mail templates
  const getAllMailTemplates = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MAIL_TEMPLATES +
        `?token=${token}&isselect=true`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setMailTemplateList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //for adding a new link tracker block
  const onAddLinkTrackerBlock = () => {
    //console.log('linkTrackerBlock >> ', linkTrackerBlock)

    setlinkTrackerBlock([...linkTrackerBlock, linkTrackerTemplate]);
  };

  //** Function for delete link tracker block */
  const onDeleteLinkTrackerBlock = (index) => {
    const filteredLinkTrackerBlock = [...linkTrackerBlock];
    filteredLinkTrackerBlock.splice(index, 1);
    setlinkTrackerBlock(filteredLinkTrackerBlock);
  };

  //** Function for change link tracker value */
  const changeLinkTrackerValue = (index, field, value) => {
    const updatedFormValues = linkTrackerBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    //console.log('updatedFormValues', updatedFormValues);
    setlinkTrackerBlock(updatedFormValues);
  };

  //** Function for mapping form repeater data */
  const updatedLinkTrackerBlock = (linkTracker = []) => {
    const linkTrackerArr = linkTracker.map((linkTrackerData) => {
      let linkTrackerSavedData = {
        link: linkTrackerData?.link,
        label: linkTrackerData?.label,
      };

      return linkTrackerSavedData;
    });
    setlinkTrackerBlock(linkTrackerArr);
  };

  //function for file upload
  const uploadHandler = async (e) => {
    try {
      setIsUploading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;
      const response = await uploadMultipleFile(requestUrl, e.target.files);
      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );
        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/
        setIsUploading(false);
        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));
        //check old file ids are exist or not.If exist then new id will concat with old id
        if (fileUploadIds.length == 0) {
          setFileUploadIds(fileIds);
        } else {
          let fileArr = fileUploadIds.concat(fileIds);
          setFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
        resetFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...fileUploadIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedFiles(closedFile);
    setFileUploadIds(removedFileId);
    resetFile();
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("uploadedMailUploadFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select template
  const templateSelectionHandler = (val) => {
    //assign template value for select
    setSelectedTemplate(val);

    //set value for send mail
    setMailSubject(val.subject);

    // setEditorContent(val.description);
    setMailDescription(val.description);

    //assing link text

    if (val.linktrackerids && val.linktrackerids.length > 0) {
      updatedLinkTrackerBlock(val.linktrackerids);
    }

    //assign uploaded files from mail template
    const uploadedFileValues = val.uploads.map(({ name, path, _id }) => ({
      name,
      path: url.SERVER_URL + path,
      _id,
    }));
    if (uploadedFiles.length == 0) {
      setUploadedFiles(uploadedFileValues);
    } else {
      let fileArr = uploadedFiles.concat(uploadedFileValues);
      setUploadedFiles(fileArr);
    }
    //assign uploaded file id for sending mail
    const fileIds = val.uploads.map(({ _id, ...other }) => ({ _id }));
    if (fileUploadIds.length == 0) {
      setFileUploadIds(fileIds);
    } else {
      let fileArr = fileUploadIds.concat(fileIds);
      setFileUploadIds(fileArr);
    }
    setValidation((prevState) => ({
      ...prevState,
      subjectWarning: false,
    }));
  };

  // validate =========================
  const validate = () => {
    let isValid = true;
    if (receivermails == "") {
      if (contactPersonEmail == "") {
        setValidation((prevState) => ({ ...prevState, emailWarning: true }));
        isValid = false;
      }
    }
    if (mailSubject === "") {
      setValidation((prevState) => ({ ...prevState, subjectWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  /* function to add label */
  const saveMailHandler = async () => {
    if (validate()) {
      let templateId = selectedTemplate ? selectedTemplate.value : null;

      let mailData = {
        receivermails:
          contactPersonEmail != "" ? contactPersonEmail : receivermails,
        subject: mailSubject,
        sender: currentUser._id,
        description: mailDescription,
        messagetemplate: templateId,
        linktrackers: linkTrackerBlock,
        uploads: fileUploadIds,
      };

      console.log("mailData=======>", mailData);

      try {
        setMailSending(true);

        let requestUrl =
          url.API_BASE_URL + url.API_ADD_MESSAGE + `?token=${token}`;

        const response = await postData(requestUrl, mailData);

        console.log("Mail Response", response);

        setMailSending(false);

        if (response.status) {
          setMessageType("success");

          resetHandler();

          window.bootstrap.Offcanvas.getInstance(
            document.querySelector("#offcanvasNewMail")
          ).hide();

          reloadList();
        } else {
          setMessageType("error");
          setWarningMessage(response.message);
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setWarningMessage("Please fill up all required fields");
      setAlertMessage("Please fill up all required fields");
    }
    setShowAlert(true);
  };

  /* reset */
  const resetHandler = () => {
    resetFile();
    setMailDescription("");
    setSelectedTemplate(null);
    setReceivermails("");
    setMailSubject("");
    setUploadedFiles([]);
    setlinkTrackerBlock([]);
    setFileUploadIds([]);
    setValidation({
      emailWarning: false,
      subjectWarning: false,
    });
    setWarningMessage("");
  };

  useEffect(() => {
    getAllMailTemplates();
  }, []);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasNewMail"
      aria-labelledby="offcanvasNewMailLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasNewMailLabel">
          {t("Send Mail")}
        </h3>
        <button
          onClick={resetHandler}
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <div className="fields-container flex-fill">
          {/* <!-- ========== Start mail address Section ========== --> */}
          <div className="form-group mb-3">
            <label htmlFor="availabilityHours" className="text-nowrap me-lg-3">
              {t("Mail Address")}
            </label>
            {contactPersonEmail && contactPersonEmail != "" ? (
              <input
                type="text"
                className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                placeholder={t("Enter mail address")}
                value={contactPersonEmail}
                readOnly
              />
            ) : (
              <input
                type="text"
                className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                placeholder={t("Enter mail address")}
                value={receivermails}
                onChange={(e) => {
                  setReceivermails(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    emailWarning: false,
                  }));
                  setWarningMessage("");
                }}
              />
            )}
            {/* ---------- mail warning --------- */}
            {validation.emailWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a mail id!")}</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End mail address Section ========== --> */}

          {/* <!-- ========== Start mail template Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="" className="text-nowrap me-lg-3">
              {t("Mail Template")}
            </label>
            <Select
              className=""
              placeholder={t("Select Mail Template")}
              options={mailTemplateList}
              value={selectedTemplate}
              onChange={(val) => {
                templateSelectionHandler(val);
              }}
            />
          </div>
          {/* <!-- ========== End mail template Section ========== --> */}

          {/* <!-- ========== Start mail variable Section ========== --> */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <label htmlFor="" className="text-nowrap me-lg-3">
                  {t("Mail Variable")}
                </label>
                <Select
                  className=""
                  placeholder={t("Select Mail Variable")}
                  options={mailVariables}
                  onChange={(event) => {
                    clipboardCopy(event.value);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center p-lg-3 body-lg-bg rounded-10">
                  {t(
                    "Select the mail variable you want to add. Next just paste where you want it use it."
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- ========== End mail variable Section ========== --> */}

          {/* <!-- ========== Start subject Section ========== --> */}
          <div className="form-group mb-3">
            <label htmlFor="availabilityHours" className="text-nowrap me-lg-3">
              {t("Subject")}
            </label>
            <input
              type="text"
              placeholder={t("Enter mail subject")}
              className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
              value={mailSubject}
              onChange={(e) => {
                setMailSubject(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  subjectWarning: false,
                }));
                setWarningMessage("");
              }}
            />
            {/* ---------- subject warning --------- */}
            {validation.subjectWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a subject!")}</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End subject Section ========== --> */}

          {/* ------- editor section start ------- */}
          <div className="form-group mb-4">
            {/* <Editor
              apiKey={editorConfig}
              init={{
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | |link alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat  | help",
                tinycomments_mode: "embedded",
              }}
              value={mailDescription}
              onEditorChange={(newContent, editor) => {
                setMailDescription(newContent);
              }}
            /> */}
            <JoditEditor
              ref={editor}
              value={mailDescription}
              config={editorConfig}
              onChange={(newContent, event) => {
                setMailDescription(newContent);
              }}
            />
          </div>
          {/* ------- editor section end ------- */}

          {/* ========= file upload start ========== */}
          <div className="form-group mb-4">
            {isUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t("Please wait while uploading")}</p>{" "}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: "6px", width: "6px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label htmlFor="Attachments" className="text-nowrap me-lg-3">
                {t("Attachments")}
              </label>
            )}
            <input
              type="file"
              className="form-control"
              id="uploadedMailUploadFile"
              multiple
              onChange={uploadHandler}
            />
            {uploadedFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => downloadFileHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => closeFileHandler(index)}>
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= file upload end ===========*/}

          {/* <!-- ========== Start list of link tracker Section ========== --> */}
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <label htmlFor="" className="text-nowrap me-lg-3">
                  {t("Link Tracker")}{" "}
                  <span className="text-primary">
                    ** {t("1. use exact link and 2. use http or https")}
                  </span>
                </label>
              </div>
            </div>
          </div>
          {linkTrackerBlock &&
            linkTrackerBlock.map((trackerBlock, index) => {
              return (
                <div
                  className="form-group p-3 bg-gray-500 mb-3 action_link_block_bg"
                  key={index}
                >
                  <div className="row">
                    {/* ------ action link name start ------ */}
                    <div className="col-lg-6 mb-1">
                      <label>{t("Add url to track")}</label>
                      <input
                        type="text"
                        name="link"
                        className="form-control bg-white"
                        placeholder="https://lobees.com"
                        value={trackerBlock.link}
                        onChange={(e) => {
                          changeLinkTrackerValue(index, "link", e.target.value);
                        }}
                      />
                    </div>
                    {/* ------ action link name end ------ */}

                    {/* ------ action link external start ------ */}
                    <div className="col-lg-5 mb-1">
                      <label>{t("Label to display if link clicked")}</label>
                      <input
                        type="text"
                        name="label"
                        className="form-control bg-white"
                        placeholder={t("visited lobees")}
                        value={trackerBlock.label}
                        onChange={(e) => {
                          changeLinkTrackerValue(
                            index,
                            "label",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    {/* ------ action link external end ------ */}

                    <div className="col-lg-1 mb-1 pt-5">
                      <Link
                        to="#"
                        className="text-danger mt-2"
                        onClick={() => {
                          onDeleteLinkTrackerBlock(index);
                        }}
                      >
                        <span className="material-symbols-outlined">close</span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}

          {/* <!-- ========== End list of link tracker Section ========== --> */}

          {/* <!-- ========== Start add new link tracker button ========== --> */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-3">
                <Link
                  onClick={onAddLinkTrackerBlock}
                  to="#"
                  className="d-flex align-items-center gap-1 text-primary fw-medium"
                >
                  <span className="d-block material-symbols-outlined">add</span>
                  <span>{t("Add Link Tracker")}</span>
                </Link>
              </div>
            </div>
          </div>
          {/* <!-- ========== End add new link tracker button ========== --> */}
        </div>
        {/* send button */}
        <div className="action d-flex align-items-center gap-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={saveMailHandler}
            style={{ cursor: mailSending ? "not-allowed" : "pointer" }}
            disabled={mailSending ? true : false}
          >
            {t("Send Mail")}
            {mailSending && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ConversationNewMailPopup;
