import React from "react";
import { assetImages } from "constants";
import { Link } from "react-router-dom";

const UpdateGanttTaskModal = ({ onUpdateGanttTask }) => {
  return (
    <div
      className="modal fade"
      id="projectTaskGanttModal"
      tabIndex="-1"
      aria-labelledby="projectTaskGanttModal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="warning"
              className="img-fluid"
            />
            <h2>Change subsequent tasks or unlink ?</h2>
            <div className="d-flex gap-3 justify-content-center">
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  onUpdateGanttTask(true, false);
                }}
              >
                Change
              </Link>
              <Link
                to="#"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  onUpdateGanttTask(false, true);
                }}
              >
                Unlink
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateGanttTaskModal;
