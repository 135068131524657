/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import CrmContactDetailsTimeLineBody from "components/CRMComponents/CRMContactDetailsComponents/CRMContactDetailsTimeLineComponents/TimeLineDetailsBody/CrmContactDetailsTimeLineBody";

const CrmContactDetailsTimeLine = () => {
  useEffect(() => {
    document.title = "CRM Contact | Timeline";
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmContactDetailsTimeLineBody />
    </main>
  );
};

export default CrmContactDetailsTimeLine;
