/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CourseFaqFilterPopup = ({
  setfilterStartDate,
  setfilterEndDate,
  setfilterRank,
  setfilterUnanswered,
}) => {
  const { t } = useTranslation(); //for translation

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rank, setrank] = useState("");
  const [unanswered, setunanswered] = useState(false);

  const [errorMessage, seterrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");
      if (startDate !== "" && endDate !== "") {
        setfilterStartDate(startDate);
        setfilterEndDate(endDate);
      } else {
        setfilterStartDate("");
        setfilterEndDate("");
      }

      if (rank !== "") {
        setfilterRank(rank);
      } else {
        setfilterRank("");
      }

      if (unanswered) {
        setfilterUnanswered(true);
      } else {
        setfilterUnanswered(false);
      }

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasFilter");

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();

      resetHandler();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //reset filter
  const resetHandler = () => {
    setStartDate("");
    setEndDate("");
    setrank("");
    setunanswered(false);
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="field-container flex-fill">
            {/* <!-- ========== Start date range Section ========== --> */}
            <div className="form-group mb-4">
              <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
                <h3>{t("Dates")}</h3>{" "}
              </label>
              <div className="d-flex gap-2">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="Start date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="End date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
              {validation.dateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>Please enter end date!</span>
                  </p>
                </div>
              )}
            </div>
            {/* <!-- ========== End date range Section ========== --> */}

            <div className="form-group mb-3 mb-sm-4">
              <label
                htmlFor="questionNumber"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Rank")}
              </label>
              <input
                type="number"
                id="questionNumber"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Rank")}
                value={rank}
                onChange={(e) => {
                  setrank(e.target.value);
                }}
              />
            </div>

            {/* <!-- ========== Start is default checkbox Section ========== --> */}
            <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
              <input
                className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                type="checkbox"
                id="agree"
                checked={unanswered}
                onChange={(e) => {
                  setunanswered(e.target.checked);
                }}
              />
              <label
                className="form-check-label fs-sm fw-semibold"
                htmlFor="agree"
              >
                {t("Unanswered")}
              </label>
            </div>
            {/* <!-- ========== End is default checkbox Section ========== --> */}
          </div>
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CourseFaqFilterPopup;
