/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { assetImages } from "constants";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

import ProcessModuleOptions from "data/Prod/ProcessModuleOptions.json";
import { useTranslation } from "react-i18next";

const AddProcessFromTemplateModal = ({
  afterProcessModalClose,
  moduleSlug = "",
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");

  const [followersInput, setFollowersInput] = useState("");
  const [folowersOptions, setFollowersOptions] = useState([]);

  const [processList, setprocessList] = useState([]);
  const [processIndex, setProcessIndex] = useState(null);
  const [processData, setProcessData] = useState(null);

  const [followersValue, setfollowersValue] = useState(null);
  const [selectedFollowers, setselectedFollowers] = useState([]);
  const [moduleValue, setmoduleValue] = useState(null);
  const [selectedModule, setSelectedModule] = useState("");

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  //function for get followers input
  const followersInputHandler = (val) => {
    setFollowersInput(val);
  };

  //function for get followers list
  const getFollowersList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${followersInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setFollowersOptions(response.data);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //get all process templates
  const getAllProcess = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PROCESS +
        `?token=${token}&istemplate=${true}`;

      const response = await getData(requestURL);

      console.log("response process----->", response);

      if (response.status) {
        //update list for react select
        const updatedProcessList = response.data.map((item, index) => {
          return {
            ...item,
            label: item.title,
            value: item._id,
          };
        });

        //display last process by default
        processSelectionHandler(
          updatedProcessList[updatedProcessList.length - 1],
          updatedProcessList.length - 1
        );

        setprocessList(updatedProcessList);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select process
  const processSelectionHandler = (processItem, index) => {
    // console.log("processItem --->", processItem);
    seterrorMessage("");
    setProcessIndex(index);
    setProcessData(processItem);
  };

  //select followers
  const followersSelectionHandler = (val) => {
    setfollowersValue(val);
    setselectedFollowers(val.map((item) => item.value));
  };

  //select module
  const moduleSelectionHandler = (val) => {
    if (val) {
      setmoduleValue(val);
      setSelectedModule(val.value);
    } else {
      setmoduleValue(null);
      setSelectedModule("");
    }
  };

  //save process
  const saveProcessHandler = async () => {
    seterrorMessage("");

    try {
      setIsSaving(true);

      //send tagids as array and component order id as null
      const processTagIds = processData.processtagids.map((item) => ({
        tagid: item,
        componentorderid: null,
      }));

      const processTemplateData = {
        title: processData.title,
        slug: processData.slug.toUpperCase(),
        moduleslug: moduleSlug === "" ? selectedModule : moduleSlug,
        istemplate: false,
        tags: processTagIds,
        followers: selectedFollowers,
      };

      console.log("processTemplateData----->", processTemplateData);

      let requestURL = url.API_BASE_URL;

      let response = {};

      requestURL += url.API_ADD_NEW_PROCESS + `?token=${token}`;

      response = await postData(requestURL, processTemplateData);

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        resetModal();
        // close the modal of addLabel
        let templateModal = document.querySelector("#kanbanExample");
        let modal = bootstrap.Modal.getInstance(templateModal);
        modal.hide();
        afterProcessModalClose(response.data);
      } else {
        seterrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetModal = () => {
    setfollowersValue(null);
    setselectedFollowers([]);
    setmoduleValue(null);
    setSelectedModule("");
    getAllProcess();
  };

  useEffect(() => {
    getAllProcess();
  }, []);

  useEffect(() => {
    if (followersInput.length > 0) {
      getFollowersList();
    }
  }, [followersInput]);

  const followersOptionsToShow =
    folowersOptions.length > 0 ? folowersOptions : [];

  return (
    <div
      className="modal fade placeholder-glow"
      id="kanbanExample"
      tabIndex="-1"
      aria-labelledby="kanbanExampleLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4">
            <h2 className="fw-bold mb-0" id="kanbanExampleLabel">
              {t("Kanban Examples")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetModal}
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="row gx-5">
              <div
                className="col-lg-3 d-none d-lg-block ps-0 overflow-y-auto"
                style={{ height: "31.25rem" }}
              >
                <ul className="menu fw-semibold">
                  {processList.map((processItem, index) => {
                    return (
                      <li
                        className={processIndex === index ? "active" : ""}
                        key={index}
                      >
                        <Link
                          to="#"
                          className="d-block"
                          onClick={() => {
                            processSelectionHandler(processItem, index);
                          }}
                        >
                          {processItem.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-lg-9">
                <div className="form-group d-block d-lg-none mb-4">
                  <Select
                    options={processList}
                    value={processData}
                    onChange={(val) => {
                      processSelectionHandler(val);
                    }}
                  />
                </div>
                {/* <p></p> */}

                {processData ? (
                  <ul className="example row justify-content-center fw-semibold mt-4">
                    {/* <li className="col-4 col-sm-auto mb-3 mb-xl-0">
                      <p className="mb-3">Backlog</p>
                      <div
                      className="p-2 border"
                      style={{ minWidth: "8.125rem" }}
                    >
                      <span className="d-block placeholder col-12 mb-2"></span>
                      <span className="d-block placeholder col-8"></span>
                    </div>
                      <img
                        src={assetImages.deliveredImg}
                        alt="Specification"
                        className="img-fluid"
                      />
                    </li> */}

                    {processData.processlabels.map((item, index) => {
                      return (
                        <li
                          className="col-4 col-sm-auto mb-3 mb-xl-0"
                          key={index}
                        >
                          <p className="mb-3">{item}</p>
                          <img
                            src={assetImages.deliveredImg}
                            alt="Specification"
                            className="img-fluid"
                          />
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </div>
            </div>

            {/* <!-- ========== Start followers Section ========== --> */}

            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Followers")}
              </label>
              <Select
                isMulti
                placeholder={t("Type user name or email")}
                options={followersOptionsToShow}
                value={followersValue}
                onChange={(val) => followersSelectionHandler(val)}
                onInputChange={(val) => {
                  followersInputHandler(val);
                }}
              />
            </div>

            {/* <!-- ========== End followers Section ========== --> */}

            {moduleSlug === "" ? (
              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Module")}
                </label>
                <Select
                  isClearable
                  placeholder={t("Select Module")}
                  options={ProcessModuleOptions}
                  value={moduleValue}
                  onChange={(val) => moduleSelectionHandler(val)}
                />
              </div>
            ) : null}
          </div>
          <div className="modal-footer p-4 d-flex">
            {errorMessage === "" ? null : (
              <div className="validation-error d-flex align-items-center gap-1 fs-md text-primary mt-2">
                <span className="d-block material-symbols-outlined icon-md">
                  info
                </span>
                <span className="d-block">{errorMessage}</span>
              </div>
            )}
            <button
              type="button"
              className="btn btn-primary m-0"
              onClick={saveProcessHandler}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              {t("Use This for My Project")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProcessFromTemplateModal;
