/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { assetImages } from "constants";
import fileDownloadHandler from "helper/DownloadFile";
import { useTranslation } from "react-i18next";

const ConversationMailPopup = ({ selectedMailId, resetMailId }) => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [senderEmail, setsenderEmail] = useState("");
  const [recieverEmail, setrecieverEmail] = useState("");
  const [mailSubject, setmailSubject] = useState("");
  const [mailDate, setmailDate] = useState("");
  const [mailTime, setmailTime] = useState("");
  const [mailDescription, setmailDescription] = useState("");
  const [mailUploads, setmailUploads] = useState([]);

  const getMailDetails = async () => {
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_MAIL_DETAILS}/${selectedMailId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setmailSubject(response.data.subject);
        setsenderEmail(response.data.sendermail);
        setrecieverEmail(response.data.receivermail);
        setmailDate(response.data.maildate);
        setmailTime(response.data.mailtime);
        setmailDescription(response.data.description);
        setmailUploads(
          response.data.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedMailId) {
      getMailDetails();
    }
  }, [selectedMailId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasEmail"
      aria-labelledby="offcanvasEmailLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasEmailLabel">
          {mailSubject}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetMailId}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="email-head mb-4">
            <ul>
              <li className="d-flex gap-3">
                <span
                  className="d-block text-gray"
                  style={{ minWidth: "60px" }}
                >
                  {t("Form")}
                </span>
                <Link
                  to="mailto:monalisa.smith@gmail.com"
                  className=" d-block text-primary"
                >
                  {senderEmail}
                </Link>
              </li>
              <li className="d-flex gap-3">
                <span
                  className="d-block text-gray"
                  style={{ minWidth: "60px" }}
                >
                  {t("To")}
                </span>
                <Link
                  to="mailto:lisa.hello@gmail.com"
                  className=" d-block text-primary"
                >
                  {recieverEmail}
                </Link>
              </li>
              <li className="d-flex gap-3">
                <span
                  className="d-block text-gray"
                  style={{ minWidth: "60px" }}
                >
                  {t("Time")}
                </span>
                <p>
                  <span className="date">{mailDate}</span>{" "}
                  <span className="text-gray">at</span>{" "}
                  <span className="time">{mailTime}</span>
                </p>
              </li>
            </ul>
          </div>
          <div className="email-body flex-fill mb-4">
            <p dangerouslySetInnerHTML={{ __html: mailDescription }}></p>
            <ul className="documents d-flex flex-wrap gap-3 mt-5">
              {mailUploads.map((doc, index) => {
                return (
                  <li
                    className="document d-flex align-items-center px-3 py-2 body-bg rounded-10"
                    key={index}
                  >
                    <img
                      src={
                        doc.path.split(".").pop() == "pdf"
                          ? assetImages.PdfImg
                          : assetImages.DocImg
                      }
                      alt="My...ent.pdf"
                      className="img-fluid me-2"
                    />
                    <span className="d-block fw-medium me-4">{doc.name}</span>
                    <Link
                      to="#"
                      onClick={() => {
                        fileDownloadHandler(doc);
                      }}
                    >
                      <span className="d-block material-symbols-outlined icon-lg">
                        download
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* <div className="email-footer d-flex align-items-center gap-3">
            <button type="submit" className="btn btn-primary">
              Reply
            </button>
            <Link to="#">
              <span className="d-block material-symbols-outlined">delete</span>
            </Link>
          </div> */}
        </form>
      </div>
    </div>
  );
};
export default ConversationMailPopup;
