/* eslint-disable */
import React, { useEffect, useState, useMemo, useRef } from "react";

import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";
import { assetImages } from "constants";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

const ViewTaskPopup = ({
  selectedTaskId,
  setSelectedTaskId,
  afterPopupclose,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const todayValue = new Date().toISOString().split("T")[0];

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [lessonName, setlessonName] = useState("");
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);

  const [logList, setLogList] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  //get task details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${selectedTaskId}?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log("task details", response);

      if (response.status) {
        settitle(response.data.title);
        setdetails(response.data.description);
        setlessonName(response.data.lessonname);

        //assign uploaded files
        setUploadedDocFiles(
          response?.data?.uploadedfiles.map(
            ({ name, path, _id, ...other }) => ({
              name,
              path: url.SERVER_URL + path,
              _id,
            })
          )
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get task logs
  const getTaskLogs = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&taskid=${selectedTaskId}`;

      // console.log("log list url----->", requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setLogList(response.data);
      }
      // else {
      //   setMessageType("error");
      //   setAlertMessage(response.message);
      //   setShowAlert(true);
      // }
    } catch (error) {
      // setMessageType("error");
      // setAlertMessage(error.message);
      // setShowAlert(true);
    }
  };

  //function for task approval
  const taskApproveHandler = async (status = "0") => {
    try {
      setIsSaving(true);

      let taskData = {
        approvalstatus: status,
        followers: [userInfo._id],
      };

      // console.log(taskData);

      let response = {};

      if (selectedTaskId) {
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_PROJECT_TASK +
          `/${selectedTaskId}?token=${token}`;

        response = await putData(requestUrl, taskData);
      }

      setIsSaving(false);

      // console.log(response);

      if (response.status) {
        setMessageType("success");
        resetHandler();
        let offCanvasPopup = document.querySelector("#viewTask");
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();
        afterPopupclose();
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  // Reset all the form fields and state variables to their initial values
  const resetHandler = () => {
    settitle("");
    setdetails("");
    setlessonName("");
    setUploadedDocFiles([]);
    setSelectedTaskId(null);
    setLogList([]);
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskDetails();
      getTaskLogs();
    }
  }, [selectedTaskId]);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="viewTask"
      aria-labelledby="viewTaskLabel"
    >
      <div className="offcanvas-header gradient-light p-4">
        <div>
          <h3 className="offcanvas-title mb-2" id="viewTaskLabel">
            {title}
          </h3>
          <ul className="task-category d-flex flex-wrap">
            <li className="position-relative">{lessonName}</li>
            {/* <li className="position-relative">IT Gients</li>
            <li className="position-relative">Technology</li> */}
          </ul>
        </div>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-0">
        <div className="task-details flex-fill p-4">
          <p className="fs-md fw-semibold mb-1">Assignment Details</p>
          <p
            dangerouslySetInnerHTML={{
              __html: details,
            }}
          ></p>

          <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
            {uploadedDocFiles.map((file, index) => {
              return (
                <li
                  className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                  key={index}
                >
                  <Link to="#" onClick={() => downloadFileHandler(file)}>
                    <span className="d-block material-symbols-outlined">
                      download
                    </span>
                  </Link>
                  <p className="fw-medium">{file.name}</p>
                  <Link to="#" onClick={() => closeDocFileHandler(index)}>
                    <span className="d-block material-symbols-outlined">
                      close
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="logs flex-fill overflow-auto p-4">
          {logList.map((logData, index) => {
            return (
              <div key={index}>
                <div className="log-date pb-3">
                  <p className="d-flex gap-2 align-items-center justify-content-center fs-md fw-semibold">
                    <span className="d-block">
                      {logData.logmonthstring} {logData.logyear}
                    </span>
                    <span className="d-flex align-items-center gap-1 text-gray ">
                      <span className="d-block material-symbols-outlined icon-lg">
                        schedule
                      </span>
                      <span>{logData.logmonthhour}Hr</span>
                    </span>
                  </p>
                </div>
                {logData.logs.map((log, index2) => {
                  return (
                    <div className="log mt-3" key={index2}>
                      <div className="d-flex gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <img
                            src={
                              log.userimage == ""
                                ? assetImages.defaultUser
                                : url.SERVER_URL + log.userimage
                            }
                            alt="Richard"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="d-flex align-items-center gap-2 mb-2">
                            <span className="d-block fs-md fw-semibold">
                              {log.username}
                            </span>
                            <span className="d-block fs-sm text-gray">
                              {getDateFormatInSpanish(log.startdate)}
                            </span>
                            <span className="d-block fs-xs lh-1 text-primary gradient-light px-2 py-1 rounded-90">
                              {log.hours}hr
                            </span>
                          </p>
                          <p
                            className="message body-bg px-3 py-2 fs-sm rounded-10"
                            dangerouslySetInnerHTML={{
                              __html: log.description,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <form onSubmit={(e) => e.preventDefault()} className="p-4 border-top">
          {/* <div className="form-group mb-4">
            <label htmlFor="comment" className="d-block fs-sm fw-semibold mb-2">
              Comments if Any
            </label>
            <textarea
              name="comment"
              id="comment"
              cols="30"
              rows="5"
              className="form-control fs-sm fw-semibold shadow-none"
              placeholder="Enter message"
            ></textarea>
          </div>
           */}
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                taskApproveHandler("1");
              }}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              Approve
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                taskApproveHandler("2");
              }}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
              disabled={isSaving ? true : false}
            >
              Decline
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewTaskPopup;
