/* eslint-disable */
import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";

import {
  getUserBrowser,
  getPublicIP,
  getTimeStamp,
} from "helper/AuthHelper/AuthHelper";

import { ecosystemSlug } from "Config/Config";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";

const Login = () => {
  const { t } = useTranslation(); // translation marker
  const startingUrl = localStorage.getItem("startingURL");

  const history = useHistory();

  const { setcreateAccountMessage, setuserEmailforOtp, setVerifyModuleName } =
    useContext(GlobalProvider);

  /*----- UseState to send username and password for Login ----*/
  const [otpEmail, setOtpEmail] = useState("");
  const [otpEmailWarning, setOtpEmailWarning] = useState(false);
  const [notValidOtpEmail, setNotValidOtpEmail] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [agreeWarning, setAgreeWarning] = useState(false);
  const [otpSending, setotpSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  //otp function
  const otpHandler = async () => {
    if (otpEmail == "") {
      setOtpEmailWarning(true);
    } else {
      setOtpEmailWarning(false);

      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (otpEmail.match(mailformat)) {
        setNotValidOtpEmail(false);
        try {
          setotpSending(true);
          const userPublicIp = await getPublicIP();

          let userData = {
            userip: userPublicIp,
            email: otpEmail,
          };
          let requestURL =
            url.API_BASE_URL +
            url.API_GET_OTP +
            `?ecosystemslug=${ecosystemSlug}`;

          const response = await postData(requestURL, userData);

          console.log(response);

          setotpSending(false);

          if (response.status) {
            seterrorMessage("");
            setcreateAccountMessage("");
            setuserEmailforOtp(otpEmail);
            setVerifyModuleName("OTP");
            history.push("/admin/verifyemail");
          } else {
            setuserEmailforOtp("");
            setVerifyModuleName("");
            setcreateAccountMessage(response.message);
            seterrorMessage(response.message);
          }
          setUserEmail("");
        } catch (error) {
          console.log(error.message);
        }
      } else {
        setNotValidOtpEmail(true);
      }
    }
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    switch (true) {
      case userEmail == "" || userPassword == "":
        userEmail == "" ? setEmailWarning(true) : setEmailWarning(false);
        userPassword == ""
          ? setPasswordWarning(true)
          : setPasswordWarning(false);
        break;
      default:
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (userEmail.match(mailformat)) {
          setNotValidEMail(false);
          setIsLoading(true);

          const detectedBrowser = getUserBrowser();
          const userPublicIp = await getPublicIP();

          const { loginDate, loginTime, loginTimeStamp } = getTimeStamp();

          try {
            let userData = {
              email: userEmail,
              password: userPassword,
              logindate: loginDate,
              logintime: loginTime,
              logintimestamp: loginTimeStamp,
              browser: detectedBrowser,
              userip: userPublicIp,
            };
            let requestURL =
              url.API_BASE_URL +
              url.API_LOGIN +
              `?ecosystemslug=${ecosystemSlug}`;

            console.log("request url -->", requestURL);

            const response = await postData(requestURL, userData);

            console.log("response -->", response);

            setIsLoading(false);

            if (response.status) {
              setLoginFailed(false);
              seterrorMessage("");
              // assigning the loggedin user's id,token,all data to the browser's local storage.
              localStorage.setItem("token", response.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              localStorage.setItem("userID", response.data._id);
              localStorage.setItem("ecosystem", response.ecosystemid);
              localStorage.setItem("ecosystemslug", response.ecosystemslug);
              localStorage.setItem("ecosystemlogo", response.ecosystemlogo);
              localStorage.setItem(
                "moduleaccess",
                response.data.moduleaccessdata
              );

              resetLoginData();
              window.location.reload();
            } else {
              setLoginFailed(true);
              seterrorMessage(response.message);
            }
          } catch (error) {
            console.error(error.message);
          }
        } else {
          setNotValidEMail(true);
        }
    }
  };

  // function for reset the useState containing the email and password text fields
  const resetLoginData = () => {
    setOtpEmail("");
    setOtpEmailWarning(false);
    setNotValidOtpEmail(false);
    setUserEmail("");
    setUserPassword("");
    setEmailWarning(false);
    setNotValidEMail(false);
    setPasswordWarning(false);
    setLoginFailed(false);
    setcreateAccountMessage("");
    setuserEmailforOtp("");
  };

  return (
    <main id="app">
      <div id="content_wrapper">
        <div className="authentication-wrapper min-h-screen py-5 d-flex align-items-center justify-content-center">
          <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto">
            <h1 className="h2 text-center">{t("Login to your account")}</h1>
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ------ tab menu start ----- */}
              <div className="login_tabs">
                <ul className="nav nav-tabs list_stye_none">
                  <li className="nav-item w-50 m-0 text-center">
                    <Link
                      className="nav-link active show"
                      data-bs-toggle="tab"
                      to="#password"
                      onClick={resetLoginData}
                    >
                      {t("Password")}
                    </Link>
                  </li>
                  <li className="nav-item w-50 m-0 text-center">
                    <Link
                      className="nav-link "
                      data-bs-toggle="tab"
                      to="#otp"
                      onClick={resetLoginData}
                    >
                      {t("OTP")}
                    </Link>
                  </li>
                </ul>
              </div>
              {/* ------ tab menu end ----- */}

              {/* ------ tab section start ----- */}
              <div className="tab-content">
                {/* ----- password tab start ----- */}
                <div className="tab-pane fade active show" id="password">
                  {/* ----- email and password section start ----- */}
                  <div className="form-group mb-4">
                    {/* ----- email section start ----- */}

                    <label className="d-block fs-sm fw-semibold mb-2">
                      {t("Email")}
                    </label>
                    <input
                      type="email"
                      className="form-control fs-sm shadow-none mb-2"
                      placeholder={t("Enter email")}
                      value={userEmail}
                      onChange={(e) => {
                        setEmailWarning(false);
                        setNotValidEMail(false);
                        setLoginFailed(false);
                        setUserEmail(e.target.value);
                      }}
                    />
                    {/* ------ email warning start ------ */}
                    {emailWarning && (
                      <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          info
                        </span>
                        <span className="d-block">
                          {t("Please enter email")}
                        </span>
                      </div>
                    )}
                    {/* ----- valid email warning ------ */}
                    {notValidEMail && (
                      <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          info
                        </span>
                        <span className="d-block">
                          {t("Please enter valid email")}
                        </span>
                      </div>
                    )}
                    {/* ------ email warning end ------ */}

                    {/* ----- email section end ----- */}

                    {/* ----- password section start ----- */}
                    <div className="form-group mb-4">
                      <label className="d-block fs-sm fw-semibold mb-2">
                        {t("Password")}
                      </label>
                      <div className="field-container field-with-icon icon-right position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control fs-sm shadow-none"
                          placeholder={t("Enter Password")}
                          value={userPassword}
                          onChange={(e) => {
                            setPasswordWarning(false);
                            setLoginFailed(false);
                            setUserPassword(e.target.value);
                          }}
                        />
                        <Link
                          to="#"
                          className="password-visibility field-icon text-gray position-absolute top-50 end-0 translate-middle-y me-3"
                          onClick={toggleText}
                        >
                          <span className="d-block material-symbols-outlined icon-sm">
                            {" "}
                            visibility
                          </span>
                        </Link>
                      </div>
                      {passwordWarning && (
                        <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                          <span className="d-block material-symbols-outlined icon-md">
                            info
                          </span>
                          <span className="d-block">
                            {t("Please enter password")}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* ----- password section end ----- */}
                  </div>
                  {/* ----- email and password section end ----- */}

                  {errorMessage === "" ? null : (
                    <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                      <span className="d-block material-symbols-outlined icon-md">
                        info
                      </span>
                      <span className="d-block">{errorMessage}</span>
                    </div>
                  )}

                  {/* ----- agree and forgot passowrd link section start ----- */}
                  <div className="form-group d-flex align-items-center justify-content-end gap-3 mb-4">
                    <Link
                      to="/admin/forgotpassword"
                      className="fs-sm fw-semibold text-primary"
                    >
                      {t("Forgot Password")}
                    </Link>
                  </div>
                  {/* ----- agree and forgot passowrd link section end ----- */}

                  {/* ----- create and login section start ----- */}
                  <div className="action d-flex gap-3 justify-content-between">
                    {/* ----- create link start ----- */}

                    {/* <Link to="/admin/register" className="text-primary">
                      {t("Create One")}
                    </Link> */}

                    <div className="create_login_outer d-flex align-items-center">
                      <div className="trms_check me-2">
                        <label className="radio-check">
                          <p>{t("Do not have an account")}?</p>
                        </label>
                      </div>
                      <div className="apply_btn">
                        <Link to="/admin/register" className="text-primary">
                          {t("Create One")}
                        </Link>
                      </div>
                    </div>

                    {/* ----- create link end ----- */}

                    {/* ----- login button start ----- */}

                    <button
                      className="btn btn-primary"
                      onClick={loginHandler}
                      disabled={isLoading ? true : false}
                      style={{
                        cursor: isLoading ? "not-allowed" : "pointer",
                      }}
                    >
                      {t("Login")}
                      {isLoading && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>

                    {/* ----- login button end ----- */}
                  </div>
                  {/* ----- create and login section end ----- */}
                </div>
                {/* ----- password tab end ----- */}

                {/* ----- otp tab start ----- */}
                <div className="tab-pane fade " id="otp">
                  <div className="form-group mb-4">
                    <label className="d-block fs-sm fw-semibold mb-2">
                      {t("Email")}
                    </label>
                    <input
                      type="email"
                      value={otpEmail}
                      onChange={(e) => {
                        setOtpEmailWarning(false);
                        setNotValidOtpEmail(false);
                        setLoginFailed(false);
                        setOtpEmail(e.target.value);
                      }}
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter email")}
                    />
                    {/* ------ email warning start ------ */}
                    {otpEmailWarning && (
                      <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          info
                        </span>
                        <span className="d-block">
                          {t("Please enter email")}
                        </span>
                      </div>
                    )}
                    {/* ----- valid email warning ------ */}
                    {notValidOtpEmail && (
                      <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          info
                        </span>
                        <span className="d-block">
                          {t("Please enter valid email")}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="action d-flex gap-3 justify-content-between">
                    {/* <Link to="/admin/register" className="text-primary">
                      Create One
                    </Link> */}

                    <div className="create_login_outer d-flex align-items-center">
                      <div className="trms_check me-2">
                        <label className="radio-check">
                          <p>{t("Do not have an account")}?</p>
                        </label>
                      </div>
                      <div className="apply_btn">
                        <Link to="/admin/register" className="text-primary">
                          {t("Create One")}
                        </Link>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={otpHandler}
                      disabled={otpSending ? true : false}
                      style={{
                        cursor: otpSending ? "not-allowed" : "pointer",
                      }}
                    >
                      {t("Get OTP")}
                      {otpSending && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                {/* ----- otp tab end ----- */}
              </div>
              {/* ------ tab section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
