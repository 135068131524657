/* eslint-disable */

import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import CrmSalesOrderHeader from '../Header/CrmSalesOrderHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import salesOrderData from '../../../../data/CRM/SalesOrderData.json';

const CrmSalesOrderBody = () => {
  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: 'CRM', link: '/admin/crm/salesorder/list' },
    { title: 'Sales Order' },
  ];

  // common header object
  const commonHeaderObject = [
    { title: 'Leads', link: '/admin/crm/lead/list' },
    { title: 'Contact', link: '/admin/crm/contact/list' },
    { title: 'Conversation', link: '/admin/crm/conversation/recieved' },
    { title: 'List', link: '/admin/crm/list' },
    { title: 'Mail Templates', link: '/admin/crm/mailtemplate/list' },
    { title: 'Label', link: '/admin/crm/label/list' },
    { title: 'Process', link: '/admin/crm/process/list' },
    { title: 'Sales Order', link: '/admin/crm/salesorder/list' },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        Cell: ({ row }) => (
          <div className="id border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <span className="fw-semibold">{row.original.id}</span>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'client',
        header: 'Client',
        Cell: ({ row }) => (
          <div className="client border-bottom-0">
            <p>{row.original.client}</p>
          </div>
        ),
      },
      {
        accessorKey: 'invoiceDate',
        header: 'Invoice Date',
        Cell: ({ row }) => (
          <div className="invoice-date border-bottom-0">
            <p>{row.original.invoiceDate}</p>
          </div>
        ),
      },
      {
        accessorKey: 'TotalAmt',
        header: 'Total Amt.',
        Cell: ({ row }) => (
          <div className="total-amount border-bottom-0">
            <p>{row.original.TotalAmt}</p>
          </div>
        ),
      },
      {
        accessorKey: 'discount',
        header: 'Discount',
        Cell: ({ row }) => (
          <div className="discount border-bottom-0">
            <p>{row.original.discount}</p>
          </div>
        ),
      },
      {
        accessorKey: 'tax',
        header: 'Taxes',
        Cell: ({ row }) => (
          <div className="taxes border-bottom-0">
            <p>{row.original.discount}</p>
          </div>
        ),
      },
      {
        accessorKey: 'payable',
        header: 'Payble Amt.',
        Cell: ({ row }) => (
          <div className="payble-amount border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-sm">
                euro
              </span>
              <span className="d-block">{row.original.payable}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <p
              className={`fw-semibold ${
                row.original.status === 'paid'
                  ? 'text-success'
                  : row.original.status === 'Overdue' && 'text-danger'
              }`}
            >
              {row.original.status}
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'created',
        header: 'Created Date',
        Cell: ({ row }) => (
          <div className="created-date border-bottom-0">
            <p>{row.original.created}</p>
          </div>
        ),
      },
      {
        accessorKey: '#',
        header: 'Action',
        Cell: ({ row }) => (
          <div className="action border-bottom-0">
            <Link
              to="#offcanvasSalesOrderDetails"
              className="d-flex align-items-center gap-1"
              data-bs-toggle="offcanvas"
              role="button"
              aria-controls="offcanvasSalesOrderDetails"
            >
              <span className="d-block">Details</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption="Sales Order"
        />

        <div className="container-fluid px-5">
          <BreadCrumb breadCrumbText={breadcrumbText} />
          <CrmSalesOrderHeader />

          {/* ------------ table section --------------- */}
          {/* <div className="table-wrapper">
            <div className="table-responsive">
              <table className="table data-table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th className="id bg-transparent fw-semibold border-0">
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllContacts"
                            name="contact"
                            value="option1"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Id</span>
                      </div>
                    </th>
                    <th className="client bg-transparent fw-semibold border-0">
                      Client
                    </th>
                    <th className="invoice-date bg-transparent fw-semibold border-0">
                      Invoice Date
                    </th>
                    <th className="total-amount bg-transparent fw-semibold border-0">
                      Total Amt.
                    </th>
                    <th className="discount bg-transparent fw-semibold border-0">
                      Discount
                    </th>
                    <th className="taxes bg-transparent fw-semibold border-0">
                      Taxes
                    </th>
                    <th className="payble-amount bg-transparent fw-semibold border-0">
                      Payble Amt.
                    </th>
                    <th className="status bg-transparent fw-semibold border-0">
                      Status
                    </th>
                    <th className="created-date bg-transparent fw-semibold border-0">
                      Created Date
                    </th>
                    <th className="action bg-transparent fw-semibold border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold">Pending</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-success">Paid</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold">Pending</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-success">pain</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-danger">Overdue</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold">Pending</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-success">Pain</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-danger">Overdue</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-success">Pain</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-danger">Overdue</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold">Pending</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="id border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="order"
                            id="order1"
                            value="order1"
                          />
                        </div>
                        <span className="fw-semibold">1005</span>
                      </div>
                    </td>
                    <td className="client border-bottom-0">
                      <p>Xavier Alonso</p>
                    </td>
                    <td className="invoice-date border-bottom-0">
                      <p>20/06/2023</p>
                    </td>
                    <td className="total-amount border-bottom-0">
                      <p>200</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p>10</p>
                    </td>
                    <td className="taxes border-bottom-0">
                      <p>40%</p>
                    </td>
                    <td className="payble-amount border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          euro
                        </span>
                        <span className="d-block">100</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="fw-semibold text-danger">Overdue</p>
                    </td>
                    <td className="created-date border-bottom-0">
                      <p>26/06/2023</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#offcanvasSalesOrderDetails"
                        className="d-flex align-items-center gap-1"
                        data-bs-toggle="offcanvas"
                        role="button"
                        aria-controls="offcanvasSalesOrderDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={salesOrderData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CrmSalesOrderBody;
