import React from "react";
import Header from "components/Common/Header/Header";
import AppUserPrivacyBody from "components/AppUserComponents/AppUserDetails/AppUserPrivacy/AppUserPrivacyBody/AppUserPrivacyBody";

const AppUserPrivacy = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="appuser" />

      {/* ------- body section ------------- */}
      <AppUserPrivacyBody />
    </main>
  );
};

export default AppUserPrivacy;
