/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const AddFaqPopup = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="addFaq"
      aria-labelledby="addFaqLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="addFaqLabel">
          {t('Add Faq')}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form action="" className="d-flex flex-column h-100">
          <div className="field-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t('Question')}
              </label>
              <input
                type="text"
                id="question"
                className="form-control fs-sm shadow-none"
                placeholder={t('Enter Question')}
              />
            </div>
            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t('Answer')}
              </label>
              <textarea
                name="answer"
                id="answer"
                cols="30"
                rows="5"
                className="form-control fs-sm shadow-none"
                placeholder={t('Enter Details')}
              ></textarea>
            </div>
          </div>
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t('Close')}
            </Link>
            <button type="submit" className="btn btn-primary">
              {t('Make Task')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFaqPopup;
