/* eslint-disable */
import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import { assetImages } from 'constants';

import CrmContactDetailsCommonHeader from 'components/CRMComponents/CRMContactDetailsComponents/common/CrmContactDetailsCommonHeader';
import CrmContactMailHeader from '../Header/CrmContactMailHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import ConversationNewMailPopup from 'components/Common/Popup/ConversationNewMailPopup';
import ConversationMailPopup from 'components/Common/Popup/ConversationMailPopup';
import ConversationFilterPopup from 'components/CRMComponents/CRMContactDetailsComponents/common/Popup/ConversationFilterPopup';

import { useTranslation } from 'react-i18next';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const RecievedMailList = () => {
  const token = localStorage.getItem('token');
  const { t, i18n } = useTranslation(); // for translations
  const params = useParams();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [tagList, setTagList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [contactName, setContactName] = useState('');
  const [contactImage, setContactImage] = useState('');
  const [contactPersonId, setContactPersonId] = useState(null);
  const [contactPersonEmail, setContactPersonEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mailList, setMailList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMailIds, setSelectedMailIds] = useState([]);

  const [selectedMailId, setSelectedMailId] = useState(null);

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterSubject, setfilterSubject] = useState('');
  const [filterTemplates, setfilterTemplates] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [filterHasAttachment, setFilterHasAttachment] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get contact details
  const getDetailsContact = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CONTACT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status && response.data) {
        setContactName(response.data.contactname);
        setContactImage(response.data.contactimage);
        setContactPersonId(response.data.contactpersonid);
        setContactPersonEmail(response.data.contactpersonemail);
      } else {
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  const getAllMailTemplates = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_MAIL_TEMPLATES + `?token=${token}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setTemplateList(
          response.data.map(template => ({
            label: template.name,
            value: template._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllRecievedMails = async () => {
    setRowSelection({});
    setSelectedMailIds([]);

    try {
      setIsLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_RECIEVED_MAIL}?token=${token}&mailsender=${contactPersonId}`;

      if (filterDateRange != '') {
        requestUrl = requestUrl + `&maildate=${filterDateRange}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&maillabels=${filterTags}`;
      }

      if (filterTemplates.length > 0) {
        requestUrl = requestUrl + `&mailtemplates=${filterTemplates}`;
      }

      // if (filterSender) {
      //   requestUrl = requestUrl + `&mailsender=${filterSender}`;
      // }

      if (filterSubject != '') {
        requestUrl = requestUrl + `&mailsubject=${filterSubject}`;
      }

      if (filterHasAttachment == true) {
        requestUrl = requestUrl + `&attachment=1`;
      }

      const response = await getData(requestUrl);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType('success');
        setMailList(response.data);
        resetFilterData();
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  const changeStatusHandler = async () => {
    if (selectedMailIds.length > 0) {
      let responseArr = [];

      for (let mailId of selectedMailIds) {
        try {
          let mailData = {
            receiverstatus: '0',
          };

          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_MAIL +
            `/${mailId}` +
            `?token=${token}`;
          const response = await putData(requestUrl, mailData);

          if (response.status) {
            setMessageType('success');
            responseArr.push(true);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllRecievedMails();
        }, 2000);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select mail for delete');
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setfilterSubject('');
    setfilterTemplates([]);
    setFilterTags([]);
    setFilterHasAttachment(false);
  };

  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterSubject != '' ||
      filterTags.length > 0 ||
      filterHasAttachment == true ||
      filterTemplates.length > 0
    ) {
      getAllRecievedMails();
    }
  }, [
    filterSubject,
    filterTemplates,
    filterDateRange,
    filterTags,
    filterHasAttachment,
  ]);

  useEffect(() => {
    if (contactPersonId) {
      getAllRecievedMails();
      getAllTags();
      getAllMailTemplates();
    }
  }, [contactPersonId]);

  useEffect(() => {
    if (params.id) {
      getDetailsContact();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */

  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasEmail"
            aria-controls="offcanvasEmail"
            onClick={() => {
              setSelectedMailId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'subject',
        header: t('Mail Subject'),
      },
      {
        accessorKey: 'maildate',
        header: t('Date'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Tags'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedMailIds(selectedIdsArray);
    } else {
      setSelectedMailIds([]);
    }
  }, [rowSelection]);

  // This is a function that is called when the alert close button is clicked.
  // It updates the state variables to hide the alert and clear the alert message and type.
  //The function has no parameters.
  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    (moduleAccess.includes('MOD_CRM') && moduleAccess.includes('MOD_CONTACT'))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-contact-mail-wrapper bg-white pb-5">
          <CrmContactDetailsCommonHeader
            moduleName="mail"
            contactImage={contactImage}
            contactName={contactName}
          />
          <div className="container-fluid px-lg-5">
            <CrmContactMailHeader reloadList={getAllRecievedMails} />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={mailList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 200,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
          {/* --- filter popup --- */}
          <ConversationFilterPopup
            tagList={tagList}
            templateList={templateList}
            setFilterDateRange={setFilterDateRange}
            setfilterSubject={setfilterSubject}
            setfilterTemplates={setfilterTemplates}
            setFilterTags={setFilterTags}
            setFilterHasAttachment={setFilterHasAttachment}
          />

          {/* --- new mail popup --- */}
          <ConversationNewMailPopup
            reloadList={getAllRecievedMails}
            contactPersonEmail={contactPersonEmail}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ---  mail popup --- */}
          <ConversationMailPopup
            selectedMailId={selectedMailId}
            resetMailId={() => {
              setSelectedMailId(null);
            }}
          />

          <AddLabelModal
            moduleName="conversation"
            selectedIds={selectedMailIds}
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllRecievedMails();
              }, 2200);
            }}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedMailIds([]);
            }}
            onDelete={changeStatusHandler}
            moduleName={t('received mail')}
          />
          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default RecievedMailList;
