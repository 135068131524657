import Header from 'components/Common/Header/Header';
import GroupProductOffersBody from 'components/GroupComponents/GroupDetails/GroupProduct/GroupProductDetails/GroupProductOffers/GroupProductOffersBody/GroupProductOffersBody';
import React from 'react';

const GroupProductOffers = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ----------- body section ------------ */}
      <GroupProductOffersBody />
    </>
  );
};

export default GroupProductOffers;
