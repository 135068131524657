/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import CouponsListData from '../../../../data/Coupons/CouponsListData.json';
import CouponListHeader from '../CouponListHeader/CouponListHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import CouponListFilterPopup from '../Popup/CouponListFilterPopup';

const CouponListBody = () => {
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'sl',
        header: 'SL',
        Cell: ({ row }) => (
          <div className="sl border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <p>{row.original.sl}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'valueAmount',
        header: 'Value/Amt.',
        Cell: ({ row }) => (
          <div className="value-amt border-bottom-0">
            <p>{row.original.valueAmount}</p>
          </div>
        ),
      },
      {
        accessorKey: 'owner',
        header: 'Owner',
        Cell: ({ row }) => (
          <div className="owner border-bottom-0">
            <p>{row.original.owner}</p>
          </div>
        ),
      },
      {
        accessorKey: 'product',
        header: 'Product',
        Cell: ({ row }) => (
          <div className="product border-bottom-0">
            <p>{row.original.product}</p>
          </div>
        ),
      },
      {
        accessorKey: 'startDate',
        header: 'Start Date',
        Cell: ({ row }) => (
          <div className="start-date border-bottom-0">
            <p>{row.original.startDate}</p>
          </div>
        ),
      },
      {
        accessorKey: 'endDate',
        header: 'End Date',
        Cell: ({ row }) => (
          <div className="end-date border-bottom-0">
            <p>{row.original.endDate}</p>
          </div>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  const getAllCoupons = () => {};

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  useEffect(() => getAllCoupons(), []);

  return (
    <div id="content_wrapper">
      <section className="survey-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          {/* ---- header area ----*/}
          <CouponListHeader />

          {/* ----------- table area --------- */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={CouponsListData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* ------------- add label modal --------------- */}
      <AddLabelModal moduleName="" />

      {/* -------------- filter popup ------------- */}
      <CouponListFilterPopup />
    </div>
  );
};

export default CouponListBody;
