/* eslint-disable */
import { useEffect, useState } from "react";
import PrevilegeHeader from "./Header/PrevilegeHeader";
import { assetImages } from "constants";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import PreviledgeSuccessModal from "components/CRMComponents/CRMLeadListComponents/Modal/PreviledgeSuccessModal";
import { useTranslation } from "react-i18next";

const ModuleRolePrevilegeBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [previleges, setPrevileges] = useState([]);

  const [isSaving, setisSaving] = useState(false);

  //function for get all tags
  const getAllRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_ROLES +
        `?token=${token}&filterfeatured=${true}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all modules
  const getAllModulePrevileges = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLE_PREVILEGES +
        `?token=${token}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setPrevileges(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //change previlege
  const changePrevilegeCheckHandler = (e, previlegeData, moduleData) => {
    const updatedPrevilegeModules = previleges.map((dataItem) => {
      const updatedPrevileges = dataItem.previleges.map((dataItem2) => {
        if (dataItem2.slug === previlegeData.slug) {
          return { ...dataItem2, privilege: e.target.checked };
        }
        return dataItem2;
      });

      if (dataItem.slug === moduleData.slug) {
        return { ...dataItem, previleges: updatedPrevileges };
      }

      return dataItem;
    });

    setPrevileges(updatedPrevilegeModules);
  };

  //save previlege
  const savePrevilegeHandler = async () => {
    setisSaving(true);
    try {
      let previlegeData = {
        previlegearr: previleges,
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_MODULE_ROLE_PREVILEGE +
        `?token=${token}`;

      const response = await postData(requestUrl, previlegeData);

      setisSaving(false);
      // console.log(response);
      if (response.status) {
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("afterSavePreviledgeModal")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllRoles();
    getAllModulePrevileges();
  }, []);

  if (userInfo.role.slug === "ADMIN" || userInfo.role.slug === "SUPER_ADMIN") {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <PrevilegeHeader
              reloadList={getAllModulePrevileges}
              savePrevilegeHandler={savePrevilegeHandler}
              isSaving={isSaving}
            />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <table
                  id="module_role_privilege"
                  className="table display nowrap"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th className="module text-gray fw-normal">
                        <span>{t("Module")}</span>
                      </th>

                      {roleList.map((dataItem, index) => {
                        return (
                          <th
                            className={`${dataItem.name} text-gray fw-normal`}
                            key={index}
                          >
                            <span>{dataItem.name}</span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  {roleList.length > 0 ? (
                    <tbody>
                      {previleges.map((dataItem, index) => {
                        return (
                          <tr
                            className={dataItem.isroot ? "parent" : "children"}
                            key={index}
                          >
                            <td className="module">
                              <span
                                className={dataItem.isroot ? "fw-bold" : "ps-3"}
                              >
                                {dataItem.name}
                              </span>
                            </td>
                            {dataItem.previleges.map(
                              (previlegeData, index2) => {
                                return (
                                  <td
                                    className={previlegeData.slug}
                                    key={index2}
                                  >
                                    <div className="form-check">
                                      <input
                                        className="form-check-input check-success border-black"
                                        type="checkbox"
                                        name={previlegeData.name}
                                        id={previlegeData.slug}
                                        checked={previlegeData.privilege}
                                        onChange={(e) =>
                                          changePrevilegeCheckHandler(
                                            e,
                                            previlegeData,
                                            dataItem
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </table>
              </div>
            )}
          </div>
        </section>
        <PreviledgeSuccessModal afterModalClose={getAllModulePrevileges} />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ModuleRolePrevilegeBody;
