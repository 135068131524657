/* eslint-disable */
import { useEffect } from "react";
import Header from "components/Common/Header/Header";
import EventPartnerBody from "components/EventComponents/EventDetailsComponents/EventPartner/EventPartnerBody/EventPartnerBody";

const EventPartner = () => {
  useEffect(() => {
    document.title = "Event Sponsor";
  }, []);

  return (
    <>
      <main id="app">
        {/* ---- header start ---- */}
        <Header moduleName="events" />
        {/* --- header end --- */}
        <EventPartnerBody />
      </main>
    </>
  );
};

export default EventPartner;
