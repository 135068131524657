/* eslint-disable */
import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

// Import Editor here (both required).
// import { Editor } from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";
import downloadFileHandler from "helper/DownloadFile";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
import { assetImages } from "constants";
import { joditEditorConfig } from "Config/Config";

const SaveLogPopup = ({ selectedLogId, onPopUpClose }) => {
  const { t } = useTranslation(); // translation

  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [taskId, setTaskId] = useState(null);
  const [taskStatusValue, settaskStatusValue] = useState(null);
  const [taskStatusData, settaskStatusData] = useState("");
  const [taskPercent, settaskPercent] = useState("");
  const [logHours, setlogHours] = useState("");
  const [logStartDate, setlogStartDate] = useState(todayValue);
  const [logEndDate, setlogEndDate] = useState(todayValue);
  const [logDetails, setlogDetails] = useState("");
  const [logOwner, setLogOwnerId] = useState(null);
  const [logAdding, setlogAdding] = useState(false);

  //* hook for validation */
  const [validation, setValidation] = useState({
    hoursWarning: false,
    detailsWarning: false,
    statusWarning: false,
  });

  const editor = useRef(null);

  const editorCustomConfig = joditEditorConfig();

  const editorConfig = useMemo(() => editorCustomConfig, []);

  //* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (logHours === "" || logHours == 0) {
      setValidation((prevState) => ({ ...prevState, hoursWarning: true }));
      isValid = false;
    }

    if (taskStatusData === null) {
      setValidation((prevState) => ({ ...prevState, userWarning: true }));
      isValid = false;
    }

    if (logDetails === "") {
      setValidation((prevState) => ({
        ...prevState,
        detailsWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //** function for get log details */
  const getLogDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LOG_DETAILS +
        `/${selectedLogId}?token=${token}`;

      console.log("log details url", requestUrl);

      const response = await getData(requestUrl);

      console.log("log details response", response);

      if (response.status && response.data) {
        setLogOwnerId(response.data.logowner?._id ?? null);
        setTaskId(response.data.projecttask?._id ?? null);
        setlogHours(response.data.hours);
        setlogStartDate(response.data.startdate);
        setlogEndDate(response.data.enddate);
        settaskPercent(response.data.taskpercent);
        setlogDetails(response.data.description);
        TaskStatusOptions.map((statusData) => {
          if (statusData.value == response.data.completedpercent) {
            statusSelectionHandler(statusData);
          }
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* select status */
  const statusSelectionHandler = (val) => {
    settaskStatusValue(val);
    settaskStatusData(val.value);
  };

  //** Funciton for save log */
  const saveLogHandler = async () => {
    if (validationHandler()) {
      try {
        setlogAdding(true);

        let logData = {
          projecttask: taskId,
          completedpercent: taskStatusData,
          description: logDetails,
          startdate: logStartDate,
          enddate: logEndDate,
          hours: logHours,
          taskpercent: taskPercent,
        };

        let response = {};

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_TASK_LOG +
          `/${selectedLogId}?token=${token}`;

        response = await putData(requestUrl, logData);

        setlogAdding(false);

        console.log(response);

        if (response.status) {
          // close the offcanvas of filter
          let offCanvasPopup = document.querySelector("#saveLog");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
          resetHandler();
          onPopUpClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setTaskId(null);
    settaskStatusValue(null);
    settaskStatusData("");
    settaskPercent("");
    setlogHours("");
    setlogStartDate(todayValue);
    setlogEndDate(todayValue);
    setlogDetails("");
  };

  useEffect(() => {
    if (selectedLogId) {
      getLogDetails();
    }
  }, [selectedLogId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveLog"
      aria-labelledby="addTaskLabel"
    >
      <div className="offcanvas-header flex-wrap p-4 pb-0 shadow-sm">
        <h3 className="offcanvas-title" id="addTaskLabel">
          {t("Log Details")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-0 mt-2">
        {/* save log form */}
        <div className="log-form px-4 py-3">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-group mb-3">
              <div className="row">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                    {t("Time (Hours)")}
                  </label>
                  <input
                    type="number"
                    className={`form-control bg-white fs-sm shadow-none ${
                      validation.hoursWarning
                        ? "border-danger"
                        : "border-gray-300"
                    }`}
                    placeholder="Hours required"
                    value={logHours}
                    onChange={(e) => {
                      setlogHours(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        hoursWarning: false,
                      }));
                    }}
                  />
                </div>
                <div className="col-lg-3">
                  <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                    {t("Dates")}
                  </label>
                  <div className="field-container d-flex flex-column flex-lg-row gap-lg-0 align-items-lg-center px-lg-3 bg-lg-white border-lg border-gray-300 rounded-10">
                    <input
                      type="date"
                      id="startdate"
                      className="form-control bg-white border-gray-300 fs-sm shadow-none me-3"
                      placeholder={t("Start Date")}
                      value={logStartDate}
                      onChange={(e) => {
                        setlogStartDate(e.target.value);
                      }}
                    />
                    <input
                      type="date"
                      id="enddate"
                      className="form-control bg-white border-gray-300 fs-sm shadow-none"
                      placeholder={t("End Date")}
                      value={logEndDate}
                      onChange={(e) => {
                        setlogEndDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                    {t("Complete %")}
                  </label>
                  <input
                    type="number"
                    className="form-control bg-white fs-sm shadow-none border-gray-300"
                    value={taskPercent}
                    onChange={(e) => {
                      // if (e.target.value !== "") {
                      //   const convertedNumber = parseInt(e.target.value);
                      //   if (convertedNumber >= 0 && convertedNumber <= 100) {
                      //     settaskPercent(convertedNumber);
                      //   }
                      // }
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        settaskPercent(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                    {t("Task Status")}
                  </label>
                  <Select
                    placeholder="Select task status"
                    options={TaskStatusOptions}
                    value={taskStatusValue}
                    onChange={(val) => {
                      statusSelectionHandler(val);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                {t("Details")}
              </label>

              {/* <Editor
                apiKey={editorConfig}
                init={{
                  placeholder: t("Enter Details"),
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat  | help",
                  height: 200,
                }}
                value={logDetails}
                onEditorChange={(newContent, editor) => {
                  setlogDetails(newContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    detailsWarning: false,
                  }));
                }}
              /> */}

              <JoditEditor
                ref={editor}
                value={logDetails}
                config={editorConfig}
                onChange={(newContent, event) => {
                  setlogDetails(newContent);
                }}
              />

              {validation.detailsWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter details!")}</span>
                  </p>
                </div>
              )}
            </div>

            {userInfo.role.slug === "ADMIN" ||
            userInfo.role.slug === "SUPER_ADMIN" ||
            (logOwner && logOwner.toString() === userInfo._id.toString()) ? (
              <div className="action d-flex align-items-center gap-3">
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={saveLogHandler}
                  disabled={logAdding ? true : false}
                  style={{
                    cursor: logAdding ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {logAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SaveLogPopup;
