/* eslint-disable */
import React, { useEffect } from "react";

import Header from "components/Common/Header/Header";
import ProjectTaskBody from "components/ProjectManagementComponents/ProjectDetailsComponents/ProjectTaskComponents/ProjectTaskBody/ProjectTaskBody";

const ProjectTasks = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectTaskBody />
    </main>
  );
};

export default ProjectTasks;
