/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";

const EcosystemFilterPopup = ({
  setFilterModuleName,
  setFilterModuleSlug,
  setFilterDateRange,
}) => {
  const { t } = useTranslation(); //for translation
  const [name, setname] = useState("");
  const [slug, setslug] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setFilterModuleName(name);
    setFilterModuleSlug(slug);

    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#ecosystemFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    setname("");
    setslug("");
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="ecosystemFilter"
      aria-labelledby="ecosystemFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* ---- date accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  {t("Dates")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group d-flex gap-2">
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("From date")}
                      value={fromDate}
                      onChange={(e) => setfromDate(e.target.value)}
                    />
                    <input
                      type="date"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("To date")}
                      value={toDate}
                      onChange={(e) => settoDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* ---- date accordion end ----- */}

            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  {t("Name")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter")}
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Slug")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter")}
                      value={slug}
                      onChange={(e) => {
                        setslug(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={searchHandler}
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EcosystemFilterPopup;
