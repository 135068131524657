/* eslint-disable */
import Header from "components/Common/Header/Header";
import GroupInfoBody from "components/GroupComponents/GroupDetails/GroupInfo/GroupInfoBody/GroupInfoBody";
import React, { useEffect } from "react";

const GroupInfo = () => {
  useEffect(() => {
    document.title = "Group Info";
  }, []);

  return (
    <main id="app">
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ---------- body section -------------- */}
      <GroupInfoBody />
    </main>
  );
};

export default GroupInfo;
