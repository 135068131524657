/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { getData, postData, putData, uploadSingleFile } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";
import downloadFileHandler from "helper/DownloadFile";

const UploadContentModal = ({
  selectedUploadId,
  setSelectedUploadId,
  uploadedFiles = [],
  setuploadedFiles,
  uploadedFileIds = [],
  setuploadedFileIds,
  setShowAlert,
  setMessageType,
  setAlertMessage,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation

  const [uploadId, setuploadId] = useState(null);
  const [uploadedFile, setuploadedFile] = useState(null);
  const [uploadedFilePath, setuploadedFilePath] = useState("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [rank, setrank] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const docFileUploadHandler = async (e) => {
    const file = e.target.files[0];
    // setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        // setIsUploading(false);
        setuploadedFile(response.data);
        setuploadedFilePath(response.data.path);
        setuploadId(response.data._id);
        setuploadedFileName(response.data?.name);
        resetUploadFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetUploadFile = () => {
    const file = document.getElementById("uploadedCourseContentFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  const closeDocFileHandler = () => {
    setuploadId(null);
    setuploadedFilePath("");
    setuploadedFile(null);
    resetUploadFile();
  };

  const closeModalHandler = () => {
    setSelectedUploadId(null);
    setuploadId(null);
    setuploadedFilePath("");
    setuploadedFile(null);
    settitle("");
    setdetails("");
    setrank("");
  };

  const getContentDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_CONTENT_DETAILS + `/${selectedUploadId}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
        setdetails(response.data.description);
        setrank(response.data.rank);
        setuploadedFile(response.data?.uploadid);
        setuploadedFilePath(response.data?.uploadid?.path);
        setuploadId(response.data?.uploadid?._id);
        setuploadedFileName(response.data?.uploadid?.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const saveContentHandler = async () => {
    try {
      setIsSaving(true);

      let uploadData = {
        title,
        slug: "",
        description: details,
        rank,
        uploadid: uploadId,
      };

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (selectedUploadId) {
        requestUrl +=
          url.API_UPDATE_CONTENT + `/${selectedUploadId}?token=${token}`;

        response = await putData(requestUrl, uploadData);
      } else {
        requestUrl += url.API_ADD_NEW_CONTENT + `?token=${token}`;

        response = await postData(requestUrl, uploadData);
      }

      setIsSaving(false);

      console.log(response);

      if (response.status) {
        const fileResponseObj = {
          _id: response.data._id,
          name: response.data?.uploadid?.name,
          title: response.data.title,
          description: response.data.description,
          rank: response.data.rank,
        };

        if (selectedUploadId) {
          // Find the index of the object to be updated
          const fileIndex = uploadedFiles.findIndex(
            (obj) => obj._id.toString() === fileResponseObj._id.toString()
          );

          // Check if the object exists in the array
          if (fileIndex !== -1) {
            // Replace the old object with the updated object
            setuploadedFiles((prevArr) =>
              prevArr.map((prevObj) =>
                prevObj._id.toString() === fileResponseObj._id.toString()
                  ? { ...prevObj, ...fileResponseObj }
                  : prevObj
              )
            );
          }
        } else {
          if (uploadedFiles.length === 0) {
            setuploadedFiles([fileResponseObj]);
          } else {
            setuploadedFiles((prev) => [...prev, fileResponseObj]);
          }

          if (uploadedFileIds.length === 0) {
            setuploadedFileIds([response.data._id]);
          } else {
            setuploadedFileIds((prev) => [...prev, response.data._id]);
          }
        }

        setShowAlert(true);
        setMessageType("success");
        setAlertMessage(response.message);

        //hide  modal
        let bootstrapModal = document.querySelector("#upload_content");
        let modal = bootstrap.Modal.getInstance(bootstrapModal);
        modal.hide();

        closeModalHandler();
      } else {
        setShowAlert(true);
        setAlertMessage("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(error.message);
    }
  };

  useEffect(() => {
    if (selectedUploadId) {
      getContentDetails();
    }
  }, [selectedUploadId]);

  return (
    <div
      className="modal fade"
      id="upload_content"
      tabIndex="-1"
      aria-labelledby="upload_content"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 border-0">
            <div className="signin_hdng text-center">
              <h3 className="mt-0 fs-xl mb-1">Save Content</h3>
              <h5 className="text-gray fs-md text-left">
                Give content information
              </h5>
            </div>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
          </div>
          <div className="modal-body p-4 pt-0">
            <div className="form_wrapper">
              <div className="form-group mb-3">
                <label className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2">
                  <span className="d-block">{t("Rank")}</span>
                </label>
                <input
                  type="number"
                  min={0}
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter Rank")}
                  value={rank}
                  onChange={(e) => setrank(e.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <label className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2">
                  <span className="d-block">{t("Title")}</span>
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter title")}
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                />
              </div>
              <div className="form-group mb-3">
                <label className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2">
                  <span className="d-block">{t("Details")}</span>
                </label>
                <textarea
                  cols="30"
                  rows="3"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter Details")}
                  value={details}
                  onChange={(e) => setdetails(e.target.value)}
                ></textarea>
              </div>

              <div className="form-group upload_content_sec">
                <label className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2">
                  <span className="d-block">{t("Content")}</span>
                </label>
                <div className="process_upload mb-3 d-flex align-items-center justify-content-center rounded-10 position-relative">
                  <input
                    type="file"
                    className="file_btn"
                    id="uploadedCourseContentFile"
                    onChange={docFileUploadHandler}
                  />
                  <div className="process_upload_text text-center">
                    <span className="material-symbols-outlined mb-2 d-block text-gray">
                      file_upload
                    </span>
                    <h3 className="text-black fw-semibold fs-md mb-2">
                      Upload
                    </h3>
                    <h5 className="fs-xs text-gray mb-0">
                      Max 20mb jpg,Pdf,doc
                    </h5>
                  </div>
                </div>
              </div>

              {uploadId ? (
                <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                  <li className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3">
                    <Link to="#" onClick={() => closeDocFileHandler()}>
                      <span className="d-block material-symbols-outlined">
                        close
                      </span>
                    </Link>
                    <p className="fw-medium">{uploadedFileName}</p>

                    <Link
                      to="#"
                      onClick={() => downloadFileHandler(uploadedFile)}
                    >
                      <span className="d-block material-symbols-outlined">
                        download
                      </span>
                    </Link>
                  </li>
                </ul>
              ) : null}

              {uploadedFilePath.split(".").pop() == "mp4" ? (
                <video
                  controls
                  width="100%"
                  height={250}
                  src={url.SERVER_URL + uploadedFilePath}
                  className="mt-3 object-fit-cover"
                  autoPlay
                >
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
            <div className="process_btns_outer d-flex align-items-center justify-content-between mt-4">
              <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-outline-primary"
                onClick={closeModalHandler}
              >
                Back
              </Link>
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveContentHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                {t("Save")}
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadContentModal;
