/* eslint-disable */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import images
import { assetImages } from "constants";

//import options
import publishOptions from "data/Prod/PublishOptions.json";

//import download file
import fileDownloadHandler from "helper/DownloadFile";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import BlogSuccessMessageModal from "../Modal/BlogSuccessMessageModal";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import downloadFileHandler from "helper/DownloadFile";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import { ckEditorConfig } from "Config/Config";
import { useTranslation } from "react-i18next";

const AddUpdateBlogBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const ecosystemSlug = localStorage.getItem("ecosystemslug");
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split("T")[0];

  const params = useParams();
  const history = useHistory();
  const loadingCircle = [1, 2, 3, 4, 5, 6];

  const coverImageFileInputRef = useRef(null);
  const uploadFileInputRef = useRef(null);

  const [categoryList, setCategoryList] = useState([]);

  const [blogName, setBlogName] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [coverImageId, setCoverImageId] = useState(null);
  const [blogSummary, setBlogSummary] = useState("");
  const [blogDetails, setBlogDetails] = useState("");
  const [categoryValue, setCategoryValue] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [facebookUrl, setFacebookUrl] = useState("");
  const [pinterestUrl, setPinterestUrl] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [publishValue, setPublishValue] = useState(null);
  const [publishStatus, setPublishStatus] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    imageWarning: false,
    summaryWarning: false,
    detailsWarning: false,
    categoryWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (blogName === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (!coverImageId) {
      setValidation((prevState) => ({ ...prevState, imageWarning: true }));
      isValid = false;
    }

    if (blogSummary === "") {
      setValidation((prevState) => ({ ...prevState, summaryWarning: true }));
      isValid = false;
    }

    if (blogDetails === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    if (!categoryValue) {
      setValidation((prevState) => ({ ...prevState, categoryWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all category
  const fetchAllCategories = async () => {
    try {
      const parentSlug = "blogpost";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setCategoryList(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for open image imput
  const openCoverImageFileInput = () => {
    coverImageFileInputRef.current.click();
  };

  //function for image upload
  const coverImageUploadHandler = async (e) => {
    setValidation((prevState) => ({
      ...prevState,
      imageWarning: false,
    }));
    setErrorMessage("");
    const file = e.target.files[0];
    setIsImageUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsImageUploading(false);
        setCoverImageUrl(url.SERVER_URL + response.data.path);
        setCoverImageId(response.data._id);
        resetCoverImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for clear cover image
  const removeCoverImageHandler = () => {
    setCoverImageUrl("");
    setCoverImageId(null);
  };

  // function for clear file value
  const resetCoverImageFile = () => {
    const file = document.getElementById("uploadcoverimage");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select category
  const categorySelectionHandler = (selectedValues) => {
    setCategoryValue(selectedValues);
    const selectedCategories = selectedValues.map((item) => item.value);
    setSelectedCategories(selectedCategories);
  };

  //function for open image imput
  const openUploadFileInput = () => {
    uploadFileInputRef.current.click();
  };

  //function for image upload
  const blogFileUploadHandler = async (e) => {
    try {
      setIsFileUploading(true);

      const blogUploadedFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, blogUploadedFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetBlogFileInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeBlogFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setUploadedFileIds(removedFileId);

    resetBlogFileInput();
  };

  //function for reset video inputs
  const resetBlogFileInput = () => {
    const file = document.getElementById("blogfileinput");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select publish
  const publishSelectionHandler = (val) => {
    setPublishValue(val);
    setPublishStatus(val.value);
  };

  //function for reset
  const resetBlogHandler = () => {
    setBlogName("");
    setCoverImageUrl("");
    setCoverImageId(null);
    setBlogSummary("");
    setBlogDetails("");
    setCategoryValue(null);
    setCategoryList([]);
    setFacebookUrl("");
    setPinterestUrl("");
    setContactEmail("");
    setLinkUrl("");
    setUploadedFiles([]);
    setUploadedFileIds([]);
    setPublishValue(null);
    setPublishStatus(null);
    setValidation({
      nameWarning: false,
      imageWarning: false,
      summaryWarning: false,
      detailsWarning: false,
      categoryWarning: false,
    });
  };

  //function for get blog details
  const getBlogDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_BLOG_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response if blog details---->", response);

      if (response.status) {
        setBlogName(response.data?.name);
        setCoverImageUrl(url.SERVER_URL + response.data?.coverimageurl);
        setCoverImageId(response.data?.coverimageid);
        setBlogSummary(response.data?.summary);
        setBlogDetails(response.data?.description);
        setFacebookUrl(response.data?.facebook);
        setContactEmail(response.data?.contactmail);
        setPinterestUrl(response.data?.pinterest);
        setLinkUrl(response.data?.linked);

        setUploadedFiles(
          response.data?.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
        const fileIds = response.data?.uploads.map(({ _id, ...other }) => ({
          _id,
        }));
        setUploadedFileIds(fileIds);

        //assign category
        categorySelectionHandler(response.data?.category);

        //assing publish
        if (response.data?.publish) {
          publishOptions.map((publishData) => {
            if (publishData.value == response.data.publish) {
              publishSelectionHandler(publishData);
            }
          });
        }
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create/update blog
  const saveBlogHandler = async () => {
    if (validate()) {
      try {
        setIsSaving(true);

        let blogData = {
          userid: userInfo._id,
          name: blogName,
          coverimage: coverImageId,
          summary: blogSummary,
          description: blogDetails,
          category: selectedCategories,
          uploads: uploadedFileIds,
          publish: publishStatus,
          facebook: facebookUrl,
          linked: linkUrl,
          pinterest: pinterestUrl,
          contactmail: contactEmail,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_BLOG +
            `/${params.id}` +
            `?token=${token}`;

          response = await putData(requestUrl, blogData);
        } else {
          requestUrl = requestUrl + url.API_CREATE_NEW_BLOG + `?token=${token}`;

          response = await postData(requestUrl, blogData);
        }

        console.log("response in save blog---->", response);

        if (response.status) {
          setIsSaving(false);
          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("afterSaveBlogModal")
          );
          bootstrapModal.show();
        } else {
          setErrorMessage(response.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setErrorMessage(t("Please fill up the required fields"));
    }
  };

  const afterSaveLeadModalClose = () => {
    resetBlogHandler();
    history.push("/admin/blogs/list");
  };

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Blog"), link: "/admin/blogs/list" },
    { title: t("Blog Details") },
  ];

  useEffect(() => {
    if (params.id) {
      getBlogDetails();
    }
  }, [params.id]);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CMS")
  ) {
    return (
      <div id="content_wrapper">
        <section className="blog-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} />

            <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
              {/* ------ title section start ------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="title"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Title")}
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter"
                  value={blogName}
                  onChange={(e) => {
                    setBlogName(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      nameWarning: false,
                    }));
                    setErrorMessage("");
                  }}
                />
                {/* name warning */}
                {validation.nameWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter a title")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ------ title section end ------- */}

              {/* ------ cover image section start ------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="coverImage"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Cover Image")}
                </label>
                <div className="d-flex align-items-center gap-3">
                  <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                    <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                      <Link
                        to="#"
                        data-target="upload"
                        className="upload-file text-center"
                        onClick={openCoverImageFileInput}
                      >
                        <span className="d-block material-symbols-outlined">
                          upload
                        </span>
                        <span className="d-block fw-semibold">
                          {t("Upload")}
                        </span>
                      </Link>
                      <span className="fs-xs">Max 5mb jpg/png</span>
                      <input
                        type="file"
                        id="uploadcoverimage"
                        className="d-none"
                        ref={coverImageFileInputRef}
                        onChange={coverImageUploadHandler}
                      />
                    </li>
                  </ul>
                  <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
                    {isImageUploading ? (
                      <li>
                        <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                          <p className="fw-bold">
                            {t("Please wait while uploading")}
                          </p>{" "}
                          {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                            return (
                              <div
                                key={index}
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                style={{
                                  height: "5px",
                                  width: "5px",
                                }}
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </li>
                    ) : (
                      <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                        <Link to="#" onClick={removeCoverImageHandler}>
                          <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                            delete
                          </span>
                        </Link>
                        <img
                          src={
                            coverImageUrl == ""
                              ? "https://placehold.co/150x150"
                              : coverImageUrl
                          }
                          alt="Uploaded"
                          className="w-100 h-100 object-fit-cover object-center"
                        />
                      </li>
                    )}
                  </ul>
                </div>
                {validation.imageWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please upload cover image")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ------ cover image section end ------- */}

              {/* ------ seo summary section start ------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label htmlFor="seo" className="d-block fs-sm fw-semibold mb-2">
                  {t("Summary")}
                </label>
                <textarea
                  name="details"
                  id="details"
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Seo summary...")}
                  value={blogSummary}
                  onChange={(e) => {
                    setBlogSummary(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      summaryWarning: false,
                    }));
                    setErrorMessage("");
                  }}
                ></textarea>
                {validation.summaryWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter summary")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ------ seo summary section end ------- */}

              {/* ------ blog details section start ------ */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="details"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Details")}
                </label>
                <CKEditor
                  className="form-control border-0 shadow-none"
                  editor={ClassicEditor}
                  config={ckEditorConfig(t("Type details here..."))}
                  data={blogDetails ? blogDetails : ""}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    let editorContent = editor.getData();
                    setBlogDetails(editorContent);
                    setValidation((prevState) => ({
                      ...prevState,
                      detailsWarning: false,
                    }));
                    setErrorMessage("");
                  }}
                />

                {validation.detailsWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter details")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ------ blog details section end ------ */}

              {/* ------ categories select section start ------ */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="categories"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Categories")}
                </label>
                <Select
                  isMulti
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => {
                    categorySelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      categoryWarning: false,
                    }));
                    setErrorMessage("");
                  }}
                />
                {validation.categoryWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please select categories")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ------ categories select section end ------ */}

              {/* ------ facebook and pinterest section start ------- */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  {/* ------ facebook section start ------- */}
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="facebook"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Facebook
                    </label>
                    <input
                      type="text"
                      id="facebook"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Paste URL")}
                      value={facebookUrl}
                      onChange={(e) => setFacebookUrl(e.target.value)}
                    />
                  </div>
                  {/* ------ facebook section end ------- */}

                  {/* ------ pinterest section start ------- */}
                  <div className="col-lg-6">
                    <label
                      htmlFor="pinterest"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Pinterest
                    </label>
                    <input
                      type="text"
                      id="pinterest"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Paste URL")}
                      value={pinterestUrl}
                      onChange={(e) => setPinterestUrl(e.target.value)}
                    />
                  </div>
                  {/* ------ pinterest section end ------- */}
                </div>
              </div>
              {/* ------ facebook and pinterest section end ------- */}

              {/* ------ contact mail and linked url section start ------- */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  {/* ------ contact mail section start ------- */}
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="contactmail"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Contact Email")}
                    </label>
                    <input
                      type="email"
                      id="gmail"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Entrer mail ID")}
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                    />
                  </div>
                  {/* ------ contact mail section end ------- */}

                  {/* ------ linked url section start ------- */}
                  <div className="col-lg-6">
                    <label
                      htmlFor="twitter"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Linked URL")}
                    </label>
                    <input
                      type="text"
                      id="linkdin"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Paste URL")}
                      value={linkUrl}
                      onChange={(e) => setLinkUrl(e.target.value)}
                    />
                  </div>
                  {/* ------ linked url section end ------- */}
                </div>
              </div>
              {/* ------ contact mail and linked url section end ------- */}

              {/* ------ attachment section start ------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="attachments"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Attachments")}
                </label>
                <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                  <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                    <Link
                      to="#"
                      data-target="upload"
                      className="upload-file text-center"
                      onClick={openUploadFileInput}
                    >
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">{t("Upload")}</span>
                    </Link>
                    <span className="fs-xs">Max 20mb doc/img/mp4</span>
                    <input
                      type="file"
                      id="blogfileinput"
                      className="d-none"
                      ref={uploadFileInputRef}
                      onChange={blogFileUploadHandler}
                      multiple
                    />
                  </li>
                </ul>

                {isFileUploading ? (
                  <div className="d-flex align-items-center mt-3">
                    <p className="mb-0 me-1">{t("Uploading Files")}</p>
                    {loadingCircle.map((circle) => {
                      return (
                        <div
                          key={circle}
                          className="spinner-grow mx-1"
                          style={{ height: "6px", width: "6px" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                    {uploadedFiles.map((file, index) => {
                      return (
                        <li
                          className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                          key={index}
                        >
                          <Link
                            to="#"
                            onClick={() => downloadFileHandler(file)}
                          >
                            <span className="d-block material-symbols-outlined">
                              download
                            </span>
                          </Link>
                          <p className="fw-medium">{file.name}</p>
                          <Link
                            to="#"
                            onClick={() => closeBlogFileHandler(index)}
                          >
                            <span className="d-block material-symbols-outlined">
                              close
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              {/* ------ attachment section end ------- */}

              {/* ------ publish selection section start ------ */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label
                      htmlFor="categories"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Publish")}
                    </label>
                    <Select
                      options={publishOptions}
                      value={publishValue}
                      onChange={(val) => publishSelectionHandler(val)}
                    />
                  </div>
                </div>
              </div>
              {/* ------ publish selection section end ------ */}

              {/* ------ agree checkbox section start ------ */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                  <input
                    className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                    type="checkbox"
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {t("Agree with the")}{" "}
                    <Link to="#">{t("Legal Notice")}</Link> &amp;{" "}
                    <Link to="#">{t("Privacy Policy")}</Link>
                  </label>
                </div>
              </div>
              {/* ------ agree checkbox section end ------ */}

              {/* ------ save and cancel button section start ------ */}
              <div className="action d-flex flex-wrap justify-content-between gap-3">
                <Link
                  onClick={resetBlogHandler}
                  to="/admin/blogs/list"
                  className="btn btn-outline-primary"
                >
                  {t("Cancel")}
                </Link>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveBlogHandler}
                  style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                  disabled={isSaving ? true : false}
                >
                  {t("Save Now")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {errorMessage == "" ? null : (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{errorMessage}!</span>
                  </p>
                </div>
              )}
              {/* ------ save and cancel button section end ------ */}
            </div>
          </div>
        </section>

        {/* ------ success message modal ------ */}
        <BlogSuccessMessageModal
          afterSaveLeadModalClose={afterSaveLeadModalClose}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default AddUpdateBlogBody;
