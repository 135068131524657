/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import ProcessListData from '../../../data/Process/ProcessListData.json';
import ProcessListHeader from '../ProcessListHeader/ProcessListHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import ProcessFilter from '../Popup/ProcessFilter';
import AddProcessPopup from '../Popup/AddProcessPopup';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

const ProcessListBody = () => {
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="d-flex gap-4">
              <p className="fs-md fw-semibold">{row.original.title}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'Tags',
        header: 'Tags',
        Cell: ({ row }) => (
          <div className="tags border-bottom-0">
            {row.original.tags.map((item, index) => (
              <p className="mb-0" key={index}>
                {item}
              </p>
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'modules',
        header: 'Modules',
        Cell: ({ row }) => (
          <div className="modules border-bottom-0">
            <p>{row.original.modules}</p>
          </div>
        ),
      },
      {
        accessorKey: 'created',
        header: 'Created',
        Cell: ({ row }) => (
          <div className="created border-bottom-0">
            <p>{row.original.created}</p>
          </div>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  // Function to get all records
  const getAllProcesses = () => {};
  useEffect(() => getAllProcesses(), []);

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="survey-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          {/* ---- header area ----*/}
          <ProcessListHeader />

          {/* ----------- table area --------- */}
          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={ProcessListData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* ---------- add label modal ------------- */}
      <AddLabelModal moduleName="" />

      {/* --------- filter popup ------------ */}
      <ProcessFilter />

      {/* ----------- add process popup ---------- */}
      <AddProcessPopup />
    </div>
  );
};

export default ProcessListBody;
