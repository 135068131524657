import Header from 'components/Common/Header/Header';
import TaskBody from 'components/CourseComponents/CourseDetails/CourseLessonComponents/DetailsComponents/TaskComponents/TaskBody/TaskBody';
import React from 'react';

const CourseLessonsDetailsTask = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <TaskBody />
    </main>
  );
};

export default CourseLessonsDetailsTask;
