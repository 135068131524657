/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';

const AffiliateDetailsPopup = () => {
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="affiliateDetails"
      aria-labelledby="affiliateDetailsLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <div className="action d-flex flex-wrap gap-3 justify-content-between">
          <Link
            to="#"
            className="btn btn-outline-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </Link>
          <button type="submit" className="btn btn-primary">
            Add Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default AffiliateDetailsPopup;
