/* eslint-disable  */
import JobInformationBody from "components/ChallengeComponents/ChallengeDetailsComponents/JobInformation/JobInformationBody/JobInformationBody";
import Header from "components/Common/Header/Header";
import { useEffect } from "react";

const JobInformation = () => {
  useEffect(() => {
    document.title = "Challenge - Information";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="challenges" />

      {/* ---------- body section ------------- */}
      <JobInformationBody />
    </main>
  );
};

export default JobInformation;
