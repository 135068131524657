/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

const CrmSalesOrderHeader = () => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 mb-3 mb-md-0">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-group d-flex align-items-center flex-row-reverse position-relative">
              <input
                type="text"
                className="form-control ps-2 bg-transparent border-0 shadow-none"
                placeholder="Search by Challenges name"
              />
              <button type="submit" className="p-0 bg-transparent border-0">
                <span className="d-block material-symbols-outlined icon-lg">
                  search
                </span>
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-6 d-flex justify-content-end gap-2">
          <Link to="#" className="btn btn-gray d-flex align-items-center">
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown">
            <button
              className="btn btn-gray text-start dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">Add Label</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">Delete</span>
                </Link>
              </li>
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFilter"
            aria-controls="offcanvasFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">Filter</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CrmSalesOrderHeader;
