import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CertificateDetailsPopup = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="certificateDetails"
      aria-labelledby="certificateDetailsLabel"
    >
      <div className="offcanvas-header gradient-light p-4">
        <div>
          <h3 className="offcanvas-title mb-2" id="certificateDetailsLabel">
            The best control monitoring
          </h3>
          <ul className="task-category d-flex flex-wrap">
            <li className="position-relative">Big Tech</li>
            <li className="position-relative">IT Gients</li>
            <li className="position-relative">Technology</li>
          </ul>
        </div>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-0">
        <div className="task-details flex-fill p-4">
          <p className="fs-md fw-semibold mb-1">{t("Comments if Any")}</p>
          <p>
            The ATM system has the ability to use all existing surveillance
            sources (PSR, SSR, MSSR, WAM / MLAT, ADS-B, both individually
            (monosensor) and combined (multisensor) presenting only one
            surveillance track that does not jump when switching from one source
            to another
          </p>
          <ul className="documents row d-flex flex-wrap mt-4">
            <li className="col-6">
              <div className="d-flex align-items-center gap-3 p-3 body-bg rounded-5">
                <img
                  src="/assets/img/pdf-icon.svg"
                  alt="Design.pdf"
                  className="img-fluid"
                />
                <div className="doc-details fs-sm">
                  <p className="fw-semibold mb-0">Design.pdf</p>
                  <p className="lh-1">1.5mb</p>
                </div>
                <Link to="#" className="text-primary ms-auto">
                  <span className="material-symbols-outlined">download</span>
                </Link>
              </div>
            </li>
            <li className="col-6">
              <div className="d-flex align-items-center gap-3 p-3 body-bg rounded-5">
                <img
                  src="/assets/img/pdf-icon.svg"
                  alt="Design.pdf"
                  className="img-fluid"
                />
                <div className="doc-details fs-sm">
                  <p className="fw-semibold mb-0">Design.pdf</p>
                  <p className="lh-1">1.5mb</p>
                </div>
                <Link to="#" className="text-primary ms-auto">
                  <span className="material-symbols-outlined">download</span>
                </Link>
              </div>
            </li>
          </ul>
        </div>
        <form action="" className="p-4 border-top">
          <div className="form-group mb-4">
            <label htmlFor="comment" className="d-block fs-sm fw-semibold mb-2">
              {t("Comments if Any")}
            </label>
            <textarea
              name="comment"
              id="comment"
              cols="30"
              rows="5"
              className="form-control fs-sm fw-semibold shadow-none"
              placeholder="Enter message"
            ></textarea>
          </div>
          <div className="form-group mb-4">
            <p className="d-flex align-items-center gap-2 fs-sm text-gray">
              <span className="d-block material-symbols-outlined icon-md">
                info
              </span>
              <span className="d-block">
                The library also has delightful and beautifully{" "}
              </span>
            </p>
          </div>
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <button type="submit" className="btn btn-primary">
              {t("Approve")}
            </button>
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Decline")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CertificateDetailsPopup;
