import React, { useState } from 'react';
import GroupProductDetailsCommonHeader from '../../GroupProductDetailsCommonHeader/GroupProductDetailsCommonHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import GroupProductVariationDeleteModal from '../Modals/GroupProductVariationDeleteModal';

const GroupProductVariationsBody = () => {
  // select data --------------------------
  const options = [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);

  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white">
        {/* --------- common header --------- */}
        <GroupProductDetailsCommonHeader activeTab="variations" />

        {/* --------- form area ------------ */}
        <div className="container-fluid px-lg-5 py-4 py-lg-5">
          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form action="">
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="attributeName1"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Attribute Name
                    </label>
                    <div className="field-container position-relative">
                      <input
                        type="text"
                        id="attributeName1"
                        className="form-control pe-5 fs-sm shadow-none"
                        placeholder="Color"
                      />
                      <Link
                        to="#"
                        className="text-gray position-absolute top-50 end-0 translate-middle-y me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                      >
                        <span className="d-block material-symbols-outlined icon-lg">
                          delete
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <label
                      htmlFor="variations1"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Variations
                    </label>
                    <Select
                      placeholder="Select options"
                      options={options}
                      value={selectOptions}
                      onChange={val => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="attributeName2"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Attribute Name
                    </label>
                    <div className="field-container position-relative">
                      <input
                        type="text"
                        id="attributeName2"
                        className="form-control pe-5 fs-sm shadow-none"
                        placeholder="Size"
                      />
                      <Link
                        to="#"
                        className="text-gray position-absolute top-50 end-0 translate-middle-y me-3"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                      >
                        <span className="d-block material-symbols-outlined icon-lg">
                          delete
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <label
                      htmlFor="variations2"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Variations
                    </label>
                    <Select
                      placeholder="Select options"
                      options={options}
                      value={selectOptions}
                      onChange={val => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link to="#" className="btn btn-outline-primary">
                  Add Another
                </Link>
                <button type="submit" className="btn btn-primary">
                  Save Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* ----------- delete modal ------------ */}
      <GroupProductVariationDeleteModal />
    </div>
  );
};

export default GroupProductVariationsBody;
