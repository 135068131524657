/* eslint-disable */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData, postData } from "utils/Gateway";

import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import { ecosystemSlug } from "Config/Config";

const AddEditSkillPointModal = ({
  skillPointIndex,
  setSkillPointIndex,
  skillPointData,
  setSkillPointData,
  skillPointListBlock,
  setSkillPointListBlock,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");

  const [allSkills, setAllSkills] = useState([]);

  //function for get all category
  const getAllSkills = async () => {
    try {
      const parentSlug = "skills";

      const response = await getAllCategories(ecosystemSlug, parentSlug);

      setAllSkills(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for add block
  const addSkillPointHandler = () => {
    setSkillPointListBlock([...skillPointListBlock, skillPointData]);
    closeModalHandler();
  };

  //function for edit education block
  const editSkillpointBlockHandler = () => {
    const updatedSkillPointList = [...skillPointListBlock];
    updatedSkillPointList[skillPointIndex] = skillPointData;
    setSkillPointListBlock(updatedSkillPointList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    setSkillPointData({
      skill: null,
      skillvalue: null,
      skillname: "",
      points: "",
      experienceyear: "",
    });
    setSkillPointIndex(null);
  };

  useEffect(() => {
    getAllSkills();
  }, []);

  return (
    <div
      className="modal fade"
      id="addSkillPointModal"
      tabIndex="-1"
      aria-labelledby="addEducationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          {/* ------ modal head section start ----- */}
          <div className="modal-header p-4 pb-0 border-0">
            {/* ------ modal title start ----- */}
            <h2 className="fw-bold mb-0" id="addEducationModalLabel">
              {t("Save SkillPoint")}
            </h2>
            {/* ------ modal title end ----- */}
            {/* ------ modal close button start ----- */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
            {/* ------ modal close button end ----- */}
          </div>
          {/* ------ modal head section end ----- */}
          {/* ------ modal body section start ----- */}
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ------ skill selection section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="selectTags"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Skill")}
                </label>
                <Select
                  isClearable
                  placeholder={t("Select Skill")}
                  options={allSkills}
                  value={skillPointData.skillvalue}
                  onChange={(val) => {
                    if (val) {
                      setSkillPointData((prevData) => ({
                        ...prevData,
                        skillvalue: val,
                        skill: val.value,
                        skillname: val.label,
                      }));
                    } else {
                      setSkillPointData((prevData) => ({
                        ...prevData,
                        skillvalue: null,
                        skill: null,
                        skillname: "",
                      }));
                    }
                  }}
                />
              </div>
              {/* ------ skill selection section end ----- */}

              {/* ------ skill name section start ----- */}
              {skillPointData.skillvalue ? null : (
                <div className="form-group mb-4">
                  <label
                    htmlFor="university"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Skill Name (* if skill is not in the list)")}
                  </label>
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Skill Name")}
                    value={skillPointData.skillname}
                    onChange={(e) => {
                      setSkillPointData((prevData) => ({
                        ...prevData,
                        skillvalue: null,
                        skill: null,
                        skillname: e.target.value,
                      }));
                    }}
                  />
                </div>
              )}
              {/* ------ skill name section end ----- */}

              {/* ------ point section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="university"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Point (score between 0 - 10)")}
                </label>
                <input
                  type="number"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Point")}
                  max="10"
                  value={skillPointData.points}
                  onChange={(e) => {
                    setSkillPointData((prevData) => ({
                      ...prevData,
                      points: e.target.value,
                    }));
                  }}
                />
              </div>
              {/* ------ point section end ----- */}

              {/* ------ point section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="university"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Total Experience")}
                </label>
                <input
                  type="number"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Total Experience")}
                  value={skillPointData.experienceyear}
                  onChange={(e) => {
                    setSkillPointData((prevData) => ({
                      ...prevData,
                      experienceyear: e.target.value,
                    }));
                  }}
                />
              </div>
              {/* ------ point section end ----- */}
              {/* ------ add/edit button start ----- */}
              <div className="action">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={
                    skillPointIndex != null
                      ? editSkillpointBlockHandler
                      : addSkillPointHandler
                  }
                >
                  {t("Save SkillPoint")}
                </button>
              </div>
              {/* ------ add/edit button end ----- */}
            </form>
          </div>
          {/* ------ modal body section end ----- */}
        </div>
      </div>
    </div>
  );
};
export default AddEditSkillPointModal;
