/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { assetImages } from "constants";

const SuccessBulkTaskModal = ({ bulkSavedTaskList, afterModalClose }) => {
  return (
    <div
      className="modal fade"
      id="afterSaveBulkModal"
      tabIndex="-1"
      aria-labelledby="afterSaveBulkModal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center p-5">
            <img
              src={assetImages.congratulationsIcon}
              alt="You have saved a lead successfuly."
              className="img-fluid mb-2"
            />
            <h2 className="mb-2">Congratulations</h2>
            <p className="fs-lg fw-semibold mb-2">
              Assignation Task execute successfuly.
            </p>

            <div className="table-wrapper">
              <div className="table-responsive mb-5">
                <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                  <thead>
                    <tr>
                      <td className="px-3 border-bottom-0 fw-bold">Task Id</td>
                      <td className="px-3 border-bottom-0 fw-bold">
                        Task Title
                      </td>
                      <td className="px-3 border-bottom-0 fw-bold">
                        Task Assigned?
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {bulkSavedTaskList.map((task, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-3 border-bottom-0">
                            {task.taskid}
                          </td>
                          <td className="px-3 border-bottom-0">
                            {task.tasktitle.slice(0, 30)}
                          </td>
                          <td className="px-3 border-bottom-0">
                            {task.isassigned}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="action d-flex justify-content-center gap-3">
              <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-outline-primary"
                onClick={() => {
                  afterModalClose(true);
                }}
              >
                Close
              </Link>
              {/* <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-primary"
                onClick={() => {
                  afterSaveLeadModalClose("details");
                }}
              >
                Preview Lead Details
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessBulkTaskModal;
