/* eslint-disable */
import { useTranslation } from "react-i18next";

const AddEditEducationModal = ({
  educationIndex,
  setEducationIndex,
  educationData,
  setEducationData,
  educationListBlock,
  setEducationListBlock,
}) => {
  const { t } = useTranslation(); //for translation

  //function for add edcuation block
  const addEducationHandler = () => {
    setEducationListBlock([...educationListBlock, educationData]);
    closeModalHandler();
  };

  //function for edit education block
  const editEducationBlockHandler = () => {
    const updatedEducationList = [...educationListBlock];
    updatedEducationList[educationIndex] = educationData;
    setEducationListBlock(updatedEducationList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    setEducationData({
      degree: "",
      year: "",
      grade: "",
      institute: "",
    });
    setEducationIndex(null);
  };

  return (
    <div
      className="modal fade"
      id="addEducationModal"
      tabIndex="-1"
      aria-labelledby="addEducationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          {/* ------ modal head section start ----- */}
          <div className="modal-header p-4 pb-0 border-0">
            {/* ------ modal title start ----- */}
            <h2 className="fw-bold mb-0" id="addEducationModalLabel">
              {educationIndex != null
                ? t("Update Education")
                : t("Add Education")}
            </h2>
            {/* ------ modal title end ----- */}
            {/* ------ modal close button start ----- */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
            {/* ------ modal close button end ----- */}
          </div>
          {/* ------ modal head section end ----- */}
          {/* ------ modal body section start ----- */}
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ------ degree section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="selectTags"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Degree")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Degree")}
                  value={educationData.degree}
                  onChange={(e) => {
                    setEducationData((prevData) => ({
                      ...prevData,
                      degree: e.target.value,
                    }));
                  }}
                />
              </div>
              {/* ------ degree section end ----- */}
              {/* ------ year and grade section start ----- */}
              <div className="form-group mb-4">
                <div className="row">
                  {/* ------ year section start ----- */}
                  <div className="col-6">
                    <label
                      htmlFor="yeare"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Year")}
                    </label>
                    <input
                      type="number"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Year")}
                      value={educationData.year}
                      onChange={(e) => {
                        setEducationData((prevData) => ({
                          ...prevData,
                          year: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {/* ------ year section end ----- */}
                  {/* ------ grade section start ----- */}
                  <div className="col-6">
                    <label
                      htmlFor="grade"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Grade")}
                    </label>
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Grade")}
                      value={educationData.grade}
                      onChange={(e) => {
                        setEducationData((prevData) => ({
                          ...prevData,
                          grade: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {/* ------ grade section end ----- */}
                </div>
              </div>
              {/* ------ year and grade section end ----- */}
              {/* ------ Institute section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="university"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Institute")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Institute")}
                  value={educationData.institute}
                  onChange={(e) => {
                    setEducationData((prevData) => ({
                      ...prevData,
                      institute: e.target.value,
                    }));
                  }}
                />
              </div>
              {/* ------ Institute section end ----- */}
              {/* ------ add/edit button start ----- */}
              <div className="action">
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={
                    educationIndex != null
                      ? editEducationBlockHandler
                      : addEducationHandler
                  }
                >
                  {educationIndex != null
                    ? t("Update Education")
                    : t("Add Education")}
                </button>
              </div>
              {/* ------ add/edit button end ----- */}
            </form>
          </div>
          {/* ------ modal body section end ----- */}
        </div>
      </div>
    </div>
  );
};
export default AddEditEducationModal;
