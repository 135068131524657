/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
import PaymentListHeader from '../PaymentListHeader/PaymentListHeader';
import { Link } from 'react-router-dom';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import PaymentListFilterPopup from '../Popup/PaymentListFilterPopup';

//import current date
import { getCurrentDateInString } from 'helper/getcurrentdatestring';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import images
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';
//import AddToListModal from "components/Common/Modal/AddToListModal";

const PaymentListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation
  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedPaymentIds, setSelectedPaymentIds] = useState([]);

  const [filterCompanyName, setFilterCompanyName] = useState('');
  const [filterCompanyTypes, setFilterCompanyTypes] = useState([]);
  const [filterCompanyTags, setFilterCompanyTags] = useState([]);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getPaymentList = async () => {
    setRowSelection({});
    setSelectedPaymentIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_MY_PAYMENTS + `?token=${token}`;

      //   if (filterCompanyName != "") {
      //     requestUrl = requestUrl + `&filtername=${filterCompanyName}`;
      //   }

      //   if (filterCompanyTypes.length > 0) {
      //     requestUrl = requestUrl + `&filtertypes=${filterCompanyTypes}`;
      //   }

      //   if (filterCompanyTags.length > 0) {
      //     requestUrl = requestUrl + `&filterlabels=${filterCompanyTags}`;
      //   }

      console.log('request url------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response ------>', response);

      setIsLoading(false);

      if (response.status) {
        setPaymentList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedCompanyIds) {
      try {
        let statusData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_COMPANY +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllCompanyList();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCompanyName('');
    setFilterCompanyTypes([]);
    setFilterCompanyTags([]);
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'user',
        header: t('Payee'),
        Cell: ({ row }) =>
          row.original.user ? (
            <div className="owner border-bottom-0 d-flex gap-1">
              <img
                className="rounded-circle"
                height={30}
                src={
                  row.original.user.photoimage
                    ? url.SERVER_URL + row.original.user.photoimage.path
                    : assetImages.defaultUser
                }
                alt=""
              />
              <p>
                <span className="d-block">
                  {row.original.user.name} {row.original.user.surname}
                </span>
                <span className="d-block">{row.original.user.email}</span>
              </p>
            </div>
          ) : (
            'System Generate'
          ),
      },
      {
        accessorKey: 'invoice',
        header: t('Invoice'),
        Cell: ({ row }) => (
          <p>
            <span className="d-block">ID: {row.original.invoice?.invoiceid??''}</span>
          
              <b>{row.original.invoice?row.original.invoice.plan?'PLAN':'':''} : {row.original.invoice?.plan?.title??''}</b>
           
          </p>
        ),
      },
      {
        accessorKey: 'amount',
        header: t('Amount'),
      },
      {
        accessorKey: 'createdAt',
        header: t('Date'),
        Cell: ({ row }) =>
          row.original.createdAt
            ? getCurrentDateInString(row.original.createdAt, 'dd/mm/yyyy')
            : '',
      },
      {
        accessorKey: 'status',
        header: t('Status'),
        Cell: ({ row }) =>
          row.original.invoice ? (
            <div className="payment border-bottom-0">
              <p className="d-flex text-success align-items-center gap-1">Paid</p>
            </div>
          ) : row.original.status ?(
            <div className="payment border-bottom-0">
              <p className="d-flex text-danger align-items-center gap-1">Failed</p>
            </div>
          ) : (''),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedPaymentIds(selectedIdsArray);
    } else {
      setSelectedPaymentIds([]);
    }
  }, [rowSelection]);

  //   useEffect(() => {
  //     if (
  //       filterCompanyName != "" ||
  //       filterCompanyTypes.length > 0 ||
  //       filterCompanyTags.length > 0
  //     ) {
  //       getAllCompanyList();
  //     }
  //   }, [filterCompanyName, filterCompanyTypes, filterCompanyTags]);

  useEffect(() => {
    getPaymentList();
    getAllTags();
  }, []);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_PAYMENT')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <PaymentListHeader reloadList={getPaymentList} />

            {/* --------- table area --------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={paymentList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area ------------- */}
        {/* -------- add label modal ------- */}
        <AddLabelModal
          selectedIds={selectedPaymentIds}
          moduleName="payment"
          afterTagModalClose={getPaymentList}
        />

        {/* ------- filter popup ------- */}
        {/* <CompanyListFilterPopup
        setFilterCompanyName={setFilterCompanyName}
        setFilterCompanyTypes={setFilterCompanyTypes}
        setFilterCompanyTags={setFilterCompanyTags}
        tagList={tagList}
        companyTypeList={companyTypeList}
      /> */}

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedPaymentIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('payment')}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default PaymentListBody;