import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
import GroupCompaniesData from '../../../../../../data/Groups/GroupCompaniesData.json';
import GroupCompaniesListHeader from '../GroupCompaniesListHeader/GroupCompaniesListHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';

const GroupCompaniesListBody = () => {
  const params = useParams();
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Company Name',
        header: 'Company Name',
        Cell: ({ row }) => (
          <td className="company-name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <p>
                <span className="d-block fs-md fw-semibold">
                  {row.original.company.name}
                </span>
                <span className="d-block"> {row.original.company.lcation}</span>
              </p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'Label',
        header: 'Label',
        Cell: ({ row }) => (
          <td className="label border-bottom-0">
            <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
              {row.original.label.map((item, index) => (
                <li key={index} className="px-2 py-1 gradient-light rounded-90">
                  {item}
                </li>
              ))}
              <li className="px-2 py-1 bg-gray-300 rounded-90">More</li>
            </ul>
          </td>
        ),
      },
      {
        accessorKey: 'ranking',
        header: 'Ranking',
        Cell: ({ row }) => (
          <td className="ranking border-bottom-0">
            <input
              type="text"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '80px' }}
              value={row.original.ranking}
            />
          </td>
        ),
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        Cell: ({ row }) => (
          <td className="owner border-bottom-0">
            <p>
              <span className="d-block">{row.original.owner.name}</span>
              <span className="d-block">{row.original.owner.email}</span>
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        Cell: ({ row }) => (
          <td className="email border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-sm">
                email
              </span>
              <Link to="mailto:customer@safeguru.com">
                {row.original.email}
              </Link>
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        Cell: ({ row }) => (
          <td className="phone border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-sm">
                phone_in_talk
              </span>
              <Link to="tel:+428923089531"> {row.original.phone}</Link>
            </p>
          </td>
        ),
      },
    ],
    []
  );

  // -------- tabs header object --------------------
  const commonHeaderObject = [
    { title: 'Group Info', link: `/admin/group/info/${params.id}` },
    { title: 'Members', link: `/admin/group/members/${params.id}` },
    { title: 'Gallery', link: `/admin/group/gallery/${params.id}` },
    { title: 'Product', link: `/admin/group/product/list/${params.id}` },
    { title: 'Companies', link: `/admin/group/companies/${params.id}` },
    { title: 'Event', link: `/admin/group/events/${params.id}` },
  ];

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white pb-5">
        {/* --- common header --- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption="Companies"
          />
        )}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ----- search / filter section ---- */}
          <GroupCompaniesListHeader />

          {/* ---- table section ----- */}

          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="company-name bg-transparent fs-md fw-semibold border-0"
                      width="30%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllCompanies"
                            name="company"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Company Name</span>
                      </div>
                    </th>
                    <th className="label bg-transparent fs-md fw-semibold border-0">
                      Label
                    </th>
                    <th className="ranking bg-transparent fs-md fw-semibold border-0">
                      Ranking
                    </th>
                    <th className="owner bg-transparent fs-md fw-semibold border-0">
                      Owner
                    </th>
                    <th className="email bg-transparent fs-md fw-semibold border-0">
                      Email
                    </th>
                    <th className="phone bg-transparent fs-md fw-semibold border-0">
                      Phone
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company1"
                            value="company1"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Babcock
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Startup
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Technology
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company2"
                            value="company2"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Aero camaras
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Aviation
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Technology
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company3"
                            value="company3"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Altia
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Startup
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Aviation
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company4"
                            value="company4"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Babcock
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Startup
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Technology
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company5"
                            value="company5"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Aero camaras
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Aviation
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Technology
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company6"
                            value="company6"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Altia
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Startup
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Aviation
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company7"
                            value="company7"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Altia
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Startup
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Aviation
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="company-name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="company"
                            id="company8"
                            value="company8"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            Altia
                          </span>
                          <span className="d-block">
                            Carrer Pedraforca, Spain
                          </span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-company-add.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="label border-bottom-0">
                      <ul className="d-flex flex-wrap gap-2 fs-xs lh-1">
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Startup
                        </li>
                        <li className="px-2 py-1 gradient-light rounded-90">
                          Aviation
                        </li>
                        <li className="px-2 py-1 bg-gray-300 rounded-90">
                          More
                        </li>
                      </ul>
                    </td>
                    <td className="ranking border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="owner border-bottom-0">
                      <p>
                        <span className="d-block">NATS</span>
                        <span className="d-block">(info@nats.com)</span>
                      </p>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          email
                        </span>
                        <Link to="mailto:customer@safeguru.com">
                          customer@safeguru.com
                        </Link>
                      </p>
                    </td>
                    <td className="phone border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-sm">
                          phone_in_talk
                        </span>
                        <Link to="tel:+428923089531">+42 89230 89531</Link>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={GroupCompaniesData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              enableRowActions // show the action column
              renderRowActionMenuItems={({ row }) => [
                <MenuItem
                  key="edit"
                  //onClick={() => console.info(row.id)}
                >
                  <Link className="dropdown-item" to="#">
                    View Details
                  </Link>
                </MenuItem>,
                <MenuItem
                  key="delete"
                  //onClick={() => console.info(row.id)}
                >
                  <Link
                    className="dropdown-item"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addLabelModal"
                  >
                    Add Label
                  </Link>
                </MenuItem>,
                <MenuItem
                  key="delete"
                  //onClick={() => console.info(row.id)}
                >
                  <Link className="dropdown-item" to="#">
                    Dublicate
                  </Link>
                </MenuItem>,
              ]} // action columns menu items
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* ==== popup and modal area ==== */}
      {/* -------- add label modal -------- */}
      <AddLabelModal moduleName="" />
    </div>
  );
};

export default GroupCompaniesListBody;
