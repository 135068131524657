/* eslint-disable */
import Header from "components/Common/Header/Header";
import SaveOpportunityBody from "components/CRMComponents/CrmOpportunity/SaveOpportunities/SaveOpportunityBody";
import React, { useEffect } from "react";

const SaveOpportunities = () => {
  useEffect(() => {
    document.title = "CRM Opportunity";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />
      <SaveOpportunityBody />
    </main>
  );
};

export default SaveOpportunities;
