/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

import languageOptions from "data/Prod/LanguageOptions.json";

//calendar --------------------------------------------
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// -----------------------------------------------

import {
  getData,
  uploadSingleFile,
  uploadMultipleFile,
  postData,
  putData,
  deleteData,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AvailibilityHeader from "../Header/AvailibilityHeader";
import SaveTimeModal from "../Modals/SaveTimeModal";
import AvilibilityOptions from "data/Prod/AvailibilityDaysOptions.json";
import ScheduleWarningModal from "../Modals/ScheduleWarningModal";
import SaveAgendaModal from "../Modals/SaveAgendaModal";
import { appUserHeaderLinks } from "helper/AppUserHelper/AppUserHelper";
import { useTranslation } from "react-i18next";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";

const AppUserAvailibilityBody = () => {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation(); // for translations
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = appUserHeaderLinks(params.id, t);

  const loadingCircle = [1, 2, 3, 4, 5, 6];

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App User"), link: "/admin/appuser/list" },
    { title: t("Availability") },
  ];

  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");

  const [taskDependOnCalender, setTaskDependOnCalender] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [avalibilityDays, setAvalibilityDays] = useState([]);

  const [selectedDayId, setselectedDayId] = useState(null);

  const [scheduleStartDate, setScheduleStartDate] = useState("");
  const [scheduleEndDate, setScheduleEndDate] = useState("");

  const [isDateSpecific, setIsDateSpecific] = useState(false);

  const [agendaListBySpecificDates, setAgendaListBySpecificDates] = useState(
    []
  );

  const [selectedCalendarAgendaDate, setSelectedCalendarAgendaDate] =
    useState("");
  const [selectedAgendaId, setSelectedAgendaId] = useState(null);

  const [calenderAgendaDates, setCalenderAgendaDates] = useState([]);

  //out of field requirements
  const [telephone, setTelephone] = useState("");
  const [position, setPosition] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [website, setWebsite] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    dateDiffWarning: false,
  });

  //* checking validation
  const validationHandler = () => {
    let isValid = true;

    if (scheduleStartDate == "") {
      setValidation((prevState) => ({
        ...prevState,
        startDateWarning: true,
      }));
      isValid = false;
    }

    if (scheduleEndDate == "") {
      setValidation((prevState) => ({
        ...prevState,
        endDateWarning: true,
      }));
      isValid = false;
    }

    if (scheduleStartDate !== "" && scheduleEndDate !== "") {
      if (scheduleStartDate > scheduleEndDate) {
        setValidation((prevState) => ({
          ...prevState,
          dateDiffWarning: true,
        }));
        isValid = false;
      }
    }

    return isValid;
  };

  //* function for get user details
  const getAppUserDetails = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setName(data.name || "");
        setSurName(data.surname || "");

        setTelephone(data.phone ?? "");
        setPosition(data.position ?? "");
        setAddress(data.address || "");
        setWebsite(data.website || "");
        setRate(data.costperhour || "");
        setstateName(data.state || "");
        setcityName(data.city || "");
        setcountry(data.country || "");
        setzipcode(data.zipcode || "");

        setTaskDependOnCalender(data.assigntaskwithcalender || false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for update user
  const changeTaskDependency = async (e) => {
    setTaskDependOnCalender(e.target.checked);
    try {
      const userPublicIp = await getPublicIP();

      let userData = {
        position,
        phone: telephone,
        address,
        city: cityName,
        state: stateName,
        country,
        zipcode,
        costperhour: rate,
        website,

        assigntaskwithcalender: e.target.checked.toString(),
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_APP_USER +
        `/${params.id}` +
        `?token=${token}&userip=${userPublicIp}`;

      const response = await putData(requestUrl, userData);

      // console.log(response);

      if (response.status) {
        getAppUserDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //create new availibility week
  const createWeekAvailibilityHandler = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let availibilityData = {
        avilibilitydays: AvilibilityOptions,
        useraccount: params.id,
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_ADD_NEW_DAYS_AVAILIBILITY +
        `?token=${token}&userip=${userPublicIp}`;

      const response = await postData(requestUrl, availibilityData);

      console.log(response);

      if (response.status) {
        getAllWeekAvailibilitySettings();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all availibility settings
  const getAllWeekAvailibilitySettings = async () => {
    const userPublicIp = await getPublicIP();

    setIsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_DAYS_AVAILIBILITY +
        `?token=${token}&useraccount=${params.id}&userip=${userPublicIp}`;

      const response = await getData(requestUrl);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        if (response.data.length === 0) {
          createWeekAvailibilityHandler();
        } else {
          setAvalibilityDays(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //clear Time FromDay week settings
  const clearTimeFromDayHandler = async () => {
    const userPublicIp = await getPublicIP();

    const checkBoxes = document.querySelectorAll('input[name="daycheckbox"]');

    for (let checkBox of checkBoxes) {
      if (checkBox.checked) {
        const value = checkBox.value;

        const dataTimeIds = JSON.parse(checkBox.getAttribute("data-timeids"));

        if (dataTimeIds.length > 0) {
          for (let dataTimeId of dataTimeIds) {
            if (dataTimeId._id) {
              try {
                let requestUrl =
                  url.API_BASE_URL +
                  url.API_DELETE_TIME_AVAILIBILITY +
                  `/${dataTimeId._id}?token=${token}&profileuserid=${params.id}&userip=${userPublicIp}`;

                await deleteData(requestUrl);
              } catch (error) {
                console.log(error.message);
              }
            }
          }
          // uncheck the selected checkbox
          checkBox.checked = false;
        }
      }
    }
    getAllWeekAvailibilitySettings();
  };

  //add settings to schedule
  const addToScheduleHandler = async () => {
    const userPublicIp = await getPublicIP();

    if (validationHandler()) {
      try {
        let agendaData = {
          startdate: scheduleStartDate,
          enddate: scheduleEndDate,
          useraccount: params.id,
          userip: userPublicIp,
        };

        let requestUrl =
          url.API_BASE_URL + url.API_SETTINGS_TO_AGENDA + `?token=${token}`;

        const response = await postData(requestUrl, agendaData);

        console.log(response);

        setScheduleStartDate("");
        setScheduleEndDate("");
        getAllAgenda();
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset validation
  const resetValidation = () => {
    setValidation({
      startDateWarning: false,
      endDateWarning: false,
      dateDiffWarning: false,
    });
  };

  //get all agenda date specific
  const getAllAgenda = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let useraccountArr = [params.id];

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_AGENDA +
        `?token=${token}&useraccount=${useraccountArr}&userip=${userPublicIp}`;

      console.log("agenda url", requestUrl);

      const response = await getData(requestUrl);

      console.log("agenda records", response);

      if (response.status) {
        setAgendaListBySpecificDates(
          response.data.filter((item) => item.datespecifichours)
        );
        // filter data as per format
        setCalendarEventData(response.data);

        //setCalenderAgendaDates(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // Calendar ---------------------------------------------
  // const events = [
  //   {
  //     id: "A1000",
  //     title: "My Event 0",
  //     start: new Date("2024-01-30T10:45:00"),
  //     end: new Date("2024-01-30T11:00:00"),

  //   },
  //   {
  //     id: "A1001",
  //     title: "My Event 1",
  //     start: new Date("2024-01-30T12:45:00"),
  //     end: new Date("2024-01-30T13:00:00"),

  //   },

  // ];

  const localizer = momentLocalizer(moment);

  // arrange the event data for big calendar
  const setCalendarEventData = (agendaRecords) => {
    let filteredEventData = [];

    if (agendaRecords.length > 0) {
      for (let agenda of agendaRecords) {
        const agendaId = agenda._id;

        //const agendaTitle = agenda.starttime + ' - ' + agenda.endtime;
        const agendaTitle = agenda.description;

        const startAgendaTime = agenda.date + "T" + agenda.starttime + ":00";

        const endAgendaTime = agenda.date + "T" + agenda.endtime + ":00";

        const event = {
          id: agendaId,
          title: agendaTitle,
          start: new Date(startAgendaTime),
          end: new Date(endAgendaTime),
          availability: agenda.availability,
        };

        filteredEventData.push(event);
      }
    }

    console.log("filteredEventData", filteredEventData);

    setCalenderAgendaDates(filteredEventData);
  };

  // set color to event
  const eventStyleGetter = (event) => {
    const backgroundColor = event.availability ? "#05843e" : "#DF3429"; // set green or red
    return { style: { backgroundColor } };
  };

  //function for update agenda
  const onSelectEvent = (event) => {
    setSelectedAgendaId(event.id);

    //show otpconfirm modal
    let myModal = new bootstrap.Modal(
      document.getElementById("saveAccountAgenda")
    );
    myModal.show();
  };

  //create new agenda
  const onSelectSlot = (event) => {
    const dateObj = new Date(event.start);

    // Extract year, month, and day from the date object
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    setSelectedCalendarAgendaDate(formattedDate);

    let myModal = new bootstrap.Modal(
      document.getElementById("saveAccountAgenda")
    );
    myModal.show();
  };
  // ----------------------------------------------------

  useEffect(() => {
    if (params.id) {
      getAppUserDetails();
      getAllWeekAvailibilitySettings();
      getAllAgenda();
    }
  }, [params.id]);

  return (
    <div id="content_wrapper">
      <section className="expert-details-wrapper bg-white pb-5">
        {/* ---- expert details common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Availability")}
          />
        )}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---- bread crumb ------- */}
          <BreadCrumb
            breadCrumbText={breadcrumbText}
            bottom={true}
            displayName={`${name} ${surName}`}
          />

          {/* header */}
          <AvailibilityHeader
            taskDependOnCalender={taskDependOnCalender}
            changeTaskDependency={changeTaskDependency}
          />

          {/* weekly hours settings */}
          <div className="tab-content" id="viewTabContent">
            <div
              className="tab-pane fade show active"
              id="list-view"
              role="tabpanel"
              aria-labelledby="list-view-tab"
              tabIndex="0"
            >
              <div className="px-3 px-lg-4 border border-gray-300 rounded-10 shadow-sm">
                <div className="row gx-lg-5">
                  <div className="col-lg-6 mb-4 py-3 py-lg-4 mb-lg-0 border-end">
                    {/* weekly upper section */}

                    <h5 className="fs-lg fw-semibold mb-2">
                      {" "}
                      {t("Add Weekly hours to my calendar for selected dates")}
                    </h5>

                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className="d-flex gap-2 mb-2"
                    >
                      <div className="form-group d-flex align-items-center gap-2">
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          value={scheduleStartDate}
                          onChange={(e) => {
                            setScheduleStartDate(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              startDateWarning: false,
                              dateDiffWarning: false,
                            }));
                          }}
                        />
                        <span className="d-block fs-sm text-nowrap fw-semibold">
                          To
                        </span>
                        <input
                          type="date"
                          className="form-control fs-sm shadow-none"
                          value={scheduleEndDate}
                          onChange={(e) => {
                            setScheduleEndDate(e.target.value);
                            setValidation((prevState) => ({
                              ...prevState,
                              endDateWarning: false,
                              dateDiffWarning: false,
                            }));
                          }}
                        />
                      </div>
                      <div className="action">
                        <button
                          className="btn btn-primary ms-auto"
                          data-bs-toggle="modal"
                          data-bs-target="#scheduleWarning"
                          onClick={resetValidation}
                        >
                          {t("Add to My Calender")}
                        </button>
                      </div>
                    </form>

                    <div className="d-flex gap-2 mb-2">
                      {validation.startDateWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter start date!")}</span>
                          </p>
                        </div>
                      )}

                      {validation.endDateWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter end date!")}</span>
                          </p>
                        </div>
                      )}

                      {validation.dateDiffWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>
                              {t(
                                "End date should not be less then start date!"
                              )}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center gap-3 justify-content-between mb-3">
                      <h5 className="fs-lg fw-semibold mb-0">
                        {t("Weekly Hours")}
                      </h5>
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fw-semibold text-primary"
                        onClick={clearTimeFromDayHandler}
                      >
                        <span className="d-block material-symbols-outlined fs-lg">
                          delete
                        </span>
                        <span className="d-block">{t("Clear")}</span>
                      </Link>
                    </div>

                    {/* list of days availibility */}
                    <ul className="d-flex flex-column gap-1">
                      {avalibilityDays.map((dayData, index) => {
                        return (
                          <li
                            className="d-flex align-items-start gap-3 body-bg p-3 rounded-10"
                            key={index}
                          >
                            <div className="form-check d-flex align-items-center gap-2 p-0 m-0 cursor-pointer">
                              <input
                                className="form-check-input border-2 border-gray-600 m-0 shadow-none"
                                type="checkbox"
                                id={dayData.slug}
                                name="daycheckbox"
                                value={dayData._id}
                                data-timeids={JSON.stringify(
                                  dayData.timeavailibilityids
                                )}
                              />
                              <label
                                className="form-check-label fw-semibold cursor-pointer"
                                htmlFor="sat"
                              >
                                {dayData.dayname.substring(0, 3).toUpperCase()}
                              </label>
                            </div>

                            {dayData.timeavailibilityids.length === 0 ? (
                              <div className="hours-container d-flex flex-column gap-2 flex-fill">
                                <p className="text-gray">{t("Unavailable")}</p>
                              </div>
                            ) : (
                              <div className="hours-container d-flex flex-column gap-2 flex-fill">
                                {dayData.timeavailibilityids.map(
                                  (timeData, index) => {
                                    return (
                                      <ul
                                        className="times lh-1 d-flex align-items-center gap-2 flex-wrap"
                                        key={index}
                                      >
                                        <li className="px-2 py-1 text-gray border border-gray-300 rounded-90">
                                          {timeData.starttime}
                                        </li>
                                        <li className="text-gray">-</li>
                                        <li className="px-2 py-1 text-gray border border-gray-300 rounded-90">
                                          {timeData.endtime}
                                        </li>
                                      </ul>
                                    );
                                  }
                                )}
                              </div>
                            )}

                            <Link
                              to="#"
                              className="text-gray ms-auto"
                              data-bs-toggle="modal"
                              data-bs-target="#editTimeForDay"
                              onClick={() => {
                                setselectedDayId(dayData._id);
                              }}
                            >
                              {dayData.timeavailibilityids.length === 0 ? (
                                <span className="d-block material-symbols-outlined">
                                  add
                                </span>
                              ) : (
                                <span className="d-block material-symbols-outlined">
                                  edit
                                </span>
                              )}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* specific date  */}
                  <div className="col-lg-6 py-3 py-lg-4">
                    <h5 className="fs-lg fw-semibold mb-0">
                      {t("Date-Specific Hours")}
                    </h5>
                    <p className="fs-sm text-gray">
                      {t(
                        "Override your availability for specific dates when your hours differ from your regular weekly hours."
                      )}
                    </p>
                    <ul className="d-flex flex-column gap-1 my-3">
                      {agendaListBySpecificDates.map((dateData, index) => {
                        return (
                          <li
                            className="d-flex align-items-start gap-3 body-bg p-3 rounded-10"
                            key={index}
                          >
                            <div className="form-check d-flex align-items-center gap-2 p-0 m-0 cursor-pointer">
                              {/* <input
                                className="form-check-input border-2 border-gray-600 m-0 shadow-none"
                                type="checkbox"
                              /> */}
                              <span className="d-block material-symbols-outlined icon-lg">
                                calendar_month
                              </span>
                              <label
                                className="form-check-label fw-semibold cursor-pointer"
                                htmlFor="datelabel"
                              >
                                {dateData.date}
                              </label>
                            </div>
                            <div className="hours-container d-flex flex-column gap-2 flex-fill">
                              <ul className="times lh-1 d-flex align-items-center gap-2 flex-wrap">
                                <li className="px-2 py-1 text-gray border border-gray-300 rounded-90">
                                  {dateData.starttime}
                                </li>
                                <li className="text-gray">-</li>
                                <li className="px-2 py-1 text-gray border border-gray-300 rounded-90">
                                  {dateData.endtime}
                                </li>
                              </ul>
                            </div>
                            <Link
                              to="#"
                              className="text-gray ms-auto"
                              data-bs-toggle="modal"
                              data-bs-target="#saveAccountAgenda"
                              onClick={() => {
                                setSelectedAgendaId(dateData._id);
                                setIsDateSpecific(true);
                              }}
                            >
                              <span className="d-block material-symbols-outlined">
                                edit
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    <Link
                      to="#"
                      className="btn btn-outline-primary d-inline-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#saveAccountAgenda"
                      onClick={() => {
                        setIsDateSpecific(true);
                      }}
                    >
                      <span className="d-block material-symbols-outlined fs-lg">
                        add
                      </span>
                      <span className="d-block">
                        {t("Add date-specific hours")}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* calender/agenda view */}
            <div
              className="tab-pane fade"
              id="calendar-view"
              role="tabpanel"
              aria-labelledby="calendar-view-tab"
              tabIndex="0"
            >
              <div style={{ height: 500 }}>
                <Calendar
                  localizer={localizer}
                  events={calenderAgendaDates} // Make sure you're passing events data
                  startAccessor="start"
                  endAccessor="end"
                  onSelectEvent={onSelectEvent}
                  onSelectSlot={onSelectSlot}
                  selectable
                  style={{ flex: 1 }}
                  eventPropGetter={eventStyleGetter}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* modal */}
      <SaveTimeModal
        afterModalClose={getAllWeekAvailibilitySettings}
        selectedDayId={selectedDayId}
        setselectedDayId={setselectedDayId}
      />
      <ScheduleWarningModal onConfirm={addToScheduleHandler} />

      <SaveAgendaModal
        onModalClose={getAllAgenda}
        selectedAgendaId={selectedAgendaId}
        setSelectedAgendaId={setSelectedAgendaId}
        setIsDateSpecific={setIsDateSpecific}
        isDateSpecific={isDateSpecific}
        selectedCalendarAgendaDate={selectedCalendarAgendaDate}
        setSelectedCalendarAgendaDate={setSelectedCalendarAgendaDate}
      />
    </div>
  );
};

export default AppUserAvailibilityBody;
