import Header from 'components/Common/Header/Header';
import GroupProductVariationsBody from 'components/GroupComponents/GroupDetails/GroupProduct/GroupProductDetails/GroupProductVariations/GroupProductVariationsBody/GroupProductVariationsBody';
import React from 'react';

const GroupProductVariations = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ----------- body section ------------ */}
      <GroupProductVariationsBody />
    </>
  );
};

export default GroupProductVariations;
