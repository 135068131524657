/* eslint-disable */
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

//import images
import { assetImages } from "constants";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData } from "utils/Gateway";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
//import context
import { GlobalProvider } from "context/GlobalContext";
import { ecosystemSlug } from "Config/Config";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";

const Register = () => {
  const {
    createAccountMessage,
    setcreateAccountMessage,
    setuserEmailforOtp,
    setVerifyModuleName,
  } = useContext(GlobalProvider);

  const history = useHistory();
  const { t } = useTranslation(); // translation marker
  /*----- UseState to send username and password for Login ----*/
  const [userName, setuserName] = useState("");
  const [userSurname, setuserSurname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isAgreePolicy, setIsAgreePolicy] = useState(false);
  const [isSubscribeCommercialMail, setIsSubscribeCommercialMail] =
    useState(false);

  const [responseMessage, setResponseMessage] = useState("");

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    firstNameWarning: false,
    lastNameWarning: false,
    emailWarning: false,
    validEmailWarning: false,
    passwordWarning: false,
    agreeWarning: false,
  });
  /* Hook for validation end */

  // validation function
  const formValidation = () => {
    let isValid = true;

    if (userName === "") {
      setValidation((prevState) => ({ ...prevState, firstNameWarning: true }));
      isValid = false;
    }

    if (userSurname === "") {
      setValidation((prevState) => ({ ...prevState, lastNameWarning: true }));
      isValid = false;
    }

    if (userEmail === "") {
      setValidation((prevState) => ({ ...prevState, emailWarning: true }));
      isValid = false;
    } else {
      setValidation((prevState) => ({ ...prevState, emailWarning: false }));
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!mailformat.test(userEmail)) {
        setValidation((prevState) => ({
          ...prevState,
          validEmailWarning: true,
        }));
        isValid = false;
      } else {
        setValidation((prevState) => ({
          ...prevState,
          validEmailWarning: false,
        }));
      }
    }

    if (userPassword === "") {
      setValidation((prevState) => ({ ...prevState, passwordWarning: true }));
      isValid = false;
    }

    if (isAgreePolicy == false) {
      setValidation((prevState) => ({ ...prevState, agreeWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  //function for sign up
  const signupHandler = async () => {
    if (formValidation()) {
      setIsLoading(true);
      const userPublicIp = await getPublicIP();

      try {
        let userData = {
          name: userName,
          surname: userSurname,
          email: userEmail,
          password: userPassword,
          subscribecommercialmail: isSubscribeCommercialMail ? "1" : "0",
          userip: userPublicIp,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_REGISTRATION +
          `?ecosystemslug=${ecosystemSlug}`;

        console.log("request url -->", requestURL);

        const response = await postData(requestURL, userData);

        console.log("response -->", response);

        setIsLoading(false);
        if (response.status) {
          setuserEmailforOtp(userEmail);
          resetRegisterData();

          setVerifyModuleName("OTP");
          history.push("/admin/verifyemail");
        } else {
          setResponseMessage(response.message);
          setVerifyModuleName("");
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  // function for reset the useState containing the email and password text fields
  const resetRegisterData = () => {
    setuserName("");
    setuserSurname("");
    setUserEmail("");
    setUserPassword("");
    setIsAgreePolicy(false);
    setIsSubscribeCommercialMail(false);
    setValidation({
      firstNameWarning: false,
      lastNameWarning: false,
      emailWarning: false,
      validEmailWarning: false,
      passwordWarning: false,
      agreeWarning: false,
    });
    setcreateAccountMessage("");
  };

  return (
    <main id="app">
      <div id="content_wrapper">
        <div className="authentication-wrapper register min-h-screen py-5 d-flex align-items-center justify-content-center">
          <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto">
            <h1 className="h2 text-center">Create Account</h1>
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ------- firstname and lastname section start ------- */}
              <div className="form-group mb-4">
                <div className="row">
                  {/* ------- firstname section start ------- */}
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <label
                      htmlFor="fName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="fName"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter First Name")}
                      value={userName}
                      onChange={(e) => {
                        setValidation((prevState) => ({
                          ...prevState,
                          firstNameWarning: false,
                        }));
                        setuserName(e.target.value);
                        setResponseMessage("");
                      }}
                    />
                    {validation.firstNameWarning && (
                      <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          info
                        </span>
                        <span className="d-block">
                          {" "}
                          {t("Please enter first name")}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* ------- firstname section end ------- */}

                  {/* ------- lastname section start ------- */}
                  <div className="col-lg-6">
                    <label
                      htmlFor="lName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lName"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter Last Name")}
                      value={userSurname}
                      onChange={(e) => {
                        setValidation((prevState) => ({
                          ...prevState,
                          lastNameWarning: false,
                        }));
                        setuserSurname(e.target.value);
                        setResponseMessage("");
                      }}
                    />
                    {validation.lastNameWarning && (
                      <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          info
                        </span>
                        <span className="d-block">
                          {" "}
                          {t("Please enter last name")}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* ------- lastname section end ------- */}
                </div>
              </div>
              {/* ------- firstname and lastname section end ------- */}

              {/* ------- email section start ------- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="email"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter email"
                  value={userEmail}
                  onChange={(e) => {
                    setValidation((prevState) => ({
                      ...prevState,
                      emailWarning: false,
                      validEmailWarning: false,
                    }));
                    setUserEmail(e.target.value);
                    setResponseMessage("");
                  }}
                />
                {/* ------ email warning start ------ */}
                {validation.emailWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block"> {t("Please enter email")}</span>
                  </div>
                )}
                {/* ----- valid email warning ------ */}
                {validation.validEmailWarning && (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">
                      {t("Please enter valid email")}
                    </span>
                  </div>
                )}

                {/* ----- create and signin section end ----- */}
                {responseMessage === "" ? null : (
                  <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                    <span className="d-block material-symbols-outlined icon-md">
                      info
                    </span>
                    <span className="d-block">{responseMessage}</span>
                  </div>
                )}
              </div>
              {/* ------- email section end ------- */}

              {/* ------- new password and confirm passowrd section start ------- */}
              <div className="form-group mb-4">
                <div className="row">
                  {/* ------- new password section start ------- */}
                  <div className="col-lg-12 mb-4 mb-lg-0">
                    <label
                      htmlFor="nPassword"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Password
                    </label>
                    <div className="field-container field-with-icon icon-right position-relative">
                      <input
                        type="Password"
                        id="nPassword"
                        className="form-control fs-sm shadow-none"
                        placeholder="Enter Password"
                        value={userPassword}
                        onChange={(e) => {
                          setValidation((prevState) => ({
                            ...prevState,
                            passwordWarning: false,
                          }));
                          setUserPassword(e.target.value);
                          setResponseMessage("");
                        }}
                      />
                      <span
                        onClick={toggleText}
                        className="d-block material-symbols-outlined icon-sm text-gray position-absolute top-50 end-0 translate-middle-y me-3"
                      >
                        visibility
                      </span>
                    </div>
                    {validation.passwordWarning && (
                      <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          info
                        </span>
                        <span className="d-block">
                          {t("Please enter password")}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* ------- new password section end ------- */}
                </div>
              </div>
              {/* ------- new password and confirm passowrd section end ------- */}

              {/* ------- agree with policy section start ------- */}
              <div className="form-group mb-4">
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                  <input
                    className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                    type="checkbox"
                    id="agree1"
                    checked={isAgreePolicy}
                    onChange={(e) => {
                      setValidation((prevState) => ({
                        ...prevState,
                        agreeWarning: false,
                      }));
                      setIsAgreePolicy(e.target.checked);
                      setResponseMessage("");
                    }}
                  />
                  <label className="form-check-label" htmlFor="agree1">
                    Agree with the{" "}
                    <span className="text-primary">Legal Notice</span> &amp;{" "}
                    <span className="text-primary">Privacy Policy</span>
                  </label>
                  {validation.agreeWarning && (
                    <div className="validation-error d-flex align-items-center gap-1 fs-xs text-primary mt-2">
                      <span className="d-block material-symbols-outlined icon-md">
                        info
                      </span>
                      <span className="d-block">
                        {t(
                          "Please Agree with the Legal Notice and Privacy Policy"
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {/* ------- agree with policy section end ------- */}

              {/* ------- agree with recieve section start ------- */}
              <div className="form-group mb-4">
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0">
                  <input
                    className="form-check-input flex-shrink-0 d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                    type="checkbox"
                    id="agree2"
                    checked={isSubscribeCommercialMail}
                    onChange={(e) => {
                      setIsSubscribeCommercialMail(e.target.checked);
                      setResponseMessage("");
                    }}
                  />
                  <label className="form-check-label" htmlFor="agree2">
                    I agree to receive commercial information that MPFORALL
                    Business & Collaboration considers appropriate to send me by
                    email
                  </label>
                </div>
              </div>
              {/* ------- agree with recieve section end ------- */}

              {/* ------- login link and register button section start ------- */}
              <div className="action d-flex align-items-center gap-3 justify-content-between">
                {/* ------- login link start ------- */}
                {/* <Link to="/" className="text-primary">
                  Sign in
                </Link> */}
                <div className="create_login_outer d-flex align-items-center">
                  <div className="trms_check me-1">
                    <label className="radio-check">
                      <p>{t("Already have an account")} ?</p>
                    </label>
                  </div>
                  <div className="apply_btn">
                    <Link to="/" className="text-primary">
                      {t("Sign in")}
                    </Link>
                  </div>
                </div>
                {/* ------- login link end ------- */}

                {/* ------- register button start ------- */}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={signupHandler}
                  disabled={isLoading ? true : false}
                  style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                >
                  {t("Create Account")}
                  {isLoading && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                {/* ------- register button end ------- */}
              </div>
              {/* ------- login link and register button section end ------- */}
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Register;
