import Header from 'components/Common/Header/Header';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import AddKPIModal from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectKPIComponents/Modal/AddKPIModal';
import ProjectKPIFilterPopup from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectKPIComponents/Popup/ProjectKPIFilterPopup';
import ProjectKPIBody from 'components/ProjectManagementComponents/ProjectDetailsComponents/ProjectKPIComponents/ProjectKPIBody/ProjectKPIBody';
import React, { useEffect } from 'react';

const ProjectKPIs = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = 'Project Management';
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectKPIBody />

      {/* ======= popup and modal section ========= */}
      {/* ------ add label modal -------- */}
      <AddLabelModal moduleName="" />

      {/* ---------- filter popup ---------- */}
      <ProjectKPIFilterPopup />

      {/* ------- add kpi modal -------- */}
      <AddKPIModal />
    </main>
  );
};

export default ProjectKPIs;
