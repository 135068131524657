import React, { useState } from 'react';
import Select from 'react-select';
import GroupCompaniesAddHeader from '../GroupCompaniesAddHeader/GroupCompaniesAddHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const GroupCompaniesAddBody = () => {
  /* ---- bread crumb text ---- */
  const breadcrumbTxt = [
    { title: 'Company', link: '/admin/group/companies' },
    { title: 'Add Company' },
  ];

  // select data --------------------------
  const options = [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white pb-5">
        {/* ---- save now section ---- */}
        <GroupCompaniesAddHeader />
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* --- bread crumb ---- */}
          <BreadCrumb breadCrumbText={breadcrumbTxt} />

          {/* --- form section ---- */}

          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form action="">
              {/* --------------- owner section ------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="owner"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Owner
                </label>
                <input
                  type="text"
                  id="owner"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Name"
                />
              </div>

              {/* ------------- cif / type section -------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  {/* ---------- cif section ------------ */}
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="cif"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      CIF
                    </label>
                    <input
                      type="text"
                      id="cif"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Name"
                    />
                  </div>

                  {/* --------- type section ------------ */}
                  <div className="col-lg-6">
                    <label
                      htmlFor="type"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Type
                    </label>

                    <Select
                      placeholder="Select options"
                      options={options}
                      value={selectOptions}
                      onChange={val => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* ------------- name / section -------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  {/* --------- name section -------------- */}
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="name"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter name"
                    />
                  </div>

                  {/* ----------- email section -------------- */}
                  <div className="col-lg-6">
                    <label
                      htmlFor="email"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
              </div>

              {/* ----------- Website / Establishment Year section ------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  {/* --------------- Website section ----------------*/}
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="website"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Website
                    </label>
                    <input
                      type="url"
                      id="website"
                      className="form-control fs-sm shadow-none"
                      placeholder="www."
                    />
                  </div>
                  {/* ---------------  Establishment Year section ----------------*/}
                  <div className="col-lg-6">
                    <label
                      htmlFor="establishmentYear"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Establishment Year
                    </label>
                    <input
                      type="text"
                      id="establishmentYear"
                      className="form-control fs-sm shadow-none"
                      placeholder="Enter Here"
                    />
                  </div>
                </div>
              </div>

              {/* ------------ Address section ---------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="address"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Address
                </label>
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter location"
                ></textarea>
              </div>

              {/* ------------ Location / Geo Location section -------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  {/* ------------ Location section -------------- */}
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="location"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Location
                    </label>
                    <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                      <Select
                        placeholder="Select options"
                        options={options}
                        value={selectOptions}
                        onChange={val => {
                          setSelectOptions(val);
                        }}
                      />
                      <input
                        type="text"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="State"
                      />
                      <input
                        type="text"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="City"
                      />
                    </div>
                  </div>

                  {/* ------------ Geo Location section -------------- */}
                  <div className="col-lg-6">
                    <label
                      htmlFor="geoLocation"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Geo Location
                    </label>
                    <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                      <input
                        type="text"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Latitude"
                      />
                      <input
                        type="text"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Length"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* ------------ Services section ---------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="services"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Services
                </label>

                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>

              {/* ------------ Resume section ---------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="resume"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Resume
                </label>
                <textarea
                  name="resume"
                  id="resume"
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder="Event Summary"
                ></textarea>
              </div>

              {/* ------------ About section ---------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="about"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  About
                </label>
                <textarea
                  name="about"
                  id="about"
                  cols="30"
                  rows="8"
                  className="form-control fs-sm shadow-none"
                  placeholder="Deatils..."
                ></textarea>
              </div>

              {/* ------------ Upload Logo section ---------------- */}
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="uploadLogo"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Upload Logo
                </label>
                <div className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                  <label className="file d-flex flex-column align-items-center justify-content-center p-3 border rounded-8 position-relative">
                    <img
                      src="/assets/img/altia.png"
                      alt="MyResume.pdf"
                      className="img-fluid mb-2"
                    />
                    <p className="fw-semibold mb-0">altia.jpg</p>
                    <p className="fs-xs">16mb</p>
                    <Link
                      to="#"
                      className="position-absolute top-0 end-0 mt-2 me-2"
                    >
                      <span className="d-block material-symbols-outlined">
                        delete
                      </span>
                    </Link>
                  </label>
                  <label className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer">
                    <span className="d-block material-symbols-outlined text-gray-600">
                      upload
                    </span>
                    <span className="d-block fw-semibold">Upload</span>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input type="file" data-id="upload" className="d-none" />
                  </label>
                </div>
              </div>

              {/* ------------ Agree with the Legal Notice & Privacy Policy section ---------------- */}
              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 b-3 mb-sm-4">
                <input
                  type="checkbox"
                  id="agree"
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                />
                <label className="form-check-label" htmlFor="agree">
                  Agree with the{' '}
                  <Link to="#" className="text-primary">
                    Legal Notice
                  </Link>{' '}
                  &amp;{' '}
                  <Link to="#" className="text-primary">
                    Privacy Policy
                  </Link>
                </label>
              </div>

              {/* ------------ cancel / submit btns ---------------- */}
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link
                  to="/admin/group/companies"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GroupCompaniesAddBody;
