/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { assetImages } from "constants";
/*import url and gateway methods */
import { postData, putData, uploadMultipleFile, getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import downloadFileHandler from "helper/DownloadFile";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

const ViewTaskTab = ({
  selectedTaskId,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token"); // token
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [taskModerator, settaskModerator] = useState(null);
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [taskFollowers, setTaskFollowers] = useState([]);
  const [projectName, setprojectName] = useState("");
  const [projectId, setprojectId] = useState(null);
  const [customId, setCustomId] = useState(null);
  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  const [startDate, setstartDate] = useState(todayValue);
  const [endDate, setendDate] = useState(todayValue);
  const [hours, sethours] = useState("");
  const [price, setPrice] = useState("");
  const [priority, setpriority] = useState("");
  const [taskStatus, settaskStatus] = useState("");
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);

  //function for get project details
  const getTaskDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASK_DETAILS +
        `/${selectedTaskId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in view task details", response);

      if (response.status && response.data) {
        setCustomId(response.data.customid);
        settitle(response.data.title);
        setdetails(response.data.description);
        setUserName(response.data.assignedusername);
        setUserImage(response.data.assigneduserimage);
        setTaskFollowers(response.data.followersarr);
        setprojectName(response.data.projectname);
        setprojectId(response.data.project._id);
        sethours(response.data.hours);
        setPrice(response.data.price ?? 0);
        setstartDate(
          response.data.startdate
            ? getDateFormatInSpanish(response.data.startdate)
            : ""
        );
        setendDate(
          response.data.enddate
            ? getDateFormatInSpanish(response.data.enddate)
            : ""
        );
        setpriority(response.data.taskpriority);
        settaskStatus(response.data.taskstatus);
        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);

        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (selectedTaskId) {
      getTaskDetails();
    }
  }, [selectedTaskId]);

  return (
    <div className="p-4">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group mb-4">
          {/* <!-- ========== Start task id and title Section ========== --> */}
          <div className="user_task_hdng mb-4">
            <h2>
              {customId ? (
                <span className="badge bg-dark me-3">{customId}</span>
              ) : null}
              {title}
            </h2>{" "}
          </div>
          {/* <!-- ========== End task id and title Section ========== --> */}

          {/* <!-- ========== Start other information Section ========== --> */}
          <div className="task_info_innr">
            {/* <!-- ========== Start task assigned user Section ========== --> */}
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Assigned to</label>
              <div className="sign_prsn_outer d-flex align-items-center gap-2 flex-wrap">
                {userName === "" ? null : (
                  <div className="aign_usr_item bg-gray-300 rounded-8 px-2 py-1 d-flex align-items-center gap-1">
                    <span className="rounded-circle d-block usr_img">
                      <img
                        height={22}
                        className="rounded-circle"
                        src={
                          userImage == ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + userImage
                        }
                        alt=""
                      />
                    </span>
                    <span className="d-block">{userName}</span>
                  </div>
                )}
              </div>
            </div>
            {/* <!-- ========== End task assigned user Section ========== --> */}

            {/* <!-- ========== Start task followers Section ========== --> */}
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Follower</label>
              {taskFollowers.length === 0 ? null : (
                <div className="sign_prsn_outer d-flex align-items-center gap-2 flex-wrap">
                  {taskFollowers.map((follower, index) => {
                    return (
                      <div
                        className="aign_usr_item bg-gray-300 rounded-8 px-2 py-1 d-flex align-items-center gap-1"
                        key={index}
                      >
                        <span className="rounded-circle d-block usr_img">
                          <img
                            height={22}
                            className="rounded-circle"
                            src={
                              follower.imagepath == ""
                                ? assetImages.defaultUser
                                : url.SERVER_URL + follower.imagepath
                            }
                            alt=""
                          />
                        </span>
                        <span className="d-block">{follower.label}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {/* <!-- ========== End task followers Section ========== --> */}

            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Project</label>
              <h5 className="mb-0 text-black fs-md">{projectName}</h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Status</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined text-green fs-lg">
                  check_circle
                </i>
                <span className="text-black fs-md">{taskStatus}</span>
              </h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Time(Hrs)</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined text-orange fs-lg">
                  schedule
                </i>
                <span className="mb-0 text-black fs-md">{hours} Hrs</span>
              </h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Deadline</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined fs-lg text-gray">
                  calendar_today
                </i>
                <span className="mb-0 text-black fs-md">
                  {startDate} To {endDate}
                </span>
              </h5>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 mb-2 py-2">
              <label className="fw-semibold">Priority</label>
              <div className="piority_status d-inline-flex align-items-center py-1 px-2 rounded-10 bg-primary text-white fs-xs">
                {priority}
              </div>
            </div>
            <div className="task_info_row d-flex align-items-center gap-1 py-2">
              <label className="fw-semibold">Price (Euro)</label>
              <h5 className="d-flex align-items-center gap-1">
                <i className="material-symbols-outlined text-green fs-lg">
                  euro
                </i>
                <span className="mb-0 text-black fs-md">{price}</span>
              </h5>
            </div>
          </div>
          {/* <!-- ========== End other information Section ========== --> */}
        </div>

        {/* <!-- ========== Start task details Section ========== --> */}
        <div className="form-group mb-4">
          <label htmlFor="Details" className="d-block fs-sm fw-semibold mb-2">
            Details
          </label>
          <div className="details_para">
            <p dangerouslySetInnerHTML={{ __html: details }}></p>
          </div>
        </div>
        {/* <!-- ========== End task details Section ========== --> */}

        {/* <!-- ========== Start uploaded file Section ========== --> */}
        <div className="form-group mb-4">
          <label
            htmlFor="attachments"
            className="d-block fs-sm fw-semibold mb-2"
          >
            Attachments
          </label>
          <ul className="upload-files d-flex flex-wrap gap-3 fs-sm">
            {uploadedDocFiles.map((file, index) => {
              return (
                <li
                  className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                  key={index}
                >
                  <p className="fw-medium">{file.name}</p>
                  <Link to="#" onClick={() => downloadFileHandler(file)}>
                    <span className="d-block material-symbols-outlined">
                      download
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <!-- ========== End uploaded file Section ========== --> */}
      </form>
    </div>
  );
};

export default ViewTaskTab;
