/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import DeleteModal from "../Modal/DeleteModal";
import SaveHiringProcessPopup from "../Popup/SaveHiringProcessPopup";

import {
  challengeHeaderLinks,
  challnegeBreadCrumbText,
} from "helper/ChallengeHelper/ChallengeHelper";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const HiringProcessBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation
  const [title, setTitle] = useState("");

  /* ---- bread crumb text ---- */
  const breadcrumbText = challnegeBreadCrumbText(t("Hiring Process"), title, t);

  // common header object
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  const [isLoading, setisLoading] = useState(false);
  const [processList, setprocessList] = useState([]);

  const [processId, setProcessId] = useState(null);

  //function for get project details
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllJobProcess = async () => {
    try {
      setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_JOB_PROCESS +
        `?token=${token}&challengeid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      setisLoading(false);

      if (response.status) {
        setprocessList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //delete process
  const deleteJobProcessHandler = async () => {
    try {
      let processFormData = {
        status: "0",
      };
      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_JOB_PROCESS +
        `/${processId}?token=${token}`;

      const response = await putData(requestUrl, processFormData);

      if (response.data) {
        setProcessId(null);
        getAllJobProcess();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getJobDetails();
      getAllJobProcess();
    }
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Hiring Process")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- bread crumb ------- */}
            <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} />

            {/* -------- form section ----------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-12 mb-4 mb-lg-0">
                  {processList.map((process, index) => {
                    return (
                      <div
                        className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-4"
                        key={index}
                      >
                        <div className="d-flex gap-3 align-items-center mb-4">
                          <Link to="#">
                            <span className="d-block material-symbols-outlined">
                              drag_indicator
                            </span>
                          </Link>
                          <h3 className="mb-0">{process.title}</h3>
                          <div className="d-flex align-items-center gap-3 ms-auto">
                            <Link
                              to="#"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasHiringProcess"
                              aria-controls="offcanvasHiringProcess"
                              onClick={() => {
                                setProcessId(process._id);
                              }}
                            >
                              <span className="d-block material-symbols-outlined">
                                edit
                              </span>
                            </Link>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModal"
                              aria-controls="deleteModal"
                              onClick={() => {
                                setProcessId(process._id);
                              }}
                            >
                              <span className="d-block material-symbols-outlined">
                                delete
                              </span>
                            </Link>
                          </div>
                        </div>
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="form-group mb-4">
                            <label
                              htmlFor="projectName"
                              className="d-block fs-sm fw-semibold mb-2"
                            >
                              {t("Preferred Location")}
                            </label>
                            <div className="field-container d-flex align-items-center pe-3 body-bg rounded-10">
                              <input
                                type="text"
                                id="projectName"
                                className="form-control fs-sm shadow-none"
                                placeholder={t("Project name")}
                                value={process.location}
                                readOnly
                              />
                              <span className="d-block material-symbols-outlined icon-lg text-gray">
                                my_location
                              </span>
                            </div>
                          </div>

                          <div className="form-group mb-4">
                            <div className="row">
                              <div className="col-lg-6 mb-4 mb-lg-0">
                                <label
                                  htmlFor="preferredLocation"
                                  className="d-block fs-sm fw-semibold mb-2"
                                >
                                  {t("Order")}
                                </label>

                                <input
                                  type="text"
                                  id="preferredLocation"
                                  className="form-control border-0 shadow-none"
                                  placeholder={t("Location")}
                                  value={process.order}
                                  readOnly
                                />
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="date"
                                  className="d-block fs-sm fw-semibold mb-2"
                                >
                                  {t("Dates")}
                                </label>
                                <div className="field-container d-flex flex-column flex-lg-row gap-4 gap-lg-0 body-lg-bg rounded-10">
                                  <input
                                    type="date"
                                    className="form-control fs-sm shadow-none"
                                    placeholder={t("From")}
                                    value={process.startdate}
                                    readOnly
                                  />
                                  <input
                                    type="date"
                                    className="form-control fs-sm shadow-none"
                                    placeholder={t("To")}
                                    readOnly
                                    value={process.enddate}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="accordion mb-4"
                            id="accordionPanelsStayOpenExample"
                          >
                            <div className="accordion-item bg-transparent border-0">
                              <h3 className="accordion-header">
                                <button
                                  className="accordion-button bg-transparent p-0 shadow-none"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#panelsStayOpen-collapse${index}`}
                                  aria-expanded="true"
                                  aria-controls={`panelsStayOpen-collapse${index}`}
                                >
                                  {t("KPIs")}
                                </button>
                              </h3>
                              <div
                                id={`panelsStayOpen-collapse${index}`}
                                className="accordion-collapse collapse show"
                              >
                                <div className="accordion-body p-0 pt-4">
                                  <div className="form-group mb-4">
                                    {process.kpisarr.map((kpis, index2) => {
                                      return (
                                        <div className="row mb-2" key={index2}>
                                          <div className="col-lg-6 mb-4 mb-lg-0">
                                            <label
                                              htmlFor="kpiName"
                                              className="d-block fs-sm fw-semibold mb-2"
                                            >
                                              {t("KPI Name")}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control fs-sm shadow-none"
                                              value={kpis.skillname}
                                              readOnly
                                            />
                                          </div>
                                          <div className="col-lg-6">
                                            <label
                                              htmlFor="points"
                                              className="d-block fs-sm fw-semibold mb-2"
                                            >
                                              {t("Points")}
                                            </label>
                                            <div className="field-container d-flex align-items-center pe-3 body-bg border border-gray-bg rounded-10">
                                              <input
                                                type="text"
                                                className="form-control fs-sm border-0 shadow-none"
                                                value={kpis.skillpoint}
                                                readOnly
                                              />
                                              <p className="fs-sm">
                                                {t("Points")}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    );
                  })}
                  {/* save button */}
                  <div className="action d-flex flex-wrap gap-3 justify-content-between">
                    <Link
                      to="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasHiringProcess"
                      className="btn btn-outline-primary gradient-light d-inline-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined">
                        add
                      </span>
                      <span className="d-block">{t("Add New Process")}</span>
                    </Link>
                    {/* <Link
                  to="#"
                  className="btn btn-primary d-inline-flex align-items-center gap-1"
                >
                  <span className="d-block material-symbols-outlined">
                    save
                  </span>
                  <span className="d-block">Save</span>
                </Link> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <SaveHiringProcessPopup
          processId={processId}
          setProcessId={setProcessId}
          onModalClose={getAllJobProcess}
        />
        {/* ----------------- delete modal -------------- */}
        <DeleteModal
          setProcessId={setProcessId}
          onDelete={deleteJobProcessHandler}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default HiringProcessBody;
