import React from "react";

const JobReviewFeedbackPopup = () => {
  return (
    <div className="review_canvas_left">
      <div
        className="offcanvas offcanvas-start bg-white"
        tabindex="-1"
        id="review_canvas"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title text-black fs-xl fw-semibold"
            id="offcanvasExampleLabel"
          >
            Response Feedback{" "}
          </h5>
          <button
            type="button"
            className="btn-close p-0 rounded-circle"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="review_form white_shadow_bx bg-white py-4 px-3 mb-3 rounded-10">
            <form>
              <div className="form_innr">
                <div className="form-group mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Email Id
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Date of feedback"
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Select a Feedback Options
                  </label>
                  <select name="" className="form-select fs-sm shadow-none">
                    <option>Feedback</option>
                    <option>Lorem Ipsum</option>
                  </select>
                </div>
                <div className="form-group description_fill mb-3">
                  <label className="text-black fw-semibold fs-md mb-2">
                    Add Comments
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Comments"
                  ></textarea>
                </div>
                <div className="form-group">
                  <button className="btn w-100 btn-primary">Send</button>
                </div>
              </div>
            </form>
          </div>
          <div className="review_form white_shadow_bx bg-white py-4 px-3 rounded-10">
            <div className="feedback_usrbx body-bg rounded-16 p-3">
              <div className="feed_usr_row d-flex align-items-center gap-2 mb-2">
                <span className="d-block feed_usr rounded-circle overflow-hidden">
                  <img
                    src="assets/img/user2.png"
                    className="h-100 w-100 object-fit-cover"
                    alt=""
                  />
                </span>
                <h4 className="text-black fw-semibold fs-lg">
                  Pierre Gagnaire
                </h4>
              </div>
              <div className="feed_date d-inline-flex align-items-center gap-2 px-3 py-2 bg-white rounded-90">
                <i className="material-symbols-outlined fs-lg text-gray d-inline-block">
                  calendar_month
                </i>
                <span className="text-black-gray fs-sm d-inline-block">
                  Saturday, 12 January 2022
                </span>
              </div>
            </div>
            <div className="feed_history pt-3 mt-3">
              <h3 className="fs-lg text-black fw-semibold mb-4">
                Feedback History
              </h3>
              <div className="bg-primary rounded-90 px-3 py-2 fs-md fw-semibold text-white mb-3 d-inline-block">
                Sent for feedback 03/06/2024
              </div>
              <div className="validate_info d-flex align-items-center gap-2 mb-3">
                <i className="material-symbols-outlined text-green">
                  check_circle
                </i>
                <span className="d-inline-block fw-semibold fs-md text-black">
                  Valid CV Portfolio
                </span>
              </div>
              <div className="status d-inline-block body-bg rounded-90 fs-md fw-semibold px-3 py-2 text-black-gray">
                Good One
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobReviewFeedbackPopup;
