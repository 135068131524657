/* eslint-disable */
import { useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { putData } from "utils/Gateway";

import {
  getUserBrowser,
  getPublicIP,
  getTimeStamp,
} from "helper/AuthHelper/AuthHelper";

import { ecosystemSlug } from "Config/Config";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { assetImages } from "constants";

const UnSubscribeToMail = () => {
  const params = useParams();

  const [isCancel, setIsCancel] = useState(false);
  const [unSubscribing, setUnSubscribing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);

  const onCancelUnSubscribe = () => {
    setIsCancel(true);
  };

  const unSubsribeHandler = async () => {
    try {
      setUnSubscribing(true);

      let mailData = {
        mailid: params.id,
      };

      let requestURL = url.API_BASE_URL + url.API_UNSUBSCRIBE_TO_MAIL;

      const response = await putData(requestURL, mailData);

      console.log(response);

      setUnSubscribing(false);

      if (response.status) {
        setIsUnsubscribe(true);
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <main id="app">
      <div id="content_wrapper">
        <div className="authentication-wrapper login min-h-screen py-5 d-flex align-items-center justify-content-center">
          {isCancel ? (
            <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto text-center">
              <div className="mail-icon mb-3 text-center">
                <img src={assetImages.unSubsribeMailSvg} alt="" />
              </div>
              <div className="unsubdcribe-text">
                <h3 className="mb-3 fw-bold fs-xl">
                  ¡Gracias por quedarse con nosotros!
                </h3>
              </div>
            </div>
          ) : isUnsubscribe ? (
            <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto text-center">
              <div className="mail-icon mb-3 text-center">
                <img src={assetImages.unSubsribeMailSvg} alt="" />
              </div>
              <div className="unsubdcribe-text">
                <h3 className="mb-3 fw-bold fs-xl">
                  ¡Estamos tristes de verte partir!
                </h3>
                <p className="mb-0 text-gray fs-md">
                  Se te ha dado de baja y ya no tendrás noticias nuestras.
                </p>
              </div>
            </div>
          ) : (
            <div className="authentication-container px-4 py-5 mw-100 bg-white rounded-10 shadow-sm mx-auto text-center">
              <div className="mail-icon mb-3 text-center">
                <img src={assetImages.unSubsribeMailSvg} alt="" />
              </div>

              <div className="unsubdcribe-text">
                <h3 className="mb-3 fw-bold fs-xl">
                  {" "}
                  ¿Estás seguro de que quieres darte de baja?
                </h3>
                <p className="mb-0 text-gray fs-md">
                  Si te das de baja dejarás de recibir nuestra newsletter semanal.{" "}
                </p>
                <div className="unsubscribe-btn mt-4 d-flex align-items-center gap-2 justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={unSubsribeHandler}
                    disabled={unSubscribing ? true : false}
                    style={{
                      cursor: unSubscribing ? "not-allowed" : "pointer",
                    }}
                  >
                    Sí darse de baja
                    {unSubscribing && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  <button
                    className="btn btn-gray"
                    onClick={onCancelUnSubscribe}
                    disabled={unSubscribing ? true : false}
                    style={{
                      cursor: unSubscribing ? "not-allowed" : "pointer",
                    }}
                  >
                    No, cancelar
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default UnSubscribeToMail;
