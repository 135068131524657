import React, { useState } from 'react';
import Select from 'react-select';

import GroupAddEventHeader from '../GroupAddEventHeader/GroupAddEventHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const GroupAddEventBody = () => {
  /* ---- bread crumb text ---- */
  const breadcrumbTxt = [
    { title: 'Group', link: '/admin/group/list' },
    { title: 'Add Event' },
  ];
  // select data --------------------------
  const options = [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div id="content_wrapper">
      <div className="inner-menu-container bg-white shadow-sm">
        <GroupAddEventHeader />

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* --- bread crumb ---- */}
          <BreadCrumb breadCrumbText={breadcrumbTxt} />

          {/* ---- form area ---- */}

          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form action="">
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="type"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Type
                    </label>
                    <Select
                      placeholder="Select options"
                      options={options}
                      value={selectOptions}
                      onChange={val => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                  <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="budget"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Budget
                    </label>
                    <input
                      type="text"
                      className="form-control fs-sm fw-semibold shadow-none"
                      value="$ 25,900"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor="title"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder="Name"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="summary"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Summary
                </label>
                <textarea
                  name="summary"
                  id="summary"
                  cols="30"
                  rows="3"
                  className="form-control fs-sm shadow-none"
                  placeholder="Event Summary"
                ></textarea>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="details"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Details
                </label>
                <textarea
                  name="summary"
                  id="summary"
                  cols="30"
                  rows="3"
                  className="form-control fs-sm shadow-none"
                  placeholder="Deatils..."
                ></textarea>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="virtual"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Virtual
                    </label>
                    <Select
                      placeholder="Select options"
                      options={options}
                      value={selectOptions}
                      onChange={val => {
                        setSelectOptions(val);
                      }}
                    />
                  </div>
                  <div className="col-lg-9">
                    <label
                      htmlFor="location"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Location
                    </label>
                    <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                      <Select
                        placeholder="Select options"
                        options={options}
                        value={selectOptions}
                        onChange={val => {
                          setSelectOptions(val);
                        }}
                      />
                      <input
                        type="text"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="State"
                      />
                      <input
                        type="text"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="City"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="address"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Address
                </label>
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="3"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter location"
                ></textarea>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <div className="row">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <label
                      htmlFor="start"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      Start
                    </label>
                    <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                      <input
                        type="date"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Date"
                      />
                      <input
                        type="time"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Time"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor="end"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      End
                    </label>
                    <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                      <input
                        type="date"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Date"
                      />
                      <input
                        type="time"
                        className="form-control border-0 border-lg-end border-gray-lg-300 rounded-lg-0 shadow-none"
                        placeholder="Time"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="virtualMeetURL"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Virtual Meet URL
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter URL"
                />
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="category"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Category
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="searching"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Searching
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="interest"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Interest
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="documents"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Documents
                </label>
                <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                  <li className="file d-flex flex-column align-items-center justify-content-center p-3 border rounded-8 position-relative">
                    <img
                      src="/assets/img/pdf-icon.svg"
                      alt="MyResume.pdf"
                      className="img-fluid mb-2"
                    />
                    <p className="fw-semibold mb-0">MyResume.pdf</p>
                    <p className="fs-xs">16mb</p>
                    <Link
                      to="#"
                      className="position-absolute top-0 end-0 mt-2 me-2"
                    >
                      <span className="d-block material-symbols-outlined">
                        delete
                      </span>
                    </Link>
                  </li>
                  <li className="file d-flex flex-column align-items-center justify-content-center p-3 border rounded-8 position-relative">
                    <img
                      src="/assets/img/video-icon.svg"
                      alt="Myvideo.mp4"
                      className="img-fluid mb-2"
                    />
                    <p className="fw-semibold mb-0">Myvideo.mp4</p>
                    <p className="fs-xs">16mb</p>
                    <Link
                      to="#"
                      className="position-absolute top-0 end-0 mt-2 me-2"
                    >
                      <span className="d-block material-symbols-outlined">
                        delete
                      </span>
                    </Link>
                  </li>
                  <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                    <Link
                      to="#"
                      data-target="upload"
                      className="upload-file text-center"
                    >
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </Link>
                    <span className="fs-xs">Max 20mb doc/mp4</span>
                    <input type="file" data-id="upload" className="d-none" />
                  </li>
                </ul>
              </div>
              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3">
                <input
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  type="checkbox"
                  id=""
                />
                <label className="form-check-label" htmlFor="">
                  Auto accept joining invitations.
                </label>
              </div>
              <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                <input
                  className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  type="checkbox"
                  id=""
                />
                <label className="form-check-label" htmlFor="">
                  Agree with the <Link to="#">Legal Notice</Link> &amp;{' '}
                  <Link to="#">Privacy Policy</Link>
                </label>
              </div>
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link to="#" className="btn btn-outline-primary">
                  Cancel
                </Link>
                <button
                  type="button"
                  className="btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#saveEventinfo"
                >
                  Save Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupAddEventBody;
