/* eslint-disable */
import { useEffect } from "react";
import Header from "components/Common/Header/Header";
import EventInfoBody from "components/EventComponents/EventDetailsComponents/EventInfoComponents/EventInfoBody/EventInfoBody";

const EventInfo = () => {
  useEffect(() => {
    document.title = "Event Information";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="events" />
      {/* ----- event info body ----- */}
      <EventInfoBody />
    </main>
  );
};

export default EventInfo;
