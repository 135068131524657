/* eslint-disable */
import { useEffect } from "react";
import Header from "components/Common/Header/Header";
import EventMembersBody from "components/EventComponents/EventDetailsComponents/EventMembersComponents/EventMembersBody/EventMembersBody";

const EventMembers = () => {
  useEffect(() => {
    document.title = "Event Members";
  }, []);

  return (
    <>
      <main id="app">
        {/* ---- header start ---- */}
        <Header moduleName="events" />
        {/* --- header end --- */}
        <EventMembersBody />
      </main>
    </>
  );
};

export default EventMembers;
