import React from "react";

import Header from "components/Common/Header/Header";
import RecievedMailList from "components/CRMComponents/CRMContactDetailsComponents/CRMContactDetailsMailComponents/RecievedMail/RecievedMailList/RecievedMailList";

const CrmContactDetailsRecievedMail = () => {
  return (
    <main id="app">
      <Header moduleName="crm" />
      <RecievedMailList />
    </main>
  );
};

export default CrmContactDetailsRecievedMail;
