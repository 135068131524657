import Header from "components/Common/Header/Header";
import CourseListBody from "components/CourseComponents/CourseList/CourseListBody/CourseListBody";
import React, { useEffect } from "react";

const CourseList = () => {
  useEffect(() => {
    document.title = "Courses";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---- body section ---- */}
      <CourseListBody />
    </main>
  );
};

export default CourseList;
