/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

const EventFeedbackHeader = () => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ---- search start ---- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <form action="">
            <div className="form-group d-flex align-items-center flex-row-reverse position-relative">
              <input
                type="text"
                className="form-control ps-2 bg-transparent border-0 shadow-none"
                placeholder="Search by Challenges name"
              />
              <button type="submit" className="p-0 bg-transparent border-0">
                <span className="d-block material-symbols-outlined icon-lg">
                  search
                </span>
              </button>
            </div>
          </form>
        </div>
        {/* ---- search end ---- */}

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          {/* --- action start --- */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabel"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">Add Label</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">Delete</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* --- action end --- */}

          {/* --- settings start --- */}
          <Link
            to="#offcanvasFeedbackSettings"
            className="btn btn-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFeedbackSettings"
            aria-controls="offcanvasFeedbackSettings"
          >
            Settings
          </Link>
          {/* --- settings end --- */}
        </div>
      </div>
    </div>
  );
};

export default EventFeedbackHeader;
