import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";

// Material UI table
import { MaterialReactTable } from "material-react-table";
import { PieChart } from "@mui/x-charts/PieChart";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { formatNumber } from "helper/Common/CommonHelper";
import { assetImages } from "constants";

const DashboardActivityTab = ({
  startDate,
  endDate,
  operationStartDate,
  operationEndDate,
  organizations,
  enclosures,
  sectors,
  eventTypes,
  events,
  channels,
  zones,

  applyFilter,
  setApplyFilter,
  refreshData,
  setRefreshData,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [totalTickets, setTotalTickets] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);

  const [incomeByEvents, setIncomeByEvents] = useState([]);
  const [salesByChannel, setSalesByChannel] = useState([]);
  const [salesByZone, setSalesByZone] = useState([]);

  const [pieDataChannel, setPieDataChannel] = useState([]);
  const [pieDataZone, setPieDataZone] = useState([]);

  // column - income or sales by event
  const columnsIncomeEvent = useMemo(
    () => [
      { accessorKey: "event", header: "Event" },
      {
        accessorKey: "ticketCount",
        header: "Tickets",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "totalIncome",
        header: "Income",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} &euro;</span>
        ),
      },
    ],
    []
  );

  // column - sales by channel
  const columnsSalesByChannel = useMemo(
    () => [
      { accessorKey: "channel", header: "Channel" },
      {
        accessorKey: "ticketCount",
        header: "Tickets",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "totalIncome",
        header: "Income",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} &euro;</span>
        ),
      },
    ],
    []
  );

  // column - sales by channel
  const columnsSalesByZone = useMemo(
    () => [
      { accessorKey: "zone", header: "Zone" },
      {
        accessorKey: "ticketCount",
        header: "Tickets",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "totalIncome",
        header: "Income",
        Cell: ({ renderedCellValue, row }) => (
          <span>{formatNumber(renderedCellValue)} &euro;</span>
        ),
      },
    ],
    []
  );

  // structure the params received from filter in url params
  const getFilterParamsInUrl = () => {
    let urlParams = "";

    if (applyFilter === false) {
      return urlParams;
    }

    if (startDate !== "" && endDate !== "") {
      urlParams += `&filterstartdate=${startDate}&filterenddate=${endDate}`;
    }

    if (operationStartDate !== "" && operationEndDate !== "") {
      urlParams += `&filteropstartdate=${operationStartDate}&filteropenddate=${operationEndDate}`;
    }

    if (organizations && organizations.length > 0) {
      organizations.forEach((data) => {
        urlParams += `&filterorganizations[]=${encodeURIComponent(data)}`;
      });
    }

    if (enclosures && enclosures.length > 0) {
      enclosures.forEach((data) => {
        urlParams += `&filterenclosures[]=${encodeURIComponent(data)}`;
      });
    }

    if (sectors && sectors.length > 0) {
      sectors.forEach((data) => {
        urlParams += `&filtersectors[]=${encodeURIComponent(data)}`;
      });
    }

    if (eventTypes && eventTypes.length > 0) {
      eventTypes.forEach((data) => {
        urlParams += `&filtereventtypes[]=${encodeURIComponent(data)}`;
      });
    }

    if (events && events.length > 0) {
      events.forEach((data) => {
        urlParams += `&filterevent[]=${encodeURIComponent(data)}`;
      });
    }

    if (channels && channels.length > 0) {
      channels.forEach((data) => {
        urlParams += `&filterchannel[]=${encodeURIComponent(data)}`;
      });
    }

    if (zones && zones.length > 0) {
      zones.forEach((data) => {
        urlParams += `&filterzone[]=${encodeURIComponent(data)}`;
      });
    }

    console.log("urlParams", urlParams);

    return urlParams;
  };

  //reset all data
  const resetHandler = () => {
    setApplyFilter(false);
    setTotalTickets(0);
    setTotalIncome(0);
    setIncomeByEvents([]);
    setSalesByChannel([]);
    setSalesByZone([]);
    setPieDataChannel([]);
    setPieDataZone([]);
  };

  // API Request --- get tickets and income ----------------------------
  const getTicketsAndIncome = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_INCOME +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("getTicketsAndIncome", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ticket and income", response);

      if (response.status) {
        setTotalTickets(formatNumber(response.data.ticketCount));

        setTotalIncome(formatNumber(response.data.totalIncome));
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get income by events ----------------------------
  const getIncomeByEvent = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_EVENT_EARNING +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log("income by event", requestUrl);

      const response = await getData(requestUrl);

      console.log("income by event", response);

      if (response.status) {
        setIncomeByEvents(response.data);
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get sales by channel ----------------------------
  const getSalesByChannel = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_CHANNEL_EARNING +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setSalesByChannel(response.data);

        const channelPData = response.data
          .map((item, index) => ({
            id: index,
            value: parseFloat(item.totalIncome),
            label: item.channel ? item.channel.slice(0, 10) : "NULL",
          }))
          .sort((a, b) => b.value - a.value) // Sort by value in descending order
          .slice(0, 5);

        setPieDataChannel(channelPData);
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // API Request --- get sales by zone ----------------------------
  const getSalesByZone = async () => {
    try {
      let urlParams = getFilterParamsInUrl();

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TICKET_ZONE_EARNING +
        `?token=${token}&userrolestring=${userInfo.role.slug}` +
        urlParams;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setSalesByZone(response.data);

        const zonePData = response.data
          .map((item, index) => ({
            id: index,
            value: parseFloat(item.totalIncome),
            label: item.zone ? item.zone.slice(0, 10) : "NULL",
          }))
          .sort((a, b) => b.value - a.value) // Sort by value in descending order
          .slice(0, 5);

        setPieDataZone(zonePData);
      }

      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    }
  };

  // call all api on intial loading
  useEffect(() => {
    console.log("default triggred >>>", applyFilter);
    getTicketsAndIncome();
    getIncomeByEvent();
    getSalesByChannel();
    getSalesByZone();
  }, []);

  // on refresh reload all data
  useEffect(() => {
    console.log("refresh triggred >>>", applyFilter);
    if (refreshData) {
      resetHandler();

      getTicketsAndIncome();
      getIncomeByEvent();
      getSalesByChannel();
      getSalesByZone();

      setRefreshData(false);
    }
  }, [refreshData]);

  // call all api if filter is applied again
  useEffect(() => {
    console.log("applyFilter triggred >>>", applyFilter);

    if (
      applyFilter &&
      (startDate !== "" ||
        operationStartDate !== "" ||
        channels.length > 0 ||
        enclosures.length > 0 ||
        sectors.length > 0 ||
        eventTypes.length > 0 ||
        events.length > 0 ||
        channels.length > 0 ||
        zones.length > 0)
    ) {

      console.log("applyFilter triggred APIU XXXX  >>>", applyFilter);
      // reset all data in dashboard
      //resetHandler();

      getFilterParamsInUrl();

      // call api to get filtered data
      getTicketsAndIncome();
      getIncomeByEvent();
      getSalesByChannel();
      getSalesByZone();
    }

    // set applyfilter false to reset
    if (applyFilter) {
      setApplyFilter(false);
    }

  }, [applyFilter]);

  return (
    <div className="activity-container py-4">
      <div className="row">
        <div className="col-md-12 col-lg-12 mb-4">
          <div className="card-content p-3 h-100 bg-white rounded-10 shadow-sm">
            <div className="head d-flex align-items-start justify-content-between gap-3 mb-4">
              <div className="title col-lg-6">
                <h3 className="mb-0">Tickets</h3>
                <p className="d-flex align-items-center gap-1 mb-1">
                  <span className="d-block text-success fw-semibold">
                    {totalTickets}
                  </span>
                  <span className="d-block fs-xs fw-medium text-gray">
                    Purchases
                  </span>
                </p>
              </div>

              <div className="title col-lg-6">
                <h3 className="mb-0">Income</h3>
                <p className="d-flex align-items-center gap-1 mb-1">
                  <span className="d-block text-success fw-semibold">
                    {totalIncome}
                  </span>
                  <span className="d-block fs-xs fw-medium text-gray">
                    &euro;
                  </span>
                </p>
              </div>
            </div>

            <div className="body">
              <div className="table-responsive">
                <div className="table-wrapper">
                  <MaterialReactTable
                    columns={columnsIncomeEvent} // map columns to be displayed with api data,
                    data={incomeByEvents} // data from api to be displayed
                    enableBottomToolbar={false}
                    enableGlobalFilterModes={true}
                    enablePagination={false}
                    enableRowNumbers={true}
                    enableRowVirtualization={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-4">
          <div className="card-content p-3 h-100 bg-white rounded-10 shadow-sm">
            <div className="head d-flex align-items-center justify-content-between gap-3 mb-4">
              <h3 className="title mb-0">Sales By Channel</h3>
            </div>
            <div className="body">
              <PieChart
                series={[
                  {
                    data: pieDataChannel,
                  },
                ]}
                width={400}
                height={200}
              />
            </div>
            <div className="table-responsive">
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columnsSalesByChannel} // map columns to be displayed with api data,
                  data={salesByChannel} // data from api to be displayed
                  enableBottomToolbar={false}
                  enableGlobalFilterModes={true}
                  enablePagination={false}
                  enableRowNumbers={true}
                  enableRowVirtualization={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 mb-4">
          <div className="card-content p-3 h-100 bg-white rounded-10 shadow-sm">
            <div className="head d-flex align-items-center justify-content-between gap-3 mb-4">
              <h3 className="title mb-0">Sales By Zone</h3>
            </div>
            <div className="body">
              <PieChart
                series={[
                  {
                    data: pieDataZone,
                  },
                ]}
                width={400}
                height={200}
              />
            </div>
            <div className="table-responsive">
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columnsSalesByZone} // map columns to be displayed with api data,
                  data={salesByZone} // data from api to be displayed
                  enableBottomToolbar={false}
                  enableGlobalFilterModes={true}
                  enablePagination={false}
                  enableRowNumbers={true}
                  enableRowVirtualization={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardActivityTab;
