import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import ProjectReportData from '../../../../../data/ProjectManagement/ProjectReportData.json';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import BreadCrumb from 'components/Common/BreadCrumb/BreadCrumb';
import { useParams } from 'react-router-dom';
import ProjectReportGraphBoard from '../ProjectReportGraphBoard/ProjectReportGraphBoard';
import ProjectReportHeader from '../ProjectReportHeader/ProjectReportHeader';

const ProjectReportBody = () => {
  const params = useParams();

  // -------- tabs header object --------------------
  const commonHeaderObject = [
    {
      title: 'Details',
      link: `/admin/projectmanagement/details/${params.id}`,
    },
    { title: 'Members', link: `/admin/projectmanagement/members/${params.id}` },
    { title: 'Tasks', link: `/admin/projectmanagement/tasks/${params.id}` },
    { title: 'Logs', link: `/admin/projectmanagement/logs/${params.id}` },
    { title: 'ODS', link: `/admin/projectmanagement/ods/${params.id}` },
    {
      title: 'Investments',
      link: `/admin/projectmanagement/investment/${params.id}`,
    },
    { title: 'KPIs', link: `/admin/projectmanagement/kpis/${params.id}` },
    { title: 'Report', link: `/admin/projectmanagement/report/${params.id}` },
  ];

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: 'Projects', link: '/admin/projectmanagement/list' },
    { title: 'KPIs' },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Dates',
        Cell: ({ row }) => (
          <td className="dates border-bottom-0">
            <p className="fs-md fw-semibold">{row.original.date}</p>
          </td>
        ),
      },
      {
        accessorKey: 'plannedHrs',
        header: 'Planed Hrs',
        Cell: ({ row }) => (
          <td className="planed-hrs border-bottom-0">
            <p>{row.original.plannedHrs}</p>
          </td>
        ),
      },
      {
        accessorKey: 'accPlan',
        header: 'Acc. Plan',
        Cell: ({ row }) => (
          <td className="acc-plan border-bottom-0">
            <p>{row.original.accPlan}</p>
          </td>
        ),
      },
      {
        accessorKey: 'worked',
        header: 'Worked',
        Cell: ({ row }) => (
          <td className="worked border-bottom-0">
            <p>{row.original.worked}</p>
          </td>
        ),
      },
      {
        accessorKey: 'accWorked',
        header: 'Acc. Worked',
        Cell: ({ row }) => (
          <td className="acc-worked border-bottom-0">
            <p>{row.original.accWorked}</p>
          </td>
        ),
      },
      {
        accessorKey: 'done',
        header: 'Done',
        Cell: ({ row }) => (
          <td className="done border-bottom-0">
            <p>{row.original.done}</p>
          </td>
        ),
      },
      {
        accessorKey: 'accDone',
        header: 'Acc. Done',
        Cell: ({ row }) => (
          <td className="acc-done border-bottom-0">
            <p>{row.original.accDone}</p>
          </td>
        ),
      },
      {
        accessorKey: 'sip',
        header: 'SIP',
        Cell: ({ row }) => (
          <td className="sip border-bottom-0">
            <p>{row.original.sip}</p>
          </td>
        ),
      },
      {
        accessorKey: 'cip',
        header: 'CIP',
        Cell: ({ row }) => (
          <td className="cip border-bottom-0">
            <p>{row.original.cip}</p>
          </td>
        ),
      },
      {
        accessorKey: 'tcpi',
        header: 'TCPI',
        Cell: ({ row }) => (
          <td className="tcip border-bottom-0">
            <p>{row.original.tcpi}</p>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ------ common header -------- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption="Report"
          />
        )}
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ------- bread crumb -------- */}
          <BreadCrumb breadCrumbText={breadCrumbText} bottom={true} />

          {/* ---------- graph board section ---------- */}
          <ProjectReportGraphBoard />

          {/* ----------- graph container section ----------- */}
          <div className="graph-container mb-4">
            <img
              src="/assets/img/graph.png"
              alt="Graph"
              className="img-fluid"
            />
          </div>

          {/* ----------- filter section ----------*/}
          <ProjectReportHeader />

          {/* --------- table section ------------ */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th className="dates bg-transparent fs-md fw-semibold border-0">
                      Dates
                    </th>
                    <th className="planed-hrs bg-transparent fs-md fw-semibold border-0">
                      Planed Hrs
                    </th>
                    <th className="acc-plan bg-transparent fs-md fw-semibold border-0">
                      Acc. Plan
                    </th>
                    <th className="worked bg-transparent fs-md fw-semibold border-0">
                      Worked
                    </th>
                    <th className="acc-worked bg-transparent fs-md fw-semibold border-0">
                      Acc. Worked
                    </th>
                    <th className="done bg-transparent fs-md fw-semibold border-0">
                      Done
                    </th>
                    <th className="acc-done bg-transparent fs-md fw-semibold border-0">
                      Acc. Done
                    </th>
                    <th className="sip bg-transparent fs-md fw-semibold border-0">
                      SIP
                    </th>
                    <th className="cip bg-transparent fs-md fw-semibold border-0">
                      CIP
                    </th>
                    <th className="tcip bg-transparent fs-md fw-semibold border-0">
                      TCPI
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="dates border-bottom-0">
                      <p className="fs-md fw-semibold">Aug 15, 2022</p>
                    </td>
                    <td className="planed-hrs border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="acc-plan border-bottom-0">
                      <p>4</p>
                    </td>
                    <td className="worked border-bottom-0">
                      <p>60</p>
                    </td>
                    <td className="acc-worked border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="acc-done border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="sip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="cip border-bottom-0">
                      <p>0</p>
                    </td>
                    <td className="tcip border-bottom-0">
                      <p>1.02</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={ProjectReportData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectReportBody;
