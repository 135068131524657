/* eslint-disable */
import Header from "components/Common/Header/Header";
import CopyProjectForm from "components/ProjectManagementComponents/ProjectDetailsComponents/CopyProjectComponents/CopyProjectForm";
import React, { useEffect } from "react";

const CopyProject = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <CopyProjectForm />
    </main>
  );
};

export default CopyProject;
