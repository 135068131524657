/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const PreviledgeSuccessModal = ({ afterModalClose }) => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="modal fade"
      id="afterSavePreviledgeModal"
      tabIndex="-1"
      aria-labelledby="afterSavePreviledgeModal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center p-5">
            <img
              src={assetImages.congratulationsIcon}
              alt="You have saved a lead successfuly."
              className="img-fluid mb-2"
            />
            <h2 className="mb-2">{t("Success")}</h2>
            <p className="fs-lg fw-semibold mb-5">
              {t("Privilege saved successfully")}.
            </p>
            <div className="action d-flex justify-content-center gap-3">
              <Link
                to="#"
                data-bs-dismiss="modal"
                className="btn btn-outline-primary"
                onClick={afterModalClose}
              >
                {t("Close")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviledgeSuccessModal;
