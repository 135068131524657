import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import GroupProductData from '../../../../../../data/Groups/GroupProductData.json';
import GroupProductListHeader from '../GroupProductListHeader/GroupProductListHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';

const GroupProductListBody = () => {
  const params = useParams();

  // -------- tabs header object --------------------
  const commonHeaderObject = [
    { title: 'Group Info', link: `/admin/group/info/${params.id}` },
    { title: 'Members', link: `/admin/group/members/${params.id}` },
    { title: 'Gallery', link: `/admin/group/gallery/${params.id}` },
    { title: 'Product', link: `/admin/group/product/list/${params.id}` },
    { title: 'Companies', link: `/admin/group/companies/${params.id}` },
    { title: 'Event', link: `/admin/group/events/${params.id}` },
  ];
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        Cell: ({ row }) => (
          <td className="name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-3">
                <div
                  className="img-container rounded-5 overflow-hidden"
                  style={{ width: '48px', height: '48px' }}
                >
                  <img
                    src={row.original.product.img}
                    alt="Tshirt Full sleevs"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <p className="fs-md fw-semibold">{row.original.product.name}</p>
              </div>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        Cell: ({ row }) => (
          <td className="owner border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '22px', height: '22px' }}
              >
                <img
                  src={row.original.owner.img}
                  alt="Richard Hawail"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{row.original.owner.name}</p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'sold',
        header: '	Sold Unit',
        Cell: ({ row }) => (
          <td className="sold-unit border-bottom-0">
            <p>{row.original.sold}</p>
          </td>
        ),
      },
      {
        accessorKey: 'price',
        header: 'Price',
        Cell: ({ row }) => (
          <td className="price border-bottom-0">
            <p>{row.original.price}</p>
          </td>
        ),
      },
      {
        accessorKey: 'category',
        header: 'Category',
        Cell: ({ row }) => (
          <td className="category border-bottom-0">
            <p>{row.original.category}</p>
          </td>
        ),
      },
      {
        accessorKey: 'stock',
        header: 'Stock',
        Cell: ({ row }) => (
          <td className="stock border-bottom-0">
            <p>{row.original.stock}</p>
          </td>
        ),
      },
      {
        accessorKey: 'tag',
        header: 'Tag',
        Cell: ({ row }) => (
          <td className="tag border-bottom-0">
            <p>{row.original.tag}</p>
          </td>
        ),
      },
      {
        accessorKey: 'created',
        header: 'Created',
        Cell: ({ row }) => (
          <td className="created border-bottom-0">
            <p>{row.original.created}</p>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white pb-5">
        {/* --- common header --- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption="Product"
          />
        )}

        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---------- header area ---------- */}
          <GroupProductListHeader />

          {/* ---------- table section ----------- */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th className="name bg-transparent fs-md fw-semibold border-0">
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllProducts"
                            name="product"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Name</span>
                      </div>
                    </th>
                    <th className="owner bg-transparent fs-md fw-semibold border-0">
                      Owner
                    </th>
                    <th className="sold-unit bg-transparent fs-md fw-semibold border-0">
                      Sold Unit
                    </th>
                    <th className="price bg-transparent fs-md fw-semibold border-0">
                      Price
                    </th>
                    <th className="category bg-transparent fs-md fw-semibold border-0">
                      Category
                    </th>
                    <th className="stock bg-transparent fs-md fw-semibold border-0">
                      Stock
                    </th>
                    <th className="tag bg-transparent fs-md fw-semibold border-0">
                      Tag
                    </th>
                    <th className="created bg-transparent fs-md fw-semibold border-0">
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product1"
                            value="product1"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            style={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product2"
                            value="product2"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product3"
                            value="product3"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product4"
                            value="product4"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product5"
                            value="product5"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product6"
                            value="product6"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product7"
                            value="product7"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product8"
                            value="product8"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product9"
                            value="product9"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="product"
                            id="product10"
                            value="product10"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="img-container rounded-5 overflow-hidden"
                            tyle={{ width: '48px', height: '48px' }}
                          >
                            <img
                              src="/assets/img/product-img.jpg"
                              alt="Tshirt Full sleevs"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fs-md fw-semibold">
                            Tshirt Full sleevs
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="owner border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="sold-unit border-bottom-0">
                      <p>295</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p>$84.06</p>
                    </td>
                    <td className="category border-bottom-0">
                      <p>Clothing</p>
                    </td>
                    <td className="stock border-bottom-0">
                      <p>088</p>
                    </td>
                    <td className="tag border-bottom-0">
                      <p>#tshirt</p>
                    </td>
                    <td className="created border-bottom-0">
                      <p>08/04/2023</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={GroupProductData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* -------- add label modal --------- */}
      <AddLabelModal moduleName="" />
    </div>
  );
};

export default GroupProductListBody;
