/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem, Box, Tooltip, IconButton } from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';

/*------ import url and method ------*/
import * as url from 'helper/UrlHelper';
import { getData, putData } from 'utils/Gateway';

import ProjectListHeader from '../ProjectListHeader/ProjectListHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import ProjectListFilterPopup from '../Popup/ProjectListFilterPopup';
import { assetImages } from 'constants';
import DeleteProjectWarningModal from '../Modal/DeleteProjectWarningModal';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import { useTranslation } from 'react-i18next';
// import AddProjectPopup from "../Popup/AddProjectPopup";

const ProjectListBody = () => {
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation
  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectProjectIds, setSelectProjectIds] = useState([]);

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterTitle, setFilterTitle] = useState('');
  const [filterTags, setFilterTags] = useState([]);
  const [filterMod, setfilterMod] = useState(null);
  const [filterMember, setfilterMember] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get project list
  const getProjectList = async () => {
    setRowSelection({});
    setSelectProjectIds([]);

    try {
      setIsLoading(true);

      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_PROJECTS}?token=${token}&userrolestring=${userInfo.role.slug}`;

      requestUrl +=
        filterDateRange != '' ? `&filterdates=${filterDateRange}` : '';

      if (filterTitle != '') {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }
      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }
      if (filterMod) {
        requestUrl = requestUrl + `&filtermod=${filterMod}`;
      }
      if (filterMember) {
        requestUrl = requestUrl + `&filtermember=${filterMember}`;
      }

      const response = await getData(requestUrl);

      // console.log("projects >>>", response);

      setIsLoading(false);

      if (response.status) {
        setMessageType('success');
        resetFilterData();
        setProjectList(response.data);
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedProjectList = [...projectList];

    const rowIndex = updatedProjectList.findIndex(
      projectData => projectData._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedProjectList[rowIndex].rank = value;
      setProjectList(updatedProjectList);
    }
  };

  //function for update rank
  const updateRankHandler = async e => {
    let responseArr = [];

    //run loop checkbox wise
    for (let selectedId of selectProjectIds) {
      //run loop of updated bloglist
      for (let project of projectList) {
        let projectData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (project._id == selectedId) {
          projectData.rank = project.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_PROJECT +
              `/${selectedId}` +
              `?token=${token}`;

            const response = await putData(requestUrl, projectData);

            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }

    setRowSelection({});
    setSelectProjectIds([]);
  };

  //function for change status
  const changeStatusHandler = async () => {
    if (selectProjectIds.length > 0) {
      let responseArr = [];

      for (let selectedId of selectProjectIds) {
        try {
          let statusData = {
            status: '0',
          };

          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_PROJECT +
            `/${selectedId}` +
            `?token=${token}`;

          const response = await putData(requestUrl, statusData);

          // console.log(response);

          if (response.status) {
            responseArr.push(true);
            setMessageType('success');
            setAlertMessage('Project deleted successfully');
          } else {
            setMessageType('error');
            setAlertMessage(response.message);
          }
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getProjectList();
        }, 1500);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select project for delete');
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setFilterTitle('');
    setFilterTags([]);
    setfilterMod(null);
    setfilterMember(null);
  };

  const onCancelProjectWarning = () => {
    setRowSelection({});
    setSelectProjectIds([]);
  };

  useEffect(() => {
    if (
      filterDateRange != '' ||
      filterTitle != '' ||
      filterTags.length > 0 ||
      filterMod ||
      filterMember
    ) {
      getProjectList();
    }
  }, [filterDateRange, filterTitle, filterMod, filterMember, filterTags]);

  useEffect(() => {
    getProjectList();
    getAllTags();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t('Title'),
      },
      // {
      //   accessorKey: "rank",
      //   header: "Rank",
      //   Cell: ({ row }) => (
      //     <div className="ranking border-bottom-0">
      //       <input
      //         type="text"
      //         className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
      //         style={{ width: "80px" }}
      //         value={row.original.rank}
      //         onChange={(e) => rankInputChangeHandler(row, e.target.value)}
      //       />
      //     </div>
      //   ),
      // },
      {
        accessorKey: 'owner',
        header: t('Owner'),
        Cell: ({ row }) => (
          <div className="owner border-bottom-0 d-flex gap-1">
            <img
              className="rounded-circle"
              height={40}
              width={40}
              src={
                row.original.ownerimage == ''
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.ownerimage
              }
              alt="Babcock"
            />
            <p>
              <span className="d-block">{row.original.owner}</span>
              <span className="d-block">{row.original.owneremail}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'startdate',
        header: t('Start Date'),
      },
      {
        accessorKey: 'enddate',
        header: t('End Date'),
      },

      {
        accessorKey: 'membercount',
        header: t('Members'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [projectList, i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectProjectIds(selectedIdsArray);
    } else {
      setSelectProjectIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_PROJECT')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <ProjectListHeader
              userInfo={userInfo}
              moduleAccess={moduleAccess}
              reloadList={getProjectList}
              changeStatusHandler={changeStatusHandler}
              updateRankHandler={updateRankHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={projectList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      key="view"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/projectmanagement/details/${row.id}`}
                      >
                        {t('View Details')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="copy"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/projectmanagement/copyproject/${row.id}`}
                      >
                        {t('Copy Project')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="member"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/projectmanagement/members/${row.id}`}
                      >
                        {t('View Members')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="task"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/projectmanagement/tasksection/${row.id}`}
                      >
                        {t('View Task')}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="log"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/projectmanagement/logs/${row.id}`}
                      >
                        {t('View Logs')}
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  // renderRowActions={({ row, table }) => (
                  //   <Box sx={{ display: "flex", gap: "1rem" }}>
                  //     <Tooltip arrow placement="left" title="Edit">
                  //       <IconButton onClick={() => table.setEditingRow(row)}>
                  //         <Edit />
                  //       </IconButton>
                  //     </Tooltip>
                  //     <Tooltip arrow placement="right" title="Delete">
                  //       <IconButton
                  //         color="error"
                  //         onClick={() => handleDeleteRow(row)}
                  //       >
                  //         <Delete />
                  //       </IconButton>
                  //     </Tooltip>
                  //   </Box>
                  // )}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ========= popups and modals area ======== */}
        {/* -------- add label modal ---------- */}
        <AddLabelModal
          moduleName="project"
          selectedIds={selectProjectIds}
          afterTagModalClose={() => {
            setTimeout(() => {
              getProjectList();
            }, 2200);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <DeleteProjectWarningModal
          onDelete={changeStatusHandler}
          onCancelDelete={onCancelProjectWarning}
        />

        {/* ---------- filter popup ---------- */}
        <ProjectListFilterPopup
          tagList={tagList}
          setFilterTitle={setFilterTitle}
          setFilterDateRange={setFilterDateRange}
          setFilterTags={setFilterTags}
          setfilterMod={setfilterMod}
          setfilterMember={setfilterMember}
        />

        {/* ---------- add project popup ------------ */}
        {/* <AddProjectPopup /> */}

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectListBody;
