/* eslint-disable */
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import React, { useState } from "react";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { challengeHeaderLinks } from "helper/ChallengeHelper/ChallengeHelper";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const JobSurveyList = () => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation
  // select data --------------------------
  const options = [
    { label: "Option 1", value: "Option 1" },
    { label: "Option 2", value: "Option 2" },
  ];

  // useState to select data ------------------
  const [selectFirstoption, setSelectFirstoption] = useState(null);
  const [selectSecondOption, setSelectSecondOption] = useState(null);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Jobs"), link: "/admin/challenges/list" },
    { title: t("Survey") },
  ];

  // common header object
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Survey")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- bread crumb ------- */}
            <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} />

            {/* -------- form section ----------- */}
            <div className="gradient-light p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-3">
              <h3 className="mb-0">{t("Title")}: Survey 086428</h3>
            </div>
            <div className="survey-container">
              <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-4">
                <h3 className="mb-4">Survey 01</h3>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item bg-transparent border-0">
                    <div className="d-flex align-items-center gap-3">
                      <Link to="#">
                        <span className="d-block material-symbols-outlined">
                          drag_indicator
                        </span>
                      </Link>
                      <h3 className="accordion-header">
                        <button
                          className="accordion-button gap-2 bg-transparent p-0 shadow-none"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          Text Field
                        </button>
                      </h3>
                      <Link to="#" className="ms-auto">
                        <span className="d-block material-symbols-outlined">
                          delete
                        </span>
                      </Link>
                    </div>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="form-group mb-4">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            {t("Question Name")}
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            // value="Message"
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            {t("Info")}{" "}
                            <span className="fw-normal">({t("if any")})</span>
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            placeholder="Enter info"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-4">
                <h3 className="mb-4">Survey 02</h3>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item bg-transparent border-0">
                    <div className="d-flex align-items-center gap-3">
                      <Link to="#">
                        <span className="d-block material-symbols-outlined">
                          drag_indicator
                        </span>
                      </Link>
                      <h3 className="accordion-header">
                        <button
                          className="accordion-button gap-2 bg-transparent p-0 shadow-none"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          Rating
                        </button>
                      </h3>
                      <Link to="#" className="ms-auto">
                        <span className="d-block material-symbols-outlined">
                          delete
                        </span>
                      </Link>
                    </div>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="form-group mb-4">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Question Name
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            // value="Give Your Rating"
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Info <span className="fw-normal">(if any)</span>
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            placeholder="Enter info"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-4">
                <h3 className="mb-4">Survey 03</h3>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item bg-transparent border-0">
                    <div className="d-flex align-items-center gap-3">
                      <Link to="#">
                        <span className="d-block material-symbols-outlined">
                          drag_indicator
                        </span>
                      </Link>
                      <h3 className="accordion-header">
                        <button
                          className="accordion-button gap-2 bg-transparent p-0 shadow-none"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          Multiselect
                        </button>
                      </h3>
                      <Link to="#" className="ms-auto">
                        <span className="d-block material-symbols-outlined">
                          delete
                        </span>
                      </Link>
                    </div>
                    <div
                      id="panelsStayOpen-collapseThree"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="form-group mb-4">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Question Name
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            // value="Select Your Tags"
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Options
                          </label>
                          <Select
                            placeholder="Select options"
                            options={options}
                            value={selectFirstoption}
                            onChange={(val) => {
                              setSelectFirstoption(val);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Info <span className="fw-normal">(if any)</span>
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            placeholder="Enter info"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm mb-4">
                <h3 className="mb-4">Survey 04</h3>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item bg-transparent border-0">
                    <div className="d-flex align-items-center gap-3">
                      <Link to="#">
                        <span className="d-block material-symbols-outlined">
                          drag_indicator
                        </span>
                      </Link>
                      <h3 className="accordion-header">
                        <button
                          className="accordion-button gap-2 bg-transparent p-0 shadow-none"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseFour"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseFour"
                        >
                          Radio
                        </button>
                      </h3>
                      <Link to="#" className="ms-auto">
                        <span className="d-block material-symbols-outlined">
                          delete
                        </span>
                      </Link>
                    </div>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="form-group mb-4">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Question Name
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            // value="Radio Tags"
                          />
                        </div>
                        <div className="form-group mb-4">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Options
                          </label>
                          <Select
                            placeholder="Select options"
                            options={options}
                            value={selectSecondOption}
                            onChange={(val) => {
                              setSelectSecondOption(val);
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                          >
                            Info <span className="fw-normal">(if any)</span>
                          </label>
                          <input
                            type="text"
                            className="form-control fs-sm shadow-none"
                            placeholder="Enter info"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="action d-flex align-items-center gap-3 flex-wrap justify-content-between">
                <Link
                  to="#"
                  className="btn btn-outline-primary d-inline-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addEducationModal"
                >
                  <span className="d-block material-symbols-outlined">add</span>
                  <span className="d-block">{t("Add Another")}</span>
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary d-inline-flex align-items-center gap-1"
                  // data-bs-toggle="modal"
                  // data-bs-target="#addEducationModal"
                >
                  <span className="d-block material-symbols-outlined">
                    save
                  </span>
                  <span className="d-block">{t("Save")}</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default JobSurveyList;
