/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import BlogListBody from "components/BlogComponents/BlogListBody/BlogListBody";

const BlogList = () => {
  useEffect(() => {
    document.title = "Blogs";
  }, []);

  return (
    <main id="app">
      <Header moduleName="blogs" />
      <BlogListBody moduleName="blog" />
    </main>
  );
};

export default BlogList;
