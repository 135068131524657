import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import GroupEventData from '../../../../../../data/Groups/GroupEventData.json';
import { MenuItem } from '@mui/material';
import GroupEventListHeader from '../GroupEventListHeader/GroupEventListHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import EventTypeModal from '../Modals/EventTypeModal';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';

const GroupEventListBody = () => {
  const params = useParams();

  // -------- tabs header object --------------------
  const commonHeaderObject = [
    { title: 'Group Info', link: `/admin/group/info/${params.id}` },
    { title: 'Members', link: `/admin/group/members/${params.id}` },
    { title: 'Gallery', link: `/admin/group/gallery/${params.id}` },
    { title: 'Product', link: `/admin/group/product/list/${params.id}` },
    { title: 'Companies', link: `/admin/group/companies/${params.id}` },
    { title: 'Event', link: `/admin/group/events/${params.id}` },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'Title',
        header: 'Title',
        Cell: ({ row }) => (
          <td className="title border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <p>
                <span className="d-block fs-md fw-semibold">
                  {row.original.title.name}
                </span>
                <span className="d-block">{row.original.title.members}</span>
              </p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'type',
        header: 'Type',
        Cell: ({ row }) => (
          <td className="type border-bottom-0">
            <p>{row.original.type}</p>
          </td>
        ),
      },
      {
        accessorKey: 'rank',
        header: 'Rank',
        Cell: ({ row }) => (
          <td className="rank border-bottom-0">
            <input
              type="text"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '80px' }}
              value={row.original.rank}
            />
          </td>
        ),
      },
      {
        accessorKey: 'Moderator',
        header: 'Moderator',
        Cell: ({ row }) => (
          <td className="moderator border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '22px', height: '22px' }}
              >
                <img
                  src={row.original.moderator.img}
                  alt="Richard Hawail"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{row.original.moderator.name}</p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'startDate',
        header: 'Start Date	',
        Cell: ({ row }) => (
          <td className="start-date border-bottom-0">
            <p>{row.original.startDate}</p>
          </td>
        ),
      },
      {
        accessorKey: 'endDate',
        header: 'End Date	',
        Cell: ({ row }) => (
          <td className="end-date border-bottom-0">
            <p>{row.original.endDate}</p>
          </td>
        ),
      },
      {
        accessorKey: 'place',
        header: 'Place',
        Cell: ({ row }) => (
          <td className="place border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-lg">
                pin_drop
              </span>
              <span>{row.original.place}</span>
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <td className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-1 fw-semibold ${
                row.original.status === 'Ongoing'
                  ? 'text-primary'
                  : 'text-success'
              }`}
            >
              <span className="d-block material-symbols-outlined icon-lg">
                {row.original.status === 'Ongoing'
                  ? 'schedule'
                  : 'check_circle'}
              </span>
              <span>{row.original.status}</span>
            </p>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white pb-5">
        {/* --- common header --- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption="Event"
          />
        )}
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ----- search / filter section ---- */}
          <GroupEventListHeader />

          {/* ---- table area ----- */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="title bg-transparent fs-md fw-semibold border-0"
                      width="30%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllEvents"
                            name="event"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Title</span>
                      </div>
                    </th>
                    <th className="type bg-transparent fs-md fw-semibold border-0">
                      Type
                    </th>
                    <th className="rank bg-transparent fs-md fw-semibold border-0">
                      Rank
                    </th>
                    <th className="moderator bg-transparent fs-md fw-semibold border-0">
                      Moderator
                    </th>
                    <th className="start-date bg-transparent fs-md fw-semibold border-0">
                      Start Date
                    </th>
                    <th className="end-date bg-transparent fs-md fw-semibold border-0">
                      End Date
                    </th>
                    <th className="place bg-transparent fs-md fw-semibold border-0">
                      Place
                    </th>
                    <th className="status bg-transparent fs-md fw-semibold border-0">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event1"
                            value="event1"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-primary">
                        <span className="d-block material-symbols-outlined icon-lg">
                          schedule
                        </span>
                        <span>Ongoing</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event2"
                            value="event2"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-lg">
                          check_circle
                        </span>
                        <span>Completed</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event3"
                            value="event3"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-lg">
                          check_circle
                        </span>
                        <span>Completed</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event4"
                            value="event4"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-lg">
                          check_circle
                        </span>
                        <span>Completed</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event5"
                            value="event5"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-lg">
                          check_circle
                        </span>
                        <span>Completed</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event6"
                            value="event6"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-lg">
                          check_circle
                        </span>
                        <span>Completed</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event7"
                            value="event7"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-lg">
                          check_circle
                        </span>
                        <span>Completed</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="title border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="event"
                            id="event8"
                            value="event8"
                          />
                        </div>
                        <p>
                          <span className="d-block fs-md fw-semibold">
                            The Spanish Line{' '}
                          </span>
                          <span className="d-block">15 Members</span>
                        </p>
                        <div className="dropdown d-flex ms-auto">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="d-block material-symbols-outlined icon-sm">
                              more_vert
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
                            <li>
                              <Link
                                to="group-add-event.html"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  info
                                </span>
                                <span className="d-block">View Details</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                                data-bs-toggle="modal"
                                data-bs-target="#addLabel"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  label
                                </span>
                                <span className="d-block">Add Label</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item d-flex align-items-center gap-2"
                              >
                                <span className="d-block material-symbols-outlined icon-md">
                                  content_copy
                                </span>
                                <span className="d-block">Duplicate</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td className="type border-bottom-0">
                      <p>Networking</p>
                    </td>
                    <td className="rank border-bottom-0">
                      <input
                        type="text"
                        className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                        style={{ width: '80px' }}
                        value="01"
                      />
                    </td>
                    <td className="moderator border-bottom-0">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: '22px', height: '22px' }}
                        >
                          <img
                            src="/assets/img/dp-1.jpg"
                            alt="Richard Hawail"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <p>Tom Hanks</p>
                      </div>
                    </td>
                    <td className="start-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="end-date border-bottom-0">
                      <p>12/4/2021</p>
                    </td>
                    <td className="place border-bottom-0">
                      <p className="d-flex align-items-center gap-1">
                        <span className="d-block material-symbols-outlined icon-lg">
                          pin_drop
                        </span>
                        <span>Madrid, Spain</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-1 fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-lg">
                          check_circle
                        </span>
                        <span>Completed</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={GroupEventData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              enableRowActions // show the action column
              renderRowActionMenuItems={({ row }) => [
                <MenuItem
                  key="edit"
                  //onClick={() => console.info(row.id)}
                >
                  <Link className="dropdown-item" to="#">
                    View Details
                  </Link>
                </MenuItem>,
                <MenuItem
                  key="delete"
                  //onClick={() => console.info(row.id)}
                >
                  <Link className="dropdown-item" to="#">
                    Update
                  </Link>
                </MenuItem>,
              ]} // action columns menu items
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>
      {/* ======== modals and popup area ========= */}
      {/* ----- add label modal ---------- */}
      <AddLabelModal moduleName="" />

      {/* ------- Event type modal ------------ */}
      <EventTypeModal />
    </div>
  );
};

export default GroupEventListBody;
