import React from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const GroupProductAddBody = () => {
  const params = useParams();
  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white pb-5">
        <div className="inner-menu-container bg-white shadow-sm">
          <div className="container-fluid px-lg-5 py-2 py-sm-3">
            <Link
              to={`/admin/group/product/list/${params.id}`}
              className="back-btn d-flex flex-shrink-0 align-items-center justify-content-center body-bg text-gray rounded-circle"
            >
              <span className="d-block material-symbols-outlined">
                arrow_back
              </span>
            </Link>
          </div>
        </div>
        <div className="container-fluid px-lg-5">
          <div className="filter-container py-3">
            <form action="">
              <div className="form-group d-flex align-items-center flex-row-reverse position-relative">
                <input
                  type="text"
                  className="form-control fs-sm ps-2 bg-transparent border-0 shadow-none"
                  placeholder="Search for catalogue"
                />
                <button type="submit" className="p-0 bg-transparent border-0">
                  <span className="d-block material-symbols-outlined icon-lg text-gray">
                    search
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div className="categories-container px-4 border border-gray-300 rounded-10 shadow-sm mb-3">
            <div className="row gx-5">
              <div className="col-lg border-0 border-bottom border-bottom-lg-0 border-lg-end border-solid border-gray-300">
                <ul className="fs-md fw-semibold py-3">
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Apps & Games</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between active"
                    >
                      <span className="d-block">Appliances</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Baby</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Beauty</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Books</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Car & Motorbike</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Clothing & Accessories</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Collectibles</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Computers & Accessories</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Deals</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Electronics</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Furniture</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg border-0 border-bottom border-bottom-lg-0 border-lg-end border-solid border-gray-300">
                <ul className="fs-md fw-semibold py-3">
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Heating & Cooling</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Kitchen & Home Appliances</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between active"
                    >
                      <span className="d-block">Large Appliances</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg border-0 border-bottom border-bottom-lg-0 border-lg-end border-solid border-gray-300">
                <ul className="fs-md fw-semibold py-3">
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Dishwashers</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Dryers</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Microwave Ovens</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Parts & Accessories</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Range Hoods & Chimneys</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Refrigerators</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="text-gray py-2 d-flex align-items-center gap-3 justify-content-between"
                    >
                      <span className="d-block">Washing Machines</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg border-0 border-bottom border-bottom-lg-0 border-lg-end border-solid border-gray-300"></div>
              <div className="col-lg"></div>
            </div>
          </div>
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <Link
              to={`/admin/group/product/list/${params.id}`}
              className="btn btn-outline-primary"
            >
              Cancel
            </Link>
            <Link
              to={`/admin/group/product/vital-info/${params.id}`}
              className="btn btn-primary"
            >
              Next
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GroupProductAddBody;
