import React, { useState } from 'react';
import Select from 'react-select';

const ConfigurationPopup = () => {
  // select data --------------------------
  const options = [
    { label: 'Option 1', value: 'Option 1' },
    { label: 'Option 2', value: 'Option 2' },
  ];

  // useState to select data ------------------
  const [selectOptions, setSelectOptions] = useState(null);
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFeedbackConfiguration"
      aria-labelledby="offcanvasFeedbackConfigurationLabel"
    >
      <div className="offcanvas-header justify-content-start gap-3 p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <h3
          className="offcanvas-title fw-bold"
          id="offcanvasFeedbackConfigurationLabel"
        >
          Feedback Configuration
        </h3>
      </div>
      <div className="offcanvas-body p-4">
        <form action="">
          <div className="form-group mb-3 mb-sm-4">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                <label
                  htmlFor="selectProvider"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Select Provider
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor="selectReceiver"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Select Receiver
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-group mb-3 mb-sm-4">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                <label
                  htmlFor="selectSurvey"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Select Survey
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor="selectModule"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Select Module
                </label>
                <Select
                  placeholder="Select options"
                  options={options}
                  value={selectOptions}
                  onChange={val => {
                    setSelectOptions(val);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary">
              Save Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfigurationPopup;
