/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { ecosystemSlug } from "Config/Config";

const AssignBulkTaskModal = ({
  moduleName,
  selectedTasks,
  afterCloseModalHandler,
  setBulkSavedTaskList,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [userInput, setUserInput] = useState("");
  const [userOptions, setuserOptions] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState("");

  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    userWarning: false,
    taskWarning: false,
  });

  //** Function for Validation */
  const validationHandler = () => {
    let isValid = true;

    if (!userValue) {
      setValidation((prevState) => ({ ...prevState, userWarning: true }));
      isValid = false;
    }

    if (selectedTasks.length <= 0) {
      setValidation((prevState) => ({ ...prevState, taskWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get user details
  const getUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_FOR_TASK +
        `?token=${token}` +
        `&userstring=${userInput}&modulename=${moduleName}`;
      //`&roleslug=${userInfo.role.name}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setuserOptions(response.data);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for get user input
  const userInputHandler = (val) => {
    setUserInput(val);
    setErrorMessage("");
  };

  //function user Selection
  const userSelectionHandler = (val) => {
    // console.log("select val >> ", val);

    setErrorMessage("");
    if (val) {
      setUserValue(val);
      val._id ? setUserId(val._id) : setUserId(null); // if new user or other than contact user email is added
      val.email ? setUserEmail(val.email) : setUserEmail(val.value); // if new user or other than contact, set value as email
    } else {
      setUserValue(null);
      setUserId(null);
      setUserEmail("");
      setuserOptions([]);
    }
  };

  const assignTaskHandler = async () => {
    if (validationHandler()) {
      setIsAdding(true);
      try {
        let assignTaskData = {
          taskids: selectedTasks,
          assigneduser: userId,
        };

        let requestUrl =
          url.API_BASE_URL + url.API_ASSIGN_BULK_TASK + `?token=${token}`;

        const response = await postData(requestUrl, assignTaskData);

        setIsAdding(false);
        // console.log(response);

        if (response.status) {
          setMessageType("success");
          setBulkSavedTaskList(response.data);
          //hide member modal
          let bootstrapModal = document.querySelector("#addAssingTask");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          resetHandler();
          //call member list api function
          // afterCloseModalHandler(true);

          const bootstrapShowModal = new bootstrap.Modal(
            document.getElementById("afterSaveBulkModal")
          );
          bootstrapShowModal.show();
        } else {
          setMessageType("error");
          setErrorMessage(response.message);
        }

        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please fill up required fields");
    }
    setShowAlert(true);
  };

  //function for reset user select
  const resetUserSelect = () => {
    setUserValue(null);
    setuserOptions([]);
  };

  //** Function for reset
  const resetHandler = () => {
    resetUserSelect();
    setUserInput("");
    setUserId(null);
    setUserEmail("");
    setErrorMessage("");
    setValidation({
      userWarning: false,
    });
  };

  useEffect(() => {
    if (userInput.length > 2) {
      getUserList();
    }
  }, [userInput]);

  const optionsToShow = userOptions.length > 0 ? userOptions : [];

  return (
    <div
      className="modal fade"
      id="addAssingTask"
      tabIndex="-1"
      aria-labelledby="addAssingTask"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            {/* <!-- ========== Start modal title  ========== --> */}
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Invite / Add Member")}
            </h2>
            {/* <!-- ========== End modal title  ========== --> */}

            {/* <!-- ========== Start modal close button ========== --> */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                resetHandler();
                afterCloseModalHandler(false);
              }}
            ></button>
            {/* <!-- ========== End modal close button ========== --> */}
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="memberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Assign app users / contact (search by name, email)")}
                </label>
                <div className="d-flex align-items-center">
                  <CreatableSelect
                    isClearable
                    className="w-100"
                    placeholder={t("Search by name, email")}
                    options={optionsToShow}
                    value={userValue}
                    onChange={(val) => {
                      userSelectionHandler(val);
                      setValidation((prevState) => ({
                        ...prevState,
                        userWarning: false,
                      }));
                      setErrorMessage("");
                    }}
                    onInputChange={(val) => {
                      userInputHandler(val);
                      setValidation((prevState) => ({
                        ...prevState,
                        userWarning: false,
                      }));
                      setErrorMessage("");
                    }}
                  />
                </div>
                {/* <!-- ========== Start user validation Section ========== --> */}
                {validation.userWarning && (
                  <p className="text-danger fw-semibold">
                    * {t("Please type or select user name or email")}
                  </p>
                )}
                {/* <!-- ========== End user validation Section ========== --> */}

                {/* <!-- ========== Start task warning Section ========== --> */}
                {validation.taskWarning && (
                  <p className="text-danger fw-semibold">
                    * {t("Please select tasks")}
                  </p>
                )}
                {/* <!-- ========== End task warning Section ========== --> */}
              </div>
              {/* ----- user select section end ----- */}

              {/* <!-- ========== Start button and error message Section ========== --> */}
              <div className="action d-flex align-items-center gap-3">
                {/* <!-- ========== Start cancel modal button ========== --> */}
                <button
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("Cancel")}
                </button>
                {/* <!-- ========== End cancel modal button ========== --> */}

                {/* <!-- ========== Start error message Section ========== --> */}
                {errorMessage == "" ? null : (
                  <p className="text-danger fw-semibold">* {errorMessage}</p>
                )}
                {/* <!-- ========== End error message Section ========== --> */}

                {/* <!-- ========== Start save button Section ========== --> */}
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={assignTaskHandler}
                  disabled={isAdding ? true : false}
                  style={{
                    cursor: isAdding ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Assign Tasks")}
                  {isAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                {/* <!-- ========== End save button Section ========== --> */}
              </div>
              {/* <!-- ========== End button and error message Section ========== --> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignBulkTaskModal;
