/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const CommonTabs = ({ activeTab }) => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid d-flex align-items-center gap-2 gap-sm-4 px-lg-5">
        <Link
          to={`/admin/course/lessons/list/${params.id}`}
          className="back-btn d-flex flex-shrink-0 align-items-center justify-content-center body-bg text-gray rounded-circle"
        >
          <span className="d-block material-symbols-outlined">arrow_back</span>
        </Link>
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-lg-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="nav-item">
            <Link
              to={`/admin/course/lessons/content/${params.id}/${params.lessonid}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === 'content' && 'active'
              } `}
            >
              {t('Content')}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/course/lessons/task/${params.id}/${params.lessonid}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === 'task' && 'active'
              } `}
            >
              {t('Task')}
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to={`/admin/course/lessons/faq/${params.id}/${params.lessonid}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === "faq" && "active"
              } `}
            >
              {t("FAQ")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/admin/course/lessons/customer-queries/${params.id}/${params.lessonid}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                activeTab === "customerQueries" && "active"
              } `}
            >
              {t("Customer Queries")}
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default CommonTabs;
