/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";

const ActionLogFilterPopup = ({ setFilterDateRange }) => {
  const { t } = useTranslation(); //for translation
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setFilterDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setFilterDateRange(fromDate);
    }

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#offcanvasActionLogFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    setfromDate("");
    settoDate("");
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasActionLogFilter"
      aria-labelledby="offcanvasActionLogFilter"
    >
      {/* -------- select filter ---------- */}
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          {/* <!-- ========== Start date Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Dates" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From date")}
                  value={fromDate}
                  onChange={(e) => setfromDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To date")}
                  value={toDate}
                  onChange={(e) => settoDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* <!-- ========== End date Section ========== --> */}

          {/* --------- save filter / apply filter ------------ */}
          <div className="action d-flex gap-2 mt-4">
            {/* <Link
              to="#"
              className="btn btn-outline-primary flex-fill"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Save Filter
            </Link> */}
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Search")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActionLogFilterPopup;
