/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import CourseLessonsHeader from '../CourseLessonsHeader/CourseLessonsHeader';
import CourseLessonsFilterPopup from '../Popup/CourseLessonsFilterPopup';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import { courseDetailsHeaderObject } from 'helper/CourseHelper/CourseHelper';
import { MenuItem } from '@mui/material';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';

const CourseLessonBody = () => {
  const params = useParams();

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const { t, i18n } = useTranslation(); //for translation

  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  const [isLoading, setIsLoading] = useState(false);
  const [lessonList, setlessonList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedLessonIds, setselectedLessonIds] = useState([]);

  const [title, settitle] = useState('');

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  // get course details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage('error');
      setAlertMessage(error.message);
    }
  };

  //get course list
  const getAllCourseLessons = async () => {
    setRowSelection({});
    setselectedLessonIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_LESSONS +
        `?token=${token}&courseid=${params.id}`;

      const response = await getData(requestURL);

      // console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType('success');
        setlessonList(response.data);
      } else {
        setAlertMessage('error');
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType('error');
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for delete course
  const deleteMassiveLessonHandler = async () => {
    if (selectedLessonIds.length > 0) {
      const lessonListToBeDelete = lessonList.filter(item =>
        selectedLessonIds.includes(item._id)
      );

      try {
        let lessonData = {
          lessonlist: lessonListToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_COURSE_LESSONS +
          `?token=${token}`;

        const response = await putData(requestURL, lessonData);

        console.log(response);

        if (response.status) {
          setMessageType('success');
          setTimeout(() => {
            getAllCourseLessons();
          }, 2500);
        } else {
          setMessageType('error');
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
      }

      setShowAlert(true);
    } else {
      setMessageType('error');
      setAlertMessage('Please select atleast one course');
      setShowAlert(true);
    }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedLessonList = [...lessonList];

    const rowIndex = updatedLessonList.findIndex(
      lesson => lesson._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedLessonList[rowIndex].rank = value;
      setlessonList(updatedLessonList);
    }
  };

  //function for update rank
  const updateBulkLessonHandler = async e => {
    if (selectedLessonIds.length > 0) {
      try {
        const lessonListToBeUpdate = lessonList.filter(item =>
          selectedLessonIds.includes(item._id)
        );

        let lessonData = {
          lessonlist: lessonListToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_MASSIVE_COURSE_LESSONS +
          `?token=${token}`;

        const response = await putData(requestURL, lessonData);

        console.log(response);

        if (response.status) {
          setMessageType('success');

          setTimeout(() => {
            getAllCourseLessons();
          }, 2500);
        } else {
          setMessageType('error');
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select atleast one course');
    }
    setShowAlert(true);
  };

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
      getAllCourseLessons();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t('Title'),
      },
      {
        accessorKey: 'chaptername',
        header: t('Chapter'),
        size: 200,
      },
      {
        accessorKey: 'rank',
        header: t('Rank'),
        Cell: ({ row }) => (
          <div className="ranking border-bottom-0">
            {row.original.coursemoderator.toString() ===
              userInfo._id.toString() ||
            userInfo.role.slug === 'ADMIN' ||
            userInfo.role.slug === 'SUPER_ADMIN' ? (
              <input
                type="number"
                className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                style={{ width: '100px' }}
                value={row.original.rank}
                onChange={e => rankInputChangeHandler(row, e.target.value)}
              />
            ) : (
              <p className="ps-3">{row.original.rank}</p>
            )}
          </div>
        ),
      },
      {
        accessorKey: 'passingscore',
        header: t('Passing Score'),
      },
      {
        accessorKey: 'membernames',
        header: t('Member'),
      },
      {
        accessorKey: 'createddate',
        header: t('Date'),
      },
    ],
    [lessonList, i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setselectedLessonIds(selectedIdsArray);
    } else {
      setselectedLessonIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_COURSE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t('Lessons')}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter  section ----------- */}
            <CourseLessonsHeader
              title={title}
              reloadList={getAllCourseLessons}
              updateRankHandler={updateBulkLessonHandler}
              deleteHandler={deleteMassiveLessonHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={lessonList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/lessons/content/${params.id}/${row.id}`}
                      >
                        {t('View Details')}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="task">
                      <Link
                        className="dropdown-item"
                        to={`/admin/course/lessons/task/${params.id}/${row.id}`}
                      >
                        {t('View Task')}
                      </Link>
                    </MenuItem>,
                    // <MenuItem key="faq">
                    //   <Link
                    //     className="dropdown-item"
                    //     to={`/admin/course/lessons/faq/${params.id}/${row.id}`}
                    //   >
                    //     {t('View Faq')}
                    //   </Link>
                    // </MenuItem>,
                    // <MenuItem key="customer-queries">
                    //   <Link
                    //     className="dropdown-item"
                    //     to={`/admin/course/lessons/customer-queries/${params.id}/${row.id}`}
                    //   >
                    //     {t('View Customer-Queries')}
                    //   </Link>
                    // </MenuItem>,
                  ]} // action columns menu items
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ======== popups and modal section =========== */}
        {/* --------- add label modal ------------ */}
        <AddLabelModal moduleName="" />

        {/* ---------- filter popup ------------ */}
        <CourseLessonsFilterPopup />

        {/* --------- alert popup ------------ */}
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseLessonBody;
