/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
import CRMListHeader from '../CRMListHeader/CRMListHeader';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import ListFilterPopup from '../Popup/ListFilterPopup';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import SaveListPopup from '../Popup/SaveListPopup';
import { crmCommonHeaderLinkLists } from 'helper/CrmHelper/CrmHelper';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const CRMListBody = () => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: 'CRM', link: '/admin/crm/list' },
    { title: t('List') },
  ];

  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [listTypeOptions, setlistTypeOptions] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allList, setAllList] = useState([]);
  const [selectedListIds, setselectedListIds] = useState([]);
  const [selectedListId, setselectedListId] = useState(null);
  const [filterTitle, setfilterTitle] = useState('');
  const [filterTypes, setfilterTypes] = useState([]);
  const [filterTags, setfilterTags] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all list type
  const getAllListType = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_LIST_TYPE + `?token=${token}`;
      const response = await getData(requestURL);
      // console.log(response);

      if (response.status) {
        setlistTypeOptions(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;
      const response = await getData(requestUrl);
      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
      setShowAlert(true);
    }
  };

  //get all lsit
  const getAllList = async () => {
    setselectedListIds([]);
    setRowSelection({});

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_LIST + `?token=${token}`;

      if (userInfo.role) {
        requestUrl = requestUrl + `&roleslug=${userInfo.role.slug}`;
      }

      if (filterTitle != '') {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }

      if (filterTypes.length > 0) {
        requestUrl = requestUrl + `&filtertypes=${filterTypes}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      setIsLoading(false);

      if (response.status) {
        resetFilterData();
        setAllList(response.data);
        setMessageType('success');
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    if (selectedListIds.length > 0) {
      let responseArr = [];

      for (let selectedId of selectedListIds) {
        try {
          let statusData = {
            status: '0',
          };

          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_A_LIST +
            `/${selectedId}` +
            `?token=${token}`;

          const response = await putData(requestUrl, statusData);

          if (response.status) {
            setMessageType('success');
            responseArr.push(true);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllList();
        }, 2000);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select data for delete');
      setShowAlert(true);
    }
  };

  //** function for reset filter
  const resetFilterData = () => {
    setfilterTitle('');
    setfilterTypes([]);
    setfilterTags([]);
  };

  useEffect(() => {
    if (filterTitle != '' || filterTags.length > 0 || filterTypes.length > 0) {
      getAllList();
    }
  }, [filterTitle, filterTypes, filterTags]);

  useEffect(() => {
    getAllList();
    getAllListType();
    getAllTags();
  }, []);

  //** Material React Table Column and States
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSaveList"
            aria-controls="offcanvasSaveList"
            onClick={() => {
              setselectedListId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'title',
        header: t('Title'),
      },
      {
        accessorKey: 'listtypename',
        header: t('Type'),
      },
      {
        accessorKey: 'moderatorname',
        header: t('Moderator'),
        Cell: ({ row }) => (
          <div className="owner border-bottom-0 d-flex gap-1">
            <img
              className="rounded-circle"
              height={40}
              width={45}
              src={
                row.original.moderatorimage == ''
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.moderatorimage
              }
              alt="Babcock"
            />
            <p>
              <span className="d-block fw-bold">
                {row.original.moderatorname}
              </span>
              <span className="d-block">{row.original.moderatoremail}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: '_id',
        header: t('No of records'),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              {row.original.listtypename == 'Company' ? (
                <p className="">
                  {row.original.companycount > 0
                    ? row.original.companycount
                    : '0'}
                </p>
              ) : row.original.listtypename == 'Contact' ? (
                <p className="">
                  {row.original.contactcount > 0
                    ? row.original.contactcount
                    : '0'}
                </p>
              ) : row.original.listtypename === 'Lead' ? (
                <p className="">
                  {row.original.leadcount > 0 ? row.original.leadcount : '0'}
                </p>
              ) : (
                <p>0</p>
              )}
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setselectedListIds(selectedIdsArray);
    } else {
      setselectedListIds([]);
    }
  }, [rowSelection]);

  //* This is a function that is called when the alert close button is clicked.
  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    (moduleAccess.includes('MOD_CRM') && moduleAccess.includes('MOD_LIST'))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('List')}
          />
          <div className="container-fluid px-lg-5">
            <CRMListHeader
              breadcrumbText={breadcrumbText}
              reloadList={getAllList}
            />
            {/* ----------- table section ----------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={allList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 200,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== modals and popup section ==== */}
        <ListFilterPopup
          tagList={tagList}
          listTypeOptions={listTypeOptions}
          setfilterTitle={setfilterTitle}
          setfilterTypes={setfilterTypes}
          setfilterTags={setfilterTags}
        />

        {/* --- add new list --- */}
        <SaveListPopup
          listTypeOptions={listTypeOptions}
          afterSave={() => {
            setTimeout(() => {
              getAllList();
            }, 2200);
          }}
          listId={selectedListId}
          resetListId={() => {
            setselectedListId(null);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* add label modal */}
        <AddLabelModal
          moduleName="list"
          selectedIds={selectedListIds}
          afterTagModalClose={getAllList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />
        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setselectedListIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('list')}
        />
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default CRMListBody;
