//function common header links
const groupDetailsHeaderLinks = (id, t) => {
  return [
    { title: t("Group Info"), link: `/admin/group/info/${id}` },
    { title: t("Members"), link: `/admin/group/members/${id}` },
    { title: t("Gallery"), link: `/admin/group/gallery/${id}` },
    // { title: "Feedback", link: `/admin/events/feedback/${id}` },
  ];
};

export { groupDetailsHeaderLinks };
