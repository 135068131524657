/* eslint-disable */
import axios from "axios";

//get method
const getData = (url = "") => {
  return request("get", url);
};

//post method
const postData = (url = "", inputData = {}) => {
  return request("post", url, inputData);
};

//put/update method
const putData = (url = "", inputData = {}) => {
  return request("put", url, inputData);
};

//delete method
const deleteData = (url = "") => {
  return request("delete", url);
};

//common method
const request = async (method, url = "", params = {}) => {
  try {
    const { data } = await axios[method](url, params);
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

//upload single file method
const uploadSingleFile = async (url, formValues = {}) => {
  try {
    const formData = new FormData();

    formData.append("upload", formValues);

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

//upload multiple file method
const uploadMultipleFile = async (url, formValues = []) => {
  try {
    const formData = new FormData();

    let objArray = Object.keys(formValues);

    objArray.forEach((element) => {
      formData.append("uploads", formValues[element]);
    });

    const { data } = await axios.post(url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

const uploadFormData = async (url, formValues = {}, method = "post") => {
  try {
    const formData = new FormData();

    let objArray = Object.keys(formValues);

    objArray.forEach((element) => {
      formData.append(element, formValues[element]);
    });

    const { data } = await axios[method](url, formData);

    return data;
  } catch (error) {
    return error.message;
  }
};

export {
  getData,
  postData,
  putData,
  deleteData,
  uploadSingleFile,
  uploadMultipleFile,
  uploadFormData,
};
