/* eslint-disable */
import React, { useEffect } from 'react';
import Header from 'components/Common/Header/Header';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import CrmSalesOrderBody from 'components/CRMComponents/CRMSalesOrderCoponents/CRMSalesOrderBody/CrmSalesOrderBody';
import CrmSalesOrderFilterPopup from 'components/CRMComponents/CRMSalesOrderCoponents/Popup/CrmSalesOrderFilterPopup';
import CrmSalesOrderDetailsPopup from 'components/CRMComponents/CRMSalesOrderCoponents/Popup/CrmSalesOrderDetailsPopup';

const CrmSalesOrder = () => {
  useEffect(() => {
    document.title = 'CRM Sales Order';
  }, []);

  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmSalesOrderBody />
      {/* ----- all pop up render here ----- */}
      <CrmSalesOrderFilterPopup />
      <CrmSalesOrderDetailsPopup />
      {/* ------ all modal render here ----- */}
      <AddLabelModal moduleName="crmsalesorder" />
    </main>
  );
};

export default CrmSalesOrder;
