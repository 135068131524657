/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";

//import membership options from json
import statusOption from "data/Prod/MembershipStatus.json";
import { useTranslation } from "react-i18next";

const SubscriberDetailsPopup = ({
  memberId,
  setMemberId,
  afterPopupClose,
  courseId,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation

  const [courseSubsriberId, setCourseSubsriberId] = useState(null);
  const [subscriberImage, setSubscriberImage] = useState("");
  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [subscriberRole, setsubscriberRole] = useState("");
  const [roleValue, setRoleValue] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [subscriberStatusValue, setSubscriberStatusValue] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [courseStatus, setCourseStatus] = useState("0");
  const [requestDate, setrequestDate] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  //function for get details of event member
  const getSubscriberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COURSE_SUBSCRIBER_DETAILS +
        `/${memberId}?token=${token}`;

      const response = await getData(requestUrl);
      console.log(response);

      if (response.status) {
        setCourseSubsriberId(response.data.coursesubscriberid);
        setSubscriberImage(response.data.image);
        setSubscriberName(response.data.name);
        setSubscriberEmail(response.data.email);
        setsubscriberRole(response.data.rolestring);
        roleSelectionHandler(response.data.roledata);
        statusOption.map((membership) => {
          if (response.data.moderatorstatus == membership.value) {
            statusSelectionHandler(membership);
          }
        });
        setCourseStatus(response.data.memberstatus);
      }
      setrequestDate(response.data?.requestdate);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setRoleData(val.value);
  };

  //function for select memebership status
  const statusSelectionHandler = (val) => {
    setSubscriberStatusValue(val);
    setStatusData(val.value);
  };

  //function for update subscriber
  const updateSubscriberHandler = async () => {
    setIsSaving(true);
    try {
      let subscriberData = {
        role: roleData,
        moderatorstatus: statusData,
        joiningdate: statusData == "1" ? getCurrentDateInString() : "",
      };

      // console.log(memberData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_UPDATE_COURSE_SUBSCRIBER +
        `/${memberId}?token=${token}&courseid=${courseId}&coursesubscriberid=${courseSubsriberId}&coursememberstatus=${courseStatus}`;

      const response = await putData(requestUrl, subscriberData);

      setIsSaving(false);
      console.log(response);

      if (response.status) {
        let offCanvasPopup = document.querySelector(
          "#offcanvasSubscriberDetails"
        );
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();

        closePopupHandler();
        afterPopupClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for after close popup
  const closePopupHandler = () => {
    setMemberId(null);
    setCourseSubsriberId(null);
    setSubscriberImage("");
    setSubscriberName("");
    setSubscriberEmail("");
    setsubscriberRole("");
    setRoleValue(null);
    setRoleData([]);
    setSubscriberStatusValue(null);
    setStatusData(null);
  };

  useEffect(() => {
    if (memberId) {
      getSubscriberDetails();
    }
  }, [memberId]);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasSubscriberDetails"
      aria-labelledby="offcanvasSubscriberDetailsLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasMemberDetailsLabel">
          {t("Subscriber Details")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={closePopupHandler}
        ></button>
      </div>
      <div className="offcanvas-body d-flex flex-column p-4">
        {/* ----- member name,email,image section start ----- */}
        <div className="profile d-flex align-items-center gap-2 mb-4">
          <div
            className="avatar rounded-circle overflow-hidden"
            style={{ width: "35px", height: "35px" }}
          >
            <img
              src={
                subscriberImage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + subscriberImage
              }
              alt="user"
              className="w-100 h-100 object-fit-cover object-center"
            />
          </div>
          <div>
            <p className="fs-md fw-semibold mb-0">{subscriberName}</p>
            <Link to="#" className="d-block lh-1">
              {subscriberEmail}
            </Link>
          </div>
        </div>
        {/* ----- member name,email,image section end ----- */}

        {/* ----- member role and other information section start ----- */}
        <div className="table-responsive flex-fill">
          <table className="table align-middle fs-sm">
            <tbody>
              <tr>
                <td className="bg-transparent">{t("Current Role")}</td>
                <td className="bg-transparent fw-semibold">{subscriberRole}</td>
              </tr>
              <tr>
                <td className="bg-transparent">{t("Date")}</td>
                <td className="bg-transparent fw-semibold">{requestDate}</td>
              </tr>
              {/* <tr>
                <td className="bg-transparent">{t("Last Activity")}</td>
                <td className="bg-transparent fw-semibold">
                  15-12-2022 at 9:50 am
                </td>
              </tr> */}
              {/* <tr>
                <td className="bg-transparent">{t("Feedback Received")}</td>
                <td className="bg-transparent fw-semibold">589</td>
              </tr> */}
              {/* <tr>
                <td className="bg-transparent">{t("Feedback Given")}</td>
                <td className="bg-transparent fw-semibold">2589</td>
              </tr> */}
            </tbody>
          </table>
        </div>
        {/* ----- member role and other information section start ----- */}

        {/* ----- member edit form section start ----- */}
        <form
          onSubmit={(e) => e.preventDefault()}
          className="pt-4 mt-4 border-top border-gray-300"
        >
          <div className="form-group mb-3 mb-sm-4">
            <label htmlFor="status" className="d-block fs-sm fw-semibold mb-2">
              {t("Status")}
            </label>

            <Select
              className="w-100"
              placeholder={t("Select status")}
              options={statusOption}
              value={subscriberStatusValue}
              onChange={(val) => statusSelectionHandler(val)}
            />
          </div>
          <div className="form-group mb-3 mb-sm-4">
            <label
              htmlFor="comments"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Comments")}
            </label>
            <textarea
              name="comments"
              id="comments"
              cols="30"
              rows="5"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter message")}
            ></textarea>
          </div>
          {/* <p className="d-flex align-items-center gap-1 fs-sm text-gray mb-3 mb-sm-4">
            <span className="d-block material-symbols-outlined icon-md">
              info
            </span>
            <span className="d-block">
              The library also has delightful and beautifully{" "}
            </span>
          </p> */}
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateSubscriberHandler}
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
        {/* ----- member edit form section end ----- */}
      </div>
    </div>
  );
};

export default SubscriberDetailsPopup;
