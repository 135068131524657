/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

// Material UI table
import { MaterialReactTable } from 'material-react-table';

import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';

import CRMLabelHeader from '../CRMLabelHeader/CRMLabelHeader';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import { crmCommonHeaderLinkLists } from 'helper/CrmHelper/CrmHelper';
import AddUpdateLabelPopup from 'components/Common/Popup/AddUpdateLabelPopup';
import LabelFilterPopup from 'components/Common/Popup/LabelFilterPopup';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const CRMLabelBody = () => {
  const token = localStorage.getItem('token');
  const moduleAccess = localStorage.getItem('moduleaccess');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleName = 'crmtag';

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  // common header object
  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tagId, setTagId] = useState(null);
  const [filterLabelName, setFilterLabelName] = useState('');
  const [filterLabelSlug, setFilterLabelSlug] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [rowSelection, setRowSelection] = useState({});
  const [selectLabelIds, setSelectLabelIds] = useState([]);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //function for get all tags
  const getAllTags = async () => {
    setRowSelection({});
    setSelectLabelIds([]);
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TAGS +
        `?token=${token}&modulename=${moduleName}`;

      if (filterLabelName !== '') {
        requestUrl = requestUrl + `&filterlabelname=${filterLabelName}`;
      }

      if (filterLabelSlug !== '') {
        requestUrl = requestUrl + `&filterlabelslug=${filterLabelSlug}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setTagList(response.data);
        setMessageType('success');
        resetFilterData();
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    if (selectLabelIds.length > 0) {
      let responseArr = [];

      for (let labelId of selectLabelIds) {
        try {
          let tagData = {
            status: '0',
          };

          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_TAG +
            `/${labelId}` +
            `?token=${token}`;

          const response = await putData(requestUrl, tagData);

          if (response.status) {
            setMessageType('success');
            responseArr.push(true);
          } else {
            setMessageType('error');
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType('error');
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTags();
        }, 2000);
      }
    } else {
      setMessageType('error');
      setAlertMessage('Please select data for delete');
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterLabelName('');
    setFilterLabelSlug('');
  };
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#addUpdateLabelOffCanvas"
            aria-controls="addUpdateLabelOffCanvas"
            onClick={() => {
              setTagId(row.original.value);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'label',
        header: t('Name'),
        size: 200,
      },
      {
        accessorKey: 'slug',
        header: t('Slug'),
        size: 200,
      },
      {
        accessorKey: 'createddate',
        header: t('Date'),
        size: 200,
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    if (filterLabelName != '' || filterLabelSlug != '') {
      getAllTags();
    }
  }, [filterLabelName, filterLabelSlug]);

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    //do something when the row selection changes...
    // console.info("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectLabelIds(selectedIdsArray);
    } else {
      setSelectLabelIds([]);
    }
  }, [rowSelection]);

  //* This is a function that is called when the alert close button is clicked.
  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage('');

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType('');
  };

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    (moduleAccess.includes('MOD_CRM') && moduleAccess.includes('MOD_LABEL'))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t('Label')}
          />
          <div className="container-fluid px-lg-5">
            <CRMLabelHeader reloadLabelList={getAllTags} />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={tagList} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row.value} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 200,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
          {/* ==== popup and modal section ==== */}
          {/* ---- filter popup ---- */}
          <AddUpdateLabelPopup
            moduleName={moduleName}
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllTags();
              }, 2500);
            }}
            tagId={tagId}
            setTagId={setTagId}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* --- label details popup --- */}
          <LabelFilterPopup
            setFilterLabelName={setFilterLabelName}
            setFilterLabelSlug={setFilterLabelSlug}
          />
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectLabelIds([]);
            }}
            onDelete={changeStatusHandler}
            moduleName={t('tag')}
          />
          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
export default CRMLabelBody;
