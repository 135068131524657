/* eslint-disable */
import React, { useEffect } from "react";

import Header from "components/Common/Header/Header";
import ProjectKanbanBody from "components/ProjectManagementComponents/ProjectDetailsComponents/ProjectTaskComponents/ProjectTaskKanbanBody/ProjectTaskKanbanBody";

const ProjectKanbantView = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* ------ body section -------- */}
      <ProjectKanbanBody />
    </main>
  );
};

export default ProjectKanbantView;
