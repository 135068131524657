import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ckEditorConfig } from "Config/Config";
//import download file
import fileDownloadHandler from "helper/DownloadFile";
import paymentDuaritonOption from "data/Prod/PaymentDuration.json";
import { assetImages } from "constants";

const BioDataAccordion = ({
  resumeTitle = "",
  setresumeTitle,
  resumeSummary = "",
  setresumeSummary,
  resumeDetails = "",
  setresumeDetails,
  minSalary = "",
  setminSalary,
  maxSalary = "",
  setmaxSalary,
  paymentDuration = null,
  setpaymentDuration,
  openDocFileInput,
  docFileInputRef,
  docResumeUploadHandler,
  docFileUploading = false,
  loadingCircle = [],
  uploadedDocFiles = [],
  closeDocFileHandler,
  openVideoFileInput,
  videoFileInputRef,
  videoResumeUploadHandler,
  videoUploading = false,
  uploadedVideoFiles = [],
  closeVideoFileHandler,
  ownerComment = "",
  setOwnerComment,
  customerComment = "",
  setCustomerComment,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      {/* ------ toggle accordion button start ------ */}
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseTwo"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseTwo"
        >
          {t("Biodata/CV")}
        </button>
      </h3>
      {/* ------ toggle accordion button end ------ */}
      <div
        id="panelsStayOpen-collapseTwo"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          {/* ----- biodata title section start ----- */}
          <div className="form-group mb-4">
            <input
              type="text"
              id="resumeTitle"
              className="form-control border-0 shadow-none"
              placeholder={t("Resume Title")}
              value={resumeTitle}
              onChange={(e) => setresumeTitle(e.target.value)}
            />
          </div>
          {/* ----- biodata title section end ----- */}
          {/* ----- biodata summary section start ----- */}
          <div className="form-group mb-4">
            <textarea
              name="resumeSummery"
              id="resumeSummery"
              cols="30"
              rows="5"
              className="form-control border-0 shadow-none"
              placeholder={t("Resume Summary")}
              value={resumeSummary}
              onChange={(e) => setresumeSummary(e.target.value)}
            ></textarea>
          </div>
          {/* ----- biodata summary section end ----- */}
          {/* ----- biodata details section start ----- */}
          <div className="form-group mb-4">
            <CKEditor
              className="form-control border-0 shadow-none"
              editor={ClassicEditor}
              config={ckEditorConfig(t("Type description here..."))}
              data={resumeDetails}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                let editorContent = editor.getData();
                setresumeDetails(editorContent);
              }}
            />
          </div>
          {/* ----- biodata details section end ----- */}
          {/* ------ payment section start ------ */}
          <div className="form-group mb-3">
            <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
              <label htmlFor="pay" className="text-nowrap me-lg-3">
                {t("Pay Rate")}
              </label>
              {/* ------ minsalary section start ------ */}
              <input
                type="number"
                className="form-control border-0 border-end rounded-0 shadow-none"
                placeholder={t("Minimum")}
                value={minSalary}
                onChange={(e) => setminSalary(e.target.value)}
              />
              {/* ------ minsalary section end ------ */}
              {/* ------ maxsalary section start ------ */}
              <input
                type="number"
                className="form-control border-0 border-end rounded-0 shadow-none"
                placeholder={t("Maximum")}
                value={maxSalary}
                onChange={(e) => setmaxSalary(e.target.value)}
              />
              {/* ------ maxsalary section end ------ */}
              {/* ------ payment duration selection start ------ */}
              <Select
                placeholder={t("Select Duration")}
                className="w-100"
                isClearable
                // className="form-select border-0 rounded-0 shadow-none"
                options={paymentDuaritonOption}
                value={paymentDuration}
                onChange={(val) => {
                  setpaymentDuration(val);
                }}
              />
              {/* ------ payment duration selection end ------ */}
            </div>
          </div>
          {/* ------ payment section end ------ */}
          {/* ------ upload resume section start ------- */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ------ uploaded doc resume section start ----- */}
              <div className="col-lg-6 mb-3 mb-lg-0">
                {/* ------ upload input file section start ----- */}
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="uploadResume" className="text-nowrap me-3">
                    {t("Upload Documents")}
                  </label>
                  <p className="form-control border-0 rounded-0 shadow-none">
                    DOCX, PDF, DOC
                  </p>
                  {/* ------ upload icon start ----- */}
                  <Link to="#" onClick={openDocFileInput}>
                    <span
                      className="d-block upload-file material-symbols-outlined icon-lg cursor-pointer"
                      data-target="uploadResume"
                    >
                      upload
                    </span>
                  </Link>
                  {/* ------ upload icon end ----- */}
                  {/* ------ upload doc input section start ----- */}
                  <input
                    type="file"
                    id="uploadDocResume"
                    className="d-none"
                    ref={docFileInputRef}
                    onChange={docResumeUploadHandler}
                    multiple
                  />
                  {/* ------ upload doc input section end ----- */}
                </div>
                {/* ------ upload input file section end ----- */}
                {/* ------ uploaded file display section start ----- */}
                {docFileUploading ? (
                  <div className="d-flex align-items-center mt-3 ms-3">
                    <p className="mb-0">{t("Uploading documents")}</p>
                    {loadingCircle.map((circle) => {
                      return (
                        <div
                          key={circle}
                          className="spinner-grow mx-1"
                          style={{ height: "6px", width: "6px" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="upload-files mt-3">
                    <label
                      htmlFor="UploadDocFile"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Uploaded Documents")}
                    </label>
                    {/* ------ uploaded documents start ------ */}
                    <ul className="files d-flex flex-wrap gap-2">
                      {uploadedDocFiles.map((docFile, index) => {
                        return (
                          <li
                            key={index}
                            className="file p-3 text-center d-flex flex-column align-items-center justify-content-center border border-gray rounded-8 position-relative"
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                fileDownloadHandler(docFile);
                              }}
                            >
                              <span className="d-block material-symbols-outlined icon-lg position-absolute top-0 start-0 mt-2 ms-2">
                                download
                              </span>
                            </Link>
                            <img
                              src={
                                docFile.path.split(".").pop() === "pdf"
                                  ? assetImages.PdfImg
                                  : docFile.path.split(".").pop() === "xlsx"
                                  ? assetImages.XlsImg
                                  : assetImages.DocImg
                              }
                              alt="docImage"
                              className="d-block img-fluid mx-auto mb-2"
                            />
                            <p className="fs-sm fw-semibold mb-0">
                              {docFile.name}
                            </p>
                            {/* <p className="size fs-xs lh-1">1.5mb</p> */}
                            <Link
                              to="#"
                              onClick={() => {
                                closeDocFileHandler(index);
                              }}
                            >
                              <span className="d-block material-symbols-outlined icon-lg position-absolute top-0 end-0 mt-2 me-2">
                                delete
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    {/* ------ uploaded documents end ------ */}
                  </div>
                )}
                {/* ------ uploaded file display section end ----- */}
              </div>
              {/* ------ uploaded doc resume section end ----- */}
              {/* ------ uploaded video resume section start ----- */}
              <div className="col-lg-6">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label
                    htmlFor="uploadResumeVideo"
                    className="text-nowrap me-3"
                  >
                    {t("Upload Videos")}
                  </label>
                  <p className="form-control border-0 rounded-0 shadow-none">
                    Mp4, Avi, Mov
                  </p>
                  {/* ------ upload icon start ----- */}
                  <Link to="#" onClick={openVideoFileInput}>
                    <span
                      className="d-block upload-file material-symbols-outlined icon-lg cursor-pointer"
                      data-target="uploadResumeVideo"
                    >
                      upload
                    </span>
                  </Link>
                  {/* ------ upload icon end ----- */}
                  {/* ------ upload input start ----- */}
                  <input
                    type="file"
                    id="uploadVideoResume"
                    className="d-none"
                    ref={videoFileInputRef}
                    onChange={videoResumeUploadHandler}
                    multiple
                  />
                  {/* ------ upload input end ----- */}
                </div>
                {/* ------ uploaded videos section start ----- */}
                {videoUploading ? (
                  <div className="d-flex align-items-center mt-3 ms-3">
                    <p className="mb-0 me-1">{t("Uploading Videos")}</p>
                    {loadingCircle.map((circle) => {
                      return (
                        <div
                          key={circle}
                          className="spinner-grow mx-1"
                          style={{ height: "6px", width: "6px" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="upload-files mt-3">
                    <label
                      htmlFor="VideoResume"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Uploaded Resume")}
                    </label>
                    {/* ----- uploaded video section start ----- */}
                    <ul className="files row gx-2">
                      {uploadedVideoFiles.map((video, index) => {
                        return (
                          <li
                            className="file col-md-4 position-relative"
                            key={index}
                          >
                            <div className="ratio ratio-16x9">
                              <video src={video.path} controls></video>
                            </div>
                            <Link
                              to="#"
                              onClick={() => {
                                closeVideoFileHandler(index);
                              }}
                            >
                              <span className="d-block material-symbols-outlined icon-lg position-absolute top-0 end-0 mt-2 me-2">
                                delete
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    {/* ----- uploaded video section end ----- */}
                  </div>
                )}
                {/* ------ uploaded videos section end ----- */}
              </div>
              {/* ------ uploaded video resume section end ----- */}
            </div>
          </div>
          {/* ------ upload resume section end ------- */}
          {/* ------- customer comment section start ------- */}
          <div className="form-group mb-4">
            <textarea
              cols="30"
              rows="5"
              className="form-control border-0 shadow-none"
              placeholder={t("Comment on Candidate for Customer")}
              value={ownerComment}
              onChange={(e) => {
                setOwnerComment(e.target.value);
              }}
            ></textarea>
          </div>
          {/* ------- customer comment section end ------- */}
          {/* ------- owner comment section start ------- */}
          <div className="form-group mb-4">
            <textarea
              cols="30"
              rows="5"
              className="form-control border-0 shadow-none"
              placeholder={t("Comment on Candidate for Owner")}
              value={customerComment}
              onChange={(e) => {
                setCustomerComment(e.target.value);
              }}
            ></textarea>
          </div>
          {/* ------- owner comment section end ------- */}
        </div>
      </div>
    </div>
  );
};

export default BioDataAccordion;
