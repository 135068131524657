/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const AddEditExperienceModal = ({
  // companyList,
  experienceIndex,
  experienceData,
  setExperienceData,
  experienceListBlock,
  setExperienceListBlock,
  setExperienceIndex,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation

  const [companyInput, setcompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  //function for get all companies
  const getCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_BY_SEARCH +
        `?token=${token}&roleslug=${userInfo.role.name}&filtername=${companyInput}`;

      console.log("url of company------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in company------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    if (val) {
      setExperienceData((prevData) => ({
        ...prevData,
        companyid: val,
      }));
    } else {
      setExperienceData((prevData) => ({
        ...prevData,
        companyid: null,
      }));
    }
  };

  //function for add edcuation block
  const addExperienceHandler = () => {
    setExperienceListBlock([...experienceListBlock, experienceData]);
    closeModalHandler();
  };

  //function for edit education block
  const editExperienceBlockHandler = () => {
    const updatedExperienceList = [...experienceListBlock];
    updatedExperienceList[experienceIndex] = experienceData;
    setExperienceListBlock(updatedExperienceList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    setExperienceData({
      companyid: null,
      company: "",
      position: "",
      start: "",
      end: "",
      ctc: "",
      responsibilities: "",
    });
    setExperienceIndex(null);
  };

  useEffect(() => {
    if (companyInput.length > 1) {
      getCompanyList();
    }
  }, [companyInput]);

  const companyOptionsToShow = companyList.length > 0 ? companyList : [];

  return (
    <div
      className="modal fade"
      id="addExperienceModal"
      tabIndex="-1"
      aria-labelledby="addEducationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          {/* ------ modal head section start ----- */}
          <div className="modal-header p-4 pb-0 border-0">
            {/* ------ modal title start ----- */}
            <h2 className="fw-bold mb-0" id="addEducationModalLabel">
              {experienceIndex != null
                ? t("Update Experience")
                : t("Add Experience")}
            </h2>
            {/* ------ modal title end ----- */}

            {/* ------ modal close button start ----- */}
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
            {/* ------ modal close button end ----- */}
          </div>
          {/* ------ modal head section end ----- */}

          {/* ------ modal body section start ----- */}
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ------ company selection section start ------ */}
              {experienceData.companyid ? (
                <div className="form-group mb-4">
                  <label
                    htmlFor="selectTags"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Company")}
                  </label>
                  <p className="form-control fs-sm shadow-none">
                    {experienceData.companyid.label}
                  </p>
                </div>
              ) : (
                <div className="form-group mb-4">
                  <label
                    htmlFor="selectTags"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Select Company")}
                  </label>
                  <CreatableSelect
                    placeholder={t("Type Company name")}
                    isClearable
                    options={companyOptionsToShow}
                    value={experienceData.companyid}
                    onChange={(val) => {
                      companySelectionHandler(val);
                    }}
                    onInputChange={(val) => {
                      setcompanyInput(val);
                    }}
                  />
                </div>
              )}
              {/* ------ company selection section end ------ */}

              {/* ------ Company section start ----- */}
              {experienceData.companyid ? null : (
                <div className="form-group mb-4">
                  <label
                    htmlFor="selectTags"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Other Company (* If not in the list)")}
                  </label>
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Company")}
                    value={experienceData.company}
                    onChange={(e) => {
                      setExperienceData((prevData) => ({
                        ...prevData,
                        company: e.target.value,
                      }));
                    }}
                  />
                </div>
              )}
              {/* ------ Company section end ----- */}

              {/* ------ Position section start ----- */}
              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-6">
                    <label
                      htmlFor="year"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Position")}
                    </label>
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Position")}
                      value={experienceData.position}
                      onChange={(e) => {
                        setExperienceData((prevData) => ({
                          ...prevData,
                          position: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label
                      htmlFor="year"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("CTC")}
                    </label>
                    <input
                      type="number"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("CTC")}
                      value={experienceData.ctc}
                      onChange={(e) => {
                        setExperienceData((prevData) => ({
                          ...prevData,
                          ctc: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* ------ Position section end ----- */}

              {/* ------ From year and To year section start ----- */}
              <div className="form-group mb-4">
                <div className="row">
                  {/* ------ from year section start ----- */}
                  <div className="col-6">
                    <label
                      htmlFor="grade"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Start Year")}
                    </label>
                    <input
                      type="number"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Start Year")}
                      value={experienceData.start}
                      onChange={(e) => {
                        setExperienceData((prevData) => ({
                          ...prevData,
                          start: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {/* ------ from year section end ----- */}

                  {/* ------ end year section start ----- */}
                  <div className="col-6">
                    <label
                      htmlFor="university"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("To Year")}
                    </label>
                    <input
                      type="number"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("To Year")}
                      value={experienceData.end}
                      onChange={(e) => {
                        setExperienceData((prevData) => ({
                          ...prevData,
                          end: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {/* ------ end year section end ----- */}
                </div>
              </div>
              {/* ------ From year and To year section end ----- */}

              {/* <!-- ========== Start responsibilities Section ========== --> */}
              <div className="form-group mb-4">
                <label
                  htmlFor="selectTags"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Responsibilities")}
                </label>
                <textarea
                  name=""
                  id=""
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Responsibilities")}
                  value={experienceData.responsibilities}
                  onChange={(e) => {
                    setExperienceData((prevData) => ({
                      ...prevData,
                      responsibilities: e.target.value,
                    }));
                  }}
                ></textarea>
              </div>
              {/* <!-- ========== End responsibilities Section ========== --> */}

              {/* ------ add/edit button start ----- */}
              <div className="action">
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={
                    experienceIndex != null
                      ? editExperienceBlockHandler
                      : addExperienceHandler
                  }
                >
                  {experienceIndex != null
                    ? t("Update Experience")
                    : t("Add Experience")}
                </button>
              </div>
              {/* ------ add/edit button end ----- */}
            </form>
          </div>
          {/* ------ modal body section end ----- */}
        </div>
      </div>
    </div>
  );
};

export default AddEditExperienceModal;
