import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
const CrmContactMailHeader = ({ reloadList }) => {
  const { t } = useTranslation(); // for translations
  const params = useParams();
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-lg-4 mb-3 mb-lg-0">
          <ul
            className="nav nav-tabs d-inline-flex p-1 body-bg flex-nowrap fs-md lh-1 fw-semibold border-0 text-nowrap rounded-10 overflow-auto"
            id="mailTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <Link
                to={`/admin/crm/contact/details/recievedmail/${params.id}`}
                className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10 active"
              >
                <span className="d-block material-symbols-outlined icon-lg">
                  archive
                </span>
                <span className="d-block">{t("Received")}</span>
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link
                to={`/admin/crm/contact/details/sentmail/${params.id}`}
                className="text-black d-flex align-items-center justify-content-center gap-2 rounded-10"
              >
                <span className="d-block material-symbols-outlined icon-lg">
                  unarchive
                </span>
                <span className="d-block">{t("Sent")}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-8 text-nowrap d-flex flex-wrap flex-md-nowrap justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center gap-1"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
            </ul>
          </div>
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFilter"
            aria-controls="offcanvasFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          <Link
            to="#offcanvasNewMail"
            className="btn btn-primary d-flex align-items-center gap-1"
            data-bs-toggle="offcanvas"
            role="button"
            aria-controls="offcanvasNewMail"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Add New")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CrmContactMailHeader;
