/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import AddUpdateSuccessStoryBody from "components/SuccessStoriesComponents/AddUpdateSuccessStoryBody/AddUpdateSuccessStoryBody";

const AddUpdateSuccessStory = () => {
  useEffect(() => {
    document.title = "Add New Success Story";
  }, []);
  return (
    <main id="app">
      <Header moduleName="successstories" />
      <AddUpdateSuccessStoryBody />
    </main>
  );
};

export default AddUpdateSuccessStory;
