/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
  deleteData,
} from "utils/Gateway";

import languageOptions from "data/Prod/LanguageOptions.json";
import fileDownloadHandler from "helper/DownloadFile";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { assetImages } from "constants";
import { courseDetailsHeaderObject } from "helper/CourseHelper/CourseHelper";
import { ckEditorConfig } from "Config/Config";
import AfterSaveCourseModal from "./Modal/AfterSaveCourseModal";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import { ecosystemSlug } from "Config/Config";
import UploadContentModal from "components/Common/Modal/UploadContentModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const CourseDetailBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];
  const params = useParams();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Course"), link: "/admin/courses" },
    { title: t("Course Details") },
  ];

  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  const imageFileInputRef = useRef(null);

  const [parentCourseInput, setparentCourseInput] = useState("");
  const [parentCourseList, setparentCourseList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [skillList, setskillList] = useState([]);
  const [title, settitle] = useState("");
  const [code, setcode] = useState("");
  const [languageValue, setlanguageValue] = useState(null);
  const [selectedLanguage, setselectedLanguage] = useState("");
  const [parentValue, setparentValue] = useState(null);
  const [selectedParentId, setselectedParentId] = useState(null);
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [selectedCategories, setselectedCategories] = useState([]);
  const [skillValue, setskillValue] = useState(null);
  const [selectedSkills, setselectedSkills] = useState([]);
  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [uploadedFileIds, setuploadedFileIds] = useState([]);
  const [authorsBio, setauthorsBio] = useState("");
  const [joinMessage, setjoinMessage] = useState("");
  const [thanksMessage, setthanksMessage] = useState("");
  const [selectedUploadId, setSelectedUploadId] = useState(null);
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);
  const [autoAccept, setautoAccept] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [savedCourseId, setSavedCourseId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [contetnIdForDelete, setcontetnIdForDelete] = useState(null);
  const [contentIndexForDelete, setcontentIndexForDelete] = useState(null);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    codeWarning: false,
    titlewarning: false,
    categoryWarning: false,
    skillsWarning: false,
  });

  // validate =========================
  const validationHandler = () => {
    let isValid = true;
    if (code == "") {
      setValidation((prevState) => ({ ...prevState, codeWarning: true }));
      isValid = false;
    }
    if (title == "") {
      setValidation((prevState) => ({ ...prevState, titlewarning: true }));
      isValid = false;
    }
    if (categoryValue == null) {
      setValidation((prevState) => ({ ...prevState, categoryWarning: true }));
      isValid = false;
    }
    if (skillValue == null) {
      setValidation((prevState) => ({ ...prevState, skillsWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function for get all parent course
  const getAllParentCourseList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PARENT_COURSES +
        `?token=${token}&coursestring=${parentCourseInput}`;

      const response = await getData(requestURL);

      if (response.status) {
        setparentCourseList(response.data);
      } else {
        setShowAlert(true);
        setAlertMessage("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "COURSE";
      const response = await getAllCategories(ecosystemSlug, parentSlug);
      if (response) {
        setcategoryList(response);
      } else {
        setShowAlert(true);
        setAlertMessage("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(error.message);
    }
  };

  //get all skills
  const getAllSkillList = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);
      if (response) {
        setskillList(response);
      } else {
        setShowAlert(true);
        setAlertMessage("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(error.message);
    }
  };

  //function for select language
  const languaSelectionHandler = (val) => {
    if (val) {
      setlanguageValue(val);
      setselectedLanguage(val.value);
    } else {
      setlanguageValue(null);
      setselectedLanguage("");
    }
  };

  //function for select parent course
  const parentCourseSelectionHandler = (val) => {
    if (val) {
      setparentValue(val);
      setselectedParentId(val.value);
    } else {
      setparentValue(null);
      setselectedParentId(null);
      setparentCourseInput("");
      setparentCourseList([]);
    }
  };

  //function for select category
  const categorySelectionHandler = (val) => {
    if (val) {
      setcategoryValue(val);
      setselectedCategories(val.map((item) => item.value));
    } else {
      setcategoryValue(null);
      setselectedCategories([]);
    }
  };

  //function for select skills
  const skillSelectionHandler = (val) => {
    if (val) {
      setskillValue(val);
      setselectedSkills(val.map((item) => item.value));
    } else {
      setskillValue(null);
      setselectedSkills([]);
    }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files[0];

    setIsUploading(true);

    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;
      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(response.data.path);
        setUploadedImageId(response.data._id);
        resetEventImageFile();
      } else {
        setShowAlert(true);
        setAlertMessage("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.error(error.message);
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(error.message);
    }
  };

  //function for close image file
  const closeEventImageFileHandler = () => {
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetEventImageFile();
  };

  // function for clear file value
  const resetEventImageFile = () => {
    const file = document.getElementById("uploadedCourseImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete content
  const deleteContentHandler = async () => {
    if (contentIndexForDelete && contetnIdForDelete) {
      try {
        setIsDeleting(true);
        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_CONTENT +
          `/${contetnIdForDelete}?token=${token}`;

        const response = await deleteData(requestURL);

        setIsDeleting(false);

        if (response.status) {
          const updatedFileids = [...uploadedFileIds];
          const updateFiles = [...uploadedFiles];

          updatedFileids.splice(contentIndexForDelete, 1);
          updateFiles.splice(contentIndexForDelete, 1);

          setuploadedFileIds(updatedFileids);
          setuploadedFiles(updateFiles);

          setcontentIndexForDelete(null);
          setcontetnIdForDelete(null);
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      //alert notification
    }
  };

  //get cuourse details
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data.parentcoursevalue) {
          parentCourseSelectionHandler(response.data.parentcoursevalue);
        }
        setcode(response.data.code);
        settitle(response.data.title);
        setsummary(response.data.summary);
        setdetails(response.data.description);
        setauthorsBio(response.data.authorsbio);
        setjoinMessage(response.data.joiningmessage);
        setthanksMessage(response.data.thanksmessage);
        //set language
        for (let languageData of languageOptions) {
          if (languageData.value === response.data.language) {
            languaSelectionHandler(languageData);
          }
        }
        categorySelectionHandler(response.data.categoryvalues);
        skillSelectionHandler(response.data.skillvalues);
        setuploadedFileIds(response.data?.uploadcontentids);
        setuploadedFiles(response.data?.uploadcontentlist);
        setUploadedImageId(response.data?.courselogoid);
        setUploadedPreviewImage(response.data?.courselogopath);
        //assing auto accept
        setautoAccept(response.data?.autoapproval);

        setShowAlert(true);
        setMessageType("success");
        setAlertMessage(response.message);
      } else {
        setShowAlert(true);
        setAlertMessage("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(error.message);
    }
  };

  //function for save course
  const saveCourseHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        let courseData = {
          code,
          parentid: selectedParentId,
          companyid: null,
          title,
          summary: summary,
          description: details,
          language: selectedLanguage,
          categories: selectedCategories,
          skills: selectedSkills,
          uploads: uploadedFileIds,
          authorsbio: authorsBio,
          joiningmessage: joinMessage,
          thanksmessage: thanksMessage,
          logoid: uploadedImageId,
          autoapproval: autoAccept,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestURL += url.API_UPDATE_COURSE + `/${params.id}?token=${token}`;
          response = await putData(requestURL, courseData);
        } else {
          requestURL += url.API_ADD_NEW_COURSE + `?token=${token}`;
          response = await postData(requestURL, courseData);
        }

        setIsSaving(false);

        if (response.status) {
          setSavedCourseId(response.data._id);
          setShowAlert(true);
          setMessageType("success");
          setAlertMessage(response.message);

          const bootstrapModal = new bootstrap.Modal(
            document.getElementById("saveCourse")
          );

          bootstrapModal.show();
        } else {
          setShowAlert(true);
          setAlertMessage("error");
          setAlertMessage(response.message);
        }
      } catch (error) {
        console.log(error.message);
        setShowAlert(true);
        setAlertMessage("error");
        setAlertMessage(error.message);
      }
    } else {
      setShowAlert(true);
      setAlertMessage("error");
      setAlertMessage(t("Please fill up the required fields"));
    }
  };

  const afterSaveCourseModalClose = (saveType = "list") => {
    if (saveType == "list") {
      history.push("/admin/courses");
    } else {
      if (params.id) {
        history.push(`/admin/course/save/${params.id}`);
      } else {
        history.push(`/admin/course/save/${savedCourseId}`);
      }
    }
  };

  // --- on alert close function ---
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  // const changeStatusHandler = (content, index) => {
  //   setCurrentContentIndex(index);
  //   deleteContentHandler(content._id, index);
  // };

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllCategoryList();
    getAllSkillList();
  }, []);

  useEffect(() => {
    if (parentCourseInput.length > 0) {
      getAllParentCourseList();
    }
  }, [parentCourseInput]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COURSE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Course Details")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ---- bread crumb ------- */}
            <BreadCrumb
              breadCrumbText={breadcrumbText}
              bottom={true}
              displayName={title}
            />

            {/* -------- form section ----------- */}
            <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              <form onSubmit={(e) => e.preventDefault()}>
                {/* <!-- ========== Start code and language Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-4 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="code"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        <span className="d-block">{t("Code")}</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control fs-sm shadow-none"
                        value={code}
                        onChange={(e) => {
                          setcode(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            codeWarning: false,
                          }));
                        }}
                      />
                      {validation.codeWarning && (
                        <div className="error-message mt-3">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter code")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4">
                      <label
                        htmlFor="language"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        <span className="d-block">{t("Language")}</span>
                      </label>
                      <Select
                        placeholder={t("Select Language")}
                        options={languageOptions}
                        value={languageValue}
                        onChange={(val) => {
                          languaSelectionHandler(val);
                        }}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label
                        htmlFor="virtualMeetURL"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Select Parent")}
                      </label>
                      <Select
                        isClearable
                        options={parentCourseList}
                        placeholder={t("Search by course title")}
                        value={parentValue}
                        onChange={(val) => {
                          parentCourseSelectionHandler(val);
                        }}
                        onInputChange={(val) => {
                          setparentCourseInput(val);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End code and language Section ========== --> */}

                {/* <!-- ========== Start title Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="title"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Title")}</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control fs-sm shadow-none"
                    value={title}
                    onChange={(e) => {
                      settitle(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        titlewarning: false,
                      }));
                    }}
                  />
                  {validation.titlewarning && (
                    <div className="error-message mt-3">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter title")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                {/* <!-- ========== End title Section ========== --> */}

                {/* <!-- ========== Start slug Section ========== --> */}
                {/* <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="slug"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Slug")}</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control fs-sm shadow-none"
                  />
                </div> */}
                {/* <!-- ========== End slug Section ========== --> */}

                {/* <!-- ========== Start summary Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="summary"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Summary")}</span>
                  </label>
                  <textarea
                    name="summary"
                    id="summary"
                    cols="30"
                    rows="5"
                    className="form-control fs-sm shadow-none"
                    value={summary}
                    onChange={(e) => {
                      setsummary(e.target.value);
                    }}
                  ></textarea>
                </div>
                {/* <!-- ========== End summary Section ========== --> */}

                {/* <!-- ========== Start details Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="category"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Details")}
                  </label>
                  <CKEditor
                    className="form-control fs-sm shadow-none"
                    editor={ClassicEditor}
                    config={ckEditorConfig("Course details")}
                    data={details ? details : ""}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editorContent = editor.getData();
                      setdetails(editorContent);
                    }}
                  />
                </div>
                {/* <!-- ========== End details Section ========== --> */}

                {/* ----- category section start ----- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="category"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Category")}
                  </label>
                  <Select
                    isMulti
                    placeholder={t("Select Categories")}
                    options={categoryList}
                    value={categoryValue}
                    onChange={(val) => {
                      categorySelectionHandler(val);
                      setValidation((prevState) => ({
                        ...prevState,
                        categoryWarning: false,
                      }));
                    }}
                  />
                  {validation.categoryWarning && (
                    <div className="error-message mt-3">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter category")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                {/* ----- category section end ----- */}

                {/* <!-- ========== Start skills Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="skills"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Skills")}
                  </label>
                  <Select
                    isMulti
                    placeholder={t("Select Skills")}
                    options={skillList}
                    value={skillValue}
                    onChange={(val) => {
                      skillSelectionHandler(val);
                      setValidation((prevState) => ({
                        ...prevState,
                        skillsWarning: false,
                      }));
                    }}
                  />
                  {validation.skillsWarning && (
                    <div className="error-message mt-3">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter skill")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                {/* <!-- ========== End skills Section ========== --> */}

                {/* <!-- ========== Start uploaded files Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2">
                    <span className="d-block">Sample Content</span>
                  </label>
                  <div className="table-wrapper">
                    <div className="table-responsive mb-4">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <thead>
                          <tr>
                            <th className="course-name bg-transparent fs-md fw-semibold border-0">
                              Rank
                            </th>
                            <th className="assignments bg-transparent fs-md fw-semibold border-0">
                              Title
                            </th>
                            <th className="duration-min bg-transparent fs-md fw-semibold border-0">
                              File
                            </th>
                            <th
                              className="questions bg-transparent fs-md fw-semibold border-0"
                              style={{ width: "40%" }}
                            >
                              Description
                            </th>
                            <th className="action bg-transparent fs-md fw-semibold border-0">
                              Action
                            </th>
                          </tr>
                        </thead>
                        {uploadedFiles.length === 0 ? null : (
                          <tbody>
                            {uploadedFiles.map((content, index) => {
                              return (
                                <tr key={index}>
                                  <td className="course-name border-bottom-0">
                                    <div className="d-flex align-items-center gap-4">
                                      <p>{content.rank}</p>
                                    </div>
                                  </td>
                                  <td className="assignments border-bottom-0">
                                    <p>{content.name}</p>
                                  </td>
                                  <td className="questions border-bottom-0">
                                    <p className="mb-0">{content.title}</p>
                                  </td>

                                  <td className="created border-bottom-0">
                                    <p>{content.description}</p>
                                  </td>
                                  <td className="action border-bottom-0">
                                    <div className="d-flex align-items-center gap-2">
                                      <Link
                                        to="#"
                                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#upload_content"
                                        onClick={() => {
                                          setSelectedUploadId(content._id);
                                        }}
                                      >
                                        <span className="d-block material-symbols-outlined">
                                          edit
                                        </span>
                                      </Link>
                                      <button
                                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary border-0"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deletewarning"
                                        onClick={() => {
                                          //set states for delete
                                          setcontetnIdForDelete(content._id);
                                          setcontentIndexForDelete(index);
                                        }}
                                        disabled={isDeleting ? true : false}
                                        style={{
                                          cursor: isDeleting
                                            ? "not-allowed"
                                            : "pointer",
                                        }}
                                      >
                                        {isDeleting &&
                                        contentIndexForDelete === index ? (
                                          <div
                                            className="mx-2 spinner-border spinner-border-sm"
                                            role="status"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        ) : (
                                          <span className="d-block material-symbols-outlined">
                                            delete
                                          </span>
                                        )}
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="add_another">
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#upload_content"
                        className="btn btn-outline-primary"
                      >
                        Add Content
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <!-- ========== End uploaded files Section ========== --> */}

                {/* <!-- ========== Start authors bio Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="Authors"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Authors Bio")}
                  </label>
                  <textarea
                    name="summary"
                    id="summary"
                    cols="30"
                    rows="5"
                    className="form-control fs-sm shadow-none"
                    value={authorsBio}
                    onChange={(e) => {
                      setauthorsBio(e.target.value);
                    }}
                  ></textarea>
                </div>
                {/* <!-- ========== End authors bio Section ========== --> */}

                {/* <!-- ========== Start joining message Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="joinmessage"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Joining Message")}
                  </label>
                  <textarea
                    name="summary"
                    id="summary"
                    cols="30"
                    rows="5"
                    className="form-control fs-sm shadow-none"
                    value={joinMessage}
                    onChange={(e) => {
                      setjoinMessage(e.target.value);
                    }}
                  ></textarea>
                </div>
                {/* <!-- ========== End joining message Section ========== --> */}

                {/* <!-- ========== Start thanks message Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="joinmessage"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Thanks Message")}
                  </label>
                  <textarea
                    name="summary"
                    id="summary"
                    cols="30"
                    rows="5"
                    className="form-control fs-sm shadow-none"
                    value={thanksMessage}
                    onChange={(e) => {
                      setthanksMessage(e.target.value);
                    }}
                  ></textarea>
                </div>
                {/* <!-- ========== End thanks message Section ========== --> */}

                {/* <!-- ========== Start banner Section ========== --> */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="banner"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Course Banner")}
                  </label>
                  <div className="form-group">
                    <div className="field-container d-flex align-items-center px-3 body-bg rounded-10 mb-3">
                      <label htmlFor="uploadDp" className="text-nowrap me-3">
                        {t("Upload Course Banner")}
                      </label>
                      <p className="form-control text-gray-600 border-0 rounded-0 shadow-none">
                        JPG, PNG, JPEG
                      </p>
                      <Link
                        to="#"
                        className="upload-file"
                        data-target="uploadBanner"
                        onClick={openImageFileInput}
                      >
                        <span className="d-block material-symbols-outlined icon-lg">
                          upload
                        </span>
                      </Link>
                      <input
                        type="file"
                        data-id="uploadBanner"
                        id="uploadedCourseImageFile"
                        className="d-none"
                        ref={imageFileInputRef}
                        onChange={imageUploadHandler}
                      />
                    </div>
                    <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
                      {isUploading ? (
                        <li>
                          <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                            <p className="fw-bold">
                              {t("Please wait while uploading")}
                            </p>{" "}
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                              return (
                                <div
                                  key={index}
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  style={{
                                    height: "5px",
                                    width: "5px",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </li>
                      ) : (
                        <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                          <Link to="#" onClick={closeEventImageFileHandler}>
                            <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                              delete
                            </span>
                          </Link>
                          <img
                            src={
                              uploadedPreviewImage === ""
                                ? "https://placehold.co/150x150"
                                : url.SERVER_URL + uploadedPreviewImage
                            }
                            alt="Uploaded"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {/* <!-- ========== End banner Section ========== --> */}

                {/* ----- auto accept section start ----- */}
                <div>
                  <label
                    htmlFor="banner"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Auto Accept")}
                  </label>{" "}
                  <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3">
                    <input
                      className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                      type="checkbox"
                      checked={autoAccept}
                      onChange={(e) => setautoAccept(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="autoaccept">
                      {t("Auto accept joining invitations")}
                    </label>
                  </div>
                </div>

                {/* ----- auto accept section end ----- */}

                {/* <!-- ========== Start button Section ========== --> */}
                <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between mt-5">
                  <Link to="/admin/courses" className="btn btn-outline-primary">
                    {t("Cancel")}
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={saveCourseHandler}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Save Now")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
                {/* <!-- ========== End button Section ========== --> */}
              </form>
            </div>
          </div>
        </section>
        {/* ======== popups and modal section =========== */}

        {/* ------- Upload Content Modal ------ */}
        <UploadContentModal
          selectedUploadId={selectedUploadId}
          setSelectedUploadId={setSelectedUploadId}
          uploadedFiles={uploadedFiles}
          setuploadedFiles={setuploadedFiles}
          uploadedFileIds={uploadedFileIds}
          setuploadedFileIds={setuploadedFileIds}
          setShowAlert={setShowAlert}
          setMessageType={setMessageType}
          setAlertMessage={setAlertMessage}
        />

        {/* ------- After Save Course Modal ------ */}
        <AfterSaveCourseModal
          afterSaveCourseModalClose={afterSaveCourseModalClose}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setcontentIndexForDelete(null);
            setcontetnIdForDelete(null);
          }}
          onDelete={deleteContentHandler}
          moduleName={t("content")}
        />
        {/* ------- Alert Notification ------ */}
        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseDetailBody;
