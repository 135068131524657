/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import membership options from json
import MembershipStatusOption from "data/Prod/MembershipStatus.json";
import { useTranslation } from "react-i18next";

const AddNewMemberModal = ({ roleList, afterCloseModal }) => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [userInput, setUserInput] = useState("");
  const [userOptions, setuserOptions] = useState([]);
  const [userValue, setUserValue] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [roleValue, setRoleValue] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [memberShipStatusValue, setMemberShipStatusValue] = useState(null);
  const [memberShipData, setmemberShipData] = useState(null);
  const [errorMessage, seterrorMessage] = useState("");

  //function for get user details
  const getUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&userstring=${userInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setuserOptions(response.data);
        } else {
          const emailMatch = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          let userData = {
            label: userInput,
            value: userInput,
            email: emailMatch.test(userInput) ? userInput : "",
            _id: null,
          };
          setuserOptions([userData]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user input
  const userInputHandler = (val) => {
    seterrorMessage("");
    setUserInput(val);
  };

  //function user Selection
  const userSelectionHandler = (val) => {
    seterrorMessage("");
    if (val) {
      setUserValue(val);
      setUserId(val._id);
      setUserEmail(val.email);
    } else {
      setUserValue(null);
      setUserId(null);
      setUserEmail("");
      setuserOptions([]);
    }
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setRoleData(val.value);
    seterrorMessage("");
  };

  //function for select memebership status
  const memberShipStatusSelectionHandler = (val) => {
    seterrorMessage("");
    setMemberShipStatusValue(val);
    setmemberShipData(val.value);
  };

  //function for reset user select
  const resetUserSelect = () => {
    setUserValue(null);
    setuserOptions([]);
  };

  //function for reset role select
  const resetRoleSelect = () => {
    setRoleValue(null);
    setRoleData(null);
  };

  //function for reset memberstatus select
  const resetMemberStatusSelect = () => {
    setMemberShipStatusValue(null);
    setmemberShipData(null);
  };

  //function for add event member
  const addChallengeMemberHandler = async () => {
    try {
      let challengeMemberData = {
        user: userId,
        challenge: params.id ? params?.id : null,
        role: roleData,
        useremail: userEmail,
        moderatorstatus: memberShipData,
        memberstatus: "1",
      };

      console.log("challengeMemberData---->", challengeMemberData);

      let requestUrl =
        url.API_BASE_URL + url.API_ADD_NEW_JOB_MEMBER + `?token=${token}`;

      const response = await postData(requestUrl, challengeMemberData);

      if (response.status) {
        resetHandler();

        //hide member modal
        let bootstrapModal = document.querySelector("#addChallengeMember");
        let modal = bootstrap.Modal.getInstance(bootstrapModal);
        modal.hide();

        //call member list api function
        afterCloseModal();
      } else {
        seterrorMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    resetUserSelect();
    resetRoleSelect();
    resetMemberStatusSelect();
    setUserInput("");
    setUserId(null);
    setUserEmail("");
    seterrorMessage("");
  };

  useEffect(() => {
    if (userInput.length > 3) {
      getUserList();
    }
  }, [userInput]);

  const optionsToShow = userOptions.length > 0 ? userOptions : [];

  return (
    <div
      className="modal fade"
      id="addChallengeMember"
      tabIndex="-1"
      aria-labelledby="addChallengeMember"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Add New Member")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="memberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Member (search by name, email)")}
                </label>
                <div className="d-flex align-items-center">
                  <Select
                    isClearable
                    className="w-100"
                    placeholder={t("Search by name, email")}
                    options={optionsToShow}
                    value={userValue}
                    onChange={(val) => {
                      userSelectionHandler(val);
                    }}
                    onInputChange={(val) => {
                      userInputHandler(val);
                    }}
                  />

                  <Link to="#" className="ms-1" onClick={resetUserSelect}>
                    <span className="d-block material-symbols-outlined">
                      delete
                    </span>
                  </Link>
                </div>
              </div>
              {/* ----- user select section end ----- */}

              {/* ----- role select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Role")}
                </label>
                <div className="d-flex align-items-center">
                  <Select
                    className="w-100"
                    placeholder={t("Select role")}
                    options={roleList}
                    value={roleValue}
                    onChange={(val) => roleSelectionHandler(val)}
                  />
                  <Link to="#" className="ms-1" onClick={resetRoleSelect}>
                    <span className="d-block material-symbols-outlined">
                      delete
                    </span>
                  </Link>
                </div>
              </div>
              {/* ----- role select section end ----- */}

              {/* ----- status select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Membership Status")}
                </label>
                <div className="d-flex align-items-center">
                  <Select
                    className="w-100"
                    placeholder={t("Select status")}
                    options={MembershipStatusOption}
                    value={memberShipStatusValue}
                    onChange={(val) => memberShipStatusSelectionHandler(val)}
                  />
                  <Link
                    to="#"
                    className="ms-1"
                    onClick={resetMemberStatusSelect}
                  >
                    <span className="d-block material-symbols-outlined">
                      delete
                    </span>
                  </Link>
                </div>
              </div>
              {/* ----- status select section end ----- */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("Cancel")}
                </Link>
                {errorMessage == "" ? null : (
                  <p className="text-danger">* {errorMessage}</p>
                )}
                {/* <Link to="#">
                  <span className="d-block material-symbols-outlined">
                    delete
                  </span>
                </Link> */}
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={addChallengeMemberHandler}
                >
                  {t("Save")}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewMemberModal;
