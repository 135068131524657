import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import GroupProductsOffersData from '../../../../../../../data/Groups/GroupProductsOffersData.json';
import GroupProductDetailsCommonHeader from '../../GroupProductDetailsCommonHeader/GroupProductDetailsCommonHeader';
import GroupProductOfferHeader from '../GroupProductOfferHeader/GroupProductOfferHeader';
import OfferDiscountModal from '../Modals/OfferDiscountModal';

const GroupProductOffersBody = () => {
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ row }) => (
          <td className="name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <p className="fs-md fw-semibold">{row.original.name}</p>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'fromDate',
        header: 'From Date',
        Cell: ({ row }) => (
          <td className="from-date border-bottom-0">
            <p>{row.original.fromDate}</p>
          </td>
        ),
      },
      {
        accessorKey: 'toDate',
        header: 'To Date',
        Cell: ({ row }) => (
          <td className="to-date border-bottom-0">
            <p>{row.original.toDate}</p>
          </td>
        ),
      },
      {
        accessorKey: 'minUnit',
        header: 'Min Unit',
        Cell: ({ row }) => (
          <td className="min-unit border-bottom-0">
            <p>{row.original.minUnit}</p>
          </td>
        ),
      },
      {
        accessorKey: 'maxUnit',
        header: 'Max Unit',
        Cell: ({ row }) => (
          <td className="max-unit border-bottom-0">
            <p>{row.original.maxUnit}</p>
          </td>
        ),
      },
      {
        accessorKey: 'discount',
        header: '% Discount',
        Cell: ({ row }) => (
          <td className="discount border-bottom-0">
            <p className="fs-md fw-semibold text-success">
              {row.original.discount}
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'price',
        header: 'Price',
        Cell: ({ row }) => (
          <td className="price border-bottom-0">
            <p className="fs-md fw-semibold">{row.original.price}</p>
          </td>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white">
        {/* --------- common header --------- */}
        <GroupProductDetailsCommonHeader activeTab="offers" />
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* ---------- header area ------- */}
          <GroupProductOfferHeader />

          {/* -------- table section ------------ */}
          {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="name bg-transparent fs-md fw-semibold border-0"
                      width="30%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllOffers"
                            name="offer"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Name</span>
                      </div>
                    </th>
                    <th className="from-date bg-transparent fs-md fw-semibold border-0">
                      From Date
                    </th>
                    <th className="to-date bg-transparent fs-md fw-semibold border-0">
                      To Date
                    </th>
                    <th className="min-unit bg-transparent fs-md fw-semibold border-0">
                      Min Unit
                    </th>
                    <th className="max-unit bg-transparent fs-md fw-semibold border-0">
                      Max Unit
                    </th>
                    <th className="discount bg-transparent fs-md fw-semibold border-0">
                      % Discount
                    </th>
                    <th className="price bg-transparent fs-md fw-semibold border-0">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer1"
                            value="offer1"
                          />
                        </div>
                        <p className="fs-md fw-semibold">New Year Offer</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer2"
                            value="offer2"
                          />
                        </div>
                        <p className="fs-md fw-semibold">New Joinee Offer</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer3"
                            value="offer3"
                          />
                        </div>
                        <p className="fs-md fw-semibold">BBD Offer</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer4"
                            value="offer4"
                          />
                        </div>
                        <p className="fs-md fw-semibold">Freedom Sale</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer5"
                            value="offer5"
                          />
                        </div>
                        <p className="fs-md fw-semibold">Mid Year Sale</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer6"
                            value="offer6"
                          />
                        </div>
                        <p className="fs-md fw-semibold">New Year Offer</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer7"
                            value="offer7"
                          />
                        </div>
                        <p className="fs-md fw-semibold">New Joinee Offer</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer8"
                            value="offer8"
                          />
                        </div>
                        <p className="fs-md fw-semibold">BBD Offer</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer9"
                            value="offer9"
                          />
                        </div>
                        <p className="fs-md fw-semibold">Freedom Sale</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="offer"
                            id="offer10"
                            value="offer10"
                          />
                        </div>
                        <p className="fs-md fw-semibold">New Year Offer</p>
                      </div>
                    </td>
                    <td className="from-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="to-date border-bottom-0">
                      <p>15-12-2022</p>
                    </td>
                    <td className="min-unit border-bottom-0">
                      <p>240</p>
                    </td>
                    <td className="max-unit border-bottom-0">
                      <p>680</p>
                    </td>
                    <td className="discount border-bottom-0">
                      <p className="fs-md fw-semibold text-success">25%</p>
                    </td>
                    <td className="price border-bottom-0">
                      <p className="fs-md fw-semibold">$84.06</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={GroupProductsOffersData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* ----------- offer discount modal ----------- */}
      <OfferDiscountModal />
    </div>
  );
};

export default GroupProductOffersBody;
