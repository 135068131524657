import Header from "components/Common/Header/Header";
import MyTaskListBody from "components/MyTaskComponents/MyTaskList/MyTaskListBody";
import React, { useEffect } from "react";

const MyTaskList = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "My Task";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="mytask" />

      <MyTaskListBody />
    </main>
  );
};

export default MyTaskList;
