/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import SuccessStoriesListBody from "components/SuccessStoriesComponents/SuccessStoriesListBody/SuccessStoriesListBody";

const SuccessStoriesList = () => {
  useEffect(() => {
    document.title = "Success Stories";
  }, []);

  return (
    <main id="app">
      <Header moduleName="successstories" />
      <SuccessStoriesListBody />
    </main>
  );
};

export default SuccessStoriesList;
