import Header from "components/Common/Header/Header";
import ProjectListBody from "components/ProjectManagementComponents/ProjectListComponents/ProjectListBody/ProjectListBody";
import React, { useEffect } from "react";

const ProjectList = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      {/* --------- body section ------------ */}
      <ProjectListBody />
    </main>
  );
};

export default ProjectList;
