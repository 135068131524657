import React from 'react';
import { Link } from 'react-router-dom';

const ODSConfirmationModal = () => {
  return (
    <div
      className="modal fade"
      id="odsConfirmation"
      tabIndex="-1"
      aria-labelledby="odsConfirmationLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="odsConfirmationLabel">
              ODS Confirmation
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <p className="fs-lg fw-semibold mb-0">Task Completed</p>
              <p className="fs-md fw-semibold text-primary">Pending</p>
            </div>
            <div className="row gx-3">
              <div className="col-lg-4 mb-3">
                <div className="body-bg p-3 text-center rounded-10">
                  <div
                    className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden mx-auto mb-3"
                    style={{
                      width: '54px',
                      height: '54px',
                      backgroundColor: '#E5233D',
                    }}
                  >
                    <img
                      src="/assets/img/family.png"
                      alt="Fin De La Pobreza"
                      className="img-fluid"
                    />
                  </div>
                  <p className="fs-md fw-semibold mb-0">Fin De La Pobreza</p>
                  <p className="fs-xs mb-3">3582 Points</p>
                  <Link to="#" className="btn btn-outline-primary">
                    Approve
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="body-bg p-3 text-center rounded-10">
                  <div
                    className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden mx-auto mb-3"
                    style={{
                      width: '54px',
                      height: '54px',
                      backgroundColor: '#DDA73A',
                    }}
                  >
                    <img
                      src="/assets/img/hot-soup.png"
                      alt="Hambre Cero"
                      className="img-fluid"
                    />
                  </div>
                  <p className="fs-md fw-semibold mb-0">Hambre Cero</p>
                  <p className="fs-xs mb-3">3582 Points</p>
                  <Link to="#" className="btn btn-outline-primary">
                    Approve
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="body-bg p-3 text-center rounded-10">
                  <div
                    className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden mx-auto mb-3"
                    style={{
                      width: '54px',
                      height: '54px',
                      backgroundColor: '#4DA247',
                    }}
                  >
                    <img
                      src="/assets/img/heart-report.png"
                      alt="Salud Y Bienestar"
                      className="img-fluid"
                    />
                  </div>
                  <p className="fs-md fw-semibold mb-0">Salud Y Bienestar</p>
                  <p className="fs-xs mb-3">3582 Points</p>
                  <Link to="#" className="btn btn-outline-primary">
                    Approve
                  </Link>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="body-bg p-3 text-center rounded-10">
                  <div
                    className="icon d-flex align-items-center justify-content-center rounded-circle overflow-hidden mx-auto mb-3"
                    style={{
                      width: '54px',
                      height: '54px',
                      backgroundColor: '#C8202F',
                    }}
                  >
                    <img
                      src="/assets/img/study.png"
                      alt="Educación De Calidad"
                      className="img-fluid"
                    />
                  </div>
                  <p className="fs-md fw-semibold mb-0">Educación De Calidad</p>
                  <p className="fs-xs mb-3">3582 Points</p>
                  <Link to="#" className="btn btn-outline-primary">
                    Approve
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ODSConfirmationModal;
