import Header from "components/Common/Header/Header";
import EventGalleryBody from "components/EventComponents/EventDetailsComponents/EventGalleryComponents/EventGalleryBody/EventGalleryBody";

const EventGallery = () => {
  return (
    <>
      <main id="app">
        {/* ---- header start ---- */}
        <Header moduleName="events" />
        {/* --- header end --- */}

        <EventGalleryBody />
      </main>
    </>
  );
};

export default EventGallery;
