/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const AddFollowerModal = ({
  moduleName = "",
  selectedIds = [],
  afterModalClose = () => {},
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const token = localStorage.getItem("token");

  const [followersInput, setFollowersInput] = useState("");
  const [folowersOptions, setFollowersOptions] = useState([]);

  const [followrsValue, setFollowrsValue] = useState(null);
  const [selectedFollowers, setSelectedFollowers] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    followerWarning: false,
  });

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (selectedFollowers.length === 0) {
      setValidation((prevState) => ({ ...prevState, followerWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get followers input
  const followersInputHandler = (val) => {
    setFollowersInput(val);
    setValidation((prevState) => ({ ...prevState, followerWarning: false }));
  };

  //function for get followers list
  const getFollowersList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${followersInput}`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setFollowersOptions(response.data);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //select followers
  const followersSelectionHandler = (val) => {
    setFollowrsValue(val);
    setSelectedFollowers(val.map((item) => item.value));
  };

  //function for save tag to user
  const addRemoveFollowersHandler = async (action) => {
    if (validationHandler()) {
      if (selectedIds.length > 0) {
        try {

          let requestUrl = url.API_BASE_URL;

          // select request url based on which module this label component is working on
          if (moduleName === "crmleadlist") {
            requestUrl += url.API_ADD_REMOVE_FOLLOWERS_LEAD;
          } else if (moduleName === "opportunity") {
            requestUrl += url.API_ADD_REMOVE_FOLLOWERS_INVOICE;
          } else if (moduleName === "task") {
            requestUrl += url.API_ADD_REMOVE_FOLLOWERS_TASK;
          } else {
            alert("error in module selection. please refresh once");
          }

          setIsSaving(true);

          for (let selectedId of selectedIds) {
            
            let followerData = {
              selectedfollowerids: selectedFollowers,
            };

            let followersUrl =
              requestUrl +
              `/${selectedId}` +
              `?token=${token}` +
              `&action=${action}`;

            const response = await putData(followersUrl, followerData);

            if (response.status) {
              setMessageType("success");
            } else {
              setMessageType("error");
            }

            setAlertMessage(response.message);
            setShowAlert(true);
          }

          setIsSaving(false);
          resetHandler();
          afterModalClose();

          // close the modal
          const bootstrapModal = document.querySelector("#addFollowers");
          const myModal = bootstrap.Modal.getInstance(bootstrapModal);
          myModal.hide();
        } catch (error) {
          setShowAlert(true);
          setAlertMessage(error.message);
          setMessageType("error");
        }
      } else {
        setShowAlert(true);
        setAlertMessage(`Please select data for follower execution`);
        setMessageType("error");
      }
    } else {
      setShowAlert(true);
      setAlertMessage(`Please select follower for follower execution`);
      setMessageType("error");
    }
  };

  // Reset all the form fields and state variables to their initial values
  const resetHandler = () => {
    setFollowersInput("");
    setFollowersOptions([]);
    setFollowrsValue(null);
    setSelectedFollowers([]);
    setValidation({
      followerWarning: false,
    });
  };

  useEffect(() => {
    if (followersInput.length > 0) {
      getFollowersList();
    }
  }, [followersInput]);

  const followersOptionsToShow =
    folowersOptions.length > 0 ? folowersOptions : [];

  return (
    <div
      className="modal fade"
      id="addFollowers"
      tabIndex="-1"
      aria-labelledby="addFollowers"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addFollowers">
              {t("Add Followers")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label
                  htmlFor="selectList"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Select Followers")}
                </label>
                <Select
                  isMulti
                  placeholder={t("Type user name or email")}
                  options={followersOptionsToShow}
                  value={followrsValue}
                  onChange={(val) => followersSelectionHandler(val)}
                  onInputChange={(val) => {
                    followersInputHandler(val);
                  }}
                />
                {validation.followerWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please select followers")}!</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="action d-flex align-items-center justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    addRemoveFollowersHandler("1");
                  }}
                  style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                  disabled={isSaving ? true : false}
                >
                  {t("Add Followers")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>

                {/* <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={resetHandler}
                >
                  {t("Remove Followers")}
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFollowerModal;
