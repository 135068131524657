/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
//Material UI components for rendring menu and others
import { MenuItem } from '@mui/material';
import CompanyListHeader from '../CompanyListHeader/CompanyListHeader';
import { Link } from 'react-router-dom';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import CompanyListFilterPopup from '../Popup/CompanyListFilterPopup';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

//import images
import { assetImages } from 'constants';
import AddToListModal from 'components/Common/Modal/AddToListModal';
import { useTranslation } from 'react-i18next';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const CompanyListBody = () => {
  const token = localStorage.getItem('token');
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');

  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);

  const [filterCompanyName, setFilterCompanyName] = useState('');
  const [filterCompanyTypes, setFilterCompanyTypes] = useState([]);
  const [filterCompanyTags, setFilterCompanyTags] = useState([]);

  //function for get all roles
  const getAllCompanyType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_COMPANY_TYPE + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCompanyTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all companies
  const getAllCompanyList = async () => {
    setRowSelection({});
    setSelectedCompanyIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&roleslug=${userInfo.role ? userInfo.role.slug : ""}`;

      if (filterCompanyName != '') {
        requestUrl = requestUrl + `&filtername=${filterCompanyName}`;
      }

      if (filterCompanyTypes.length > 0) {
        requestUrl = requestUrl + `&filtertypes=${filterCompanyTypes}`;
      }

      if (filterCompanyTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterCompanyTags}`;
      }

      console.log('url of app user list------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in app user list------>', response);

      setIsLoading(false);
      if (response.status) {
        setCompanyList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedCompanyList = [...companyList];

    const rowIndex = updatedCompanyList.findIndex(
      company => company._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedCompanyList[rowIndex].rank = value;
      setCompanyList(updatedCompanyList);
    }
  };

  //function for update rank
  const updateRankHandler = async e => {
    let responseArr = [];

    //run loop checkbox wise
    for (let selectedId of selectedCompanyIds) {
      //run loop of updated company
      for (let company of companyList) {
        let companyData = {};

        //check the cheked blog id match with changed rank filed blog id.If check then proceed
        if (company._id == selectedId) {
          companyData.rank = company.rank;

          try {
            let requestUrl =
              url.API_BASE_URL +
              url.API_UPDATE_COMPANY +
              `/${selectedId}` +
              `?token=${token}`;

            const response = await putData(requestUrl, companyData);

            if (response.status) {
              responseArr.push(response.status);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    }

    setRowSelection({});
    setSelectedCompanyIds([]);
    getAllCompanyList();
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectedCompanyIds) {
      try {
        let statusData = {
          status: '0',
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_COMPANY +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllCompanyList();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCompanyName('');
    setFilterCompanyTypes([]);
    setFilterCompanyTags([]);
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Company Name'),
        Cell: ({ row }) => (
          <div className="company-name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="company-info">
                <div className="d-flex align-items-center gap-2 mb-1">
                  <div
                    className="avatar rounded-circle overflow-hidden"
                    style={{ width: '35px', height: '35px' }}
                  >
                    <img
                      src={
                        row.original.logo
                          ? url.SERVER_URL + row.original.logo.path
                          : assetImages.defaultUser
                      }
                      alt="member"
                      className="w-100 h-100 object-fit-cover object-center"
                    />
                  </div>

                  <p className="fs-md">{row.original.name}</p>
                </div>
                <p className="d-flex align-items-center gap-1">
                  <span className="d-block material-symbols-outlined icon-md icon-fill text-primary">
                    place
                  </span>
                  <span className="d-block">
                    {row.original.city} {row.original.state},{' '}
                    {row.original.country}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'rank',
        header: t('Rank'),
        Cell: ({ row }) => (
          <div className="ranking border-bottom-0">
            <input
              type="text"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: '80px' }}
              value={row.original.rank}
              onChange={e => rankInputChangeHandler(row, e.target.value)}
            />
          </div>
        ),
      },
      {
        accessorKey: 'ownerfullname',
        header: t('Owner'),
        Cell: ({ row }) => (
          <div className="owner border-bottom-0 d-flex gap-1">
            <img
              className="rounded-circle"
              height={30}
              src={
                row.original.moderator.photoimage
                  ? url.SERVER_URL + row.original.moderator.photoimage.path
                  : assetImages.defaultUser
              }
              alt="Babcock"
            />
            <p>
              <span className="d-block">{row.original.ownerfullname}</span>
              <span className="d-block">{row.original.moderator.email}</span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'email',
        header: t('Email'),
        Cell: ({ row }) => (
          <div className="email border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-sm">
                email
              </span>
              <Link to="#">{row.original.email}</Link>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'phone',
        header: t('Phone'),
        Cell: ({ row }) => (
          <div className="phone border-bottom-0">
            <p className="d-flex align-items-center gap-1">
              <span className="d-block material-symbols-outlined icon-sm">
                phone_in_talk
              </span>
              <Link to="#"> {row.original.phone}</Link>
            </p>
          </div>
        ),
      },
      {
        accessorKey: 'tagstring',
        header: t('Label'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [companyList, i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedCompanyIds(selectedIdsArray);
    } else {
      setSelectedCompanyIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (
      filterCompanyName != '' ||
      filterCompanyTypes.length > 0 ||
      filterCompanyTags.length > 0
    ) {
      getAllCompanyList();
    }
  }, [filterCompanyName, filterCompanyTypes, filterCompanyTags]);

  useEffect(() => {
    getAllCompanyList();
    getAllTags();
    getAllCompanyType();
  }, []);

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_COMPANY')
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <CompanyListHeader
              reloadCompanyList={getAllCompanyList}
              updateRankHandler={updateRankHandler}
            />

            {/* --------- table area --------- */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={companyList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      key="edit"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/companies/info/${row.id}`}
                      >
                        View Details
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="members"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/companies/teammembers/${row.id}`}
                      >
                        View Members
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ---------- popups and modals area ------------- */}
        {/* -------- add label modal ------- */}
        <AddLabelModal
          selectedIds={selectedCompanyIds}
          moduleName="companylist"
          afterTagModalClose={getAllCompanyList}
        />

        {/* ------- filter popup ------- */}
        <CompanyListFilterPopup
          setFilterCompanyName={setFilterCompanyName}
          setFilterCompanyTypes={setFilterCompanyTypes}
          setFilterCompanyTags={setFilterCompanyTags}
          tagList={tagList}
          companyTypeList={companyTypeList}
        />

        {/* ------- add to list modal -------- */}
        <AddToListModal
          moduleName="company"
          selectedIds={selectedCompanyIds}
          afterModalClose={() => {
            setRowSelection({});
            setSelectedCompanyIds([]);
          }}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedCompanyIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t('company')}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CompanyListBody;
