/* eslint-disable */
import Header from "components/Common/Header/Header";
import GroupListBody from "components/GroupComponents/GroupList/GroupListBody/GroupListBody";
import React, { useEffect } from "react";

const GroupList = () => {
  useEffect(() => {
    document.title = "Group list";
  }, []);

  return (
    <main id="app">
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* --- body section ---- */}
      <GroupListBody />
    </main>
  );
};

export default GroupList;
