/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  getData,
  uploadSingleFile,
  uploadMultipleFile,
  postData,
  putData,
  deleteData,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const SaveTimeModal = ({
  afterModalClose,
  selectedDayId,
  setselectedDayId,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); // for translations
  const [dayNameString, setDayNameString] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    timeEmptyWarning: false,
    startTimeWarning: false,
    endTimeWarning: false,
    timeDiffWarning: false,
  });

  const timeFormTemplate = {
    starttime: "",
    endtime: "",
  };

  const [timeFormBlock, setTimeFormBlock] = useState([]);

  //add new time block
  const addNewTimeBlock = () => {
    setValidation((prevState) => ({
      ...prevState,
      timeEmptyWarning: false,
    }));
    setTimeFormBlock([...timeFormBlock, timeFormTemplate]);
  };

  //delete time block
  const onDeleteBlock = async (timeData, index) => {
    setValidation((prevState) => ({
      ...prevState,
      startTimeWarning: false,
      endTimeWarning: false,
      timeDiffWarning: false,
    }));

    if (timeData._id) {
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_TIME_AVAILIBILITY +
          `/${timeData._id}?token=${token}`;

        const response = await deleteData(requestUrl);

        if (response.status) {
          afterModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    setTimeFormBlock([
      ...timeFormBlock.slice(0, index),
      ...timeFormBlock.slice(index + 1),
    ]);
  };

  //input changes handler
  const changeInputHandler = (index, field, value) => {
    setValidation((prevState) => ({
      ...prevState,
      startTimeWarning: false,
      endTimeWarning: false,
      timeDiffWarning: false,
    }));

    if (field === "starttime") {
      const startTime = new Date(`2024-01-01T${value}`);
      const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // Adding 1 hour
      const formattedEndTime = `${String(endTime.getHours()).padStart(
        2,
        "0"
      )}:${String(endTime.getMinutes()).padStart(2, "0")}`;
      setTimeFormBlock(
        timeFormBlock.map((block, i) =>
          index === i
            ? { ...block, [field]: value, endtime: formattedEndTime }
            : block
        )
      );
    } else {
      setTimeFormBlock(
        timeFormBlock.map((block, i) =>
          index === i ? { ...block, [field]: value } : block
        )
      );
    }

    // setTimeFormBlock(
    //   timeFormBlock.map((block, i) =>
    //     index === i ? { ...block, [field]: value } : block
    //   )
    // );
  };

  //input data maping from details
  const updatedBlockData = (timeAvailibilityIds) => {
    let updatedLinkArr = [];

    timeAvailibilityIds.map((timeAvailibilityId) => {
      let savedFormData = {
        _id: timeAvailibilityId?._id,
        starttime: timeAvailibilityId?.starttime,
        endtime: timeAvailibilityId?.endtime,
      };
      updatedLinkArr.push(savedFormData);
    });
    setTimeFormBlock(updatedLinkArr);
  };

  //get details
  const getDayAvailibilityDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DAYS_AVAILIBILITY_DETAILS +
        `/${selectedDayId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setDayNameString(response.data.dayname);
        updatedBlockData(response.data.timeavailibilityids);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (timeFormBlock.length > 0) {
      timeFormBlock.forEach((timeData) => {
        if (timeData.starttime === "") {
          setValidation((prevState) => ({
            ...prevState,
            startTimeWarning: true,
          }));
          isValid = false;
        }

        if (timeData.endtime === "") {
          setValidation((prevState) => ({
            ...prevState,
            endTimeWarning: true,
          }));
          isValid = false;
        }

        if (
          timeData.starttime !== "" &&
          timeData !== "" &&
          timeData.starttime > timeData.endtime
        ) {
          setValidation((prevState) => ({
            ...prevState,
            timeDiffWarning: true,
          }));
          isValid = false;
        }
      });
    } else {
      setValidation((prevState) => ({
        ...prevState,
        timeEmptyWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //save time
  const saveTimeAvailibilityHandler = async () => {
    if (validate()) {
      try {
        let timeAvailibilityData = {
          dayavailibility: selectedDayId,
          timeavailbilities: timeFormBlock,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_ADD_TIME_AVAILIBILITY_OF_DAY +
          `?token=${token}`;

        const response = await postData(requestUrl, timeAvailibilityData);

        // console.log(response);

        if (response.status) {
          resetHandler();
          //hide modal
          let formModal = document.querySelector("#editTimeForDay");
          let modal = bootstrap.Modal.getInstance(formModal);
          modal.hide();

          afterModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset
  const resetHandler = () => {
    setTimeFormBlock([]);
    setselectedDayId(null);
    setValidation({
      timeEmptyWarning: false,
      startTimeWarning: false,
      endTimeWarning: false,
      timeDiffWarning: false,
    });
  };

  useEffect(() => {
    if (selectedDayId) {
      getDayAvailibilityDetails();
    }
  }, [selectedDayId]);

  return (
    <div
      className="modal fade"
      id="editTimeForDay"
      tabIndex="-1"
      aria-labelledby="editTimeForDay"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="editDayLabel">
              {t("Save Time for")} {dayNameString}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label
                  htmlFor="title"
                  className="d-block fs-sm fw-semibold mb-2 text-danger"
                >
                  ** {t("Each slot should be in 1 hour")}
                </label>
              </div>

              <div className="form-group d-flex mb-4">
                <div className="field-group d-flex flex-column gap-2 flex-fill">
                  <label htmlFor="timing" className="d-block fs-sm fw-semibold">
                    {t("Timings")}
                  </label>
                  {timeFormBlock.map((timeData, index) => {
                    return (
                      <div
                        className="field-container d-flex align-items-center gap-2"
                        key={index}
                      >
                        <div>
                          <input
                            type="time"
                            className="form-control fs-sm shadow-none"
                            placeholder="Start Time"
                            value={timeData.starttime}
                            onChange={(e) => {
                              changeInputHandler(
                                index,
                                "starttime",
                                e.target.value
                              );
                            }}
                          />
                          {validation.startTimeWarning && (
                            <div className="error-message mt-2">
                              <p className="d-flex align-items-center gap-1 text-danger">
                                <span className="material-symbols-outlined">
                                  warning
                                </span>
                                <span>Please add start time!</span>
                              </p>
                            </div>
                          )}
                        </div>

                        <span className="d-inline-block">To</span>
                        <div>
                          <input
                            type="time"
                            className="form-control fs-sm shadow-none"
                            placeholder="End Time"
                            value={timeData.endtime}
                            onChange={(e) => {
                              changeInputHandler(
                                index,
                                "endtime",
                                e.target.value
                              );
                            }}
                          />
                          {validation.endTimeWarning && (
                            <div className="error-message mt-2">
                              <p className="d-flex align-items-center gap-1 text-danger">
                                <span className="material-symbols-outlined">
                                  warning
                                </span>
                                <span>Please add end time!</span>
                              </p>
                            </div>
                          )}
                          {validation.timeDiffWarning && (
                            <div className="error-message mt-2">
                              <p className="d-flex align-items-center gap-1 text-danger">
                                <span className="material-symbols-outlined">
                                  warning
                                </span>
                                <span>
                                  End time should not less then start time!
                                </span>
                              </p>
                            </div>
                          )}
                        </div>

                        <Link
                          to="#"
                          onClick={() => {
                            onDeleteBlock(timeData, index);
                          }}
                        >
                          <span className="d-block material-symbols-outlined">
                            close
                          </span>
                        </Link>
                      </div>
                    );
                  })}

                  {/* empty warning */}
                  {validation.timeEmptyWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>
                          Please add time block by clicking plus icon!
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="action d-flex align-items-center justify-content-between">
                <button
                  className="btn btn-outline-primary d-inline-flex align-items-center gap-1"
                  onClick={addNewTimeBlock}
                >
                  <span className="d-block material-symbols-outlined">add</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveTimeAvailibilityHandler}
                >
                  {t("Save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveTimeModal;
