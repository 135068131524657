/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

// Material UI table
import { MaterialReactTable } from "material-react-table";
import LessonsTaskData from "../../../../../../../data/Courses/LessonsTaskData.json";

import CommonTabs from "../../CommonTabs/CommonTabs";
import TaskHeader from "../TaskHeader/TaskHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import SaveTaskPopup from "../Popup/SaveTaskPopup";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import MyTaskFilterPopup from "components/Common/Popup/MyTaskFilterPopup";

const TaskBody = () => {
  const { t } = useTranslation(); //for translation
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [title, settitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [reloadData, setReloadData] = useState(false);

  const [filterTaskDateRangeType, setFilterTaskDateRangeType] = useState("");
  const [filterTaskFromDate, setfilterTaskFromDate] = useState("");
  const [filterTaskToDate, setfilterTaskToDate] = useState("");
  const [filterTaskCustomIds, setfilterTaskCustomIds] = useState([]);
  const [filterTaskTitle, setfilterTaskTitle] = useState("");
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskAssignedToUsers, setfilterTaskAssignedToUsers] = useState(
    []
  );
  const [filterTaskAssignedByUsers, setfilterTaskAssignedByUsers] = useState(
    []
  );
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);
  const [isFilterReset, setIsFilterReset] = useState(false);
  //get lesson details
  const getLessonDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_LESSON_DETAILS +
        `/${params.lessonid}?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all tasks
  const getAllTask = async () => {
    setSelectedTaskIds([]);
    setRowSelection({});

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&filtercourse=${params.id}&filterlesson=${params.lessonid}`;

      if (filterTaskDateRangeType != "") {
        requestUrl =
          requestUrl + `&filterdaterangetype=${filterTaskDateRangeType}`;
      }

      if (filterTaskFromDate != "" || filterTaskToDate != "") {
        console.log(`filterTaskFromDate`, filterTaskFromDate);
        requestUrl =
          requestUrl +
          `&filterfromdate=${filterTaskFromDate}&filtertodate=${filterTaskToDate}`;
      }

      if (filterTaskCustomIds.length > 0) {
        requestUrl = requestUrl + `&filtertaskcustomids=${filterTaskCustomIds}`;
      }

      if (filterTaskTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
      }

      if (filterTaskLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
      }

      if (filterTaskStatus.length > 0) {
        requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
      }

      if (filterTaskPriorities.length > 0) {
        requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
      }

      if (filterTaskProjects.length > 0) {
        requestUrl = requestUrl + `&filterprojects=${filterTaskProjects}`;
      }

      if (filterTaskAssignedByUsers.length > 0) {
        requestUrl =
          requestUrl + `&filtermoderators=${filterTaskAssignedByUsers}`;
      }

      if (filterTaskAssignedToUsers.length > 0) {
        requestUrl = requestUrl + `&filterusers=${filterTaskAssignedToUsers}`;
      }

      // console.log(`getAllTask requestUrl =============>>>`, requestUrl);

      const response = await getData(requestUrl);

      // console.log('response in task list------->', response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setTaskList(response.data);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].rank = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for update rank
  const updateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        //run loop of updated company
        for (let task of taskList) {
          let taskData = {};

          //check the cheked blog id match with changed rank filed blog id.If check then proceed
          if (task._id == updatedTaskId) {
            taskData.rank = task.rank;
            taskData.taskpercent = task.taskpercent;
            taskData.startdate = task.startdate;
            taskData.enddate = task.enddate;
            taskData.hours = task.hours;

            try {
              let requestUrl =
                url.API_BASE_URL +
                url.API_UPDATE_PROJECT_TASK +
                `/${updatedTaskId}` +
                `?token=${token}`;

              const response = await putData(requestUrl, taskData);

              // console.log(response);

              if (response.status) {
                setMessageType("success");
                responseArr.push(response.status);
              } else {
                setMessageType("error");
              }

              setAlertMessage(response.message);
            } catch (error) {
              setAlertMessage(error.message);
              setMessageType("error");
            }

            setShowAlert(true);
          }
        }
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTask();
        }, 2200);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#saveTask"
            aria-controls="saveTask"
            onClick={() => {
              setSelectedTaskId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "customid",
        header: t("ID"),
        size: 100,
      },
      {
        accessorKey: "title",
        header: t("Title"),
        size: 200,
      },
      {
        accessorKey: "rank",
        header: t("Rank"),
        Cell: ({ row }) => (
          <div className="ranking border-bottom-0">
            {row.original.moderator._id.toString() ===
              userInfo._id.toString() ||
            userInfo.role.slug === "ADMIN" ||
            userInfo.role.slug === "SUPER_ADMIN" ? (
              <input
                type="number"
                className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
                style={{ width: "100px" }}
                value={row.original.rank}
                onChange={(e) => rankInputChangeHandler(row, e.target.value)}
              />
            ) : (
              <p className="ps-3">{row.original.rank}</p>
            )}
          </div>
        ),
      },
      {
        accessorKey: "price",
        header: t("Score"),
      },

      {
        accessorKey: "hours",
        header: t("Duration Minutes"),
      },
      {
        accessorKey: "moderatorname",
        header: t("Assigned By"),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    []
  );

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //delete multiple tasks
  const deActivateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        try {
          let requestUrl =
            url.API_BASE_URL +
            url.API_DEACTIVATE_PROJECT_TASK +
            `/${updatedTaskId}` +
            `?token=${token}`;
          console.log(`deActivateBulkTaskHandler requestUrl`, requestUrl);
          const response = await putData(requestUrl);

          if (response.status) {
            setMessageType("success");
            responseArr.push(response.status);
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType("error");
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTask();
        }, 1500);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //refresh icon function
  const refreshRecords = () => {
    resetFilterData();
    // setVisibleColoumns(initialVisibilityState);
    // setColumnOrder(initialColumnOrder);
  };

  //* Function for reset filter
  const resetFilterData = () => {
    setFilterTaskDateRangeType("");
    setfilterTaskFromDate("");
    setfilterTaskToDate("");
    setfilterTaskCustomIds([]);
    setfilterTaskTitle("");
    setfilterTaskLabels([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setfilterTaskProjects([]);
    setfilterTaskAssignedToUsers([]);
    setfilterTaskAssignedByUsers([]);

    setReloadData(true);
    // reset pagination to default
    // setLastPagination({
    //   pageIndex: 0,
    //   pageSize: 10,
    // });
    // setPagination({
    //   pageIndex: 0,
    //   pageSize: 10,
    // });
  };

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  // useEffect(() => {}, [rowSelection]);
  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllTask();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    if (
      filterTaskDateRangeType != "" ||
      filterTaskFromDate != "" ||
      filterTaskToDate != "" ||
      filterTaskTitle != "" ||
      filterTaskLabels.length > 0 ||
      filterTaskCustomIds.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskProjects.length > 0 ||
      filterTaskAssignedByUsers.length > 0 ||
      filterTaskAssignedToUsers.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      getAllTask();
    }
  }, [
    filterTaskDateRangeType,
    filterTaskFromDate,
    filterTaskToDate,
    filterTaskCustomIds,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskProjects,
    filterTaskAssignedByUsers,
    filterTaskAssignedToUsers,
  ]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTaskIds(selectedIdsArray);
    } else {
      setSelectedTaskIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (params.lessonid) {
      getLessonDetails();
      getAllTask();
    }
  }, [params.lessonid]);

  return (
    <div id="content_wrapper">
      <section className="course-details-wrapper bg-white pb-5">
        {/* -------------- common tab ----------- */}
        {params.id && <CommonTabs activeTab="task" />}
        <div className="container-fluid px-lg-5">
          {/* --------- filter area ---------- */}
          <TaskHeader
            title={title}
            reloadList={refreshRecords}
            updateBulkTaskHandler={updateBulkTaskHandler}
          />

          {/* ---------- table area ------------- */}
          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={taskList} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                enableColumnOrdering={true}
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>

      {/* --------- add label modal --------------- */}
      <AddLabelModal
        moduleName="task"
        selectedIds={selectedTaskIds}
        afterTagModalClose={getAllTask}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {/* --------- filter popup ----------- */}
      <MyTaskFilterPopup
        // visibleColoumns={visibleColoumns}
        // setVisibleColoumns={setVisibleColoumns}
        // columnOrder={columnOrder}
        // setColumnOrder={setColumnOrder}
        // columnFilterValues={columnFilterValues}
        // setColumnFilterValues={setColumnFilterValues}
        // showGlobalFilters={showGlobalFilters}
        // setShowGlobalFilters={setShowGlobalFilters}
        isFilterReset={isFilterReset}
        setIsFilterReset={setIsFilterReset}
        filterTaskDateRangeType={filterTaskDateRangeType}
        setFilterTaskDateRangeType={setFilterTaskDateRangeType}
        filterTaskFromDate={filterTaskFromDate}
        setfilterTaskFromDate={setfilterTaskFromDate}
        filterTaskToDate={filterTaskToDate}
        setfilterTaskToDate={setfilterTaskToDate}
        filterTaskCustomIds={filterTaskCustomIds}
        setfilterTaskCustomIds={setfilterTaskCustomIds}
        filterTaskTitle={filterTaskTitle}
        setfilterTaskTitle={setfilterTaskTitle}
        filterTaskProjects={filterTaskProjects}
        setfilterTaskProjects={setfilterTaskProjects}
        filterTaskAssignedByUsers={filterTaskAssignedByUsers}
        setfilterTaskAssignedToUsers={setfilterTaskAssignedToUsers}
        filterTaskAssignedToUsers={filterTaskAssignedToUsers}
        setfilterTaskAssignedByUsers={setfilterTaskAssignedByUsers}
        filterTaskStatus={filterTaskStatus}
        setfilterTaskStatus={setfilterTaskStatus}
        filterTaskPriorities={filterTaskPriorities}
        setfilterTaskPriorities={setfilterTaskPriorities}
        filterTaskLabels={filterTaskLabels}
        setfilterTaskLabels={setfilterTaskLabels}
        moduleName="courseLessonTask"
      />

      {/* --------- add task popup --------- */}
      <SaveTaskPopup
        selectedTaskId={selectedTaskId}
        setSelectedTaskId={setSelectedTaskId}
        AfterPopupClose={getAllTask}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {/* ----------- delete warning modal ------------ */}
      <DeleteWarningModal
        onCancelDelete={() => {
          setRowSelection({});
          setSelectedTaskIds([]);
        }}
        onDelete={deActivateBulkTaskHandler}
        moduleName={t("task")}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default TaskBody;
