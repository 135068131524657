/* eslint-disable */
import React, { useEffect, useState, useMemo, useRef } from "react";

import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

// import { Editor } from "@tinymce/tinymce-react";
import JoditEditor from "jodit-react";

//image resize for editor
import { resizeOrCompressImage } from "helper/Common/CommonHelper";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
import downloadFileHandler from "helper/DownloadFile";
import { joditEditorConfig } from "Config/Config";

const SaveTaskTab = ({
  moduleName,
  taskContactId,
  taskLeadId,
  taskProjectId,
  sectionOptions,
  memberList,
  taskMileStone = false,
  taskCustomId,
  taskTitle,
  taskAssignedUserValue,
  taskAssignedUserId,
  taskCurrentStatusValue,
  taskCurrentStatus,
  taskFollowersvalue = null,
  taskFollowersSelected,
  taskDetails = "",
  taskSectionValue = null,
  taskSelectedSectionId = null,
  previousTaskValue = null,
  previousTaskIdOfTask = null,
  taskHours = "",
  taskPrice = "",
  taskStartDate = "",
  taskEndDate = "",
  taskPriorityValue = null,
  taskPriorityData = "",
  taskPercent = 0,
  taskRank = 1,
  taskFiles = [],
  taskFileIds = [],
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const todayValue = new Date().toISOString().split("T")[0];

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const params = useParams();
  const token = localStorage.getItem("token");

  const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload doc

  const docFileInputRef = useRef(null);

  const [assignedUserInput, setAssignedUserInput] = useState("");
  const [assignedUserOptions, setAssignedUserOptions] = useState([]);

  const [followersInput, setFollowersInput] = useState("");
  const [folowersOptions, setFolowersOptions] = useState([]);

  const [taskInput, settaskInput] = useState("");
  const [taskListOptions, settaskListOptions] = useState([]);

  const [title, settitle] = useState(taskTitle);

  const [assignedUserValue, setassignedUserValue] = useState(
    taskAssignedUserValue
  );
  const [assignedUserId, setassignedUserId] = useState(taskAssignedUserId);

  const [taskStatusValue, settaskStatusValue] = useState(
    taskCurrentStatusValue
  );
  const [taskStatus, settaskStatus] = useState(taskCurrentStatus);

  const [followrsValue, setFollowrsValue] = useState(taskFollowersvalue);
  const [selectedFollowers, setSelectedFollowers] = useState(
    taskFollowersSelected
  );

  const [details, setdetails] = useState(taskDetails);

  const [sectionValue, setsectionValue] = useState(taskSectionValue);
  const [selectedSectionId, setselectedSectionId] = useState(
    taskSelectedSectionId
  );

  const [prevTaskValue, setPrevTaskValue] = useState(previousTaskValue);
  const [previousTaskId, setPreviousTaskId] = useState(previousTaskIdOfTask);

  const [hours, sethours] = useState(taskHours);
  const [price, setPrice] = useState(taskPrice);
  const [startDate, setstartDate] = useState(taskStartDate);
  const [endDate, setendDate] = useState(taskEndDate);

  const [priorityValue, setpriorityValue] = useState(taskPriorityValue);
  const [priority, setpriority] = useState(taskPriorityData);

  const [rank, setRank] = useState(taskRank);

  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState(taskFiles);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState(taskFileIds);
  const [isMilestone, setIsMilestone] = useState(taskMileStone);

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    // memberWarning: false,
    nameWarning: false,
    sectionWarning: false,
    statusWarning: false,
    hoursWarning: false,
    fromDateWarning: false,
    toDateWarning: false,
    dateCompareWarning: false,
    priorityWarning: false,
  });

  const editor = useRef(null);

  //const editorCustomConfig = joditEditorConfig();
  const editorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 400,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestUrl =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestUrl, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  // jodit config 2 ends -----------------------------------------------

  const editorConfig = useMemo(() => editorCustomConfig, []);

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (hours === "") {
      setValidation((prevState) => ({
        ...prevState,
        hoursWarning: true,
      }));
      isValid = false;
    }

    if (startDate === "") {
      setValidation((prevState) => ({
        ...prevState,
        fromDateWarning: true,
      }));
      isValid = false;
    }

    if (endDate === "") {
      setValidation((prevState) => ({ ...prevState, toDateWarning: true }));
      isValid = false;
    }

    if (startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        setValidation((prevState) => ({
          ...prevState,
          dateCompareWarning: true,
        }));
        isValid = false;
      }
    }

    //     if (!sectionValue) {
    //       setValidation((prevState) => ({
    //         ...prevState,
    //         sectionWarning: true,
    //       }));
    //       isValid = false;
    //     }

    // if (!assignedUserValue) {
    //   setValidation((prevState) => ({ ...prevState, memberWarning: true }));
    //   isValid = false;
    // }

    if (taskStatus == "") {
      setValidation((prevState) => ({ ...prevState, statusWarning: true }));
      isValid = false;
    }

    if (priority == "") {
      setValidation((prevState) => ({
        ...prevState,
        priorityWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //get all tasks for select previous task
  const getAllPreviousTask = async () => {
    let selectedSectionId = null;

    if (sectionValue) {
      selectedSectionId = sectionValue.value;
    }

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_TASKLIST_BY_SEARCH +
        `?token=${token}&searchtitle=${taskInput}&projectid=${taskProjectId}`;

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in task list------->", response);

      if (response.status) {
        settaskListOptions(response.data);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //select section
  const sectionSelectionHandler = (val) => {
    setsectionValue(val);
    setselectedSectionId(val.value);
    setstartDate(val.startdate);
    setendDate(val.enddate);
  };

  //select status
  const statusSelectionHandler = (val) => {
    settaskStatusValue(val);
    settaskStatus(val.value);
  };

  //select priority
  const prioritySelectionHandler = (val) => {
    setpriorityValue(val);
    setpriority(val.value);
  };

  //function for get assigned user list
  const getAssignedUserList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_FOR_TASK +
        `?token=${token}` +
        `&modulename=${moduleName}&userstring=${assignedUserInput}&projectid=${taskProjectId}&optiontype=assigneduser`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setAssignedUserOptions(response.data);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for get followers list
  const getFollowersList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_FOR_TASK +
        `?token=${token}` +
        `&modulename=${moduleName}&userstring=${followersInput}&projectid=${taskProjectId}&optiontype=follower`;

      const response = await getData(requestUrl);

      if (response.status) {
        if (response.data.length > 0) {
          setFolowersOptions(response.data);
        }
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for get user input
  const assignedUserInputHandler = (val) => {
    setAssignedUserInput(val);
    seterrorMessage("");
  };

  //function for get followers input
  const followersInputHandler = (val) => {
    setFollowersInput(val);
    seterrorMessage("");
  };

  //select assigned user
  const assignedUserSelectionHandler = (val) => {
    if (val) {
      setassignedUserValue(val);
      setassignedUserId(val.value);
    } else {
      setassignedUserValue(null);
      setassignedUserId(null);
      setAssignedUserInput("");
      setAssignedUserOptions([]);
    }
  };

  //select followers
  const followersSelectionHandler = (val) => {
    setFollowrsValue(val);
    setSelectedFollowers(val.map((item) => item.value));
  };

  //select previous task
  const previousTaskSelectionHandler = (val) => {
    if (val) {
      setPrevTaskValue(val);
      setPreviousTaskId(val.value);
      setstartDate(val.enddate);
      setendDate("");
    } else {
      setPrevTaskValue(null);
      setPreviousTaskId(null);
      settaskListOptions([]);
      // setstartDate("");
      // setendDate("");
    }
  };

  //function for open doc imput
  const openDocFileInput = () => {
    docFileInputRef.current.click();
  };

  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);

      const docFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, docFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }

        /*----- assign uploaded files with path for display end ------*/
        setDocFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
      resetDocInput();
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];
    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);
    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);
    resetDocInput();
  };

  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedTaskDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //save task
  const saveTaskHandler = async () => {
    if (validationHandler()) {
      seterrorMessage("");

      try {
        setIsSaving(true);

        let taskData = {
          leadid: taskLeadId,
          project: taskProjectId,
          contact: taskContactId,
          ismilestone: isMilestone,
          projectsection: selectedSectionId,
          previoustask: previousTaskId,
          title,
          description: details,
          startdate: startDate,
          enddate: endDate,
          hours,
          price,
          priority,
          completedpercent: taskStatus,
          taskpercent: taskPercent,
          assigneduser: assignedUserId,
          followers: selectedFollowers,
          rank: rank || 1,
          uploads: uploadedDocFileIds,
        };

        // console.log(taskData);

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_PROJECT_TASK +
          `/${params.id}?token=${token}`;

        const response = await putData(requestUrl, taskData);

        setIsSaving(false);

        // console.log(response);

        if (response.status) {
          // resetHandler();
          setMessageType("success");
        } else {
          seterrorMessage(response.message);
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      seterrorMessage("Please fill up all required fields");
      setAlertMessage("Please fill up all required fields");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (taskInput.length > 1) {
      getAllPreviousTask();
    }
  }, [taskInput]);

  // Fetches the assigned user list when the length of the assignedUserInput is greater than 3.
  // This is done to prevent unnecessary API calls when the input is too short.
  useEffect(() => {
    if (assignedUserInput.length > 1) {
      getAssignedUserList();
    }
  }, [assignedUserInput]);

  useEffect(() => {
    if (followersInput.length > 1) {
      getFollowersList();
    }
  }, [followersInput]);

  const assignedUserOptionsToShow =
    moduleName == "lead" || moduleName == "contact"
      ? memberList
      : assignedUserOptions.length > 0
      ? assignedUserOptions
      : [];

  const folowersOptionsToShow =
    moduleName == "lead" || moduleName == "contact"
      ? memberList
      : folowersOptions.length > 0
      ? folowersOptions
      : [];

  const taskOptionsToShow = taskListOptions.length > 0 ? taskListOptions : [];

  return (
    <div className="p-4">
      <h2>
        {taskCustomId ? (
          <span className="badge bg-dark me-3">{taskCustomId}</span>
        ) : null}
        {title}
      </h2>{" "}
      <form onSubmit={(e) => e.preventDefault()}>
        {/* <!-- ========== Start assigned user,milestone and status Section ========== --> */}
        <div className="form-group mb-4">
          <div className="row">
            <div className="col-md-2">
              <label
                htmlFor="status"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Is Milestone")}
              </label>
              <input
                className="large-checkbox"
                type="checkbox"
                checked={isMilestone}
                onChange={(e) => setIsMilestone(e.target.checked)}
              />
            </div>

            <div className="col-md-5">
              <label
                htmlFor="assignTo"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Assigned to")}
              </label>
              <Select
                isClearable
                placeholder={
                  moduleName == "lead" || moduleName == "contact"
                    ? t("Select Member")
                    : t("Type member name or email")
                }
                options={assignedUserOptionsToShow}
                value={assignedUserValue}
                onChange={(val) => {
                  assignedUserSelectionHandler(val);
                  // setValidation((prevState) => ({
                  //   ...prevState,
                  //   memberWarning: false,
                  // }));
                  seterrorMessage("");
                }}
                onInputChange={(val) => {
                  assignedUserInputHandler(val);
                }}
              />
              {/* {validation.memberWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select member!")}</span>
                  </p>
                </div>
              )} */}
            </div>
            <div className="col-md-5">
              <label
                htmlFor="status"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Complete Status")}
              </label>
              <Select
                placeholder={t("Select task status")}
                options={TaskStatusOptions}
                value={taskStatusValue}
                onChange={(val) => {
                  statusSelectionHandler(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    statusWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              {validation.statusWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select status!")}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <!-- ========== End assigned user and status Section ========== --> */}

        {/* <!-- ========== Start followers Section ========== --> */}
        <div className="form-group mb-4">
          <label htmlFor="assignTo" className="d-block fs-sm fw-semibold mb-2">
            {t("Followers")}
          </label>
          <Select
            isMulti
            placeholder={
              moduleName == "lead" || moduleName == "contact"
                ? t("Select Member")
                : t("Type member name or email")
            }
            options={folowersOptionsToShow}
            value={followrsValue}
            onChange={(val) => {
              followersSelectionHandler(val);
              // setValidation((prevState) => ({
              //   ...prevState,
              //   memberWarning: false,
              // }));
              seterrorMessage("");
            }}
            onInputChange={(val) => {
              followersInputHandler(val);
            }}
          />
        </div>
        {/* <!-- ========== End followers Section ========== --> */}

        {/* <!-- ========== Start title Section ========== --> */}
        <div className="form-group mb-4">
          <label htmlFor="taskName" className="d-block fs-sm fw-semibold mb-2">
            {t("Task Name")}
          </label>
          <input
            type="text"
            id="taskName"
            className="form-control fs-sm shadow-none"
            placeholder={t("Enter Name")}
            value={title}
            onChange={(e) => {
              settitle(e.target.value);
              setValidation((prevState) => ({
                ...prevState,
                nameWarning: false,
              }));
              seterrorMessage("");
            }}
          />
          {validation.nameWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter title!")}</span>
              </p>
            </div>
          )}
        </div>
        {/* <!-- ========== End title Section ========== --> */}

        {/* <!-- ========== Start details Section ========== --> */}
        <div className="form-group mb-4">
          <label htmlFor="details" className="d-block fs-sm fw-semibold mb-2">
            {t("Details")}
          </label>

          <JoditEditor
            ref={editor}
            value={details}
            config={editorConfig} //{joditconfig}
            onChange={(newContent, event) => {
              console.log("newCOntent to save", newContent);
              setdetails(newContent);
            }}
          />
        </div>
        {/* <!-- ========== End details Section ========== --> */}

        {/* <!-- ========== Start section and previous task Section ========== --> */}
        <div className="form-group mb-4">
          <div className="row">
            {selectedSectionId && (
              <div className="col-md-6 mb-4 mb-md-0">
                <label
                  htmlFor="section"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Section")}
                </label>
                <Select
                  placeholder={t("Select Section")}
                  options={sectionOptions}
                  value={sectionValue}
                  onChange={(val) => {
                    sectionSelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      sectionWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                {validation.sectionWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please select section!")}</span>
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className={selectedSectionId ? "col-md-6" : "col-md-12"}>
              <label
                htmlFor="status"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Previous task to complete (If Any)")}
              </label>
              <Select
                isClearable
                placeholder={t("Type task name")}
                options={taskOptionsToShow}
                value={prevTaskValue}
                onChange={(val) => {
                  previousTaskSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  settaskInput(val);
                }}
              />
            </div>
          </div>
        </div>
        {/* <!-- ========== End section and previous task Section ========== --> */}

        {/* hours and price */}
        <div className="form-group mb-4">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <label htmlFor="time" className="d-block fs-sm fw-semibold mb-2">
                {t("Time (Hours)")}
              </label>
              <input
                type="number"
                id="time"
                min={0}
                className="form-control fs-sm shadow-none"
                placeholder={t("Hours required")}
                value={hours}
                onChange={(e) => {
                  sethours(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    hoursWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              {validation.hoursWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter hours!")}</span>
                  </p>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="price" className="d-block fs-sm fw-semibold mb-2">
                {t("Price")}
              </label>
              <input
                type="number"
                min={0}
                id="price"
                className="form-control fs-sm shadow-none"
                placeholder={t("Budget")}
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {/* deadline and priority */}
        <div className="form-group mb-4">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <label
                htmlFor="deadline"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Deadline")}
              </label>
              <div className="field-container d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-lg-center px-lg-3 body-lg-bg rounded-10">
                <input
                  type="date"
                  id="startdate"
                  className="form-control fs-sm shadow-none"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(e) => {
                    setstartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      fromDateWarning: false,
                      toDateWarning: false,
                      dateCompareWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
                <input
                  type="date"
                  id="enddate"
                  className="form-control fs-sm shadow-none"
                  placeholder="End Date"
                  value={endDate}
                  onChange={(e) => {
                    setendDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      fromDateWarning: false,
                      toDateWarning: false,
                      dateCompareWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
              {validation.fromDateWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter start date!")}</span>
                  </p>
                </div>
              )}
              {validation.toDateWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter end date!")}</span>
                  </p>
                </div>
              )}
              {validation.dateCompareWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>
                      {t("End date should be greater then start date!")}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label
                htmlFor="priority"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Priority")}
              </label>
              <Select
                placeholder={t("Select Priority")}
                options={TaskPriority}
                value={priorityValue}
                onChange={(val) => {
                  prioritySelectionHandler(val);
                  setValidation((prevState) => ({
                    ...prevState,
                    priorityWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              {validation.priorityWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select priority!")}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <!-- ========== Start rank Section ========== --> */}
        <div className="form-group mb-4">
          <label htmlFor="price" className="d-block fs-sm fw-semibold mb-2">
            {t("Rank")}
          </label>
          <input
            type="number"
            min={1}
            id="price"
            className="form-control fs-sm shadow-none"
            placeholder={t("Rank")}
            value={rank}
            onChange={(e) => {
              setRank(e.target.value);
            }}
          />
        </div>
        {/* <!-- ========== End rank Section ========== --> */}
        {/* ------ upload documnet section start ------- */}
        <div className="form-group mb-3 mb-sm-4">
          <label htmlFor="documents" className="d-block fs-sm fw-semibold mb-2">
            {t("Upload Documents")}
          </label>
          <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
            <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
              <Link
                to="#"
                data-target="upload"
                className="upload-file text-center"
                onClick={openDocFileInput}
              >
                <span className="d-block material-symbols-outlined">
                  upload
                </span>
                <span className="d-block fw-semibold">{t("Upload")}</span>
              </Link>
              <span className="fs-xs">Max 20mb doc/img/mp4</span>
              <input
                type="file"
                data-id="upload"
                id="uploadedTaskDoc"
                className="d-none"
                ref={docFileInputRef}
                onChange={docFileUploadHandler}
                multiple
              />
            </li>
          </ul>
          {docFileUploading ? (
            <div className="d-flex align-items-center mt-3">
              <p className="mb-0 me-1">Uploading Files</p>
              {loadingCircle.map((circle) => {
                return (
                  <div
                    key={circle}
                    className="spinner-grow mx-1"
                    style={{ height: "6px", width: "6px" }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                );
              })}
            </div>
          ) : (
            <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
              {uploadedDocFiles.map((file, index) => {
                return (
                  <li
                    className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                    key={index}
                  >
                    <Link to="#" onClick={() => downloadFileHandler(file)}>
                      <span className="d-block material-symbols-outlined">
                        download
                      </span>
                    </Link>
                    <p className="fw-medium">{file.name}</p>
                    <Link to="#" onClick={() => closeDocFileHandler(index)}>
                      <span className="d-block material-symbols-outlined">
                        close
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {/* ------ upload documnet section end ------- */}
        <div className="action d-flex gap-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={saveTaskHandler}
            style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
            disabled={isSaving ? true : false}
          >
            {t("Save")}
            {isSaving && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
          {errorMessage == "" ? null : (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage} !</span>
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default SaveTaskTab;
