import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { useTranslation } from "react-i18next";

const PrevilegeHeader = ({ isSaving, reloadList, savePrevilegeHandler }) => {
  const { t } = useTranslation(); //for translation
  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Administration"), link: "/admin/administration" },
    { title: t("App user role privilege") },
  ];
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        <div className="col-md-6 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          <Link
            onClick={reloadList}
            to="#"
            className="btn btn-gray d-flex align-items-center"
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>

          <button
            className="btn btn-primary d-flex align-items-center gap-1"
            onClick={savePrevilegeHandler}
            disabled={isSaving ? true : false}
            style={{
              cursor: isSaving ? "not-allowed" : "pointer",
            }}
          >
            {/* <span className="d-block material-symbols-outlined icon-md">
              add
            </span> */}
            <span className="d-block">{t("Save Privileges")}</span>
            {isSaving && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrevilegeHeader;
