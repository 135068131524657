export const shuffleColors = (colorsArray) => {
  let currentIndex = colorsArray.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = colorsArray[currentIndex];
    colorsArray[currentIndex] = colorsArray[randomIndex];
    colorsArray[randomIndex] = temporaryValue;
  }

  return colorsArray;
};
