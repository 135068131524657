import React from "react";
import Header from "components/Common/Header/Header";
import CrmContactTaskList from "components/CRMComponents/CRMContactDetailsComponents/CrmContactDetailsTaskComponents/CrmContactTaskList/CrmContactTaskList";

const CrmContactDetailsTask = () => {
  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmContactTaskList />
    </main>
  );
};

export default CrmContactDetailsTask;
