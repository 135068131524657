/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const SaveColumnModal = ({
  afterModalClose,
  selectedProcessId,
  setSelectedProcessId,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [tagName, setTagName] = useState("");

  const [tagList, setTagList] = useState([]);

  const [tagWarning, setTagWarning] = useState(false);

  const [title, settitle] = useState("");
  const [slug, setslug] = useState("");

  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedTag, setselectedTag] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  //function for create new tag
  const createNewTagHandler = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_CREATE_NEW_TAG + `?token=${token}`;

      let columnData = {
        title: tagName,
        slug: tagName,
      };

      const response = await postData(requestUrl, columnData);

      console.log(response);

      if (response.status) {
        setTagName("");
        getAllTags();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    let prevTags = selectedTags;

    const isTagIdIncluded = prevTags.some((obj) => obj.tagid === data.value);
    const isComponentOrderIdIncluded = prevTags.some(
      (obj) => obj.tagid === data.value && obj.componentorderid === null
    );

    if (isTagIdIncluded) {
      let selectedTagItem = document.getElementById("columnData-" + data.value);
      selectedTagItem.classList.remove("active");
      if (isComponentOrderIdIncluded) {
        // exist in array, thus remove
        let key = prevTags.indexOf(data.value);
        prevTags.splice(key, 1);
      }
    } else {
      let selectedTagItem = document.getElementById("columnData-" + data.value);
      selectedTagItem.classList.add("active");

      const tagObj = {
        tagid: data.value,
        componentorderid: null,
      };
      // not exist in array, thus add
      prevTags.push(tagObj);
    }

    setSelectedTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("columnBlock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //get process details
  const getProcessDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_PROCESS_DETAILS +
        `/${selectedProcessId}?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status && response.data) {
        settitle(response.data.title);
        setslug(response.data.slug);
        if (response.data.tagvalues.length > 0) {
          setSelectedTags(
            response.data.tagvalues.map((item) => ({
              tagid: item.value,
              componentorderid: item?.componentorderid ?? null,
            }))
          );
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addNewColumnHandler = async () => {
    setIsUpdating(true);
    try {
      let processData = {
        title,
        slug,
        istemplate: false,
        tags: selectedTags,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_PROCESS +
        `/${selectedProcessId}?token=${token}`;

      const response = await putData(requestURL, processData);

      setIsUpdating(false);

      if (response.status) {
        closeModalhandler();
        afterModalClose();
        //hide modal
        let colModal = document.querySelector("#saveColumn");
        let modal = bootstrap.Modal.getInstance(colModal);
        modal.hide();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for close modal
  const closeModalhandler = () => {
    setTagName("");
    setSelectedTags([]);
    removeActiveClass();
    resetSelectTags();
    setTagWarning(false);
    // afterTagModalClose();
  };

  useEffect(() => {
    if (selectedProcessId) {
      getProcessDetails();
    }
  }, [selectedProcessId]);

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="modal fade"
      id="saveColumn"
      tabIndex="-1"
      aria-labelledby="saveColumn"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addLabelModalLabel">
              {t("Tags")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalhandler}
            ></button>
          </div>

          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- create new tag section start ------ */}
              <div className="form-group field-with-icon icon-right position-relative mb-4">
                <input
                  type="text"
                  className="form-control shadow-none"
                  placeholder={t("Enter to create new tag")}
                  value={tagName}
                  onChange={(e) => setTagName(e.target.value)}
                />
                <button
                  onClick={createNewTagHandler}
                  type="button"
                  className="icon text-white p-0 bg-primary border-0 rounded-circle position-absolute top-50 end-0 translate-middle-y me-3"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    add
                  </span>
                </button>
              </div>
              {/* ----- create new tag section end ------ */}

              {/* ----- tag list section start ------ */}
              <ul className="tags fs-sm d-flex flex-wrap gap-1">
                {tagList.map((tag, index) => {
                  return (
                    <li className="tag" key={index}>
                      <Link
                        to="#"
                        onClick={() => tagselectedHandler(tag)}
                        id={"columnData-" + tag.value}
                        className="columnBlock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                      >
                        <span>{tag.label}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
              {/* ----- tag list section end ------ */}
            </form>
          </div>
          <div className="modal-footer p-4 border-0 ">
            <div className="d-flex w-100 gap-2 align-items-center">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={addNewColumnHandler}
                style={{ cursor: isUpdating ? "not-allowed" : "pointer" }}
                disabled={isUpdating ? true : false}
              >
                {t("Add Column")}
                {isUpdating && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveColumnModal;
