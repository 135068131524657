/* eslint-disable */
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React, { useState, useEffect, useRef } from "react";
import CompanyDetailsCommonHeader from "../../CompanyDetailsCommonHeader/CompanyDetailsCommonHeader";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import fileDownloadHandler from "helper/DownloadFile";

//import images
import { assetImages } from "constants";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";
import SaveCompanySuccessModal from "../Modals/SaveCompanySuccessModal";
import downloadFileHandler from "helper/DownloadFile";
import { ecosystemSlug } from "Config/Config";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";

const CompanyGeneralInfoBody = () => {
  const { t } = useTranslation(); //for translation
  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: t("Company"), link: "/admin/companies" },
    { title: t("General Info") },
  ];

  const params = useParams();
  const history = useHistory();
  const moduleAccess = localStorage.getItem("moduleaccess");

  const loadingCircle = [1, 2, 3, 4, 5, 6];

  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const uploadFileInputRef = useRef(null);

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type description here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [cif, setCif] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [establishedYear, setEstablishedYear] = useState("");
  const [billings, setBillings] = useState(0);
  const [noOfEmployees, setNoOfEmployees] = useState(0);
  const [authKey, setAuthKey] = useState("");
  const [companySummary, setCompanySummary] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [companyTypeValue, setCompanyTypeValue] = useState(null);
  const [companyType, setCompanyType] = useState(null);
  const [serviceValue, setServiceValue] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [searchingValue, setSearchingValue] = useState(null);
  const [selectedSearchings, setSelectedSearchings] = useState([]);
  const [interestValue, setInterestValue] = useState(null);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const [logoImage, setLogoImage] = useState("");
  const [logoImageId, setLogoImageId] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  //function for get all roles
  const getAllCompanyType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_COMPANY_TYPE + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCompanyTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all services
  const getAllServices = async () => {
    try {
      const parentSlug = "skills";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      if (response) {
        setServiceList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all searching and interest
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "services";
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      if (response) {
        setCategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    if (zipcode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

        const response = await getData(url);

        if (response.status === "OK") {
          const results = response.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setcountry(item?.long_name);
              }
              if (item.types[0] === "locality") {
                setcityName(item?.long_name);
              }
              if (item.types[0] === "administrative_area_level_1") {
                setstateName(item?.long_name);
              }
            });

            setLatitude(results[0]?.geometry?.location?.lat);
            setLongitude(results[0]?.geometry?.location?.lng);

            // setMapCoordinate(
            //   `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            // );
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  //function for select company type
  const companyTypeSelectionHandler = (val) => {
    setCompanyTypeValue(val);
    setCompanyType(val.value);
  };

  //function for select services
  const serviceSelectionHandler = (val) => {
    setServiceValue(val);
    setSelectedServices(val.map((item) => item.value));
  };

  //function for select searchings
  const searchSelectionHandler = (val) => {
    setSearchingValue(val);
    setSelectedSearchings(val.map((item) => item.value));
  };

  //function for select interests
  const interestSelectionHandler = (val) => {
    setInterestValue(val);
    setSelectedInterest(val.map((item) => item.value));
  };

  //function for open image imput
  const openUploadFileInput = () => {
    uploadFileInputRef.current.click();
  };

  //function for image upload
  const docFileUploadHandler = async (e) => {
    try {
      setIsFileUploading(true);

      const docUploadedFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, docUploadedFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedFileIds.length == 0) {
          setUploadedFileIds(fileIds);
        } else {
          let fileArr = uploadedFileIds.concat(fileIds);
          setUploadedFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetCompanyDocInput();
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...uploadedFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setUploadedFileIds(removedFileId);

    resetCompanyDocInput();
  };

  //function for reset video inputs
  const resetCompanyDocInput = () => {
    const file = document.getElementById("companydocinput");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for upload cover image
  const logoImageUploadHandler = async (e) => {
    const file = e.target.files[0];
    setIsImageUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsImageUploading(false);
        setLogoImage(url.SERVER_URL + response.data.path);
        setLogoImageId(response.data._id);
        resetProfileImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetProfileImageFile = () => {
    const file = document.getElementById("logouploadedimage");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for reset logo
  const resetLogo = () => {
    setLogoImage("");
    setLogoImageId(null);
    resetProfileImageFile();
  };

  //function for reset
  const resetCompanyHandler = () => {
    setCif("");
    setName("");
    setEmail("");
    setEstablishedYear("");
    setBillings(0);
    setAuthKey("");
    setNoOfEmployees(0);
    setCompanySummary("");
    setCompanyDetails("");
    setPhone("");
    setAddress("");
    setWebsite("");
    setCompanyTypeValue(null);
    setCompanyType(null);
    setServiceValue(null);
    setSelectedServices([]);
    setSearchingValue(null);
    setSelectedSearchings([]);
    setInterestValue(null);
    setSelectedInterest([]);
    setstateName("");
    setcityName("");
    setcountry("");
    setzipcode("");
    setLatitude("");
    setLongitude("");
    setUploadedFiles([]);
    setUploadedFileIds([]);
    setLogoImage("");
    setLogoImageId(null);
  };

  //function for company details
  const getCompanyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_COMPANY_DETAILS +
        `/${params.id}?token=${token}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      if (response.status && response.data) {
        setCif(response.data.cif ?? "");
        setName(response.data.name ?? "");
        setEmail(response.data.email ?? "");
        setEstablishedYear(response.data.establishedyear ?? "");
        setBillings(response.data.billing ?? 0);
        setNoOfEmployees(response.data.noofemployees ?? 0);
        setCompanySummary(response.data.summary ?? "");
        setCompanyDetails(response.data.about ?? "");
        setPhone(response.data.phone ?? "");
        setAddress(response.data.location ?? "");
        setWebsite(response.data.website ?? "");
        setAuthKey(response.data?.authkey);
        if (response.data.companytype) {
          companyTypeSelectionHandler(response.data.companytype);
        }

        if (response.data.companyservices) {
          serviceSelectionHandler(response.data.companyservices);
        }

        if (response.data.companysearching) {
          searchSelectionHandler(response.data.companysearching);
        }

        if (response.data.companyinterest) {
          interestSelectionHandler(response.data.companyinterest);
        }

        setstateName(response.data.state ?? "");
        setcityName(response.data.city ?? "");
        setcountry(response.data.country ?? "");
        setzipcode(response.data.zipcode ?? "");
        setLatitude(response.data.latitude ?? "");
        setLongitude(response.data.longitude ?? "");
        setUploadedFiles(
          response.data.uploads.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
        const fileIds = response.data?.uploads.map(({ _id, ...other }) => ({
          _id,
        }));
        setUploadedFileIds(fileIds);

        setLogoImage(
          response.data.logo ? url.SERVER_URL + response.data.logo?.path : ""
        );
        setLogoImageId(
          response.data.logo?._id ? response.data.logo?._id : null
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save company
  const saveCompanyHandler = async () => {
    try {
      setIsSaving(true);
      let companyData = {
        name,
        cif,
        email,
        website,
        companytype: companyType,
        summary: companySummary,
        about: companyDetails,
        location: address,
        phone,
        city: cityName,
        state: stateName,
        country,
        zipcode: zipcode,
        logo: logoImageId,
        uploads: uploadedFileIds,
        latitude: latitude,
        longitude: longitude,
        services: selectedServices,
        searching: selectedSearchings,
        interest: selectedInterest,
        establishedyear: establishedYear,
        billing: billings,
        noofemployees: noOfEmployees,
        authkey: authKey,
      };

      let response = {};

      if (params.id) {
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_COMPANY +
          `/${params.id}?token=${token}`;

        response = await putData(requestUrl, companyData);
      } else {
        let requestUrl =
          url.API_BASE_URL + url.API_ADD_NEW_COMPANY + `?token=${token}`;

        response = await postData(requestUrl, companyData);
      }

      setIsSaving(false);

      console.log(response);
      resetCompanyHandler();
      if (response.status) {
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("afterSaveCompanyModal")
        );
        bootstrapModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for aftermodal close
  const afterSaveCompanyModalClose = () => {
    history.push(`/admin/companies`);
  };

  useEffect(() => {
    if (params.id) {
      getCompanyDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllCompanyType();
    getAllServices();
    getAllCategoryList();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COMPANY")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- companies common header ---- */}
          {params.id && <CompanyDetailsCommonHeader moduleName="info" />}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- bread crumb ---------- */}
            <BreadCrumb breadCrumbText={breadCrumbText} bottom={true} />

            {/* --- add/update section ----- */}
            <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              <form onSubmit={(e) => e.preventDefault()}>
                {/* --------- Client/Owner ----------- */}
                {/* <div className="form-group mb-3 mb-sm-4">
                <label htmlFor="client" className="d-block fs-sm fw-semibold mb-2">
                  Client/Owner
                </label>
                <input
                  type="text"
                  id="client"
                  className="form-control fs-sm shadow-none"
                  placeholder="Name"
                />
              </div> */}

                {/* -------- Cif / Type -------------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="cif"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Cif")}
                      </label>
                      <input
                        type="text"
                        id="cif"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={cif}
                        onChange={(e) => setCif(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="type"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Type")}
                      </label>

                      <Select
                        placeholder={t("Select Company Type")}
                        options={companyTypeList}
                        value={companyTypeValue}
                        onChange={(val) => {
                          companyTypeSelectionHandler(val);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* ----------- Name / Email ------------ */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="name"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Name")}
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="email"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Email")}
                      </label>
                      <input
                        type="text"
                        id="email"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* ----------- Website / Establishment Year --------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="website"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Website")}
                      </label>
                      <input
                        type="text"
                        id="website"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="establishmentYear"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Establishment Year")}
                      </label>
                      <input
                        type="number"
                        id="establishmentYear"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={establishedYear}
                        onChange={(e) => setEstablishedYear(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* ----------- location ------------ */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="address"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Location")}
                      </label>
                      <input
                        type="text"
                        id="website"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="address"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Phone")}
                      </label>
                      <input
                        type="text"
                        id="website"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* ------ zipcode,state,city,country ----- */}
                <div className="form-group mb-3">
                  <div className="row">
                    {/* ----- zipcode section start ------ */}
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <div className="row">
                        <div className="col">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("Zipcode")}
                            value={zipcode}
                            onChange={(e) => {
                              setzipcode(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col">
                          <button
                            className="btn btn-primary"
                            onClick={getLocationFromZip}
                          >
                            {t("Fetch Location by zipcode")}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* ----- zipcode section end ------ */}

                    {/* ----- city,state,country section start ------ */}
                    <div className="col-lg-8">
                      <div className="row">
                        {/* ----- city section start ------ */}
                        <div className="col-lg-4 mb-3 mb-lg-0">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("City")}
                            value={cityName}
                            onChange={(e) => {
                              setcityName(e.target.value);
                            }}
                          />
                        </div>
                        {/* ----- city section end ------ */}

                        {/* ----- state section start ------ */}
                        <div className="col-lg-4 mb-3 mb-lg-0">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("State")}
                            value={stateName}
                            onChange={(e) => {
                              setstateName(e.target.value);
                            }}
                          />
                        </div>
                        {/* ----- state section end ------ */}

                        {/* ----- country section start ------ */}
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control border-0 shadow-none"
                            placeholder={t("Country")}
                            value={country}
                            onChange={(e) => {
                              setcountry(e.target.value);
                            }}
                          />
                        </div>
                        {/* ----- country section end ------ */}
                      </div>
                    </div>
                    {/* ----- city,state,country section end ------ */}
                  </div>
                </div>

                {/* --------- Latitude / longitude --------------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="latitude"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Latitude")}
                      </label>
                      <input
                        type="text"
                        id="latitude"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="length"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Longitude")}
                      </label>
                      <input
                        type="text"
                        id="length"
                        className="form-control fs-sm shadow-none"
                        placeholder="Enter"
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* ------------- Summary ------------ */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="resume"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Summary")}
                  </label>
                  <textarea
                    name="resume"
                    id="resume"
                    cols="30"
                    rows="5"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Summary")}
                    value={companySummary}
                    onChange={(e) => setCompanySummary(e.target.value)}
                  ></textarea>
                </div>

                {/* ------------ About ------------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="about"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Details")}
                  </label>

                  <CKEditor
                    className="form-control fs-sm shadow-none"
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={companyDetails}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editorContent = editor.getData();
                      setCompanyDetails(editorContent);
                    }}
                  />
                </div>

                {/* ----- billing and no of emplyess ------ */}
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-4 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="website"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Billing")}
                      </label>
                      <input
                        type="number"
                        id="website"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={billings}
                        onChange={(e) => setBillings(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label
                        htmlFor="establishmentYear"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Number of Employees")}
                      </label>
                      <input
                        type="number"
                        id="establishmentYear"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter")}
                        value={noOfEmployees}
                        onChange={(e) => setNoOfEmployees(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label
                        htmlFor="authkey"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Authkey")}
                      </label>
                      <input
                        type="text"
                        id="authkey"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter authkey")}
                        value={authKey}
                        onChange={(e) => setAuthKey(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* ---------- Services ---------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="services"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Services")}
                  </label>
                  <Select
                    isMulti
                    placeholder={t("Select Service")}
                    options={serviceList}
                    value={serviceValue}
                    onChange={(val) => {
                      serviceSelectionHandler(val);
                    }}
                  />
                </div>

                {/* ----- seraching ----- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="services"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Searching")}
                  </label>
                  <Select
                    isMulti
                    placeholder={t("Select searching")}
                    options={categoryList}
                    value={searchingValue}
                    onChange={(val) => {
                      searchSelectionHandler(val);
                    }}
                  />
                </div>

                {/* ----- interest ----- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="services"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Interest")}
                  </label>
                  <Select
                    isMulti
                    placeholder={t("Select Interest")}
                    options={categoryList}
                    value={interestValue}
                    onChange={(val) => {
                      interestSelectionHandler(val);
                    }}
                  />
                </div>

                {/* -------------- Upload Logo ------------ */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="uploadLogo"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Upload Logo")}
                  </label>

                  <div className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                    {logoImage == "" ? null : (
                      <label className="file d-flex flex-column align-items-center justify-content-center p-3 border rounded-8 position-relative">
                        <img
                          src={logoImage}
                          alt="MyResume.pdf"
                          className="img-fluid mb-2"
                        />
                        {/* <p className="fw-semibold mb-0">altia.jpg</p> */}
                        {/* <p className="fs-xs">16mb</p> */}
                        <Link
                          to="#"
                          className="position-absolute top-0 end-0 mt-2 me-2"
                          onClick={resetLogo}
                        >
                          <span className="d-block material-symbols-outlined">
                            delete
                          </span>
                        </Link>
                      </label>
                    )}
                    <label className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer">
                      <span className="d-block material-symbols-outlined text-gray-600">
                        upload
                      </span>
                      <span className="d-block fw-semibold">{t("Upload")}</span>
                      <span className="fs-xs">Max 20mb doc/mp4</span>
                      <input
                        type="file"
                        data-id="upload"
                        className="d-none"
                        id="logouploadedimage"
                        onChange={logoImageUploadHandler}
                      />
                    </label>
                  </div>
                </div>

                {/* ------ attachment section start ------- */}
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="attachments"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Attachments")}
                  </label>
                  <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
                    <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
                      <Link
                        to="#"
                        data-target="upload"
                        className="upload-file text-center"
                        onClick={openUploadFileInput}
                      >
                        <span className="d-block material-symbols-outlined">
                          upload
                        </span>
                        <span className="d-block fw-semibold">
                          {t("Upload")}
                        </span>
                      </Link>
                      <span className="fs-xs">Max 20mb doc/img/mp4</span>
                      <input
                        type="file"
                        id="companydocinput"
                        className="d-none"
                        ref={uploadFileInputRef}
                        onChange={docFileUploadHandler}
                        multiple
                      />
                    </li>
                  </ul>

                  {isFileUploading ? (
                    <div className="d-flex align-items-center mt-3">
                      <p className="mb-0 me-1">Uploading Files</p>
                      {loadingCircle.map((circle) => {
                        return (
                          <div
                            key={circle}
                            className="spinner-grow mx-1"
                            style={{ height: "6px", width: "6px" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                      {uploadedFiles.map((file, index) => {
                        return (
                          <li
                            className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                            key={index}
                          >
                            <Link
                              to="#"
                              onClick={() => downloadFileHandler(file)}
                            >
                              <span className="d-block material-symbols-outlined">
                                download
                              </span>
                            </Link>
                            <p className="fw-medium">{file.name}</p>
                            <Link
                              to="#"
                              onClick={() => closeDocFileHandler(index)}
                            >
                              <span className="d-block material-symbols-outlined">
                                close
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
                {/* ------ attachment section end ------- */}

                {/* ----------- Agree with the Legal Notice & Privacy Policy ---------- */}
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 b-3 mb-sm-4">
                  <input
                    type="checkbox"
                    id="agree"
                    className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  />
                  <label className="form-check-label" htmlFor="agree">
                    {t("Agree with the")}{" "}
                    <Link to="#" className="text-primary">
                      {t("Legal Notice")}
                    </Link>{" "}
                    &amp;{" "}
                    <Link to="#" className="text-primary">
                      {t("Privacy Policy")}
                    </Link>
                  </label>
                </div>

                {/* ---------- cancel / submit ----------- */}
                <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                  <Link
                    to="/admin/companies"
                    className="btn btn-outline-primary"
                  >
                    {t("Cancel")}
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
                    disabled={isSaving ? true : false}
                    onClick={saveCompanyHandler}
                  >
                    {t("Save")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <SaveCompanySuccessModal
            afterSaveCompanyModalClose={afterSaveCompanyModalClose}
          />
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CompanyGeneralInfoBody;
