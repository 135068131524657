import React from 'react';
import { Link } from 'react-router-dom';

const SettingsPopup = () => {
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFeedbackSettings"
      aria-labelledby="offcanvasFeedbackSettingsLabel"
    >
      <div className="offcanvas-header gap-3 p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <h3
          className="offcanvas-title fw-bold"
          id="offcanvasFeedbackSettingsLabel"
        >
          Feedback Setting
        </h3>
        <Link
          to="offcanvasFeedbackConfiguration"
          className="btn btn-primary ms-auto"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasFeedbackConfiguration"
          aria-controls="offcanvasFeedbackConfiguration"
        >
          Add
        </Link>
      </div>
      <div className="offcanvas-body p-4">
        <div className="table-wrapper">
          <div className="table-responsive mb-4">
            <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
              <thead>
                <tr>
                  <th className="provider-role bg-transparent fs-md fw-semibold border-0">
                    Provider Role
                  </th>
                  <th className="receiver-role bg-transparent fs-md fw-semibold border-0">
                    Receiver Role
                  </th>
                  <th className="survey bg-transparent fs-md fw-semibold border-0">
                    Survey
                  </th>
                  <th className="module bg-transparent fs-md fw-semibold border-0">
                    Module
                  </th>
                  <th className="action bg-transparent fs-md fw-semibold border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="provider-role border-bottom-0">
                    <p className="fw-semibold">Manager</p>
                  </td>
                  <td className="receiver-role border-bottom-0">
                    <p className="fw-semibold">Designer</p>
                  </td>
                  <td className="survey border-bottom-0">
                    <p className="fw-semibold">Html Dev Survey</p>
                  </td>
                  <td className="module border-bottom-0">
                    <p className="fw-semibold">Feedback Module</p>
                  </td>
                  <td className="action border-bottom-0">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                    >
                      <span className="d-block">Details</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="provider-role border-bottom-0">
                    <p className="fw-semibold">Manager</p>
                  </td>
                  <td className="receiver-role border-bottom-0">
                    <p className="fw-semibold">Designer</p>
                  </td>
                  <td className="survey border-bottom-0">
                    <p className="fw-semibold">Html Dev Survey</p>
                  </td>
                  <td className="module border-bottom-0">
                    <p className="fw-semibold">Feedback Module</p>
                  </td>
                  <td className="action border-bottom-0">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                    >
                      <span className="d-block">Details</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="provider-role border-bottom-0">
                    <p className="fw-semibold">Manager</p>
                  </td>
                  <td className="receiver-role border-bottom-0">
                    <p className="fw-semibold">Designer</p>
                  </td>
                  <td className="survey border-bottom-0">
                    <p className="fw-semibold">Html Dev Survey</p>
                  </td>
                  <td className="module border-bottom-0">
                    <p className="fw-semibold">Feedback Module</p>
                  </td>
                  <td className="action border-bottom-0">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                    >
                      <span className="d-block">Details</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="provider-role border-bottom-0">
                    <p className="fw-semibold">Manager</p>
                  </td>
                  <td className="receiver-role border-bottom-0">
                    <p className="fw-semibold">Designer</p>
                  </td>
                  <td className="survey border-bottom-0">
                    <p className="fw-semibold">Html Dev Survey</p>
                  </td>
                  <td className="module border-bottom-0">
                    <p className="fw-semibold">Feedback Module</p>
                  </td>
                  <td className="action border-bottom-0">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                    >
                      <span className="d-block">Details</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="provider-role border-bottom-0">
                    <p className="fw-semibold">Manager</p>
                  </td>
                  <td className="receiver-role border-bottom-0">
                    <p className="fw-semibold">Designer</p>
                  </td>
                  <td className="survey border-bottom-0">
                    <p className="fw-semibold">Html Dev Survey</p>
                  </td>
                  <td className="module border-bottom-0">
                    <p className="fw-semibold">Feedback Module</p>
                  </td>
                  <td className="action border-bottom-0">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                    >
                      <span className="d-block">Details</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="provider-role border-bottom-0">
                    <p className="fw-semibold">Manager</p>
                  </td>
                  <td className="receiver-role border-bottom-0">
                    <p className="fw-semibold">Designer</p>
                  </td>
                  <td className="survey border-bottom-0">
                    <p className="fw-semibold">Html Dev Survey</p>
                  </td>
                  <td className="module border-bottom-0">
                    <p className="fw-semibold">Feedback Module</p>
                  </td>
                  <td className="action border-bottom-0">
                    <Link
                      to="#"
                      className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                    >
                      <span className="d-block">Details</span>
                      <span className="d-block material-symbols-outlined">
                        chevron_right
                      </span>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;
