import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const SurveyQuestionResponseHeader = ({ moduleName }) => {
  const params = useParams();
  const { t } = useTranslation(); //for translation
  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid px-lg-5">
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-xl-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          <li className="nav-item flex-fill">
            <Link
              to={`/admin/survey/question/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName === "question" && "active"
              } `}
            >
              {t("Add Questions to Survey")}
            </Link>
          </li>
          <li className="nav-item flex-fill">
            <Link
              to={`/admin/survey/response/${params.id}`}
              className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                moduleName === "response" && "active"
              } `}
            >
              {t("Survey Invite and Responses")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SurveyQuestionResponseHeader;
