/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import time calculation
import { getCurrentTime } from "helper/TimeCalculation";
import { getDuration } from "helper/EventHelper/EventHelper";
import { useTranslation } from "react-i18next";

const SaveAgendaModal = ({
  isEventModerator,
  agendaId,
  setAgendaId,
  onModalClose,
}) => {
  const { t } = useTranslation(); //for translation
  const dateOfToday = new Date().toISOString().split("T")[0];

  const params = useParams();
  const token = localStorage.getItem("token");

  const [memberList, setMemberList] = useState([]);
  const [agendaTitle, setagendaTitle] = useState("");
  const [location, setlocation] = useState("");
  const [agendaDate, setagendaDate] = useState("");
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [memberValue, setmemberValue] = useState(null);
  const [selectedMembers, setselectedMembers] = useState([]);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    titleWarning: false,
    locationWarning: false,
    dateWarning: false,
    startTimeWarning: false,
    endTimeWarning: false,
    timeDiffWarning: false,
    attendantWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (agendaTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleWarning: true }));
      isValid = false;
    }
    if (location === "") {
      setValidation((prevState) => ({ ...prevState, locationWarning: true }));
      isValid = false;
    }

    if (agendaDate == "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (startTime == "") {
      setValidation((prevState) => ({ ...prevState, startTimeWarning: true }));
      isValid = false;
    }

    if (endTime == "") {
      setValidation((prevState) => ({ ...prevState, endTimeWarning: true }));
      isValid = false;
    }

    if (startTime !== "" && endTime !== "") {
      if (startTime > endTime) {
        setValidation((prevState) => ({
          ...prevState,
          timeDiffWarning: true,
        }));
        isValid = false;
      }
    }

    // if (!memberValue) {
    //   setValidation((prevState) => ({ ...prevState, attendantWarning: true }));
    //   isValid = false;
    // }

    return isValid;
  };

  //function for get all event members
  const getAllEventMembers = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENT_MEMBER +
        `?token=${token}&event=${params.id}&isallparticipants=${true}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setMemberList(
          response.data.map((item) => ({
            label: item.name,
            value: item.userid,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get event details
  const getEventDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_EVENT_DETAILS +
        `/${params.id}?token=${token}`;

      console.log("url of event details------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response of event details------>", response);

      if (response.status) {
        //assign start date
        setagendaDate(response.data?.fromdate);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //member select
  const memberSectionHandler = (val) => {
    setmemberValue(val);
    setselectedMembers(val.map((item) => item.value));
  };

  //get agenda details
  const getAgendaDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DETAILS_AGENDA +
        `/${agendaId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setagendaTitle(response.data.description);
        setlocation(response.data.location);
        setagendaDate(response.data.date);
        setstartTime(response.data.starttime);
        setendTime(response.data.endtime);
        memberSectionHandler(response.data.attandantslistvalues);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save agenda
  const saveAgendaHandler = async () => {
    if (validate()) {
      try {
        let agendaData = {
          description: agendaTitle,
          location: location,
          date: agendaDate,
          starttime: startTime,
          endtime: endTime,
          duration: getDuration(startTime, endTime),
          availability: true,
          event: params.id,
          attendants: selectedMembers || [],
        };

        console.log("agenda data----->", agendaData);

        let requestUrl = url.API_BASE_URL;

        let response = null;

        if (agendaId) {
          requestUrl =
            requestUrl + url.API_UPDATE_AGENDA + `/${agendaId}?token=${token}`;

          console.log(requestUrl);

          response = await putData(requestUrl, agendaData);
        } else {
          requestUrl = requestUrl + url.API_ADD_NEW_AGENDA + `?token=${token}`;

          console.log(requestUrl);

          response = await postData(requestUrl, agendaData);
        }

        console.log(response);

        if (response.status) {
          resetHandler();

          //hide member modal
          let bootstrapModal = document.querySelector("#addAgenda");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          onModalClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
    }
  };

  //reset
  const resetHandler = () => {
    setValidation({
      titleWarning: false,
      locationWarning: false,
      dateWarning: false,
      startTimeWarning: false,
      endTimeWarning: false,
      attendantWarning: false,
    });

    setagendaTitle("");
    setlocation("");
    getEventDetails();
    setstartTime(getCurrentTime());
    setendTime(getCurrentTime());
    setmemberValue(null);
    setselectedMembers([]);
    setAgendaId(null);
  };

  useEffect(() => {
    if (agendaId) {
      getAgendaDetails();
    }
  }, [agendaId]);

  useEffect(() => {
    if (params.id) {
      getEventDetails();
    }
  }, [params.id]);

  useEffect(() => {
    getAllEventMembers();

    if (agendaDate == "") {
      setagendaDate(dateOfToday);
    }
    if (startTime == "") {
      setstartTime(getCurrentTime());
    }
    if (endTime == "") {
      setendTime(getCurrentTime());
    }
  }, []);

  return (
    <div
      className="modal fade"
      id="addAgenda"
      tabIndex="-1"
      aria-labelledby="addAgenda"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Save Agenda")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- title ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="memberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Title")}
                </label>
                <input
                  className="form-control"
                  placeholder={t("Enter tile")}
                  value={agendaTitle}
                  onChange={(e) => {
                    setagendaTitle(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      titleWarning: false,
                    }));
                  }}
                />
                {/* title warning */}
                {validation.titleWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter a title")}!</span>
                    </p>
                  </div>
                )}
              </div>

              {/* ----- location ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Location")}
                </label>
                <input
                  className="form-control"
                  placeholder={t("Enter Location")}
                  value={location}
                  onChange={(e) => {
                    setlocation(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      locationWarning: false,
                    }));
                  }}
                />
                {validation.locationWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please enter location")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ----- role select section end ----- */}

              <div className="form-group mb-4">
                <div className="row">
                  <div className="col-md-4">
                    <label
                      htmlFor="role"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Date")}
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      value={agendaDate}
                      onChange={(e) => {
                        setagendaDate(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          dateWarning: false,
                        }));
                      }}
                    />
                    {validation.dateWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter date")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="role"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Start time")}
                    </label>
                    <input
                      className="form-control"
                      type="time"
                      value={startTime}
                      onChange={(e) => {
                        setstartTime(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          startTimeWarning: false,
                          timeDiffWarning: false,
                        }));
                      }}
                    />
                    {validation.startTimeWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter start time")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="role"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("End Time")}
                    </label>
                    <input
                      className="form-control"
                      type="time"
                      value={endTime}
                      onChange={(e) => {
                        setendTime(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          endTimeWarning: false,
                          timeDiffWarning: false,
                        }));
                      }}
                    />
                    {validation.endTimeWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter a end time")}!</span>
                        </p>
                      </div>
                    )}
                    {validation.timeDiffWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>
                            {t("End time should not be less then start time")}!
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ----- attendants start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Attendants")}
                </label>

                <Select
                  className="w-100"
                  placeholder={t("Select attendants")}
                  isMulti
                  options={memberList}
                  value={memberValue}
                  onChange={(val) => {
                    memberSectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      attendantWarning: false,
                    }));
                  }}
                />
                {validation.attendantWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t("Please select attendant")}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ----- status select section end ----- */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {t("Cancel")}
                </Link>
                {/* {errorMessage == "" ? null : (
                  <p className="text-danger">* {errorMessage}</p>
                )} */}
                {/* <Link to="#">
                  <span className="d-block material-symbols-outlined">
                    delete
                  </span>
                </Link> */}
                {isEventModerator ? (
                  <button
                    type="button"
                    className="btn btn-primary ms-auto"
                    onClick={saveAgendaHandler}
                  >
                    {t("Save")}
                  </button>
                ) : null}
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveAgendaModal;
