/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import CrmLeadInformationBody from "components/CRMComponents/CRMLeadInformationComponents/CRMLeadInformationDetailsComponents/LeadInformationDetailsBody/CrmLeadInformationBody";

const CrmLeadInformationDetails = () => {
  useEffect(() => {
    document.title = "CRM Lead Information | Details";
    window.scrollTo(0, 0);
  }, []);
  return (
    <main id="app">
      <Header moduleName="crm" />
      <CrmLeadInformationBody />
    </main>
  );
};

export default CrmLeadInformationDetails;
