import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { t } from "i18next";
import React from "react";

const EventgalleryHeader = ({
  isEventModerator,
  parentEventTitle,
  breadCrumbText,
  addGalleryImageHandler,
}) => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ---- search section start ---- */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb
            breadCrumbText={breadCrumbText}
            displayName={parentEventTitle}
          />
        </div>
        {/* ---- search section end ---- */}

        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          {/* --- action button section start ---- */}
          {/* <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu w-100 bg-white fs-sm fw-semibold border-0 rounded-10 shadow-sm">
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabel"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">Add Label</span>
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-2"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">Delete</span>
                </Link>
              </li>
            </ul>
          </div> */}
          {/* --- action button section end ---- */}

          {/* --- add new section start ---- */}
          {isEventModerator ? (
            <button
              onClick={addGalleryImageHandler}
              className="btn btn-primary d-flex align-items-center gap-1"
            >
              <span className="d-block">{t("Save")}</span>
              <span className="d-block material-symbols-outlined icon-md">
                save
              </span>
            </button>
          ) : null}
          {/* --- add new section end ---- */}
        </div>
      </div>
    </div>
  );
};

export default EventgalleryHeader;
