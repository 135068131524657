/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

const SaveChapterPopup = ({
  selectedChapterId,
  setSelectedChapterId,
  afterPopupClose,
}) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const [memberList, setmemberList] = useState([]);

  const [code, setcode] = useState("");
  const [title, settitle] = useState("");
  const [rank, setrank] = useState("");
  const [details, setdetails] = useState("");
  const [memberValue, setmemberValue] = useState(null);
  const [memberIds, setmemberIds] = useState([]);
  const [isSaving, setisSaving] = useState(false);

  const getAllMemberList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_MEMBER_LIST_OPTIONS +
        `?token=${token}&courseid=${params.id}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setmemberList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select members
  const memberSelectionHandler = (val) => {
    setmemberValue(val);
    setmemberIds(val.map((item) => item.value));
  };

  const getChapterDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_CHAPTER_DETAILS +
        `/${selectedChapterId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setcode(response.data.code);
        settitle(response.data.title);
        setrank(response.data.rank);
        setdetails(response.data.description);
        memberSelectionHandler(response.data.membervalues);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const saveChapterHandler = async () => {
    try {
      setisSaving(true);

      let chapterData = {
        course: params.id,
        code,
        title,
        rank,
        description: details,
        members: memberIds,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (selectedChapterId) {
        requestURL +=
          url.API_UPDATE_COURSE_CHAPTER +
          `/${selectedChapterId}?token=${token}`;

        response = await putData(requestURL, chapterData);
      } else {
        requestURL += url.API_ADD_NEW_COURSE_CHAPTER + `?token=${token}`;

        response = await postData(requestURL, chapterData);
      }

      console.log(response);

      setisSaving(false);

      if (response.status) {
        resetHandler();
        afterPopupClose();
        window.bootstrap.Offcanvas.getInstance(
          document.querySelector("#addUpdateChapterOffCanvas")
        ).hide();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setcode("");
    settitle("");
    setrank("");
    setdetails("");
    setmemberValue(null);
    setmemberIds([]);
    setSelectedChapterId(null);
  };

  useEffect(() => {
    if (selectedChapterId) {
      getChapterDetails();
    }
  }, [selectedChapterId]);

  useEffect(() => {
    if (params.id) {
      getAllMemberList();
    }
  }, [params.id]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="addUpdateChapterOffCanvas"
      aria-labelledby="offcanvasLabelDetailsLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Save Chapter to course")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="form-group mb-4">
            <label
              htmlFor="labelName"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Select Members")}
            </label>
            <Select
              isMulti
              placeholder={t("Select course members")}
              options={memberList}
              value={memberValue}
              onChange={(val) => {
                memberSelectionHandler(val);
              }}
            />
          </div>

          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Code")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter title")}
                value={code}
                onChange={(e) => {
                  setcode(e.target.value);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Title")}
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter title")}
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Rank")}
              </label>
              <input
                type="number"
                min={0}
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter rank")}
                value={rank}
                onChange={(e) => {
                  setrank(e.target.value);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Description")}
              </label>
              <textarea
                name="planDescription"
                id="planDescription"
                cols="30"
                rows="4"
                className="form-control"
                placeholder={t("Enter Description")}
                value={details}
                onChange={(e) => {
                  setdetails(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveChapterHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveChapterPopup;
