/* eslint-disable */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Logout = () => {
  const token = localStorage.getItem("token");

  /* ------------- Language translation imports starts here ------------- */
  const { i18n } = useTranslation();

  useEffect(() => {
    try {
      if (token) {
        localStorage.clear();
        window.location.reload();
        i18n.changeLanguage("en");
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [token]);
};

export default Logout;
