/* eslint-disable */
import React from "react";
import { Link, useParams } from "react-router-dom";

import EventFeedbackHeader from "../Header/EventFeedbackHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import SettingsPopup from "../Popup/SettingsPopup";
import ConfigurationPopup from "../Popup/ConfigurationPopup";
import FeedbackDetailsPopup from "../Popup/FeedbackDetailsPopup";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { eventDetailsHeaderLinks } from "helper/EventHelper/EventHelper";
import { useTranslation } from "react-i18next";

const EventFeedbackBody = () => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  const breadCrumbText = [
    { title: "Event", link: "/admin/events/list" },
    { title: "Feedback" },
  ];

  // -------- tabs header object --------------------
  const commonHeaderObject = eventDetailsHeaderLinks(params.id, t);

  return (
    <div id="content_wrapper">
      <section className="event-details-wrapper bg-white pb-5">
        {/* ---- common header ---- */}
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption="Feedback"
        />
        <div className="container-fluid px-lg-5 pt-4 pt-md-0">
          {/* --- bread crumb ---- */}
          <BreadCrumb breadCrumbText={breadCrumbText} />

          {/* ---- Feedback header --- */}
          <EventFeedbackHeader />

          {/* --- feedback list table section start ---- */}
          <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                {/* --- table header start --- */}
                <thead>
                  <tr>
                    <th
                      className="provider bg-transparent fs-md fw-semibold border-0"
                      width="40%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllFeedback"
                            name="feedback"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Provider</span>
                      </div>
                    </th>
                    <th
                      className="receiver bg-transparent fs-md fw-semibold border-0"
                      width="40%"
                    >
                      Receiver
                    </th>
                    <th className="date bg-transparent fs-md fw-semibold border-0">
                      Date
                    </th>
                    <th className="action bg-transparent fs-md fw-semibold border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                {/* --- table header end --- */}

                {/* --- table body start ---- */}
                <tbody>
                  <tr>
                    {/* --- use js map loop here --- */}
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback1"
                            //value="feedback1"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback2"
                            //value="feedback2"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback3"
                            //value="feedback3"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback4"
                            //value="feedback4"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback5"
                            //value="feedback5"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback6"
                            //value="feedback6"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback7"
                            //value="feedback7"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback8"
                            //value="feedback8"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback9"
                            //value="feedback9"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="provider border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="feedback"
                            id="feedback10"
                            //value="feedback10"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: "35px", height: "35px" }}
                          >
                            <img
                              src="assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <div>
                            <p className="fs-md fw-semibold mb-0">
                              Richard Hawail
                            </p>
                            <Link
                              href="mailto:richard.hawail@gmail.com"
                              className="d-block lh-1"
                            >
                              richard.hawail@gmail.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="receiver border-bottom-0">
                      <div className="profile d-flex align-items-center gap-2">
                        <div
                          className="avatar rounded-circle overflow-hidden"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <img
                            src="/assets/img/dp-2.jpg"
                            alt="Margarita Segoui"
                            className="w-100 h-100 object-fit-cover object-center"
                          />
                        </div>
                        <div>
                          <p className="fs-md fw-semibold mb-0">
                            Margarita Segouil
                          </p>
                          <Link
                            href="mailto:margarita.segoui@gmail.com"
                            className="d-block lh-1"
                          >
                            margarita.segoui@gmail.com
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className="date border-bottom-0">
                      <p className="fw-semibold">15-12-2022</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        href="#offcanvasFeedbackDetails"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasFeedbackDetails"
                        aria-controls="offcanvasFeedbackDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                </tbody>
                {/* --- table body end ---- */}
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div>
          {/* --- feedback list table section end ---- */}
        </div>
      </section>

      {/* === popup and modal secction === */}

      {/* --- add label modal --- */}
      <AddLabelModal moduleName="" />

      {/* --- settings popup ---- */}
      <SettingsPopup />

      {/* --- configuration popup ---- */}
      <ConfigurationPopup />

      {/* ---- details popup --- */}
      <FeedbackDetailsPopup />
    </div>
  );
};

export default EventFeedbackBody;
