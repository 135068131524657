/* eslint-disable */
import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useTranslation } from 'react-i18next';

const AlertNotification = ({ showAlert, alertType, message, onClose }) => {
  const { t } = useTranslation();

  return (
    <Snackbar open={showAlert} autoHideDuration={3500} onClose={onClose}>
      <Alert severity={alertType} variant="filled" onClose={onClose}>
        {t(message)}
      </Alert>
    </Snackbar>
  );
};

export default AlertNotification;
