import Header from "components/Common/Header/Header";
import FAQBody from "components/CourseComponents/CourseDetails/CourseLessonComponents/DetailsComponents/FAQComponents/FAQBody/FAQBody";

import React from "react";

const CourseLessonsDetailsFAQ = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <FAQBody />
    </main>
  );
};

export default CourseLessonsDetailsFAQ;
