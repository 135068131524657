/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const SubmitSurveyModal = ({
  closeModalHandler,
  isSending,
  setIsSending,
  previousModalhandler,
  surveyTitle,
  surveyQuestion,
  saveAnswerOfSurvey,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  totalNoOfQuestions,
}) => {
  const starCount = [1, 2, 3, 4, 5];

  const [answerOfQuestion, setAnswerOfQuestion] = useState("");

  const [starRating, setstarRating] = useState("1");
  const [singleSelectAnswer, setSingleSelectAnswer] = useState(null);
  const [multiSelectAnswer, setMultiSelectAnswer] = useState(null);

  const [answerError, setAnswerError] = useState(false);

  //select star rating
  const starRatingHandler = (value) => {
    setstarRating(value);
  };

  const validator = () => {
    let isValid = true;

    if (surveyQuestion?.questiontypeslug !== "RATING") {
      if (answerOfQuestion === "") {
        setAnswerError(true);
        isValid = false;
      }
    }

    return isValid;
  };

  //save survey
  const saveAnswerToSurvey = () => {
    if (validator()) {
      setIsSending(true);
      //answer for rating
      if (surveyQuestion?.questiontypeslug === "RATING") {
        saveAnswerOfSurvey(surveyQuestion._id, starRating, false);
      }

      //answer for text
      if (surveyQuestion?.questiontypeslug === "TEXT") {
        saveAnswerOfSurvey(surveyQuestion._id, answerOfQuestion, false);
      }

      //answer for single select
      if (surveyQuestion?.questiontypeslug === "SINGLE SELECT") {
        saveAnswerOfSurvey(surveyQuestion._id, answerOfQuestion, false);
      }

      //answer for multi select
      if (surveyQuestion?.questiontypeslug === "MULTI SELECT") {
        saveAnswerOfSurvey(surveyQuestion._id, answerOfQuestion, false);
      }

      //answer for comments
      if (surveyQuestion?.questiontypeslug === "COMMENTS") {
        saveAnswerOfSurvey(surveyQuestion._id, answerOfQuestion, false);
      }

      // resetHandler();
    }
  };

  // function to reset
  const resetHandler = () => {
    setstarRating("1");
    setAnswerOfQuestion("");
    setSingleSelectAnswer(null);
    setMultiSelectAnswer(null);
  };

  //function for assing answer to input fields
  const assignAnswerHandler = () => {
    //answer for rating
    if (surveyQuestion?.questiontypeslug === "RATING") {
      surveyQuestion.surveyanswer &&
        surveyQuestion.surveyanswer !== "" &&
        setstarRating(surveyQuestion.surveyanswer);
    }

    //answer for text
    if (surveyQuestion?.questiontypeslug === "TEXT") {
      surveyQuestion.surveyanswer &&
        surveyQuestion.surveyanswer !== "" &&
        setAnswerOfQuestion(surveyQuestion.surveyanswer);
    }

    //answer for single select
    if (surveyQuestion?.questiontypeslug === "SINGLE SELECT") {
      if (surveyQuestion.surveyanswer && surveyQuestion.surveyanswer !== "") {
        const selectValue = {
          label: surveyQuestion.surveyanswer,
          value: surveyQuestion.surveyanswer,
        };
        setSingleSelectAnswer(selectValue);
        setAnswerOfQuestion(surveyQuestion.surveyanswer);
      }
    }

    //answer for multi select
    if (surveyQuestion?.questiontypeslug === "MULTI SELECT") {
      if (surveyQuestion.surveyanswer && surveyQuestion.surveyanswer !== "") {
        const selectValueArr = surveyQuestion.surveyanswer
          .split(", ")
          .map((item) => {
            return {
              label: item,
              value: item,
            };
          });
        setMultiSelectAnswer(selectValueArr);
        setAnswerOfQuestion(surveyQuestion.surveyanswer);
      }
    }

    //answer for comments
    if (surveyQuestion?.questiontypeslug === "COMMENTS") {
      surveyQuestion.surveyanswer &&
        surveyQuestion.surveyanswer !== "" &&
        setAnswerOfQuestion(surveyQuestion.surveyanswer);
    }
  };

  useEffect(() => {
    if (surveyQuestion) {
      resetHandler();
      assignAnswerHandler();
    }
  }, [surveyQuestion]);

  return (
    <div className="process_modal feededit_modal">
      <div
        className="modal fade show"
        id="submit_survey"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-4">
              {/* <!-- ========== Start survey title Section ========== --> */}
              <div className="signin_hdng text-center">
                <h5 className="mt-0">{surveyTitle}</h5>
              </div>
              {/* <!-- ========== End survey title Section ========== --> */}

              {/* <!-- ========== Start close modal Section ========== --> */}
              <button
                type="button"
                className="close rounded-circle body-bg d-flex align-items-center justify-content-center text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  resetHandler();
                  closeModalHandler();
                }}
              >
                <i className="material-symbols-outlined">close </i>
              </button>
              {/* <!-- ========== End close modal Section ========== --> */}
            </div>
            <div className="modal-body p-4">
              {/* <!-- ========== Start survey question title Section ========== --> */}
              <div className="question_heading mb-5 text-center">
                <h3 className="text-black fs-xl">
                  {surveyQuestion?.name ?? ""}
                </h3>
              </div>
              {/* <!-- ========== End survey question title Section ========== --> */}

              {/* <!-- ========== Start question with answer Section ========== --> */}
              <div className="form_innr">
                {surveyQuestion?.questiontypeslug ? (
                  <div className="form-group">
                    {/* <!-- ========== Start Text type question Section ========== --> */}
                    {surveyQuestion?.questiontypeslug === "TEXT" ? (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your answer"
                        value={answerOfQuestion}
                        onChange={(e) => {
                          setAnswerOfQuestion(e.target.value);
                          setAnswerError(false);
                        }}
                      />
                    ) : null}
                    {/* <!-- ========== End Text type question Section ========== --> */}

                    {/* <!-- ========== Start comment question Section ========== --> */}
                    {surveyQuestion.questiontypeslug === "COMMENTS" ? (
                      <textarea
                        className="form-control"
                        value={answerOfQuestion}
                        onChange={(e) => {
                          setAnswerOfQuestion(e.target.value);
                          setAnswerError(false);
                        }}
                      ></textarea>
                    ) : null}
                    {/* <!-- ========== End comment question Section ========== --> */}

                    {/* <!-- ========== Start single select Section ========== --> */}
                    {surveyQuestion.questiontypeslug === "SINGLE SELECT" ? (
                      <Select
                        placeholder="Select answer"
                        options={surveyQuestion.questionoptions}
                        value={singleSelectAnswer}
                        onChange={(val) => {
                          setSingleSelectAnswer(val);
                          setAnswerOfQuestion(val.value);
                          setAnswerError(false);
                        }}
                      />
                    ) : null}
                    {/* <!-- ========== End single select Section ========== --> */}

                    {/* <!-- ========== Start multi select Section ========== --> */}
                    {surveyQuestion.questiontypeslug === "MULTI SELECT" ? (
                      <Select
                        isMulti
                        placeholder="Select answer"
                        options={surveyQuestion.questionoptions}
                        value={multiSelectAnswer}
                        onChange={(val) => {
                          setMultiSelectAnswer(val);
                          const answerString = val
                            .map((item) => {
                              return item.value;
                            })
                            .join(", ");
                          setAnswerOfQuestion(answerString);
                          setAnswerError(false);
                        }}
                      />
                    ) : null}
                    {/* <!-- ========== End multi select Section ========== --> */}

                    {/* <!-- ========== Start rating Section ========== --> */}
                    {surveyQuestion?.questiontypeslug === "RATING" ? (
                      <ul className="list_style_none rating_star d-flex align-items-center justify-content-center">
                        {starCount.map((star, index) => {
                          return (
                            <li key={index} className="active">
                              <Link
                                to="#"
                                onClick={() =>
                                  starRatingHandler(star.toString())
                                }
                              >
                                <span
                                  className={`material-symbols-outlined  ${
                                    star.toString() <= starRating.toString()
                                      ? "text-primary icon-fill"
                                      : "text-gray"
                                  }`}
                                >
                                  star
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                    {/* <!-- ========== End rating Section ========== --> */}

                    {/* <!-- ========== Start validation message Section ========== --> */}
                    {answerError && (
                      <p className="text-danger mb-0 mt-2 fs-sm fw-semibold">
                        * Please fill the answer
                      </p>
                    )}
                    {/* <!-- ========== End validation message Section ========== --> */}
                  </div>
                ) : null}
              </div>
              {/* <!-- ========== End question with answer Section ========== --> */}

              {/* <!-- ========== Start answer submit(next) or (Previous) button Section ========== --> */}
              <div
                className={`process_btns_outer d-flex align-items-center  mt-5 ${
                  currentQuestionIndex === 0
                    ? "justify-content-end"
                    : "justify-content-between"
                }`}
              >
                {/* <!-- ========== Start previous button ========== --> */}
                {currentQuestionIndex === 0 ? null : (
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn bg-gray-light"
                    onClick={() => {
                      setCurrentQuestionIndex(currentQuestionIndex - 1);
                      previousModalhandler();
                    }}
                  >
                    Back
                  </Link>
                )}
                {/* <!-- ========== End previous button ========== --> */}

                {/* <!-- ========== Start next or finish button Section ========== --> */}
                <button
                  className="btn btn-primary"
                  onClick={saveAnswerToSurvey}
                  disabled={isSending ? true : false}
                  style={{
                    cursor: isSending ? "not-allowed" : "pointer",
                  }}
                >
                  {isSending
                    ? ""
                    : currentQuestionIndex + 1 < totalNoOfQuestions
                    ? "Next"
                    : "Finish"}

                  {isSending && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                {/* <!-- ========== End next or finish button Section ========== --> */}
              </div>
              {/* <!-- ========== End answer submit(next) or (Previous) button Section ========== --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitSurveyModal;
