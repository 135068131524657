import Header from 'components/Common/Header/Header';
import GroupProductWarrantyBody from 'components/GroupComponents/GroupDetails/GroupProduct/GroupProductDetails/GroupProductWarranty/GroupProductWarrantyBody/GroupProductWarrantyBody';
import React from 'react';

const GroupProductWarranty = () => {
  return (
    <>
      {/* ---- header start ---- */}
      <Header moduleName="group" />
      {/* --- header end --- */}

      {/* ----------- body section ------------ */}
      <GroupProductWarrantyBody />
    </>
  );
};

export default GroupProductWarranty;
