/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams, useLocation  } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";
import { ecosystemSlug, invoiceOwnerEmail, invoiceBillingAddress } from "Config/Config";
import { useTranslation } from "react-i18next";

const FailurePayment = () => {

    const { t } = useTranslation(); //for translation
    const params = useParams();
    const location = useLocation();
    const orderId = new URLSearchParams(location.search).get('order');


    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const [invoiceDetails, setInvoiceDetails] = useState({});


    // get plan id and set the price and show plan details
    const getOrderDetails = async () => {
        try {
            let requestUrl =
                url.API_BASE_URL +
                url.API_INVOICE_DETAILS +
                `/${orderId}`;
            
            console.log('invoice request', requestUrl);

            const response = await getData(requestUrl);

            console.log('invoice response', response);

            if (response.status) {
                setInvoiceDetails(response.data);
            }

        } catch (error) {
            console.log(error.message);
        }
    };



    // get order details to show the status
    useEffect(()=>{
        console.log('orderId useEffect', orderId);
        getOrderDetails();
    }, [orderId])



    return (
        <main id="app">
            <header className="cart_header py-3 bg-white shadow-sm">
                <div className="container-fluid  px-lg-5">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="header_logo">
                            <a href={invoiceBillingAddress.website} className="d-inline-block">
                                <img src={invoiceBillingAddress.logourl} className="w-50" alt="" />
                            </a>
                        </div>
                        <div className="login_btn">
                            <a href={invoiceBillingAddress.website} className="btn btn-outline-primary">Back to Home</a>
                        </div>
                    </div>
                </div>
            </header>

            <div id="content_wrapper">
                <section className="cart_wrapper py-4">
                    <div className="container-fluid px-lg-5">
                        <div className="cart_payment_bx bg-white shadow-sm rounded-10 p-4 text-center">
                            <div className="cart_payment_msg body-bg rounded-10 px-3 py-4 mb-3">
                                <div className="success_logo mb-2 text-center">
                                    <img src="assets/img/cart-payment-arror.png" alt="" />
                                </div>
                                <h2 className="text-primary fs-xl fw-semibold mb-1">Opposes...!</h2>
                                <p className="mb-0 text-black fs-sm">Something error in you payment</p>
                            </div>
                            <div className="payment_info">
                                <h5 className="mb-2 text-primary fw-semibold">ORDER ID : {invoiceDetails.invoiceid}</h5>
                                <h4 className="text-black fw-semibold fs-lg">{invoiceDetails.plan?.title??''}</h4>
                                <ul className="list_style_none d-flex gap-4 mb-3 justify-content-center">
                                    {
                                        invoiceDetails.plan?(
                                            <li className="d-flex align-items-center gap-2 fs-sm text-black">
                                                <label className="text-gray m-0">Duration:</label>
                                                <span className="text-black">{invoiceDetails.plan?.duration??''} Days</span>
                                            </li>
                                        ):""
                                        
                                    }
                                </ul>
                                <h5 className="mb-2 text-primary fw-normal">
                                    If amount is debited from your account, please connect support with this ORDER ID as reference.
                                </h5>
                                <h4 className="text-black fw-semibold fs-lg">
                                    {invoiceBillingAddress.supportmail}, {invoiceBillingAddress.phone}
                                </h4>
                                {/* <Link to="#" className="btn btn-primary">Retry Payment</Link> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </main>
    )


};

export default FailurePayment;



