import React from 'react';

const CrmSalesOrderDetailsPopup = () => {
  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasSalesOrderDetails"
      aria-labelledby="offcanvasSalesOrderLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasSalesOrderLabel">
          Sales Order
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4"></div>
    </div>
  );
};

export default CrmSalesOrderDetailsPopup;
