import { assetImages } from "constants";
import React from "react";
import { useTranslation } from "react-i18next";

const EmptyImageModal = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="modal fade"
      id="eventGalleryEmptyModal"
      tabIndex="-1"
      aria-labelledby="eventGallerydeleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-body text-center px-4 py-5">
            <img
              src={assetImages.exclamationIcon}
              alt="Are you sure you want to delete this item?"
              className="img-fluid"
            />
            <h2>{t("Please add images")}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyImageModal;
