/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const EditSurveyModal = ({
  selectedSurveyId,
  setSelectedSurveyId,
  afterModalClose,
}) => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  const [name, setname] = useState("");
  const [code, setcode] = useState("");

  const [nameError, setNameError] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const [isPosting, setIsPosting] = useState(false);

  const validateFields = () => {
    let isValid = true;

    if (name.trim() === "") {
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    if (code.trim() === "") {
      setCodeError(true);
      isValid = false;
    } else {
      setCodeError(false);
    }

    return isValid;
  };

  //function for get survey details
  const getSurveyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${selectedSurveyId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status) {
        setname(response.data.name);
        setcode(response.data.code);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for add new survey
  const updateSurveyHandler = async () => {
    if (validateFields()) {
      try {
        setIsPosting(true);
        let surveyData = {
          name,
          code,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_SURVEY +
          `/${selectedSurveyId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, surveyData);

        console.log(response);

        setIsPosting(false);
        if (response.status) {
          resetHandler();
          //hide survey modal
          let bootstrapModal = document.querySelector("#editSurveyModal");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          //show success modal
          let myModal = new bootstrap.Modal(
            document.getElementById("surveySuccessModal")
          );
          myModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //function for reset
  const resetHandler = () => {
    setname("");
    setcode("");
    setNameError(false);
    setCodeError(false);
    setSelectedSurveyId(null);
  };

  useEffect(() => {
    if (selectedSurveyId) {
      getSurveyDetails();
    }
  }, [selectedSurveyId]);

  return (
    <div
      className="modal fade"
      id="editSurveyModal"
      tabIndex="-1"
      aria-labelledby="editSurveyModal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Update Survey")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="name"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Survey name")}
                </label>
                <input
                  className="form-control fs-sm shadow-none"
                  value={name}
                  onChange={(e) => {
                    setNameError(false);
                    setname(e.target.value);
                  }}
                />

                {nameError && (
                  <p className="fw-bold text-danger">
                    * {t("Please enter survey name")}
                  </p>
                )}
              </div>
              {/* ----- user select section end ----- */}

              {/* ----- role select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="Code"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Survey Code")}
                </label>
                <input
                  className="form-control fs-sm shadow-none"
                  value={code}
                  onChange={(e) => {
                    setCodeError(false);
                    setcode(e.target.value);
                  }}
                />
                {codeError && (
                  <p className="fw-bold text-danger">
                    * {t("Please enter survey code")}
                  </p>
                )}
              </div>
              {/* ----- role select section end ----- */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  {t("Cancel")}
                </Link>
                {/* <Link to="#">
                  <span className="d-block material-symbols-outlined">
                    delete
                  </span>
                </Link> */}
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={updateSurveyHandler}
                  disabled={isPosting ? true : false}
                  style={{
                    cursor: isPosting ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {isPosting && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSurveyModal;
