/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import { assetImages } from "constants";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";

import AddLabelModal from "components/Common/Modal/AddLabelModal";
import SaveInvoicePopup from "components/Common/Popup/SaveInvoicePopup";
import CrmContactDetailsCommonHeader from "../../common/CrmContactDetailsCommonHeader";
import OpportunityHeader from "../Header/OpportunityHeader";
import InvoiceListFIlterPopup from "components/Common/Popup/InvoiceListFIlterPopup";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddFollowerModal from "components/Common/Modal/AddFollowerModal";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

const ContactOpportunityBody = () => {
  const moduleSlug = "opportunity";
  const moduleName = "contact";

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  const todayValue = new Date().toISOString().split("T")[0];
  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const [contactName, setContactName] = useState("");
  const [contactImage, setContactImage] = useState("");
  const [contactPersonId, setContactPersonId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterStartingPrice, setfilterStartingPrice] = useState("");
  const [filterEndingPrice, setfilterEndingPrice] = useState("");
  const [filterTags, setfilterTags] = useState([]);
  const [filterPromisePercent, setfilterPromisePercent] = useState("");
  const [filterTitle, setfilterTitle] = useState("");
  const [filterOwners, setfilterOwners] = useState([]);
  const [filterFollowers, setfilterFollowers] = useState([]);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get contact details
  const getDetailsContact = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CONTACT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        setContactName(response.data.contactname);
        setContactImage(response.data.contactimage);
        setContactPersonId(response.data.contactpersonid);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getInvoiceList = async () => {
    setRowSelection({});
    setSelectedInvoiceIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_MY_INVOICES +
        `?token=${token}&moduleslug=${moduleSlug}&moduletype=contact&filtercontactid=${params.id}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterStartingPrice !== "" && filterEndingPrice !== "") {
        requestUrl += `&filterstartprice=${filterStartingPrice}&filterendprice=${filterEndingPrice}`;
      }

      if (filterTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }

      if (filterPromisePercent !== "") {
        requestUrl = requestUrl + `&filterpercent=${filterPromisePercent}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterTags}`;
      }

      if (filterOwners.length > 0) {
        requestUrl = requestUrl + `&filterowners=${filterOwners}`;
      }

      if (filterFollowers.length > 0) {
        requestUrl = requestUrl + `&filterfollowers=${filterFollowers}`;
      }

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setInvoiceList(response.data);
        // resetFilterData();
        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    if (selectedInvoiceIds.length > 0) {
      let responseArr = [];

      for (let selectedId of selectedInvoiceIds) {
        try {
          let invoiceData = {
            status: "0",
          };

          let requestUrl =
            url.API_BASE_URL +
            url.API_DELETE_INVOICE +
            `/${selectedId}` +
            `?token=${token}`;

          const response = await putData(requestUrl, invoiceData);

          if (response.status) {
            setMessageType("success");
            responseArr.push(true);
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType("error");
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getInvoiceList();
        }, 1500);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select task for delete");
      setShowAlert(true);
    }
  };

  //material table columns array
  const initialTableColumns = [
    // {
    //   accessorKey: "#",
    //   header: t("Action"),
    //   enableColumnActions: false, // Hides the column action icon
    //   enableColumnDragging: false, // Hides the move icon
    //   enableSorting: false,
    //   Cell: ({ row }) => (
    //     <button
    //       className="action_btn_mui"
    //       data-bs-toggle="offcanvas"
    //       data-bs-target="#saveInvoice"
    //       aria-controls="saveInvoice"
    //       onClick={() => {
    //         setSelectedInvoiceId(row.original._id);
    //       }}
    //     >
    //       <span className="d-block material-symbols-outlined horz_icon">
    //         more_horiz
    //       </span>
    //     </button>
    //   ),
    // },
    {
      accessorKey: "title",
      header: t("Title"),
    },
    {
      accessorKey: "amount",
      header: t("Price"),
    },
    {
      accessorKey: "invoicedate",
      header: t("Date"),
    },
    {
      accessorKey: "promisepercent",
      header: t("Percentage") + "(%)",
    },
    {
      accessorKey: "ownername",
      header: t("Owner"),
    },
    {
      accessorKey: "followersname",
      header: t("Followers"),
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
  ];

  //* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const resetFilterData = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterStartingPrice("");
    setfilterEndingPrice("");
    setfilterTags([]);
    setfilterPromisePercent("");
    setfilterTitle("");
    setReloadData(true);
  };

  useEffect(() => {
    if (
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterStartingPrice !== "" ||
      filterEndingPrice !== "" ||
      filterTags.length > 0 ||
      filterPromisePercent !== "" ||
      filterTitle !== ""
    ) {
      getInvoiceList();
    }
  }, [
    filterStartDate,
    filterEndDate,
    filterStartingPrice,
    filterEndingPrice,
    filterTags,
    filterPromisePercent,
    filterTitle,
  ]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getInvoiceList();
    }
  }, [isNoDefaultFilter]);

  useEffect(() => {
    if (params.id) {
      getDetailsContact();
    }
  }, [params.id]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInvoiceIds(selectedIdsArray);
    } else {
      setSelectedInvoiceIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getInvoiceList();
      setReloadData(false);
    }
  }, [reloadData]);

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") &&
      moduleAccess.includes("MOD_OPPORTUNITY"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <CrmContactDetailsCommonHeader
            moduleName="opportunity"
            contactImage={contactImage}
            contactName={contactName}
          />
          <div className="container-fluid px-lg-5">
            <OpportunityHeader reloadList={resetFilterData} />
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns}
                  data={invoiceList}
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }}
                  // defaultColumn={{
                  //   minSize: 20,
                  //   maxSize: 150,
                  //   size: 50, //make columns wider by default
                  // }}
                  enableRowActions
                  renderRowActionMenuItems={({ row }) => {
                    return [
                      <MenuItem key="details">
                        <Link
                          className="dropdown-item"
                          to={`/admin/crm/contact/opportunity/save/${params.id}/${row.id}`}
                        >
                          {t("View and save Opportunity")}
                        </Link>
                      </MenuItem>,
                    ];
                  }} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        <AddLabelModal
          moduleName="invoice"
          selectedIds={selectedInvoiceIds}
          afterTagModalClose={() => {
            setTimeout(() => {
              getInvoiceList();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <SaveInvoicePopup
          leadId={null}
          contactPersonId={contactPersonId}
          moudleSlug={moduleSlug}
          moduleName={moduleName}
          selectedInvoiceId={selectedInvoiceId}
          setSelectedInvoiceId={setSelectedInvoiceId}
          reloadList={() => {
            setTimeout(() => {
              getInvoiceList();
            }, 2500);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <InvoiceListFIlterPopup
          componentName={"crmcontactopportunity"}
          moduleType={"list"}
          isReset={isFilterReset}
          setIsReset={setIsFilterReset}
          filterStartDate={filterStartDate}
          setfilterStartDate={setfilterStartDate}
          filterEndDate={filterEndDate}
          setfilterEndDate={setfilterEndDate}
          filterStartingPrice={filterStartingPrice}
          setfilterStartingPrice={setfilterStartingPrice}
          filterEndingPrice={filterEndingPrice}
          setfilterEndingPrice={setfilterEndingPrice}
          filterTags={filterTags}
          setfilterTags={setfilterTags}
          filterOwners={filterOwners}
          setfilterOwners={setfilterOwners}
          filterFollowers={filterFollowers}
          setfilterFollowers={setfilterFollowers}
          filterPromisePercent={filterPromisePercent}
          setfilterPromisePercent={setfilterPromisePercent}
          filterTitle={filterTitle}
          setfilterTitle={setfilterTitle}
          setIsNoDefaultFilter={setIsNoDefaultFilter}
        />

        <AddFollowerModal
          moduleName="opportunity"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          selectedIds={selectedInvoiceIds}
          afterModalClose={() => {
            setTimeout(() => {
              getInvoiceList();
            }, 2500);
          }}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedInvoiceIds([]);
          }}
          onDelete={changeStatusHandler}
          moduleName={t("opportunity")}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {" "}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};

export default ContactOpportunityBody;
