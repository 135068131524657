import Header from 'components/Common/Header/Header';
import ContentBody from 'components/CourseComponents/CourseDetails/CourseLessonComponents/DetailsComponents/ContentComponents/ContentBody/ContentBody';

import React from 'react';

const CourseLessonsDetailsContent = () => {
  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="courses" />

      {/* ---------- body section ------------- */}
      <ContentBody />
    </main>
  );
};

export default CourseLessonsDetailsContent;
