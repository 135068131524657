/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import OfferListData from '../../../../data/Offers/OfferListData.json';
import OfferListHeader from '../OfferListHeader/OfferListHeader';

import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import OfferListFilterPopup from '../Popup/OfferListFilterPopup';
import PostOfferPopup from '../Popup/PostOfferPopup';
/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

const OfferListBody = () => {
  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Product name',
        Cell: ({ row }) => (
          <div className="product-name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <p className="fs-md fw-semibold">{row.original.name}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'Posted by',
        header: 'Posted by',
        Cell: ({ row }) => (
          <div className="posted-by border-bottom-0">
            <div className="d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: '22px', height: '22px' }}
              >
                <img
                  src={row.original.postedBy.img}
                  alt="Richard Hawail"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <p>{row.original.postedBy.name}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <p>{row.original.status}</p>
          </div>
        ),
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        Cell: ({ row }) => (
          <div className="quantity border-bottom-0">
            <p>{row.original.quantity}</p>
          </div>
        ),
      },
      {
        accessorKey: 'responses',
        header: 'Responses',
        Cell: ({ row }) => (
          <div className="responses border-bottom-0">
            <p>{row.original.responses}</p>
          </div>
        ),
      },
      {
        accessorKey: 'price',
        header: 'Price',
        Cell: ({ row }) => (
          <div className="price border-bottom-0">
            <p>{row.original.price}</p>
          </div>
        ),
      },
      {
        accessorKey: 'Action',
        header: 'Action',
        Cell: () => (
          <div className="action border-bottom-0">
            <Link
              to="/admin/offers/response"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
            >
              <span className="d-block">Response</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  // Function to get all records
  const getAllOffers = () => {};
  useEffect(() => getAllOffers(), []);

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  return (
    <div id="content_wrapper">
      <section className="survey-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          {/* ---- header area ----*/}
          <OfferListHeader />

          {/* ----------- table area --------- */}
          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={OfferListData} // data from api to be displayed
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={row => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              muiTableContainerProps={{
                sx: {
                  maxHeight: '60vh',
                },
              }}
              enableStickyHeader
            />
          </div>
        </div>
      </section>

      {/* -------- add label modal ----------- */}
      <AddLabelModal moduleName="" />

      {/* ------------ filter popup -------------- */}
      <OfferListFilterPopup />

      {/* ----------- post offer popup -------- */}
      <PostOfferPopup />
    </div>
  );
};

export default OfferListBody;
