import GptPromptList from "components/AdministrationComponents/GptPromptComponents/GptPromptList";
import Header from "components/Common/Header/Header";
import React, { useEffect } from "react";

const GptPrompt = () => {
  useEffect(() => {
    document.title = "Administration | Gpt Prompts";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <GptPromptList />
    </main>
  );
};

export default GptPrompt;
