/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

// Material UI table
import { MaterialReactTable } from 'material-react-table';
/*import url and gateway methods */

import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';

import { assetImages } from 'constants';

import { useTranslation } from 'react-i18next';

import CrmLeadInformationCommonHeader from '../../common/CrmLeadInformationCommonHeader';
import CrmLeadCommunicationHeader from '../Header/CrmLeadCommunicationHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import ConversationMailPopup from 'components/Common/Popup/ConversationMailPopup';
import ConversationNewMailPopup from 'components/Common/Popup/ConversationNewMailPopup';
import ConversationFilterPopup from '../Popup/ConversationFilterPopup';
import AlertNotification from 'components/Common/AlertNotification/AlertNotification';
import DeleteWarningModal from 'components/Common/Modal/DeleteWarningModal';

const CrmLeadCommunicationBody = () => {
  const token = localStorage.getItem('token');

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const params = useParams();

  const moduleAccess = localStorage.getItem('moduleaccess');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const [tagList, setTagList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [leadEmail, setLeadEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mailList, setMailList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMailIds, setSelectedMailIds] = useState([]);
  const [selectedMailId, setSelectedMailId] = useState(null);

  const [filterDateRange, setFilterDateRange] = useState('');
  const [filterSubject, setfilterSubject] = useState('');
  const [filterTemplates, setfilterTemplates] = useState([]);
  const [filterTags, setFilterTags] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  //** function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** function for get all mail templates
  const getAllMailTemplates = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_MAIL_TEMPLATES + `?token=${token}`;
      const response = await getData(requestUrl);
      console.log(response);
      if (response.status) {
        setTemplateList(
          response.data.map(template => ({
            label: template.name,
            value: template._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** function for get lead details
  const getLeadDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_LEAD_DETAILS +
        `/${params.id}?token=${token}`;

      console.log('url of lead details------>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response of lead details------>', response);

      if (response.status) {
        setLeadEmail(response.data.email);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllSentMails = async () => {
    setRowSelection({});
    setSelectedMailIds([]);

    try {
      setIsLoading(true);
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_SENT_MAIL}?token=${token}&filterreceivermail=${leadEmail}`;
      if (filterDateRange != '') {
        requestUrl = requestUrl + `&maildate=${filterDateRange}`;
      }
      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&maillabels=${filterTags}`;
      }
      if (filterTemplates.length > 0) {
        requestUrl = requestUrl + `&mailtemplates=${filterTemplates}`;
      }
      if (filterSubject != '') {
        requestUrl = requestUrl + `&mailsubject=${filterSubject}`;
      }

      // console.log(requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);
      setIsLoading(false);

      if (response.status) {
        resetFilterData();
        setMailList(response.data);
        setMessageType('success');
      } else {
        setMessageType('error');
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType('error');
    }

    setShowAlert(true);
  };

  const changeStatusHandler = async () => {
    for (let mailId of selectedMailIds) {
      try {
        let mailData = {
          senderstatus: '0',
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_MAIL +
          `/${mailId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, mailData);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType('error');
        setShowAlert(true);
      }
    }
    getAllSentMails();
  };

  //** function for reset filter
  const resetFilterData = () => {
    setFilterDateRange('');
    setfilterSubject('');
    setfilterTemplates([]);
    setFilterTags([]);
  };

  useEffect(() => {
    if (params.id) {
      getLeadDetails();
      getAllTags();
      getAllMailTemplates();
    }
  }, [params.id]);

  useEffect(() => {
    if (leadEmail != '') {
      getAllSentMails();
    }
  }, [leadEmail]);

  useEffect(() => {
    if (
      filterDateRange !== '' ||
      filterSubject !== '' ||
      filterTemplates.length > 0 ||
      filterTags.length > 0
    ) {
      if (leadEmail != '') {
        getAllSentMails();
      }
    }
  }, [filterDateRange, filterSubject, filterTemplates, filterTags]);

  //* Material React Table Column and States
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Actions'),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasEmail"
            aria-controls="offcanvasEmail"
            onClick={() => {
              setSelectedMailId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: 'subject',
        header: t('Mail Subject'),
      },
      {
        accessorKey: 'maildate',
        header: t('Date'),
      },
      {
        accessorKey: 'tagstring',
        header: t('Tags'),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != '' && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(' , ').map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      key => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedMailIds(selectedIdsArray);
    } else {
      setSelectedMailIds([]);
    }
  }, [rowSelection]);

  /**
   * Closes the alert by updating the state variables.
   *
   * @return {void} No return value.
   */
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage('');
    setMessageType('');
  };

  //initialize the column order
  const columnOrder = [
    '#',
    'mrt-row-select',
    ...columns.map(c => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    (moduleAccess.includes('MOD_CRM') && moduleAccess.includes('MOD_LEAD'))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <CrmLeadInformationCommonHeader moduleName="communication" />
          <div className="container-fluid px-lg-5">
            <CrmLeadCommunicationHeader reloadList={getAllSentMails} />
            {isLoading ? null : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={mailList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={row => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 100,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: '60vh',
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>

          {/* --- filter popup --- */}
          <ConversationFilterPopup
            tagList={tagList}
            templateList={templateList}
            setFilterDateRange={setFilterDateRange}
            setfilterSubject={setfilterSubject}
            setfilterTemplates={setfilterTemplates}
            setFilterTags={setFilterTags}
          />

          {/* --- new mail popup --- */}
          <ConversationNewMailPopup
            reloadList={getAllSentMails}
            contactPersonEmail={leadEmail}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {/* ---  mail popup --- */}
          <ConversationMailPopup
            selectedMailId={selectedMailId}
            resetMailId={() => {
              setSelectedMailId(null);
            }}
          />

          <AddLabelModal
            moduleName="conversation"
            selectedIds={selectedMailIds}
            afterTagModalClose={getAllSentMails}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedMailIds([]);
            }}
            onDelete={changeStatusHandler}
            moduleName={t('communication')}
          />
          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div className="empty_access text-center">
        <div className="empty_pic mb-4">
          {' '}
          <img src={assetImages.emptyVector} alt="" />
        </div>
        <div className="empty_text">
          <p className="fs-lg text-gray fw-semibold mb-4">
            {t("Sorry....! You don't have privilege to see this content")}
          </p>
        </div>
      </div>
    );
  }
};
export default CrmLeadCommunicationBody;
