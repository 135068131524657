/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import ModeratorStatusOptions from "data/Prod/CompanyModeratorStatus.json";

const MemberDetailsModal = ({
  afterModalClose,
  selectedMemberId,
  setSelectedMemberId,
}) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [userInput, setuserInput] = useState("");
  const [userOptions, setuserOptions] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  const [membervalue, setMembervalue] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  const [roleValue, setRoleValue] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const [modStatusValue, setModStatusValue] = useState(null);
  const [modStatus, setModStatus] = useState("");

  //function for get all contacts
  const getAllContacts = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${userInput}`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      if (response.status) {
        setuserOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all roles
  const getAllCompanyRoles = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLES +
        `?token=${token}&slug=MOD_COMPANY`;

      const response = await getData(requestUrl);

      if (response.status) {
        setRolesList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select member
  const memberSelectionHandler = (val) => {
    setMembervalue(val);
    setSelectedMember(val.value);
  };

  //function for select role
  const roleSelectionHandler = (val) => {
    setRoleValue(val);
    setSelectedRole(val.value);
  };

  const statusSelectionHandler = (val) => {
    setModStatusValue(val);
    setModStatus(val.value);
  };

  //function for get member details
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_COMPANY_MEMBER_DETAILS +
        `/${selectedMemberId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        memberSelectionHandler(response.data.companymember);
        roleSelectionHandler(response.data.memberrole);
        for (let status of ModeratorStatusOptions) {
          if (status.value == response.data.moderatorstatus) {
            statusSelectionHandler(status);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setuserInput("");
    setuserOptions([]);
    setMembervalue(null);
    setSelectedMember(null);
    setRoleValue(null);
    setSelectedRole(null);
    setSelectedMemberId(null);
    setModStatusValue(null);
    setModStatus("");
  };

  //function for save member
  const saveMemberHandler = async () => {
    if (selectedMember && selectedRole && params.id) {
      try {
        let companyMemberData = {
          company: params.id,
          user: selectedMember,
          role: selectedRole,
          moderatorstatus: modStatus,
        };

        let response = {};

        if (selectedMemberId) {
          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_COMPANY_MEMBER +
            `/${selectedMemberId}?token=${token}`;

          response = await putData(requestUrl, companyMemberData);
        } else {
          let requestUrl =
            url.API_BASE_URL +
            url.API_ADD_NEW_COMPANY_MEMBER +
            `?token=${token}`;

          response = await postData(requestUrl, companyMemberData);
        }

        resetHandler();
        // close the modal of addLabel
        let memberModal = document.querySelector("#addCompanyMember");
        let modal = bootstrap.Modal.getInstance(memberModal);
        modal.hide();
        afterModalClose();
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    if (selectedMemberId) {
      getMemberDetails();
    }
  }, [selectedMemberId]);

  useEffect(() => {
    if (userInput.length > 0) {
      getAllContacts();
    }
  }, [userInput]);

  useEffect(() => {
    getAllCompanyRoles();
  }, []);

  return (
    <div
      className="modal fade"
      id="addCompanyMember"
      tabIndex="-1"
      aria-labelledby="addMemberLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              Member Details
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label
                  htmlFor="memberName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Member Name
                </label>
                <Select
                  placeholder="Select Member"
                  options={userOptions}
                  value={membervalue}
                  onChange={(val) => memberSelectionHandler(val)}
                  onInputChange={(val) => {
                    setuserInput(val);
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Role
                </label>
                <Select
                  placeholder="Select Role"
                  options={rolesList}
                  value={roleValue}
                  onChange={(val) => roleSelectionHandler(val)}
                />
              </div>

              <div className="form-group mb-4">
                <label
                  htmlFor="role"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Status
                </label>
                <Select
                  placeholder="Select Status"
                  options={ModeratorStatusOptions}
                  value={modStatusValue}
                  onChange={(val) => statusSelectionHandler(val)}
                />
              </div>

              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </Link>
                {/* <Link to="#">
                  <span className="d-block material-symbols-outlined">
                    delete
                  </span>
                </Link> */}
                <button
                  type="button"
                  className="btn btn-primary ms-auto"
                  onClick={saveMemberHandler}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetailsModal;
