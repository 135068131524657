import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const BussinessAccordion = ({
  sectorList = [],
  sectorValue = null,
  sectorSelectionHandler,
  billing = "",
  setbilling,
  countOfEmployees = "",
  setcountOfEmployees,
  lastTransaction = "",
  setlastTransaction,
  transactionModule = "",
  settransactionModule,
  transactionAmount = "",
  settransactionAmount,
  transactionDate = "",
  settransactionDate,
  transactionElement = "",
  settransactionElement,
}) => {
  const { t } = useTranslation();

  return (
    <div className="accordion-item bg-transparent border-0">
      <h3 className="accordion-header">
        <button
          className="accordion-button bg-transparent p-0 shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseNine"
          aria-expanded="true"
          aria-controls="panelsStayOpen-collapseNine"
        >
          {t("Business Information")}
        </button>
      </h3>
      <div
        id="panelsStayOpen-collapseNine"
        className="accordion-collapse collapse show"
      >
        <div className="accordion-body p-0 pt-4">
          {/* <!-- ========== Start sector Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center ps-3 py-1 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Sector")}
              </label>
              <Select
                isMulti
                className="w-100 ms-3"
                placeholder={t("Select sectors")}
                options={sectorList}
                value={sectorValue}
                onChange={(val) => {
                  sectorSelectionHandler(val);
                }}
              />
            </div>
          </div>
          {/* <!-- ========== End sector Section ========== --> */}

          {/* <!-- ========== Start billing Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Billing")}
              </label>
              <input
                type="number"
                min={0}
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Add billing")}
                value={billing}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setbilling(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          {/* <!-- ========== End billing Section ========== --> */}

          {/* <!-- ========== Start employees Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="" className="text-nowrap me-3">
                {t("Employees")}
              </label>
              <input
                type="number"
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Add employees numbers")}
                value={countOfEmployees}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    setcountOfEmployees(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          {/* <!-- ========== End employees Section ========== --> */}

          {/* subscribe section start */}

          {/* <!-- ========== Start last transaction and module Section ========== --> */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="dob">{t("Last transaction")}</label>
                  <input
                    type="text"
                    className="form-control border-0 border-end rounded-0 shadow-none"
                    value={lastTransaction}
                    onChange={(e) => setlastTransaction(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="dob">{t("Transaction Module")}</label>
                  <input
                    type="text"
                    className="form-control border-0 border-end rounded-0 shadow-none"
                    value={transactionModule}
                    onChange={(e) => settransactionModule(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- ========== End last transaction and module Section ========== --> */}

          {/* <!-- ========== Start transaction amount and date Section ========== --> */}
          <div className="form-group mb-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="dob">{t("Transaction Amount")}</label>
                  <input
                    type="text"
                    className="form-control border-0 border-end rounded-0 shadow-none"
                    value={transactionAmount}
                    onChange={(e) => settransactionAmount(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                  <label htmlFor="dob">{t("Transaction Date")}</label>
                  <input
                    type="date"
                    className="form-control border-0 border-end rounded-0 shadow-none"
                    value={transactionDate}
                    onChange={(e) => settransactionDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- ========== End transaction amount and date Section ========== --> */}

          {/* <!-- ========== Start transaction element Section ========== --> */}
          <div className="form-group mb-3">
            <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
              <label htmlFor="Website" className="text-nowrap me-3">
                {t("Transaction Element")}
              </label>
              <input
                type="text"
                className="form-control border-0 rounded-0 shadow-none"
                placeholder={t("Transaction Element")}
                value={transactionElement}
                onChange={(e) => {
                  settransactionElement(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <!-- ========== End transaction element Section ========== --> */}
        </div>
      </div>
    </div>
  );
};

export default BussinessAccordion;
