/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react';
/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'helper/UrlHelper';
// Material UI table
import { MaterialReactTable } from 'material-react-table';
import { useParams, Link } from 'react-router-dom';
import TabsHeader from 'components/Common/TabsHeader/TabsHeader';
import CourseCertificationHeader from '../CourseCertificationHeader/CourseCertificationHeader';
import AddLabelModal from 'components/Common/Modal/AddLabelModal';
import CourseCertificationFilterPopup from '../Popup/CourseCertificationFilterPopup';
import CertificateDetailsPopup from '../Popup/CertificateDetailsPopup';
import { assetImages } from 'constants';
import { useTranslation } from 'react-i18next';
import { courseDetailsHeaderObject } from 'helper/CourseHelper/CourseHelper';

const CourseCertificationBody = () => {
  const params = useParams();

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const moduleAccess = localStorage.getItem('moduleaccess');
  const { t, i18n } = useTranslation(); //for translation

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [title, settitle] = useState('');
  // common header object
  const commonHeaderObject = courseDetailsHeaderObject(params.id, t);

  // dummy data --------------------------------
  const dummyData = [
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Approved',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Applied',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Approved',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Applied',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Approved',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Approved',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Approved',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Applied',
      percentage: '75%',
    },
    {
      bio: {
        name: 'Richard Hawail',
        img: '/assets/img/dp-1.jpg',
      },
      email: 'tom.hanks@gcolabora.com',
      joined: '12th March 2022',
      status: 'Approved',
      percentage: '75%',
    },
  ];

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: '#',
        header: t('Name'),
        Cell: ({ row }) => (
          <td className="name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="profile d-flex align-items-center gap-2">
                <div
                  className="avatar rounded-circle overflow-hidden"
                  style={{ width: '35px', height: '35px' }}
                >
                  <img
                    src={row.original.bio.img}
                    alt="Richard Hawail"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <p className="fw-semibold mb-0">{row.original.bio.name}</p>
              </div>
            </div>
          </td>
        ),
      },
      {
        accessorKey: 'email',
        header: t('Email'),
        Cell: ({ row }) => (
          <td className="email border-bottom-0">
            <p className="d-flex align-items-center gap-2">
              <span className="d-block material-symbols-outlined icon-md">
                email
              </span>
              <Link
                to={`mailto:${row.original.email}`}
                className="d-block text-black"
              >
                {row.original.email}
              </Link>
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'joined',
        header: t('Joined'),
        Cell: ({ row }) => (
          <td className="joined border-bottom-0">
            <p className="d-flex align-items-center gap-2">
              <span className="d-block material-symbols-outlined icon-md">
                calendar_month
              </span>
              <span className="d-block">{row.original.joined}</span>
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'status',
        header: t('Status'),
        Cell: ({ row }) => (
          <td className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-2 fs-md fw-semibold ${
                row.original.status === 'Approved'
                  ? 'text-success'
                  : 'text-primary'
              }`}
            >
              <span className="d-block material-symbols-outlined icon-md">
                {row.original.status === 'Approved'
                  ? 'check_circle'
                  : 'schedule'}
              </span>
              <span className="d-block">{row.original.status}</span>
            </p>
          </td>
        ),
      },
      {
        accessorKey: 'percentage',
        header: t('Percentage'),
        Cell: ({ row }) => (
          <td className="percentage border-bottom-0">
            <p>{row.original.percentage}</p>
          </td>
        ),
      },
      {
        accessorKey: 'action',
        header: t('Action'),
        Cell: ({ row }) => (
          <td className="action border-bottom-0">
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#certificateDetails"
              aria-controls="certificateDetails"
            >
              <span className="d-block">{t('Details')}</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </td>
        ),
      },
    ],
    [i18n.language]
  );

  // --get course details --
  const getCourseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_COURSE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    //do something when the row selection changes...
    console.info('rowSelection', rowSelection);
  }, [rowSelection]);

  useEffect(() => {
    if (params.id) {
      getCourseDetails();
    }
  }, [params.id]);
  if (
    userInfo.role.slug === 'ADMIN' ||
    userInfo.role.slug === 'SUPER_ADMIN' ||
    moduleAccess.includes('MOD_COURSE')
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t('Certification')}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- filter  section ----------- */}
            <CourseCertificationHeader title={title} />

            {/* ------- list area ---------- */}
            {/* <div className="table-wrapper">
            <div className="table-responsive mb-4">
              <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                <thead>
                  <tr>
                    <th
                      className="name bg-transparent fs-md fw-semibold border-0"
                      width="33.333%"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-black shadow-none m-0"
                            type="checkbox"
                            id="selectAllCertifications"
                            name="certification"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="p-0 bg-transparent border-0 dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>
                          <ul className="dropdown-menu bg-white border-0 rounded-10 shadow-sm">
                            <li>
                              <Link className="dropdown-item" to="#">
                                Action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Another action
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#">
                                Something else here
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <span className="d-block ms-3">Name</span>
                      </div>
                    </th>
                    <th className="email bg-transparent fs-md fw-semibold border-0">
                      Email
                    </th>
                    <th className="joined bg-transparent fs-md fw-semibold border-0">
                      Joined
                    </th>
                    <th className="status bg-transparent fs-md fw-semibold border-0">
                      Status
                    </th>
                    <th className="percentage bg-transparent fs-md fw-semibold border-0">
                      Percentage
                    </th>
                    <th className="action bg-transparent fs-md fw-semibold border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification1"
                            value="certification1"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-md">
                          check_circle
                        </span>
                        <span className="d-block">Approved</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification2"
                            value="certification2"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-md">
                          check_circle
                        </span>
                        <span className="d-block">Approved</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification3"
                            value="certification3"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-md">
                          check_circle
                        </span>
                        <span className="d-block">Approved</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification4"
                            value="certification4"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-md">
                          check_circle
                        </span>
                        <span className="d-block">Approved</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification5"
                            value="certification5"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-primary">
                        <span className="d-block material-symbols-outlined icon-md">
                          schedule
                        </span>
                        <span className="d-block">Applied</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification6"
                            value="certification6"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-md">
                          check_circle
                        </span>
                        <span className="d-block">Approved</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification7"
                            value="certification7"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-md">
                          check_circle
                        </span>
                        <span className="d-block">Approved</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification8"
                            value="certification8"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-success">
                        <span className="d-block material-symbols-outlined icon-md">
                          check_circle
                        </span>
                        <span className="d-block">Approved</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="name border-bottom-0">
                      <div className="d-flex align-items-center gap-4">
                        <div className="form-check p-0 m-0">
                          <input
                            className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
                            type="checkbox"
                            name="certification"
                            id="certification9"
                            value="certification9"
                          />
                        </div>
                        <div className="profile d-flex align-items-center gap-2">
                          <div
                            className="avatar rounded-circle overflow-hidden"
                            style={{ width: '35px', height: '35px' }}
                          >
                            <img
                              src="/assets/img/dp-1.jpg"
                              alt="Richard Hawail"
                              className="w-100 h-100 object-fit-cover object-center"
                            />
                          </div>
                          <p className="fw-semibold mb-0">Richard Hawail</p>
                        </div>
                      </div>
                    </td>
                    <td className="email border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          email
                        </span>
                        <Link
                          to="mailto:tom.hanks@gcolabora.com"
                          className="d-block text-black"
                        >
                          tom.hanks@gcolabora.com
                        </Link>
                      </p>
                    </td>
                    <td className="joined border-bottom-0">
                      <p className="d-flex align-items-center gap-2">
                        <span className="d-block material-symbols-outlined icon-md">
                          calendar_month
                        </span>
                        <span className="d-block">12th March 2022</span>
                      </p>
                    </td>
                    <td className="status border-bottom-0">
                      <p className="d-flex align-items-center gap-2 fs-md fw-semibold text-primary">
                        <span className="d-block material-symbols-outlined icon-md">
                          schedule
                        </span>
                        <span className="d-block">Applied</span>
                      </p>
                    </td>
                    <td className="percentage border-bottom-0">
                      <p>75%</p>
                    </td>
                    <td className="action border-bottom-0">
                      <Link
                        to="#"
                        className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#certificateDetails"
                        aria-controls="certificateDetails"
                      >
                        <span className="d-block">Details</span>
                        <span className="d-block material-symbols-outlined">
                          chevron_right
                        </span>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ol className="pagination align-items-center justify-content-center gap-1 gap-sm-3 fw-semibold mb-0">
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_back
                  </span>
                </Link>
              </li>
              <li className="active">
                <span className="d-flex align-items-center justify-content-center rounded-circle">
                  1
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  2
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  3
                </Link>
              </li>
              <li>
                <span className="d-flex align-items-center justify-content-center">
                  ...
                </span>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  25
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  26
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-center rounded-circle"
                >
                  <span className="d-block material-symbols-outlined">
                    arrow_forward
                  </span>
                </Link>
              </li>
            </ol>
          </div> */}

            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={dummyData} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                getRowId={row => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                muiTableContainerProps={{
                  sx: {
                    maxHeight: '60vh',
                  },
                }}
                enableStickyHeader
              />
            </div>
          </div>
        </section>

        {/* ======== popups and modal section =========== */}
        {/* --------- add label modal ------------ */}
        <AddLabelModal moduleName="" />

        {/* ------- filter popup ------------- */}
        <CourseCertificationFilterPopup />

        {/* ------------ certificate detials popup --------- */}
        <CertificateDetailsPopup />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {' '}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CourseCertificationBody;
