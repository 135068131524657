import React from 'react';
import GroupProductDetailsCommonHeader from '../../GroupProductDetailsCommonHeader/GroupProductDetailsCommonHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const GroupProductWarrantyBody = () => {
  return (
    <div id="content_wrapper">
      <section className="group-details-wrapper bg-white">
        {/* --------- common header --------- */}
        <GroupProductDetailsCommonHeader activeTab="warranty" />

        {/* ------------ form area --------------- */}
        <div className="container-fluid px-lg-5 py-4 py-lg-5">
          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form action="">
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="warrantyDays"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Warranty Days
                </label>
                <input
                  type="text"
                  id="warrantyDays"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Days"
                />
              </div>
              <div className="form-group mb-3 mb-sm-4">
                <label
                  htmlFor="returnPolicy"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  Return Policy
                </label>
                <textarea
                  name="returnPolicy"
                  id="returnPolicy"
                  cols="30"
                  rows="10"
                  className="form-control fs-sm shadow-none"
                  placeholder="Enter Policy"
                ></textarea>
              </div>
              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link
                  to="/admin/group/product/add"
                  className="btn btn-outline-primary"
                >
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Save Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GroupProductWarrantyBody;
